import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {IAfterGuiAttachedParams, ICellRendererParams} from "ag-grid-community";

@Component({
  selector: 'castateparksscp-order-edit-button-renderer',
  templateUrl: './order-edit-button-renderer.component.html',
  styleUrl: './order-edit-button-renderer.component.css'
})
export class OrderEditButtonRendererComponent implements ICellRendererAngularComp {
    params: any;
    icon: string;
    label: string;
    type: string;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    clickMove($event, isUp = false) {
        if (this.params.clickMove instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data,
            };
            this.params.clickMove(params, isUp);
        }
    }

    isUpDisabled() {
        if (!this.params) return true;
        if (this.params.editableCallback instanceof Function && !this.params?.editableCallback()) return true;
        return this.params?.node?.rowIndex == 0;
    }
    isDownDisabled() {
        if (!this.params) return true;
        if (this.params.editableCallback instanceof Function && !this.params?.editableCallback()) return true;
        return this.params?.node?.rowIndex + 1 == this.params['dtos'].length;
    }
}


