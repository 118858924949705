import {Component, OnInit} from '@angular/core';
import {BasicFormComponent, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {AlertService} from 'src/app/shared/services/alert.service';
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {CurrentResearchProjectService} from "../../../services/current-research-project.service";
import {
    ResearchProjectInformationUpsertDto
} from "../../../shared/generated/model/research-project-information-upsert-dto";
import {
    ResearchProjectCategoryTypeIdAndDisplayNameArray
} from "../../../shared/models/enums/research-project-category.enum.util";

@Component({
    selector: 'castateparksscp-research-project-information-form',
    templateUrl: '../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../basic/shared/basic-form/basic-form.component.scss'],
})
export class ResearchProjectInformationFormComponent extends BasicFormComponent implements OnInit {
    upsertDto: ResearchProjectInformationUpsertDto;
    researchProjectCategoryOptions: Array<{ text: string, value: number }> = ResearchProjectCategoryTypeIdAndDisplayNameArray;

    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'ResearchProjectCategoryTypeID',
                label: 'Project category',
                type: 'select',
                selectOptions: this.researchProjectCategoryOptions,
                requiredValidated: true
            },
            {
                class: 'g-col-7',
                name: 'Title',
                label: 'Project title',
                type: 'text',
                requiredValidated: true
            },
            {
                class: 'g-col-7',
                name: 'Purpose',
                label: 'Project purpose',
                type: 'textArea',
                helperText: 'Describe the study rationale and significance. Include a summary of relevant literature  and specific hypotheses to be tested and/or study objectives. Describe why you want to do research in California State Parks?',
                requiredValidated: true
            },
            {
                class: 'g-col-7',
                name: 'AnticipatedBenefit',
                label: 'Anticipated benefits to the State Park System',
                type: 'textArea',
                helperText: 'Identify any anticipated benefits to the California State Park System that may result from your proposed research.',
                requiredValidated: true
            },
            {
                class: 'g-col-12',
                label: 'Project Dates',
                type: 'subHeading',
                elementHideCondition: () => !this.dto?.['AmendedFromPermitNumber']
            },
            {
                class: 'g-col-9',
                label: 'Project dates will be used to determine the length of the permit. Permits can be issued up to 12 months at a time. If you require a permit for a longer duration, you must apply for a renewal at the end of the permit term.',
                type: 'displayText',
                elementHideCondition: () => !this.dto?.['AmendedFromPermitNumber']
            },
            {
                class: 'g-col-5',
                name: 'ProjectStartDate',
                label: 'Project start date',
                type: 'date',
                onChange: (dateValue) => {
                    if (!!dateValue) {
                        let startDate = new Date(dateValue);
                        this.upsertDto['RequestedPermitStartDate'] = startDate.toLocaleDateString();
                        let endDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1)).toLocaleDateString();
                        this.upsertDto['RequestedPermitEndDate'] = endDate;
                        this.upsertDto['ProjectEndDate'] = endDate;
                        this.upsertDto['TentativeAnnualReportCompletionDate'] = endDate;
                    }
                },
                requiredValidated: true,
                elementHideCondition: () => !this.dto?.['AmendedFromPermitNumber']
            },
            {
                class: 'g-col-5',
                name: 'ProjectEndDate',
                label: 'Project end date',
                type: 'date',
                requiredValidated: true,
                elementHideCondition: () => !this.dto?.['AmendedFromPermitNumber']
            },
            {
                class: 'g-col-5',
                name: 'RequestedPermitStartDate',
                label: 'Permit requested start date',
                type: 'date',
                onChange: (dateValue) => {
                    if (!!dateValue) {
                        let startDate = new Date(dateValue);
                        let endDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1)).toLocaleDateString();
                        this.upsertDto['RequestedPermitEndDate'] = endDate;
                        this.upsertDto['TentativeAnnualReportCompletionDate'] = endDate;
                    }
                },
                requiredValidated: true,
                elementHideCondition: () => !this.dto?.['AmendedFromPermitNumber']
            },
            {
                class: 'g-col-5',
                name: 'RequestedPermitEndDate',
                label: 'Permit requested end date',
                type: 'date',
                requiredValidated: true,
                elementHideCondition: () => !this.dto?.['AmendedFromPermitNumber']
            },
            {
                class: 'g-col-12',
                label: 'The usual turnaround time for an application is 90 days which means that your permit may not be issued by the start date requested.',
                type: 'displayText',
                textInfo: true,
                showCondition: this.isLessThanNinetyDaysFromToday.bind(this)
            },
            {
                class: 'g-col-5',
                name: 'TentativeAnnualReportCompletionDate',
                label: 'Annual report tentative completion date',
                type: 'date',
                requiredValidated: true,
                elementHideCondition: () => !this.dto?.['AmendedFromPermitNumber']
            },
        ]
    } as FormPage;

    constructor(public currentResearchProjectService: CurrentResearchProjectService,
                public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => this.currentResearchProjectService.createResearchProjectInformationUpsertDto(dto);
        this.formPage.post = (upsertDto) => this.researchProjectService.researchProjectsPost(upsertDto);
        this.formPage.put = (id, upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdateInformationPut(id, upsertDto);
    }

    isLessThanNinetyDaysFromToday() {
        if (!!this.dto?.AmendedFromPermitNumber) return false;
        if (!this.upsertDto?.ProjectStartDate) return false;
        let projectStartDate = new Date(this.upsertDto.ProjectStartDate);
        const diffInMilliseconds = Math.abs(projectStartDate.getTime() - Date.now());
        const diffInDays = diffInMilliseconds / 86400000; // divide by milliseconds in a day
        return diffInDays < 90;
    }

    override handleSaveFormError(err) {
        this.errors = err;
    }
}
