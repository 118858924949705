import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {BasicFormComponent, FormElement, FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectTagService} from "../../../shared/generated/api/research-project-tag.service";
import {ResourceCategoryTagService} from "../../../shared/generated/api/resource-category-tag.service";
import {ResearchProjectTagDto} from "../../../shared/generated/model/research-project-tag-dto";
import {forkJoin} from "rxjs";
import {ResearchProjectTagUpsertDto} from "../../../shared/generated/model/research-project-tag-upsert-dto";

@Component({
    selector: 'castateparksscp-resource-category-tag-form',
    templateUrl: './../../basic/shared/basic-form/basic-form.component.html',
})
export class ResourceCategoryTagFormComponent extends BasicFormComponent {
    @Input() public canEditTechnicalReview: boolean;
    @Input() public researchProjectID: number;
    @Output() public formChanged = new EventEmitter<any>();

    upsertDto: ResearchProjectTagUpsertDto;
    tags = [];
    tagMap: Map<number, string> = new Map<number, string>();
    projectTags: ResearchProjectTagDto[] = [];

    formPage: FormPage = {
        dtoName: 'tag',
    } as FormPage;

    constructor(public alertService: AlertService,
                public researchProjectTagService: ResearchProjectTagService,
                public resourceCategoryTagService: ResourceCategoryTagService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            return {
                ResearchProjectID: this.researchProjectID,
                ResourceCategoryTagID: null
            }
        };
        this.formPage.post = (upsertDto) => this.researchProjectTagService.researchProjectTagsPost(upsertDto);
    }

    override ngOnInit() {
        forkJoin({
            allTags: this.resourceCategoryTagService.resourceCategoryTagsGet(),
            projectTags: this.researchProjectTagService.researchProjectTagsResearchProjectsResearchProjectIDGet(this.researchProjectID)
        })
        .subscribe(({allTags, projectTags}) => {
            this.tags = allTags.map(tag => {
                this.tagMap.set(tag.ResourceCategoryTagID, tag.Content);
                return {
                    content: tag.Content,
                    value: tag.ResourceCategoryTagID
                };
            });
            this.projectTags = projectTags;
            this.setAlreadyAssociatedTagOptionsDisable();
            this.refreshFormElements();
        });
        super.ngOnInit();
    }

    public setAlreadyAssociatedTagOptionsDisable() {
        this.tags.forEach(tag => tag.disabled = this.projectTags.findIndex(x => x.ResourceCategoryTagID == tag.value) > -1);
    }

    override buildFormElements(_isAccessRestricted): FormElement[] {
        let mappedDtoValue = this.mapDtoValueToListItem('ResourceCategoryTagID');
        return [
            {
                class: 'g-col-4',
                name: 'ResourceCategoryTagID',
                newListItemName: 'Content',
                label: 'Tags',
                type: 'combobox',
                listItems: this.tags,
                listItemValue: mappedDtoValue,
                displayFilterSelectedItems: true,
                disabled: !this.canEditTechnicalReview,
                onChange: () => this.formChanged.emit(),
                placeholder: 'Type to search or add new tag'
            }
        ] as FormElement[];
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        this.refreshFormElements();
    }

    getResearchProjectTags() {
        return this.projectTags;
    }
}
