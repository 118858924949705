<div class="detail-view">
    <div class="page-header pb-3">
        <h1 class="page-title">New Permit</h1>
        <div class="g-col-6" style="display: flex; flex-direction: column; justify-content: center">
            <p>
                <cds-toggle [(ngModel)]="useCDS" [label]="'Use Carbon Design System'"></cds-toggle>
            </p>
        </div>
    </div>

    <castateparksscp-permit-form
        #createNewProjectForm
        [useCDS]="useCDS"
        [permit]="permit"
        [editMode]="editMode"
        (formSubmitted)="save($event)"
        (cancelEditModeChange)="cancelEdit()"></castateparksscp-permit-form>
</div>
