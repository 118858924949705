<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!isEditing">
        <div
            id="displayContent"
            [ngClass]="
                userCanEdit
                    ? 'customRichTextContent'
                    : 'customRichTextContentNoEdit'
            "
        >
            <esa-material-button
                class="hoverEditButton"
                *ngIf="userCanEdit"
                type="clear"
                label="Edit"
                icon="edit"
                (click)="enterEdit()"
                data-cy="edit"
            ></esa-material-button>

            <ng-container *ngIf="!isEmptyContent">
                <div [innerHTML]="customRichTextContent"></div>
            </ng-container>

            <div *ngIf="isEmptyContent" class="hoverEmptySpace"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="isEditing">
        <editor
            [id]="editorId"
            #tinyMceEditor
            [init]="tinyMceConfig"
            [(ngModel)]="editedContent"
        ></editor>

        <div class="actions-bar flex-end" style="margin-top: 1rem;">
            <esa-material-button
                type="primary"
                icon="save"
                label="Save"
                (click)="saveEdit()"
                data-cy="save"
                style="margin-right: 1rem;"
            ></esa-material-button>
            <esa-material-button
                type="clear"
                icon="cancel"
                label="Cancel"
                (click)="cancelEdit()"
                data-cy="cancel"
            ></esa-material-button>
        </div>
    </ng-container>
</ng-container>
