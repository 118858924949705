<div class="detail-view">
    <div class="actions-bar">
        <esa-material-button *ngIf="canEdit()" type="clear" icon="edit"
                             (click)="enableEditMode()"></esa-material-button>
    </div>

    <ng-container *ngIf="district$ | async as c">
        <castateparksscp-district-form
            #updateDistrictForm
            [ID]="c.DistrictID"
            [isCDS]="true"
            [dto]="c"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"></castateparksscp-district-form>
    </ng-container>

    <ng-container *ngIf="sectors?.length">
        <h4 style="margin-top: 32px">Sectors</h4>
        <div class="table-responsive" style="height: 107px">
            <ag-grid-angular
                #sectorGrid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [rowData]="sectors"
                [columnDefs]="sectorColumnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                (gridReady)="onSectorGridReady($event)"
                [components]="gridComponents">
            </ag-grid-angular>
        </div>
    </ng-container>

    <h4 style="margin-top: 32px">Park lands</h4>
    <table *ngIf="parks$ | async as parkColumns" style="table-layout: fixed; width:100%">
        <tr>
            <td *ngFor="let parkColumn of parkColumns">
                <ul>
                    <li *ngFor="let park of parkColumn" style="padding-bottom: 2px; padding-top: 2px;">
                        {{ park.Name }}
                    </li>
                </ul>
            </td>
        </tr>
    </table>

    <h4 style="margin-top: 32px;">District contacts</h4>
    <div class="actions-bar" *ngIf="canEdit()">
        <esa-material-button type="primary" icon="add" label="Add Contact"
                             (click)="openDistrictContactModal(null)"></esa-material-button>
    </div>
    <ng-container *ngIf="contacts?.length">
        <div class="table-responsive" style="height: 300px">
            <ag-grid-angular
                #contactGrid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [rowData]="contacts"
                [columnDefs]="contactColumnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                (gridReady)="onContactGridReady($event)"
                [components]="gridComponents">
            </ag-grid-angular>
        </div>
    </ng-container>
</div>
