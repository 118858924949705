import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {ResearchProjectSuccessDto} from "../../shared/generated/model/research-project-success-dto";

@Component({
  selector: 'castateparksscp-research-project-success',
  templateUrl: './research-project-success.component.html',
  styleUrl: './research-project-success.component.css'
})
export class ResearchProjectSuccessComponent implements OnInit{
    researchProjectID: number;
    researchProject: ResearchProjectSuccessDto;

    constructor(private router: Router,
                private route:ActivatedRoute,
                private researchProjectService: ResearchProjectService) {
    }

    ngOnInit(): void {
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        this.researchProjectService.researchProjectsResearchProjectIDSuccessGet(this.researchProjectID)
            .subscribe( r => this.researchProject = r);

    }
    navigateToPermits() {
        this.router.navigateByUrl(`/permits`).then();
    }
}
