//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[ResearchProjectStatusType]

export enum ResearchProjectStatusTypeEnum {
  Draft = 1,
  InReview = 2,
  ReturnedToResearcher = 3,
  ActivePermit = 4,
  Rejected = 5,
  Expired = 6,
  Withdrawn = 7,
  AwaitingAnnualReport = 8
}
