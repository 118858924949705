<ng-container *ngIf="modalEdit; else inlineEdit">
    <button type="button" class="b btn btn-sm" (click)="delete($event)" *ngIf="canDelete()">
        <span class="text-muted fa fa-trash"></span>
    </button>
    <button type="button" class="b btn btn-sm" (click)="edit($event)" *ngIf="canEdit()">
        <span class="text-muted fas fa-edit"></span>
    </button>
</ng-container>
<ng-template #inlineEdit>
    <button type="button" class="b btn btn-sm" (click)="delete($event)" *ngIf="!isEditMode() && canDelete()">
        <span class="text-muted fa fa-trash"></span>
    </button>
    <button type="button" class="b btn btn-sm" (click)="edit($event)" *ngIf="!isEditMode() && canEdit()">
        <span class="text-muted fas fa-edit"></span>
    </button>
    <ng-container *ngIf="!params.useCheckSaveIcon">
        <button type="button" class="save btn btn-sm" (click)="save($event)" *ngIf="isEditMode()">
            <span class="text-muted fas fa-save"></span>
        </button>
    </ng-container>
    <ng-container *ngIf="!!params.useCheckSaveIcon">
        <button type="button" class="save btn btn-sm" (click)="save($event)" *ngIf="isEditMode()">
            <span class="text-muted fas fa-check"></span>
        </button>
    </ng-container>

    <button type="button" class="b btn btn-sm" (click)="cancel($event)" *ngIf="isEditMode()">
        <span class="text-muted fas fa-ban more"></span>
    </button>
</ng-template>
