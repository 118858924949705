export const ResearchProjectStatusTypeIdToDisplayNameMap: Map<number, string> = new Map(
    [
        [1, 'Draft'],
        [2, 'In Review'],
        [3, 'Returned to Researcher'],
        [4, 'Active Permit'],
        [5, 'Rejected'],
        [6, 'Expired'],
        [7, 'Withdrawn'],
        [8, 'Awaiting Annual Report'],

    ])
