<castateparksscp-file-uploader
    #fileUploader
    [titleLinkLabel]="titleLinkLabel"
    [titleLinkRoute]="titleLinkRoute"
    [required]="required"
    [disabled]="disabled"
    [uploadFileButtonText]="uploadFileButtonText"
    [title]="title"
    [description]="description"
    [fileRoute]="fileRoute"
    [limitOneFile]="limitOneFile"
    [canDeleteFile]="canDeleteFile"
    [isCDS]="useCDS"
    [buttonSize]="'lg'"
    [filesGetter]="filesGetter"
    [fileDelete]="fileDelete"
    [researchProjectFileTypeID]="researchProjectFileTypeID"
    [supportedFileTypes]="supportedFileTypes"
    [buttonType]="buttonType"
    [isShowUploadedFiles]="isShowUploadedFiles"
    (hasFilesToUpload)="hasFilesToUpload($event)"
    (fileUploadStarted)="fileUploadStarted($event)"
    (fileUploadSuccess)="fileUploadSuccess($event)"
    (fileDeleteSuccess)="fileDeleteSuccess($event)"
    *ngIf="fileRoute && canUploadProjectFile(currentUser)">
</castateparksscp-file-uploader>
<ng-container *ngIf="!useCDS">
    <h6>Uploaded Files</h6>
    <castateparksscp-research-project-file-list
        #researchProjectFileList
        [researchProjectID]="researchProjectID"
        [researchProjectFileTypeID]="researchProjectFileTypeID"
        [fileRoute]="fileRoute"
    ></castateparksscp-research-project-file-list>
</ng-container>

