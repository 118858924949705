import {Component} from '@angular/core';
import {AgRendererComponent} from "ag-grid-angular";

@Component({
    selector: 'castateparksscp-html-renderer',
    templateUrl: './html-renderer.component.html',
})
export class HtmlRendererComponent implements AgRendererComponent {
    value: any;
    agInit(params: any): void {
        if (params.value === null) {
            params = {value: ""}
        } else {
            this.value = params.value;
        }
    }
    refresh(params: any): boolean {
        return false;
    }
}
