import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "src/app/services/authentication.service";
import {LinkRendererComponent} from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import {TaxonService} from "src/app/shared/generated/api/taxon.service";
import {PermissionEnum} from "src/app/shared/generated/enum/permission-enum";
import {DateColumnCreatorService} from "src/app/shared/services/date-column-creator/date-column-creator.service";
import {ButtonModule, Step} from 'carbon-components-angular';
import {BasicListComponent, ListPage} from "../../basic/basic-list/basic-list.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "castateparksscp-taxon-list",
    templateUrl: "../../basic/basic-list/basic-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxonListComponent extends BasicListComponent implements OnInit {
    listPage: ListPage = {
        permission: PermissionEnum.TaxonRights,
        createRoute: '/taxons/create',
        pageTitle: 'Taxons',
        createButtonLabel: 'taxon',
        downloadFileName: 'taxons',
    } as ListPage;

    constructor(
        public taxonService: TaxonService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.taxonService.taxonsGet();
        this.listPage.columnDefs = [
            {
                field: "Name",
                headerName: "Name",
                headerTooltip: "Name",
                editable: true,
                cellEditor: "agTextCellEditor",
                width: 200,
                sort: "asc",
            },
        ];
    }

    override onGridReady(gridEvent) {
        this.grid.api.showLoadingOverlay();

        this.getRequest = this.listPage
            .dtosGetter()
            .subscribe((results) => {
                this.rowData = results;
                this.grid.api.hideOverlay();
                this.dtos = results;
                this.cdr.markForCheck();
            });
    }
}
