import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'castateparksscp-research-project-dpr65a-flag-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
})
export class ResearchProjectDpr65aFlagFormComponent extends BasicFormComponent {
    upsertDto: Dpr65AFlagDto;

    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'OptionalInsuranceAddendumDpr65A',
                label: 'Will you conduct or plan on conducting research activities above and beyond simple use? (I.e. ' +
                    'Examples of activities beyond simple use include but are not limited to the use of aircraft or ' +
                    'drones, boats, high risk vehicle use, scuba diving, climbing equipment, trapping, handling of ' +
                    'large and/or dangerous wildlife, and collection of materials that create risk such as large ' +
                    'pinecones, projects that include large teams of volunteers, etc.)?',
                required: false,
                type: 'radio',
                radioOptions: this.flagOptions,
                onChange: this.handleFlagChange.bind(this)
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            return {
                OptionalInsuranceAddendumDpr65A: dto?.OptionalInsuranceAddendumDpr65A || false
            } as Dpr65AFlagDto
        };
    }
}

export interface Dpr65AFlagDto {
    OptionalInsuranceAddendumDpr65A: boolean;
}
