import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentContactService} from 'src/app/services/current-contact.service';
import {BasicCreateComponent, CreatePage} from '../../basic/basic-create/basic-create.component';
import {ContactUpsertDto} from 'src/app/shared/generated/model/contact-upsert-dto';
import {ContactFormComponent} from '../shared/contact-form/contact-form.component';

@Component({
    selector: 'castateparksscp-contact-create',
    templateUrl: './contact-create.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCreateComponent extends BasicCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createForm", {read: ContactFormComponent}) form: ContactFormComponent;

    public dto: ContactUpsertDto = new ContactUpsertDto();
    public upsertDto: ContactUpsertDto;

    public createPage: CreatePage = {
        cancelRoute: '/contacts',
        dtoName: 'Contact',
    } as CreatePage;

    constructor(
        public currentContactService: CurrentContactService,
        public router: Router,
        public cdr: ChangeDetectorRef
    ) {
        super(router, cdr);
        this.createPage.getSuccessRoute = (result) => `/contacts/${result?.ContactID}`;
        this.createPage.dtoCreator = () => this.currentContactService.createContactDto(this.dto);
    }
}
