import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchProjectFileTypeEnum} from "../../shared/generated/enum/research-project-file-type-enum";
import {
    ResearchProjectAdditionalDocumentationUpsertDto
} from "../../shared/generated/model/research-project-additional-documentation-upsert-dto";
import {ResearchProjectSoilDisturbanceFlagFormComponent} from "./research-project-soil-disturbance-flag-form.component";
import {ResearchProjectSoilDisturbanceFormComponent} from "./research-project-soil-disturbance-form.component";
import {
    ResearchProjectAdditionalPermitFlagFormComponent
} from "./research-project-additional-permit-flag-form.component";
import {ResearchProjectAdditionalPermitFormComponent} from "./research-project-additional-permit-form.component";
import {ResearchProjectBudgetFormComponent} from "./research-project-budget-form.component";
import {ResearchProjectLiteratureCitedFormComponent} from "./research-project-literature-cited-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";
import {Alert} from "../../shared/models/alert";
import {ResearchProjectContactTypeEnum} from "../../shared/generated/enum/research-project-contact-type-enum";
import {ResearchProjectContactService} from "../../shared/generated/api/research-project-contact.service";
import {ResearchProjectDpr65aFlagFormComponent} from "./research-project-dpr65a-flag-form.component";
import {finalize, forkJoin} from "rxjs";
import {
    ResearchProjectBaseComponentComponent
} from "../research-project/shared/research-project-base-component/research-project-base-component.component";
import {BasicModalConfiguration} from "../basic/basic-modal/basic-modal.component";
import {ResearchProjectDto} from "../../shared/generated/model/research-project-dto";
import {AuthenticationService} from "../../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {
    ReturnedToResearcherNotificationComponent
} from "../research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {Title} from "@angular/platform-browser";


@Component({
    selector: 'castateparksscp-research-project-documents',
    templateUrl: './research-project-documents.component.html',
    styleUrls: ['./research-project-documents.component.scss']
})
export class ResearchProjectDocumentsComponent extends ResearchProjectBaseComponentComponent implements OnInit {
    pageSpecificTitle = 'Additional Documentation';
    stepIndex: number = 4;
    @ViewChild('researchProjectSoilDisturbanceFlagForm') soilDisturbanceFlagForm: ResearchProjectSoilDisturbanceFlagFormComponent;
    @ViewChild('researchProjectSoilDisturbanceForm') soilDisturbanceForm: ResearchProjectSoilDisturbanceFormComponent;
    @ViewChild('researchProjectAdditionalPermitFlagForm') additionalPermitFlagForm: ResearchProjectAdditionalPermitFlagFormComponent;
    @ViewChild('researchProjectAdditionalPermitForm') additionalPermitForm: ResearchProjectAdditionalPermitFormComponent;
    @ViewChild('researchProjectBudgetForm') budgetForm: ResearchProjectBudgetFormComponent;
    @ViewChild('researchProjectLiteratureCitedForm') literatureCitedForm: ResearchProjectLiteratureCitedFormComponent;
    @ViewChild('researchProjectDpr65aFlagForm') dpr65aFlagForm: ResearchProjectDpr65aFlagFormComponent;
    @ViewChild("notificationComponent", {static: true})
    public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;

    researchProjectID: number;
    researchProjectDto: ResearchProjectAdditionalDocumentationUpsertDto = {};
    projectDtoRaw: ResearchProjectDto;
    errors = {};
    studyProposalFields = ['Project Title',
    'Person preparing the proposal',
    'Abstract',
    'Introduction',
    'Methods',
    'Other state or federal permits',
    'Schedule',
    'Anticipated benefits to State Park System'];
    studyProposalDeferredFields = [
        'ProposalIncludesDataProducts',
        'ProposalIncludesStudyProcedures',
        'ProposalIncludesCollectionRationale',
        'ProposalIncludesSoilDisturbance',
        'ProposalIncludesBudget',
        'ProposalIncludesLiteratureCited',
    ];
    studyProposalRequirementMap = new Map([
        ['ProposalIncludesDataProducts', 'Products'],
        ['ProposalIncludesStudyProcedures', 'Procedures'],
        ['ProposalIncludesCollectionRationale', 'Collections'],
        ['ProposalIncludesSoilDisturbance', 'Soil disturbance'],
        ['ProposalIncludesBudget', 'Budget'],
        ['ProposalIncludesLiteratureCited', 'Literature cited'],
    ]);

    sectionStepIndexToLabelMap = new Map<number, string>([
        [0,`Research Team`],
        [1,`Project Information`],
        [2,`Study Areas`],
        [3,`Data Collection`],
        [4,`Additional Documentation`],
    ])

    organizationContact;
    piContact;
    isLoading: boolean = false;

    modalConfig: BasicModalConfiguration = {} as BasicModalConfiguration;
    modalOpen;
    constructor(private router: Router,
                private researchProjectService: ResearchProjectService,
                private researchProjectContactService: ResearchProjectContactService,
                private alertService: AlertService,
                public route: ActivatedRoute,
                public dialog: MatDialog,
                public cdr: ChangeDetectorRef,
                public authenticationService: AuthenticationService,
                public titleService: Title
    ) {
        super(titleService);
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        forkJoin({
            researchProject: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
            contacts: this.researchProjectContactService.researchProjectContactsResearchProjectResearchProjectIDGet(this.researchProjectID),
            currentUser: this.authenticationService.getCurrentUser()
        })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
            next: ({researchProject, contacts, currentUser}) => {
                this.setTitle(researchProject);
                this.researchProjectDto = researchProject;
                this.projectDtoRaw = researchProject;
                let orgIndex = contacts?.findIndex(r => ResearchProjectContactTypeEnum.Organization == r.ResearchProjectContactTypeID);
                if (orgIndex > -1) {
                    this.organizationContact = contacts[orgIndex];
                }
                let piIndex = contacts?.findIndex(r => ResearchProjectContactTypeEnum.PrincipalInvestigator == r.ResearchProjectContactTypeID);
                if (piIndex > -1) {
                    this.piContact = contacts[piIndex];
                }

                this.user = currentUser;

                this.setCanEditPage(currentUser, this.researchProjectDto, this.router);
            }
        });
    }

    saveAndContinue(isStepSelected = false): void {
        this.soilDisturbanceFlagForm.mapUpsert(this.researchProjectDto);
        this.soilDisturbanceForm.mapUpsert(this.researchProjectDto);
        this.additionalPermitFlagForm.mapUpsert(this.researchProjectDto);
        this.additionalPermitForm.mapUpsert(this.researchProjectDto);
        this.budgetForm.mapUpsert(this.researchProjectDto);
        this.literatureCitedForm.mapUpsert(this.researchProjectDto);
        this.dpr65aFlagForm.mapUpsert(this.researchProjectDto);

        this.researchProjectService.researchProjectsResearchProjectIDUpdateAdditionalDocumentationPut(this.researchProjectID, this.researchProjectDto)
            .subscribe({
                next: (researchProject) => {
                    if (isStepSelected || !this.incompleteSectionsExcludingReviewAndSubmitExist(researchProject)) {
                        let url = isStepSelected ? this.stepSelectedRoute : `/public/research-project-review-and-submit/${this.researchProjectID}`;
                        this.router.navigateByUrl(url)
                            .then(x => this.alertService.pushAlert(new Alert('The research project was successfully updated.'), 5000));
                        return;
                    }
                    this.displayIncompleteSectionsModal(researchProject);
                },
                error: (err) => this.errors = err?.error
            });
    }

    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;

    displayIncompleteSectionsModal(researchProject: ResearchProjectDto) {
        if (!researchProject?.IncompleteSectionNumbers?.length) return;
        const listOfNumbersString = researchProject.IncompleteSectionNumbers
            .filter(number => number != 5)
            .map(number => '<li class="cds--list__item">' + this.sectionStepIndexToLabelMap.get(number) + '</li>')
            .join("");
        this.modalConfig = {
            size: "md",
            modalHeaderTitle: 'Application Incomplete',
            contentTitle: 'Missing required information',
            content: 'This section was saved but the application is still incomplete, and cannot continue to the review' +
                ' section. Please return to the following pages and fill out any missed requirements.<br/><br/>' +
                '<ul class="cds--list--unordered">' + listOfNumbersString + '</ul>',
            primaryButtonClick: () => this.modalOpen = false,
        } as BasicModalConfiguration;
        this.modalOpen = true;
    }

    handleInvolvesSoilDisturbance(value) {
        this.researchProjectDto.SoilDisturbance = value;
        if (!value) {
            this.soilDisturbanceForm.setBlankForm();
        }
    }

    handleInvolvesAdditionalPermit(value) {
        this.researchProjectDto.AdditionalPermits = value;
        if (!value) {
            this.additionalPermitForm.setBlankForm();
        }
    }

    handleInvolvesDpr65A(value) {
        this.researchProjectDto.OptionalInsuranceAddendumDpr65A = value;
    }

    getStudyProposalText() {
        if (!this.getProposalRequirements().length) {
            return 'Your study proposal may contain some of the relevant sections:';
        }
        return 'When you submit your study proposal please ensure it contains the previously deferred fields:';
    }

    handleProposalDeferredField(event: {name: string, value: any}) {
        this.researchProjectDto[event?.name] = event?.value;
    }

    getProposalRequirementList() {
        let requirements = this.getProposalRequirements();
        if (!requirements.length) {
            return [...this.studyProposalFields, ...Array.from(this.studyProposalRequirementMap.values())];
        }
        return [...requirements];
    }

    getProposalRequirements() {
        return this.studyProposalDeferredFields.filter(f => !!this.researchProjectDto[f])
            .map(f => this.studyProposalRequirementMap.get(f));
    }

    handleStepSelect(route: string): void {
        this.stepSelectedRoute = route;
        if (this.isLoading) {
            this.router.navigateByUrl(route).then(x => {});
        } else {
            this.saveAndContinue(true);
        }
    }

    additionalPermitDocumentRequired() {
        return !this?.additionalPermitForm?.upsertDto?.AdditionalPermitsDetails;
    }
    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => this.researchProjectDto = res);
        this.returnedToResearcherNotification.refreshInternalFields();
    }

    handleWithdraw() {
        this.withdraw(this.dialog, this.researchProjectID, this.researchProjectService, this.router, this.alertService);
    }

    canMoveToWithdrawnStatus(): boolean {
        return this.canWithdrawStatuses.includes(this.projectDtoRaw?.ResearchProjectStatusTypeID);
    }
}
