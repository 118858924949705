import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
  selector: 'castateparksscp-research-project-collection-rationale-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectCollectionRationaleFormComponent extends BasicFormComponent{
    upsertDto: CollectionRationaleDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'CollectionRationaleDetails',
                label: 'Collection Rationale',
                type: 'textArea',
                helperText: 'Describe why collection is necessary, and the disposition of specimens collected. Provide power analysis to justify proposed sample size(s).',
                checkbox: true,
                checkboxName: 'ProposalIncludesCollectionRationale',
                requiredValidated: true
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                CollectionRationaleDetails: dto?.CollectionRationaleDetails,
                ProposalIncludesCollectionRationale: dto?.ProposalIncludesCollectionRationale} as CollectionRationaleDto
        };
    }
}

export interface CollectionRationaleDto {
    CollectionRationaleDetails: string;
    ProposalIncludesCollectionRationale: boolean;
}
