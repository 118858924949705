<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="researchProject"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <h2>{{title}}</h2>
        <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>
        <castateparksscp-readonly-notification [show]="!canEditPage"></castateparksscp-readonly-notification>
        <castateparksscp-public-instructional-text *ngIf="!!researchProject"></castateparksscp-public-instructional-text>

        <p *ngIf="!!researchProject?.RenewedFromPermitID" [style]="'margin-bottom: 10px; margin-top: 10px'">Renewing from: <a href='public/research-project-review-and-submit/{{researchProject?.RenewedFromPermitID}}'>{{researchProject?.RenewedFromPermitNumber}}</a></p>
        <p *ngIf="!!researchProject?.AmendedFromPermitID" [style]="'margin-bottom: 10px; margin-top: 10px'">Amended from: <a href='public/research-project-review-and-submit/{{researchProject?.AmendedFromPermitID}}'>{{researchProject?.AmendedFromPermitNumber}}</a></p>

        <castateparksscp-research-project-information-form
            #researchProjectInformationForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="researchProject"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (formSubmitted)="researchProjectInformationSaved($event)"
            (cancelEditModeChange)="cancelEditResearchProject()"></castateparksscp-research-project-information-form>

        <castateparksscp-research-project-field-investigation-occurrence-list
            #researchProjectFieldInvestigationOccurrenceList
            [entryRequired]="true"
            [researchProjectID]="researchProjectID"
            [disabled]="!canEditPage"
        >
        </castateparksscp-research-project-field-investigation-occurrence-list>

        <castateparksscp-research-project-file-list-manager
            [title]="'Project schedule additional documentation'"
            [description]="'If you have a more complex project schedule you can upload supporting documents.'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.ProjectScheduleDocumentation"
            [researchProjectID]="researchProjectID"
            [disabled]="!canEditPage"
        ></castateparksscp-research-project-file-list-manager>

        <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float:right; display: flex; flex-direction: row-reverse">
            <button cdsButton size="xl" (click)="saveAndContinue()">Save and Continue
                <castateparksscp-right-arrow></castateparksscp-right-arrow>
            </button>
            <button *ngIf="displayWithdrawButton()" size="xl" cdsButton="ghost" (click)="handleWithdraw()">Withdraw application</button>
        </div>
    </div>

</div>
