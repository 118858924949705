<div class="detail-view" *ngIf="user$ | async as user">
  <div class="page-header pb-3">
    <h1 class="page-title">
      {{ user?.FullName || ((user?.FirstName || user?.LastName) ? (user?.FirstName + " " + user?.LastName) : null) }}
    </h1>
  </div>

  <form class="form grid-12" (ngSubmit)="onSubmit(editUserForm)" #editUserForm="ngForm">
    <div class="g-col-12">
      <esa-material-ff
          keyStyle="20%"
          name="RoleID"
          [editMode]="true"
          [required]="true"
          label="Role"
          type="dropdown"
          [options]="roles$ | async"
          optionLabel="DisplayName"
          optionValue="RoleID"
          (ngModelChange)="checkReceiveSupportEmails()"
          [(ngModel)]="model.RoleID">
      </esa-material-ff>
    </div>

    <div class="required-note g-col-8">
      <i class="fas fa-bolt"></i> Required field
    </div>

    <div class="g-col-12 actions-bar" style="margin-top: 1rem">
      <esa-material-button
        type="primary"
        label="Save"
        icon="save"
        [tooltip]="!editUserForm.form.valid ? 'You are missing requried fields.' : ''"
        (click)="onSubmit(editUserForm)"
        [disabled]="!editUserForm.form.valid || isLoadingSubmit"
        style="margin-right: 1rem">
      </esa-material-button>
      <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancel()"> </esa-material-button>
    </div>
  </form>
</div>
