<form class="form grid-12" #permitForm="ngForm" *ngIf="useCDS">
    <div class="g-col-12">
        <cds-progress-indicator
            [skeleton]="skeleton"
            [steps]="steps"
            [orientation]="orientation"
            [current]="current"
            (stepSelected)="stepSelected($event)"
            [spacing]="spacing">
        </cds-progress-indicator>
    </div>

    <div class="g-col-12">
        <cds-text-label>
            Applicant Organization
            <input
                cdsText>
        </cds-text-label>

<!--        <cds-text-label-->
<!--            [helperText]="'This is input box for Application Organization'"-->
<!--            [invalid]="false"-->
<!--            [invalidText]="'This field is required'"-->
<!--            [warn]="false"-->
<!--            [disabled]="false"-->
<!--            [warnText]="'Be sure to fill out this field'"-->
<!--        >-->
<!--            Applicant Organization-->
<!--            <input-->
<!--                cdsText-->
<!--                [size]="'lg'"-->
<!--                [invalid]="false"-->
<!--                [warn]="false"-->
<!--                [disabled]="false"-->
<!--                [theme]="'dark'"-->
<!--                [placeholder]="'Enter applicant organization here'"-->
<!--                [autocomplete]="'off'"-->
<!--            />-->
<!--        </cds-text-label>-->
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Phone Number
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Email
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Organization mailing address
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Address 2
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            State
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Zip Code
            <input
                cdsText>
        </cds-text-label>
    </div>
    <div class="g-col-12">

    </div>
    <div class="g-col-12">
        <cds-text-label>
            Principal Investigator name
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">

        <cds-number
            [label]="'Cell Phone'"
        ></cds-number>

<!--        <cds-text-label>-->
<!--            Cell Phone-->
<!--            <input-->
<!--                cdsText>-->
<!--        </cds-text-label>-->
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Email
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Address 1
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Address 2
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            City
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            State
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Zip Code
            <input
                cdsText>
        </cds-text-label>
    </div>

    <div class="g-col-6">
        <cds-text-label>
            Country
            <input
                cdsText>
        </cds-text-label>
    </div>

    <button cdsButton>Save</button>
    <button cdsButton="secondary">Cancel</button>
</form>


<form class="form grid-12" #permitForm="ngForm" *ngIf="!useCDS">
    <div class="g-col-12">
        <esa-material-ff
            name="ApplicantOrganization"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitApplicantOrganizationCustomLabel">
        </esa-material-ff>
        <ng-template #permitApplicantOrganizationCustomLabel>
            <castateparksscp-custom-form-label label="Applicant Organization" fieldDefinitionType="ProjectName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="PhoneNumber"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitPhoneNumberCustomLabel">
        </esa-material-ff>
        <ng-template #permitPhoneNumberCustomLabel>
            <castateparksscp-custom-form-label label="Phone Number" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="Email"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitEmailCustomLabel">
        </esa-material-ff>
        <ng-template #permitEmailCustomLabel>
            <castateparksscp-custom-form-label label="Email" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="OrganizationMailingAddress"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitOrganizationMailingAddressCustomLabel">
        </esa-material-ff>
        <ng-template #permitOrganizationMailingAddressCustomLabel>
            <castateparksscp-custom-form-label label="Organization Mailing Address" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="Address2"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitAddress2CustomLabel">
        </esa-material-ff>
        <ng-template #permitAddress2CustomLabel>
            <castateparksscp-custom-form-label label="Address 2" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="State"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitStateCustomLabel">
        </esa-material-ff>
        <ng-template #permitStateCustomLabel>
            <castateparksscp-custom-form-label label="State" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="ZipCode"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitZipCodeCustomLabel">
        </esa-material-ff>
        <ng-template #permitZipCodeCustomLabel>
            <castateparksscp-custom-form-label label="ZipCode" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-12">
    </div>

    <div class="g-col-12">
        <esa-material-ff
            name="PrincipalInvestigatorName"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitPrincipalInvestigatorNameCustomLabel">
        </esa-material-ff>
        <ng-template #permitPrincipalInvestigatorNameCustomLabel>
            <castateparksscp-custom-form-label label="Principal Investigator Name" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="CellPhone"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitCellPhoneCustomLabel">
        </esa-material-ff>
        <ng-template #permitCellPhoneCustomLabel>
            <castateparksscp-custom-form-label label="Cell Phone" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="Email"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitEmailCustomLabel">
        </esa-material-ff>
        <ng-template #permitEmailCustomLabel>
            <castateparksscp-custom-form-label label="Email" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="Address1"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitAddress1CustomLabel">
        </esa-material-ff>
        <ng-template #permitAddress1CustomLabel>
            <castateparksscp-custom-form-label label="Address 1" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="Address2"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitAddress2CustomLabel">
        </esa-material-ff>
        <ng-template #permitAddress2CustomLabel>
            <castateparksscp-custom-form-label label="Address 2" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="City"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitCityCustomLabel">
        </esa-material-ff>
        <ng-template #permitCityCustomLabel>
            <castateparksscp-custom-form-label label="City" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="State"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitStateCustomLabel">
        </esa-material-ff>
        <ng-template #permitStateCustomLabel>
            <castateparksscp-custom-form-label label="State" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="ZipCode"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitZipCodeCustomLabel">
        </esa-material-ff>
        <ng-template #permitZipCodeCustomLabel>
            <castateparksscp-custom-form-label label="Zip Code" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="Country"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="permitUpsertDto.Name"
            [customLabel]="permitCountryCustomLabel">
        </esa-material-ff>
        <ng-template #permitCountryCustomLabel>
            <castateparksscp-custom-form-label label="Country" fieldDefinitionType="PermitCountry"></castateparksscp-custom-form-label>
        </ng-template>
    </div>
<!--    -->
<!--    -->
<!--    <div class="g-col-6">-->
<!--        <esa-material-ff-->
<!--            name="Name"-->
<!--            [editMode]="editMode"-->
<!--            [required]="true"-->
<!--            type="text"-->
<!--            [(ngModel)]="permitUpsertDto.Name"-->
<!--            [customLabel]="permitNameCustomLabel">-->
<!--        </esa-material-ff>-->
<!--        <ng-template #permitNameCustomLabel>-->
<!--            <castateparksscp-custom-form-label label="Name" fieldDefinitionType="PermitName"></castateparksscp-custom-form-label>-->
<!--        </ng-template>-->
<!--    </div>-->

<!--    <div class="g-col-6">-->
<!--        <esa-material-ff-->
<!--            name="PermitDate"-->
<!--            [editMode]="editMode"-->
<!--            type="date"-->
<!--            [(ngModel)]="permitUpsertDto.PermitDate"-->
<!--            [customLabel]="PermitDateCustomLabel">-->
<!--        </esa-material-ff>-->
<!--        <ng-template #PermitDateCustomLabel>-->
<!--            <castateparksscp-custom-form-label label="Date" fieldDefinitionType="PermitDate"></castateparksscp-custom-form-label>-->
<!--        </ng-template>-->
<!--    </div>-->

<!--    <div class="g-col-12">-->
<!--        <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">-->
<!--            <castateparksscp-custom-form-label label="Description" fieldDefinitionType="PermitDescription"></castateparksscp-custom-form-label>-->
<!--            <div class="value" *ngIf="editMode">-->
<!--                <div class="grid-12">-->
<!--                    <div class="g-col-12">-->
<!--                        <tinymce-editor #tinyMceEditor name="Description" [(content)]="permitUpsertDto.Description"></tinymce-editor>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div-->
<!--                [innerHTML]="permitUpsertDto.Description === null ? '-' : (permitUpsertDto.Description | bypassHtml)"-->
<!--                class="value-display"-->
<!--                *ngIf="!editMode"></div>-->
<!--        </div>-->
<!--    </div>-->

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <esa-material-button
            style="margin-right: 1rem"
            type="primary"
            label="Save"
            icon="save"
            [tooltip]="!permitForm.form.valid ? 'You are missing required fields.' : ''"
            (click)="saveForm(form)"
            [disabled]="!permitForm.form.valid">
        </esa-material-button>

        <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
    </div>
</form>
