import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PermissionEnum} from '../shared/generated/enum/permission-enum';
import {RightsEnum} from '../shared/models/enums/rights.enum';
import {AuthenticationService} from './authentication.service';
import {UserDto} from '../shared/generated/model/user-dto';
import {DistrictSimpleDto} from "../shared/generated/model/district-simple-dto";

@Injectable({
    providedIn: 'root'
})
export class CurrentDistrictService {
    currentDistrict$ = new BehaviorSubject<DistrictSimpleDto>(null);

    constructor(private authenticationService: AuthenticationService) {
    }

    setCurrentDistrict(district: DistrictSimpleDto): void {
        this.currentDistrict$.next(district);
    }

    getCurrentDistrict(): Observable<any> {
        return this.currentDistrict$.asObservable();
    }

    canEditCurrentDistrict(currentUser: UserDto, district: DistrictSimpleDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.DistrictRights, RightsEnum.Update);
    }
}
