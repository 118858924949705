import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchProjectFileTypeEnum} from "../../shared/generated/enum/research-project-file-type-enum";
import {ResearchProjectInternalFieldsDto} from "../../shared/generated/model/research-project-internal-fields-dto";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {finalize, forkJoin, throwError} from "rxjs";
import {
    InternalReviewAssigneeFormComponent
} from "./internal-revew-internal-fields-form/internal-review-assignee-form.component";
import {BasicModalComponent} from "../basic/basic-modal/basic-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {ResearchProjectStatusTypeEnum} from "../../shared/generated/enum/research-project-status-type-enum";
import {UnsignedPermitFormComponent} from "./unsigned-permit-form/unsigned-permit-form.component";
import {ResearchProjectFileService} from "../../shared/generated/api/research-project-file.service";
import {ResearchProjectDto} from "../../shared/generated/model/research-project-dto";
import {
    ResearchProjectFileListComponent
} from "../research-project-file/research-project-file-list/research-project-file-list.component";
import {FileService} from "../../shared/services/file/file.service";
import {InlineLoadingState, TableHeaderItem, TableItem, TableModel} from "carbon-components-angular";
import {
    ResearchProjectBaseComponentComponent
} from "../research-project/shared/research-project-base-component/research-project-base-component.component";
import {AlertService} from "../../shared/services/alert.service";
import {AuthenticationService} from "../../services/authentication.service";
import {
    ResearchProjectFileListManagerComponent
} from "../research-project-file/research-project-file-list-manager/research-project-file-list-manager.component";
import {Alert} from "../../shared/models/alert";
import {AlertContext} from "../../shared/models/enums/alert-context.enum";
import {ResearchProjectInternalNotesDto} from "../../shared/generated/model/research-project-internal-notes-dto";
import {InternalNotesFormComponent} from "./internal-notes-form/internal-notes-form.component";
import {UserDto} from "../../shared/generated/model/user-dto";
import {RoleEnum} from "../../shared/generated/enum/role-enum";
import {
    ResearchProjectAssociatedDistrictContactDto
} from "../../shared/generated/model/research-project-associated-district-contact-dto";
import {ResourceCategoryTagFormComponent} from "./resource-category-tag-form/resource-category-tag-form.component";
import {ResearchProjectTagService} from "../../shared/generated/api/research-project-tag.service";
import {ResearchProjectTagDto} from "../../shared/generated/model/research-project-tag-dto";
import {
    ReturnedToResearcherNotificationComponent
} from "../research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {catchError, switchMap} from "rxjs/operators";
import {PermitWorkflowChangeListComponent} from "./permit-workflow-change-list/permit-workflow-change-list.component";
import {ResearchProjectRenewedFromDto} from "../../shared/generated/model/research-project-renewed-from-dto";
import {Title} from "@angular/platform-browser";
import {ViewportScroller} from "@angular/common";

@Component({
    selector: 'castateparksscp-internal-review',
    templateUrl: './internal-review.component.html',
    styleUrl: './internal-review.component.css'
})
export class InternalReviewComponent extends ResearchProjectBaseComponentComponent implements OnInit {
    pageSpecificTitle = 'Internal Review';
    readonly stepIndex = 6;
    researchProjectID: number;
    fileRoute: string;
    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;
    internalFieldsDto: ResearchProjectInternalFieldsDto;
    internalNotes: ResearchProjectInternalNotesDto;
    public project: ResearchProjectDto;
    tags: ResearchProjectTagDto[];
    isLoading: boolean;
    isGenerating: boolean = false;
    isApprovalInProgress: boolean = false;
    isMarkAwaitingAnnualReportInProgress: boolean = false;
    private currentUser: UserDto;
    renewedFromResearchProject : ResearchProjectRenewedFromDto;

    @ViewChild("internalReviewAssigneeForm", {static: false})
    public internalReviewAssigneeForm: InternalReviewAssigneeFormComponent;
    @ViewChild("unsignedPermitForm", {static: false})
    public unsignedPermitForm: UnsignedPermitFormComponent;
    @ViewChild("additionalInformationRequestedModal", {static: false})
    public additionalInformationModal: BasicModalComponent;
    @ViewChild("signedPermitFileManager")
    public signedPermitFileManager: ResearchProjectFileListManagerComponent;
    @ViewChild("amendmentSupportingDocFileManager")
    public amendmentSupportingDocFileManager: ResearchProjectFileListManagerComponent;
    @ViewChild("amendmentFinalLetterFileManager")
    public amendmentFinalLetterFileManager: ResearchProjectFileListManagerComponent;
    @ViewChild("researchProjectFileList", {static: false})
    public researchProjectFileManager: ResearchProjectFileListManagerComponent;
    @ViewChild("internalNotesForm", {static: false})
    public internalNotesForm: InternalNotesFormComponent;
    @ViewChild("tagForm", {static: false})
    public tagForm: ResourceCategoryTagFormComponent;
    @ViewChild("notificationComponent", {static: false})
    public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;
    @ViewChild("workflowChangesList", {static: false})
    public workflowChangesList: PermitWorkflowChangeListComponent;

    errorState = InlineLoadingState.Hidden;
    loadingState = InlineLoadingState.Active;
    districtContacts: ResearchProjectAssociatedDistrictContactDto[];
    restrictedStatuses = [ResearchProjectStatusTypeEnum.ActivePermit,
        ResearchProjectStatusTypeEnum.Expired, ResearchProjectStatusTypeEnum.Rejected,
        ResearchProjectStatusTypeEnum.Withdrawn];
    constructor(private researchProjectService: ResearchProjectService,
                private alertService: AlertService,
                private router: Router,
                public route: ActivatedRoute,
                public cdr: ChangeDetectorRef,
                public researchProjectFileService: ResearchProjectFileService,
                public researchProjectTagService: ResearchProjectTagService,
                private fileService: FileService,
                public dialog: MatDialog,
                public authenticationService: AuthenticationService,
                public titleService: Title,
                private viewportScroller: ViewportScroller
    ) {
        super(titleService);
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        this.fileRoute = `researchProjects/${this.researchProjectID}`;
        this.viewportScroller.setOffset([0, 200]);

        forkJoin({
            currentUser: this.authenticationService.getCurrentUser(),
            internal: this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID),
            internalNotes: this.researchProjectService.researchProjectsResearchProjectIDInternalNotesGet(this.researchProjectID),
            project: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
            districtContacts: this.researchProjectService.researchProjectsResearchProjectIDAssociatedDistrictContactsGet(this.researchProjectID),
            renewedFromResearchProject: this.researchProjectService.researchProjectsResearchProjectIDRenewedFromResearchProjectGet(this.researchProjectID)
        })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(({currentUser, project, internal, internalNotes, districtContacts, renewedFromResearchProject}) => {
                this.currentUser = currentUser;
                this.project = project;
                this.setTitle(project);
                this.internalFieldsDto = internal;
                this.internalNotes = internalNotes;
                this.districtContacts = districtContacts;

                let model = new TableModel();
                model.header = [
                    new TableHeaderItem({
                        data: "District Staff Assigned",
                    }),
                    new TableHeaderItem({
                        data: "District",
                    })
                ];
                let t = [];
                districtContacts.forEach(dto => {
                    t.push(
                        [new TableItem({data: dto.Name}), new TableItem({data: dto.DistrictName})]
                    );
                });
                model.data = [...t];

                this.cdsTable.model = model;
                this.renewedFromResearchProject = renewedFromResearchProject;
            });
    }

    cdsTable = {
        title: 'Assigned District Contacts',
        description: 'District contacts associated with the parks selected for application.',
        stickyHeader: true,
        model: null,
        batchText: '',
        size: 'sm',
    }

    approve(): void {
        this.isApprovalInProgress = true;
        this.researchProjectService.researchProjectsResearchProjectIDApprovePut(this.researchProjectID)
            .pipe(switchMap(() => forkJoin({
                project: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
                internalFields: this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID)
            })),
                catchError(err => throwError(err)))
            .subscribe({
                next: response => {
                    this.project = response.project;
                    this.internalFieldsDto = response.internalFields;
                    this.returnedToResearcherNotification.setInternalFields(response.internalFields);
                    this.workflowChangesList.refreshData();
                    this.isApprovalInProgress = false;
                    this.alertService.pushAlert(new Alert(`The permit was successfully approved.`, AlertContext.Success));
                },
                error: err => this.isApprovalInProgress = false
            })
    }

    internalFieldsSaved(dto: ResearchProjectInternalFieldsDto) {
        this.internalFieldsDto = dto;
    }

    saveInternalFields(): void {
        this.internalReviewAssigneeForm.save();
    }

    generate() {
        this.isGenerating = true;
        this.errorState = InlineLoadingState.Active;
        if (this.restrictedStatuses.includes(this.project?.ResearchProjectStatusTypeID) || !this.canEditPermitDatesAndCeqa()) {
            this.generateUnsignedPermit(this.project);
            return;
        }
        this.unsignedPermitForm.save();
    }

    generateUnsignedPermit(updatedDto: ResearchProjectDto) {
        if (!updatedDto) {
            this.isGenerating = false;
            this.errorState = InlineLoadingState.Hidden;
            return;
        }
        this.project = updatedDto;
        this.researchProjectFileService.researchProjectFilesResearchProjectIDUnsignedFinalLetterGet(this.researchProjectID)
            .subscribe({
                next: response => {
                    this.isGenerating = false;
                    this.errorState = InlineLoadingState.Hidden;
                    ResearchProjectFileListComponent.downloadFile(this.fileService, this.fileRoute, response);
                    this.researchProjectFileManager.refreshData();
                },
                error: err => {
                    this.errorState = InlineLoadingState.Error;
                },
                complete: () => {
                    this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID).subscribe(res => {this.researchProject = res});
                }
            });
    }

    isApproveButtonDisabled() {
        if (this.isRestrictedStatusOrApprovalInProgress()) return true;
        if (this.renewedFromResearchProject?.AnnualReportMissing) return true;
        if (this.project?.AmendedFromPermitNumber) {
            return !this.amendmentFinalLetterFileManager?.uploadedFilesExist();
        }
        if (!this.signedPermitFileManager?.uploadedFilesExist()) return true;
        if (this.isReturnedToResearcher()) return true;
        return !this.project.PermitEndDate || !this.project.PermitStartDate;
    }

    isRestrictedStatusOrApprovalInProgress() {
        if (this.restrictedStatuses.includes(this.project?.ResearchProjectStatusTypeID)) return true;
        return !!this.isApprovalInProgress;
    }

    handleDocumentChange() {
        this.researchProjectFileManager.refreshData();
    }

    handleStepSelect(route: string): void {
        this.router.navigateByUrl(route).then(x => {});
    }

    refreshInternalNotes($event: any) {
        this.internalNotes = $event;
        this.internalNotesForm.editMode = false;
    }

    enterInternalNotesEditMode() {
        this.internalNotesForm.editMode = true;
    }

    showInternalNotesEditButton() {
        if (!this.internalNotesForm) return false;
        return !this.internalNotesForm.editMode;
    }

    handleInternalNotesEditCancel() {
        this.internalNotesForm.editMode = false;
    }

    handleTagSave(value) {
        this.tagForm.ngOnInit();
    }
    handleTagFormChanged(value) {
        this.tagForm.save();
    }
    handleTagRemove(researchProjectTagID) {
        this.researchProjectTagService.researchProjectTagsResearchProjectTagIDDelete(researchProjectTagID)
            .subscribe(() => this.tagForm.ngOnInit());
    }

    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => {
                this.project = res;
            });
        this.returnedToResearcherNotification.refreshInternalFields();
        this.workflowChangesList.refreshData();
    }

    displayGenerateButton() {
        if (this.project && this.project.AmendedFromPermitNumber) return false;
        return this.project?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Withdrawn &&
            this.project?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Rejected &&
            this.project?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Draft;
    }

    getGenerateButtonLabel() {
        if (this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher
            || this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.InReview
            || this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.AwaitingAnnualReport
        ) {
            return "Save Dates and Generate Unsigned Permit";
        }
        return "Generate Unsigned Permit";
    }

    handleSpecialConditionsEditEvent() {
        this.workflowChangesList.refreshData();
    }

    markAwaitingAnnualReport() {
        this.isMarkAwaitingAnnualReportInProgress = true;
       this.researchProjectService.researchProjectsResearchProjectIDMarkAwaitingAnnualReportPut(this.researchProjectID)
           .pipe(switchMap(() => forkJoin({
                   project: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
                   internalFields: this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID)
               })),
               catchError(err => throwError(err)))
           .subscribe({
               next: response => {
                   this.project = response.project;
                   this.internalFieldsDto = response.internalFields;
                   this.returnedToResearcherNotification.setInternalFields(response.internalFields);
                   this.workflowChangesList.refreshData();
                   this.isMarkAwaitingAnnualReportInProgress = false;
                   this.alertService.pushAlert(new Alert(`The permit was successfully marked as Awaiting Annual Report.`, AlertContext.Success));
               },
               error: err => this.isMarkAwaitingAnnualReportInProgress = false
           })
    }

    isMarkAwaitingAnnualReportButtonDisabled() {
        if (this.isMarkAwaitingAnnualReportInProgress) return true;
        if (this.project.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.AwaitingAnnualReport) return true;
        if (!this.signedPermitFileManager?.uploadedFilesExist()) return true;
        return !this.renewedFromResearchProject?.AnnualReportMissing;
    }

    getApprovalHeader() {
        if (!!this.project?.AmendedFromPermitNumber) return 'Amendment Approval';
        if (!!this.project) return 'Permit Approval';
        return "";
    }

    isSystemAdmin() {
        return this.currentUser?.Role?.RoleID == RoleEnum.SystemAdmin;
    }

    isTechnicalReviewerRole() {
        return this.currentUser?.Role?.RoleID == RoleEnum.TechnicalReviewer;
    }

    isAssignedTechnicalReviewer() {
        return this.project?.AssignedTechnicalReviewerUserID == this.currentUser?.UserID;
    }

    isDistrictStaffRole() {
        return this.currentUser?.Role?.RoleID == RoleEnum.DistrictStaff;
    }

    isAssignedDistrictStaff() {
        return this.project?.UserIsAssociatedDistrictContact;
    }

    isDraft() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft;
    }

    isReturnedToResearcher() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher;
    }

    isWithdrawn() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Withdrawn;
    }

    isActive() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ActivePermit;
    }

    isAwaitingAnnualReport() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.AwaitingAnnualReport;
    }

    isRejected() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Rejected;
    }

    isInReview() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.InReview;
    }

    isExpired() {
        return this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Expired;
    }

    canReadAssignee(): boolean {
        if (this.isSystemAdmin() || this.isTechnicalReviewerRole()) return !this.isDraft();
        if (this.isAssignedDistrictStaff()) return !this.isDraft() && !this.isWithdrawn();
        if (this.isDistrictStaffRole()) return !this.isDraft() && !this.isWithdrawn() && !this.isInReview() && !this.isReturnedToResearcher();
        return false;
    }

    canEditAssignee(): boolean {
        return (this.isAssignedTechnicalReviewer() || this.isSystemAdmin()) && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport());

    }

    canReadTags(): boolean {
        return !this.isDraft()
            && !(this.isDistrictStaffRole() && this.isWithdrawn());
    }

    canEditTags(): boolean {
        return (this.isAssignedTechnicalReviewer() || this.isSystemAdmin());

    }

    canReadPermitDatesAndCeqa(): boolean {
        if (this.isSystemAdmin() || this.isTechnicalReviewerRole()) return true;
        if (this.isAssignedDistrictStaff()) return !this.isDraft() && !this.isWithdrawn();
        if (this.isDistrictStaffRole()) return !this.isDraft() && !this.isWithdrawn() && !this.isInReview() && !this.isReturnedToResearcher();
        return false;
    }

    canEditPermitDatesAndCeqa() {
        if (this.isApprovalInProgress) return false;
        return !this.project?.AmendedFromPermitNumber
        && (
                (this.isSystemAdmin() || this.isAssignedTechnicalReviewer()) && (this.isInReview() || this.isAwaitingAnnualReport())
            );
    }

    canReadGenerateButton(): boolean {
        return (this.isSystemAdmin() && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport()))
            || (this.isAssignedTechnicalReviewer() && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport()));

    }

    isDisabledGenerateButton(): boolean {
        if (this.isGenerating) return true;
        if (this.isApprovalInProgress) return true;
        if (this.unsignedPermitForm?.form?.invalid) return true;
        return (this.isTechnicalReviewerRole() && this.isReturnedToResearcher()) || (this.isSystemAdmin() && this.isReturnedToResearcher());
    }

    canReadUploadSigned(): boolean {
        return (this.isSystemAdmin() && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport()))
                || (this.isTechnicalReviewerRole() && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport()));
    }

    canEditUploadSigned(): boolean {
        return (this.isSystemAdmin() && (this.isInReview() || this.isAwaitingAnnualReport()))
            || (this.isAssignedTechnicalReviewer() && (this.isInReview() || this.isAwaitingAnnualReport()));

    }

    canReadInternalNotes(): boolean {
        if (this.isSystemAdmin() || this.isTechnicalReviewerRole()) return true;
        if (this.isAssignedDistrictStaff()) return !this.isDraft() && !this.isWithdrawn();
        if (this.isDistrictStaffRole()) return !this.isDraft() && !this.isWithdrawn() && !this.isInReview() && !this.isReturnedToResearcher();
        return false;
    }

    canEditInternalNotes() {
        return (this.isSystemAdmin())
            || (this.isAssignedTechnicalReviewer() && (this.isInReview() || this.isAwaitingAnnualReport() || this.isReturnedToResearcher()))
            || (!this.isAssignedTechnicalReviewer() && this.isTechnicalReviewerRole() && !(this.isInReview() || this.isAwaitingAnnualReport() || this.isReturnedToResearcher()));
    }

    canReadHQSpecialConditions(): boolean {
        if (this.isSystemAdmin() || this.isTechnicalReviewerRole()) return true;
        if (this.isAssignedDistrictStaff()) return !this.isDraft() && !this.isWithdrawn();
        if (this.isDistrictStaffRole()) return !this.isDraft() && !this.isWithdrawn() && !this.isInReview() && !this.isReturnedToResearcher();
        return false;
    }

    canEditHQSpecialConditions(): boolean {
        return (this.isSystemAdmin() && (this.isReturnedToResearcher() || this.isInReview() || this.isAwaitingAnnualReport()))
            || (this.isAssignedTechnicalReviewer() && (this.isReturnedToResearcher() || this.isInReview() || this.isAwaitingAnnualReport()));

    }

    canReadDistrictSpecialConditions(): boolean {
        return (this.isSystemAdmin())
            || (this.isTechnicalReviewerRole())
            || (this.isAssignedDistrictStaff() && (this.isInReview() || this.isReturnedToResearcher()))
            || (this.isDistrictStaffRole() && (this.isActive() || this.isRejected() || this.isExpired() || this.isAwaitingAnnualReport()));

    }

    canEditDistrictSpecialConditions(): boolean {
        return (this.isSystemAdmin() && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport()))
            || (this.isAssignedTechnicalReviewer() && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport()))
            || (this.isAssignedDistrictStaff() && (this.isInReview() || this.isReturnedToResearcher()));
    }

    isUploadSupportingDocumentationDisabled() {
        if (this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft ||
            this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Rejected ||
            this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Withdrawn) return true;
        return !this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([1]) &&
                this.internalFieldsDto?.TechnicalReviewerUserID != this.currentUser?.UserID &&
                !this.internalFieldsDto.CurrentUserIsAssociatedDistrictStaff;
    }

    handleWithdraw(): void {
        throw new Error('Method not implemented.');
    }

    handlePermitApprovalSectionExists() {
        const fragment = this.route.snapshot.fragment;
        if (fragment) {
            this.viewportScroller.scrollToAnchor(fragment);
        }
    }

    scrollToPermitApproval() {
        const permitApprovalSection = document.getElementById("permit-approval");
        permitApprovalSection?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    canViewApproveButton() {
        return ((this.isSystemAdmin() || this.isAssignedTechnicalReviewer()) && (this.isInReview() || this.isReturnedToResearcher() || this.isAwaitingAnnualReport()));
    }
}
