<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="projectDtoRaw"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <h2>{{title}}</h2>
        <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>
        <castateparksscp-readonly-notification [show]="!canEditPage"></castateparksscp-readonly-notification>
        <castateparksscp-public-instructional-text *ngIf="!!projectDtoRaw"></castateparksscp-public-instructional-text>
        <h4>Specimen Collection</h4>
        <castateparksscp-research-project-specimen-collection-flag-form
            #researchProjectSpecimenCollectionFlagForm
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (flagChanged)="handleInvolvesSpecimenCollection($event)"
            [errors]="errors">
        </castateparksscp-research-project-specimen-collection-flag-form>

        <div [hidden]="!researchProjectDto?.SpecimenCollection">
            <castateparksscp-research-project-specimen-collection-list
                [entryRequired]="true"
                [researchProjectID]="researchProjectID">
            </castateparksscp-research-project-specimen-collection-list>
            <castateparksscp-research-project-collection-rationale-form
                #researchProjectCollectionRationaleForm
                [isLoading]="isLoading"
                [isCDS]="true"
                [dto]="researchProjectDto"
                [editMode]="canEditPage"
                [isHideActionButtons]="true"
                [errors]="errors">
            </castateparksscp-research-project-collection-rationale-form>
            <castateparksscp-research-project-file-list-manager
                [title]="'Upload optional supporting documentation.'"
                [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.CollectionRationaleSupportingDocumentation"
                [researchProjectID]="researchProjectID"
            ></castateparksscp-research-project-file-list-manager>
            <br/>
            <castateparksscp-research-project-specimen-collection-form
                #researchProjectSpecimenCollectionForm
                [isLoading]="isLoading"
                [isCDS]="true"
                [dto]="researchProjectDto"
                [editMode]="canEditPage"
                [isHideActionButtons]="true"
                [errors]="errors">
            </castateparksscp-research-project-specimen-collection-form>
        </div>

        <h4>Laboratory Work</h4>
        <castateparksscp-research-project-laboratory-work-flag-form
            #researchProjectLaboratoryWorkFlagForm
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (flagChanged)="handleInvolvesLaboratoryWork($event)"
            [errors]="errors">
        </castateparksscp-research-project-laboratory-work-flag-form>

        <div [hidden]="!researchProjectDto?.LaboratoryWork">
            <br/>
            <castateparksscp-research-project-laboratory-work-form
                #researchProjectLaboratoryWorkForm
                [isLoading]="isLoading"
                [isCDS]="true"
                [dto]="researchProjectDto"
                [editMode]="canEditPage"
                [isHideActionButtons]="true"
                [errors]="errors">
            </castateparksscp-research-project-laboratory-work-form>
        </div>

        <h4>Additional Information</h4>
        <castateparksscp-research-project-study-procedures-form
            #researchProjectStudyProceduresForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            [errors]="errors">
        </castateparksscp-research-project-study-procedures-form>

        <castateparksscp-research-project-file-list-manager
            [title]="'Upload optional supporting documentation.'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.StudyProcedureSupportingDocumentation"
            [researchProjectID]="researchProjectID"
            [disabled]="!canEditPage"
        ></castateparksscp-research-project-file-list-manager>
        <castateparksscp-research-project-data-products-form
            #researchProjectDataProductsForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            [errors]="errors">
        </castateparksscp-research-project-data-products-form>

        <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float:right; display: flex; flex-direction: row-reverse">
            <button cdsButton size="xl" (click)="saveAndContinue()" [disabled]="isSaveInProgress">Save and Continue
                <castateparksscp-right-arrow></castateparksscp-right-arrow>
            </button>
            <button *ngIf="displayWithdrawButton()" size="xl" cdsButton="ghost" (click)="handleWithdraw()">Withdraw application</button>
        </div>
    </div>

</div>
