<div class="detail-view">
    <ng-container *ngIf="contact$ | async as c">
        <castateparksscp-contact-form
            #updateContactForm
            [isPublicUser]="isPublicUser()"
            [ID]="c.ContactID"
            [isCDS]="true"
            [dto]="c"
            [editMode]="true"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"></castateparksscp-contact-form>
    </ng-container>
</div>
