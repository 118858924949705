import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from '../../basic/basic-list/basic-list.component';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {Router} from "@angular/router";
import {AuthenticationService} from 'src/app/services/authentication.service';
import {DateColumnCreatorService} from 'src/app/shared/services/date-column-creator/date-column-creator.service';
import {AlertService} from 'src/app/shared/services/alert.service';
import {
    InLineEditButtonsRendererComponent
} from 'src/app/shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component';
import {GetRowIdFunc, GetRowIdParams} from 'ag-grid-community';
import {ResearchProjectFieldInvestigationOccurrenceSimpleDto} from 'src/app/shared/generated/model/models';
import {MatDialog} from '@angular/material/dialog';
import {
    ResearchProjectFieldInvestigationOccurrenceService
} from "../../../shared/generated/api/research-project-field-investigation-occurrence.service";
import {RequiredHeaderComponent} from "../../../shared/components/ag-grid/required-header/required-header.component";

@Component({
    selector: 'castateparksscp-research-project-field-investigation-occurrence-list',
    templateUrl: '../../basic/basic-list/basic-list.component.html',
    styleUrl: './research-project-field-investigation-occurrence-list.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResearchProjectFieldInvestigationOccurrenceListComponent extends BasicListComponent implements OnInit {
    @Input() researchProjectID: number;

    isH4Title: boolean = true;

    listPage: ListPage = {
        permission: PermissionEnum.ResearchProjectRights,
        pageTitle: 'Field Investigation Occurrence',
        createButtonLabel: 'Field Investigation Occurrence',
        downloadFileName: 'researchProjectFieldInvestigationOccurrences',
        isShowAddButton: true,
        iSShowTitle: true,
        gridTableStyle: 'height: 150px',
        hints: 'Provide each start and end date per field investigation occurrence in park lands.',
        defaultColDef: {
            sortable: true,
            filter: false,
            resizable: true,
            floatingFilter: false,
            suppressMenu: true,
        },
        isHideDownload: true,
        headerText: "<cds-text-label>Always contact the park before actual site visits as described in the final permit. Click on save icon (<span class=\"text-muted fas fa-save\"></span>) to save any grid edits.</cds-text-label>",
        headerTextClass: 'copy-4',
        isShowHeader: true,
        headerTextNoMargin: true
    } as ListPage;

    useInGridEdit: boolean = true;
    private getCanEdit() { return this.canEdit };

    constructor(
        public researchProjectFieldInvestigationOccurrenceService: ResearchProjectFieldInvestigationOccurrenceService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.researchProjectFieldInvestigationOccurrenceService.researchProjectFieldInvestigationOccurrencesResearchProjectsResearchProjectIDGet(this.researchProjectID);
        const dateConfigMap = new Map<string, any>(
            [
                ['flex', 1],
                ['width', null],
                ['headerComponentParams', { required: true }]
            ]);
        this.listPage.columnDefs = [
            {
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                            editableCallback: this.getCanEdit.bind(this),
                        },
                    };
                },
                field: "ResearchProjectFieldInvestigationOccurrenceID",
                width: 100,
                pinned: 'left',
                filterable: false,
                headerName: "",
                editable: false,
            },
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Start Date",
                "StartDate",
                "M/dd/YYYY",
                dateConfigMap
            ),
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "End Date",
                "EndDate",
                "M/dd/YYYY",
                dateConfigMap
            ),
        ]
    }

    public idField: string = 'ResearchProjectFieldInvestigationOccurrenceID';
    public startEditingColKey: string = 'ResearchProjectFieldInvestigationOccurrenceID';

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ResearchProjectFieldInvestigationOccurrenceSimpleDto> | any) => params.data.ResearchProjectFieldInvestigationOccurrenceID;
    public putAction = (id: number, dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectFieldInvestigationOccurrenceService.researchProjectFieldInvestigationOccurrencesResearchProjectFieldInvestigationOccurrenceIDPut(id, dto);
    }
    public postAction = (dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectFieldInvestigationOccurrenceService.researchProjectFieldInvestigationOccurrencesPost(dto);
    }

    public deleteAction = (id: number) => {
        return this.researchProjectFieldInvestigationOccurrenceService.researchProjectFieldInvestigationOccurrencesResearchProjectFieldInvestigationOccurrenceIDDelete(id);
    }

    ngOnInit() {
        super.ngOnInit();
        this.gridComponents['agColumnHeader'] = RequiredHeaderComponent;
    }
}
