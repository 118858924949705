import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import { BasicFormComponent, FormPage } from '../shared/basic-form/basic-form.component';

@Component({
    selector: 'castateparksscp-basic-create',
    templateUrl: './basic-create.component.html',
    styleUrl: './basic-create.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createForm", {read: BasicFormComponent}) form: BasicFormComponent

    public dto: any;
    public upsertDto: any;
    private createResponse: any;

    editMode = true;

    public createPage: CreatePage;
    public formPage: FormPage;

    constructor(
        public router: Router,
        public cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.upsertDto = this.createPage.dtoCreator();
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    save(result): void {
        if (!!result) {
            this.createResponse = result;
            this.router.navigateByUrl(this.createPage.getSuccessRoute(result));
        } else {
            this.cdr.detectChanges();
        }
    }

    cancelEdit() {
        this.router.navigateByUrl(this.createPage.cancelRoute);
    }

    canExit() {
        if (this.createResponse) {
            return true;
        } else {
            return JSON.stringify(this.upsertDto) === JSON.stringify(this.form.upsertDto);
        }
    }
}

export interface CreatePage {
    cancelRoute: string;
    saveSuccessRoute: string;
    dtoName: string;
    getSuccessRoute: Function;
    dtoCreator: Function;
}
