<h2 mat-dialog-title>{{ data.header }}</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="!!data.showNotification && data.showNotification()">
        <cds-inline-notification [notificationObj]="{
        type: 'info',
        lowContrast: true,
        showClose: false,
        title: data.notificationTitle,
        message: data.notificationMessage
        }"
        [style]="'margin-top: 10px; margin-bottom: 10px'"
        ></cds-inline-notification>
    </ng-container>
    <p *ngIf="data.content" style="margin-bottom: 1rem"><span [innerHTML]="data.content"></span></p>
    <ng-container *ngIf="!!dto">
        <castateparksscp-basic-form
            #form
            [ID]="ID"
            [isCDS]="true"
            [dto]="dto"
            [editMode]="canEdit()"
            (formSubmitted)="handleFormSubmitEvent($event)"
            [formPage]="formPage"
            [isHideActionButtons]="true"
            [isLoading]="false"
        >
        </castateparksscp-basic-form>
    </ng-container>
</mat-dialog-content>
<ng-container *ngIf="showActions">
    <cds-modal-footer>
        <button mat-dialog-close cdsButton="ghost">
            Cancel
        </button>
        <button [disabled]="isFormInvalid() || (!!data.saveButtonDisabled && data.saveButtonDisabled())" cdsButton="{{saveButtonClass}}" (click)="saveButtonClicked($event)">
            {{saveButtonLabel}}
        </button>
    </cds-modal-footer>

</ng-container>
