export const ResearchProjectCategoryTypeDisplayNameToIdMap: Map<string, number> = new Map(
    [
        ['Aesthetics (e.g. sense of place, lightscape, soundscape, odor)', 1],
        ['Air Resources', 2],
        ['Animal Resources', 3],
        ['Freshwater Aquatic Resources (e.g. riparian ecosystems, floodplains)', 4],
        ['Geologic Resources', 5],
        ['Marine Aquatic Resources (e.g. tidepools, coastal wetlands)', 6],
        ['Paleontological Resources', 7],
        ['Plant Resources', 8],
        ['Soil Resources', 9],
        ['Water Resources (e.g. water quality)', 10],
        ['Wildfire', 11],
        ['Other', 12],
    ])

export const ResearchProjectCategoryTypeIdToDisplayNameMap: Map<number, string> = new Map(
    [
        [1, 'Aesthetics (e.g. sense of place, lightscape, soundscape, odor)'],
        [2, 'Air Resources'],
        [3, 'Animal Resources'],
        [4, 'Freshwater Aquatic Resources (e.g. riparian ecosystems, floodplains)'],
        [5, 'Geologic Resources'],
        [6, 'Marine Aquatic Resources (e.g. tidepools, coastal wetlands)'],
        [7, 'Paleontological Resources'],
        [8, 'Plant Resources'],
        [9, 'Soil Resources'],
        [10, 'Water Resources (e.g. water quality)'],
        [11, 'Wildfire'],
        [12, 'Other'],
    ])

export const ResearchProjectCategoryTypeIdAndDisplayNameArray: Array<{ text: string, value: number }> = [

    {text: 'Aesthetics (e.g. sense of place, lightscape, soundscape, odor)', value: 1},
    {text: 'Air Resources', value: 2},
    {text: 'Animal Resources', value: 3},
    {text: 'Freshwater Aquatic Resources (e.g. riparian ecosystems, floodplains)', value: 4},
    {text: 'Geologic Resources', value: 5},
    {text: 'Marine Aquatic Resources (e.g. tidepools, coastal wetlands)', value: 6},
    {text: 'Paleontological Resources', value: 7},
    {text: 'Plant Resources', value: 8},
    {text: 'Soil Resources', value: 9},
    {text: 'Water Resources (e.g. water quality)', value: 10},
    {text: 'Wildfire', value: 11},
    {text: 'Other', value: 12},
]
