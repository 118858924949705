import {Component, ElementRef, ViewChild} from '@angular/core';
import {IHeaderAngularComp} from "ag-grid-angular";
import {IHeaderParams} from 'ag-grid-community';

export interface ICustomHeaderParams {
    required?: boolean;
}
@Component({
    selector: 'castateparksscp-required-header',
    templateUrl: './required-header.component.html',
    styleUrl: './required-header.component.css'
})
export class RequiredHeaderComponent implements IHeaderAngularComp {
    public params!: IHeaderParams & ICustomHeaderParams;
    public sorted: string;

    agInit(params: IHeaderParams & ICustomHeaderParams): void {
        this.params = params;

        params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.onSortChanged();
    }

    onSortChanged() {
        if (this.params.column.isSortAscending()) {
            this.sorted = 'asc'
        } else if (this.params.column.isSortDescending()) {
            this.sorted = 'desc'
        } else {
            this.sorted = ''
        }
    };

    onSortRequested(event) {
        this.params.progressSort(event.shiftKey);
    };

    refresh(params: IHeaderParams) {
        return false;
    }
}
