import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from "../../basic/basic-list/basic-list.component";
import {PermissionEnum} from "../../../shared/generated/enum/permission-enum";
import {AuthenticationService} from "../../../services/authentication.service";
import {DateColumnCreatorService} from "../../../shared/services/date-column-creator/date-column-creator.service";
import {Router} from "@angular/router";
import {AlertService} from "../../../shared/services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {PermitWorkflowChangeService} from "../../../shared/generated/api/permit-workflow-change.service";
import {
    CustomDropdownFilterComponent
} from "../../../shared/components/custom-dropdown-filter/custom-dropdown-filter.component";

@Component({
    selector: 'castateparksscp-permit-workflow-change-list',
    templateUrl: '../../basic/basic-list/basic-list.component.html',
    styleUrl: './permit-workflow-change-list.component.css'
})
export class PermitWorkflowChangeListComponent extends BasicListComponent implements OnInit {
    @Input() researchProjectID: number;
    isProjectedTitle: boolean = true;

    listPage: ListPage = {
        permission: PermissionEnum.ResearchProjectRights,
        pageTitle: 'History',
        downloadFileName: 'History',
        iSShowTitle: true,
        gridTableStyle: 'height: 200px',
    } as ListPage;
    hasListViewPadding = false;
    constructor(
        public permitWorkflowChangeService: PermitWorkflowChangeService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.permitWorkflowChangeService.researchProjectsResearchProjectIDPermitWorkflowChangesGet(this.researchProjectID);
        this.listPage.columnDefs = [
            {
                headerName: "User",
                field: "CreateUserName",
                flex: 1,
                cellClass: 'long-text-cell',
                editable: false
            },
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Date",
                "CreateDate",
                "M/d/YYYY h:mm a",
                new Map<string, any>([['width', 175], ['editable', false]])
            ),
            {
                headerName: "Event",
                field: "DisplayName",
                editable: false,
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "DisplayName",
                },
                cellClass: 'long-text-cell',
            },
        ]
    }

    override onGridReady(gridEvent) {
        this.grid.api.showLoadingOverlay();

        this.getRequest = this.listPage
            .dtosGetter()
            .subscribe((results) => {
                this.rowData = results;
                this.grid.api.hideOverlay();
                this.dtos = results;
                this.cdr.markForCheck();
            });
    }
}
