import {Component, OnInit} from '@angular/core';
import {BasicFormComponent, FormPage} from "../../../basic/shared/basic-form/basic-form.component";
import {CurrentDistrictService} from "../../../../services/current-district.service";
import {DistrictService} from "../../../../shared/generated/api/district.service";
import {AlertService} from "../../../../shared/services/alert.service";
import {DistrictSimpleDto} from "../../../../shared/generated/model/district-simple-dto";
import {StateIdAndDisplayNameArrayUSandCA} from "../../../../shared/models/enums/state.enum.util";

@Component({
    selector: 'castateparksscp-district-form',
    templateUrl: '../../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../../basic/shared/basic-form/basic-form.component.scss'],
})
export class DistrictFormComponent extends BasicFormComponent implements OnInit {
    upsertDto: DistrictSimpleDto;

    stateOptions: Array<{ text: string, value: number }> = StateIdAndDisplayNameArrayUSandCA;

    formPage: FormPage = {
        dtoName: 'district',
        elements: [
            {
                class: 'g-col-6',
                name: 'AddressLine1',
                label: 'Address line 1',
                required: false,
                fieldDefinitionType: 'AddressLine1',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'AddressLine2',
                label: 'Address line 2',
                required: false,
                fieldDefinitionType: 'AddressLine2',
                type: 'text',
            },
            {
                class: 'g-col-4',
                name: 'City',
                label: 'City',
                required: false,
                fieldDefinitionType: 'City',
                type: 'text',
            },
            {
                class: 'g-col-4',
                name: 'StateID',
                label: 'State/Province',
                required: false,
                fieldDefinitionType: 'State',
                selectDisplayFieldName: 'StateDisplayName',
                type: 'select',
                selectOptions: this.stateOptions,
            },
            {
                class: 'g-col-4',
                name: 'PostalCode',
                label: 'Zip code',
                required: false,
                fieldDefinitionType: 'PostalCode',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'PhoneNumber',
                label: 'Phone number',
                required: false,
                fieldDefinitionType: 'PhoneNumber',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'Email',
                label: 'Email',
                required: false,
                fieldDefinitionType: 'Email',
                type: 'text',
            },
        ]
    } as FormPage;

    constructor(public currentDistrictService: CurrentDistrictService,
                public districtService: DistrictService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => dto;
        this.formPage.put = (id, upsertDto) => this.districtService.districtsDistrictIDPut(id, upsertDto);
    }
}
