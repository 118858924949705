import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import {FileService} from "../../services/file/file.service";
import {Observable, Subscription} from "rxjs";
import {FileItem, InlineLoadingState} from "carbon-components-angular";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialog} from "../confirm-dialog/confirm-dialog.component";

@Component({
    selector: "castateparksscp-file-uploader",
    templateUrl: "./file-uploader.component.html",
    styleUrls: ["./file-uploader.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent implements OnInit, OnDestroy {
    @Input() fileRoute: string;
    @Input() buttonSize: 'sm' | 'md' | 'lg' | 'xl' | '2xl' = 'sm';
    @Input() buttonType: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger' = 'primary';
    @Input() disabled: boolean = false;
    @Input() required: boolean = false;
    @Input() limitOneFile: boolean = false;
    @Input() canDeleteFile: boolean = true;
    @Output() hasFilesToUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadStarted: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Input() supportedFileTypes: string[] = [];
    @Input() isShowUploadedFiles: boolean = true;

    public filesToUpload: File[] = [];
    public isLoading: boolean = false;

    uploadSubscription: Subscription;

    // used by CDS components
    @Input() isCDS: boolean = false;
    @Input() title: string = '';
    @Input() titleLinkRoute: string = '';
    @Input() titleLinkLabel: string = '';
    @Input() description: string = '';
    @Input() uploadFileButtonText: string;
    @Input() filesGetter: () => Observable<Array<any>>;
    @Input() fileDelete;
    @Input() researchProjectFileTypeID: number;
    @Output() fileDeleteSuccess: EventEmitter<any> = new EventEmitter<any>();

    protected stagedFileItems = new Set<FileItem>();
    protected uploadedFiles = new Set<FileItem>();
    protected errors: string[] = [];
    private fileNameToBlobNameMap: Map<string, string> = new Map();
    getRequest: Subscription;
    errorState = InlineLoadingState.Error;
    loadingState = InlineLoadingState.Active;
    defaultUploadFileButtonText: string = 'Upload files';

    constructor(private fileService: FileService,
                public dialog: MatDialog,
                private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.loadData();
        if (!this.uploadFileButtonText) {
            this.uploadFileButtonText = this.defaultUploadFileButtonText;
        }
    }

    onFilesSelected(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            this.filesToUpload.push(files.item(i));
        }

        if (this.filesToUpload.length) {
            this.hasFilesToUpload.emit(true);
        }
    }

    spliceFiles(index) {
        this.filesToUpload.splice(index, 1);

        if (!this.filesToUpload.length) {
            this.hasFilesToUpload.emit(false);
        }
    }

    uploadFiles() {
        this.fileUploadStarted.emit(true);
        this.uploadSubscription = this.fileService.uploadFiles(this.fileRoute, this.filesToUpload).subscribe({
            next: (result) => {
                this.fileUploadSuccess.emit({isSuccess: true});
            },
            error: (err) => {
                let errorMessage = err?.error?.Name || [err?.error];
                if (this.isCDS) {
                    this.errors = errorMessage;
                    this.filesToUpload = [];
                }
                this.isLoading = false;
                this.fileUploadSuccess.emit({isSuccess: false, error: errorMessage});
            },
            complete: () => {
                this.filesToUpload = [];
                this.isLoading = false;
                if (this.isCDS) {
                    this.loadData();
                } else {
                    this.hasFilesToUpload.emit(false);
                    this.cdr.markForCheck();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.uploadSubscription?.unsubscribe();
        this.getRequest?.unsubscribe();
    }

    /* CDS related methods */
    handleFilesChange(event: Set<FileItem>) {
        this.isLoading = true;
        this.errors = [];
        event.forEach(fi => this.filesToUpload.push(fi.file));
        this.stagedFileItems = new Set();
        this.uploadFiles();
    }

    public loadData() {
        if (this.filesGetter) {
            this.errors = [];
            this.getRequest = this.filesGetter()
                .subscribe((results) => {
                    results = !this.researchProjectFileTypeID ? results :
                        results.filter((file) => file.ResearchProjectFileTypeID == this.researchProjectFileTypeID);
                    this.uploadedFiles = new Set(this.mapToFileItems(results));
                    this.fileNameToBlobNameMap.clear();
                    results.forEach(file => this.fileNameToBlobNameMap.set(file.Name, file.BlobName));
                    this.cdr.markForCheck();
                });
        }
    }

    public getUploadedFilesCount(){
        return this.uploadedFiles?.size;
    }
    isFileUploadButtonDisabled() {
        if ((this.limitOneFile && !!this.uploadedFiles?.size) || this.disabled) {
            return true;
        }
        return this.disabled || this.isLoading;
    }

    /**
     * Maps array of files to Set of FileItem data type that Carbon Design component is expecting
     * @param files
     */
    mapToFileItems(files): Set<FileItem> {
        return new Set(
            files.map((file) => {
                return {
                    file: {name: file.Name} as File,
                    invalid: false,
                    state: "edit",
                    uploaded: false
                } as FileItem;
            })
        );
    }

    removeCdsFileItem(fileItem: FileItem) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Delete file",
                text: `You are about to delete the file ${fileItem.file.name}. This action cannot be undone. Are you sure you wish to proceed?`,
            }
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.fileDelete(this.researchProjectFileTypeID, this.fileNameToBlobNameMap.get(fileItem.file.name)).subscribe({
                    next: () => {
                        this.fileDeleteSuccess.emit(true);
                        this.loadData();
                    },
                    error: () => this.fileDeleteSuccess.emit(false)
                });
            }
        })
    }
}
