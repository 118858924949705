<header-nav
    [currentUser]="currentUser"
    (loginClicked)="login()"
    (logOutClicked)="logOut()"
    (editProfileClicked)="editProfile()"
    (newApplicationClicked)="createNewApplication()"
    [environment]="environment"
    [userBeingImpersonated]="userBeingImpersonated"
    [userCanManageDashboard]="userCanManageDashboard"
    [userCanViewPermits]="userCanViewPermits"
    [userCanViewResearchProjects]="userCanViewResearchProjects"
    [userCanViewContacts]="userCanViewContacts"
    [userCanViewSpecialConditions]="userCanViewSpecialConditions"
    [userCanViewDistricts]="userCanViewDistricts"
    [userCanViewTags]="userCanViewTags"
    [userCanCreateNewApplication]="userCanCreateNewApplication"
    [isPublic]="isPublic()"
    [isInternal]="isInternal()"
></header-nav>

<main class="site">
    <div class="site-wrapper">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
</main>

<footer-nav
    *ngIf="!isPublic()"
    [currentUser]="currentUser"
    (loginClicked)="login()"
    (logOutClicked)="logOut()"
></footer-nav>
