import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FieldDefinitionService } from 'src/app/shared/generated/api/field-definition.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { ColDef } from 'ag-grid-community';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AgGridAngular } from 'ag-grid-angular';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { Subscription } from "rxjs";

@Component({
  selector: 'castateparksscp-field-definition-list',
  templateUrl: './field-definition-list.component.html',
  styleUrls: ['./field-definition-list.component.scss']
})
export class FieldDefinitionListComponent implements OnInit {
  @ViewChild("fieldDefinitionsGrid") fieldDefinitionsGrid: AgGridAngular;

  private currentUser: UserDto;
  canEditText: boolean;

  public richTextTypeID : number = CustomRichTextTypeEnum.LabelsAndDefinitionsList;

  public rowData = [];
  public columnDefs: ColDef[];
  public defaultColDef: any;

  userSubscription: Subscription;
  fieldDefSubscription: Subscription;

  constructor(
    private fieldDefinitionService: FieldDefinitionService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef) {
      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        suppressMenu: true,
      };
  }

  ngOnInit() {
    this.userSubscription = this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;

      this.columnDefs = [];

      if (this.authenticationService.hasPermission(this.currentUser, PermissionEnum.FieldDefinitionRights, RightsEnum.Update)) {
        this.canEditText = true;

        this.columnDefs.push({
            headerName: "Label",
            valueGetter: function (params: any) {
                return {
                    LinkValue:
                        params.data.FieldDefinitionType.FieldDefinitionTypeID,
                    LinkDisplay: params.data.FieldDefinitionType.DisplayName,
                };
            },
            cellRendererSelector: (params) => {
                return {
                    component: LinkRendererComponent,
                    params: { inRouterLink: "./" },
                };
            },
            filterValueGetter: function (params: any) {
                return params.data.FieldDefinitionType.DisplayName;
            },
            comparator: function (id1: any, id2: any) {
                let link1 = id1.LinkDisplay;
                let link2 = id2.LinkDisplay;
                if (link1 < link2) {
                    return -1;
                }
                if (link1 > link2) {
                    return 1;
                }
                return 0;
            },
            width: 200,
            autoHeight: true,
        });
      } else {
        this.columnDefs.push({
          headerName: 'Label',
          field: 'FieldDefinitionType.DisplayName',
          cellRenderer:function (params: any) {
            return params.data.FieldDefinitionType.DisplayName ? params.data.FieldDefinitionType.DisplayName : ''
          },
          autoHeight:true,
          width:200,
          cellStyle: {'white-space': 'normal'}
        });
      }

      this.columnDefs.push({
          headerName: "Definition",
          field: "FieldDefinitionValue",
          cellRenderer: function (params: any) {
              return `<div><p>${params?.data?.FieldDefinitionValue || "N/A"}</p></div>`;
          },
          autoHeight: true,
          flex: 1,
          cellStyle: { "white-space": "normal" },
      });
      this.cdr.markForCheck();
    });
  }

  onGridReady(gridEvent: any) {
    this.fieldDefinitionsGrid?.api.showLoadingOverlay();

    this.fieldDefSubscription = this.fieldDefinitionService.fieldDefinitionsGet().subscribe((fieldDefinitions) => {
        this.rowData = fieldDefinitions;
        this.fieldDefinitionsGrid.api.hideOverlay();
        this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    this.userSubscription.unsubscribe();
    this.fieldDefSubscription.unsubscribe();
  }
}
