import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PermissionEnum} from '../shared/generated/enum/permission-enum';
import {RightsEnum} from '../shared/models/enums/rights.enum';
import {AuthenticationService} from './authentication.service';
import {UserDto} from '../shared/generated/model/user-dto';
import {
    ResearchProjectDataCollectionUpsertDto,
    ResearchProjectDto,
    ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto, ResearchProjectUpdateUserIsThePrincipalInvestigatorDto,
    ResearchProjectUpsertDto
} from '../shared/generated/model/models';

@Injectable({
  providedIn: 'root'
})
export class CurrentResearchProjectService {
  currentResearchProject$ = new BehaviorSubject<ResearchProjectDto>(null);

  constructor(private authenticationService: AuthenticationService) {}

  setCurrentResearchProject(contact: ResearchProjectDto): void {
      this.currentResearchProject$.next(contact);
  }

  getCurrentResearchProject(): Observable<any> {
      return this.currentResearchProject$.asObservable();
  }

  createResearchProjectUpsertDto(researchProject: ResearchProjectDto) {
      const researchProjectUpsertDto = new ResearchProjectUpsertDto({
          ResearchProjectID: researchProject?.ResearchProjectID,
          ResearchProjectCategoryTypeID: researchProject?.ResearchProjectCategoryTypeID,
          Title: researchProject?.Title,
          Purpose: researchProject?.Purpose,
          AnticipatedBenefit: researchProject?.AnticipatedBenefit,
          ProjectStartDate: researchProject?.ProjectStartDate,
          ProjectEndDate: researchProject?.ProjectEndDate,
          PermitStartDate: researchProject?.PermitStartDate,
          PermitEndDate: researchProject?.PermitEndDate,
          TentativeAnnualReportCompletionDate: researchProject?.TentativeAnnualReportCompletionDate,
          SoilDisturbance: researchProject?.SoilDisturbance,
          SoilDisturbanceDetails: researchProject?.SoilDisturbanceDetails,
          CreateDate: researchProject?.CreateDate,
          CreateUserID: researchProject?.CreateUserID,
          UpdateDate: researchProject?.UpdateDate,
          UpdateUserID: researchProject?.UpdateUserID,
      });
      return researchProjectUpsertDto;
  }

    createResearchProjectInformationUpsertDto(researchProject: ResearchProjectDto) {
        const researchProjectUpsertDto = new ResearchProjectUpsertDto({
            ResearchProjectID: researchProject?.ResearchProjectID,
            ResearchProjectCategoryTypeID: researchProject?.ResearchProjectCategoryTypeID,
            Title: researchProject?.Title,
            Purpose: researchProject?.Purpose,
            AnticipatedBenefit: researchProject?.AnticipatedBenefit,
            RequestedPermitStartDate: researchProject?.RequestedPermitStartDate,
            RequestedPermitEndDate: researchProject?.RequestedPermitEndDate,
            ProjectStartDate: researchProject?.ProjectStartDate,
            ProjectEndDate: researchProject?.ProjectEndDate,
            TentativeAnnualReportCompletionDate: researchProject?.TentativeAnnualReportCompletionDate,
        });
        return researchProjectUpsertDto;
    }

    createResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto(researchProject: ResearchProjectDto) {
        const dto = new ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto({
            ResearchProjectID: researchProject?.ResearchProjectID,
            UserIsThePersonInChargeOfFieldWork: researchProject?.PersonInChargeOfFieldWorkIsPrincipalInvestigator,
            PersonInChargeOfFieldWorkIsPrincipalInvestigator: researchProject?.PersonInChargeOfFieldWorkIsPrincipalInvestigator,
        });
        return dto;
    }

    createResearchProjectUpdateUserIsThePrincipalInvestigatorDto(researchProject: ResearchProjectDto) {
        const dto = new ResearchProjectUpdateUserIsThePrincipalInvestigatorDto({
            ResearchProjectID: researchProject?.ResearchProjectID,
            UserIsThePrincipalInvestigator: researchProject?.UserIsThePrincipalInvestigator,
        });
        return dto;
    }

    createResearchProjectDateUpsertDto(researchProject: ResearchProjectDto) {
        const researchProjectUpsertDto = new ResearchProjectUpsertDto({
            ResearchProjectID: researchProject?.ResearchProjectID,
            PermitStartDate: researchProject?.PermitStartDate,
            PermitEndDate: researchProject?.PermitEndDate,
            ProjectStartDate: researchProject?.ProjectStartDate,
            ProjectEndDate: researchProject?.ProjectEndDate,
            TentativeAnnualReportCompletionDate: researchProject?.TentativeAnnualReportCompletionDate,
        });
        return researchProjectUpsertDto;
    }

  canEditCurrentResearchProject(currentUser: UserDto, contact: ResearchProjectDto): boolean {
      return this.authenticationService.hasPermission(currentUser, PermissionEnum.ResearchProjectRights, RightsEnum.Update);
  }
}
