import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams, IAfterGuiAttachedParams } from "ag-grid-community";

@Component({
    selector: "castateparksscp-button-renderer",
    templateUrl: "./button-renderer.component.html",
    styleUrls: ["./button-renderer.component.scss"],
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
    params: any;
    icon: string;
    label: string;
    type: string;
    useCDS: boolean = false;
    buttonTitle: string = '';
    disabled: boolean = false;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.icon = this.params.icon || null;
        this.label = this.params.label || null;
        this.type = this.params.type || "clear";
        this.useCDS = this.params.useCDS || false;
        this.buttonTitle = this.params.buttonTitle || false;
        if (this.params.isDisabled) {
            this.disabled = this.params.isDisabled(this.params);
        }
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    onClick($event) {
        if (this.params.onClick instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data,
            };

            this.params.onClick(params);
        }
    }
}
