import { Component } from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
  selector: 'castateparksscp-research-project-literature-cited-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectLiteratureCitedFormComponent extends BasicFormComponent {
    upsertDto: LiteratureCitedDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'LiteratureCitedDetails',
                label: 'Literature Cited (optional)',
                type: 'textArea',
                checkbox: true,
                checkboxName: 'ProposalIncludesLiteratureCited'
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                LiteratureCitedDetails: dto?.LiteratureCitedDetails,
                ProposalIncludesLiteratureCited: dto?.ProposalIncludesLiteratureCited
            } as LiteratureCitedDto
        };
    }
}

export interface LiteratureCitedDto {
    LiteratureCitedDetails: string;
    ProposalIncludesLiteratureCited: boolean;
}
