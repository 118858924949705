//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[ResearchProjectFileType]

export enum ResearchProjectFileTypeEnum {
  ProjectScheduleDocumentation = 1,
  PersonInChargeOfFieldWorkResume = 2,
  PrincipalInvestigatorResume = 3,
  SoilDisturbanceAreaSupportingDocumentation = 4,
  StudyAreaSupportingDocumentation = 5,
  StudyProcedureSupportingDocumentation = 6,
  CollectionRationaleSupportingDocumentation = 7,
  AdditionalPermitSupportingDocumentation = 8,
  BudgetSupportingDocumentation = 9,
  LiteratureCitedSupportingDocumentation = 10,
  StudyProposal = 11,
  StandardConditionsAgreementForm = 12,
  WaiverAndIndemnityAgreementForm = 13,
  OptionalInsuranceAddendumDpr65A = 14,
  OptionalLiabilityWaiverAddendumDpr65B = 15,
  UnsignedPermit = 16,
  SignedActivePermit = 17,
  AnnualReport = 18,
  AmendmentSupportingDocumentation = 19,
  SignedAmendmentFinalLetter = 20,
  AmendmentSpecialConditionsAndParkDirectory = 21,
  OriginalSignedPermit = 22,
  SupportingDocumentation = 23
}
