<div class="site">
    <div class="header-wrapper">
        <ng-container *ngIf="dto$ | async as p">
            <div class="project-nav">
                <div *ngIf="showOptions()" matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
                    <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="profile" color="accent" [disabled]="editMode">
                        <mat-icon>expand_more</mat-icon>&nbsp;Options
                    </button>

                    <mat-menu #profile="matMenu" [overlapTrigger]="false">
                        <button mat-menu-item (click)="delete()" *ngIf="canDelete()" [disabled]="isDeleteDisabled()">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="project-title">
                <h3>{{p[titleField]}}</h3>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isShowSideNav; else noSideNav">
        <div class="dashboard">
            <aside class="sidebar">
                <ul class="sidebar-nav">
                    <li class="sidebar-item" *ngFor="let item of sideNav">
                        <a [routerLink]="item.Link" routerLinkActive="active" class="sidebar-link">{{ item.Name }}</a>
                    </li>
                </ul>
            </aside>
            <div class="main">
                <router-outlet #manageOutlet="outlet"></router-outlet>
            </div>
        </div>
    </ng-container>
    <ng-template #noSideNav>
        <router-outlet #manageOutlet="outlet"></router-outlet>
    </ng-template>
</div>
