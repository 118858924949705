import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactFormOrganizationComponent} from "../../contact/shared/contact-form/contact-form-organization.component";
import {
    ContactFormPrincipalInvestigatorComponent
} from "../../contact/shared/contact-form/contact-form-principal-investigator.component";
import {
    ContactFormPersonInChargeOfFieldWorkComponent
} from "../../contact/shared/contact-form/contact-form-person-in-charge-of-field-work.component";
import {
    ResearchProjectContactCollectionUpsertDto,
    ResearchProjectContactDto,
    ResearchProjectContactUpsertDto,
    ResearchProjectDto,
    ResearchProjectResearchTeamUpsertDto
} from 'src/app/shared/generated/model/models';
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectContactService} from "../../../shared/generated/api/research-project-contact.service";
import {ResearchProjectContactTypeEnum} from "../../../shared/generated/enum/research-project-contact-type-enum";
import {ResearchProjectFileTypeEnum} from "../../../shared/generated/enum/research-project-file-type-enum";
import {StateIdToDisplayNameMap} from "../../../shared/models/enums/state.enum.util";
import {CountryIdToDisplayNameMap} from "../../../shared/models/enums/country.enum.util";
import {ResearchTeamUserIsPrincipalInvestigatorForm} from "./research-team-user-is-principal-investigator-form";
import {ResearchTeamPersonChargeOfFieldWorkFlagForm} from "./research-team-person-charge-of-field-work-flag-form";
import {UserService} from "../../../shared/generated/api/user.service";
import {AuthenticationService} from "../../../services/authentication.service";
import {finalize, forkJoin, mergeMap, Subscription, throwError} from 'rxjs';
import {FormElement, FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {buildStateIdAndDisplayName} from "../../../shared/models/enums/state.util";
import {Alert} from "../../../shared/models/alert";
import {ResearchProjectDpr65bFlagFormComponent} from "./research-project-dpr65b-flag-form.component";

import {
    ResearchProjectBaseComponentComponent
} from "../../research-project/shared/research-project-base-component/research-project-base-component.component";
import {BasicModalConfiguration} from "../../basic/basic-modal/basic-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {
    ReturnedToResearcherNotificationComponent
} from "../../research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {Title} from "@angular/platform-browser";
import {catchError} from "rxjs/operators";

@Component({
    selector: 'castateparksscp-research-team-create-public',
    templateUrl: './research-team-create-public.component.html',
    styleUrl: './research-team-create-public.component.css'
})
export class ResearchTeamCreatePublicComponent extends ResearchProjectBaseComponentComponent implements OnInit, OnDestroy {
    pageSpecificTitle= 'Research Team';
    stepIndex: number = 0;
    organizationContactDto: ResearchProjectContactUpsertDto = {CountryID:234} as ResearchProjectContactUpsertDto;
    principalInvestigatorContactDto: ResearchProjectContactUpsertDto = {CountryID:234} as ResearchProjectContactUpsertDto;
    personInChargeOfFieldWorkContactDto: ResearchProjectContactUpsertDto = {CountryID:234} as ResearchProjectContactUpsertDto;
    researchProjectID: number;
    researchProject: ResearchProjectDto;
    userSubscription: Subscription;
    organizationErrors= {};
    principalInvestigatorErrors= {};
    fieldWorkErrors= {};

    @ViewChild("contactFormOrganization", {static: true})
    public contactFormOrganization: ContactFormOrganizationComponent;

    @ViewChild("contactFormPrincipalInvestigator", {static: true})
    public contactFormPrincipalInvestigator: ContactFormPrincipalInvestigatorComponent;

    @ViewChild("contactFormPersonInChargeOfFieldWork", {static: true})
    public contactFormPersonInChargeOfFieldWork: ContactFormPersonInChargeOfFieldWorkComponent;

    @ViewChild("userIsPrincipalInvestigatorForm", {static: true})
    public userIsPrincipalInvestigatorForm: ResearchTeamUserIsPrincipalInvestigatorForm;

    @ViewChild("researchTeamPersonChargeOfFieldWorkFlagForm", {static: true})
    public researchTeamPersonChargeOfFieldWorkFlagForm: ResearchTeamPersonChargeOfFieldWorkFlagForm;

    @ViewChild("researchProjectDpr65bFlagForm", {static: true})
    public dpr65bFlagForm: ResearchProjectDpr65bFlagFormComponent;

    @ViewChild("notificationComponent", {static: true})
    public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;

    static readonly ORG_FORM_LABEL: string = 'organization';
    static readonly PI_FORM_LABEL: string = 'principalInvestigator';
    static readonly FW_FORM_LABEL: string = 'personInChargeOfFieldWork';

    modalConfig: BasicModalConfiguration;
    secondModalConfig: BasicModalConfiguration;
    modalOpen: boolean = false;
    secondModalOpen: boolean = false;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        public researchProjectService: ResearchProjectService,
        public researchProjectContactService: ResearchProjectContactService,
        public alertService: AlertService,
        public userService: UserService,
        public titleService: Title
    ) {
        super(titleService);
    }

    ngOnInit(): void {
        if (!!history.state.modal) {
            this.buildModalConfigurations();
            this.modalOpen = true;
        }
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe(currentUser => {
            this.user = currentUser
        });
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        this.organizationContactDto.ResearchProjectID = this.researchProjectID;
        this.organizationContactDto.ResearchProjectContactTypeID = ResearchProjectContactTypeEnum.Organization;
        this.principalInvestigatorContactDto.ResearchProjectID = this.researchProjectID;
        this.principalInvestigatorContactDto.ResearchProjectContactTypeID = ResearchProjectContactTypeEnum.PrincipalInvestigator;
        this.personInChargeOfFieldWorkContactDto.ResearchProjectID = this.researchProjectID;
        this.personInChargeOfFieldWorkContactDto.ResearchProjectContactTypeID = ResearchProjectContactTypeEnum.PersonInChargeOfFieldWork;
        this.loadPage();
    }

    private buildModalConfigurations() {
        this.modalConfig = {
            size: "md",
            modalHeaderTitle: 'Before you continue with the CA State Parks SCP application',
            contentTitle: 'Step 1: Download required documents',
            content: 'These documents will be required to submit the form. You may or may not be authorized to ' +
                'sign the documents on behalf of your institution so please review them and plan accordingly. ' +
                'These documents will also be available to download at the end of the application.',
            downloadLinks: [
                {text: 'Standard Conditions agreement', route: '/assets/pdfs/standard_conditions_agreement.pdf'},
                {text: 'Waiver and Indemnity agreement', route: '/assets/pdfs/waiver_and_indemnity_agreement.pdf'}
            ],
            primaryButtonClick: () => {
                this.modalOpen = false;
                this.secondModalOpen = true;
            },
        } as BasicModalConfiguration;

        this.secondModalConfig = {
            size: "md",
            modalHeaderTitle: 'Before you continue with the CA State Parks SCP application',
            contentTitle: 'Step 2: Study Proposal guidance',
            content: 'Before you continue filling out the form, please consider whether you have a prepared study proposal. ' +
                'This is often the case if you are applying for multiple permits, or you had to apply for grants. If you ' +
                'have a study proposal, you may skip typing out answers in this form that are already covered in your ' +
                'document.',
            secondaryButtonText: 'Cancel application',
            secondaryButtonClick: () => this.handleCancelApplication(),
            formPage: {
                elements: [
                    {
                        class: 'g-col-12',
                        name: 'StudyProposal',
                        label: 'Do you have a study proposal?',
                        required: false,
                        type: 'radio',
                        radioOptions: [
                            {text: "No, I don't have a study proposal", value: false,
                                helperText: 'Select this if you will answer all questions directly on this form.'},
                            {text: "Yes, I will submit a study proposal", value: true,
                                helperText: 'Select this if you plan to use a study proposal. Look for the "Answered in ' +
                                    'Study Proposal" checkbox beside some questions which you may skip if the answers ' +
                                    'are covered in your proposal.'}
                        ],
                    },
                ],
                createUpsertDto: (dto) => {
                    return {'StudyProposal': false}
                },
            } as FormPage,
        } as BasicModalConfiguration;
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
    }

    loadPage() {
        this.isLoading = true;
        forkJoin([this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID), this.authenticationService.getCurrentUser()])
            .subscribe(([project, currentUser ]) => {
                if (project) this.researchProject = project;
                this.setTitle(project);
                this.setCanEditPage(currentUser, this.researchProject, this.router);
            });

        this.researchTeamPersonChargeOfFieldWorkFlagForm.ID = this.researchProjectID;
        this.userIsPrincipalInvestigatorForm.ID = this.researchProjectID;

        this.researchProjectContactService.researchProjectContactsResearchProjectResearchProjectIDGet(this.researchProjectID)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((researchProjectContacts: Array<ResearchProjectContactDto>) => {
                if (researchProjectContacts) {
                    researchProjectContacts.forEach(r => {
                        if (r.StateID) r['StateDisplayName'] = StateIdToDisplayNameMap.get(r.StateID);
                        if (r.CountryID) r['CountryDisplayName'] = CountryIdToDisplayNameMap.get(r.CountryID);
                        if (ResearchProjectContactTypeEnum.Organization == r.ResearchProjectContactTypeID) {
                            this.organizationContactDto = r;
                            this.contactFormOrganization.ID = this.organizationContactDto.ResearchProjectContactID;
                            if (r.CountryID) {
                                const e: Array<FormElement> = this.contactFormOrganization.formPage.elements.filter(e => e.name == 'StateID');
                                e.forEach(e => e.selectOptions = buildStateIdAndDisplayName(r.CountryID));
                            }
                            return;
                        }
                        if (ResearchProjectContactTypeEnum.PrincipalInvestigator == r.ResearchProjectContactTypeID) {
                            this.principalInvestigatorContactDto = r;
                            this.contactFormPrincipalInvestigator.ID = this.principalInvestigatorContactDto.ResearchProjectContactID;
                            return;
                        }
                        if (ResearchProjectContactTypeEnum.PersonInChargeOfFieldWork == r.ResearchProjectContactTypeID) {
                            this.personInChargeOfFieldWorkContactDto = r;
                            this.contactFormPersonInChargeOfFieldWork.ID = this.personInChargeOfFieldWorkContactDto.ResearchProjectContactID;
                            return;
                        }
                    })
                }
            });
    }

    saveOrganization(dto: ResearchProjectContactDto): void {
        this.organizationContactDto = dto;
        this.contactFormOrganization.ID = dto.ResearchProjectContactID;
        this.contactFormOrganization.dto = dto;
    }

    savePrincipalInvestigator(dto: ResearchProjectContactDto): void {
        this.principalInvestigatorContactDto = dto;
        this.contactFormPrincipalInvestigator.ID = dto.ResearchProjectContactID;
        this.contactFormPrincipalInvestigator.dto = dto;
    }

    savePersonInChargeOfFieldWork(dto: ResearchProjectContactDto): void {
        this.personInChargeOfFieldWorkContactDto = dto;
        this.contactFormPersonInChargeOfFieldWork.ID = dto.ResearchProjectContactID;
        this.contactFormPersonInChargeOfFieldWork.dto = dto;
    }

    cancelEditOrganization() {
    }

    cancelEditPrincipalInvestigator() {
    }

    cancelEditPersonInChargeOfFieldWork() {
    }

    saveAndContinue(): void {
        this.getSaveRequest().subscribe(researchProject => {
            if (researchProject == null) {
                return;
            }
            let url = this.incompleteSectionsExcludingReviewAndSubmitExist(researchProject)
                ? `/public/research-projects/${this.researchProjectID}`
                : `/public/research-project-review-and-submit/${this.researchProjectID}`;
            this.router.navigateByUrl(url).then(x => this.alertService.pushAlert(
                new Alert('The research project was successfully updated.'), 5000));
        });
    }

    getSaveRequest() {
        let flagUpsertDto = this.buildResearchTeamUpsertDto() as ResearchProjectResearchTeamUpsertDto;
        let multipleContacts = {
            'ContactUpsertDtos': [
                this.contactFormOrganization.getUpsert(),
                this.contactFormPrincipalInvestigator.getUpsert(),
                this.contactFormPersonInChargeOfFieldWork.getUpsert()
            ]
        } as ResearchProjectContactCollectionUpsertDto;
        const observables = [
            this.researchProjectContactService.researchProjectContactsMultiplePost(multipleContacts),
            this.researchProjectService.researchProjectsResearchProjectIDUpdateResearchTeamFlagsPut(this.researchProjectID, flagUpsertDto)
        ];

        return forkJoin(observables).pipe(
            mergeMap(() => this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)),
            catchError((err) => {
                this.handleMultipleContactsFormErrors(err);
                return throwError(err);
            })
        );
    }

    private handleMultipleContactsFormErrors(err) {
        let error = err?.error?.errors ? err.error.errors : err?.error ? err.error : err;
        const contactIndices = [0, 1, 2];
        let errorProperties = Object.keys(error);
        contactIndices.forEach(index => {
            let props = errorProperties.filter(e => e.startsWith(`ContactUpsertDtos[${index}].`))
            let formErrors = {};
            props.forEach(p => {
                let label = p.replace(`ContactUpsertDtos[${index}].`, '');
                formErrors[label] = error[p];
            });
            if (index == 0) {
                this.organizationErrors = formErrors;
            }
            if (index == 1) {
                this.principalInvestigatorErrors = formErrors;
            }
            if (index == 2) {
                this.fieldWorkErrors = formErrors;
            }
        })
    }

    private buildResearchTeamUpsertDto() {
        let researchTeamUpsertDto = {researchProjectID: this.researchProjectID};
        this.userIsPrincipalInvestigatorForm.mapUpsert(researchTeamUpsertDto);
        this.researchTeamPersonChargeOfFieldWorkFlagForm.mapUpsert(researchTeamUpsertDto);
        this.dpr65bFlagForm.mapUpsert(researchTeamUpsertDto)
        return researchTeamUpsertDto;
    }

    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;

    userIsThePrincipalInvestigatorChanged(value: boolean) {
        if (value) {
            this.userService.usersUserIDContactGet(this.user.UserID).subscribe(contactDto => {
                this.contactFormPrincipalInvestigator.replaceContactInfo(contactDto);
                this.contactFormPrincipalInvestigator.refreshFormElements(value);
                this.cdr.markForCheck();
            });
        } else {
            this.contactFormPrincipalInvestigator.refreshFormElements(value);
            this.cdr.markForCheck();
        }
    }

    handleUserIsThePersonInChargeOfFieldWorkChange(value) {
        this.researchProject.UserIsThePersonInChargeOfFieldWork = value;
        this.cdr.markForCheck();
        if (value) {
            this.userService.usersUserIDContactGet(this.user.UserID).subscribe(contactDto => {
                this.contactFormPersonInChargeOfFieldWork.replaceContactInfo(contactDto);
                this.contactFormPersonInChargeOfFieldWork.refreshFormElements(value);
                this.cdr.markForCheck();
            });
        } else {
            this.contactFormPersonInChargeOfFieldWork.refreshFormElements(value);
            this.cdr.markForCheck();
        }
    }

    principalInvestigatorIsThePersonInChargeOfFieldWorkChanged(value: boolean) {
        if (value) {
            this.contactFormPersonInChargeOfFieldWork.replaceContactInfo(this.contactFormPrincipalInvestigator.dto);
            this.researchProject.UserIsThePersonInChargeOfFieldWork = this.researchProject.UserIsThePrincipalInvestigator;
        }
        this.contactFormPersonInChargeOfFieldWork.refreshFormElements(!!this.researchProject.UserIsThePersonInChargeOfFieldWork);
        this.researchProject.PersonInChargeOfFieldWorkIsPrincipalInvestigator = value;
        this.researchTeamPersonChargeOfFieldWorkFlagForm.refreshFormPageElements();
        this.cdr.markForCheck();
    }

    isHidePersonInChargeOfFieldWorkItems() {
        return this.researchProject?.PersonInChargeOfFieldWorkIsPrincipalInvestigator;
    }

    handleStepSelect(route: string): void {
        this.stepSelectedRoute = route;
        if (this.isLoading) {
            this.router.navigateByUrl(route).then(x => {});
        } else {
            this.getSaveRequest().subscribe((response) => {
                if (response == null) return;
                this.router.navigateByUrl(this.stepSelectedRoute)
                    .then(x => this.alertService.pushAlert(new Alert('The research project was successfully updated.'), 5000))
            });
        }
    }

    handleCancelApplication(){
        this.researchProjectService.researchProjectsResearchProjectIDDelete(this.researchProjectID)
            .subscribe(() => {
                this.modalOpen = false;
                this.router.navigateByUrl("/permits").then();
            });
    }

    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => {
                if (res) this.researchProject = res;
                this.setCanEditPage(this.user, this.researchProject, this.router);
            });
        this.returnedToResearcherNotification.refreshInternalFields();
    }

    handleWithdraw() {
        this.withdraw(this.dialog, this.researchProjectID, this.researchProjectService, this.router, this.alertService);
    }
}
