import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'castateparksscp-truncated-text-renderer',
    templateUrl: './truncated-text-renderer.component.html',
    styleUrl: './truncated-text-renderer.component.css'
})
export class TruncatedTextRendererComponent implements ICellRendererAngularComp {
    refresh(params: ICellRendererParams<any, any, any>): boolean {
        return false;
    }

    params: any;
    cellValue: string;
    displayValue: string;
    isTruncated: boolean = true;

    agInit(params: any): void {
        this.params = params;
        this.cellValue = params.value || '';

        if (this.cellValue.length > this.params.limit) {
            this.displayValue = this.cellValue.substring(0, this.params.limit) + '...';
        } else {
            this.displayValue = this.cellValue;
        }
    }

    toggleDisplay(): void {
        this.isTruncated = !this.isTruncated;
    }
}
