<cds-modal [size]="config?.size" [open]="open" (overlaySelected)="closeModal()">
    <cds-modal-header (closeSelect)="closeModal()" [showCloseButton]="true">
        <h3 cdsModalHeaderHeading><strong>{{config.modalHeaderTitle}}</strong></h3>
    </cds-modal-header>
    <section cdsModalContent [hasForm]="true">
        <ng-container *ngIf="!!config.contentTitle">
            <p cdsModalContentText style="font-size: larger">{{config.contentTitle}}</p>
            <br/>
        </ng-container>
        <p cdsModalContentText [innerHTML]="config.content"></p>
        <ng-container *ngIf="config.downloadLinks">
            <br/>
            <ul cdsList>
                <li *ngFor="let link of config.downloadLinks" cdsListItem>
                    {{link.text}} (<a cdsLink [href]="link.route" target="_blank">Download</a>)
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf="!!config?.formPage?.elements">
            <br/>
            <castateparksscp-basic-modal-form #formComponent
                                              [isCDS]="true"
                                              [formPage]="config.formPage"
                                              [editMode]="true"
                                              (formSubmitted)="handleFormSubmitted($event)"
                                              [isHideActionButtons]="true"></castateparksscp-basic-modal-form>
        </ng-container>
    </section>
    <cds-modal-footer>
        <button *ngIf="!!config.secondaryButtonClick" cdsButton="ghost" (click)="config.secondaryButtonClick()">
            {{config.secondaryButtonText}}
        </button>
        <button [disabled]="isPrimaryButtonDisabled()" cdsButton="primary" modal-primary-focus (click)="handlePrimaryButtonClick()">
            {{config.primaryButtonText || 'Continue'}}
        </button>
    </cds-modal-footer>
</cds-modal>
