import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BasicListComponent, ButtonDef, ListPage} from '../../basic/basic-list/basic-list.component';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {Router} from "@angular/router";
import {UserService} from 'src/app/shared/generated/api/user.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {DateColumnCreatorService} from 'src/app/shared/services/date-column-creator/date-column-creator.service';
import {AlertService} from 'src/app/shared/services/alert.service';
import {GetRowIdFunc, GetRowIdParams} from 'ag-grid-community';
import {UserDto} from 'src/app/shared/generated/model/models';
import {MatDialog} from '@angular/material/dialog';
import {LinkRendererComponent} from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import {
    CustomDropdownFilterComponent
} from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import {
    InLineEditButtonsRendererComponent
} from 'src/app/shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component';
import {RoleDisplayNameToIdMap} from 'src/app/shared/models/enums/role.enum.util';
import {RightsEnum} from 'src/app/shared/models/enums/rights.enum';
import {DistrictService} from "../../../shared/generated/api/district.service";
import {
    CustomMultiSelectEditorComponent
} from "../../../shared/components/ag-grid/custom-multi-select-editor/custom-multi-select-editor.component";
import {
    ResearchProjectCategoryTypeIdAndDisplayNameArray,
    ResearchProjectCategoryTypeIdToDisplayNameMap
} from "../../../shared/models/enums/research-project-category.enum.util";
import {
    CustomBasicSelectEditorComponent
} from "../../../shared/components/ag-grid/custom-basic-select-editor/custom-basic-select-editor.component";

@Component({
    selector: "castateparksscp-user-list",
    templateUrl: '../../basic/basic-list/basic-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent extends BasicListComponent implements OnInit {
    readonly TECHNICAL_REVIEWER_ROLE = 'Technical Reviewer';
    readonly DISTRICT_STAFF_ROLE = 'District Staff';
    listPage: ListPage = {
        permission: PermissionEnum.UserRights,
        createRoute: '/users/create',
        pageTitle: 'Users',
        createButtonLabel: 'User',
        downloadFileName: 'Users',
    } as ListPage;

    useInGridEdit: boolean = true;
    listAsTile: boolean = false;
    districtSelectOptions: Array<{ text: string, value: any }> = [];
    researchCategories = ResearchProjectCategoryTypeIdAndDisplayNameArray;

    override showNotification = true;
    override notificationMessage = 'If you want to add a district staff user add them as a contact in the respective district via the <a href="/districts">Districts</a> page.';

    constructor(
        public userService: UserService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
        public districtService: DistrictService,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.userService.usersGet();
        this.listPage.columnDefs = [
            {
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                            canEdit: this.canEdit,
                            canDelete: false,
                        },
                    };
                },
                filterable: false,
                width: 100,
                pinned: 'left',
                suppressNavigable: true,
                editable: false,
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.UserID,
                        LinkDisplay: params.data.FullName,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: {inRouterLink: "/manage/users/"},
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.FullName;
                },
                comparator: function (id1: any, id2: any) {
                    let link1 = id1.LinkDisplay;
                    let link2 = id2.LinkDisplay;
                    if (link1 < link2) {
                        return -1;
                    }
                    if (link1 > link2) {
                        return 1;
                    }
                    return 0;
                },
                flex: 2,
                tooltipField: "Name",
                suppressNavigable: true,
                cellClass: 'long-text-cell',
                editable: false
            },
            {
                headerName: "Email",
                field: "Email",
                editable: false,
                flex: 2,
                tooltipField: "Email",
                suppressNavigable: true,
                cellClass: 'long-text-cell'
            },
            {
                headerName: "Role",
                field: "Role.DisplayName",
                editable: true,
                flex: 1,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Role.DisplayName",
                },
                cellEditor: CustomBasicSelectEditorComponent,
                cellEditorParams: {
                    values: ['System Administrator', 'Technical Reviewer', 'Public', 'District Staff', 'Inactive'],
                    disable: this.disableRoleSelect.bind(this),
                    disableInit: (params) => params.node.data['Role']['DisplayName'] == this.DISTRICT_STAFF_ROLE,
                    disabledOptions: ['District Staff'],
                    isListenedTo: true
                },
                cellClass: 'long-text-cell',
            },
            {
                headerName: "Expertise",
                field: "ResearchProjectCategoryTypeIDs",
                valueFormatter: function(params) {
                    return params.data?.ResearchProjectCategoryTypeIDs?.map(x => ResearchProjectCategoryTypeIdToDisplayNameMap.get(x))
                        .join(', ');
                },
                editable: true,
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ResearchProjectCategoryTypeIDs",
                    columnContainsMultipleValues: true,
                    elementToDisplayNameMap: ResearchProjectCategoryTypeIdToDisplayNameMap
                },
                cellEditor: CustomMultiSelectEditorComponent,
                cellEditorParams: {
                    items: this.researchCategories,
                    displayField: 'text',
                    isListener: true,
                    disable: this.disableExpertise.bind(this),
                    disableInit: (params) => params.node.data['Role']['DisplayName'] != this.TECHNICAL_REVIEWER_ROLE
                },
                cellClass: 'long-text-cell'
            },
            {
                headerName: "District",
                field: "DistrictID",
                valueFormatter: function(params) {
                    return params.data.DistrictDisplayName
                },
                editable: true,
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "DistrictDisplayName",
                },
                cellEditor: CustomMultiSelectEditorComponent,
                cellEditorParams: {
                    single: true,
                    items: this.districtSelectOptions,
                    displayField: 'text',
                    isListener: true,
                    disableInit: (params) => true,
                },
                cellClass: 'long-text-cell'
            },
        ]
    }

    public idField: string = 'UserID';
    public startEditingColKey: string = 'Role.DisplayName';

    override ngOnInit() {
        this.districtService.districtsGet().subscribe(districts => {
            districts.forEach(d => {
                this.districtSelectOptions.push({text: d.Name, value: d.DistrictID});
            })
            this.cdr.markForCheck();
        });
        super.ngOnInit();
    }

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<UserDto> | any) => params.data.UserID;
    public putAction = (id: number, dto: any) => {
        return this.userService.usersUserIDPut(id, dto);
    }
    public postAction = (dto: any) => {
        return null;
    }

    public deleteAction = (id: number) => {
        return null;
    }

    override preprocessSaveDto(data: UserDto) {
        data['RoleID'] = RoleDisplayNameToIdMap.get(data.Role.DisplayName);
        data['ResearchProjectCategoryTypeIDs'] = data.ResearchProjectCategoryTypeIDs || [];
        return data;
    }

    inviteUser() {
        this.router.navigateByUrl("manage/users/invite");
    }

    canInviteUser(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.UserRights,
            RightsEnum.Create
        );
    }

    getTileHeader(row: any): string {
        return row.FullName;
    }

    getTileSubHeader(row: any): string {
        return row.Role.DisplayName;
    }
    getTileContent(row: UserDto): string {
        return row.Email;
    }

    getTileLink(row: UserDto): string {
        return `/manage/users/${row.UserID}`;
    }

    onRowEditingStarted(event) {
        super.onRowEditingStarted(event);
        const cells = document.querySelectorAll('.ag-cell-inline-editing');
        cells.forEach(cell => cell.classList.remove('long-text-cell'));
    }

    disableExpertise(role) {
        return this.TECHNICAL_REVIEWER_ROLE != role;
    }

    disableRoleSelect(role) {
        return this.DISTRICT_STAFF_ROLE == role;
    }
}
