import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DistrictFormComponent} from "../../shared/district-form/district-form.component";
import {Observable, Subscription} from "rxjs";
import {UserDto} from "../../../../shared/generated/model/user-dto";
import {CurrentDistrictService} from "../../../../services/current-district.service";
import {AuthenticationService} from "../../../../services/authentication.service";
import {EditViewEventService} from "../../../../services/edit-view-event.service";
import {map} from "rxjs/operators";
import {DistrictSimpleDto} from "../../../../shared/generated/model/district-simple-dto";
import {AgGridAngular} from "ag-grid-angular";
import {ColDef} from "ag-grid-community";
import {ButtonRendererComponent} from "../../../../shared/components/ag-grid/button-renderer/button-renderer.component";
import {SectorService} from "../../../../shared/generated/api/sector.service";
import {ParkService} from "../../../../shared/generated/api/park.service";
import {ParkSimpleDto} from "../../../../shared/generated/model/park-simple-dto";
import {ContactService} from "../../../../shared/generated/api/contact.service";
import {
    TruncatedTextRendererComponent
} from "../../../../shared/components/ag-grid/truncated-text-renderer/truncated-text-renderer.component";
import {MatDialog} from "@angular/material/dialog";
import {ContactUpsertDto} from "../../../../shared/generated/model/contact-upsert-dto";
import {DistrictContactModalComponent} from "../../district-contact-modal/district-contact-modal.component";
import {
    InLineEditButtonsRendererComponent
} from "../../../../shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component";
import {ConfirmDialog} from "../../../../shared/components/confirm-dialog/confirm-dialog.component";
import {Alert} from "../../../../shared/models/alert";
import {AlertContext} from "../../../../shared/models/enums/alert-context.enum";
import {AlertService} from "../../../../shared/services/alert.service";
import {UtilityFunctionsService} from "../../../../services/utility-functions.service";
import {StateIdToDisplayNameMapUSandCA} from "../../../../shared/models/enums/state.enum.util";

@Component({
    selector: 'castateparksscp-district-detail-summary',
    templateUrl: './district-detail-summary.component.html',
})
export class DistrictDetailSummaryComponent implements OnInit, OnDestroy {
    @ViewChild("updateDistrictForm", {read: DistrictFormComponent}) form: DistrictFormComponent;
    @ViewChild("sectorGrid") sectorGrid: AgGridAngular;
    @ViewChild("contactGrid") contactGrid: AgGridAngular;

    public district$: Observable<any>;

    public district: DistrictSimpleDto;
    public currentUser: UserDto;
    public editMode = false;
    public districtUpsertDto: DistrictSimpleDto;

    public defaultColDef: any;

    // sector
    public sectors: any[];
    public sectorColumnDefs: ColDef[];
    public gridComponents: any;
    public isSectorLoading: boolean = false;
    sectorSubscription;

    // parks
    parks$: Observable<any>;
    user: Subscription;
    readonly NUMBER_OF_PARK_COLUMNS = 4;
    readonly NUMBER_OF_NAMES_PER_COLUMN = 8;

    // contacts
    public contacts: any[];
    public contactColumnDefs: ColDef[];
    public isContactLoading: boolean = false;
    contactSubscription;

    constructor(
        private parkService: ParkService,
        private sectorService: SectorService,
        private contactService: ContactService,
        private currentDistrictService: CurrentDistrictService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private utilityFunctionsService: UtilityFunctionsService
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: false,
            resizable: true,
            floatingFilter: false,
            suppressMenu: true,
        };

        this.gridComponents = {
            buttonRenderer: ButtonRendererComponent,
        };
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.district$ = this.currentDistrictService.getCurrentDistrict().pipe(
            map((data) => {
                this.district = data;
                if (this.district && this.district.StateID) {this.district['StateDisplayName'] = StateIdToDisplayNameMapUSandCA.get(this.district.StateID)};
                this.districtUpsertDto = this.district;
                this.sectorColumnDefs = [
                    {
                        headerName: "Name",
                        field: "Name",
                        tooltipField: "Name",
                        flex: 2,
                        sort: "asc",
                    },
                    {
                        headerName: "Phone number",
                        field: "PhoneNumber",
                        tooltipField: "PhoneNumber",
                        flex: 1,
                    },
                ];
                this.contactColumnDefs = [
                    {
                        headerName: "Name",
                        field: "$Name",
                        tooltipField: "Name",
                        flex: 1,
                        sort: "asc",
                        wrapText: true,
                        autoHeight: true,
                        comparator: function (
                            valueA,
                            valueB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let lastNameA = nodeA.data.LastName.toLowerCase();
                            let lastNameB = nodeB.data.LastName.toLowerCase();

                            return lastNameA.localeCompare(lastNameB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                    },
                    {
                        headerName: "Phone number",
                        field: "$Phone1",
                        tooltipField: "$Phone1",
                        flex: 1,
                        wrapText: true,
                        autoHeight: true
                    },
                    {
                        headerName: "Email",
                        field: "Email",
                        tooltipField: "Email",
                        flex: 2,
                        wrapText: true,
                        autoHeight: true
                    },
                    {
                        headerName: "Associated parks",
                        field: "$Parks",
                        flex: 3,
                        tooltipField: "",
                        wrapText: true,
                        autoHeight: true,
                        cellRendererSelector: (params) => {
                            return {
                                component: TruncatedTextRendererComponent,
                                params: Object.assign(params, {limit: 70})
                            }
                        },
                    },
                ];
                if (this.canEdit()) {
                    this.contactColumnDefs.unshift({
                        cellRendererSelector: (params) => {
                            return {
                                component: InLineEditButtonsRendererComponent,
                                params: {
                                    edit: this.openDistrictContactModal.bind(this),
                                    delete: this.deleteDistrictContact.bind(this),
                                    editRowIndex: () => null,
                                },
                            };
                        },
                        width: 100,
                        pinned: 'left',
                    });
                }
                this.refreshSectorData();
                this.refreshParkData();
                this.refreshContactData();
                this.cdr.markForCheck();
                return data;
            })
        );
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.sectorSubscription.unsubscribe();
        this.contactSubscription.unsubscribe();
    }

    refreshDistrict(result: any) {
        this.editMode = false;
        this.currentDistrictService.setCurrentDistrict(result);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save(result) {
        this.refreshDistrict(result);
    }

    canEdit(): boolean {
        return this.currentDistrictService.canEditCurrentDistrict(this?.currentUser, this.district) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.districtUpsertDto) === JSON.stringify(this.form.upsertDto);
        } else {
            return true;
        }
    }

    onSectorGridReady(event) {
        this.sectorGrid.api.hideOverlay();
    }

    refreshSectorData() {
        if (this.district) {
            this.isSectorLoading = true;
            this.sectorSubscription = this.sectorSubscription = this.sectorService.districtsDistrictIDSectorsGet(this.district.DistrictID).subscribe((result) => {
                this.sectors = result;
                this.isSectorLoading = false;
                this.cdr.markForCheck();
            });
        }
    }

    private refreshParkData() {
        if (this.district) {
            this.parks$ = this.parkService.districtsDistrictIDParksGet(this.district?.DistrictID).pipe(
                map((data) => {
                    const parks = data.sort((a: ParkSimpleDto, b: ParkSimpleDto) => {
                        if (a.Name > b.Name) return 1;
                        if (a.Name < b.Name) return -1;
                        return 0;
                    });
                    return this.utilityFunctionsService.buildTableColumnsForArray(parks, this.NUMBER_OF_PARK_COLUMNS,
                        this.NUMBER_OF_NAMES_PER_COLUMN);
                })
            );
        }
    }

    refreshContactData() {
        if (this.district) {
            this.isContactLoading = true;
            this.contactSubscription = this.contactSubscription = this.contactService.districtsDistrictIDContactsGet(this.district.DistrictID).subscribe((result) => {
                this.contacts = result;
                this.contacts.forEach((contact) => {
                    contact.$Name = contact.FirstName + " " + contact.LastName;
                    if (!!contact.PhoneNumber1) {
                        contact.$Phone1 = contact.PhoneNumber1 + (!!contact.Extension1 ? " x" + contact.Extension1 : "");
                    }
                    contact.$Parks = !!contact?.DistrictID ? 'All' : contact?.Parks.sort().join(", ");
                    contact.$IsNotified = false;
                });
                this.isContactLoading = false;
                this.cdr.markForCheck();
            });
        }
    }

    onContactGridReady(event) {
        this.contactGrid.api.hideOverlay();
    }

    openDistrictContactModal(editParams) {
        let dialogRef = this.dialog.open(DistrictContactModalComponent, {
            data: {
                districtID: this.district?.DistrictID,
                contact: !editParams ? new ContactUpsertDto({DistrictID: this.district?.DistrictID}) : editParams['node']['data'],
                header: (!editParams ? 'Add' : 'Edit') + ' contact'
            }
        });

        if (dialogRef) {
            dialogRef.afterClosed().subscribe(() => {
                setTimeout(() => {
                    this.refreshContactData();
                });
            });
        }
    }

    deleteDistrictContact(params) {
        const contactId = params.rowData['ContactID'];
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: `Delete district contact`,
                text: `Are you sure you want to delete this contact?`,
            }
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.contactService.contactsContactIDDelete(contactId).subscribe({
                    next: () => {
                        this.alertService.pushAlert(new Alert(`District contact was successfully deleted.`, AlertContext.Success, true));
                        this.refreshContactData();
                    },
                    error: error => {
                        this.alertService.pushAlert(new Alert(`There was an error removing the district contact. Please try again.`, AlertContext.Danger, true));
                    }
                });
            }
        });
    }
}
