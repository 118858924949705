import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {EditViewEventService} from 'src/app/services/edit-view-event.service';
import {ConfirmDialog} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {UserDto} from 'src/app/shared/generated/model/user-dto';
import {Alert} from 'src/app/shared/models/alert';
import {AlertContext} from 'src/app/shared/models/enums/alert-context.enum';
import {RightsEnum} from 'src/app/shared/models/enums/rights.enum';
import {AlertService} from 'src/app/shared/services/alert.service';

@Component({
    selector: 'castateparksscp-basic-detail',
    templateUrl: './basic-detail.component.html',
    styleUrls: ['./basic-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class BasicDetailComponent implements OnInit {
    detailPage: DetailPage;

    public titleField: string = 'Name';

    public permission: any = PermissionEnum.ProjectRights;

    public dto$: Observable<any>;
    public dto?: any | undefined;

    public currentUser: UserDto;
    public editMode: boolean = false;
    public sideNav: any[] = [];
    public isShowSideNav: boolean = true;
    public isShowOptions: boolean = true;

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;

    constructor(
        public authenticationService: AuthenticationService,
        public editViewEventService: EditViewEventService,
        public alertService: AlertService,
        public router: Router,
        public route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.sideNav = this.detailPage.sideNav;

        this.detailPage.dtoGetter().subscribe((data) => {
                data = this.processData(data);
                this.detailPage.setCurrentDto(data);
                this.cdr.markForCheck();
            },
            () => {
                this.router.navigate([""]);
            });

        this.dto$ = this.detailPage.currentDtoObserver().pipe(
            map((data) => {
                this.dto = data;
                this.cdr.markForCheck();
                return data;
            })
        );

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
            this.editMode = result;
            this.cdr.markForCheck();
        });
    }

    processData(data) {
        return data
    }

    ngOnDestroy(): void {
        this.detailPage.setCurrentDto(null);
        this.user.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    refreshProject(result: any) {
        this.dto = result;
        this.detailPage.setCurrentDto(this.dto);
        this.cdr.detectChanges();
    }

    canDelete() {
        return this.authenticationService.hasPermission(this.currentUser, this.detailPage.permission, RightsEnum.Delete);
    }

    public delete() {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: `Delete ${this.detailPage.dtoName}`,
                text: this.detailPage.getDeleteWarningMessage(this.dto),
            }
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.detailPage.dtoDelete().subscribe(() => {
                    this.alertService.pushAlert(new Alert(`${this.detailPage.dtoName} was successfully deleted.`, AlertContext.Success, true));
                    this.router.navigate([this.detailPage.deleteSuccessRoute]);
                }, error => {
                    this.alertService.pushAlert(new Alert(`There was an error deleting the ${this.detailPage.dtoName}. Please try again.`, AlertContext.Danger, true));
                });
            }
        });
    }

    hasReadOnly() {
        const read = this.authenticationService.hasPermission(this?.currentUser, this.detailPage.permission, RightsEnum.Read);
        const update = this.authenticationService.hasPermission(this?.currentUser, this.detailPage.permission, RightsEnum.Update);
        const remove = this.authenticationService.hasPermission(this?.currentUser, this.detailPage.permission, RightsEnum.Delete);
        const create = this.authenticationService.hasPermission(this?.currentUser, this.detailPage.permission, RightsEnum.Create);
        if (read && !update && !remove && !create) {
            return true;
        } else {
            return false;
        }
    }

    showOptions() {
        if (!this.isShowOptions) return false;
        return !this.hasReadOnly();
    }

    isDeleteDisabled() {
        return false;
    }
}

export interface DetailPage {
    dtoName: string,
    sideNav: Array<{Name: string, Link: string}>;
    permission: any;
    editMode: boolean;
    dtoGetter: () => Observable<any>;
    dtoDelete: () => Observable<any>;
    currentDtoObserver: () => Observable<any>;
    setCurrentDto: (data) => void;
    getDeleteWarningMessage: (dto: any) => string;
    deleteSuccessRoute: string;
}
