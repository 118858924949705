import {
    LogLevel,
    Configuration,
    BrowserCacheLocation,
} from "@azure/msal-browser";

const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;
export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_CAStateParks_SignUpSignIn",
        editProfile: "B2C_1_EditProfile",
    },
    authorities: {
        signUpSignIn: {
            authority:
                "https://identitystoresitka.b2clogin.com/identitystoresitka.onmicrosoft.com/B2C_1_CAStateParks_SignUpSignIn",
        },
        editProfile: {
            authority:
                "https://identitystoresitka.b2clogin.com/identitystoresitka.onmicrosoft.com/B2C_1_EditProfile",
        },
    },
    authorityDomain: "identitystoresitka.b2clogin.com",
    apiScopes: [
        "https://identitystoresitka.onmicrosoft.com/e709504e-30a7-48d5-9f43-7757e6ad6663/User.Access",
        //"https://identitystoresitka.onmicrosoft.com/6533d564-99a1-46ba-8b3c-e4082e7e2634/User.Access",
    ],
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        //One ClientID per applicaiton, create this in the Azure B2C Page and update her for new apps.
        //clientId: "da527e88-778c-40df-b6ee-8ae6680c7e89", // This is the ONLY mandatory field that you need to supply.
        clientId: "ad9e9ae7-749b-468a-8a4f-aa9afbcb87dc",
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                //console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
        },
    },
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net",
};
