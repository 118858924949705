import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges,} from "@angular/core";
import {BasicListComponent, ListPage} from '../../basic/basic-list/basic-list.component';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {DateColumnCreatorService} from 'src/app/shared/services/date-column-creator/date-column-creator.service';
import {Router} from '@angular/router';
import {AlertService} from "src/app/shared/services/alert.service";
import {GetRowIdFunc, GetRowIdParams} from "ag-grid-community";
import {MatDialog} from "@angular/material/dialog";
import {FileService} from "../../../shared/services/file/file.service";
import {ResearchProjectFileDto} from "src/app/shared/generated/model/research-project-file-dto";
import {ConfirmDialog} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import {Alert} from "../../../shared/models/alert";
import {AlertContext} from "../../../shared/models/enums/alert-context.enum";
import {ResearchProjectFileService} from "../../../shared/generated/api/research-project-file.service";
import {ResearchProjectFileSimpleDto} from "../../../shared/generated/model/research-project-file-simple-dto";
import {ResearchProjectFileTypeEnum} from "../../../shared/generated/enum/research-project-file-type-enum";


@Component({
    selector: 'castateparksscp-research-project-file-list',
    templateUrl: "../../basic/basic-list/basic-list.component.html",
    styleUrl: "./research-project-file-list.component.css",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResearchProjectFileListComponent extends BasicListComponent implements OnInit, OnChanges {
    @Input() researchProjectID: number;
    @Input() researchProjectFileTypeID: number;
    @Input() fileRoute: string;
    @Input() viewAllFiles: boolean = false;
    @Input() useDefaultViewAllFilesHeight: boolean = true;
    @Input() isShowAddButton: boolean = false;

    listPage: ListPage = {
        permission: PermissionEnum.ProjectRights,
        pageTitle: 'Files',
        createButtonLabel: 'File',
        downloadFileName: 'Files',
        isShowAddButton: false,
        iSShowTitle: false,
        iSShowButtonGroup: false,
        isShowHeader: false,
        gridTableStyle: 'height: 300px',
    } as ListPage;

    useInGridEdit: boolean = true;

    constructor(
        public researchProjectFileService: ResearchProjectFileService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
        private dateColumnCreatorService: DateColumnCreatorService,
        private fileService: FileService,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.columnDefs = [
            {
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                    onClick: this.downloadRowFile.bind(this),
                    icon: "download",
                },
                cellStyle: (params) => {
                    return {"text-overflow": "unset"};
                },
                filter: null,
                sortable: false,
                width: 90
            },
            {
                headerName: "Name",
                field: "Name",
                tooltipField: "Name",
                flex: 4,
                sort: "asc",
                cellClass: 'long-text-cell'
            },
            {
                headerName: "Type",
                field: "ResearchProjectFileTypeDisplayName",
                flex: 4,
                cellClass: 'long-text-cell'
            },
            {
                headerName: "Size",
                field: "Size",
                flex: 1,
                cellClass: 'long-text-cell'
            },
            {
                headerName: "Uploaded By",
                field: "UploadedBy",
                flex: 2,
                cellClass: 'long-text-cell'
            },
            this.dateColumnCreatorService.createDateColumnDef("Uploaded On", "UploadedOn", "M/dd/YYYY"),
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['researchProjectID']) {
            this.loadData();
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.listPage.dtosGetter = (this.viewAllFiles)
            ? () => this.researchProjectFileService.researchProjectsResearchProjectIDFilesGet(this.researchProjectID)
            : () => this.researchProjectFileService.researchProjectsResearchProjectIDResearchProjectFileTypesResearchProjectFileTypeIDFilesGet(this.researchProjectID, this.researchProjectFileTypeID);

        if (this.viewAllFiles && this.useDefaultViewAllFilesHeight) this.listPage.gridTableStyle = 'height: 600px';
        if (this.isShowAddButton) this.listPage.isShowAddButton = true;

        this.loadData();
    }

    override processDtosGet(results: Array<ResearchProjectFileSimpleDto>) {
        results.forEach(file => {
            file['UploadedBy'] = file?.UpdateUserName ?? file?.CreateUserName;
            file['UploadedOn'] = file?.UpdateDate ?? file?.CreateDate;
            file['Size'] = this.formatFileSize(file.ObjectSize);
        })
        super.processDtosGet(results);
    }

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ResearchProjectFileDto> | any) => params.data.ResearchProjectFileID;
    public putAction = (id: number, dto: any) => {
        return null
    }

    public postAction = (dto: any) => {
        return null
    }

    downloadRowFile(event) {
        ResearchProjectFileListComponent.downloadFile(this.fileService, this.fileRoute, event.rowData);
    }

    public static downloadFile(fileService: FileService, route: string, fileDto: ResearchProjectFileSimpleDto) {
        fileService.downloadFile(`${route}/researchProjectFileTypes/${fileDto.ResearchProjectFileTypeID}/files/${fileDto.BlobName}`)
            .subscribe((result) => {
            if (!result) {
                return;
            }

            const file = new File([result], fileDto.Name);
            if (navigator.msSaveBlob) {
                return navigator.msSaveBlob(file, file.name);
            }

            ResearchProjectFileListComponent.downloadAsHref(URL.createObjectURL(file), file.name);
        });
    }

    static downloadAsHref(href: string, filename: string) {
        const tempDownloadLink = document.createElement("a");
        tempDownloadLink.href = href;
        tempDownloadLink.download = filename;

        document.body.appendChild(tempDownloadLink);
        tempDownloadLink.click();
        document.body.removeChild(tempDownloadLink);
    }

    deleteFile(event) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Delete Project File",
                text: `You are about to delete the file ${event.rowData.Name}. This action cannot be undone. Are you sure you wish to proceed?`,
            }
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.researchProjectFileService.researchProjectsResearchProjectIDResearchProjectFileTypesResearchProjectFileTypeIDFilesBlobNameDelete(this.researchProjectID, event.rowData.ResearchProjectFileTypeID, event.rowData.BlobName).subscribe(() => {
                    this.alertService.pushAlert(new Alert(`File was successfully deleted.`, AlertContext.Success, true));
                    this.refreshData();
                }, error => {
                    this.alertService.pushAlert(new Alert(`There was an error deleting the file. Please try again.`, AlertContext.Danger, true));
                });
            }
        });
    }

    formatFileSize(bytes, decimals = 2) {
        if (!+bytes) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    public getFirstFileByResearchProjectFileType(researchProjectFileTypeID: number) {
        let permitIndex = this.dtos?.findIndex((dto: ResearchProjectFileSimpleDto) => dto.ResearchProjectFileTypeID == researchProjectFileTypeID);
        if (permitIndex == -1) return;
        ResearchProjectFileListComponent.downloadFile(this.fileService, this.fileRoute, this.dtos[permitIndex]);
    }
}

