import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'castateparksscp-link-renderer',
  templateUrl: './link-renderer.component.html',
  styleUrls: ['./link-renderer.component.scss']
})

export class LinkRendererComponent implements AgRendererComponent {
  params: any;

  agInit(params: any): void {
    if(params.value === null)
    {
      params = { value: { LinkDisplay: "", LinkValue: "", ChildRoute: "", QueryParams: null }, inRouterLink: "" };
    }
    else
    {
      this.params = params;
    }
    if (!!this.params.routerLinkGetter) {
        this.params.inRouterLink = this.params.routerLinkGetter(this.params);
    }
  }

  refresh(params: any): boolean {
      return false;
  }
}
