import {UserDto} from "src/app/shared/generated/model/user-dto";
import { BooleanIdToDisplayNameMap } from "src/app/shared/models/boolean.util";
import { RoleIdToDisplayNameMap } from "src/app/shared/models/enums/role.enum.util";

export class UserDtoUtil {
    public static additionalProcess(dto: UserDto): UserDto {
        dto['RoleDisplayName'] = !!(dto.Role.RoleID) ? RoleIdToDisplayNameMap.get(dto.Role.RoleID): '';
        dto['ReceiveSupportEmailsDisplay'] = !!(dto.ReceiveSupportEmails) ? BooleanIdToDisplayNameMap.get(dto.ReceiveSupportEmails): 'No';
        return dto;
    }
}
