import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchProjectFileTypeEnum} from "../../shared/generated/enum/research-project-file-type-enum";
import {ResearchProjectStudyAreaFormComponent} from "./research-project-study-area-form.component";
import {ResearchProjectAreaParkService} from "../../shared/generated/api/research-project-area-park.service";
import {
    ResearchProjectAreaParkListUpsertDto
} from "../../shared/generated/model/research-project-area-park-list-upsert-dto";
import {finalize, forkJoin} from "rxjs";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {ResearchProjectDto} from "../../shared/generated/model/research-project-dto";
import {
    ResearchProjectBaseComponentComponent
} from "../research-project/shared/research-project-base-component/research-project-base-component.component";
import {AuthenticationService} from "../../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertService} from "../../shared/services/alert.service";
import {
    ReturnedToResearcherNotificationComponent
} from "../research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'castateparksscp-research-project-site-location',
    templateUrl: './research-project-site-location.component.html',
    styleUrls: ['./research-project-site-location.component.scss']
})
export class ResearchProjectSiteLocationComponent extends ResearchProjectBaseComponentComponent implements OnInit {
    pageSpecificTitle = 'Study Areas';
    stepIndex: number = 2;
    researchProjectID: number;
    researchProjectAreaParks: ResearchProjectAreaParkListUpsertDto = {};
    researchProject: ResearchProjectDto;

    @ViewChild("researchProjectStudyAreaForm", {static: true})
    public studyAreaForm: ResearchProjectStudyAreaFormComponent;
    @ViewChild("notificationComponent", {static: true})
    public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;

    constructor(private researchProjectAreaParkService: ResearchProjectAreaParkService,
                private researchProjectService: ResearchProjectService,
                public alertService : AlertService,
                public dialog: MatDialog,
                private router: Router,
                public route: ActivatedRoute,
                public cdr: ChangeDetectorRef,
                public auth: AuthenticationService,
                public titleService: Title
    ) {
        super(titleService);
    }

    ngOnInit(): void {
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        this.isLoading = true;
        forkJoin({
            parks: this.researchProjectAreaParkService.researchProjectAreaParksResearchProjectsResearchProjectIDGet(this.researchProjectID),
            researchProject: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
            user: this.auth.getCurrentUser()
        })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(response => {
                this.researchProjectAreaParks =  { ParkIDs: response.parks.map(p => p.ParkID)};
                this.researchProject = response.researchProject;
                this.user = response.user;
                this.setTitle(response.researchProject);
                this.setCanEditPage(response.user, response.researchProject, this.router);
            });
    }

    saveAndContinue(): void {
        this.stepSelectedRoute = '';
        this.studyAreaForm.save();
    }

    researchProjectStudyAreaSaved(researchProject): void {
        if (!researchProject) return;

        let url = this.stepSelectedRoute;
        if (!url)
            url = this.incompleteSectionsExcludingReviewAndSubmitExist(researchProject)
                ? `/public/research-project-data-collection/${this.researchProjectID}`
                : `/public/research-project-review-and-submit/${this.researchProjectID}`;
        this.router.navigateByUrl(url).then();
    }

    displayDistrictWarning() {
        if (!this.studyAreaForm) return false;
        if (!this.studyAreaForm.errors) return false;
        return !!this.studyAreaForm.errors['Districts'];
    }

    handleStepSelect(route: string): void {
        this.stepSelectedRoute = route;
        if (this.isLoading) {
            this.router.navigateByUrl(route).then(x => {});
        } else {
            this.saveAndContinue();
        }
    }

    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => this.researchProject = res);
        this.returnedToResearcherNotification.refreshInternalFields();
    }

    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;

    handleWithdraw() {
        this.withdraw(this.dialog, this.researchProjectID, this.researchProjectService, this.router, this.alertService);
    }
}
