import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from "../../services/authentication.service";
import {AppComponent} from "../../app.component";
import {BusyService} from "../../shared/services";
import {Title} from '@angular/platform-browser';
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {ResearchProjectUpsertDto} from 'src/app/shared/generated/model/research-project-upsert-dto';
import {UserService} from "../../shared/generated/api/user.service";
import {tap} from "rxjs/operators";

@Component({
    selector: 'castateparksscp-public-home',
    templateUrl: './public-home.component.html',
    styleUrl: './public-home.component.css'
})
export class PublicHomeComponent extends AppComponent implements OnInit {
    constructor(
        public router: Router,
        public busyService: BusyService,
        public titleService: Title,
        public viewRef: ViewContainerRef,
        public authenticationService: AuthenticationService,
        public researchProjectService: ResearchProjectService,
        public userService: UserService,
    ) {
        super(router, busyService, titleService, viewRef, userService, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit()
    }

    createNewPermit(): void {
        let successObservable = this.researchProjectService.researchProjectsPost({ResearchProjectCategoryTypeID: 1} as ResearchProjectUpsertDto)
            .pipe(tap((newPermit) => this.navigateToNewPermit(newPermit.ResearchProjectID, true)));
        this.authenticationService.isAuthenticated() ? successObservable.subscribe()
            : this.authenticationService.login(undefined, successObservable);
    }

    navigateToNewPermit(researchProjectID: number, modal = false) {
        this.router.navigateByUrl(`/public/research-teams/${researchProjectID}`, {state: {modal: modal}}).then(x => {});
    }

    selected(event): void {

    }
}
