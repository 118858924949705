/**
 * CAStateParksSCP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ResearchProjectAreaParkListDto } from '../model/research-project-area-park-list-dto';
import { ResearchProjectAreaParkListUpsertDto } from '../model/research-project-area-park-list-upsert-dto';
import { ResearchProjectAreaParkSimpleDto } from '../model/research-project-area-park-simple-dto';
import { ResearchProjectAreaParkUpsertDto } from '../model/research-project-area-park-upsert-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ResearchProjectAreaParkService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectAreaParksGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchProjectAreaParkSimpleDto>>>;
    public researchProjectAreaParksGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchProjectAreaParkSimpleDto>>>;
    public researchProjectAreaParksGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchProjectAreaParkSimpleDto>>(`${this.basePath}/researchProjectAreaParks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectAreaParkUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectAreaParksPost(researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectAreaParkSimpleDto>;
    public researchProjectAreaParksPost(researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksPost(researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksPost(researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResearchProjectAreaParkSimpleDto>(`${this.basePath}/researchProjectAreaParks`,
            researchProjectAreaParkUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectAreaParkID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectAreaParksResearchProjectAreaParkIDDelete(researchProjectAreaParkID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectAreaParksResearchProjectAreaParkIDDelete(researchProjectAreaParkID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectAreaParksResearchProjectAreaParkIDDelete(researchProjectAreaParkID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectAreaParksResearchProjectAreaParkIDDelete(researchProjectAreaParkID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectAreaParkID === null || researchProjectAreaParkID === undefined) {
            throw new Error('Required parameter researchProjectAreaParkID was null or undefined when calling researchProjectAreaParksResearchProjectAreaParkIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/researchProjectAreaParks/${encodeURIComponent(String(researchProjectAreaParkID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectAreaParkID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectAreaParksResearchProjectAreaParkIDGet(researchProjectAreaParkID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectAreaParkSimpleDto>;
    public researchProjectAreaParksResearchProjectAreaParkIDGet(researchProjectAreaParkID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksResearchProjectAreaParkIDGet(researchProjectAreaParkID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksResearchProjectAreaParkIDGet(researchProjectAreaParkID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectAreaParkID === null || researchProjectAreaParkID === undefined) {
            throw new Error('Required parameter researchProjectAreaParkID was null or undefined when calling researchProjectAreaParksResearchProjectAreaParkIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResearchProjectAreaParkSimpleDto>(`${this.basePath}/researchProjectAreaParks/${encodeURIComponent(String(researchProjectAreaParkID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectAreaParkID 
     * @param researchProjectAreaParkUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectAreaParksResearchProjectAreaParkIDPut(researchProjectAreaParkID: number, researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectAreaParkSimpleDto>;
    public researchProjectAreaParksResearchProjectAreaParkIDPut(researchProjectAreaParkID: number, researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksResearchProjectAreaParkIDPut(researchProjectAreaParkID: number, researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksResearchProjectAreaParkIDPut(researchProjectAreaParkID: number, researchProjectAreaParkUpsertDto?: ResearchProjectAreaParkUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectAreaParkID === null || researchProjectAreaParkID === undefined) {
            throw new Error('Required parameter researchProjectAreaParkID was null or undefined when calling researchProjectAreaParksResearchProjectAreaParkIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectAreaParkSimpleDto>(`${this.basePath}/researchProjectAreaParks/${encodeURIComponent(String(researchProjectAreaParkID))}`,
            researchProjectAreaParkUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectAreaParksResearchProjectsResearchProjectIDGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchProjectAreaParkSimpleDto>>;
    public researchProjectAreaParksResearchProjectsResearchProjectIDGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchProjectAreaParkSimpleDto>>>;
    public researchProjectAreaParksResearchProjectsResearchProjectIDGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchProjectAreaParkSimpleDto>>>;
    public researchProjectAreaParksResearchProjectsResearchProjectIDGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectAreaParksResearchProjectsResearchProjectIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchProjectAreaParkSimpleDto>>(`${this.basePath}/researchProjectAreaParks/researchProjects/${encodeURIComponent(String(researchProjectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectAreaParkListUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectAreaParksResearchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectAreaParkListUpsertDto?: ResearchProjectAreaParkListUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectAreaParkListDto>;
    public researchProjectAreaParksResearchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectAreaParkListUpsertDto?: ResearchProjectAreaParkListUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectAreaParkListDto>>;
    public researchProjectAreaParksResearchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectAreaParkListUpsertDto?: ResearchProjectAreaParkListUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectAreaParkListDto>>;
    public researchProjectAreaParksResearchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectAreaParkListUpsertDto?: ResearchProjectAreaParkListUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectAreaParksResearchProjectsResearchProjectIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectAreaParkListDto>(`${this.basePath}/researchProjectAreaParks/researchProjects/${encodeURIComponent(String(researchProjectID))}`,
            researchProjectAreaParkListUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
