import {Component, OnInit} from '@angular/core';
import {BasicFormComponent, FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectDateUpsertDto} from "../../../shared/generated/model/research-project-date-upsert-dto";

@Component({
  selector: 'castateparksscp-unsigned-permit-form',
    templateUrl: '../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../basic/shared/basic-form/basic-form.component.scss'],
})
export class UnsignedPermitFormComponent extends BasicFormComponent implements OnInit {
    upsertDto: ResearchProjectDateUpsertDto;

    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-5',
                name: 'PermitStartDate',
                label: 'Start date',
                type: 'date',
                required: true,
                onChange: (dateValue) => {
                    if (!!dateValue) {
                        let startDate = new Date(dateValue);
                        this.upsertDto['PermitStartDate'] = startDate.toLocaleDateString();
                        this.upsertDto['PermitEndDate'] = new Date(startDate.setFullYear(startDate.getFullYear() + 1)).toLocaleDateString();
                    }
                },
                helperTextGetter: () => !!this.dto?.['RequestedPermitStartDate'] ? `Requested start date ${new Date(this.dto['RequestedPermitStartDate']).toLocaleDateString()}` : ''
            },
            {
                class: 'g-col-5',
                name: 'PermitEndDate',
                label: 'End date',
                type: 'date',
                required: true,
                helperTextGetter: () => !!this.dto?.['RequestedPermitEndDate'] ? `Requested end date ${new Date(this.dto['RequestedPermitEndDate']).toLocaleDateString()}` : '',
                conditionalHelperTextArray: [
                    {showCondition: this.requestedAndEnteredDatesDoNotMatch.bind(this), text: 'The requested start and end date do not match the values entered above.', textInfo: true},
                    {showCondition: this.startAndEndDatePeriodExceedsOneYear.bind(this), text: 'The entered start and end dates are more than one year apart.', textInfo: true},
                    {showCondition: this.annualReportTentativeDateIsAfterEndDate.bind(this), textGetter: this.getAnnualReportTentativeDateIsAfterEndDateText.bind(this), textInfo: true},
                ]
            },
            {
                class: 'g-col-12',
                label: 'CEQA',
                type: 'radio',
                name: 'Ceqa',
                required: true,
                radioOptions: [
                    {text: "YES", value: true},
                    {text: "N/A", value: false}
                ],
                onChange: (ceqa) => {
                        this.upsertDto['Ceqa'] = ceqa;
                },
            }
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            let start = !!dto?.PermitStartDate ? new Date(dto?.PermitStartDate) : null;
            let end = !!dto?.PermitEndDate ? new Date(dto?.PermitEndDate) : null;
            return {'PermitStartDate': start, 'PermitEndDate': end, 'Ceqa': dto?.Ceqa || false};
        };
        this.formPage.put = (id, upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdateDatesPut(id, upsertDto);
    }

    startAndEndDatePeriodExceedsOneYear() {
        if (!this.upsertDto?.PermitStartDate || !this.upsertDto?.PermitEndDate) return false;
        let start = this.getTruncatedTimeOfDate(this.upsertDto.PermitStartDate);
        let end = this.getTruncatedTimeOfDate(this.upsertDto.PermitEndDate);
        return end - start > (365 * 24 * 60 * 60 * 1000);
    }

    private getTruncatedTimeOfDate(dateString) {
        return new Date(dateString)?.getTime();
    }

    requestedAndEnteredDatesDoNotMatch() {
        if (!this.upsertDto?.PermitStartDate || !this.upsertDto?.PermitEndDate) return false;
        return this.getTruncatedTimeOfDate(this.upsertDto?.PermitStartDate) != this.getTruncatedTimeOfDate(this.dto?.RequestedPermitStartDate) ||
            this.getTruncatedTimeOfDate(this.upsertDto?.PermitEndDate) != this.getTruncatedTimeOfDate(this.dto?.RequestedPermitEndDate);
    }

    annualReportTentativeDateIsAfterEndDate() {
        if (!this.upsertDto?.PermitEndDate) return false;
        return this.getTruncatedTimeOfDate(this.upsertDto?.PermitEndDate) < this.getTruncatedTimeOfDate(this.dto?.TentativeAnnualReportCompletionDate);
    }

    getAnnualReportTentativeDateIsAfterEndDateText() {
        return `The tentative annual report completion date, ${new Date(this.dto?.TentativeAnnualReportCompletionDate)?.toLocaleDateString()}, is after the entered end date.`;
    }
}
