import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DistrictContactFormComponent} from "../district-contact-form/district-contact-form.component";
import {UserDto} from "../../../shared/generated/model/user-dto";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CurrentContactService} from "../../../services/current-contact.service";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
  selector: 'castateparksscp-district-contact-modal',
  templateUrl: './district-contact-modal.component.html',
})
export class DistrictContactModalComponent implements OnInit, OnDestroy {
    @ViewChild("districtContactForm", {read: DistrictContactFormComponent}) form: DistrictContactFormComponent;

    public contact;
    public currentUser: UserDto;
    public editMode = false;
    public districtID: number;

    user: Subscription;
    showActions = true;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private currentContactService: CurrentContactService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef
    ) {
        this.contact = data.contact;
        this.districtID = data.districtID;
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
    }

    saveButtonClicked(result) {
        this.form.saveForm(null);
    }

    canEdit(): boolean {
        return this.currentContactService.canEditCurrentContact(this?.currentUser, this.contact) && !this.editMode;
    }

    handleFormSubmitEvent(result) {
        this.dialogRef.close();
    }

    isFormInvalid() {
        if (this.form?.form?.invalid) return true;
        return !this.form?.isParkSelectionValid();
    }
}
