<ng-container *ngIf="isCDS; else originalStyle">
    <div style="margin: 1rem 0">
        <label *ngIf="!!title" class="cds--file--label">{{title}}<span *ngIf="!!titleLinkRoute"> (<a cdsLink [href]="titleLinkRoute" target="_blank">{{titleLinkLabel}}</a>)</span></label>
        <p *ngIf="!!description" style="margin-block-end: 0" class="cds--label-description">{{description}}</p>
        <cds-file-uploader
            [disabled]="isFileUploadButtonDisabled()"
            [buttonText]="uploadFileButtonText"
            [buttonType]="buttonType"
            [accept]="[]"
            [multiple]="true"
            [size]="buttonSize"
            [fileItemSize]="'sm'"
            (ngModelChange)="handleFilesChange($event)"
            [ngModel]="stagedFileItems">
        </cds-file-uploader>
        <ng-container *ngIf="errors?.length">
            <cds-inline-loading [state]="errorState"
                                [errorText]="'Upload failed'">
            </cds-inline-loading>
            <p *ngFor="let err of errors" class="text-danger smaller">
                {{err}}
            </p>
            <br/>
        </ng-container>
        <div *ngIf="required && !uploadedFiles?.size">
            <span class="text-info smaller">
                Requires an uploaded file.
            </span>
        </div>
        <div *ngIf="!!supportedFileTypes?.length">
            <span style="font-size: smaller">
                (Supported file types: {{supportedFileTypes?.join(", ")}})
            </span>
        </div>
        <ng-container *ngIf="isLoading">
            <cds-inline-loading [state]="loadingState"
                                [loadingText]="'Uploading…'">
            </cds-inline-loading>
        </ng-container>
        <ng-container *ngIf="isShowUploadedFiles">
            <div class="cds--file">
                <div class="cds--file-container">
                    <ng-container *ngFor="let fileItem of uploadedFiles">
                        <div class="cds--file__selected-file cds--file__selected-file--sm">
                            <p class="cds--file-filename" [title]="fileItem.file.name">{{fileItem.file.name}}</p>
                            <span class="cds--file__state-container">
                        <button *ngIf="canDeleteFile"
                                type="button"
                                class="cds--file-close"
                                [attr.aria-label]="'Remove Button'"
                                tabindex="0"
                                (click)="removeCdsFileItem(fileItem)"
                                (keyup.enter)="removeCdsFileItem(fileItem)"
                                (keyup.space)="removeCdsFileItem(fileItem)">
                            <svg cdsIcon="close" size="16"></svg>
                        </button>
		                </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #originalStyle>
    <div style="margin-top: 1rem;">
        <!--    <esa-material-button type="primary" label="Select Files" icon="file_open" (click)="fileInput.click()" [disabled]="isLoading"></esa-material-button>-->
        <button cdsButton (click)="fileInput.click()" [disabled]="isLoading">Select Files</button>

        <input type="file" multiple hidden (change)="onFilesSelected(fileInput.files)" #fileInput />

        <div *ngIf="filesToUpload && filesToUpload.length > 0" style="margin-top: 2rem;">
            <ul style="margin-bottom: 2rem;">
                <li *ngFor="let file of filesToUpload; let i = index;" style="font-size: 16px; height: 32px;">
                    <span>{{ file.name }}</span>
                    <!--                <esa-material-button *ngIf="!isLoading" type="icon" icon="disabled_by_default" (click)="spliceFiles(i)" style="margin-left: 1rem;"></esa-material-button>-->
                    <button cdsButton *ngIf="!isLoading" (click)="spliceFiles(i)" style="margin-left: 1rem;">Remove</button>
                </li>
            </ul>

            <mat-spinner *ngIf="isLoading" style="margin: 1rem;"></mat-spinner>

            <!--        <esa-material-button type="primary" label="Upload Files" icon="publish" (click)="uploadFiles()" [disabled]="isLoading"></esa-material-button>-->
            <button cdsButton (click)="uploadFiles()" [disabled]="isLoading">Upload Files</button>
        </div>
    </div>
</ng-template>
