import {ChangeDetectorRef, Component, OnInit, SimpleChanges} from '@angular/core';
import {BasicFormComponent, FormElement, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {StateIdAndDisplayNameArrayUS} from 'src/app/shared/models/enums/state.enum.util';
import {AlertService} from 'src/app/shared/services/alert.service';
import {CurrentResearchProjectContactService} from "../../../../services/current-research-project-contact.service";
import {ResearchProjectContactService} from "../../../../shared/generated/api/research-project-contact.service";
import {ResearchProjectContactUpsertDto} from "../../../../shared/generated/model/research-project-contact-upsert-dto";
import {CountryIdAndDisplayNameArray} from "../../../../shared/models/enums/country.enum.util";
import {ContactFormComponent} from "./contact-form.component";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {
    ResearchTeamCreatePublicComponent
} from "../../../research-team/research-team-create-public/research-team-create-public.component";

@Component({
    selector: 'castateparksscp-contact-form-organization',
    templateUrl: '../../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../../basic/shared/basic-form/basic-form.component.scss'],
})
export class ContactFormOrganizationComponent extends BasicFormComponent implements OnInit {
    upsertDto: ResearchProjectContactUpsertDto;

    stateOptions: Array<{ text: string, value: number }> = StateIdAndDisplayNameArrayUS;
    countryOptions: Array<{ text: string, value: number }> = CountryIdAndDisplayNameArray;

    formPage: FormPage = {
        dtoName: 'contact'
    } as FormPage;

    buildFormElements(isAccessRestricted): FormElement[] {
        return [
            {
                class: 'g-col-12',
                name: 'OrganizationName',
                label: 'Applicant Organization',
                requiredValidated: true,
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'PhoneNumber1',
                label: 'Phone Number',
                requiredValidated: true,
                fieldDefinitionType: 'PhoneNumber',
                type: 'phoneNumber',
            },
            {
                class: 'g-col-6',
                name: 'Email',
                label: 'Email',
                requiredValidated: true,
                fieldDefinitionType: 'Email',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'AddressLine1',
                label: 'Organization Mailing Address Line 1',
                requiredValidated: true,
                fieldDefinitionType: 'AddressLine1',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'AddressLine2',
                label: 'Address Line 2',
                fieldDefinitionType: 'AddressLine2',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'CountryID',
                label: 'Country',
                requiredValidated: true,
                fieldDefinitionType: 'Country',
                selectDisplayFieldName: 'CountryDisplayName',
                type: 'select',
                selectOptions: this.countryOptions,
                onChange: ContactFormComponent.updateStateList,
            },
            {
                class: 'g-col-6',
                name: 'City',
                label: 'City',
                requiredValidated: true,
                fieldDefinitionType: 'City',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'StateID',
                label: 'State',
                fieldDefinitionType: 'State',
                selectDisplayFieldName: 'StateDisplayName',
                type: 'select',
                selectOptions: this.stateOptions,
            },
            {
                class: 'g-col-6',
                name: 'PostalCode',
                label: 'Zip Code',
                requiredValidated: true,
                fieldDefinitionType: 'PostalCode',
                type: 'text',
            },
        ] as FormElement[];
    }

    constructor(public currentResearchProjectContactService: CurrentResearchProjectContactService,
                public researchProjectContactService: ResearchProjectContactService,
                private cdr: ChangeDetectorRef,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => this.currentResearchProjectContactService.createResearchProjectContactDto(dto);
        this.formPage.post = (upsertDto) => this.researchProjectContactService.researchProjectContactsPost(upsertDto);
        this.formPage.put = (id, upsertDto) => this.researchProjectContactService.researchProjectContactsResearchProjectContactIDPut(id, upsertDto);
    }

    refreshFormElements(isAccessRestricted: boolean = false) {
        super.refreshFormElements(isAccessRestricted);
        if (this.upsertDto?.CountryID) {
            ContactFormComponent.updateStateList(this, !this.dto?.['StateID']);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        this.refreshFormElements();
    }
}
