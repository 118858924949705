import {Component, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {environment} from "../environments/environment";
import {NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router,} from "@angular/router";
import {BusyService} from "./shared/services";
import {Title} from "@angular/platform-browser";
import {AuthenticationService} from "./services/authentication.service";
import {UserDto} from "./shared/generated/model/user-dto";
import {Subscription} from "rxjs";
import {PermissionEnum} from "./shared/generated/enum/permission-enum";
import {RightsEnum} from "./shared/models/enums/rights.enum";
import {RoleEnum} from "./shared/generated/enum/role-enum";
import {UserService} from "./shared/generated/api/user.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    currentUser: UserDto;

    isIframe = false;
    userBeingImpersonated: boolean;
    userCanManageDashboard: boolean;
    userCanViewProjects: boolean;
    userCanViewPermits: boolean;
    userCanViewResearchProjects: boolean;
    userCanViewContacts: boolean;
    userCanViewStandardConditions: boolean;
    userCanViewSpecialConditions: boolean;
    userCanViewDistricts: boolean;
    userCanViewTags: boolean;
    userCanCreateNewApplication: boolean;
    environment: string;

    routeSubscription: Subscription;
    currentUserSub: Subscription;

    constructor(
        public router: Router,
        public busyService: BusyService,
        public titleService: Title,
        public viewRef: ViewContainerRef,
        public userService: UserService,
        public authenticationService: AuthenticationService
    ) {
    }

    ngOnInit(): void {
        this.isIframe = window !== window.parent && !window.opener;
        this.currentUserSub = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.userBeingImpersonated =
                    this.authenticationService.isCurrentUserBeingImpersonated(
                        this.currentUser
                    );
                this.userCanViewProjects =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.ProjectRights,
                        RightsEnum.Read
                    );
                this.userCanViewPermits =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.PermitRights,
                        RightsEnum.Read
                    );
                this.userCanViewResearchProjects =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.ResearchProjectRights,
                        RightsEnum.Read
                    );
                this.userCanViewContacts =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.ContactRights,
                        RightsEnum.Read
                    );
                this.userCanViewStandardConditions =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.StandardConditionRights,
                        RightsEnum.Read
                    );
                this.userCanViewSpecialConditions =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.SpecialConditionRights,
                        RightsEnum.Read
                    );
                this.userCanViewDistricts =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.DistrictRights,
                        RightsEnum.Read
                    );
                this.userCanManageDashboard = this.hasManageMenu();
                this.userCanCreateNewApplication = RoleEnum.Public == this.currentUser?.Role?.RoleID;
                this.userCanViewTags = RoleEnum.Public != this.currentUser?.Role?.RoleID &&
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.ResourceCategoryTagRights,
                        RightsEnum.Read
                    );
            });

        this.router.events.subscribe((event: any) => {
            if (event instanceof RouteConfigLoadStart) {
                // lazy loaded route started
                this.busyService.setBusy(true);
            } else if (event instanceof RouteConfigLoadEnd) {
                // lazy loaded route ended
                this.busyService.setBusy(false);
            } else if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });

        this.titleService.setTitle(`${environment.platformLongName}`);

        if (environment.dev) {
            this.environment = "dev";
        }

        if (environment.staging) {
            this.environment = "staging";
        }

        if (environment.production) {
            this.environment = "production";
        }
    }

    ngOnDestroy(): void {
        this.currentUserSub?.unsubscribe();
    }

    login(): void {
        this.authenticationService.login();

        // TODO the automatic navigation to the permits page is temporarily disabled
        // this.authenticationService.login(undefined,
        //     of(null).pipe(tap(() => this.router.navigateByUrl(`/permits`).then())));

        // this.authenticationService.login(undefined,
        //     of(null).pipe(tap(() => setTimeout(() => this.router.navigateByUrl(`/permits`).then(), 1000))));
    }

    logOut(): void {
        this.authenticationService.logout();
    }

    editProfile(): void {
        if (this.currentUser.ContactID) {
            this.router.navigateByUrl(`/contacts/${this.currentUser.ContactID}`).then(x => {
            });
        }
        else {
            this.userService.usersUserIDContactPost(this.currentUser.UserID).subscribe(ContactID => {
                this.router.navigateByUrl(`/contacts/${ContactID}`).then(x => {
                });
            });
        }
    }

    hasManageMenu(): boolean {
        return this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([RoleEnum.SystemAdmin]);
    }

    isInternal(): boolean {
        if (this.currentUser == undefined) return false;
        return this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([
            RoleEnum.SystemAdmin,
            RoleEnum.TechnicalReviewer,
            RoleEnum.DistrictStaff
        ]);
    }

    isPublic(): boolean {
        if (this.currentUser == undefined) return true;
        return this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([RoleEnum.Public]);
    }

    createNewApplication() {

    }
}
