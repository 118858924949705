<div>
    <div id="returnedToResearcher" *ngIf="showNotification()" class="m-y">
        <cds-inline-notification [notificationObj]="{
            type: 'info',
            lowContrast: true,
            showClose: false,
            template: notificationContent,
            title: 'Returned to Researcher'
        }"></cds-inline-notification>
        <ng-template #notificationContent let-data>
            <h3 cdsToastTitle>{{ data.title }}</h3>
            <h3 cdsToastSubtitle>Returned
                Date: {{ internalFields?.LatestReturnedToResearcherDate | date:'M/d/yyyy' }}</h3>
            <ul cdsToastCaption cdsList>
                <li *ngIf="internalFields?.DPR65ASubmission" cdsListItem>Insurance Addendum in the Additional
                    Documentation page
                </li>
                <li *ngIf="internalFields?.DPR65BSubmission" cdsListItem>Liability Waiver Addendum in the Research Team
                    page
                </li>
                <li *ngIf="internalFields?.AdditionalDocumentation" cdsListItem>Additional documentation
                    requested/Missing documents
                </li>
                <li *ngIf="internalFields?.OtherInformationOrChanges" cdsListItem>Other information or changes
                    requested
                </li>
                <li *ngIf="internalFields?.AdditionalRequestedMessage" cdsListItem>Additional
                    Information:<br/>{{ internalFields.AdditionalRequestedMessage }}
                </li>
            </ul>
        </ng-template>
    </div>
</div>

