import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'castateparksscp-research-project-specimen-collection-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectSpecimenCollectionFormComponent extends BasicFormComponent {
    upsertDto: SpecimenCollectionDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'CurationFacility',
                label: 'Curation facility',
                type: 'text',
                requiredValidated: true
            },
            {
                class: 'g-col-7',
                name: 'CurationResponsibleOfficialName',
                label: 'Responsible official name',
                type: 'text',
                requiredValidated: true
            },
            {
                class: 'g-col-5'
            },
            {
                class: 'g-col-3',
                name: 'CurationPhone',
                label: 'Phone',
                type: 'phoneNumber',
                requiredValidated: true
            },
            {
                class: 'g-col-3',
                name: 'CurationEmail',
                label: 'Email',
                type: 'text',
                requiredValidated: true
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                CurationFacility: dto?.CurationFacility,
                CurationResponsibleOfficialName: dto?.CurationResponsibleOfficialName,
                CurationPhone: dto?.CurationPhone,
                CurationEmail: dto?.CurationEmail,
            } as SpecimenCollectionDto;
        }
    }
}

export interface SpecimenCollectionDto {
    CurationFacility: string;
    CurationResponsibleOfficialName: string;
    CurationPhone: string;
    CurationEmail: string;
}
