import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {BasicListComponent, ListPage} from '../../basic/basic-list/basic-list.component';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {DistrictService} from 'src/app/shared/generated/api/district.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {DateColumnCreatorService} from 'src/app/shared/services/date-column-creator/date-column-creator.service';
import {AlertService} from 'src/app/shared/services/alert.service';
import {LinkRendererComponent} from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';

@Component({
    selector: 'castateparksscp-district-list',
    templateUrl: "../../basic/basic-list/basic-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistrictListComponent extends BasicListComponent implements OnInit {
    listPage: ListPage = {
        permission: PermissionEnum.DistrictRights,
        pageTitle: 'Districts',
        downloadFileName: 'districts',
        defaultColDef: {
            sortable: true,
            filter: false,
            resizable: true,
            floatingFilter: false,
            suppressMenu: true,
        }
    } as ListPage;

    constructor(
        public districtService: DistrictService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.districtService.districtsGet();
        this.listPage.columnDefs = [
            {
                field: "Name",
                headerName: "Name",
                headerTooltip: "Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.DistrictID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return {
                        component: LinkRendererComponent,
                        params: {inRouterLink: "/districts/"},
                    };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (
                    linkA,
                    linkB,
                    nodeA,
                    nodeB,
                    isDescending
                ) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 2,
                sort: "asc",
            },
            {
                headerName: "Phone number",
                field: "PhoneNumber",
                tooltipField: "PhoneNumber",
                flex: 1,
            }
        ];
    }

    override onGridReady(gridEvent) {
        this.grid.api.showLoadingOverlay();

        this.getRequest = this.listPage
            .dtosGetter()
            .subscribe((results) => {
                this.rowData = results;
                this.grid.api.hideOverlay();
                this.dtos = results;
                this.cdr.markForCheck();
            });
    }
}
