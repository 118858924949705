import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Step } from 'carbon-components-angular/progress-indicator/progress-indicator-step.interface';
import { TinymceEditorComponent } from 'src/app/shared/components/tinymce-editor/tinymce-editor.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
    selector: 'castateparksscp-permit-form',
    templateUrl: './permit-form.component.html',
    styleUrl: './permit-form.component.css'
})
export class PermitFormComponent implements OnInit {
    @ViewChild("permitForm", {read: NgForm}) form: NgForm;
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() permitID: number;
    @Input() permit: PermitUpsertDto;
    @Input() editMode: boolean;
    @Input() useCDS: boolean = true;

    permitUpsertDto: PermitUpsertDto;

    constructor(private alertService: AlertService) {
    }

    //TODO: add services
    // constructor(private currentPermitService: CurrentPermitService,
    //             private permitService: PermitService,
    //             private alertService: AlertService) {
    // }

    ngOnInit(): void {
        if (this.permit) {
            this.setForm();
        }
    }

    setForm() {
        //this.permitUpsertDto = this.currentPermitService.createPermitDto(this.permit);
        this.permitUpsertDto = {} as PermitUpsertDto;
    }

    saveForm(form: NgForm) {
        // if (!!this.permitID) {
        //     this.permitService.permitsPermitIDPut(this.permitID, this.permitUpsertDto)
        //         .subscribe((result) => {
        //             this.alertService.pushAlert(new Alert("The permit was successfully updated.", AlertContext.Success), 5000);
        //             this.formSubmitted.emit(result);
        //         });
        // } else {
        //     this.permitService.permitsPost(this.permitUpsertDto)
        //         .subscribe((result) => {
        //                 this.formSubmitted.emit(result);
        //                 this.alertService.pushAlert(new Alert("The permit was successfully created.", AlertContext.Success), 5000);
        //             },
        //             () => {
        //                 this.formSubmitted.emit(null);
        //             });
        // }
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }

    skeleton: boolean = false;
    steps: Array<Step> = [
        {
            label: 'Step 1',
            secondaryLabel: 'Optional label 1',
            description: 'Description 1'
        },
        {
            label: 'Step 2',
            secondaryLabel: 'Optional label 2',
            description: 'Description 2'
        },
        {
            label: 'Step 3',
            secondaryLabel: 'Optional label 3',
            description: 'Description 3'
        },
        {
            label: 'Step 4',
            secondaryLabel: 'Optional label 4',
            description: 'Description 4'
        },
    ];
    orientation: string = 'horizontal';
    current: number = 0;
    spacing: string = "default";

    stepSelected(event: any) {
    }
}

export class PermitUpsertDto {
    Name: string;
    Description?: string;
    PermitDate?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
