import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'castateparksscp-research-project-soil-disturbance-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectSoilDisturbanceFormComponent extends BasicFormComponent {
    upsertDto: SoilDisturbanceDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'SoilDisturbanceDetails',
                label: 'Soil disturbance details',
                type: 'textArea',
                helperText: 'Describe why collection is necessary, the disposition of specimens collected. Provide power analysis to justify proposed sample size(s).',
                checkbox: true,
                checkboxName: 'ProposalIncludesSoilDisturbance',
                requiredValidated: true
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                SoilDisturbanceDetails: dto?.SoilDisturbanceDetails,
                ProposalIncludesSoilDisturbance: dto?.ProposalIncludesSoilDisturbance} as SoilDisturbanceDto
        };
    }
}

export interface SoilDisturbanceDto {
    SoilDisturbanceDetails: string;
    ProposalIncludesSoilDisturbance: boolean;
}
