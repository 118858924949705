<h2 mat-dialog-title>{{ data.header }}</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="!!contact">
        <castateparksscp-district-contact-form
            #districtContactForm
            [ID]="contact.ContactID"
            [districtID]="districtID"
            [isCDS]="true"
            [dto]="contact"
            [editMode]="canEdit()"
            (formSubmitted)="handleFormSubmitEvent($event)">
        </castateparksscp-district-contact-form>
    </ng-container>
</mat-dialog-content>
<ng-container *ngIf="showActions">
    <mat-dialog-actions align="end">
        <esa-material-button
            (click)="saveButtonClicked($event)"
            [disabled]="isFormInvalid()"
            icon="save"
            label="Save Contact"
            style="margin-right: 1rem"
            type="primary">
        </esa-material-button>
        <esa-material-button
            mat-dialog-close
            label="Cancel"
            icon="cancel"
            type="clear"
        ></esa-material-button>
    </mat-dialog-actions>
</ng-container>

