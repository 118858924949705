import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'castateparksscp-readonly-notification',
  templateUrl: './readonly-notification.component.html',
})
export class ReadonlyNotificationComponent implements OnInit {
    ngOnInit(): void {}

    @Input() show: boolean = false;
}
