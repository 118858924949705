import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from "../../basic/basic-list/basic-list.component";
import {PermissionEnum} from "../../../shared/generated/enum/permission-enum";
import {AuthenticationService} from "../../../services/authentication.service";
import {DateColumnCreatorService} from "../../../shared/services/date-column-creator/date-column-creator.service";
import {Router} from "@angular/router";
import {AlertService} from "../../../shared/services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {
    ResearchProjectSpecialConditionService
} from "../../../shared/generated/api/research-project-special-condition.service";
import {
    InLineEditButtonsRendererComponent
} from "../../../shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component";
import {HtmlRendererComponent} from "../../../shared/components/ag-grid/html-renderer/html-renderer.component";
import {GetRowIdFunc, GetRowIdParams} from "ag-grid-community";
import {
    ResearchProjectSpecialConditionSimpleDto
} from "../../../shared/generated/model/research-project-special-condition-simple-dto";
import {RequiredHeaderComponent} from "../../../shared/components/ag-grid/required-header/required-header.component";
import {BasicConfigurableModalComponent} from "../../basic/basic-configurable-modal/basic-configurable-modal.component";
import {FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {DistrictService} from "../../../shared/generated/api/district.service";

@Component({
    selector: 'castateparksscp-research-project-district-special-conditions-grid',
    templateUrl: '../../basic/basic-list/basic-list.component.html',
})
export class ResearchProjectDistrictSpecialConditionsGridComponent extends BasicListComponent implements OnInit {
    @Input() researchProjectID: number;
    isProjectedTitle: boolean = true;

    listPage: ListPage = {
        permission: PermissionEnum.InternalReviewRights,
        pageTitle: 'District Special Conditions',
        createButtonLabel: 'District Special Condition',
        downloadFileName: 'researchProjectSpecialConditions',
        isShowAddButton: true,
        addButtonCdsButton: 'secondary',
        iSShowTitle: true,
        defaultColDef: {
            sortable: true,
            filter: false,
            resizable: true,
            floatingFilter: false,
            suppressMenu: true,
        },
        isHideDownload: true,
    } as ListPage;
    useInGridEdit: boolean = false;
    public idField: string = 'ResearchProjectSpecialConditionID';
    public startEditingColKey: string = 'ResearchProjectSpecialConditionID';
    public districtSelectData: {text: string, value: number}[];
    private defaultDistrictID: number = 1;
    hasListViewPadding = false;

    constructor(
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
        public researchProjectSpecialConditionService: ResearchProjectSpecialConditionService,
        public districtService: DistrictService
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.researchProjectSpecialConditionService.researchProjectDistrictSpecialConditionsResearchProjectIDGet(this.researchProjectID);
        this.listPage.columnDefs = [
            {
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                            editableCallback: this.getCanEdit.bind(this),
                        },
                    };
                },
                field: "ResearchProjectSpecialConditionID",
                width: 100,
                pinned: 'left',
                filterable: false,
                headerName: "",
                editable: false,
            },
            {
                field: "Content",
                headerName: "Condition",
                cellRendererSelector: (params) => {
                    return {
                        component: HtmlRendererComponent,
                        params: params
                    }
                },
                flex: 5,
                editable: false,
                wrapText: true,
                autoHeight: true,
                width: 100,
            },
            {
                field: "DistrictName",
                headerName: "District",
                suppressNavigable: true,
                flex: 2,
                editable: false,
            },
        ]
    }

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ResearchProjectSpecialConditionSimpleDto> | any) => params.data.ResearchProjectSpecialConditionID;

    public putAction = (id: number, dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectSpecialConditionService.researchProjectSpecialConditionsResearchProjectSpecialConditionIDPut(id, dto);
    }

    public postAction = (dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectSpecialConditionService.researchProjectSpecialConditionsPost(dto);
    }

    public deleteAction = (id: number) => {
        return this.researchProjectSpecialConditionService.researchProjectSpecialConditionsResearchProjectSpecialConditionIDDelete(id);
    }

    ngOnInit() {
        super.ngOnInit();
        this.gridComponents['agColumnHeader'] = RequiredHeaderComponent;

        this.districtService.districtsGet().subscribe(districts => {
            this.districtSelectData = districts.map(district => {
                return {text: district.Name, value: district.DistrictID}
            });
        });
    }

    override doActionAfterSettingCurrentUser() {
        this.defaultDistrictID = this.currentUser?.DistrictID || 1;
    }

    private getCanEdit() {
        return this.canEdit
    };

    override edit(params) {
        this.openEditModal(params);
    }

    override navigateToCreatePage() {
        this.openEditModal();
    }

    private openEditModal(params?) {
        let dialogRef = this.dialog.open(BasicConfigurableModalComponent, {
            data: {
                ID: !!params ? params.node.data.ResearchProjectSpecialConditionID : null,
                dto: !!params ? params.node.data :
                    {
                        Content: '',
                        DistrictID: this.defaultDistrictID,
                    },
                header: 'Edit District Special Condition',
                showActions: true,
                editMode: true,
                formPage: {
                    dtoName: 'research project district special condition',
                    elements: [
                        {
                            class: 'g-col-4',
                            name: 'DistrictID',
                            label: 'District',
                            type: 'select',
                            selectDisplayFieldName: 'DistrictName',
                            selectOptions: this.districtSelectData,
                            requiredValidated: true,

                        },
                        {
                            class: 'g-col-12',
                            name: 'Content',
                            label: 'Condition',
                            type: 'editor',
                            editorConfig: {toolbar: true, menubar: true, plugins: 'lists link table help wordcount'}
                        },
                    ],
                    createUpsertDto: (dto) => {
                        return dto;
                    },
                    put: (id, dto) => this.putAction(id, dto),
                    post: (dto) => this.postAction(dto)
                } as FormPage
            }
        });

        if (dialogRef) {
            dialogRef.afterClosed().subscribe(() => {
                this.editEvent.emit();
                this.refreshData();
            });
        }
    }

}
