export const CountryIdToDisplayNameMap: Map<number, string> = new Map(
    [[3, "Afghanistan"],
        [15, "Aland Islands"],
        [6, "Albania"],
        [62, "Algeria"],
        [11, "American Samoa"],
        [1, "Andorra"],
        [8, "Angola"],
        [5, "Anguilla"],
        [9, "Antarctica"],
        [4, "Antigua and Barbuda"],
        [10, "Argentina"],
        [7, "Armenia"],
        [14, "Aruba"],
        [13, "Australia"],
        [12, "Austria"],
        [16, "Azerbaijan"],
        [32, "Bahamas"],
        [23, "Bahrain"],
        [19, "Bangladesh"],
        [18, "Barbados"],
        [36, "Belarus"],
        [20, "Belgium"],
        [37, "Belize"],
        [25, "Benin"],
        [27, "Bermuda"],
        [33, "Bhutan"],
        [29, "Bolivia"],
        [30, "Bonaire, Saint Eustatius and Saba"],
        [17, "Bosnia and Herzegovina"],
        [35, "Botswana"],
        [34, "Bouvet Island"],
        [31, "Brazil"],
        [106, "British Indian Ocean Territory"],
        [240, "British Virgin Islands"],
        [28, "Brunei"],
        [22, "Bulgaria"],
        [21, "Burkina Faso"],
        [24, "Burundi"],
        [117, "Cambodia"],
        [47, "Cameroon"],
        [38, "Canada"],
        [52, "Cape Verde"],
        [125, "Cayman Islands"],
        [41, "Central African Republic"],
        [216, "Chad"],
        [46, "Chile"],
        [48, "China"],
        [54, "Christmas Island"],
        [39, "Cocos Islands"],
        [49, "Colombia"],
        [119, "Comoros"],
        [45, "Cook Islands"],
        [50, "Costa Rica"],
        [98, "Croatia"],
        [51, "Cuba"],
        [53, "Curacao"],
        [55, "Cyprus"],
        [56, "Czech Republic"],
        [40, "Democratic Republic of the Congo"],
        [59, "Denmark"],
        [58, "Djibouti"],
        [60, "Dominica"],
        [61, "Dominican Republic"],
        [222, "East Timor"],
        [63, "Ecuador"],
        [65, "Egypt"],
        [211, "El Salvador"],
        [88, "Equatorial Guinea"],
        [67, "Eritrea"],
        [64, "Estonia"],
        [69, "Ethiopia"],
        [72, "Falkland Islands"],
        [74, "Faroe Islands"],
        [71, "Fiji"],
        [70, "Finland"],
        [75, "France"],
        [80, "French Guiana"],
        [176, "French Polynesia"],
        [217, "French Southern Territories"],
        [76, "Gabon"],
        [85, "Gambia"],
        [79, "Georgia"],
        [57, "Germany"],
        [82, "Ghana"],
        [83, "Gibraltar"],
        [89, "Greece"],
        [84, "Greenland"],
        [78, "Grenada"],
        [87, "Guadeloupe"],
        [92, "Guam"],
        [91, "Guatemala"],
        [81, "Guernsey"],
        [86, "Guinea"],
        [93, "Guinea-Bissau"],
        [94, "Guyana"],
        [99, "Haiti"],
        [96, "Heard Island and McDonald Islands"],
        [97, "Honduras"],
        [95, "Hong Kong"],
        [100, "Hungary"],
        [109, "Iceland"],
        [105, "India"],
        [101, "Indonesia"],
        [108, "Iran"],
        [107, "Iraq"],
        [102, "Ireland"],
        [104, "Isle of Man"],
        [103, "Israel"],
        [110, "Italy"],
        [44, "Ivory Coast"],
        [112, "Jamaica"],
        [114, "Japan"],
        [111, "Jersey"],
        [113, "Jordan"],
        [126, "Kazakhstan"],
        [115, "Kenya"],
        [118, "Kiribati"],
        [123, "Kosovo"],
        [124, "Kuwait"],
        [116, "Kyrgyzstan"],
        [127, "Laos"],
        [136, "Latvia"],
        [128, "Lebanon"],
        [133, "Lesotho"],
        [132, "Liberia"],
        [137, "Libya"],
        [130, "Liechtenstein"],
        [134, "Lithuania"],
        [135, "Luxembourg"],
        [149, "Macao"],
        [145, "Macedonia"],
        [143, "Madagascar"],
        [157, "Malawi"],
        [159, "Malaysia"],
        [156, "Maldives"],
        [146, "Mali"],
        [154, "Malta"],
        [144, "Marshall Islands"],
        [151, "Martinique"],
        [152, "Mauritania"],
        [155, "Mauritius"],
        [247, "Mayotte"],
        [158, "Mexico"],
        [73, "Micronesia"],
        [140, "Moldova"],
        [139, "Monaco"],
        [148, "Mongolia"],
        [141, "Montenegro"],
        [153, "Montserrat"],
        [138, "Morocco"],
        [160, "Mozambique"],
        [147, "Myanmar"],
        [161, "Namibia"],
        [170, "Nauru"],
        [169, "Nepal"],
        [167, "Netherlands"],
        [252, "Netherlands Antilles"],
        [162, "New Caledonia"],
        [172, "New Zealand"],
        [166, "Nicaragua"],
        [163, "Niger"],
        [165, "Nigeria"],
        [171, "Niue"],
        [164, "Norfolk Island"],
        [121, "North Korea"],
        [150, "Northern Mariana Islands"],
        [168, "Norway"],
        [173, "Oman"],
        [179, "Pakistan"],
        [186, "Palau"],
        [184, "Palestinian Territory"],
        [174, "Panama"],
        [177, "Papua New Guinea"],
        [187, "Paraguay"],
        [175, "Peru"],
        [178, "Philippines"],
        [182, "Pitcairn"],
        [180, "Poland"],
        [185, "Portugal"],
        [183, "Puerto Rico"],
        [188, "Qatar"],
        [42, "Republic of the Congo"],
        [189, "Reunion"],
        [190, "Romania"],
        [192, "Russia"],
        [193, "Rwanda"],
        [26, "Saint Barthelemy"],
        [201, "Saint Helena"],
        [120, "Saint Kitts and Nevis"],
        [129, "Saint Lucia"],
        [142, "Saint Martin"],
        [181, "Saint Pierre and Miquelon"],
        [238, "Saint Vincent and the Grenadines"],
        [245, "Samoa"],
        [206, "San Marino"],
        [210, "Sao Tome and Principe"],
        [194, "Saudi Arabia"],
        [207, "Senegal"],
        [191, "Serbia"],
        [251, "Serbia and Montenegro"],
        [196, "Seychelles"],
        [205, "Sierra Leone"],
        [200, "Singapore"],
        [212, "Sint Maarten"],
        [204, "Slovakia"],
        [202, "Slovenia"],
        [195, "Solomon Islands"],
        [208, "Somalia"],
        [248, "South Africa"],
        [90, "South Georgia and the South Sandwich Islands"],
        [122, "South Korea"],
        [198, "South Sudan"],
        [68, "Spain"],
        [131, "Sri Lanka"],
        [197, "Sudan"],
        [209, "Suriname"],
        [203, "Svalbard and Jan Mayen"],
        [214, "Swaziland"],
        [199, "Sweden"],
        [43, "Switzerland"],
        [213, "Syria"],
        [229, "Taiwan"],
        [220, "Tajikistan"],
        [230, "Tanzania"],
        [219, "Thailand"],
        [218, "Togo"],
        [221, "Tokelau"],
        [225, "Tonga"],
        [227, "Trinidad and Tobago"],
        [224, "Tunisia"],
        [226, "Turkey"],
        [223, "Turkmenistan"],
        [215, "Turks and Caicos Islands"],
        [228, "Tuvalu"],
        [241, "U.S. Virgin Islands"],
        [232, "Uganda"],
        [231, "Ukraine"],
        [2, "United Arab Emirates"],
        [77, "United Kingdom"],
        [234, "United States"],
        [233, "United States Minor Outlying Islands"],
        [235, "Uruguay"],
        [236, "Uzbekistan"],
        [243, "Vanuatu"],
        [237, "Vatican"],
        [239, "Venezuela"],
        [242, "Vietnam"],
        [244, "Wallis and Futuna"],
        [66, "Western Sahara"],
        [246, "Yemen"],
        [249, "Zambia"],
        [250, "Zimbabwe"],
    ])


export const CountryIdAndDisplayNameArray: Array<{ text: string, value: number}> = [
    {text: "Afghanistan", value:3 },
    {text: "Aland Islands", value:15 },
    {text: "Albania", value:6 },
    {text: "Algeria", value:62 },
    {text: "American Samoa", value:11 },
    {text: "Andorra", value:1 },
    {text: "Angola", value:8 },
    {text: "Anguilla", value:5 },
    {text: "Antarctica", value:9 },
    {text: "Antigua and Barbuda", value:4 },
    {text: "Argentina", value:10 },
    {text: "Armenia", value:7 },
    {text: "Aruba", value:14 },
    {text: "Australia", value:13 },
    {text: "Austria", value:12 },
    {text: "Azerbaijan", value:16 },
    {text: "Bahamas", value:32 },
    {text: "Bahrain", value:23 },
    {text: "Bangladesh", value:19 },
    {text: "Barbados", value:18 },
    {text: "Belarus", value:36 },
    {text: "Belgium", value:20 },
    {text: "Belize", value:37 },
    {text: "Benin", value:25 },
    {text: "Bermuda", value:27 },
    {text: "Bhutan", value:33 },
    {text: "Bolivia", value:29 },
    {text: "Bonaire, Saint Eustatius and Saba", value:30 },
    {text: "Bosnia and Herzegovina", value:17 },
    {text: "Botswana", value:35 },
    {text: "Bouvet Island", value:34 },
    {text: "Brazil", value:31 },
    {text: "British Indian Ocean Territory", value:106 },
    {text: "British Virgin Islands", value:240 },
    {text: "Brunei", value:28 },
    {text: "Bulgaria", value:22 },
    {text: "Burkina Faso", value:21 },
    {text: "Burundi", value:24 },
    {text: "Cambodia", value:117 },
    {text: "Cameroon", value:47 },
    {text: "Canada", value:38 },
    {text: "Cape Verde", value:52 },
    {text: "Cayman Islands", value:125 },
    {text: "Central African Republic", value:41 },
    {text: "Chad", value:216 },
    {text: "Chile", value:46 },
    {text: "China", value:48 },
    {text: "Christmas Island", value:54 },
    {text: "Cocos Islands", value:39 },
    {text: "Colombia", value:49 },
    {text: "Comoros", value:119 },
    {text: "Cook Islands", value:45 },
    {text: "Costa Rica", value:50 },
    {text: "Croatia", value:98 },
    {text: "Cuba", value:51 },
    {text: "Curacao", value:53 },
    {text: "Cyprus", value:55 },
    {text: "Czech Republic", value:56 },
    {text: "Democratic Republic of the Congo", value:40 },
    {text: "Denmark", value:59 },
    {text: "Djibouti", value:58 },
    {text: "Dominica", value:60 },
    {text: "Dominican Republic", value:61 },
    {text: "East Timor", value:222 },
    {text: "Ecuador", value:63 },
    {text: "Egypt", value:65 },
    {text: "El Salvador", value:211 },
    {text: "Equatorial Guinea", value:88 },
    {text: "Eritrea", value:67 },
    {text: "Estonia", value:64 },
    {text: "Ethiopia", value:69 },
    {text: "Falkland Islands", value:72 },
    {text: "Faroe Islands", value:74 },
    {text: "Fiji", value:71 },
    {text: "Finland", value:70 },
    {text: "France", value:75 },
    {text: "French Guiana", value:80 },
    {text: "French Polynesia", value:176 },
    {text: "French Southern Territories", value:217 },
    {text: "Gabon", value:76 },
    {text: "Gambia", value:85 },
    {text: "Georgia", value:79 },
    {text: "Germany", value:57 },
    {text: "Ghana", value:82 },
    {text: "Gibraltar", value:83 },
    {text: "Greece", value:89 },
    {text: "Greenland", value:84 },
    {text: "Grenada", value:78 },
    {text: "Guadeloupe", value:87 },
    {text: "Guam", value:92 },
    {text: "Guatemala", value:91 },
    {text: "Guernsey", value:81 },
    {text: "Guinea", value:86 },
    {text: "Guinea-Bissau", value:93 },
    {text: "Guyana", value:94 },
    {text: "Haiti", value:99 },
    {text: "Heard Island and McDonald Islands", value:96 },
    {text: "Honduras", value:97 },
    {text: "Hong Kong", value:95 },
    {text: "Hungary", value:100 },
    {text: "Iceland", value:109 },
    {text: "India", value:105 },
    {text: "Indonesia", value:101 },
    {text: "Iran", value:108 },
    {text: "Iraq", value:107 },
    {text: "Ireland", value:102 },
    {text: "Isle of Man", value:104 },
    {text: "Israel", value:103 },
    {text: "Italy", value:110 },
    {text: "Ivory Coast", value:44 },
    {text: "Jamaica", value:112 },
    {text: "Japan", value:114 },
    {text: "Jersey", value:111 },
    {text: "Jordan", value:113 },
    {text: "Kazakhstan", value:126 },
    {text: "Kenya", value:115 },
    {text: "Kiribati", value:118 },
    {text: "Kosovo", value:123 },
    {text: "Kuwait", value:124 },
    {text: "Kyrgyzstan", value:116 },
    {text: "Laos", value:127 },
    {text: "Latvia", value:136 },
    {text: "Lebanon", value:128 },
    {text: "Lesotho", value:133 },
    {text: "Liberia", value:132 },
    {text: "Libya", value:137 },
    {text: "Liechtenstein", value:130 },
    {text: "Lithuania", value:134 },
    {text: "Luxembourg", value:135 },
    {text: "Macao", value:149 },
    {text: "Macedonia", value:145 },
    {text: "Madagascar", value:143 },
    {text: "Malawi", value:157 },
    {text: "Malaysia", value:159 },
    {text: "Maldives", value:156 },
    {text: "Mali", value:146 },
    {text: "Malta", value:154 },
    {text: "Marshall Islands", value:144 },
    {text: "Martinique", value:151 },
    {text: "Mauritania", value:152 },
    {text: "Mauritius", value:155 },
    {text: "Mayotte", value:247 },
    {text: "Mexico", value:158 },
    {text: "Micronesia", value:73 },
    {text: "Moldova", value:140 },
    {text: "Monaco", value:139 },
    {text: "Mongolia", value:148 },
    {text: "Montenegro", value:141 },
    {text: "Montserrat", value:153 },
    {text: "Morocco", value:138 },
    {text: "Mozambique", value:160 },
    {text: "Myanmar", value:147 },
    {text: "Namibia", value:161 },
    {text: "Nauru", value:170 },
    {text: "Nepal", value:169 },
    {text: "Netherlands", value:167 },
    {text: "Netherlands Antilles", value:252 },
    {text: "New Caledonia", value:162 },
    {text: "New Zealand", value:172 },
    {text: "Nicaragua", value:166 },
    {text: "Niger", value:163 },
    {text: "Nigeria", value:165 },
    {text: "Niue", value:171 },
    {text: "Norfolk Island", value:164 },
    {text: "North Korea", value:121 },
    {text: "Northern Mariana Islands", value:150 },
    {text: "Norway", value:168 },
    {text: "Oman", value:173 },
    {text: "Pakistan", value:179 },
    {text: "Palau", value:186 },
    {text: "Palestinian Territory", value:184 },
    {text: "Panama", value:174 },
    {text: "Papua New Guinea", value:177 },
    {text: "Paraguay", value:187 },
    {text: "Peru", value:175 },
    {text: "Philippines", value:178 },
    {text: "Pitcairn", value:182 },
    {text: "Poland", value:180 },
    {text: "Portugal", value:185 },
    {text: "Puerto Rico", value:183 },
    {text: "Qatar", value:188 },
    {text: "Republic of the Congo", value:42 },
    {text: "Reunion", value:189 },
    {text: "Romania", value:190 },
    {text: "Russia", value:192 },
    {text: "Rwanda", value:193 },
    {text: "Saint Barthelemy", value:26 },
    {text: "Saint Helena", value:201 },
    {text: "Saint Kitts and Nevis", value:120 },
    {text: "Saint Lucia", value:129 },
    {text: "Saint Martin", value:142 },
    {text: "Saint Pierre and Miquelon", value:181 },
    {text: "Saint Vincent and the Grenadines", value:238 },
    {text: "Samoa", value:245 },
    {text: "San Marino", value:206 },
    {text: "Sao Tome and Principe", value:210 },
    {text: "Saudi Arabia", value:194 },
    {text: "Senegal", value:207 },
    {text: "Serbia", value:191 },
    {text: "Serbia and Montenegro", value:251 },
    {text: "Seychelles", value:196 },
    {text: "Sierra Leone", value:205 },
    {text: "Singapore", value:200 },
    {text: "Sint Maarten", value:212 },
    {text: "Slovakia", value:204 },
    {text: "Slovenia", value:202 },
    {text: "Solomon Islands", value:195 },
    {text: "Somalia", value:208 },
    {text: "South Africa", value:248 },
    {text: "South Georgia and the South Sandwich Islands", value:90 },
    {text: "South Korea", value:122 },
    {text: "South Sudan", value:198 },
    {text: "Spain", value:68 },
    {text: "Sri Lanka", value:131 },
    {text: "Sudan", value:197 },
    {text: "Suriname", value:209 },
    {text: "Svalbard and Jan Mayen", value:203 },
    {text: "Swaziland", value:214 },
    {text: "Sweden", value:199 },
    {text: "Switzerland", value:43 },
    {text: "Syria", value:213 },
    {text: "Taiwan", value:229 },
    {text: "Tajikistan", value:220 },
    {text: "Tanzania", value:230 },
    {text: "Thailand", value:219 },
    {text: "Togo", value:218 },
    {text: "Tokelau", value:221 },
    {text: "Tonga", value:225 },
    {text: "Trinidad and Tobago", value:227 },
    {text: "Tunisia", value:224 },
    {text: "Turkey", value:226 },
    {text: "Turkmenistan", value:223 },
    {text: "Turks and Caicos Islands", value:215 },
    {text: "Tuvalu", value:228 },
    {text: "U.S. Virgin Islands", value:241 },
    {text: "Uganda", value:232 },
    {text: "Ukraine", value:231 },
    {text: "United Arab Emirates", value:2 },
    {text: "United Kingdom", value:77 },
    {text: "United States", value:234 },
    {text: "United States Minor Outlying Islands", value:233 },
    {text: "Uruguay", value:235 },
    {text: "Uzbekistan", value:236 },
    {text: "Vanuatu", value:243 },
    {text: "Vatican", value:237 },
    {text: "Venezuela", value:239 },
    {text: "Vietnam", value:242 },
    {text: "Wallis and Futuna", value:244 },
    {text: "Western Sahara", value:66 },
    {text: "Yemen", value:246 },
    {text: "Zambia", value:249 },
    {text: "Zimbabwe", value:250 },
]


