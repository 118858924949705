import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CurrentProjectService} from 'src/app/services/current-project.service';
import {EditViewEventService} from 'src/app/services/edit-view-event.service';
import {ProjectService} from 'src/app/shared/generated/api/project.service';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {AlertService} from 'src/app/shared/services/alert.service';
import {BasicDetailComponent, DetailPage} from '../../basic/basic-detail/basic-detail.component';
import { ProjectDto } from 'src/app/shared/generated/model/project-dto';

@Component({
    selector: 'castateparksscp-project-detail',
    styleUrls: ['../../basic/basic-detail/basic-detail.component.scss'],
    templateUrl: '../../basic/basic-detail/basic-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ProjectDetailComponent extends BasicDetailComponent implements OnInit {

    public dto?: ProjectDto | undefined;

    public titleField: string = 'Name';

    detailPage: DetailPage = {
        permission: PermissionEnum.ProjectRights,
        editMode: false,
        dtoName: 'Project',
    } as DetailPage;

    constructor(
        public projectService: ProjectService,
        public currentProjectService: CurrentProjectService,
        public authenticationService: AuthenticationService,
        public editViewEventService: EditViewEventService,
        public alertService: AlertService,
        public router: Router,
        public route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        super(authenticationService, editViewEventService, alertService, router, route, cdr, dialog);
    }

    ngOnInit(): void {
        const projectID = parseInt(this.route.snapshot.paramMap.get("id"));
        this.detailPage.sideNav = [
            {
                Name: "Summary",
                Link: `/projects/${projectID}`
            },
            {
                Name: "File List",
                Link: `/projects/${projectID}/file-list`
            }
        ];
        this.detailPage.dtoGetter = () => this.projectService.projectsProjectIDGet(projectID);
        this.detailPage.setCurrentDto = (data) => this.currentProjectService.setCurrentProject(data);
        this.detailPage.dtoDelete = () => this.projectService.projectsProjectIDDelete(this.dto?.ProjectID);
        this.detailPage.currentDtoObserver = () => this.currentProjectService.getCurrentProject()
        this.detailPage.getDeleteWarningMessage = (dto) => {
            return `You are about to delete project ${dto?.Name}. This action cannot be undone. Are you sure you wish to proceed?`
        }
        this.detailPage.deleteSuccessRoute = '/projects';
        super.ngOnInit();
    }
}
