import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchProjectInformationFormComponent} from "./research-project-information-form.component";
import {
    ResearchProjectFieldInvestigationOccurrenceListComponent
} from "./research-project-field-investigation-occurrence-list.component";
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectDto} from "../../../shared/generated/model/research-project-dto";
import {ResearchProjectFileTypeEnum} from "../../../shared/generated/enum/research-project-file-type-enum";
import {
    ResearchProjectBaseComponentComponent
} from "../shared/research-project-base-component/research-project-base-component.component";
import {finalize, forkJoin} from "rxjs";
import {AuthenticationService} from "../../../services/authentication.service";
import {Title} from "@angular/platform-browser";
import {
    ReturnedToResearcherNotificationComponent
} from "../shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'castateparksscp-research-project-create-public',
    templateUrl: './research-project-create-public.component.html',
    styleUrl: './research-project-create-public.component.css'
})
export class ResearchProjectCreatePublicComponent extends ResearchProjectBaseComponentComponent implements OnInit {
    pageSpecificTitle = 'Project Information';
    stepIndex: number = 1;

    researchProjectID: number;

    @ViewChild("researchProjectInformationForm", {static: true})
    public researchProjectInformationForm: ResearchProjectInformationFormComponent;

    @ViewChild("researchProjectFieldInvestigationOccurrenceList", {static: true})
    public researchProjectFieldInvestigationOccurrenceList: ResearchProjectFieldInvestigationOccurrenceListComponent;

    @ViewChild("notificationComponent", {static: true})
    public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public dialog : MatDialog,
        public researchProjectService: ResearchProjectService,
        public alertService: AlertService,
        public authenticationService: AuthenticationService,
        public titleService: Title
    ) {
        super(titleService);
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        this.researchProjectInformationForm.ID = this.researchProjectID;
        forkJoin({
            user: this.authenticationService.getCurrentUser(),
            researchProjectDto: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
        })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(({user, researchProjectDto}) => {
                this.researchProject = researchProjectDto;
                this.setTitle(researchProjectDto);
                this.user = user;
                this.setCanEditPage(user, researchProjectDto, this.router);
            })
    }

    researchProjectInformationSaved(researchProject: ResearchProjectDto): void {
        let url = this.stepSelectedRoute;
        if (!url)
            url = this.incompleteSectionsExcludingReviewAndSubmitExist(researchProject)
                ? `/public/research-project-study-areas/${this.researchProjectID}`
                :`/public/research-project-review-and-submit/${this.researchProjectID}`;
        this.router.navigateByUrl(url).then(x => {});
    }

    cancelEditResearchProject(): void {
    }

    saveAndContinue(): void {
        this.stepSelectedRoute = ''
        this.researchProjectInformationForm.save();
    }
    handleStepSelect(route: string): void {
        this.stepSelectedRoute = route;
        if (this.isLoading) {
            this.router.navigateByUrl(route).then(x => {});
        } else {
            this.researchProjectInformationForm.save();
        }
    }

    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => this.researchProject = res);
        this.returnedToResearcherNotification.refreshInternalFields();
    }

    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;

    handleWithdraw() {
        this.withdraw(this.dialog, this.researchProjectID, this.researchProjectService, this.router, this.alertService);
    }
}
