<a
    [routerLink]="[params.inRouterLink, params.value.LinkValue]"
    *ngIf="params.value.LinkDisplay && !params.value.ChildRoute"
    [className]="params.value.CssClasses">
    {{ params.value.LinkDisplay }}
</a>
<a
    [routerLink]="[params.inRouterLink, params.value.LinkValue, params.value.ChildRoute]"
    [queryParams]="params.value.QueryParams"
    *ngIf="params.value.LinkDisplay && params.value.ChildRoute"
    [className]="params.value.CssClasses">
    {{ params.value.LinkDisplay }}
</a>
