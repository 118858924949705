<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="researchProject"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div id="review" class="g-col-10">
        <div class="permit-summary-header">
            <h2>{{title}}</h2>
            <div style="display: flex; gap: .5rem;">
                <button *ngIf="displayAmendButton()" [disabled]="isAmendmentInProgress" class="action-button" cdsButton="secondary" (click)="amend()">Amend permit</button>
                <button *ngIf="displayRenewButton()" class="action-button" cdsButton="secondary" (click)="renew()">Renew permit</button>
            </div>
        </div>
        <castateparksscp-annual-report-notification [researchProjectID]="researchProjectID"></castateparksscp-annual-report-notification>
        <p style="padding-inline-start: 1rem; margin-bottom: 1rem" *ngIf="!!researchProject?.RenewedFromPermitNumber">Renewed from: <a href='public/research-project-review-and-submit/{{researchProject?.RenewedFromPermitID}}'>{{researchProject?.RenewedFromPermitNumber}}</a></p>
        <p style="padding-inline-start: 1rem; margin-bottom: 1rem" *ngIf="!!researchProject?.AmendedFromPermitID">Amended from: <a href='public/research-project-review-and-submit/{{researchProject?.AmendedFromPermitID}}'>{{researchProject?.AmendedFromPermitNumber}}</a></p>
        <ng-container *ngIf="incompleteSectionsExcludingReviewAndSubmitExist(researchProject); else complete">
            <p *ngIf="!isLoading && (!researchProject || !researchProject.Complete)">This research project permit application is not yet ready for review. Please go back and complete all
                unfinished sections.</p>
        </ng-container>
        <ng-template #complete>
            <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>
            <ng-container *ngIf="canDisplayPermitApprovedDates()">
                <p>Permit approved start and end date: {{researchProject?.PermitStartDate | date:'M/d/yyyy'}} - {{researchProject?.PermitEndDate | date:'M/d/yyyy'}}</p>
            </ng-container>
            <ng-container *ngIf="allowAnnualReportUpload()">
                <p>Annual report due date: {{researchProject?.PermitEndDate | date:'M/d/yyyy'}}</p>
                <cds-inline-notification [notificationObj]="{
                        type: 'warning',
                        lowContrast: true,
                        showClose: false,
                        title: '',
                        template: annualReportnotificationMessage
                        }"
                         [style]="'margin-top: 10px; margin-bottom: 10px'"
                ></cds-inline-notification>
                <ng-template #annualReportnotificationMessage>
                    <p>Uploading annual report cannot be undone via the interface. If you submit a file in error please contact <a href="mailto:nrd.research@parks.ca.gov">{{'nrd.research@parks.ca.gov'}}</a> to request removal.</p>
                </ng-template>

                <castateparksscp-research-project-file-list-manager
                    #annualReportManager
                    [limitOneFile]="false"
                    [uploadFileButtonText]="'Upload Annual Report'"
                    [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.AnnualReport"
                    [researchProjectID]="researchProjectID"
                    [canDeleteFile]="canDeleteUploads()"
                ></castateparksscp-research-project-file-list-manager>
            </ng-container>

            <cds-accordion [align]="'end'" [size]="'lg'">
                <cds-accordion-item title="Research Team">
                    <castateparksscp-review-section [showDiff]="!!amendedFromResearchProject"
                                                    [reviewRows]="contactReview"
                                                    [hideButtons]="!canEditPage"
                                                    buttonRoute="/public/research-teams/{{researchProjectID}}"></castateparksscp-review-section>
                    <div *ngIf="additionalParticipants?.length" class="review-tile" style="border-top: none">
                        <p>Additional Participants</p>
                        <!--                        TODO remove if error display no longer needed-->
                        <!--                        <p *ngIf="additionalParticipantsInvalid"><svg style="fill: #da1e28" class="cds&#45;&#45;btn__icon" cdsIcon="warning" size="16"></svg>-->
                        <!--                            Information is incomplete: First name, last name, and email are all required.</p>-->
                        <div class="table-responsive" style="height: 150px">
                            <ag-grid-angular
                                #Grid
                                style="width: 100%; height: 100%"
                                class="ag-theme-balham"
                                [rowData]="additionalParticipants"
                                [columnDefs]="additionalParticipantsColDefs"
                                [suppressMenuHide]="true"
                                [readOnlyEdit]="false">
                            </ag-grid-angular>
                        </div>
                        <div class="table-responsive" *ngIf="originalAdditionalParticipants" style="height: 150px; margin-block-start: 1rem">
                            <ag-grid-angular
                                #Grid
                                style="width: 100%; height: 100%"
                                class="ag-theme-balham"
                                [rowData]="originalAdditionalParticipants"
                                [columnDefs]="additionalParticipantsColDefs"
                                [suppressMenuHide]="true"
                                [readOnlyEdit]="false">
                            </ag-grid-angular>
                        </div>
                    </div>
                </cds-accordion-item>
                <cds-accordion-item title="Project Information">
                    <castateparksscp-review-section
                        [showDiff]="!!amendedFromResearchProject"
                        [reviewRows]="projectInformationReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-projects/{{researchProjectID}}"></castateparksscp-review-section>
                </cds-accordion-item>
                <cds-accordion-item title="Study Areas">
                    <castateparksscp-review-section
                        [showDiff]="!!amendedFromResearchProject"
                        [reviewRows]="studyAreaReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-project-study-areas/{{researchProjectID}}"></castateparksscp-review-section>
                </cds-accordion-item>
                <cds-accordion-item title="Data Collection">
                    <castateparksscp-review-section
                        [showDiff]="!!amendedFromResearchProject"
                        [reviewRows]="dataCollectionReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-project-data-collection/{{researchProjectID}}"></castateparksscp-review-section>
                    <div *ngIf="specimenItems?.length" class="review-tile" style="border-top: none">
                        <p>Types of specimen to be collected</p>
                        <div class="table-responsive" style="height: 150px">
                            <ag-grid-angular
                                style="width: 100%; height: 100%"
                                class="ag-theme-balham"
                                [rowData]="specimenItems"
                                [columnDefs]="specimenItemsColDefs"
                                [suppressMenuHide]="true"
                                [readOnlyEdit]="false">
                            </ag-grid-angular>
                        </div>
                        <div class="table-responsive" *ngIf="originalSpecimenItems" style="height: 150px; margin-block-start: 1rem">
                            <ag-grid-angular
                                #Grid
                                style="width: 100%; height: 100%"
                                class="ag-theme-balham"
                                [rowData]="originalSpecimenItems"
                                [columnDefs]="specimenItemsColDefs"
                                [suppressMenuHide]="true"
                                [readOnlyEdit]="false">
                            </ag-grid-angular>
                        </div>
                    </div>
                </cds-accordion-item>
                <cds-accordion-item title="Additional Documentation">
                    <castateparksscp-review-section
                        [showDiff]="!!amendedFromResearchProject"
                        [reviewRows]="additionalDocumentationReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-project-documents/{{researchProjectID}}"></castateparksscp-review-section>
                </cds-accordion-item>
            </cds-accordion>

            <div *ngIf="showNotification()" style="margin-bottom: 1rem; margin-top: 1rem;">
                <cds-inline-notification [notificationObj]="{
                    type: 'info',
                    title: '',
                    template: customNotificationContent,
                    lowContrast: true,
                    showClose: false
                }"></cds-inline-notification>
                <ng-template #customNotificationContent let-data>
                    <p cdsToastSubtitle>You are required to submit a summary report of your activities in the California State Park units by
                        {{researchProject?.PermitEndDate | date:'fullDate'}}, as well as a final report and any associated data projects withing one month on their completion. </p>
                    <p *ngIf="!researchProject?.RenewedFromPermitNumber && !researchProject?.AmendedFromPermitNumber" cdsToastCaption>Please download, print, and keep a copy with you in the field: <strong><a style="cursor:pointer;" cdsLink (click)="researchProjectFileList.getFirstFileByResearchProjectFileType(ResearchProjectFileTypeEnum.SignedActivePermit)">Signed Active Permit</a></strong></p>
                    <ng-container *ngIf="!!researchProject?.RenewedFromPermitNumber">
                        <p cdsToastCaption style="width: 100%; margin-bottom: 0">Please download, print, and attach to your original permit (see Original Signed Permit):</p>
                        <ul cdsToastCaption cdsList>
                            <li cdsListItem>
                                <a style="cursor:pointer;" cdsLink (click)="researchProjectFileList.getFirstFileByResearchProjectFileType(ResearchProjectFileTypeEnum.SignedAmendmentFinalLetter)">Final Amendment Letter</a>
                            </li>
                            <li cdsListItem>
                                <a style="cursor:pointer;" cdsLink (click)="researchProjectFileList.getFirstFileByResearchProjectFileType(ResearchProjectFileTypeEnum.AmendmentSpecialConditionsAndParkDirectory)">Special Conditions & Park Directory</a>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="!!researchProject?.AmendedFromPermitNumber">
                        <p cdsToastCaption style="width: 100%; margin-bottom: 0">Please download, print, and keep a copy with you in the fields of the following documents:</p>
                        <ul cdsToastCaption cdsList>
                            <li cdsListItem>
                                <a style="cursor:pointer;" cdsLink (click)="researchProjectFileList.getFirstFileByResearchProjectFileType(ResearchProjectFileTypeEnum.SignedAmendmentFinalLetter)">Final Amendment Letter</a>
                            </li>
                            <li cdsListItem>
                                <a style="cursor:pointer;" cdsLink (click)="researchProjectFileList.getFirstFileByResearchProjectFileType(ResearchProjectFileTypeEnum.AmendmentSpecialConditionsAndParkDirectory)">Special Conditions & Park Directory</a>
                            </li>
                            <li cdsListItem>
                                <a style="cursor:pointer;" cdsLink (click)="researchProjectFileList.getFirstFileByResearchProjectFileType(ResearchProjectFileTypeEnum.OriginalSignedPermit)">Original Signed Permit</a>
                            </li>
                        </ul>
                    </ng-container>
                </ng-template>
            </div>

            <h6>Uploaded Files</h6>
            <castateparksscp-research-project-file-list
                #researchProjectFileList
                [researchProjectID]="researchProjectID"
                [researchProjectFileTypeID]="null"
                [fileRoute]="fileRoute"
                [viewAllFiles]="true"
                [useDefaultViewAllFilesHeight]="false"
                [disabled]="!canEditPage"
            ></castateparksscp-research-project-file-list>
            <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float: right; display: flex; flex-direction: row-reverse">
                <button *ngIf="displaySubmitButton()" cdsButton (click)="submit()" [disabled]="isSubmitDisabled()">Submit application</button>
                <button *ngIf="displayWithdrawButton()" cdsButton="ghost" (click)="handleWithdraw()">Withdraw application</button>
            </div>
        </ng-template>
    </div>

</div>

