import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from "../basic/basic-list/basic-list.component";
import {PermissionEnum} from "../../shared/generated/enum/permission-enum";
import {AuthenticationService} from "../../services/authentication.service";
import {DateColumnCreatorService} from "../../shared/services/date-column-creator/date-column-creator.service";
import {Router} from "@angular/router";
import {AlertService} from "../../shared/services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {SpecialConditionService} from "../../shared/generated/api/special-condition.service";
import {HtmlRendererComponent} from "../../shared/components/ag-grid/html-renderer/html-renderer.component";
import {RightsEnum} from "../../shared/models/enums/rights.enum";
import {
    InLineEditButtonsRendererComponent
} from "../../shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component";
import {GetRowIdFunc, GetRowIdParams} from "ag-grid-community";
import {SpecialConditionSimpleDto} from "../../shared/generated/model/special-condition-simple-dto";
import {BasicConfigurableModalComponent} from "../basic/basic-configurable-modal/basic-configurable-modal.component";
import {FormPage} from "../basic/shared/basic-form/basic-form.component";

@Component({
  selector: 'castateparksscp-special-condition-list',
  templateUrl: '../basic/basic-list/basic-list.component.html',
  styleUrl: './special-condition-list.component.css'
})
export class SpecialConditionListComponent extends BasicListComponent implements OnInit{
    listPage: ListPage = {
        permission: PermissionEnum.SpecialConditionRights,
        pageTitle: 'Special Conditions',
        createButtonLabel: 'Special Condition',
        downloadFileName: 'specialConditions',
        isShowAddButton: true,
    } as ListPage;

    useInGridEdit: boolean = false;

    constructor(public specialConditionService: SpecialConditionService,
                public authenticationService: AuthenticationService,
                public dateColumnCreator: DateColumnCreatorService,
                public cdr: ChangeDetectorRef,
                public router: Router,
                public alertService: AlertService,
                public dialog: MatDialog,) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);

        this.listPage.dtosGetter = () => this.specialConditionService.specialConditionsGet();
        this.listPage.columnDefs = [
            {
                field: "IsDefaultCondition",
                headerName: "Default Condition",
                editable: false,
                cellRenderer: 'agCheckboxCellRenderer',
                cellRendererParams: {disabled: true},
                cellEditor: 'agCheckboxCellEditor',
                autoHeight: true,
            },
            {
                field: "Content",
                headerName: "Condition",
                cellRendererSelector: (params) => {
                    return {
                        component: HtmlRendererComponent,
                        params: params
                    }
                },
                flex: 1,
                editable: false,
                cellEditorPopup: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {maxLength:1000},
                wrapText: true,
                autoHeight: true,
            },
        ];
    }

    override doActionAfterSettingCurrentUser() {
        if (this.authenticationService.hasPermission(this.currentUser,
            PermissionEnum.SpecialConditionRights,
            RightsEnum.Update)) {
            this.listPage.columnDefs.unshift({
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                            editableCallback: () => this.canEdit,
                        },
                    };
                },
                width: 100,
                pinned: 'left',
                editable: false,
                canEdit: false,
            });
            this.grid?.api?.setGridOption("columnDefs", this.listPage.columnDefs);
        }
    }

    public idField: string = 'SpecialConditionID';

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<SpecialConditionSimpleDto> | any) => params.data.SpecialConditionID;
    public putAction = (id: number, dto: any) => {
        return this.specialConditionService.specialConditionsSpecialConditionIDPut(id, dto);
    }
    public postAction = (dto: any) => {
        return this.specialConditionService.specialConditionsPost(dto);
    }

    public deleteAction = (id: number) => {
        return this.specialConditionService.specialConditionsSpecialConditionIDDelete(id);
    }

    override edit(params) {
        this.openEditModal(params);
    }

    override navigateToCreatePage() {
        this.openEditModal(null, "Add");
    }

    private openEditModal(params?, title="Edit") {
        let dialogRef = this.dialog.open(BasicConfigurableModalComponent, {
            data: {
                ID: !!params ? params.node.data.SpecialConditionID : null,
                dto: !!params ? params.node.data : {Content: ''},
                header: title + ' Special Condition',
                showActions: true,
                editMode: true,
                formPage: {
                    dtoName: 'research project special condition',
                    elements: [
                        {
                            class: 'g-col-12',
                            name: 'IsDefaultCondition',
                            label: 'Default condition',
                            type: 'checkbox',
                        },
                        {
                            class: 'g-col-12',
                            name: 'Content',
                            label: 'Condition',
                            type: 'editor',
                            editorConfig: {toolbar: true, menubar: true, plugins: 'lists link table help wordcount'}
                        },
                    ],
                    createUpsertDto: (dto) => {
                        return dto;
                    },
                    put: (id, dto) => this.putAction(id, dto),
                    post: (dto) => this.postAction(dto)
                } as FormPage
            }
        });

        if (dialogRef) {
            dialogRef.afterClosed().subscribe(() => {
                this.refreshData();
            });
        }
    }
}
