import {Component, Input, OnInit} from '@angular/core';
import {BasicFormComponent, FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {AlertService} from "../../../shared/services/alert.service";
import {ContactService} from "../../../shared/generated/api/contact.service";
import {CurrentContactService} from "../../../services/current-contact.service";
import {UtilityFunctionsService} from "../../../services/utility-functions.service";
import {ParkSimpleDto} from "../../../shared/generated/model/park-simple-dto";
import {ParkService} from "../../../shared/generated/api/park.service";
import {ContactUpsertDto} from "../../../shared/generated/model/contact-upsert-dto";

@Component({
    selector: 'castateparksscp-district-contact-form',
    templateUrl: './../../basic/shared/basic-form/basic-form.component.html',
})
export class DistrictContactFormComponent extends BasicFormComponent implements OnInit {
    @Input() districtID :number;
    parks = [];
    parkIDs = [];
    isHideActionButtons = true;
    formPage: FormPage = {
        dtoName: 'district',
        elements: [
            {
                class: 'g-col-6',
                name: 'FirstName',
                label: 'First name',
                required: true,
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'LastName',
                label: 'Last name',
                required: true,
                type: 'text',
            },
            {
                class: 'g-col-4',
                name: 'PhoneNumber1',
                label: 'Phone number',
                required: false,
                type: 'text',
            },
            {
                class: 'g-col-2',
                name: 'Extension1',
                label: 'Extension',
                required: false,
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'Email',
                label: 'Email',
                required: true,
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'Title',
                label: 'Title',
                required: false,
                type: 'text',
            },
            {
                class: 'g-col-12',
                name: 'ParkIDs',
                label: 'Parks',
                required: true,
                type: 'multipleCheckboxes',
                itemLabel: 'Name'
            },
        ]
    } as FormPage;
    readonly NUMBER_OF_PARK_COLUMNS = 4;

    constructor(public currentContactService: CurrentContactService,
                public contactService: ContactService,
                public alertService: AlertService,
                public utilityFunctionsService: UtilityFunctionsService,
                public parkService: ParkService
                ) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => this.currentContactService.createDistrictContactUpsertDto(dto);
        this.formPage.post = (upsertDto) => {
            this.updateParkIDsOrDistrictID(upsertDto);
            return this.contactService.contactsPost(upsertDto);
        };
        this.formPage.put = (id, upsertDto) => {
            this.updateParkIDsOrDistrictID(upsertDto);
            return this.contactService.contactsContactIDPut(id, upsertDto)
        };
    }

    ngOnInit() {
        super.ngOnInit();
        this.parkService.districtsDistrictIDParksGet(this.districtID).subscribe((data) => {
            this.parks = data.sort((a: ParkSimpleDto, b: ParkSimpleDto) => {
                if (a.Name > b.Name) return 1;
                if (a.Name < b.Name) return -1;
                return 0;
            });
            this.itemColumns.set('ParkIDs', this.utilityFunctionsService.buildTableColumnsForArray(this.parks, this.NUMBER_OF_PARK_COLUMNS));
            if (!this.ID || !!this.upsertDto.DistrictID) {
                this.selectAllMultiCheckboxes(null);
            }

        });
    }

    onCheckboxChange(name, item) {
        let index = this.parkIDs.findIndex(x => x == item.ParkID);
        if (index == -1) {
            this.parkIDs.push(item.ParkID);
        } else {
            this.parkIDs.splice(index, 1);
        }
    }

    getCheckboxValue(name, item): boolean {
        return this.parkIDs.findIndex(x => x == item.ParkID) > -1;
    }

    private updateParkIDsOrDistrictID(upsertDto: ContactUpsertDto) {
        if (this.parks.every(p => this.parkIDs.includes(p.ParkID))) {
            this.upsertDto.ParkIDs = [];
            this.upsertDto.DistrictID = this.districtID;
        } else if (!!this.parkIDs.length) {
            this.upsertDto.DistrictID = null;
            this.upsertDto.ParkIDs = this.parkIDs;
        }
    }

    selectAllMultiCheckboxes(name: string) {
        this.parkIDs = this.parks.map(p => p.ParkID);
    }

    deselectAllMultiCheckboxes(name: string) {
        this.upsertDto.DistrictID = null;
        this.parkIDs = [];
    }

    setForm() {
        super.setForm();
        this.parkIDs = this.upsertDto.ParkIDs;
    }

    isParkSelectionValid() {
        if (!!this.parkIDs.length) return true;
        return !!this.upsertDto.DistrictID;
    }

    handleSaveFormError(err) {
        this.errors = err;
    }
}
