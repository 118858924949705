import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CurrentContactService} from 'src/app/services/current-contact.service';
import {EditViewEventService} from 'src/app/services/edit-view-event.service';
import {ContactService} from 'src/app/shared/generated/api/contact.service';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {AlertService} from 'src/app/shared/services/alert.service';
import {BasicDetailComponent, DetailPage} from '../../basic/basic-detail/basic-detail.component';
import {ContactDto} from 'src/app/shared/generated/model/contact-dto';
import {ContactDtoUtil} from '../shared/contact-dto.util';
import {Subscription} from "rxjs";

@Component({
    selector: 'castateparksscp-contact-detail',
    styleUrls: ['../../basic/basic-detail/basic-detail.component.scss'],
    templateUrl: '../../basic/basic-detail/basic-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,

})
export class ContactDetailComponent extends BasicDetailComponent implements OnInit, OnDestroy {

    public dto?: ContactDto | undefined
    public titleField: string = 'Name';
    override isShowOptions = false;

    detailPage: DetailPage = {
        permission: PermissionEnum.ContactRights,
        editMode: false,
        dtoName: 'Contact',
    } as DetailPage;
    isShowSideNav = false;

    routeParams: Subscription;

    constructor(
        public contactService: ContactService,
        public currentContactService: CurrentContactService,
        public authenticationService: AuthenticationService,
        public editViewEventService: EditViewEventService,
        public alertService: AlertService,
        public router: Router,
        public route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        super(authenticationService, editViewEventService, alertService, router, route, cdr, dialog);
        this.routeParams = this.route.params.subscribe(params => {
            this.setUpContactDetailPage();
        });
    }

    private setUpContactDetailPage() {
        this.editMode = false;
        const contactID = parseInt(this.route.snapshot.paramMap.get("id"));
        this.detailPage.sideNav = [
            {
                Name: "Summary",
                Link: `/contacts/${contactID}`
            },
        ];
        this.detailPage.dtoGetter = () => this.contactService.contactsContactIDGet(contactID);
        this.detailPage.setCurrentDto = (data) => this.currentContactService.setCurrentContact(data);
        this.detailPage.dtoDelete = () => this.contactService.contactsContactIDDelete(this.dto?.ContactID);
        this.detailPage.currentDtoObserver = () => this.currentContactService.getCurrentContact()
        this.detailPage.getDeleteWarningMessage = (dto) => {
            return `You are about to delete contact ${dto?.Name}. This action cannot be undone. Are you sure you wish to proceed?`
        }
        this.detailPage.deleteSuccessRoute = '/contacts';
        super.ngOnInit();
    }

    override processData(data: ContactDto) {
        return ContactDtoUtil.additionalProcess(data);
    }

    isDeleteDisabled(): boolean {
        return this.currentUser?.ContactID == this.dto?.ContactID;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.routeParams?.unsubscribe();
    }

    showOptions(): boolean {
        if (this.currentUser?.ContactID == this.dto?.ContactID) return false;
        return super.showOptions();
    }
}
