import {
    Component,
    OnDestroy,
    OnInit,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { SystemInfoDto } from "../generated/model/system-info-dto";
import { SystemInfoService } from "../generated/api/system-info.service";
import { Subscription } from "rxjs";
import { UserDto } from "../generated/model/user-dto";

@Component({
    selector: "footer-nav",
    templateUrl: "./footer-nav.component.html",
    styleUrls: ["./footer-nav.component.scss"],
})
export class FooterNavComponent implements OnInit, OnDestroy {
    @Input() currentUser: UserDto;

    @Output() loginClicked = new EventEmitter<any>();
    @Output() logOutClicked = new EventEmitter<any>();

    public currentYear: number = new Date().getFullYear();
    systemInfoSubscription: Subscription;
    systemInfo: SystemInfoDto;

    constructor(
        public systemInfoService: SystemInfoService
    ) {}

    ngOnInit(): void {
        this.systemInfoSubscription = this.systemInfoService
            .getSystemInfo()
            .subscribe((result) => {
                this.systemInfo = result;
            });
    }

    ngOnDestroy() {
        this.systemInfoSubscription?.unsubscribe();
    }

    login(): void {
        this.loginClicked.emit();
    }

    logout(): void {
        this.logOutClicked.emit();
    }
}
