import {Component, Input} from '@angular/core';
import {BasicFormComponent} from "../../shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../../../shared/generated/api/research-project.service";
import {AlertService} from "../../../../shared/services/alert.service";

@Component({
  selector: 'castateparksscp-basic-modal-form',
  templateUrl: './../../shared/basic-form/basic-form.component.html',
})
export class BasicModalFormComponent extends BasicFormComponent {
    @Input() formPage;
    @Input() dto = {};

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
    }
}
