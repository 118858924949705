import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ResearchProjectService} from "../../../../shared/generated/api/research-project.service";
import {ResearchProjectRenewedFromDto} from "../../../../shared/generated/model/research-project-renewed-from-dto";
import {NotificationContent} from "carbon-components-angular";

@Component({
    selector: 'castateparksscp-annual-report-notification',
    templateUrl: './annual-report-notification.component.html',
})
export class AnnualReportNotificationComponent implements OnInit, OnChanges {
    @Input() researchProjectID: number;
    @Input() parentRenewedFromPermit: ResearchProjectRenewedFromDto;
    annualReportNotificationObject = {} as NotificationContent;

    renewedFromPermit: ResearchProjectRenewedFromDto;
    constructor(private researchProjectService: ResearchProjectService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['parentRenewedFromPermit'] || changes['researchProjectID']) {
            this.loadPermit();
        }
    }

    ngOnInit(): void {
        this.loadPermit();
    }

    public loadPermit() {
        if (this.parentRenewedFromPermit) {
            this.setRenewedFromPermit(this.parentRenewedFromPermit);
            return;
        }
        if (this.researchProjectID) {
            this.researchProjectService.researchProjectsResearchProjectIDRenewedFromResearchProjectGet(this.researchProjectID)
                .subscribe(result => {
                    this.renewedFromPermit = result;
                    this.setRenewedFromPermit(result);
                });
        }
    }


    private setRenewedFromPermit(renewedFromPermit: ResearchProjectRenewedFromDto) {
        this.renewedFromPermit = renewedFromPermit;
        this.annualReportNotificationObject = {
            type: 'error',
            title: `Annual Report for Original Permit: <a href='public/research-project-review-and-submit/${this.renewedFromPermit?.ResearchProjectID}'>${this.renewedFromPermit?.ApplicationNumber} (${this.renewedFromPermit?.Title})</a> not yet submitted.`,
            message: `Due ${new Date(this.renewedFromPermit?.DueDate)?.toLocaleDateString()}, Researcher estimated date for submission is ${new Date(this.renewedFromPermit?.RequestedDueDate)?.toLocaleDateString()}.`,
            lowContrast: true,
            showClose: false
        }
    }

    showNotification() {
        return this.renewedFromPermit?.AnnualReportMissing;
    }
}
