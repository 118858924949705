import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as _throw } from 'rxjs';
import { AlertService } from '../alert.service';
import { Alert } from 'src/app/shared/models/alert';
// import { OAuthService } from 'angular-oauth2-oidc';
import { AlertContext } from '../../models/enums/alert-context.enum';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(
        private alertService: AlertService,
        // private oauthService: OAuthService,
        private router: Router
        ) {

        }

    public handleError(error: any, supressErrorMessage = false, clearBusyGlobally = true): Observable<any> {
        if (!supressErrorMessage) {
            if (error && (error.status === 401)) {
                this.alertService.pushAlert(new Alert("Access token expired..."));
                // this.oauthService.initCodeFlow();
            } else if (error && (error.status === 403)) {
                this.alertService.pushNotFoundUnauthorizedAlert();
                this.router.navigate(["/"]);
            } else if (error.error && typeof error.error === 'string') {
                this.alertService.pushNotFoundUnauthorizedAlert();
                this.alertService.pushAlert(new Alert(error.error));
            } else if (error.error && error.status === 404) {
                // let the caller handle not found appropriate to whatever it was doing
            } else if (error.error && !(error.error instanceof ProgressEvent)) {
                // FIXME: will break if error.error[key] or error.error.errors[key] is not a string[]
                // if there are multiple errors as in the case with validations errors or the key has square brackets to indicate
                // errors for a collection, the form will handle form errors
                // and will display a general form error alter
                let errors = !!error.error.errors ? error.error.errors : error.error;
                let keys = Object.keys(errors);
                if (keys.length == 1 && !keys[0].includes("[") && !keys[0].includes("]")) {
                    for (const key of Object.keys(errors)) {
                        const newLocal = new Alert((errors[key] as string[]).map((fe: string) => { return key + ": " + fe; }).join(","), AlertContext.Danger);
                        this.alertService.pushAlert(newLocal);
                    }
                } else {
                    this.alertService.pushAlert(new Alert("Please correct form errors, and try again."));
                }
            } else {
                this.alertService.pushAlert(new Alert("Oops! Something went wrong and we couldn't complete the action..."));
            }
        }

        return _throw(error);
    }
}
