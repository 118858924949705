//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Role]

export enum RoleEnum {
  SystemAdmin = 1,
  Public = 2,
  TechnicalReviewer = 5,
  DistrictStaff = 6,
  Inactive = 7
}
