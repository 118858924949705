import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BasicDetailComponent, DetailPage} from "../../basic/basic-detail/basic-detail.component";
import {PermissionEnum} from "../../../shared/generated/enum/permission-enum";
import {DistrictService} from "../../../shared/generated/api/district.service";
import {CurrentDistrictService} from "../../../services/current-district.service";
import {AuthenticationService} from "../../../services/authentication.service";
import {EditViewEventService} from "../../../services/edit-view-event.service";
import {AlertService} from "../../../shared/services/alert.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DistrictSimpleDto} from "../../../shared/generated/model/district-simple-dto";

@Component({
    selector: 'castateparksscp-district-detail',
    styleUrls: ['../../basic/basic-detail/basic-detail.component.scss'],
    templateUrl: '../../basic/basic-detail/basic-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DistrictDetailComponent extends BasicDetailComponent implements OnInit {

    public dto?: DistrictSimpleDto | undefined;

    public titleField: string = 'Name';

    detailPage: DetailPage = {
        permission: PermissionEnum.DistrictRights,
        editMode: false,
        dtoName: 'District',
    } as DetailPage;
    isShowSideNav = false;
    isShowOptions = false;

    constructor(
        public districtService: DistrictService,
        public currentDistrictService: CurrentDistrictService,
        public authenticationService: AuthenticationService,
        public editViewEventService: EditViewEventService,
        public alertService: AlertService,
        public router: Router,
        public route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        super(authenticationService, editViewEventService, alertService, router, route, cdr, dialog);
    }

    ngOnInit(): void {
        const districtID = parseInt(this.route.snapshot.paramMap.get("id"));
        this.detailPage.sideNav = [
            {
                Name: "Overview",
                Link: `/districts/${districtID}`
            },
        ];
        this.detailPage.dtoGetter = () => this.districtService.districtsDistrictIDGet(districtID);
        this.detailPage.setCurrentDto = (data) => this.currentDistrictService.setCurrentDistrict(data);
        this.detailPage.currentDtoObserver = () => this.currentDistrictService.getCurrentDistrict()
        super.ngOnInit();
    }

    override canDelete(): boolean {
        return false;
    }
}
