import {Component, OnDestroy} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {AgGridEventService} from "../../../../services/ag-grid-event.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'castateparksscp-custom-multi-select-editor',
    templateUrl: './custom-multi-select-editor.component.html',
    styleUrl: './custom-multi-select-editor.component.css',
})
export class CustomMultiSelectEditorComponent implements ICellEditorAngularComp, OnDestroy {
    private params: any;
    items: any = [];
    type: 'multi' | 'single' = 'multi';
    disabled: boolean = false;
    label: string = '';
    placeholderText: string = 'Select';
    helperText: string = '';
    model;
    currentListenerValue;
    listenerSubscription: Subscription;

    constructor(private agGridEventService: AgGridEventService) {
    }

    agInit(params: any): void {
        this.params = params;
        if (params.single) {
            this.type = 'single';
        }
        let displayField = params.colDef.cellEditorParams.displayField;
        this.items = this.params.colDef.cellEditorParams.items.map((i: any) => !!displayField
            ? Object.assign(i, {'content': i[displayField]}) : {'content': i, 'value': i});
        this.model = params.value;
        if (params.colDef.cellEditorParams.isListener) {
            this.listenerSubscription = this.agGridEventService.currentValue
                .subscribe(value => this.handleNewListenerValue(value));
        }
        if (params.colDef.cellEditorParams.disableInit) {
            this.disabled = params.colDef.cellEditorParams.disableInit(params);
        }
    }

    getValue(): any {
        return this.model;
    }

    handleChange(event) {
        this.model = event;
    }

    ngOnDestroy(): void {
        this.listenerSubscription?.unsubscribe();
    }

    handleNewListenerValue(newValue) {
        this.currentListenerValue = newValue;
        if (!!this.params.colDef.cellEditorParams.disable) {
            this.disabled = this.params.colDef.cellEditorParams.disable(newValue);
            this.model = this.disabled ? null : this.model;
        }
    }
}
