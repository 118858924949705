import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentProjectService} from 'src/app/services/current-project.service';
import {BasicCreateComponent, CreatePage} from '../../basic/basic-create/basic-create.component';
import {ProjectUpsertDto} from 'src/app/shared/generated/model/project-upsert-dto';
import {ProjectFormComponent} from '../shared/project-form/project-form.component';

@Component({
    selector: 'castateparksscp-project-create',
    templateUrl: './project-create.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCreateComponent extends BasicCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createForm", {read: ProjectFormComponent}) form: ProjectFormComponent;

    public dto: ProjectUpsertDto = new ProjectUpsertDto();
    public upsertDto: ProjectUpsertDto;

    public createPage: CreatePage = {
        cancelRoute: '/projects',
        dtoName: 'Project',
    } as CreatePage;

    constructor(
        public currentProjectService: CurrentProjectService,
        public router: Router,
        public cdr: ChangeDetectorRef
    ) {
        super(router, cdr);
        this.createPage.getSuccessRoute = (result) => `/projects/${result?.ProjectID}`;
        this.createPage.dtoCreator = () => this.currentProjectService.createProjectDto(this.dto);
    }
}
