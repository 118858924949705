<div class="grid-12" style="margin-inline: 0;">
    <div class="g-col-12">
        <div class="container" >
            <div class="center">
                <h1 style="color:white">Scientific Research and Collection Permit</h1>
            </div>
            <div class="center-lower">
                <button cdsButton (click)="createNewPermit()" [disabled]="!userCanCreateNewApplication && !!currentUser" style="margin-inline:20px">Apply for new permit</button>
            </div>
        </div>
    </div>

    <div class="g-col-6" style="margin-bottom:100px; margin-inline: 20px;">
        <h3>Instructions</h3>

        <p>Thank you for your interest in conducting scientific research within California State Parks. This form facilitates the application for a research permit valid for up to 12 months.</p>

        <h5>Understanding Permit Duration and Renewal:</h5>
        <ul>
            <li><p><b>Annual Permits:</b> Permits are issued for a maximum of 12 months.  If your research spans multiple years, each year will require a separate renewal.</li>
            <li><p><b>Renewal Process:</b> Easily renew your permit through your researcher profile. This avoids the need to start a new project application each time, streamlining your continued research efforts.</li>
        </ul>

        <h5>Application Overview:</h5>
        <ul>
            <li><p><b>Flexibility:</b> You can complete the form in multiple sessions and in any order.</li>
            <li><p><b>Required Information:</b> All mandatory fields must be filled out to submit your application.</li>
            <li><p><b>Study Proposal:</b> If you submit a detailed study proposal, you can skip certain questions covered in the document. This step is optional; otherwise, please complete all form fields.</li>
        </ul>

        <h5>Required Materials:</h5>
        <ul>
            <li><p>CV or resume of the Principal Investigator and, if applicable, the person overseeing fieldwork.</li>
            <li><p>Precise location details of your study area, including coordinates and/or GIS files.</li>
            <li><p>Applicable additional local, state, or federal permits.</li>
            <li><p>For Renewals: A summary report of activities under your previous permit.</li>
            <li><p>All information as described in study proposal (<a cdsLink href="/assets/pdfs/study_proposal_guidelines.pdf" target="_blank">Download guidelines</a>)</p></li>
        </ul>

        <h5>Optional:</h5>
        <ul>
            <li><p>A PDF of your study proposal, you may follow the Study Proposal Guidelines for Research in California State Parks.</li>
            <li><p> Submitting a PDF in addition to filling out the form fields. (<a cdsLink href="/assets/pdfs/study_proposal_guidelines.pdf" target="_blank">Download guidelines</a>)</li>
        </ul>

        <h5>Evaluation:</h5>
        <p>Your application will be evaluated based on its scientific merits and potential impacts on park resources, operations, and visitors. Incomplete applications may be returned for revisions.

        <h5>Submission Deadline:</h5>
        <p>Submit your application at least 90 days before the desired start date of your permit.

        <h5>Need Help?</h5>
        <p>For assistance, email your inquiries to <a href="mailto:nrd.research@parks.ca.gov">nrd.research&#64;parks.ca.gov</a>, including any relevant screenshots. Please use your question as the email subject line for faster service.
    </div>
    <div class="g-col-6" style="margin-inline: 0px;">
        <h3>Application Fields</h3>

        <p>The application can be completed in several sessions. Below are all application questions and items to review prior to starting. Required information is marked with a *</p>

        <div style="margin-top:30px">
        <cds-accordion [align]="'end'" [size]="'md'">
            <cds-accordion-item title="Research Team" (selected)="selected($event)">
                <ul cdsList class="unstyled-list">
                    <li cdsListItem>
                        Organization
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem>
                                Application Organziation*
                            </li>
                            <li cdsListItem>
                                Phone Number*
                            </li>
                            <li cdsListItem>
                                Email*
                            </li>
                            <li cdsListItem>
                                Mailing Address*
                            </li>
                            <li cdsListItem>
                                Country*
                            </li>
                            <li cdsListItem>
                                City*
                            </li>
                            <li cdsListItem>
                                State*
                            </li>
                            <li cdsListItem>
                                Zip Code*
                            </li>
                        </ul>
                    </li>
                    <li cdsListItem>Principal Investigator
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem>
                                First Name*
                            </li>
                            <li cdsListItem>
                                Last Name*
                            </li>
                            <li cdsListItem>
                                Phone Number*
                            </li>
                            <li cdsListItem>
                                Email*
                            </li>
                            <li cdsListItem>
                                Mailing Address*
                            </li>
                            <li cdsListItem>
                                Country*
                            </li>
                            <li cdsListItem>
                                City*
                            </li>
                            <li cdsListItem>
                                State*
                            </li>
                            <li cdsListItem>
                                Zip Code*
                            </li>
                            <li>
                                Resume*
                            </li>
                        </ul>
                    </li>
                    <li cdsListItem>Person in Direct Charge of Field Work
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem>
                                First Name*
                            </li>
                            <li cdsListItem>
                                Last Name*
                            </li>
                            <li cdsListItem>
                                Phone Number*
                            </li>
                            <li cdsListItem>
                                Email*
                            </li>
                            <li cdsListItem>
                                Mailing Address*
                            </li>
                            <li cdsListItem>
                                Country*
                            </li>
                            <li cdsListItem>
                                City*
                            </li>
                            <li cdsListItem>
                                State*
                            </li>
                            <li cdsListItem>
                                Zip Code*
                            </li>
                            <li>
                                Resume*
                            </li>
                        </ul>
                    </li>
                    <li cdsListItem>Additional Participants
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem>
                                First Name*
                            </li>
                            <li cdsListItem>
                                Last Name*
                            </li>
                            <li cdsListItem>
                                Phone Number
                            </li>
                            <li cdsListItem>
                                Email*
                            </li>
                            <li cdsListItem>
                                Title
                            </li>
                            <li cdsListItem>
                                Comments
                            </li>
                        </ul>
                    </li>
                    <li cdsListItem> Are all project personnel employed or insured by the institution conducting the research?
                        <ul cdsList class="unstyled-list"> If yes:
                            <li cdsListItem>
                                Required Liability Waiver Addendum*
                            </li>
                        </ul>
                    </li>
                </ul>
            </cds-accordion-item>
            <cds-accordion-item title="Project Information" (selected)="selected($event)">
                <ul cdsList class="unstyled-list">
                    <li cdsListItem>
                        Project Category*
                    </li>
                    <li cdsListItem>
                        Project Title*
                    </li>
                    <li cdsListItem>
                        Project Purpose: Describe the study rationale and significance. Include a summary of relevant literature and specific hypotheses to be tested and/or study objectives. Describe why you want to do research in California State Parks?*
                    </li>
                    <li cdsListItem>
                        Anticipated benefits to the State Park System: Identify any anticipated benefits to the California State Park System that may result from your proposed research.*
                    </li>
                    <li cdsListItem>
                        Project start and end dates*
                    </li>
                    <li cdsListItem>
                        Permit requested start and end dates*
                    </li>
                    <li cdsListItem>
                        Annual report tentative completion date*
                    </li>
                    <li cdsListItem>
                        Field Investigation Occurence: Provide each start and end date per field investigation occurrence in park lands.*
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem>Start and end dates*</li>
                        </ul>
                    </li>
                    <li cdsListItem>
                        Project schedule additional documentation: If you have a more complex project schedule you can upload supporting documents.
                    </li>
                </ul>
            </cds-accordion-item>
            <cds-accordion-item title="Study Area" (selected)="selected($event)">
                <ul cdsList class="unstyled-list">
                    <li cdsListItem>Parks*</li>
                    <li cdsListItem>GIS Location</li>
                </ul>
            </cds-accordion-item>
            <cds-accordion-item title="Data Collection" (selected)="selected($event)">
                <ul cdsList class="unstyled-list">
                    <li cdsListItem> Will your project involve collection of specimens?*
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem> Types of specimens to be collected*
                                <ul cdsList class="unstyled-list">
                                    <li cdsListItem>Species*</li>
                                    <li cdsListItem>Quantity*</li>
                                    <li cdsListItem>Size*</li>
                                    <li cdsListItem>Condition*</li>
                                </ul>
                            </li>
                            <li cdsListItem> Collection rationale: Describe why collection is necessary, and the disposition of
                                specimens collected. Provide power analysis to justify proposed sample size(s).*
                            </li>
                            <li cdsListItem> Collection rationale optional supporting documents</li>
                            <li cdsListItem>Curation facility*</li>
                            <li cdsListItem>Curation Responsible official name*</li>
                            <li cdsListItem>Curation Phone*</li>
                            <li cdsListItem>Curation Email*</li>
                        </ul>
                    </li>
                    <li cdsListItem> Will your project involve laboratory work?*
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem>Laboratory facility*</li>
                            <li cdsListItem>Laboratory Responsible official name*</li>
                            <li cdsListItem>Laboratory Phone*</li>
                            <li cdsListItem>Laboratory Email*</li>
                            <li cdsListItem>Lab study start and end dates*</li>
                        </ul>
                    </li>
                    <li cdsListItem>Study Procedures: Describe your study and sampling design, field and laboratory
                        methods or protocols, treatments and controls, number of replicates, and statistical analyses
                        and models, and include power analyses to justify proposed sample size(s) needed to answer the
                        question(s).*
                    </li>
                    <li cdsListItem>Study Procedures optional supporting documents
                    </li>
                    <li cdsListItem>Location of data and data products collected under this permit: Describe anticipated
                        products and deliverables resulting from this study, such as reports, publications, brochures,
                        GIS layers, web tools, videos, etc., and how study results will be disseminated.*
                    </li>
                </ul>
            </cds-accordion-item>
            <cds-accordion-item title="Additional Documentation" (selected)="selected($event)">
                <ul cdsList class="unstyled-list">
                    <li cdsListItem> Will your project involve soil disturbance (placement of stakes, collection of soil
                        cores, etc.)?*
                        <ul cdsList class="unstyled-list">
                            <li cdsListItem> Soil disturbance details: Describe why collection is necessary, the
                                disposition of specimens collected. Provide power analysis to justify proposed sample
                                size(s).*
                            </li>
                            <li cdsListItem> Soil disturbance areas and supporting documents</li>
                        </ul>
                    </li>
                    <li cdsListItem> Will your project require additional federal, state, or local permits?
                        <ul cdsList class="unstyled-list"> If yes:
                            <li cdsListItem>Existing additional permits and/or supporting documents*</li>
                            <li cdsListItem>If you replied yes but do not have all the necessary permits prior to
                                submission of this application, provide rationale or additional information
                            </li>
                        </ul>
                    </li>
                    <li cdsListItem>Budget</li>
                    <li cdsListItem>Budget supporting documents</li>
                    <li cdsListItem>Literature Cited</li>
                    <li cdsListItem>Literature Cited supporting documents</li>
                    <li cdsListItem>Study Proposal (may be required)</li>
                    <li cdsListItem>Standard condition agreement*</li>
                    <li cdsListItem>Waiver and indemnity agreement*</li>
                    <li cdsListItem>Will you conduct or plan on conducting research activities above and beyond simple
                        use? (I.e. Examples of activities beyond simple use include but are not limited to the use of
                        aircraft or drones, boats, high risk vehicle use, scuba diving, climbing equipment, trapping,
                        handling of large and/or dangerous wildlife, and collection of materials that create risk such
                        as large pinecones, projects that include large teams of volunteers, etc.)?*
                        <ul cdsList class="unstyled-list"> If yes:
                            <li cdsListItem>Required Insurance Addendum*</li>
                        </ul>
                    </li>
                </ul>
            </cds-accordion-item>
            <cds-accordion-item title="Documents to download" (selected)="selected($event)">
                <p>Required documents</p>
                <ul cdsList class="unstyled-list">
                    <li cdsListItem>
                        Standard Condition Agreement (<a cdsLink href="/assets/pdfs/standard_conditions_agreement.pdf" target="_blank">Download unsigned form</a>)
                    </li>
                    <li cdsListItem>
                        Waiver and Indemnity Agreement (<a cdsLink href="/assets/pdfs/waiver_and_indemnity_agreement.pdf" target="_blank">Download unsigned form</a>)
                    </li>
                </ul>
                <p>Optional documents</p>
                <ul cdsList class="unstyled-list">
                    <li cdsListItem>
                        Optional Insurance Addendum (<a cdsLink href="/assets/pdfs/DPR65A.pdf" target="_blank">Download unsigned form</a>)
                        <p class="cds--label">This form is required when the proposed research requires activities above and beyond simple
                            use. If your research includes activities beyond simple use like the use of aircraft or
                            drones, boats, high risk vehicle use, and others, you will be asked to submit this form
                            signed during your application. </p>
                    </li>
                    <li cdsListItem>
                        Optional Liability Waiver Addendum (<a cdsLink href="/assets/pdfs/DPR65B.pdf" target="_blank">Download unsigned form</a>)
                        <p class="cds--label">This form is required when all project personnel are employed or insured
                            by the institution conducting the research or at the discretion of the application reviewer.
                            <strong>This document must be signed by ALL participants involved in research activities, so
                                plan accordingly.</strong></p>
                    </li>
                </ul>
            </cds-accordion-item>
        </cds-accordion>
        </div>
    </div>
</div>
