import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
  selector: 'castateparksscp-research-project-study-procedures-form',
  templateUrl: './../basic/shared/basic-form/basic-form.component.html',
  styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectStudyProceduresFormComponent extends BasicFormComponent {
    upsertDto: StudyProceduresDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'StudyProceduresDetails',
                label: 'Study Procedures',
                type: 'textArea',
                helperText: 'Describe your study and sampling design, field and laboratory methods or protocols, treatments and controls, number of replicates, and statistical analyses and models, and include power analyses to justify proposed sample size(s) needed to answer the question(s).',
                checkbox: true,
                checkboxName: 'ProposalIncludesStudyProcedures',
                requiredValidated: true
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                StudyProceduresDetails: dto.StudyProceduresDetails,
                ProposalIncludesStudyProcedures: dto.ProposalIncludesStudyProcedures} as StudyProceduresDto
        };
    }
}

export interface StudyProceduresDto {
    StudyProceduresDetails: string;
    ProposalIncludesStudyProcedures: boolean;
}
