import {DatePipe} from "@angular/common";
import {Injectable} from "@angular/core";
import {ColDef} from "ag-grid-community";

@Injectable({
    providedIn: "root",
})
export class DateColumnCreatorService {
    constructor() {
    }

    public createDateColumnDef(headerName: string, fieldName: string, dateFormat: string): ColDef {
        let datePipe = new DatePipe("en-US");
        return {
            cellEditor: 'agDateCellEditor',
            headerName: headerName,
            field: fieldName,
            valueFormatter: function (params: any) {
                return datePipe.transform(params.data[fieldName], dateFormat);
            },
            valueGetter: function (params: any) {
                return params.data[fieldName] == undefined? null: new Date(params.data[fieldName]);
            },
            filter: "agDateColumnFilter",
            filterParams: {
                filterOptions: ["inRange"],
                comparator: this.dateFilterComparator,
            },
            width: 150,
            comparator: function (dateA, dateB, nodeA, nodeB, isDescending) {
                let valueA = Date.parse(dateA);
                let valueB = Date.parse(dateB);

                return valueA - valueB;
            },
        };
    }

    private dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
        const cellDate = Date.parse(cellValue);
        if (cellDate == filterLocalDateAtMidnight) {
            return 0;
        }

        return cellDate < filterLocalDateAtMidnight ? -1 : 1;
    }

    public createDateColumnDefWithConfigMap(headerName: string, fieldName: string, dateFormat: string,
                                            configMap: Map<string, any> = new Map<string, any>(
                                                [
                                                    ['flex', 1],
                                                    ['width', null],
                                                ])): ColDef {
        const colDef = this.createDateColumnDef(headerName, fieldName, dateFormat);
        colDef['cellClass'] = 'long-text-cell';
        configMap.forEach((v, k) => colDef[k] = v);
        return colDef;
    }
}
