import { Injectable } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CsvExportParams } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class UtilityFunctionsService {

  constructor() { }

  public stringToKebabCase(string: string): string {
    return string.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase());
  }

  public linkRendererComparator(id1: any, id2: any) {
    if (id1.LinkDisplay == id2.LinkDisplay) {
      return 0;
    }
    return id1.LinkDisplay > id2.LinkDisplay ? 1 : -1;
  }

  public buildTableColumnsForArray(items: Array<any>, numberOfColumns: number, minItemsPerColumn: number = null) {
      if (!!minItemsPerColumn && items.length <= minItemsPerColumn) {
          return [items, null, null, null, null];
      } else {
          let itemsPerColumn = Math.ceil((items.length * 100 / numberOfColumns) / 100);
          let itemColumns = [];
          for (let i = 0; i < numberOfColumns; i++) {
              itemColumns[i] = items.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
          }
          return itemColumns;
      }
  }

  public exportGridToCsv(grid: AgGridAngular, fileName: string, columnKeys: Array<string>) {
    var params =
      {
        skipHeader: false,
        columnGroups: false,
        skipFooters: true,
        skipGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        allColumns: true,
        onlySelected: false,
        suppressQuotes: false,
        fileName: fileName,
        processCellCallback: function (p) {
          if (p.column.getColDef().cellRenderer) {
            if (p.value.DownloadDisplay) {
              return p.value.DownloadDisplay;
            } else {
              return p.value.LinkDisplay;
            }
          }
          else {
            return p.value;
          }
        }
      } as CsvExportParams
    if (columnKeys) {
      params.columnKeys = columnKeys;
    }
    grid.api.exportDataAsCsv(params);
  }
}
