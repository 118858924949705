import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HomeIndexComponent} from "./pages/home/home-index/home-index.component";
import {UserListComponent} from "./pages/user/user-list/user-list.component";
import {RouterModule} from "@angular/router";
import {UserDetailComponent} from "./pages/user/user-detail/user-detail.component";
import {UserEditComponent} from "./pages/user-edit/user-edit.component";
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LoginCallbackComponent} from "./pages/login-callback/login-callback.component";
import {HelpComponent} from "./pages/help/help.component";
import {CreateUserCallbackComponent} from "./pages/create-user-callback/create-user-callback.component";
import {FieldDefinitionListComponent} from "./pages/field-definition-list/field-definition-list.component";
import {FieldDefinitionEditComponent} from "./pages/field-definition-edit/field-definition-edit.component";
import {HttpErrorInterceptor} from "./shared/interceptors/httpErrorInterceptor";
import {environment} from "src/environments/environment";
import {AppInsightsService} from "./shared/services/app-insights.service";
import {GlobalErrorHandlerService} from "./shared/services/global-error-handler.service";
import {CustomPageListComponent} from "./pages/custom-page-list/custom-page-list.component";
import {CustomPageDetailComponent} from "./pages/custom-page-detail/custom-page-detail.component";
import {
    CustomPageEditPropertiesComponent
} from "./pages/custom-page-edit-properties/custom-page-edit-properties.component";
import {SelectDropDownModule} from "ngx-select-dropdown";
import {SharedModule} from "./shared/shared.module";
import {ApiModule} from "./shared/generated/api.module";
import {Configuration} from "./shared/generated/configuration";
import {InteractionType, IPublicClientApplication, PublicClientApplication,} from "@azure/msal-browser";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from "@azure/msal-angular";
import {AuthInterceptor} from "./shared/interceptors/auth-interceptor";
import {msalConfig} from "./auth.config";
import {DashboardManageComponent} from "./pages/dashboards/dashboard-manage/dashboard-manage.component";
import {AgGridModule} from "ag-grid-angular";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatRadioModule} from "@angular/material/radio";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatCardModule} from "@angular/material/card";
import {EsaMaterialButtonComponent, EsaMaterialFormFieldComponent} from "esa-material-form-field";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ButtonRendererComponent} from "./shared/components/ag-grid/button-renderer/button-renderer.component";
import {ProjectListComponent} from './pages/project/project-list/project-list.component';
import {ProjectCreateComponent} from './pages/project/project-create/project-create.component';
import {BypassHtmlPipe} from './pipes/bypass-html.pipe';
import {ProjectDetailComponent} from "./pages/project/project-detail/project-detail.component";
import {ProjectFormComponent} from "./pages/project/shared/project-form/project-form.component";
import {
    ProjectDetailSummaryComponent
} from "./pages/project/project-detail/project-detail-summary/project-detail-summary.component";
import {UserInviteComponent} from "./pages/user/user-invite/user-invite.component";
import {ProjectFileListComponent} from "./pages/project/project-detail/project-file-list/project-file-list.component";
import {
    ResearchProjectSpecialConditionsGridComponent
} from "./pages/internal-review/research-project-special-conditions-grid/research-project-special-conditions-grid.component";

// carbon-components-angular default imports
import {
    AccordionModule,
    ButtonModule,
    CheckboxModule,
    ComboBoxModule,
    DatePickerModule,
    DropdownModule,
    GridModule,
    IconModule,
    InlineLoadingModule,
    InputModule,
    LinkModule,
    ListModule,
    ModalModule,
    NotificationModule,
    NumberModule,
    ProgressIndicatorModule,
    RadioModule,
    SelectModule,
    TableModule,
    TagModule,
    TilesModule,
    ToggleModule,
    UIShellModule,
} from 'carbon-components-angular';
import {PermitCreateComponent} from './pages/permit/permit-create/permit-create.component';
import {PermitDetailComponent} from './pages/permit/permit-detail/permit-detail.component';
import {PermitListComponent} from './pages/permit/permit-list/permit-list.component';
import {PermitFormComponent} from './pages/permit/shared/permit-form/permit-form.component';
import {BasicCreateComponent} from './pages/basic/basic-create/basic-create.component';
import {BasicDetailComponent} from './pages/basic/basic-detail/basic-detail.component';
import {BasicListComponent} from './pages/basic/basic-list/basic-list.component';
import {BasicFormComponent} from './pages/basic/shared/basic-form/basic-form.component';
import {BasicFileListComponent} from './pages/basic/basic-detail/basic-file-list/basic-file-list.component';
import {BasicEditListComponent} from './pages/basic/basic-edit-list/basic-edit-list.component';
import {ContactCreateComponent} from './pages/contact/contact-create/contact-create.component';
import {ContactDetailComponent} from './pages/contact/contact-detail/contact-detail.component';
import {ContactListComponent} from './pages/contact/contact-list/contact-list.component';
import {ContactFormComponent} from './pages/contact/shared/contact-form/contact-form.component';
import {
    ContactDetailSummaryComponent
} from './pages/contact/contact-detail/contact-detail-summary/contact-detail-summary.component';
import {GridTestComponent} from './pages/grid-test/grid-test.component';
import {TaxonListComponent} from './pages/taxon/taxon-list/taxon-list.component';
import {
    StandardConditionListComponent
} from './pages/standard-condition/standard-condition-list/standard-condition-list.component';
import {UserFormComponent} from './pages/user/shared/user-form/user-form.component';
import {DistrictListComponent} from './pages/district/district-list/district-list.component';
import {
    ResearchTeamCreatePublicComponent
} from './pages/research-team/research-team-create-public/research-team-create-public.component';
import {
    ResearchPermitPublicFormProgressIndicatorComponent
} from './pages/research-permit-public-form-progress-indicator/research-permit-public-form-progress-indicator.component';
import {
    ContactFormOrganizationComponent
} from "./pages/contact/shared/contact-form/contact-form-organization.component";
import {
    ContactFormPrincipalInvestigatorComponent
} from "./pages/contact/shared/contact-form/contact-form-principal-investigator.component";
import {
    ContactFormAdditionalParticipantComponent
} from "./pages/contact/shared/contact-form/contact-form-additional-participant.component";
import {
    ContactFormPersonInChargeOfFieldWorkComponent
} from "./pages/contact/shared/contact-form/contact-form-person-in-charge-of-field-work.component";
import {DistrictDetailComponent} from './pages/district/district-detail/district-detail.component';
import {
    DistrictDetailSummaryComponent
} from './pages/district/district-detail/district-detail-summary/district-detail-summary.component';
import {DistrictFormComponent} from './pages/district/shared/district-form/district-form.component';
import {
    ResearchProjectCreatePublicComponent
} from './pages/research-project/research-project-create-public/research-project-create-public.component';
import {
    ResearchProjectInformationFormComponent
} from "./pages/research-project/research-project-create-public/research-project-information-form.component";
import {
    ResearchProjectFieldInvestigationOccurrenceListComponent
} from "./pages/research-project/research-project-create-public/research-project-field-investigation-occurrence-list.component";
import {
    ResearchProjectSiteLocationComponent
} from './pages/research-project-site-location/research-project-site-location.component';
import {
    ResearchProjectDocumentsComponent
} from './pages/research-project-documents/research-project-documents.component';
import {
    ResearchProjectReviewAndSubmitComponent
} from './pages/research-project-review-and-submit/research-project-review-and-submit.component';
import {
    ResearchProjectDataCollectionComponent
} from './pages/research-project-data-collection/research-project-data-collection.component';
import {PublicHomeComponent} from './pages/public-home/public-home.component';
import {DistrictContactFormComponent} from './pages/district/district-contact-form/district-contact-form.component';
import {DistrictContactModalComponent} from './pages/district/district-contact-modal/district-contact-modal.component';
import {ResearchPermitListComponent} from './pages/research-permit-list/research-permit-list.component';
import {
    ResearchProjectFileListComponent
} from './pages/research-project-file/research-project-file-list/research-project-file-list.component';
import {
    ResearchProjectFileListManagerComponent
} from './pages/research-project-file/research-project-file-list-manager/research-project-file-list-manager.component';
import {
    ResearchTeamPersonChargeOfFieldWorkFlagForm
} from "./pages/research-team/research-team-create-public/research-team-person-charge-of-field-work-flag-form";
import {
    ResearchTeamUserIsPrincipalInvestigatorForm
} from "./pages/research-team/research-team-create-public/research-team-user-is-principal-investigator-form";
import {InternalReviewComponent} from './pages/internal-review/internal-review.component';
import {
    ResearchTeamAdditionalParticipantsListComponent
} from "./pages/research-team/research-team-create-public/research-team-additional-participants-list.component";
import {
    ResearchProjectStudyAreaFormComponent
} from './pages/research-project-site-location/research-project-study-area-form.component';
import {
    ResearchProjectStudyProceduresFormComponent
} from './pages/research-project-data-collection/research-project-study-procedures-form.component';
import {
    ResearchProjectLaboratoryWorkFlagFormComponent
} from './pages/research-project-data-collection/research-project-laboratory-work-flag-form.component';
import {
    ResearchProjectLaboratoryWorkFormComponent
} from './pages/research-project-data-collection/research-project-laboratory-work-form.component';
import {
    ResearchProjectSpecimenCollectionFlagFormComponent
} from './pages/research-project-data-collection/research-project-specimen-collection-flag-form.component';
import {
    ResearchProjectSpecimenCollectionFormComponent
} from './pages/research-project-data-collection/research-project-specimen-collection-form.component';
import {
    ResearchProjectCollectionRationaleFormComponent
} from './pages/research-project-data-collection/research-project-collection-rationale-form.component';
import {
    ResearchProjectSpecimenCollectionListComponent
} from './pages/research-project-data-collection/research-project-specimen-collection-list.component';
import {
    ResearchProjectDataProductsFormComponent
} from './pages/research-project-data-collection/research-project-data-products-form.component';
import {
    ResearchProjectSoilDisturbanceFormComponent
} from './pages/research-project-documents/research-project-soil-disturbance-form.component';
import {
    ResearchProjectSoilDisturbanceFlagFormComponent
} from './pages/research-project-documents/research-project-soil-disturbance-flag-form.component';
import {
    ResearchProjectAdditionalPermitFlagFormComponent
} from './pages/research-project-documents/research-project-additional-permit-flag-form.component';
import {
    ResearchProjectAdditionalPermitFormComponent
} from './pages/research-project-documents/research-project-additional-permit-form.component';
import {
    ResearchProjectBudgetFormComponent
} from './pages/research-project-documents/research-project-budget-form.component';
import {
    ResearchProjectLiteratureCitedFormComponent
} from './pages/research-project-documents/research-project-literature-cited-form.component';
import {
    ReviewSectionComponent
} from './pages/research-project-review-and-submit/review-section/review-section.component';
import {ResearchProjectSuccessComponent} from './pages/research-project-success/research-project-success.component';
import {
    ResearchProjectDpr65aFlagFormComponent
} from './pages/research-project-documents/research-project-dpr65a-flag-form.component';
import {
    ResearchProjectDpr65bFlagFormComponent
} from './pages/research-team/research-team-create-public/research-project-dpr65b-flag-form.component';
import {SpecialConditionListComponent} from './pages/special-condition-list/special-condition-list.component';
import {
    InternalReviewAssigneeFormComponent
} from './pages/internal-review/internal-revew-internal-fields-form/internal-review-assignee-form.component';
import {BasicModalComponent} from './pages/basic/basic-modal/basic-modal.component';
import {BasicModalFormComponent} from './pages/basic/basic-modal/basic-modal-form/basic-modal-form.component';
import {
    BasicConfigurableModalComponent
} from './pages/basic/basic-configurable-modal/basic-configurable-modal.component';
import {UnsignedPermitFormComponent} from './pages/internal-review/unsigned-permit-form/unsigned-permit-form.component';
import {
    PermitWorkflowChangeListComponent
} from './pages/internal-review/permit-workflow-change-list/permit-workflow-change-list.component';
import {InternalNotesFormComponent} from './pages/internal-review/internal-notes-form/internal-notes-form.component';
import {
    ResearchProjectDistrictSpecialConditionsGridComponent
} from './pages/internal-review/research-project-district-special-conditions-grid/research-project-district-special-conditions-grid.component';
import {
    ResourceCategoryTagFormComponent
} from './pages/internal-review/resource-category-tag-form/resource-category-tag-form.component';
import {
    ResourceCategoryTagListComponent
} from './pages/resource-category-tag-list/resource-category-tag-list.component';
import {
    ReturnedToResearcherNotificationComponent
} from './pages/research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component';
import {
    PublicInstructionalTextComponent
} from './pages/research-project/shared/public-instructional-text/public-instructional-text.component';
import {
    ReadonlyNotificationComponent
} from './pages/research-project/shared/readonly-notification/readonly-notification.component';
import {
    AnnualReportNotificationComponent
} from './pages/research-project/shared/annual-report-notification/annual-report-notification.component';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

export function init_app(appInsightsService: AppInsightsService) {
    return () => {
        if (environment.appInsightsInstrumentationKey) {
            appInsightsService.initAppInsights();
        }
    };
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: null,
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HomeIndexComponent,
        UserListComponent,
        UserDetailComponent,
        UserEditComponent,
        LoginCallbackComponent,
        HelpComponent,
        CreateUserCallbackComponent,
        FieldDefinitionListComponent,
        FieldDefinitionEditComponent,
        CustomPageListComponent,
        CustomPageDetailComponent,
        CustomPageEditPropertiesComponent,
        DashboardManageComponent,
        ButtonRendererComponent,
        ProjectListComponent,
        ProjectDetailComponent,
        ProjectCreateComponent,
        ProjectFormComponent,
        ProjectDetailSummaryComponent,
        BypassHtmlPipe,
        UserInviteComponent,
        ProjectFileListComponent,
        PermitCreateComponent,
        PermitDetailComponent,
        PermitListComponent,
        PermitFormComponent,
        BasicCreateComponent,
        BasicDetailComponent,
        BasicListComponent,
        BasicFormComponent,
        BasicFileListComponent,
        ContactCreateComponent,
        ContactDetailComponent,
        ContactListComponent,
        ContactFormComponent,
        ContactFormOrganizationComponent,
        ContactFormPrincipalInvestigatorComponent,
        ContactFormAdditionalParticipantComponent,
        ContactFormPersonInChargeOfFieldWorkComponent,
        ContactFormOrganizationComponent,
        ResearchProjectInformationFormComponent,
        ContactDetailSummaryComponent,
        TaxonListComponent,
        StandardConditionListComponent,
        UserFormComponent,
        DistrictListComponent,
        ResearchTeamCreatePublicComponent,
        ResearchPermitPublicFormProgressIndicatorComponent,
        DistrictDetailComponent,
        DistrictDetailSummaryComponent,
        DistrictFormComponent,
        ResearchProjectCreatePublicComponent,
        ResearchProjectFieldInvestigationOccurrenceListComponent,
        ResearchProjectSiteLocationComponent,
        ResearchProjectDocumentsComponent,
        ResearchProjectReviewAndSubmitComponent,
        ResearchProjectDataCollectionComponent,
        PublicHomeComponent,
        DistrictContactFormComponent,
        DistrictContactModalComponent,
        ResearchPermitListComponent,
        ResearchProjectFileListComponent,
        ResearchProjectFileListManagerComponent,
        ResearchTeamPersonChargeOfFieldWorkFlagForm,
        ResearchTeamUserIsPrincipalInvestigatorForm,
        InternalReviewComponent,
        ResearchTeamAdditionalParticipantsListComponent,
        ResearchProjectStudyAreaFormComponent,
        ResearchProjectStudyProceduresFormComponent,
        ResearchProjectLaboratoryWorkFlagFormComponent,
        ResearchProjectLaboratoryWorkFormComponent,
        ResearchProjectSpecimenCollectionFlagFormComponent,
        ResearchProjectSpecimenCollectionFormComponent,
        ResearchProjectCollectionRationaleFormComponent,
        ResearchProjectSpecimenCollectionListComponent,
        ResearchProjectDataProductsFormComponent,
        ResearchProjectSoilDisturbanceFormComponent,
        ResearchProjectSoilDisturbanceFlagFormComponent,
        ResearchProjectAdditionalPermitFlagFormComponent,
        ResearchProjectAdditionalPermitFormComponent,
        ResearchProjectBudgetFormComponent,
        ResearchProjectLiteratureCitedFormComponent,
        ReviewSectionComponent,
        ResearchProjectSuccessComponent,
        ResearchProjectDpr65aFlagFormComponent,
        ResearchProjectDpr65bFlagFormComponent,
        SpecialConditionListComponent,
        InternalReviewAssigneeFormComponent,
        BasicModalComponent,
        BasicModalFormComponent,
        BasicConfigurableModalComponent,
        UnsignedPermitFormComponent,
        PermitWorkflowChangeListComponent,
        InternalNotesFormComponent,
        ResearchProjectDistrictSpecialConditionsGridComponent,
        ResearchProjectSpecialConditionsGridComponent,
        ResourceCategoryTagFormComponent,
        ResourceCategoryTagListComponent,
        ReturnedToResearcherNotificationComponent,
        PublicInstructionalTextComponent,
        AnnualReportNotificationComponent,
        ReadonlyNotificationComponent
    ],
    imports: [
        GridTestComponent,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        SharedModule.forRoot(),
        FormsModule.withConfig({
            callSetDisabledState: "whenDisabledForLegacyCode",
        }),
        BrowserAnimationsModule,
        AgGridModule,
        SelectDropDownModule,
        ApiModule.forRoot(() => {
            return new Configuration({
                basePath: `${environment.mainAppApiUrl}`,
            });
        }),
        MsalModule,
        MatExpansionModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatChipsModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatCardModule,
        MatAutocompleteModule,
        MatDialogModule,
        MsalModule,
        MatRadioModule,
        MatTooltipModule,
        MatButtonModule,
        MsalModule,
        MatMomentDateModule,
        EsaMaterialFormFieldComponent,
        EsaMaterialButtonComponent,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        UIShellModule,
        IconModule,
        GridModule,
        ProgressIndicatorModule,
        RadioModule,
        SelectModule,
        DatePickerModule,
        InputModule,
        ButtonModule,
        ToggleModule,
        TableModule,
        NumberModule,
        TilesModule,
        AccordionModule,
        BasicEditListComponent,
        CheckboxModule,
        LinkModule,
        DropdownModule,
        ComboBoxModule,
        ListModule,
        ModalModule,
        InlineLoadingModule,
        TagModule,
        NotificationModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppInsightsService],
            multi: true
        },
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        DecimalPipe,
        CurrencyPipe,
        DatePipe,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {appearance: "outline", hideRequiredMarker: true},
        },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
}
