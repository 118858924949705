import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from "../basic/basic-list/basic-list.component";
import {PermissionEnum} from "../../shared/generated/enum/permission-enum";
import {AuthenticationService} from "../../services/authentication.service";
import {DateColumnCreatorService} from "../../shared/services/date-column-creator/date-column-creator.service";
import {Router} from "@angular/router";
import {AlertService} from "../../shared/services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {
    InLineEditButtonsRendererComponent
} from "../../shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component";
import {GetRowIdFunc, GetRowIdParams} from "ag-grid-community";
import {ResearchProjectSpecimenItemService} from "../../shared/generated/api/research-project-specimen-item.service";
import {
    ResearchProjectSpecimenItemUpsertDto
} from "../../shared/generated/model/research-project-specimen-item-upsert-dto";
import {RequiredHeaderComponent} from "../../shared/components/ag-grid/required-header/required-header.component";

@Component({
    selector: 'castateparksscp-research-project-specimen-collection-list',
    templateUrl: './../basic/basic-list/basic-list.component.html',
    styleUrl: './research-project-specimen-collection-list.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResearchProjectSpecimenCollectionListComponent extends BasicListComponent implements OnInit {
    @Input() researchProjectID: number;

    isH4Title: boolean = true;

    listPage: ListPage = {
        permission: PermissionEnum.ResearchProjectRights,
        pageTitle: 'Types of specimens to be collected',
        createButtonLabel: 'species',
        downloadFileName: 'SpecimenCollection',
        isShowAddButton: true,
        iSShowTitle: true,
        gridTableStyle: 'height: 150px',
        defaultColDef: {
            sortable: true,
            filter: false,
            resizable: true,
            floatingFilter: false,
            suppressMenu: true,
        },
        isHideDownload: true,
        headerText: "<cds-text-label>Click on save icon (<span class=\"text-muted fas fa-save\"></span>) to save any grid edits.</cds-text-label>",
        headerTextClass: 'copy-4',
        isShowHeader: true,
        headerTextNoMargin: true,
    } as ListPage;

    useInGridEdit: boolean = true;

    constructor(
        public researchProjectSpecimenItemService: ResearchProjectSpecimenItemService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.researchProjectSpecimenItemService
            .researchProjectSpecimenItemsResearchProjectsResearchProjectIDGet(this.researchProjectID);
        this.listPage.columnDefs = [
            {
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                        },
                    };
                },
                editable: false,
                width: 100,
                pinned: 'left',
            },
            {
                headerName: "Species",
                field: "Species",
                editable: true,
                flex: 2,
                headerComponentParams: { required: true }
            },
            {
                headerName: "Quantity",
                field: "Quantity",
                editable: true,
                flex: 1,
                headerComponentParams: { required: true }
            },
            {
                headerName: "Size",
                field: "Size",
                editable: true,
                flex: 1,
                headerComponentParams: { required: true }
            },
            {
                headerName: "Condition",
                field: "Condition",
                editable: true,
                flex: 5,
                headerComponentParams: { required: true }
            }
        ]
    }

    public idField: string = 'ResearchProjectSpecimenItemID';
    public startEditingColKey: string = 'Species';

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ResearchProjectSpecimenItemUpsertDto> | any) => params.data.ResearchProjectSpecimenItemID;
    public putAction = (id: number, dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectSpecimenItemService.researchProjectSpecimenItemsResearchProjectSpecimenItemIDPut(id, dto);
    }
    public postAction = (dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectSpecimenItemService.researchProjectSpecimenItemsPost(dto);
    }

    public deleteAction = (id: number) => {
        return this.researchProjectSpecimenItemService.researchProjectSpecimenItemsResearchProjectSpecimenItemIDDelete(id);
    }

    ngOnInit() {
        super.ngOnInit();
        this.gridComponents['agColumnHeader'] = RequiredHeaderComponent;
    }

}
