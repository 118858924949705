import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'castateparksscp-research-project-budget-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectBudgetFormComponent extends BasicFormComponent {
    upsertDto: BudgetDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'BudgetDetails',
                label: 'Budget (optional)',
                type: 'textArea',
                checkbox: true,
                checkboxName: 'ProposalIncludesBudget'
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                BudgetDetails: dto?.BudgetDetails,
                ProposalIncludesBudget: dto?.ProposalIncludesBudget
            } as BudgetDto
        };
    }
}

export interface BudgetDto {
    BudgetDetails: string;
    ProposalIncludesBudget: boolean;
}
