import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from "../basic/basic-list/basic-list.component";
import {PermissionEnum} from "../../shared/generated/enum/permission-enum";
import {AuthenticationService} from "../../services/authentication.service";
import {DateColumnCreatorService} from "../../shared/services/date-column-creator/date-column-creator.service";
import {Router} from "@angular/router";
import {AlertService} from "../../shared/services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {HtmlRendererComponent} from "../../shared/components/ag-grid/html-renderer/html-renderer.component";
import {RightsEnum} from "../../shared/models/enums/rights.enum";
import {
    InLineEditButtonsRendererComponent
} from "../../shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component";
import {GetRowIdFunc, GetRowIdParams} from "ag-grid-community";
import {SpecialConditionSimpleDto} from "../../shared/generated/model/special-condition-simple-dto";
import {ResourceCategoryTagService} from "../../shared/generated/api/resource-category-tag.service";

@Component({
    selector: 'castateparksscp-resource-category-tag-list',
    templateUrl: '../basic/basic-list/basic-list.component.html',
    styleUrl: './resource-category-tag-list.component.css'

})
export class ResourceCategoryTagListComponent extends BasicListComponent implements OnInit {
    listPage: ListPage = {
        permission: PermissionEnum.ResourceCategoryTagRights,
        pageTitle: 'Resource Category Tags',
        createButtonLabel: 'Tag',
        downloadFileName: 'tags',
        isShowAddButton: true,
        divId: 'tagList',
        isShowHeader: true,
        headerText: 'Changes to this tag will affect all research projects associated to that tag.'
    } as ListPage;

    useInGridEdit: boolean = true;

    constructor(public resourceCategoryTagService: ResourceCategoryTagService,
                public authenticationService: AuthenticationService,
                public dateColumnCreator: DateColumnCreatorService,
                public cdr: ChangeDetectorRef,
                public router: Router,
                public alertService: AlertService,
                public dialog: MatDialog,) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);

        this.listPage.dtosGetter = () => this.resourceCategoryTagService.resourceCategoryTagsGet();
        this.listPage.columnDefs = [
            {
                field: "Content",
                headerName: "Condition",
                cellRendererSelector: (params) => {
                    return {
                        component: HtmlRendererComponent,
                        params: params
                    }
                },
                flex: 1,
                editable: true,
            },
        ];
    }

    override doActionAfterSettingCurrentUser() {
        if (this.authenticationService.hasPermission(this.currentUser,
            PermissionEnum.ResourceCategoryTagRights,
            RightsEnum.Update)) {
            this.listPage.columnDefs.unshift({
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                        },
                    };
                },
                width: 100,
                pinned: 'left',
                editable: false
            });
            this.grid?.api?.setGridOption("columnDefs", this.listPage.columnDefs);
        }
    }

    public idField: string = 'ResourceCategoryTagID';
    public startEditingColKey: string = 'Content';

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<SpecialConditionSimpleDto> | any) => params.data.ResourceCategoryTagID;
    public putAction = (id: number, dto: any) => {
        return this.resourceCategoryTagService.resourceCategoryTagsResourceCategoryTagIDPut(id, dto);
    }
    public postAction = (dto: any) => {
        return this.resourceCategoryTagService.resourceCategoryTagsPost(dto);
    }

    public deleteAction = (id: number) => {
        return this.resourceCategoryTagService.resourceCategoryTagsResourceCategoryTagIDDelete(id);
    }
}
