import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PermissionEnum} from '../shared/generated/enum/permission-enum';
import {RightsEnum} from '../shared/models/enums/rights.enum';
import {AuthenticationService} from './authentication.service';
import {ContactDto} from '../shared/generated/model/contact-dto';
import {UserDto} from '../shared/generated/model/user-dto';
import {ContactParkDto, ContactUpsertDto} from '../shared/generated/model/models';

@Injectable({
    providedIn: 'root'
})
export class CurrentContactService {
    currentContact$ = new BehaviorSubject<ContactDto>(null);

    constructor(private authenticationService: AuthenticationService) {
    }

    setCurrentContact(contact: ContactDto): void {
        this.currentContact$.next(contact);
    }

    getCurrentContact(): Observable<any> {
        return this.currentContact$.asObservable();
    }

    createContactDto(contact: ContactDto) {
        let contactDto = new ContactUpsertDto({
            ContactID: contact?.ContactID,
            FirstName: contact?.FirstName,
            LastName: contact?.LastName,
            PhoneNumber1: contact?.PhoneNumber1,
            Extension1: contact?.Extension1,
            Email: contact?.Email,
            OrganizationName: contact?.OrganizationName,
            Title: contact?.Title,
            Comment: contact?.Comment,
            DistrictID: null,
            AddressLine1: contact?.AddressLine1,
            AddressLine2: contact?.AddressLine2,
            City: contact?.City,
            StateID: contact?.StateID,
            CountryID: contact?.CountryID,
            PostalCode: contact?.PostalCode,
        });
        if (contactDto.CountryID == undefined) contactDto.CountryID = 234; //default to US
        if (contact && contact['StateDisplayName']) contactDto['StateDisplayName'] = contact['StateDisplayName'];
        if (contact && contact['CountryDisplayName']) contactDto['CountryDisplayName'] = contact['CountryDisplayName'];
        return contactDto;
    }

    createDistrictContactUpsertDto(contact: ContactParkDto) {
        return new ContactUpsertDto({
            ContactID: contact?.ContactID,
            FirstName: contact?.FirstName,
            LastName: contact?.LastName,
            PhoneNumber1: contact?.PhoneNumber1,
            Extension1: contact?.Extension1,
            Email: contact?.Email,
            Title: contact?.Title,
            DistrictID: contact?.Parks?.length ? null : contact?.DistrictID,
            ParkIDs: contact?.ParkIDs?.length ? contact.ParkIDs : []
        });
    }

    canEditCurrentContact(currentUser: UserDto, contact: ContactDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.ContactRights, RightsEnum.Update);
    }
}
