import {Component, OnInit} from '@angular/core';
import {BasicFormComponent, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {CurrentProjectService} from 'src/app/services/current-project.service';
import {ProjectService} from 'src/app/shared/generated/api/project.service';
import {ProjectUpsertDto} from 'src/app/shared/generated/model/project-upsert-dto';
import {AlertService} from 'src/app/shared/services/alert.service';

@Component({
    selector: 'castateparksscp-project-form',
    templateUrl: '../../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../../basic/shared/basic-form/basic-form.component.scss'],
})
export class ProjectFormComponent extends BasicFormComponent implements OnInit {
    upsertDto: ProjectUpsertDto;

    formPage: FormPage = {
        dtoName: 'project',
        elements: [
            {
                class: 'g-col-6',
                name: 'Name',
                label: 'Name',
                required: true,
                fieldDefinitionType: 'ProjectName',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'ProjectDate',
                label: 'Date',
                required: false,
                fieldDefinitionType: 'ProjectDate',
                type: 'date',
            },
            {
                class: 'g-col-12',
                name: 'Description',
                label: 'Description',
                required: false,
                fieldDefinitionType: 'ProjectDescription',
                type: 'editor',
            }
        ]
    } as FormPage;

    constructor(public currentProjectService: CurrentProjectService,
                public projectService: ProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => this.currentProjectService.createProjectDto(dto);
        this.formPage.post = (upsertDto) => this.projectService.projectsPost(upsertDto);
        this.formPage.put = (id, upsertDto) => this.projectService.projectsProjectIDPut(id, upsertDto);
    }
}
