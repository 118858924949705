<div class="detail-view">
    <div style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column" >
        <h1>{{researchProject?.Title}}: #{{researchProject?.ApplicationNumber}} was submitted!</h1>
        <p>A confirmation email was set to: {{researchProject?.ConfirmationEmails?.join(', ')}}</p>
        <p>If further information is required, or a decision is reached for this application, you will be notified via
            email.</p>
        <svg class="checkmark" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" style="margin-top: 3rem">
            <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
            </g>
        </svg>
        <button (click)="navigateToPermits()" cdsButton style="margin-top: 3rem">
            My Permits <svg class="cds--btn__icon" cdsIcon="add" size="20"></svg>
        </button>
    </div>
</div>
