export const buildStateIdAndDisplayName = (CountryID: number): Array<{ text: string, value: number }> => {
    let array = [];
    StateList.forEach(s => {
        if (s.CountryID == CountryID) {
            array.push({text: s.DisplayName, value: s.StateID})
        }
    });
    return array;
}

export const StateList: Array<{ StateID: number, CountryID: number, DisplayName: string }> =
    [{StateID: 73, CountryID: 1, DisplayName: "Andorra la Vella"},
        {StateID: 72, CountryID: 1, DisplayName: "Canillo"},
        {StateID: 71, CountryID: 1, DisplayName: "Encamp"},
        {StateID: 74, CountryID: 1, DisplayName: "Escaldes-Engordany"},
        {StateID: 70, CountryID: 1, DisplayName: "La Massana"},
        {StateID: 69, CountryID: 1, DisplayName: "Ordino"},
        {StateID: 68, CountryID: 1, DisplayName: "Sant Julià de Loria"},
        {StateID: 81, CountryID: 2, DisplayName: "Abu Dhabi"},
        {StateID: 80, CountryID: 2, DisplayName: "Ajman"},
        {StateID: 77, CountryID: 2, DisplayName: "Dubai"},
        {StateID: 79, CountryID: 2, DisplayName: "Fujairah"},
        {StateID: 75, CountryID: 2, DisplayName: "Imarat Umm al Qaywayn"},
        {StateID: 76, CountryID: 2, DisplayName: "Ra's al Khaymah"},
        {StateID: 78, CountryID: 2, DisplayName: "Sharjah"},
        {StateID: 111, CountryID: 3, DisplayName: "Badakhshan"},
        {StateID: 110, CountryID: 3, DisplayName: "Badghis"},
        {StateID: 109, CountryID: 3, DisplayName: "Baghlan"},
        {StateID: 108, CountryID: 3, DisplayName: "Balkh"},
        {StateID: 107, CountryID: 3, DisplayName: "Bamyan"},
        {StateID: 114, CountryID: 3, DisplayName: "Daykundi"},
        {StateID: 106, CountryID: 3, DisplayName: "Farah"},
        {StateID: 105, CountryID: 3, DisplayName: "Faryab"},
        {StateID: 104, CountryID: 3, DisplayName: "Ghazni"},
        {StateID: 103, CountryID: 3, DisplayName: "Ghowr"},
        {StateID: 102, CountryID: 3, DisplayName: "Helmand"},
        {StateID: 101, CountryID: 3, DisplayName: "Herat"},
        {StateID: 100, CountryID: 3, DisplayName: "Jowzjan"},
        {StateID: 99, CountryID: 3, DisplayName: "Kabul"},
        {StateID: 98, CountryID: 3, DisplayName: "Kandahar"},
        {StateID: 97, CountryID: 3, DisplayName: "Kapisa"},
        {StateID: 112, CountryID: 3, DisplayName: "Khowst"},
        {StateID: 96, CountryID: 3, DisplayName: "Kunar"},
        {StateID: 95, CountryID: 3, DisplayName: "Kunduz"},
        {StateID: 94, CountryID: 3, DisplayName: "Laghman"},
        {StateID: 93, CountryID: 3, DisplayName: "Logar"},
        {StateID: 92, CountryID: 3, DisplayName: "Nangarhar"},
        {StateID: 91, CountryID: 3, DisplayName: "Nimroz"},
        {StateID: 113, CountryID: 3, DisplayName: "Nuristan"},
        {StateID: 90, CountryID: 3, DisplayName: "Oruzgan"},
        {StateID: 89, CountryID: 3, DisplayName: "Paktia"},
        {StateID: 88, CountryID: 3, DisplayName: "Paktika"},
        {StateID: 115, CountryID: 3, DisplayName: "Panjshir"},
        {StateID: 87, CountryID: 3, DisplayName: "Parwan"},
        {StateID: 86, CountryID: 3, DisplayName: "Samangan"},
        {StateID: 85, CountryID: 3, DisplayName: "Sar-e Pol"},
        {StateID: 84, CountryID: 3, DisplayName: "Takhar"},
        {StateID: 83, CountryID: 3, DisplayName: "Vardak"},
        {StateID: 82, CountryID: 3, DisplayName: "Zabul"},
        {StateID: 123, CountryID: 4, DisplayName: "Barbuda"},
        {StateID: 122, CountryID: 4, DisplayName: "Redonda"},
        {StateID: 121, CountryID: 4, DisplayName: "Saint George"},
        {StateID: 120, CountryID: 4, DisplayName: "Saint John"},
        {StateID: 119, CountryID: 4, DisplayName: "Saint Mary"},
        {StateID: 118, CountryID: 4, DisplayName: "Saint Paul"},
        {StateID: 117, CountryID: 4, DisplayName: "Saint Peter"},
        {StateID: 116, CountryID: 4, DisplayName: "Saint Philip"},
        {StateID: 124, CountryID: 5, DisplayName: "Blowing Point"},
        {StateID: 128, CountryID: 5, DisplayName: "East End"},
        {StateID: 135, CountryID: 5, DisplayName: "George Hill"},
        {StateID: 134, CountryID: 5, DisplayName: "Island Harbour"},
        {StateID: 129, CountryID: 5, DisplayName: "North Hill"},
        {StateID: 133, CountryID: 5, DisplayName: "North Side"},
        {StateID: 125, CountryID: 5, DisplayName: "Sandy Ground"},
        {StateID: 126, CountryID: 5, DisplayName: "Sandy Hill"},
        {StateID: 131, CountryID: 5, DisplayName: "South Hill"},
        {StateID: 136, CountryID: 5, DisplayName: "Stoney Ground"},
        {StateID: 137, CountryID: 5, DisplayName: "The Farrington"},
        {StateID: 132, CountryID: 5, DisplayName: "The Quarter"},
        {StateID: 127, CountryID: 5, DisplayName: "The Valley"},
        {StateID: 130, CountryID: 5, DisplayName: "West End"},
        {StateID: 138, CountryID: 6, DisplayName: "Berat"},
        {StateID: 139, CountryID: 6, DisplayName: "Dibër"},
        {StateID: 144, CountryID: 6, DisplayName: "Durrës"},
        {StateID: 140, CountryID: 6, DisplayName: "Elbasan"},
        {StateID: 145, CountryID: 6, DisplayName: "Fier"},
        {StateID: 141, CountryID: 6, DisplayName: "Gjirokastër"},
        {StateID: 142, CountryID: 6, DisplayName: "Korçë"},
        {StateID: 143, CountryID: 6, DisplayName: "Kukës"},
        {StateID: 146, CountryID: 6, DisplayName: "Lezhë"},
        {StateID: 147, CountryID: 6, DisplayName: "Shkodër"},
        {StateID: 148, CountryID: 6, DisplayName: "Tirana"},
        {StateID: 149, CountryID: 6, DisplayName: "Vlorë"},
        {StateID: 154, CountryID: 7, DisplayName: "Aragatsotn"},
        {StateID: 150, CountryID: 7, DisplayName: "Ararat"},
        {StateID: 155, CountryID: 7, DisplayName: "Armavir"},
        {StateID: 156, CountryID: 7, DisplayName: "Gegharkunik"},
        {StateID: 157, CountryID: 7, DisplayName: "Kotayk"},
        {StateID: 158, CountryID: 7, DisplayName: "Lori"},
        {StateID: 159, CountryID: 7, DisplayName: "Shirak"},
        {StateID: 151, CountryID: 7, DisplayName: "Syunik"},
        {StateID: 160, CountryID: 7, DisplayName: "Tavush"},
        {StateID: 152, CountryID: 7, DisplayName: "Vayots Dzor"},
        {StateID: 153, CountryID: 7, DisplayName: "Yerevan"},
        {StateID: 171, CountryID: 8, DisplayName: "Bengo"},
        {StateID: 178, CountryID: 8, DisplayName: "Benguela"},
        {StateID: 177, CountryID: 8, DisplayName: "Bíe"},
        {StateID: 170, CountryID: 8, DisplayName: "Cabinda"},
        {StateID: 164, CountryID: 8, DisplayName: "Cuando Cobango"},
        {StateID: 169, CountryID: 8, DisplayName: "Cuanza Norte"},
        {StateID: 175, CountryID: 8, DisplayName: "Cunene"},
        {StateID: 174, CountryID: 8, DisplayName: "Huambo"},
        {StateID: 173, CountryID: 8, DisplayName: "Huíla"},
        {StateID: 176, CountryID: 8, DisplayName: "Kwanza Sul"},
        {StateID: 168, CountryID: 8, DisplayName: "Luanda"},
        {StateID: 162, CountryID: 8, DisplayName: "Luanda Norte"},
        {StateID: 161, CountryID: 8, DisplayName: "Lunda Sul"},
        {StateID: 167, CountryID: 8, DisplayName: "Malanje"},
        {StateID: 163, CountryID: 8, DisplayName: "Moxico"},
        {StateID: 172, CountryID: 8, DisplayName: "Namibe"},
        {StateID: 166, CountryID: 8, DisplayName: "Uíge"},
        {StateID: 165, CountryID: 8, DisplayName: "Zaire"},
        {StateID: 184, CountryID: 10, DisplayName: "Buenos Aires"},
        {StateID: 181, CountryID: 10, DisplayName: "Buenos Aires F.D."},
        {StateID: 202, CountryID: 10, DisplayName: "Catamarca"},
        {StateID: 201, CountryID: 10, DisplayName: "Chaco"},
        {StateID: 200, CountryID: 10, DisplayName: "Chubut"},
        {StateID: 199, CountryID: 10, DisplayName: "Cordoba"},
        {StateID: 183, CountryID: 10, DisplayName: "Corrientes"},
        {StateID: 182, CountryID: 10, DisplayName: "Entre Rios"},
        {StateID: 180, CountryID: 10, DisplayName: "Formosa"},
        {StateID: 198, CountryID: 10, DisplayName: "Jujuy"},
        {StateID: 197, CountryID: 10, DisplayName: "La Pampa"},
        {StateID: 196, CountryID: 10, DisplayName: "La Rioja"},
        {StateID: 195, CountryID: 10, DisplayName: "Mendoza"},
        {StateID: 179, CountryID: 10, DisplayName: "Misiones"},
        {StateID: 194, CountryID: 10, DisplayName: "Neuquen"},
        {StateID: 193, CountryID: 10, DisplayName: "Rio Negro"},
        {StateID: 192, CountryID: 10, DisplayName: "Salta"},
        {StateID: 191, CountryID: 10, DisplayName: "San Juan"},
        {StateID: 190, CountryID: 10, DisplayName: "San Luis"},
        {StateID: 189, CountryID: 10, DisplayName: "Santa Cruz"},
        {StateID: 188, CountryID: 10, DisplayName: "Santa Fe"},
        {StateID: 187, CountryID: 10, DisplayName: "Santiago del Estero"},
        {StateID: 186, CountryID: 10, DisplayName: "Tierra del Fuego"},
        {StateID: 185, CountryID: 10, DisplayName: "Tucuman"},
        {StateID: 205, CountryID: 11, DisplayName: "Eastern District"},
        {StateID: 206, CountryID: 11, DisplayName: "Manu'a"},
        {StateID: 207, CountryID: 11, DisplayName: "Rose Island"},
        {StateID: 204, CountryID: 11, DisplayName: "Swains Island"},
        {StateID: 203, CountryID: 11, DisplayName: "Western District"},
        {StateID: 216, CountryID: 12, DisplayName: "Burgenland"},
        {StateID: 215, CountryID: 12, DisplayName: "Carinthia"},
        {StateID: 214, CountryID: 12, DisplayName: "Lower Austria"},
        {StateID: 212, CountryID: 12, DisplayName: "Salzburg"},
        {StateID: 211, CountryID: 12, DisplayName: "Styria"},
        {StateID: 210, CountryID: 12, DisplayName: "Tyrol"},
        {StateID: 213, CountryID: 12, DisplayName: "Upper Austria"},
        {StateID: 208, CountryID: 12, DisplayName: "Vienna"},
        {StateID: 209, CountryID: 12, DisplayName: "Vorarlberg"},
        {StateID: 224, CountryID: 13, DisplayName: "Australian Capital Territory"},
        {StateID: 223, CountryID: 13, DisplayName: "New South Wales"},
        {StateID: 219, CountryID: 13, DisplayName: "Northern Territory"},
        {StateID: 222, CountryID: 13, DisplayName: "Queensland"},
        {StateID: 218, CountryID: 13, DisplayName: "South Australia"},
        {StateID: 221, CountryID: 13, DisplayName: "Tasmania"},
        {StateID: 220, CountryID: 13, DisplayName: "Victoria"},
        {StateID: 217, CountryID: 13, DisplayName: "Western Australia"},
        {StateID: 226, CountryID: 15, DisplayName: "Ålands landsbygd"},
        {StateID: 227, CountryID: 15, DisplayName: "Ålands skärgård"},
        {StateID: 225, CountryID: 15, DisplayName: "Mariehamns stad"},
        {StateID: 283, CountryID: 16, DisplayName: "Abseron"},
        {StateID: 249, CountryID: 16, DisplayName: "Agcab?di"},
        {StateID: 250, CountryID: 16, DisplayName: "Agdam"},
        {StateID: 285, CountryID: 16, DisplayName: "Agdas"},
        {StateID: 287, CountryID: 16, DisplayName: "Agstafa"},
        {StateID: 284, CountryID: 16, DisplayName: "Agsu"},
        {StateID: 247, CountryID: 16, DisplayName: "Astara"},
        {StateID: 282, CountryID: 16, DisplayName: "Baki"},
        {StateID: 280, CountryID: 16, DisplayName: "Balak?n"},
        {StateID: 281, CountryID: 16, DisplayName: "Barda"},
        {StateID: 228, CountryID: 16, DisplayName: "Beyl?qan"},
        {StateID: 235, CountryID: 16, DisplayName: "Bil?suvar"},
        {StateID: 279, CountryID: 16, DisplayName: "Dask?s?n"},
        {StateID: 244, CountryID: 16, DisplayName: "Füzuli"},
        {StateID: 286, CountryID: 16, DisplayName: "G?d?b?y"},
        {StateID: 288, CountryID: 16, DisplayName: "G?nc?"},
        {StateID: 274, CountryID: 16, DisplayName: "Goranboy"},
        {StateID: 277, CountryID: 16, DisplayName: "Göyçay"},
        {StateID: 270, CountryID: 16, DisplayName: "Goygol Rayon"},
        {StateID: 298, CountryID: 16, DisplayName: "Haciqabul"},
        {StateID: 243, CountryID: 16, DisplayName: "Imisli"},
        {StateID: 276, CountryID: 16, DisplayName: "Ismayilli"},
        {StateID: 245, CountryID: 16, DisplayName: "Jabrayil"},
        {StateID: 246, CountryID: 16, DisplayName: "Jalilabad"},
        {StateID: 272, CountryID: 16, DisplayName: "Kalbajar"},
        {StateID: 256, CountryID: 16, DisplayName: "Khojavend"},
        {StateID: 266, CountryID: 16, DisplayName: "Kürd?mir"},
        {StateID: 240, CountryID: 16, DisplayName: "L?nk?ran"},
        {StateID: 241, CountryID: 16, DisplayName: "Laçin"},
        {StateID: 252, CountryID: 16, DisplayName: "Lankaran Sahari"},
        {StateID: 239, CountryID: 16, DisplayName: "Lerik"},
        {StateID: 238, CountryID: 16, DisplayName: "Masally"},
        {StateID: 289, CountryID: 16, DisplayName: "Ming?cevir"},
        {StateID: 290, CountryID: 16, DisplayName: "Naftalan"},
        {StateID: 237, CountryID: 16, DisplayName: "Nakhichevan"},
        {StateID: 236, CountryID: 16, DisplayName: "Neftçala"},
        {StateID: 260, CountryID: 16, DisplayName: "Oguz"},
        {StateID: 267, CountryID: 16, DisplayName: "Q?b?l?"},
        {StateID: 275, CountryID: 16, DisplayName: "Qax"},
        {StateID: 273, CountryID: 16, DisplayName: "Qazax"},
        {StateID: 291, CountryID: 16, DisplayName: "Qobustan"},
        {StateID: 269, CountryID: 16, DisplayName: "Quba"},
        {StateID: 242, CountryID: 16, DisplayName: "Qubadli"},
        {StateID: 268, CountryID: 16, DisplayName: "Qusar"},
        {StateID: 265, CountryID: 16, DisplayName: "S?mkir"},
        {StateID: 234, CountryID: 16, DisplayName: "Saatli"},
        {StateID: 233, CountryID: 16, DisplayName: "Sabirabad"},
        {StateID: 232, CountryID: 16, DisplayName: "Salyan"},
        {StateID: 263, CountryID: 16, DisplayName: "Samaxi"},
        {StateID: 292, CountryID: 16, DisplayName: "Samux"},
        {StateID: 278, CountryID: 16, DisplayName: "Shabran"},
        {StateID: 264, CountryID: 16, DisplayName: "Shaki"},
        {StateID: 293, CountryID: 16, DisplayName: "Shaki City"},
        {StateID: 251, CountryID: 16, DisplayName: "Shirvan"},
        {StateID: 231, CountryID: 16, DisplayName: "Shusha"},
        {StateID: 253, CountryID: 16, DisplayName: "Shusha City"},
        {StateID: 294, CountryID: 16, DisplayName: "Siy?z?n"},
        {StateID: 295, CountryID: 16, DisplayName: "Sumqayit"},
        {StateID: 254, CountryID: 16, DisplayName: "T?rt?r"},
        {StateID: 262, CountryID: 16, DisplayName: "Tovuz"},
        {StateID: 261, CountryID: 16, DisplayName: "Ucar"},
        {StateID: 271, CountryID: 16, DisplayName: "Xaçmaz"},
        {StateID: 255, CountryID: 16, DisplayName: "Xank?ndi"},
        {StateID: 296, CountryID: 16, DisplayName: "Xizi"},
        {StateID: 248, CountryID: 16, DisplayName: "Xocali"},
        {StateID: 230, CountryID: 16, DisplayName: "Yardimli"},
        {StateID: 259, CountryID: 16, DisplayName: "Yevlax"},
        {StateID: 297, CountryID: 16, DisplayName: "Yevlax City"},
        {StateID: 229, CountryID: 16, DisplayName: "Z?ngilan"},
        {StateID: 257, CountryID: 16, DisplayName: "Z?rdab"},
        {StateID: 258, CountryID: 16, DisplayName: "Zaqatala"},
        {StateID: 301, CountryID: 17, DisplayName: "Brcko"},
        {StateID: 299, CountryID: 17, DisplayName: "Federation of B&H"},
        {StateID: 300, CountryID: 17, DisplayName: "Srpska"},
        {StateID: 312, CountryID: 18, DisplayName: "Christ Church"},
        {StateID: 311, CountryID: 18, DisplayName: "Saint Andrew"},
        {StateID: 310, CountryID: 18, DisplayName: "Saint George"},
        {StateID: 309, CountryID: 18, DisplayName: "Saint James"},
        {StateID: 308, CountryID: 18, DisplayName: "Saint John"},
        {StateID: 307, CountryID: 18, DisplayName: "Saint Joseph"},
        {StateID: 306, CountryID: 18, DisplayName: "Saint Lucy"},
        {StateID: 305, CountryID: 18, DisplayName: "Saint Michael"},
        {StateID: 304, CountryID: 18, DisplayName: "Saint Peter"},
        {StateID: 303, CountryID: 18, DisplayName: "Saint Philip"},
        {StateID: 302, CountryID: 18, DisplayName: "Saint Thomas"},
        {StateID: 317, CountryID: 19, DisplayName: "Barisal"},
        {StateID: 315, CountryID: 19, DisplayName: "Chittagong"},
        {StateID: 314, CountryID: 19, DisplayName: "Dhaka"},
        {StateID: 316, CountryID: 19, DisplayName: "Khulna"},
        {StateID: 320, CountryID: 19, DisplayName: "Mymensingh Division"},
        {StateID: 313, CountryID: 19, DisplayName: "Rajshahi Division"},
        {StateID: 319, CountryID: 19, DisplayName: "Rangpur Division"},
        {StateID: 318, CountryID: 19, DisplayName: "Sylhet"},
        {StateID: 321, CountryID: 20, DisplayName: "Brussels Capital"},
        {StateID: 323, CountryID: 20, DisplayName: "Flanders"},
        {StateID: 322, CountryID: 20, DisplayName: "Wallonia"},
        {StateID: 324, CountryID: 21, DisplayName: "Boucle du Mouhoun"},
        {StateID: 325, CountryID: 21, DisplayName: "Cascades"},
        {StateID: 326, CountryID: 21, DisplayName: "Centre"},
        {StateID: 327, CountryID: 21, DisplayName: "Centre-Est"},
        {StateID: 328, CountryID: 21, DisplayName: "Centre-Nord"},
        {StateID: 329, CountryID: 21, DisplayName: "Centre-Ouest"},
        {StateID: 330, CountryID: 21, DisplayName: "Centre-Sud"},
        {StateID: 331, CountryID: 21, DisplayName: "Est"},
        {StateID: 332, CountryID: 21, DisplayName: "Hauts-Bassins"},
        {StateID: 333, CountryID: 21, DisplayName: "Nord"},
        {StateID: 334, CountryID: 21, DisplayName: "Plateau-Central"},
        {StateID: 335, CountryID: 21, DisplayName: "Sahel"},
        {StateID: 336, CountryID: 21, DisplayName: "Sud-Ouest"},
        {StateID: 352, CountryID: 22, DisplayName: "Blagoevgrad"},
        {StateID: 351, CountryID: 22, DisplayName: "Burgas"},
        {StateID: 341, CountryID: 22, DisplayName: "Dobrich"},
        {StateID: 353, CountryID: 22, DisplayName: "Gabrovo"},
        {StateID: 349, CountryID: 22, DisplayName: "Haskovo"},
        {StateID: 354, CountryID: 22, DisplayName: "Kardzhali"},
        {StateID: 355, CountryID: 22, DisplayName: "Kyustendil"},
        {StateID: 348, CountryID: 22, DisplayName: "Lovech"},
        {StateID: 338, CountryID: 22, DisplayName: "Montana"},
        {StateID: 347, CountryID: 22, DisplayName: "Pazardzhik"},
        {StateID: 346, CountryID: 22, DisplayName: "Pernik"},
        {StateID: 345, CountryID: 22, DisplayName: "Pleven"},
        {StateID: 344, CountryID: 22, DisplayName: "Plovdiv"},
        {StateID: 337, CountryID: 22, DisplayName: "Razgrad"},
        {StateID: 343, CountryID: 22, DisplayName: "Ruse"},
        {StateID: 356, CountryID: 22, DisplayName: "Shumen"},
        {StateID: 357, CountryID: 22, DisplayName: "Silistra"},
        {StateID: 358, CountryID: 22, DisplayName: "Sliven"},
        {StateID: 359, CountryID: 22, DisplayName: "Smolyan"},
        {StateID: 342, CountryID: 22, DisplayName: "Sofia"},
        {StateID: 350, CountryID: 22, DisplayName: "Sofia-Capital"},
        {StateID: 360, CountryID: 22, DisplayName: "Stara Zagora"},
        {StateID: 361, CountryID: 22, DisplayName: "Targovishte"},
        {StateID: 340, CountryID: 22, DisplayName: "Varna"},
        {StateID: 362, CountryID: 22, DisplayName: "Veliko Tarnovo"},
        {StateID: 363, CountryID: 22, DisplayName: "Vidin"},
        {StateID: 339, CountryID: 22, DisplayName: "Vratsa"},
        {StateID: 364, CountryID: 22, DisplayName: "Yambol"},
        {StateID: 366, CountryID: 23, DisplayName: "Manama"},
        {StateID: 365, CountryID: 23, DisplayName: "Muharraq"},
        {StateID: 368, CountryID: 23, DisplayName: "Northern"},
        {StateID: 367, CountryID: 23, DisplayName: "Southern Governorate"},
        {StateID: 376, CountryID: 24, DisplayName: "Bubanza"},
        {StateID: 384, CountryID: 24, DisplayName: "Bujumbura Mairie"},
        {StateID: 385, CountryID: 24, DisplayName: "Bujumbura Rural"},
        {StateID: 370, CountryID: 24, DisplayName: "Bururi"},
        {StateID: 374, CountryID: 24, DisplayName: "Cankuzo"},
        {StateID: 377, CountryID: 24, DisplayName: "Cibitoke"},
        {StateID: 372, CountryID: 24, DisplayName: "Gitega"},
        {StateID: 375, CountryID: 24, DisplayName: "Karuzi"},
        {StateID: 379, CountryID: 24, DisplayName: "Kayanza"},
        {StateID: 381, CountryID: 24, DisplayName: "Kirundo"},
        {StateID: 369, CountryID: 24, DisplayName: "Makamba"},
        {StateID: 371, CountryID: 24, DisplayName: "Muramvya"},
        {StateID: 380, CountryID: 24, DisplayName: "Muyinga"},
        {StateID: 383, CountryID: 24, DisplayName: "Mwaro"},
        {StateID: 378, CountryID: 24, DisplayName: "Ngozi"},
        {StateID: 386, CountryID: 24, DisplayName: "Rumonge"},
        {StateID: 382, CountryID: 24, DisplayName: "Rutana"},
        {StateID: 373, CountryID: 24, DisplayName: "Ruyigi"},
        {StateID: 393, CountryID: 25, DisplayName: "Alibori"},
        {StateID: 392, CountryID: 25, DisplayName: "Atakora"},
        {StateID: 391, CountryID: 25, DisplayName: "Atlantique"},
        {StateID: 390, CountryID: 25, DisplayName: "Borgou"},
        {StateID: 394, CountryID: 25, DisplayName: "Collines"},
        {StateID: 396, CountryID: 25, DisplayName: "Donga"},
        {StateID: 395, CountryID: 25, DisplayName: "Kouffo"},
        {StateID: 397, CountryID: 25, DisplayName: "Littoral"},
        {StateID: 389, CountryID: 25, DisplayName: "Mono"},
        {StateID: 388, CountryID: 25, DisplayName: "Ouémé"},
        {StateID: 398, CountryID: 25, DisplayName: "Plateau"},
        {StateID: 387, CountryID: 25, DisplayName: "Zou"},
        {StateID: 409, CountryID: 27, DisplayName: "Devonshire"},
        {StateID: 407, CountryID: 27, DisplayName: "Hamilton"},
        {StateID: 408, CountryID: 27, DisplayName: "Hamilton city"},
        {StateID: 406, CountryID: 27, DisplayName: "Paget"},
        {StateID: 405, CountryID: 27, DisplayName: "Pembroke"},
        {StateID: 404, CountryID: 27, DisplayName: "Saint George"},
        {StateID: 403, CountryID: 27, DisplayName: "Saint George's"},
        {StateID: 402, CountryID: 27, DisplayName: "Sandys"},
        {StateID: 401, CountryID: 27, DisplayName: "Smith's"},
        {StateID: 400, CountryID: 27, DisplayName: "Southampton"},
        {StateID: 399, CountryID: 27, DisplayName: "Warwick"},
        {StateID: 413, CountryID: 28, DisplayName: "Belait"},
        {StateID: 412, CountryID: 28, DisplayName: "Brunei-Muara District"},
        {StateID: 411, CountryID: 28, DisplayName: "Temburong"},
        {StateID: 410, CountryID: 28, DisplayName: "Tutong"},
        {StateID: 421, CountryID: 29, DisplayName: "Chuquisaca"},
        {StateID: 420, CountryID: 29, DisplayName: "Cochabamba"},
        {StateID: 422, CountryID: 29, DisplayName: "El Beni"},
        {StateID: 419, CountryID: 29, DisplayName: "La Paz"},
        {StateID: 418, CountryID: 29, DisplayName: "Oruro"},
        {StateID: 417, CountryID: 29, DisplayName: "Pando"},
        {StateID: 416, CountryID: 29, DisplayName: "Potosí"},
        {StateID: 415, CountryID: 29, DisplayName: "Santa Cruz"},
        {StateID: 414, CountryID: 29, DisplayName: "Tarija"},
        {StateID: 423, CountryID: 30, DisplayName: "Bonaire"},
        {StateID: 424, CountryID: 30, DisplayName: "Saba"},
        {StateID: 425, CountryID: 30, DisplayName: "Sint Eustatius"},
        {StateID: 451, CountryID: 31, DisplayName: "Acre"},
        {StateID: 434, CountryID: 31, DisplayName: "Alagoas"},
        {StateID: 433, CountryID: 31, DisplayName: "Amapá"},
        {StateID: 450, CountryID: 31, DisplayName: "Amazonas"},
        {StateID: 447, CountryID: 31, DisplayName: "Bahia"},
        {StateID: 432, CountryID: 31, DisplayName: "Ceará"},
        {StateID: 446, CountryID: 31, DisplayName: "Espírito Santo"},
        {StateID: 445, CountryID: 31, DisplayName: "Federal District"},
        {StateID: 444, CountryID: 31, DisplayName: "Goiás"},
        {StateID: 431, CountryID: 31, DisplayName: "Maranhão"},
        {StateID: 443, CountryID: 31, DisplayName: "Mato Grosso"},
        {StateID: 442, CountryID: 31, DisplayName: "Mato Grosso do Sul"},
        {StateID: 441, CountryID: 31, DisplayName: "Minas Gerais"},
        {StateID: 430, CountryID: 31, DisplayName: "Pará"},
        {StateID: 429, CountryID: 31, DisplayName: "Paraíba"},
        {StateID: 440, CountryID: 31, DisplayName: "Paraná"},
        {StateID: 428, CountryID: 31, DisplayName: "Pernambuco"},
        {StateID: 427, CountryID: 31, DisplayName: "Piauí"},
        {StateID: 439, CountryID: 31, DisplayName: "Rio de Janeiro"},
        {StateID: 426, CountryID: 31, DisplayName: "Rio Grande do Norte"},
        {StateID: 438, CountryID: 31, DisplayName: "Rio Grande do Sul"},
        {StateID: 452, CountryID: 31, DisplayName: "Rondônia"},
        {StateID: 449, CountryID: 31, DisplayName: "Roraima"},
        {StateID: 437, CountryID: 31, DisplayName: "Santa Catarina"},
        {StateID: 436, CountryID: 31, DisplayName: "São Paulo"},
        {StateID: 435, CountryID: 31, DisplayName: "Sergipe"},
        {StateID: 448, CountryID: 31, DisplayName: "Tocantins"},
        {StateID: 465, CountryID: 32, DisplayName: "Acklins"},
        {StateID: 455, CountryID: 32, DisplayName: "Berry Islands"},
        {StateID: 464, CountryID: 32, DisplayName: "Bimini"},
        {StateID: 466, CountryID: 32, DisplayName: "Black Point"},
        {StateID: 463, CountryID: 32, DisplayName: "Cat Island"},
        {StateID: 467, CountryID: 32, DisplayName: "Central Abaco"},
        {StateID: 468, CountryID: 32, DisplayName: "Central Andros"},
        {StateID: 469, CountryID: 32, DisplayName: "Central Eleuthera"},
        {StateID: 470, CountryID: 32, DisplayName: "Crooked Island and Long Cay"},
        {StateID: 471, CountryID: 32, DisplayName: "East Grand Bahama"},
        {StateID: 462, CountryID: 32, DisplayName: "Exuma"},
        {StateID: 461, CountryID: 32, DisplayName: "Freeport"},
        {StateID: 472, CountryID: 32, DisplayName: "Grand Cay"},
        {StateID: 460, CountryID: 32, DisplayName: "Harbour Island"},
        {StateID: 473, CountryID: 32, DisplayName: "Hope Town"},
        {StateID: 459, CountryID: 32, DisplayName: "Inagua"},
        {StateID: 458, CountryID: 32, DisplayName: "Long Island"},
        {StateID: 474, CountryID: 32, DisplayName: "Mangrove Cay"},
        {StateID: 457, CountryID: 32, DisplayName: "Mayaguana"},
        {StateID: 475, CountryID: 32, DisplayName: "Moore’s Island"},
        {StateID: 456, CountryID: 32, DisplayName: "New Providence"},
        {StateID: 476, CountryID: 32, DisplayName: "North Abaco"},
        {StateID: 477, CountryID: 32, DisplayName: "North Andros"},
        {StateID: 478, CountryID: 32, DisplayName: "North Eleuthera"},
        {StateID: 454, CountryID: 32, DisplayName: "Ragged Island"},
        {StateID: 479, CountryID: 32, DisplayName: "Rum Cay"},
        {StateID: 453, CountryID: 32, DisplayName: "San Salvador"},
        {StateID: 480, CountryID: 32, DisplayName: "South Abaco"},
        {StateID: 481, CountryID: 32, DisplayName: "South Andros"},
        {StateID: 482, CountryID: 32, DisplayName: "South Eleuthera"},
        {StateID: 483, CountryID: 32, DisplayName: "Spanish Wells"},
        {StateID: 484, CountryID: 32, DisplayName: "West Grand Bahama"},
        {StateID: 485, CountryID: 33, DisplayName: "Bumthang"},
        {StateID: 488, CountryID: 33, DisplayName: "Chirang"},
        {StateID: 486, CountryID: 33, DisplayName: "Chukha"},
        {StateID: 487, CountryID: 33, DisplayName: "Dagana"},
        {StateID: 503, CountryID: 33, DisplayName: "Gasa"},
        {StateID: 489, CountryID: 33, DisplayName: "Geylegphug"},
        {StateID: 490, CountryID: 33, DisplayName: "Haa"},
        {StateID: 491, CountryID: 33, DisplayName: "Lhuntse"},
        {StateID: 492, CountryID: 33, DisplayName: "Mongar"},
        {StateID: 493, CountryID: 33, DisplayName: "Paro"},
        {StateID: 494, CountryID: 33, DisplayName: "Pemagatshel"},
        {StateID: 495, CountryID: 33, DisplayName: "Punakha"},
        {StateID: 496, CountryID: 33, DisplayName: "Samchi"},
        {StateID: 497, CountryID: 33, DisplayName: "Samdrup Jongkhar"},
        {StateID: 498, CountryID: 33, DisplayName: "Shemgang"},
        {StateID: 499, CountryID: 33, DisplayName: "Tashigang"},
        {StateID: 500, CountryID: 33, DisplayName: "Thimphu"},
        {StateID: 501, CountryID: 33, DisplayName: "Tongsa"},
        {StateID: 504, CountryID: 33, DisplayName: "Trashi Yangste"},
        {StateID: 502, CountryID: 33, DisplayName: "Wangdi Phodrang"},
        {StateID: 514, CountryID: 35, DisplayName: "Central"},
        {StateID: 513, CountryID: 35, DisplayName: "Chobe"},
        {StateID: 515, CountryID: 35, DisplayName: "City of Francistown"},
        {StateID: 516, CountryID: 35, DisplayName: "Gaborone"},
        {StateID: 512, CountryID: 35, DisplayName: "Ghanzi"},
        {StateID: 517, CountryID: 35, DisplayName: "Jwaneng"},
        {StateID: 511, CountryID: 35, DisplayName: "Kgalagadi"},
        {StateID: 510, CountryID: 35, DisplayName: "Kgatleng"},
        {StateID: 509, CountryID: 35, DisplayName: "Kweneng"},
        {StateID: 518, CountryID: 35, DisplayName: "Lobatse"},
        {StateID: 505, CountryID: 35, DisplayName: "Ngwaketsi"},
        {StateID: 507, CountryID: 35, DisplayName: "North-East"},
        {StateID: 508, CountryID: 35, DisplayName: "North-West"},
        {StateID: 519, CountryID: 35, DisplayName: "Selibe Phikwe"},
        {StateID: 506, CountryID: 35, DisplayName: "South-East"},
        {StateID: 520, CountryID: 35, DisplayName: "Sowa Town"},
        {StateID: 527, CountryID: 36, DisplayName: "Brest"},
        {StateID: 526, CountryID: 36, DisplayName: "Gomel Oblast"},
        {StateID: 525, CountryID: 36, DisplayName: "Grodnenskaya"},
        {StateID: 523, CountryID: 36, DisplayName: "Minsk"},
        {StateID: 524, CountryID: 36, DisplayName: "Minsk City"},
        {StateID: 522, CountryID: 36, DisplayName: "Mogilev"},
        {StateID: 521, CountryID: 36, DisplayName: "Vitebsk"},
        {StateID: 533, CountryID: 37, DisplayName: "Belize"},
        {StateID: 532, CountryID: 37, DisplayName: "Cayo"},
        {StateID: 531, CountryID: 37, DisplayName: "Corozal"},
        {StateID: 530, CountryID: 37, DisplayName: "Orange Walk"},
        {StateID: 529, CountryID: 37, DisplayName: "Southern District"},
        {StateID: 528, CountryID: 37, DisplayName: "Toledo"},
        {StateID: 1, CountryID: 38, DisplayName: "Alberta"},
        {StateID: 2, CountryID: 38, DisplayName: "British Columbia"},
        {StateID: 3, CountryID: 38, DisplayName: "Manitoba"},
        {StateID: 4, CountryID: 38, DisplayName: "New Brunswick"},
        {StateID: 5, CountryID: 38, DisplayName: "Newfoundland and Labrador"},
        {StateID: 6, CountryID: 38, DisplayName: "Northwest Territories"},
        {StateID: 7, CountryID: 38, DisplayName: "Nova Scotia"},
        {StateID: 8, CountryID: 38, DisplayName: "Nunavut"},
        {StateID: 9, CountryID: 38, DisplayName: "Ontario"},
        {StateID: 10, CountryID: 38, DisplayName: "Prince Edward Island"},
        {StateID: 11, CountryID: 38, DisplayName: "Quebec"},
        {StateID: 12, CountryID: 38, DisplayName: "Saskatchewan"},
        {StateID: 13, CountryID: 38, DisplayName: "Yukon"},
        {StateID: 555, CountryID: 40, DisplayName: "Bas-Congo"},
        {StateID: 549, CountryID: 40, DisplayName: "Bas-Uele"},
        {StateID: 548, CountryID: 40, DisplayName: "Équateur"},
        {StateID: 556, CountryID: 40, DisplayName: "Haut-Katanga"},
        {StateID: 547, CountryID: 40, DisplayName: "Haut-Lomami"},
        {StateID: 546, CountryID: 40, DisplayName: "Haut-Uele"},
        {StateID: 545, CountryID: 40, DisplayName: "Ituri"},
        {StateID: 544, CountryID: 40, DisplayName: "Kasai"},
        {StateID: 542, CountryID: 40, DisplayName: "Kasai-Central"},
        {StateID: 543, CountryID: 40, DisplayName: "Kasaï-Oriental"},
        {StateID: 554, CountryID: 40, DisplayName: "Kinshasa"},
        {StateID: 553, CountryID: 40, DisplayName: "Kwango"},
        {StateID: 552, CountryID: 40, DisplayName: "Kwilu"},
        {StateID: 557, CountryID: 40, DisplayName: "Lomami"},
        {StateID: 550, CountryID: 40, DisplayName: "Lualaba"},
        {StateID: 551, CountryID: 40, DisplayName: "Mai-Ndombe"},
        {StateID: 541, CountryID: 40, DisplayName: "Maniema"},
        {StateID: 540, CountryID: 40, DisplayName: "Mongala"},
        {StateID: 539, CountryID: 40, DisplayName: "Nord Kivu"},
        {StateID: 558, CountryID: 40, DisplayName: "Nord-Ubangi"},
        {StateID: 538, CountryID: 40, DisplayName: "Sankuru"},
        {StateID: 537, CountryID: 40, DisplayName: "South Kivu"},
        {StateID: 559, CountryID: 40, DisplayName: "Sud-Ubangi"},
        {StateID: 536, CountryID: 40, DisplayName: "Tanganyika"},
        {StateID: 535, CountryID: 40, DisplayName: "Tshopo"},
        {StateID: 534, CountryID: 40, DisplayName: "Tshuapa"},
        {StateID: 566, CountryID: 41, DisplayName: "Bamingui-Bangoran"},
        {StateID: 576, CountryID: 41, DisplayName: "Bangui"},
        {StateID: 565, CountryID: 41, DisplayName: "Basse-Kotto"},
        {StateID: 563, CountryID: 41, DisplayName: "Haut-Mbomou"},
        {StateID: 564, CountryID: 41, DisplayName: "Haute-Kotto"},
        {StateID: 573, CountryID: 41, DisplayName: "Kémo"},
        {StateID: 572, CountryID: 41, DisplayName: "Lobaye"},
        {StateID: 574, CountryID: 41, DisplayName: "Mambéré-Kadéï"},
        {StateID: 562, CountryID: 41, DisplayName: "Mbomou"},
        {StateID: 575, CountryID: 41, DisplayName: "Nana-Grébizi"},
        {StateID: 571, CountryID: 41, DisplayName: "Nana-Mambéré"},
        {StateID: 570, CountryID: 41, DisplayName: "Ombella-M'Poko"},
        {StateID: 561, CountryID: 41, DisplayName: "Ouaka"},
        {StateID: 569, CountryID: 41, DisplayName: "Ouham"},
        {StateID: 568, CountryID: 41, DisplayName: "Ouham-Pendé"},
        {StateID: 567, CountryID: 41, DisplayName: "Sangha-Mbaéré"},
        {StateID: 560, CountryID: 41, DisplayName: "Vakaga"},
        {StateID: 585, CountryID: 42, DisplayName: "Bouenza"},
        {StateID: 586, CountryID: 42, DisplayName: "Brazzaville"},
        {StateID: 584, CountryID: 42, DisplayName: "Cuvette"},
        {StateID: 587, CountryID: 42, DisplayName: "Cuvette-Ouest"},
        {StateID: 583, CountryID: 42, DisplayName: "Kouilou"},
        {StateID: 582, CountryID: 42, DisplayName: "Lékoumou"},
        {StateID: 581, CountryID: 42, DisplayName: "Likouala"},
        {StateID: 580, CountryID: 42, DisplayName: "Niari"},
        {StateID: 579, CountryID: 42, DisplayName: "Plateaux"},
        {StateID: 588, CountryID: 42, DisplayName: "Pointe-Noire"},
        {StateID: 578, CountryID: 42, DisplayName: "Pool"},
        {StateID: 577, CountryID: 42, DisplayName: "Sangha"},
        {StateID: 614, CountryID: 43, DisplayName: "Aargau"},
        {StateID: 612, CountryID: 43, DisplayName: "Appenzell Ausserrhoden"},
        {StateID: 613, CountryID: 43, DisplayName: "Appenzell Innerrhoden"},
        {StateID: 610, CountryID: 43, DisplayName: "Basel-City"},
        {StateID: 611, CountryID: 43, DisplayName: "Basel-Landschaft"},
        {StateID: 609, CountryID: 43, DisplayName: "Bern"},
        {StateID: 608, CountryID: 43, DisplayName: "Fribourg"},
        {StateID: 607, CountryID: 43, DisplayName: "Geneva"},
        {StateID: 606, CountryID: 43, DisplayName: "Glarus"},
        {StateID: 605, CountryID: 43, DisplayName: "Grisons"},
        {StateID: 604, CountryID: 43, DisplayName: "Jura"},
        {StateID: 603, CountryID: 43, DisplayName: "Lucerne"},
        {StateID: 602, CountryID: 43, DisplayName: "Neuchâtel"},
        {StateID: 601, CountryID: 43, DisplayName: "Nidwalden"},
        {StateID: 600, CountryID: 43, DisplayName: "Obwalden"},
        {StateID: 599, CountryID: 43, DisplayName: "Saint Gallen"},
        {StateID: 598, CountryID: 43, DisplayName: "Schaffhausen"},
        {StateID: 597, CountryID: 43, DisplayName: "Schwyz"},
        {StateID: 596, CountryID: 43, DisplayName: "Solothurn"},
        {StateID: 595, CountryID: 43, DisplayName: "Thurgau"},
        {StateID: 594, CountryID: 43, DisplayName: "Ticino"},
        {StateID: 593, CountryID: 43, DisplayName: "Uri"},
        {StateID: 592, CountryID: 43, DisplayName: "Valais"},
        {StateID: 591, CountryID: 43, DisplayName: "Vaud"},
        {StateID: 590, CountryID: 43, DisplayName: "Zug"},
        {StateID: 589, CountryID: 43, DisplayName: "Zurich"},
        {StateID: 628, CountryID: 44, DisplayName: "Abidjan"},
        {StateID: 616, CountryID: 44, DisplayName: "Bas-Sassandra"},
        {StateID: 617, CountryID: 44, DisplayName: "Comoé"},
        {StateID: 618, CountryID: 44, DisplayName: "Denguélé"},
        {StateID: 619, CountryID: 44, DisplayName: "Gôh-Djiboua"},
        {StateID: 620, CountryID: 44, DisplayName: "Lacs"},
        {StateID: 621, CountryID: 44, DisplayName: "Lagunes"},
        {StateID: 622, CountryID: 44, DisplayName: "Montagnes"},
        {StateID: 623, CountryID: 44, DisplayName: "Sassandra-Marahoué"},
        {StateID: 624, CountryID: 44, DisplayName: "Savanes"},
        {StateID: 625, CountryID: 44, DisplayName: "Vallée du Bandama"},
        {StateID: 626, CountryID: 44, DisplayName: "Woroba"},
        {StateID: 615, CountryID: 44, DisplayName: "Yamoussoukro"},
        {StateID: 627, CountryID: 44, DisplayName: "Zanzan"},
        {StateID: 629, CountryID: 45, DisplayName: "Aitutaki"},
        {StateID: 630, CountryID: 45, DisplayName: "Atiu"},
        {StateID: 633, CountryID: 45, DisplayName: "Ma'uke"},
        {StateID: 631, CountryID: 45, DisplayName: "Mangaia"},
        {StateID: 632, CountryID: 45, DisplayName: "Manihiki"},
        {StateID: 634, CountryID: 45, DisplayName: "Mitiaro"},
        {StateID: 635, CountryID: 45, DisplayName: "Palmerston"},
        {StateID: 636, CountryID: 45, DisplayName: "Penrhyn"},
        {StateID: 637, CountryID: 45, DisplayName: "Pukapuka"},
        {StateID: 638, CountryID: 45, DisplayName: "Rakahanga"},
        {StateID: 639, CountryID: 45, DisplayName: "Rarotonga"},
        {StateID: 650, CountryID: 46, DisplayName: "Antofagasta"},
        {StateID: 649, CountryID: 46, DisplayName: "Araucanía"},
        {StateID: 653, CountryID: 46, DisplayName: "Arica y Parinacota"},
        {StateID: 648, CountryID: 46, DisplayName: "Atacama"},
        {StateID: 651, CountryID: 46, DisplayName: "Aysén"},
        {StateID: 647, CountryID: 46, DisplayName: "Biobío"},
        {StateID: 646, CountryID: 46, DisplayName: "Coquimbo Region"},
        {StateID: 644, CountryID: 46, DisplayName: "Los Lagos Region"},
        {StateID: 654, CountryID: 46, DisplayName: "Los Ríos Region"},
        {StateID: 643, CountryID: 46, DisplayName: "Maule Region"},
        {StateID: 655, CountryID: 46, DisplayName: "Ñuble"},
        {StateID: 645, CountryID: 46, DisplayName: "O'Higgins Region"},
        {StateID: 652, CountryID: 46, DisplayName: "Region of Magallanes"},
        {StateID: 642, CountryID: 46, DisplayName: "Santiago Metropolitan"},
        {StateID: 641, CountryID: 46, DisplayName: "Tarapacá"},
        {StateID: 640, CountryID: 46, DisplayName: "Valparaíso"},
        {StateID: 665, CountryID: 47, DisplayName: "Adamaoua"},
        {StateID: 664, CountryID: 47, DisplayName: "Centre"},
        {StateID: 663, CountryID: 47, DisplayName: "East"},
        {StateID: 662, CountryID: 47, DisplayName: "Far North"},
        {StateID: 661, CountryID: 47, DisplayName: "Littoral"},
        {StateID: 660, CountryID: 47, DisplayName: "North"},
        {StateID: 659, CountryID: 47, DisplayName: "North-West"},
        {StateID: 657, CountryID: 47, DisplayName: "South"},
        {StateID: 656, CountryID: 47, DisplayName: "South-West"},
        {StateID: 658, CountryID: 47, DisplayName: "West"},
        {StateID: 691, CountryID: 48, DisplayName: "Anhui"},
        {StateID: 696, CountryID: 48, DisplayName: "Beijing"},
        {StateID: 690, CountryID: 48, DisplayName: "Chongqing"},
        {StateID: 689, CountryID: 48, DisplayName: "Fujian"},
        {StateID: 688, CountryID: 48, DisplayName: "Gansu"},
        {StateID: 687, CountryID: 48, DisplayName: "Guangdong"},
        {StateID: 686, CountryID: 48, DisplayName: "Guangxi"},
        {StateID: 685, CountryID: 48, DisplayName: "Guizhou"},
        {StateID: 684, CountryID: 48, DisplayName: "Hainan"},
        {StateID: 683, CountryID: 48, DisplayName: "Hebei"},
        {StateID: 695, CountryID: 48, DisplayName: "Heilongjiang"},
        {StateID: 682, CountryID: 48, DisplayName: "Henan"},
        {StateID: 681, CountryID: 48, DisplayName: "Hubei"},
        {StateID: 680, CountryID: 48, DisplayName: "Hunan"},
        {StateID: 692, CountryID: 48, DisplayName: "Inner Mongolia"},
        {StateID: 679, CountryID: 48, DisplayName: "Jiangsu"},
        {StateID: 678, CountryID: 48, DisplayName: "Jiangxi"},
        {StateID: 694, CountryID: 48, DisplayName: "Jilin"},
        {StateID: 693, CountryID: 48, DisplayName: "Liaoning"},
        {StateID: 677, CountryID: 48, DisplayName: "Ningxia Hui Autonomous Region"},
        {StateID: 667, CountryID: 48, DisplayName: "Qinghai"},
        {StateID: 676, CountryID: 48, DisplayName: "Shaanxi"},
        {StateID: 675, CountryID: 48, DisplayName: "Shandong"},
        {StateID: 674, CountryID: 48, DisplayName: "Shanghai"},
        {StateID: 673, CountryID: 48, DisplayName: "Shanxi"},
        {StateID: 672, CountryID: 48, DisplayName: "Sichuan"},
        {StateID: 671, CountryID: 48, DisplayName: "Tianjin"},
        {StateID: 666, CountryID: 48, DisplayName: "Tibet"},
        {StateID: 668, CountryID: 48, DisplayName: "Xinjiang"},
        {StateID: 670, CountryID: 48, DisplayName: "Yunnan"},
        {StateID: 669, CountryID: 48, DisplayName: "Zhejiang"},
        {StateID: 729, CountryID: 49, DisplayName: "Amazonas"},
        {StateID: 728, CountryID: 49, DisplayName: "Antioquia"},
        {StateID: 726, CountryID: 49, DisplayName: "Atlántico"},
        {StateID: 725, CountryID: 49, DisplayName: "Bogota D.C."},
        {StateID: 724, CountryID: 49, DisplayName: "Bolívar"},
        {StateID: 723, CountryID: 49, DisplayName: "Boyacá"},
        {StateID: 722, CountryID: 49, DisplayName: "Caldas"},
        {StateID: 721, CountryID: 49, DisplayName: "Caquetá"},
        {StateID: 720, CountryID: 49, DisplayName: "Casanare"},
        {StateID: 719, CountryID: 49, DisplayName: "Cauca"},
        {StateID: 718, CountryID: 49, DisplayName: "Cesar"},
        {StateID: 717, CountryID: 49, DisplayName: "Chocó"},
        {StateID: 716, CountryID: 49, DisplayName: "Córdoba"},
        {StateID: 715, CountryID: 49, DisplayName: "Cundinamarca"},
        {StateID: 727, CountryID: 49, DisplayName: "Departamento de Arauca"},
        {StateID: 714, CountryID: 49, DisplayName: "Guainía"},
        {StateID: 713, CountryID: 49, DisplayName: "Guaviare"},
        {StateID: 712, CountryID: 49, DisplayName: "Huila"},
        {StateID: 711, CountryID: 49, DisplayName: "La Guajira"},
        {StateID: 710, CountryID: 49, DisplayName: "Magdalena"},
        {StateID: 709, CountryID: 49, DisplayName: "Meta"},
        {StateID: 708, CountryID: 49, DisplayName: "Nariño"},
        {StateID: 707, CountryID: 49, DisplayName: "Norte de Santander"},
        {StateID: 706, CountryID: 49, DisplayName: "Putumayo"},
        {StateID: 705, CountryID: 49, DisplayName: "Quindío"},
        {StateID: 704, CountryID: 49, DisplayName: "Risaralda"},
        {StateID: 703, CountryID: 49, DisplayName: "San Andres y Providencia"},
        {StateID: 702, CountryID: 49, DisplayName: "Santander"},
        {StateID: 701, CountryID: 49, DisplayName: "Sucre"},
        {StateID: 700, CountryID: 49, DisplayName: "Tolima"},
        {StateID: 699, CountryID: 49, DisplayName: "Valle del Cauca"},
        {StateID: 698, CountryID: 49, DisplayName: "Vaupés"},
        {StateID: 697, CountryID: 49, DisplayName: "Vichada"},
        {StateID: 736, CountryID: 50, DisplayName: "Alajuela"},
        {StateID: 735, CountryID: 50, DisplayName: "Cartago"},
        {StateID: 734, CountryID: 50, DisplayName: "Guanacaste"},
        {StateID: 733, CountryID: 50, DisplayName: "Heredia"},
        {StateID: 732, CountryID: 50, DisplayName: "Limón"},
        {StateID: 731, CountryID: 50, DisplayName: "Puntarenas"},
        {StateID: 730, CountryID: 50, DisplayName: "San José"},
        {StateID: 751, CountryID: 51, DisplayName: "Artemisa"},
        {StateID: 750, CountryID: 51, DisplayName: "Camagüey"},
        {StateID: 749, CountryID: 51, DisplayName: "Ciego de Ávila"},
        {StateID: 748, CountryID: 51, DisplayName: "Cienfuegos"},
        {StateID: 746, CountryID: 51, DisplayName: "Granma"},
        {StateID: 745, CountryID: 51, DisplayName: "Guantánamo"},
        {StateID: 747, CountryID: 51, DisplayName: "Havana"},
        {StateID: 744, CountryID: 51, DisplayName: "Holguín"},
        {StateID: 743, CountryID: 51, DisplayName: "Isla de la Juventud"},
        {StateID: 742, CountryID: 51, DisplayName: "Las Tunas"},
        {StateID: 741, CountryID: 51, DisplayName: "Matanzas"},
        {StateID: 752, CountryID: 51, DisplayName: "Mayabeque"},
        {StateID: 740, CountryID: 51, DisplayName: "Pinar del Río"},
        {StateID: 739, CountryID: 51, DisplayName: "Sancti Spíritus"},
        {StateID: 738, CountryID: 51, DisplayName: "Santiago de Cuba"},
        {StateID: 737, CountryID: 51, DisplayName: "Villa Clara"},
        {StateID: 762, CountryID: 52, DisplayName: "Boa Vista"},
        {StateID: 761, CountryID: 52, DisplayName: "Brava"},
        {StateID: 760, CountryID: 52, DisplayName: "Maio"},
        {StateID: 763, CountryID: 52, DisplayName: "Mosteiros"},
        {StateID: 759, CountryID: 52, DisplayName: "Paul"},
        {StateID: 768, CountryID: 52, DisplayName: "Porto Novo"},
        {StateID: 758, CountryID: 52, DisplayName: "Praia"},
        {StateID: 769, CountryID: 52, DisplayName: "Ribeira Brava"},
        {StateID: 757, CountryID: 52, DisplayName: "Ribeira Grande"},
        {StateID: 774, CountryID: 52, DisplayName: "Ribeira Grande de Santiago"},
        {StateID: 756, CountryID: 52, DisplayName: "Sal"},
        {StateID: 755, CountryID: 52, DisplayName: "Santa Catarina"},
        {StateID: 770, CountryID: 52, DisplayName: "Santa Catarina do Fogo"},
        {StateID: 764, CountryID: 52, DisplayName: "Santa Cruz"},
        {StateID: 765, CountryID: 52, DisplayName: "São Domingos"},
        {StateID: 766, CountryID: 52, DisplayName: "São Filipe"},
        {StateID: 773, CountryID: 52, DisplayName: "São Lourenço dos Órgãos"},
        {StateID: 767, CountryID: 52, DisplayName: "São Miguel"},
        {StateID: 771, CountryID: 52, DisplayName: "São Salvador do Mundo"},
        {StateID: 754, CountryID: 52, DisplayName: "São Vicente"},
        {StateID: 753, CountryID: 52, DisplayName: "Tarrafal"},
        {StateID: 772, CountryID: 52, DisplayName: "Tarrafal de São Nicolau"},
        {StateID: 780, CountryID: 55, DisplayName: "Ammochostos"},
        {StateID: 779, CountryID: 55, DisplayName: "Keryneia"},
        {StateID: 778, CountryID: 55, DisplayName: "Larnaka"},
        {StateID: 777, CountryID: 55, DisplayName: "Limassol"},
        {StateID: 776, CountryID: 55, DisplayName: "Nicosia"},
        {StateID: 775, CountryID: 55, DisplayName: "Pafos"},
        {StateID: 792, CountryID: 56, DisplayName: "Central Bohemia"},
        {StateID: 781, CountryID: 56, DisplayName: "Hlavní mesto Praha"},
        {StateID: 783, CountryID: 56, DisplayName: "Jihoceský kraj"},
        {StateID: 785, CountryID: 56, DisplayName: "Karlovarský kraj"},
        {StateID: 786, CountryID: 56, DisplayName: "Královéhradecký kraj"},
        {StateID: 787, CountryID: 56, DisplayName: "Liberecký kraj"},
        {StateID: 789, CountryID: 56, DisplayName: "Moravskoslezský"},
        {StateID: 788, CountryID: 56, DisplayName: "Olomoucký"},
        {StateID: 790, CountryID: 56, DisplayName: "Pardubický"},
        {StateID: 791, CountryID: 56, DisplayName: "Plzenský kraj"},
        {StateID: 782, CountryID: 56, DisplayName: "South Moravian"},
        {StateID: 793, CountryID: 56, DisplayName: "Ústecký kraj"},
        {StateID: 784, CountryID: 56, DisplayName: "Vysocina"},
        {StateID: 794, CountryID: 56, DisplayName: "Zlín"},
        {StateID: 810, CountryID: 57, DisplayName: "Baden-Württemberg"},
        {StateID: 809, CountryID: 57, DisplayName: "Bavaria"},
        {StateID: 808, CountryID: 57, DisplayName: "Berlin"},
        {StateID: 807, CountryID: 57, DisplayName: "Brandenburg"},
        {StateID: 806, CountryID: 57, DisplayName: "Bremen"},
        {StateID: 805, CountryID: 57, DisplayName: "Hamburg"},
        {StateID: 804, CountryID: 57, DisplayName: "Hesse"},
        {StateID: 802, CountryID: 57, DisplayName: "Lower Saxony"},
        {StateID: 803, CountryID: 57, DisplayName: "Mecklenburg-Vorpommern"},
        {StateID: 801, CountryID: 57, DisplayName: "North Rhine-Westphalia"},
        {StateID: 800, CountryID: 57, DisplayName: "Rheinland-Pfalz"},
        {StateID: 799, CountryID: 57, DisplayName: "Saarland"},
        {StateID: 798, CountryID: 57, DisplayName: "Saxony"},
        {StateID: 797, CountryID: 57, DisplayName: "Saxony-Anhalt"},
        {StateID: 796, CountryID: 57, DisplayName: "Schleswig-Holstein"},
        {StateID: 795, CountryID: 57, DisplayName: "Thuringia"},
        {StateID: 815, CountryID: 58, DisplayName: "Ali Sabieh"},
        {StateID: 816, CountryID: 58, DisplayName: "Arta"},
        {StateID: 814, CountryID: 58, DisplayName: "Dikhil"},
        {StateID: 813, CountryID: 58, DisplayName: "Djibouti"},
        {StateID: 812, CountryID: 58, DisplayName: "Obock"},
        {StateID: 811, CountryID: 58, DisplayName: "Tadjourah"},
        {StateID: 817, CountryID: 59, DisplayName: "Capital Region"},
        {StateID: 818, CountryID: 59, DisplayName: "Central Jutland"},
        {StateID: 819, CountryID: 59, DisplayName: "North Denmark"},
        {StateID: 821, CountryID: 59, DisplayName: "South Denmark"},
        {StateID: 820, CountryID: 59, DisplayName: "Zealand"},
        {StateID: 831, CountryID: 60, DisplayName: "Saint Andrew"},
        {StateID: 830, CountryID: 60, DisplayName: "Saint David"},
        {StateID: 829, CountryID: 60, DisplayName: "Saint George"},
        {StateID: 828, CountryID: 60, DisplayName: "Saint John"},
        {StateID: 827, CountryID: 60, DisplayName: "Saint Joseph"},
        {StateID: 826, CountryID: 60, DisplayName: "Saint Luke"},
        {StateID: 825, CountryID: 60, DisplayName: "Saint Mark"},
        {StateID: 824, CountryID: 60, DisplayName: "Saint Patrick"},
        {StateID: 823, CountryID: 60, DisplayName: "Saint Paul"},
        {StateID: 822, CountryID: 60, DisplayName: "Saint Peter"},
        {StateID: 861, CountryID: 61, DisplayName: "Azua"},
        {StateID: 860, CountryID: 61, DisplayName: "Baoruco"},
        {StateID: 859, CountryID: 61, DisplayName: "Barahona"},
        {StateID: 858, CountryID: 61, DisplayName: "Dajabón"},
        {StateID: 857, CountryID: 61, DisplayName: "Duarte"},
        {StateID: 855, CountryID: 61, DisplayName: "El Seíbo"},
        {StateID: 856, CountryID: 61, DisplayName: "Elías Piña"},
        {StateID: 854, CountryID: 61, DisplayName: "Espaillat"},
        {StateID: 853, CountryID: 61, DisplayName: "Hato Mayor"},
        {StateID: 840, CountryID: 61, DisplayName: "Hermanas Mirabal"},
        {StateID: 852, CountryID: 61, DisplayName: "Independencia"},
        {StateID: 851, CountryID: 61, DisplayName: "La Altagracia"},
        {StateID: 850, CountryID: 61, DisplayName: "La Romana"},
        {StateID: 849, CountryID: 61, DisplayName: "La Vega"},
        {StateID: 848, CountryID: 61, DisplayName: "María Trinidad Sánchez"},
        {StateID: 847, CountryID: 61, DisplayName: "Monseñor Nouel"},
        {StateID: 846, CountryID: 61, DisplayName: "Monte Cristi"},
        {StateID: 845, CountryID: 61, DisplayName: "Monte Plata"},
        {StateID: 844, CountryID: 61, DisplayName: "Nacional"},
        {StateID: 843, CountryID: 61, DisplayName: "Pedernales"},
        {StateID: 842, CountryID: 61, DisplayName: "Peravia"},
        {StateID: 841, CountryID: 61, DisplayName: "Puerto Plata"},
        {StateID: 839, CountryID: 61, DisplayName: "Samaná"},
        {StateID: 837, CountryID: 61, DisplayName: "San Cristóbal"},
        {StateID: 862, CountryID: 61, DisplayName: "San José de Ocoa"},
        {StateID: 836, CountryID: 61, DisplayName: "San Juan"},
        {StateID: 835, CountryID: 61, DisplayName: "San Pedro de Macorís"},
        {StateID: 838, CountryID: 61, DisplayName: "Sánchez Ramírez"},
        {StateID: 834, CountryID: 61, DisplayName: "Santiago"},
        {StateID: 833, CountryID: 61, DisplayName: "Santiago Rodríguez"},
        {StateID: 863, CountryID: 61, DisplayName: "Santo Domingo"},
        {StateID: 832, CountryID: 61, DisplayName: "Valverde"},
        {StateID: 911, CountryID: 62, DisplayName: "Adrar"},
        {StateID: 910, CountryID: 62, DisplayName: "Aïn Defla"},
        {StateID: 909, CountryID: 62, DisplayName: "Aïn Témouchent"},
        {StateID: 908, CountryID: 62, DisplayName: "Algiers"},
        {StateID: 907, CountryID: 62, DisplayName: "Annaba"},
        {StateID: 906, CountryID: 62, DisplayName: "Batna"},
        {StateID: 905, CountryID: 62, DisplayName: "Béchar"},
        {StateID: 904, CountryID: 62, DisplayName: "Béjaïa"},
        {StateID: 914, CountryID: 62, DisplayName: "Beni Abbes"},
        {StateID: 903, CountryID: 62, DisplayName: "Biskra"},
        {StateID: 902, CountryID: 62, DisplayName: "Blida"},
        {StateID: 912, CountryID: 62, DisplayName: "Bordj Badji Mokhtar"},
        {StateID: 901, CountryID: 62, DisplayName: "Bordj Bou Arréridj"},
        {StateID: 900, CountryID: 62, DisplayName: "Bouira"},
        {StateID: 899, CountryID: 62, DisplayName: "Boumerdes"},
        {StateID: 898, CountryID: 62, DisplayName: "Chlef"},
        {StateID: 897, CountryID: 62, DisplayName: "Constantine"},
        {StateID: 917, CountryID: 62, DisplayName: "Djanet"},
        {StateID: 896, CountryID: 62, DisplayName: "Djelfa"},
        {StateID: 895, CountryID: 62, DisplayName: "El Bayadh"},
        {StateID: 918, CountryID: 62, DisplayName: "El Menia"},
        {StateID: 920, CountryID: 62, DisplayName: "El Mghair"},
        {StateID: 894, CountryID: 62, DisplayName: "El Oued"},
        {StateID: 893, CountryID: 62, DisplayName: "El Tarf"},
        {StateID: 892, CountryID: 62, DisplayName: "Ghardaia"},
        {StateID: 891, CountryID: 62, DisplayName: "Guelma"},
        {StateID: 890, CountryID: 62, DisplayName: "Illizi"},
        {StateID: 915, CountryID: 62, DisplayName: "In Guezzam"},
        {StateID: 916, CountryID: 62, DisplayName: "In Salah"},
        {StateID: 889, CountryID: 62, DisplayName: "Jijel"},
        {StateID: 888, CountryID: 62, DisplayName: "Khenchela"},
        {StateID: 887, CountryID: 62, DisplayName: "Laghouat"},
        {StateID: 882, CountryID: 62, DisplayName: "M'Sila"},
        {StateID: 886, CountryID: 62, DisplayName: "Mascara"},
        {StateID: 885, CountryID: 62, DisplayName: "Medea"},
        {StateID: 884, CountryID: 62, DisplayName: "Mila"},
        {StateID: 883, CountryID: 62, DisplayName: "Mostaganem"},
        {StateID: 881, CountryID: 62, DisplayName: "Naama"},
        {StateID: 880, CountryID: 62, DisplayName: "Oran"},
        {StateID: 879, CountryID: 62, DisplayName: "Ouargla"},
        {StateID: 921, CountryID: 62, DisplayName: "Ouled Djellal"},
        {StateID: 878, CountryID: 62, DisplayName: "Oum el Bouaghi"},
        {StateID: 877, CountryID: 62, DisplayName: "Relizane"},
        {StateID: 876, CountryID: 62, DisplayName: "Saida"},
        {StateID: 875, CountryID: 62, DisplayName: "Sétif"},
        {StateID: 874, CountryID: 62, DisplayName: "Sidi Bel Abbès"},
        {StateID: 873, CountryID: 62, DisplayName: "Skikda"},
        {StateID: 872, CountryID: 62, DisplayName: "Souk Ahras"},
        {StateID: 871, CountryID: 62, DisplayName: "Tamanrasset"},
        {StateID: 870, CountryID: 62, DisplayName: "Tébessa"},
        {StateID: 869, CountryID: 62, DisplayName: "Tiaret"},
        {StateID: 913, CountryID: 62, DisplayName: "Timimoun"},
        {StateID: 868, CountryID: 62, DisplayName: "Tindouf"},
        {StateID: 867, CountryID: 62, DisplayName: "Tipaza"},
        {StateID: 866, CountryID: 62, DisplayName: "Tissemsilt"},
        {StateID: 865, CountryID: 62, DisplayName: "Tizi Ouzou"},
        {StateID: 864, CountryID: 62, DisplayName: "Tlemcen"},
        {StateID: 919, CountryID: 62, DisplayName: "Touggourt"},
        {StateID: 941, CountryID: 63, DisplayName: "Azuay"},
        {StateID: 940, CountryID: 63, DisplayName: "Bolívar"},
        {StateID: 939, CountryID: 63, DisplayName: "Cañar"},
        {StateID: 938, CountryID: 63, DisplayName: "Carchi"},
        {StateID: 937, CountryID: 63, DisplayName: "Chimborazo"},
        {StateID: 936, CountryID: 63, DisplayName: "Cotopaxi"},
        {StateID: 935, CountryID: 63, DisplayName: "El Oro"},
        {StateID: 934, CountryID: 63, DisplayName: "Esmeraldas"},
        {StateID: 933, CountryID: 63, DisplayName: "Galápagos"},
        {StateID: 932, CountryID: 63, DisplayName: "Guayas"},
        {StateID: 931, CountryID: 63, DisplayName: "Imbabura"},
        {StateID: 930, CountryID: 63, DisplayName: "Loja"},
        {StateID: 929, CountryID: 63, DisplayName: "Los Ríos"},
        {StateID: 928, CountryID: 63, DisplayName: "Manabí"},
        {StateID: 927, CountryID: 63, DisplayName: "Morona-Santiago"},
        {StateID: 926, CountryID: 63, DisplayName: "Napo"},
        {StateID: 943, CountryID: 63, DisplayName: "Orellana"},
        {StateID: 925, CountryID: 63, DisplayName: "Pastaza"},
        {StateID: 924, CountryID: 63, DisplayName: "Pichincha"},
        {StateID: 945, CountryID: 63, DisplayName: "Santa Elena"},
        {StateID: 944, CountryID: 63, DisplayName: "Santo Domingo de los Tsáchilas"},
        {StateID: 942, CountryID: 63, DisplayName: "Sucumbios"},
        {StateID: 923, CountryID: 63, DisplayName: "Tungurahua"},
        {StateID: 922, CountryID: 63, DisplayName: "Zamora-Chinchipe"},
        {StateID: 960, CountryID: 64, DisplayName: "Harjumaa"},
        {StateID: 959, CountryID: 64, DisplayName: "Hiiumaa"},
        {StateID: 958, CountryID: 64, DisplayName: "Ida-Virumaa"},
        {StateID: 957, CountryID: 64, DisplayName: "Järvamaa"},
        {StateID: 956, CountryID: 64, DisplayName: "Jõgevamaa"},
        {StateID: 955, CountryID: 64, DisplayName: "Lääne"},
        {StateID: 954, CountryID: 64, DisplayName: "Lääne-Virumaa"},
        {StateID: 953, CountryID: 64, DisplayName: "Pärnumaa"},
        {StateID: 952, CountryID: 64, DisplayName: "Põlvamaa"},
        {StateID: 951, CountryID: 64, DisplayName: "Raplamaa"},
        {StateID: 950, CountryID: 64, DisplayName: "Saare"},
        {StateID: 949, CountryID: 64, DisplayName: "Tartu"},
        {StateID: 948, CountryID: 64, DisplayName: "Valgamaa"},
        {StateID: 947, CountryID: 64, DisplayName: "Viljandimaa"},
        {StateID: 946, CountryID: 64, DisplayName: "Võrumaa"},
        {StateID: 981, CountryID: 65, DisplayName: "Alexandria"},
        {StateID: 971, CountryID: 65, DisplayName: "Aswan"},
        {StateID: 970, CountryID: 65, DisplayName: "Asyut"},
        {StateID: 984, CountryID: 65, DisplayName: "Beheira"},
        {StateID: 969, CountryID: 65, DisplayName: "Beni Suweif"},
        {StateID: 976, CountryID: 65, DisplayName: "Cairo"},
        {StateID: 986, CountryID: 65, DisplayName: "Dakahlia"},
        {StateID: 967, CountryID: 65, DisplayName: "Damietta"},
        {StateID: 983, CountryID: 65, DisplayName: "Faiyum"},
        {StateID: 982, CountryID: 65, DisplayName: "Gharbia"},
        {StateID: 979, CountryID: 65, DisplayName: "Giza"},
        {StateID: 980, CountryID: 65, DisplayName: "Ismailia"},
        {StateID: 965, CountryID: 65, DisplayName: "Kafr el-Sheikh"},
        {StateID: 987, CountryID: 65, DisplayName: "Luxor"},
        {StateID: 964, CountryID: 65, DisplayName: "Matruh"},
        {StateID: 977, CountryID: 65, DisplayName: "Minya"},
        {StateID: 978, CountryID: 65, DisplayName: "Monufia"},
        {StateID: 974, CountryID: 65, DisplayName: "New Valley"},
        {StateID: 962, CountryID: 65, DisplayName: "North Sinai"},
        {StateID: 968, CountryID: 65, DisplayName: "Port Said"},
        {StateID: 975, CountryID: 65, DisplayName: "Qalyubia"},
        {StateID: 963, CountryID: 65, DisplayName: "Qena"},
        {StateID: 985, CountryID: 65, DisplayName: "Red Sea"},
        {StateID: 973, CountryID: 65, DisplayName: "Sharqia"},
        {StateID: 961, CountryID: 65, DisplayName: "Sohag"},
        {StateID: 966, CountryID: 65, DisplayName: "South Sinai"},
        {StateID: 972, CountryID: 65, DisplayName: "Suez"},
        {StateID: 988, CountryID: 67, DisplayName: "Anseba"},
        {StateID: 989, CountryID: 67, DisplayName: "Debub"},
        {StateID: 991, CountryID: 67, DisplayName: "Gash-Barka"},
        {StateID: 992, CountryID: 67, DisplayName: "Maekel"},
        {StateID: 993, CountryID: 67, DisplayName: "Northern Red Sea"},
        {StateID: 990, CountryID: 67, DisplayName: "Southern Red Sea"},
        {StateID: 997, CountryID: 68, DisplayName: "Andalusia"},
        {StateID: 1007, CountryID: 68, DisplayName: "Aragon"},
        {StateID: 1002, CountryID: 68, DisplayName: "Asturias"},
        {StateID: 996, CountryID: 68, DisplayName: "Balearic Islands"},
        {StateID: 1011, CountryID: 68, DisplayName: "Basque Country"},
        {StateID: 998, CountryID: 68, DisplayName: "Canary Islands"},
        {StateID: 1006, CountryID: 68, DisplayName: "Cantabria"},
        {StateID: 1008, CountryID: 68, DisplayName: "Castille and León"},
        {StateID: 999, CountryID: 68, DisplayName: "Castille-La Mancha"},
        {StateID: 1009, CountryID: 68, DisplayName: "Catalonia"},
        {StateID: 995, CountryID: 68, DisplayName: "Ceuta"},
        {StateID: 1000, CountryID: 68, DisplayName: "Extremadura"},
        {StateID: 1010, CountryID: 68, DisplayName: "Galicia"},
        {StateID: 1005, CountryID: 68, DisplayName: "La Rioja"},
        {StateID: 1004, CountryID: 68, DisplayName: "Madrid"},
        {StateID: 1012, CountryID: 68, DisplayName: "Melilla"},
        {StateID: 994, CountryID: 68, DisplayName: "Murcia"},
        {StateID: 1003, CountryID: 68, DisplayName: "Navarre"},
        {StateID: 1001, CountryID: 68, DisplayName: "Valencia"},
        {StateID: 1013, CountryID: 69, DisplayName: "Addis Ababa"},
        {StateID: 1014, CountryID: 69, DisplayName: "Afar"},
        {StateID: 1015, CountryID: 69, DisplayName: "Amhara"},
        {StateID: 1016, CountryID: 69, DisplayName: "Binshangul Gumuz"},
        {StateID: 1017, CountryID: 69, DisplayName: "Dire Dawa"},
        {StateID: 1018, CountryID: 69, DisplayName: "Gambela"},
        {StateID: 1019, CountryID: 69, DisplayName: "Harari"},
        {StateID: 1020, CountryID: 69, DisplayName: "Oromiya"},
        {StateID: 1023, CountryID: 69, DisplayName: "SNNPR"},
        {StateID: 1021, CountryID: 69, DisplayName: "Somali"},
        {StateID: 1022, CountryID: 69, DisplayName: "Tigray"},
        {StateID: 1030, CountryID: 70, DisplayName: "Central Finland"},
        {StateID: 1027, CountryID: 70, DisplayName: "Central Ostrobothnia"},
        {StateID: 1025, CountryID: 70, DisplayName: "Kainuu"},
        {StateID: 1037, CountryID: 70, DisplayName: "Kanta-Häme"},
        {StateID: 1035, CountryID: 70, DisplayName: "Kymenlaakso"},
        {StateID: 1024, CountryID: 70, DisplayName: "Lapland"},
        {StateID: 1031, CountryID: 70, DisplayName: "North Karelia"},
        {StateID: 1026, CountryID: 70, DisplayName: "North Ostrobothnia"},
        {StateID: 1032, CountryID: 70, DisplayName: "North Savo"},
        {StateID: 1028, CountryID: 70, DisplayName: "Ostrobothnia"},
        {StateID: 1040, CountryID: 70, DisplayName: "Paijat-Hame"},
        {StateID: 1036, CountryID: 70, DisplayName: "Pirkanmaa"},
        {StateID: 1041, CountryID: 70, DisplayName: "Satakunta"},
        {StateID: 1034, CountryID: 70, DisplayName: "South Karelia"},
        {StateID: 1029, CountryID: 70, DisplayName: "South Ostrobothnia"},
        {StateID: 1033, CountryID: 70, DisplayName: "South Savo"},
        {StateID: 1038, CountryID: 70, DisplayName: "Southwest Finland"},
        {StateID: 1039, CountryID: 70, DisplayName: "Uusimaa"},
        {StateID: 1044, CountryID: 71, DisplayName: "Central"},
        {StateID: 1045, CountryID: 71, DisplayName: "Eastern"},
        {StateID: 1043, CountryID: 71, DisplayName: "Northern"},
        {StateID: 1046, CountryID: 71, DisplayName: "Rotuma"},
        {StateID: 1042, CountryID: 71, DisplayName: "Western"},
        {StateID: 1050, CountryID: 73, DisplayName: "Chuuk"},
        {StateID: 1049, CountryID: 73, DisplayName: "Kosrae"},
        {StateID: 1048, CountryID: 73, DisplayName: "Pohnpei"},
        {StateID: 1047, CountryID: 73, DisplayName: "Yap"},
        {StateID: 1056, CountryID: 74, DisplayName: "Eysturoy"},
        {StateID: 1055, CountryID: 74, DisplayName: "Norðoyar"},
        {StateID: 1054, CountryID: 74, DisplayName: "Sandoy"},
        {StateID: 1053, CountryID: 74, DisplayName: "Streymoy"},
        {StateID: 1052, CountryID: 74, DisplayName: "Suðuroy"},
        {StateID: 1051, CountryID: 74, DisplayName: "Vágar"},
        {StateID: 1069, CountryID: 75, DisplayName: "Auvergne-Rhône-Alpes"},
        {StateID: 1063, CountryID: 75, DisplayName: "Bourgogne-Franche-Comté"},
        {StateID: 1062, CountryID: 75, DisplayName: "Brittany"},
        {StateID: 1061, CountryID: 75, DisplayName: "Centre"},
        {StateID: 1060, CountryID: 75, DisplayName: "Corsica"},
        {StateID: 1066, CountryID: 75, DisplayName: "Grand Est"},
        {StateID: 1068, CountryID: 75, DisplayName: "Hauts-de-France"},
        {StateID: 1059, CountryID: 75, DisplayName: "Île-de-France"},
        {StateID: 1065, CountryID: 75, DisplayName: "Normandy"},
        {StateID: 1064, CountryID: 75, DisplayName: "Nouvelle-Aquitaine"},
        {StateID: 1067, CountryID: 75, DisplayName: "Occitanie"},
        {StateID: 1058, CountryID: 75, DisplayName: "Pays de la Loire"},
        {StateID: 1057, CountryID: 75, DisplayName: "Provence-Alpes-Côte d'Azur"},
        {StateID: 1078, CountryID: 76, DisplayName: "Estuaire"},
        {StateID: 1077, CountryID: 76, DisplayName: "Haut-Ogooué"},
        {StateID: 1076, CountryID: 76, DisplayName: "Moyen-Ogooué"},
        {StateID: 1075, CountryID: 76, DisplayName: "Ngouni"},
        {StateID: 1074, CountryID: 76, DisplayName: "Nyanga"},
        {StateID: 1073, CountryID: 76, DisplayName: "Ogooué-Ivindo"},
        {StateID: 1072, CountryID: 76, DisplayName: "Ogooué-Lolo"},
        {StateID: 1071, CountryID: 76, DisplayName: "Ogooué-Maritime"},
        {StateID: 1070, CountryID: 76, DisplayName: "Woleu-Ntem"},
        {StateID: 1082, CountryID: 77, DisplayName: "England"},
        {StateID: 1081, CountryID: 77, DisplayName: "Northern Ireland"},
        {StateID: 1080, CountryID: 77, DisplayName: "Scotland"},
        {StateID: 1079, CountryID: 77, DisplayName: "Wales"},
        {StateID: 1089, CountryID: 78, DisplayName: "Carriacou and Petite Martinique"},
        {StateID: 1088, CountryID: 78, DisplayName: "Saint Andrew"},
        {StateID: 1087, CountryID: 78, DisplayName: "Saint David"},
        {StateID: 1086, CountryID: 78, DisplayName: "Saint George"},
        {StateID: 1085, CountryID: 78, DisplayName: "Saint John"},
        {StateID: 1084, CountryID: 78, DisplayName: "Saint Mark"},
        {StateID: 1083, CountryID: 78, DisplayName: "Saint Patrick"},
        {StateID: 1101, CountryID: 79, DisplayName: "Abkhazia"},
        {StateID: 1091, CountryID: 79, DisplayName: "Achara"},
        {StateID: 1094, CountryID: 79, DisplayName: "Guria"},
        {StateID: 1095, CountryID: 79, DisplayName: "Imereti"},
        {StateID: 1093, CountryID: 79, DisplayName: "Kakheti"},
        {StateID: 1092, CountryID: 79, DisplayName: "Kvemo Kartli"},
        {StateID: 1097, CountryID: 79, DisplayName: "Mtskheta-Mtianeti"},
        {StateID: 1098, CountryID: 79, DisplayName: "Racha-Lechkhumi and Kvemo Svaneti"},
        {StateID: 1099, CountryID: 79, DisplayName: "Samegrelo and Zemo Svaneti"},
        {StateID: 1100, CountryID: 79, DisplayName: "Samtskhe-Javakheti"},
        {StateID: 1096, CountryID: 79, DisplayName: "Shida Kartli"},
        {StateID: 1090, CountryID: 79, DisplayName: "T'bilisi"},
        {StateID: 1102, CountryID: 80, DisplayName: "Guyane"},
        {StateID: 1113, CountryID: 81, DisplayName: "Alderney"},
        {StateID: 1110, CountryID: 81, DisplayName: "Castel"},
        {StateID: 1106, CountryID: 81, DisplayName: "Forest"},
        {StateID: 1108, CountryID: 81, DisplayName: "Saint Andrew"},
        {StateID: 1105, CountryID: 81, DisplayName: "Saint Saviour"},
        {StateID: 1107, CountryID: 81, DisplayName: "St Martin"},
        {StateID: 1109, CountryID: 81, DisplayName: "St Peter Port"},
        {StateID: 1103, CountryID: 81, DisplayName: "St Pierre du Bois"},
        {StateID: 1112, CountryID: 81, DisplayName: "St Sampson"},
        {StateID: 1104, CountryID: 81, DisplayName: "Torteval"},
        {StateID: 1111, CountryID: 81, DisplayName: "Vale"},
        {StateID: 1126, CountryID: 82, DisplayName: "Ahafo"},
        {StateID: 1122, CountryID: 82, DisplayName: "Ashanti"},
        {StateID: 1123, CountryID: 82, DisplayName: "Bono"},
        {StateID: 1127, CountryID: 82, DisplayName: "Bono East"},
        {StateID: 1121, CountryID: 82, DisplayName: "Central"},
        {StateID: 1120, CountryID: 82, DisplayName: "Eastern"},
        {StateID: 1119, CountryID: 82, DisplayName: "Greater Accra"},
        {StateID: 1125, CountryID: 82, DisplayName: "North East"},
        {StateID: 1118, CountryID: 82, DisplayName: "Northern"},
        {StateID: 1124, CountryID: 82, DisplayName: "Oti"},
        {StateID: 1128, CountryID: 82, DisplayName: "Savannah"},
        {StateID: 1117, CountryID: 82, DisplayName: "Upper East"},
        {StateID: 1116, CountryID: 82, DisplayName: "Upper West"},
        {StateID: 1115, CountryID: 82, DisplayName: "Volta"},
        {StateID: 1114, CountryID: 82, DisplayName: "Western"},
        {StateID: 1129, CountryID: 82, DisplayName: "Western North"},
        {StateID: 1134, CountryID: 84, DisplayName: "Avannaata"},
        {StateID: 1130, CountryID: 84, DisplayName: "Kujalleq"},
        {StateID: 1133, CountryID: 84, DisplayName: "Qeqertalik"},
        {StateID: 1131, CountryID: 84, DisplayName: "Qeqqata"},
        {StateID: 1132, CountryID: 84, DisplayName: "Sermersooq"},
        {StateID: 1140, CountryID: 85, DisplayName: "Banjul"},
        {StateID: 1138, CountryID: 85, DisplayName: "Central River"},
        {StateID: 1139, CountryID: 85, DisplayName: "Lower River"},
        {StateID: 1137, CountryID: 85, DisplayName: "North Bank"},
        {StateID: 1136, CountryID: 85, DisplayName: "Upper River"},
        {StateID: 1135, CountryID: 85, DisplayName: "Western"},
        {StateID: 1142, CountryID: 86, DisplayName: "Boke"},
        {StateID: 1141, CountryID: 86, DisplayName: "Conakry"},
        {StateID: 1143, CountryID: 86, DisplayName: "Faranah"},
        {StateID: 1144, CountryID: 86, DisplayName: "Kankan"},
        {StateID: 1145, CountryID: 86, DisplayName: "Kindia"},
        {StateID: 1146, CountryID: 86, DisplayName: "Labe"},
        {StateID: 1147, CountryID: 86, DisplayName: "Mamou"},
        {StateID: 1148, CountryID: 86, DisplayName: "Nzerekore"},
        {StateID: 1149, CountryID: 87, DisplayName: "Guadeloupe"},
        {StateID: 1150, CountryID: 88, DisplayName: "Annobon"},
        {StateID: 1151, CountryID: 88, DisplayName: "Bioko Norte"},
        {StateID: 1152, CountryID: 88, DisplayName: "Bioko Sur"},
        {StateID: 1153, CountryID: 88, DisplayName: "Centro Sur"},
        {StateID: 1157, CountryID: 88, DisplayName: "Djibloho"},
        {StateID: 1154, CountryID: 88, DisplayName: "Kié-Ntem"},
        {StateID: 1155, CountryID: 88, DisplayName: "Litoral"},
        {StateID: 1156, CountryID: 88, DisplayName: "Wele-Nzas"},
        {StateID: 1159, CountryID: 89, DisplayName: "Attica"},
        {StateID: 1160, CountryID: 89, DisplayName: "Central Greece"},
        {StateID: 1161, CountryID: 89, DisplayName: "Central Macedonia"},
        {StateID: 1162, CountryID: 89, DisplayName: "Crete"},
        {StateID: 1163, CountryID: 89, DisplayName: "East Macedonia and Thrace"},
        {StateID: 1164, CountryID: 89, DisplayName: "Epirus"},
        {StateID: 1165, CountryID: 89, DisplayName: "Ionian Islands"},
        {StateID: 1158, CountryID: 89, DisplayName: "Mount Athos"},
        {StateID: 1166, CountryID: 89, DisplayName: "North Aegean"},
        {StateID: 1167, CountryID: 89, DisplayName: "Peloponnese"},
        {StateID: 1168, CountryID: 89, DisplayName: "South Aegean"},
        {StateID: 1169, CountryID: 89, DisplayName: "Thessaly"},
        {StateID: 1170, CountryID: 89, DisplayName: "West Greece"},
        {StateID: 1171, CountryID: 89, DisplayName: "West Macedonia"},
        {StateID: 1193, CountryID: 91, DisplayName: "Alta Verapaz"},
        {StateID: 1192, CountryID: 91, DisplayName: "Baja Verapaz"},
        {StateID: 1191, CountryID: 91, DisplayName: "Chimaltenango"},
        {StateID: 1190, CountryID: 91, DisplayName: "Chiquimula"},
        {StateID: 1189, CountryID: 91, DisplayName: "El Progreso"},
        {StateID: 1188, CountryID: 91, DisplayName: "Escuintla"},
        {StateID: 1187, CountryID: 91, DisplayName: "Guatemala"},
        {StateID: 1186, CountryID: 91, DisplayName: "Huehuetenango"},
        {StateID: 1185, CountryID: 91, DisplayName: "Izabal"},
        {StateID: 1184, CountryID: 91, DisplayName: "Jalapa"},
        {StateID: 1183, CountryID: 91, DisplayName: "Jutiapa"},
        {StateID: 1182, CountryID: 91, DisplayName: "Petén"},
        {StateID: 1181, CountryID: 91, DisplayName: "Quetzaltenango"},
        {StateID: 1180, CountryID: 91, DisplayName: "Quiché"},
        {StateID: 1179, CountryID: 91, DisplayName: "Retalhuleu"},
        {StateID: 1178, CountryID: 91, DisplayName: "Sacatepéquez"},
        {StateID: 1177, CountryID: 91, DisplayName: "San Marcos"},
        {StateID: 1176, CountryID: 91, DisplayName: "Santa Rosa"},
        {StateID: 1175, CountryID: 91, DisplayName: "Sololá"},
        {StateID: 1174, CountryID: 91, DisplayName: "Suchitepeque"},
        {StateID: 1173, CountryID: 91, DisplayName: "Totonicapán"},
        {StateID: 1172, CountryID: 91, DisplayName: "Zacapa"},
        {StateID: 1204, CountryID: 92, DisplayName: "Agana Heights"},
        {StateID: 1207, CountryID: 92, DisplayName: "Agat"},
        {StateID: 1206, CountryID: 92, DisplayName: "Asan"},
        {StateID: 1209, CountryID: 92, DisplayName: "Barrigada"},
        {StateID: 1205, CountryID: 92, DisplayName: "Chalan Pago-Ordot"},
        {StateID: 1208, CountryID: 92, DisplayName: "Dededo"},
        {StateID: 1210, CountryID: 92, DisplayName: "Hagatna"},
        {StateID: 1211, CountryID: 92, DisplayName: "Inarajan"},
        {StateID: 1203, CountryID: 92, DisplayName: "Mangilao"},
        {StateID: 1202, CountryID: 92, DisplayName: "Merizo"},
        {StateID: 1212, CountryID: 92, DisplayName: "Mongmong-Toto-Maite"},
        {StateID: 1194, CountryID: 92, DisplayName: "Piti"},
        {StateID: 1195, CountryID: 92, DisplayName: "Santa Rita"},
        {StateID: 1196, CountryID: 92, DisplayName: "Sinajana"},
        {StateID: 1197, CountryID: 92, DisplayName: "Talofofo"},
        {StateID: 1198, CountryID: 92, DisplayName: "Tamuning"},
        {StateID: 1199, CountryID: 92, DisplayName: "Umatac"},
        {StateID: 1200, CountryID: 92, DisplayName: "Yigo"},
        {StateID: 1201, CountryID: 92, DisplayName: "Yona"},
        {StateID: 1221, CountryID: 93, DisplayName: "Bafatá"},
        {StateID: 1220, CountryID: 93, DisplayName: "Biombo"},
        {StateID: 1219, CountryID: 93, DisplayName: "Bissau"},
        {StateID: 1218, CountryID: 93, DisplayName: "Bolama"},
        {StateID: 1217, CountryID: 93, DisplayName: "Cacheu"},
        {StateID: 1216, CountryID: 93, DisplayName: "Gabú"},
        {StateID: 1215, CountryID: 93, DisplayName: "Oio"},
        {StateID: 1214, CountryID: 93, DisplayName: "Quinara"},
        {StateID: 1213, CountryID: 93, DisplayName: "Tombali"},
        {StateID: 1231, CountryID: 94, DisplayName: "Barima-Waini"},
        {StateID: 1230, CountryID: 94, DisplayName: "Cuyuni-Mazaruni"},
        {StateID: 1229, CountryID: 94, DisplayName: "Demerara-Mahaica"},
        {StateID: 1228, CountryID: 94, DisplayName: "East Berbice-Corentyne"},
        {StateID: 1227, CountryID: 94, DisplayName: "Essequibo Islands-West Demerara"},
        {StateID: 1226, CountryID: 94, DisplayName: "Mahaica-Berbice"},
        {StateID: 1225, CountryID: 94, DisplayName: "Pomeroon-Supenaam"},
        {StateID: 1224, CountryID: 94, DisplayName: "Potaro-Siparuni"},
        {StateID: 1223, CountryID: 94, DisplayName: "Upper Demerara-Berbice"},
        {StateID: 1222, CountryID: 94, DisplayName: "Upper Takutu-Upper Essequibo"},
        {StateID: 1237, CountryID: 95, DisplayName: "Central and Western"},
        {StateID: 1239, CountryID: 95, DisplayName: "Eastern"},
        {StateID: 1236, CountryID: 95, DisplayName: "Islands"},
        {StateID: 1243, CountryID: 95, DisplayName: "Kowloon City"},
        {StateID: 1246, CountryID: 95, DisplayName: "Kwai Tsing"},
        {StateID: 1245, CountryID: 95, DisplayName: "Kwun Tong"},
        {StateID: 1248, CountryID: 95, DisplayName: "North"},
        {StateID: 1235, CountryID: 95, DisplayName: "Sai Kung"},
        {StateID: 1249, CountryID: 95, DisplayName: "Sha Tin"},
        {StateID: 1242, CountryID: 95, DisplayName: "Sham Shui Po"},
        {StateID: 1240, CountryID: 95, DisplayName: "Southern"},
        {StateID: 1234, CountryID: 95, DisplayName: "Tai Po"},
        {StateID: 1233, CountryID: 95, DisplayName: "Tsuen Wan"},
        {StateID: 1247, CountryID: 95, DisplayName: "Tuen Mun"},
        {StateID: 1238, CountryID: 95, DisplayName: "Wan Chai"},
        {StateID: 1244, CountryID: 95, DisplayName: "Wong Tai Sin"},
        {StateID: 1241, CountryID: 95, DisplayName: "Yau Tsim Mong"},
        {StateID: 1232, CountryID: 95, DisplayName: "Yuen Long"},
        {StateID: 1267, CountryID: 97, DisplayName: "Atlántida"},
        {StateID: 1257, CountryID: 97, DisplayName: "Bay Islands"},
        {StateID: 1266, CountryID: 97, DisplayName: "Choluteca"},
        {StateID: 1265, CountryID: 97, DisplayName: "Colón"},
        {StateID: 1264, CountryID: 97, DisplayName: "Comayagua"},
        {StateID: 1263, CountryID: 97, DisplayName: "Copán"},
        {StateID: 1262, CountryID: 97, DisplayName: "Cortés"},
        {StateID: 1261, CountryID: 97, DisplayName: "El Paraíso"},
        {StateID: 1260, CountryID: 97, DisplayName: "Francisco Morazán"},
        {StateID: 1259, CountryID: 97, DisplayName: "Gracias a Dios"},
        {StateID: 1258, CountryID: 97, DisplayName: "Intibucá"},
        {StateID: 1256, CountryID: 97, DisplayName: "La Paz"},
        {StateID: 1255, CountryID: 97, DisplayName: "Lempira"},
        {StateID: 1254, CountryID: 97, DisplayName: "Ocotepeque"},
        {StateID: 1253, CountryID: 97, DisplayName: "Olancho"},
        {StateID: 1252, CountryID: 97, DisplayName: "Santa Bárbara"},
        {StateID: 1251, CountryID: 97, DisplayName: "Valle"},
        {StateID: 1250, CountryID: 97, DisplayName: "Yoro"},
        {StateID: 1280, CountryID: 98, DisplayName: "Šibensko-Kniniska"},
        {StateID: 1268, CountryID: 98, DisplayName: "Bjelovarsko-Bilogorska"},
        {StateID: 1287, CountryID: 98, DisplayName: "City of Zagreb"},
        {StateID: 1270, CountryID: 98, DisplayName: "Dubrovacko-Neretvanska"},
        {StateID: 1271, CountryID: 98, DisplayName: "Istria"},
        {StateID: 1272, CountryID: 98, DisplayName: "Karlovacka"},
        {StateID: 1273, CountryID: 98, DisplayName: "Koprivnicko-Križevacka"},
        {StateID: 1274, CountryID: 98, DisplayName: "Krapinsko-Zagorska"},
        {StateID: 1275, CountryID: 98, DisplayName: "Licko-Senjska"},
        {StateID: 1276, CountryID: 98, DisplayName: "Medimurska"},
        {StateID: 1277, CountryID: 98, DisplayName: "Osjecko-Baranjska"},
        {StateID: 1278, CountryID: 98, DisplayName: "Požeško-Slavonska"},
        {StateID: 1279, CountryID: 98, DisplayName: "Primorsko-Goranska"},
        {StateID: 1281, CountryID: 98, DisplayName: "Sisacko-Moslavacka"},
        {StateID: 1269, CountryID: 98, DisplayName: "Slavonski Brod-Posavina"},
        {StateID: 1282, CountryID: 98, DisplayName: "Split-Dalmatia"},
        {StateID: 1283, CountryID: 98, DisplayName: "Varaždinska"},
        {StateID: 1288, CountryID: 98, DisplayName: "Virovitick-Podravska"},
        {StateID: 1284, CountryID: 98, DisplayName: "Vukovar-Sirmium"},
        {StateID: 1285, CountryID: 98, DisplayName: "Zadarska"},
        {StateID: 1286, CountryID: 98, DisplayName: "Zagrebacka"},
        {StateID: 1297, CountryID: 99, DisplayName: "Artibonite"},
        {StateID: 1296, CountryID: 99, DisplayName: "Centre"},
        {StateID: 1295, CountryID: 99, DisplayName: "Grand'Anse"},
        {StateID: 1298, CountryID: 99, DisplayName: "Nippes"},
        {StateID: 1294, CountryID: 99, DisplayName: "Nord"},
        {StateID: 1293, CountryID: 99, DisplayName: "Nord-Est"},
        {StateID: 1292, CountryID: 99, DisplayName: "Nord-Ouest"},
        {StateID: 1291, CountryID: 99, DisplayName: "Ouest"},
        {StateID: 1290, CountryID: 99, DisplayName: "Sud"},
        {StateID: 1289, CountryID: 99, DisplayName: "Sud-Est"},
        {StateID: 1318, CountryID: 100, DisplayName: "Bács-Kiskun"},
        {StateID: 1317, CountryID: 100, DisplayName: "Baranya"},
        {StateID: 1305, CountryID: 100, DisplayName: "Bekes County"},
        {StateID: 1304, CountryID: 100, DisplayName: "Borsod-Abaúj-Zemplén"},
        {StateID: 1316, CountryID: 100, DisplayName: "Budapest"},
        {StateID: 1303, CountryID: 100, DisplayName: "Csongrád"},
        {StateID: 1315, CountryID: 100, DisplayName: "Fejér"},
        {StateID: 1314, CountryID: 100, DisplayName: "Gyor-Moson-Sopron"},
        {StateID: 1302, CountryID: 100, DisplayName: "Hajdú-Bihar"},
        {StateID: 1301, CountryID: 100, DisplayName: "Heves"},
        {StateID: 1300, CountryID: 100, DisplayName: "Jász-Nagykun-Szolnok"},
        {StateID: 1313, CountryID: 100, DisplayName: "Komárom-Esztergom"},
        {StateID: 1312, CountryID: 100, DisplayName: "Nógrád"},
        {StateID: 1311, CountryID: 100, DisplayName: "Pest"},
        {StateID: 1310, CountryID: 100, DisplayName: "Somogy"},
        {StateID: 1299, CountryID: 100, DisplayName: "Szabolcs-Szatmár-Bereg"},
        {StateID: 1309, CountryID: 100, DisplayName: "Tolna"},
        {StateID: 1308, CountryID: 100, DisplayName: "Vas"},
        {StateID: 1307, CountryID: 100, DisplayName: "Veszprém"},
        {StateID: 1306, CountryID: 100, DisplayName: "Zala"},
        {StateID: 1320, CountryID: 101, DisplayName: "Aceh"},
        {StateID: 1344, CountryID: 101, DisplayName: "Bali"},
        {StateID: 1347, CountryID: 101, DisplayName: "Bangka–Belitung Islands"},
        {StateID: 1345, CountryID: 101, DisplayName: "Banten"},
        {StateID: 1343, CountryID: 101, DisplayName: "Bengkulu"},
        {StateID: 1338, CountryID: 101, DisplayName: "Central Java"},
        {StateID: 1334, CountryID: 101, DisplayName: "Central Kalimantan"},
        {StateID: 1326, CountryID: 101, DisplayName: "Central Sulawesi"},
        {StateID: 1337, CountryID: 101, DisplayName: "East Java"},
        {StateID: 1333, CountryID: 101, DisplayName: "East Kalimantan"},
        {StateID: 1329, CountryID: 101, DisplayName: "East Nusa Tenggara"},
        {StateID: 1346, CountryID: 101, DisplayName: "Gorontalo"},
        {StateID: 1341, CountryID: 101, DisplayName: "Jakarta"},
        {StateID: 1340, CountryID: 101, DisplayName: "Jambi"},
        {StateID: 1332, CountryID: 101, DisplayName: "Lampung"},
        {StateID: 1331, CountryID: 101, DisplayName: "Maluku"},
        {StateID: 1352, CountryID: 101, DisplayName: "North Kalimantan"},
        {StateID: 1348, CountryID: 101, DisplayName: "North Maluku"},
        {StateID: 1324, CountryID: 101, DisplayName: "North Sulawesi"},
        {StateID: 1319, CountryID: 101, DisplayName: "North Sumatra"},
        {StateID: 1342, CountryID: 101, DisplayName: "Papua"},
        {StateID: 1328, CountryID: 101, DisplayName: "Riau"},
        {StateID: 1351, CountryID: 101, DisplayName: "Riau Islands"},
        {StateID: 1335, CountryID: 101, DisplayName: "South Kalimantan"},
        {StateID: 1327, CountryID: 101, DisplayName: "South Sulawesi"},
        {StateID: 1322, CountryID: 101, DisplayName: "South Sumatra"},
        {StateID: 1325, CountryID: 101, DisplayName: "Southeast Sulawesi"},
        {StateID: 1339, CountryID: 101, DisplayName: "West Java"},
        {StateID: 1336, CountryID: 101, DisplayName: "West Kalimantan"},
        {StateID: 1330, CountryID: 101, DisplayName: "West Nusa Tenggara"},
        {StateID: 1349, CountryID: 101, DisplayName: "West Papua"},
        {StateID: 1350, CountryID: 101, DisplayName: "West Sulawesi"},
        {StateID: 1323, CountryID: 101, DisplayName: "West Sumatra"},
        {StateID: 1321, CountryID: 101, DisplayName: "Yogyakarta"},
        {StateID: 1353, CountryID: 102, DisplayName: "Connaught"},
        {StateID: 1354, CountryID: 102, DisplayName: "Leinster"},
        {StateID: 1355, CountryID: 102, DisplayName: "Munster"},
        {StateID: 1356, CountryID: 102, DisplayName: "Ulster"},
        {StateID: 1361, CountryID: 103, DisplayName: "Central District"},
        {StateID: 1359, CountryID: 103, DisplayName: "Haifa"},
        {StateID: 1357, CountryID: 103, DisplayName: "Jerusalem"},
        {StateID: 1363, CountryID: 103, DisplayName: "Judea and Samaria Area"},
        {StateID: 1360, CountryID: 103, DisplayName: "Northern District"},
        {StateID: 1362, CountryID: 103, DisplayName: "Southern District"},
        {StateID: 1358, CountryID: 103, DisplayName: "Tel Aviv"},
        {StateID: 1364, CountryID: 104, DisplayName: "Andreas"},
        {StateID: 1365, CountryID: 104, DisplayName: "Arbory"},
        {StateID: 1366, CountryID: 104, DisplayName: "Ballaugh"},
        {StateID: 1367, CountryID: 104, DisplayName: "Braddan"},
        {StateID: 1368, CountryID: 104, DisplayName: "Bride"},
        {StateID: 1369, CountryID: 104, DisplayName: "Castletown"},
        {StateID: 1370, CountryID: 104, DisplayName: "Douglas"},
        {StateID: 1371, CountryID: 104, DisplayName: "German"},
        {StateID: 1372, CountryID: 104, DisplayName: "Jurby"},
        {StateID: 1373, CountryID: 104, DisplayName: "Laxey"},
        {StateID: 1374, CountryID: 104, DisplayName: "Lezayre"},
        {StateID: 1375, CountryID: 104, DisplayName: "Lonan"},
        {StateID: 1376, CountryID: 104, DisplayName: "Malew"},
        {StateID: 1377, CountryID: 104, DisplayName: "Marown"},
        {StateID: 1378, CountryID: 104, DisplayName: "Maughold"},
        {StateID: 1379, CountryID: 104, DisplayName: "Michael"},
        {StateID: 1380, CountryID: 104, DisplayName: "Onchan"},
        {StateID: 1381, CountryID: 104, DisplayName: "Patrick"},
        {StateID: 1382, CountryID: 104, DisplayName: "Peel"},
        {StateID: 1383, CountryID: 104, DisplayName: "Port Erin"},
        {StateID: 1384, CountryID: 104, DisplayName: "Port St Mary"},
        {StateID: 1385, CountryID: 104, DisplayName: "Ramsey"},
        {StateID: 1386, CountryID: 104, DisplayName: "Rushen"},
        {StateID: 1387, CountryID: 104, DisplayName: "Santon"},
        {StateID: 1418, CountryID: 105, DisplayName: "Andaman and Nicobar"},
        {StateID: 1417, CountryID: 105, DisplayName: "Andhra Pradesh"},
        {StateID: 1416, CountryID: 105, DisplayName: "Arunachal Pradesh"},
        {StateID: 1415, CountryID: 105, DisplayName: "Assam"},
        {StateID: 1414, CountryID: 105, DisplayName: "Bihar"},
        {StateID: 1413, CountryID: 105, DisplayName: "Chandigarh"},
        {StateID: 1419, CountryID: 105, DisplayName: "Chhattisgarh"},
        {StateID: 1423, CountryID: 105, DisplayName: "Dadra and Nagar Haveli and Daman and Diu"},
        {StateID: 1412, CountryID: 105, DisplayName: "Delhi"},
        {StateID: 1411, CountryID: 105, DisplayName: "Goa"},
        {StateID: 1410, CountryID: 105, DisplayName: "Gujarat"},
        {StateID: 1409, CountryID: 105, DisplayName: "Haryana"},
        {StateID: 1408, CountryID: 105, DisplayName: "Himachal Pradesh"},
        {StateID: 1407, CountryID: 105, DisplayName: "Jammu and Kashmir"},
        {StateID: 1420, CountryID: 105, DisplayName: "Jharkhand"},
        {StateID: 1406, CountryID: 105, DisplayName: "Karnataka"},
        {StateID: 1405, CountryID: 105, DisplayName: "Kerala"},
        {StateID: 1404, CountryID: 105, DisplayName: "Laccadives"},
        {StateID: 1422, CountryID: 105, DisplayName: "Ladakh"},
        {StateID: 1403, CountryID: 105, DisplayName: "Madhya Pradesh"},
        {StateID: 1402, CountryID: 105, DisplayName: "Maharashtra"},
        {StateID: 1401, CountryID: 105, DisplayName: "Manipur"},
        {StateID: 1400, CountryID: 105, DisplayName: "Meghalaya"},
        {StateID: 1399, CountryID: 105, DisplayName: "Mizoram"},
        {StateID: 1398, CountryID: 105, DisplayName: "Nagaland"},
        {StateID: 1397, CountryID: 105, DisplayName: "Odisha"},
        {StateID: 1396, CountryID: 105, DisplayName: "Puducherry"},
        {StateID: 1395, CountryID: 105, DisplayName: "Punjab"},
        {StateID: 1394, CountryID: 105, DisplayName: "Rajasthan"},
        {StateID: 1393, CountryID: 105, DisplayName: "Sikkim"},
        {StateID: 1392, CountryID: 105, DisplayName: "Tamil Nadu"},
        {StateID: 1391, CountryID: 105, DisplayName: "Telangana"},
        {StateID: 1390, CountryID: 105, DisplayName: "Tripura"},
        {StateID: 1389, CountryID: 105, DisplayName: "Uttar Pradesh"},
        {StateID: 1421, CountryID: 105, DisplayName: "Uttarakhand"},
        {StateID: 1388, CountryID: 105, DisplayName: "West Bengal"},
        {StateID: 1441, CountryID: 107, DisplayName: "Al Anbar"},
        {StateID: 1440, CountryID: 107, DisplayName: "Al Muthanná"},
        {StateID: 1439, CountryID: 107, DisplayName: "Al Qadisiyah"},
        {StateID: 1438, CountryID: 107, DisplayName: "An Najaf"},
        {StateID: 1430, CountryID: 107, DisplayName: "Arbil"},
        {StateID: 1435, CountryID: 107, DisplayName: "Babil"},
        {StateID: 1434, CountryID: 107, DisplayName: "Baghdad"},
        {StateID: 1424, CountryID: 107, DisplayName: "Basra"},
        {StateID: 1432, CountryID: 107, DisplayName: "Dhi Qar"},
        {StateID: 1431, CountryID: 107, DisplayName: "Diyalá"},
        {StateID: 1433, CountryID: 107, DisplayName: "Duhok"},
        {StateID: 1442, CountryID: 107, DisplayName: "Halabja Governorate"},
        {StateID: 1429, CountryID: 107, DisplayName: "Karbala'"},
        {StateID: 1436, CountryID: 107, DisplayName: "Kirkuk"},
        {StateID: 1428, CountryID: 107, DisplayName: "Maysan"},
        {StateID: 1427, CountryID: 107, DisplayName: "Nineveh"},
        {StateID: 1426, CountryID: 107, DisplayName: "Salah ad Din"},
        {StateID: 1437, CountryID: 107, DisplayName: "Sulaymaniyah"},
        {StateID: 1425, CountryID: 107, DisplayName: "Wasit"},
        {StateID: 1473, CountryID: 108, DisplayName: "Alborz Province"},
        {StateID: 1464, CountryID: 108, DisplayName: "Ardabil"},
        {StateID: 1461, CountryID: 108, DisplayName: "Bushehr"},
        {StateID: 1460, CountryID: 108, DisplayName: "Chaharmahal and Bakhtiari"},
        {StateID: 1462, CountryID: 108, DisplayName: "East Azerbaijan"},
        {StateID: 1459, CountryID: 108, DisplayName: "Fars"},
        {StateID: 1458, CountryID: 108, DisplayName: "Gilan"},
        {StateID: 1466, CountryID: 108, DisplayName: "Golestan"},
        {StateID: 1457, CountryID: 108, DisplayName: "Hamadan"},
        {StateID: 1456, CountryID: 108, DisplayName: "Hormozgan"},
        {StateID: 1455, CountryID: 108, DisplayName: "Ilam Province"},
        {StateID: 1465, CountryID: 108, DisplayName: "Isfahan"},
        {StateID: 1454, CountryID: 108, DisplayName: "Kerman"},
        {StateID: 1453, CountryID: 108, DisplayName: "Kermanshah"},
        {StateID: 1452, CountryID: 108, DisplayName: "Khuzestan"},
        {StateID: 1451, CountryID: 108, DisplayName: "Kohgiluyeh and Boyer-Ahmad"},
        {StateID: 1450, CountryID: 108, DisplayName: "Kordestan"},
        {StateID: 1449, CountryID: 108, DisplayName: "Lorestan Province"},
        {StateID: 1448, CountryID: 108, DisplayName: "Markazi"},
        {StateID: 1447, CountryID: 108, DisplayName: "Mazandaran"},
        {StateID: 1472, CountryID: 108, DisplayName: "North Khorasan"},
        {StateID: 1467, CountryID: 108, DisplayName: "Qazvin"},
        {StateID: 1468, CountryID: 108, DisplayName: "Qom"},
        {StateID: 1471, CountryID: 108, DisplayName: "Razavi Khorasan"},
        {StateID: 1446, CountryID: 108, DisplayName: "Semnan"},
        {StateID: 1469, CountryID: 108, DisplayName: "Sistan and Baluchestan"},
        {StateID: 1470, CountryID: 108, DisplayName: "South Khorasan Province"},
        {StateID: 1443, CountryID: 108, DisplayName: "Tehran"},
        {StateID: 1463, CountryID: 108, DisplayName: "West Azerbaijan"},
        {StateID: 1445, CountryID: 108, DisplayName: "Yazd"},
        {StateID: 1444, CountryID: 108, DisplayName: "Zanjan"},
        {StateID: 1478, CountryID: 109, DisplayName: "Capital Region"},
        {StateID: 1476, CountryID: 109, DisplayName: "East"},
        {StateID: 1475, CountryID: 109, DisplayName: "Northeast"},
        {StateID: 1474, CountryID: 109, DisplayName: "Northwest"},
        {StateID: 1477, CountryID: 109, DisplayName: "South"},
        {StateID: 1479, CountryID: 109, DisplayName: "Southern Peninsula"},
        {StateID: 1480, CountryID: 109, DisplayName: "West"},
        {StateID: 1481, CountryID: 109, DisplayName: "Westfjords"},
        {StateID: 1501, CountryID: 110, DisplayName: "Abruzzo"},
        {StateID: 1486, CountryID: 110, DisplayName: "Aosta Valley"},
        {StateID: 1490, CountryID: 110, DisplayName: "Apulia"},
        {StateID: 1500, CountryID: 110, DisplayName: "Basilicate"},
        {StateID: 1484, CountryID: 110, DisplayName: "Calabria"},
        {StateID: 1499, CountryID: 110, DisplayName: "Campania"},
        {StateID: 1498, CountryID: 110, DisplayName: "Emilia-Romagna"},
        {StateID: 1497, CountryID: 110, DisplayName: "Friuli Venezia Giulia"},
        {StateID: 1496, CountryID: 110, DisplayName: "Latium"},
        {StateID: 1495, CountryID: 110, DisplayName: "Liguria"},
        {StateID: 1494, CountryID: 110, DisplayName: "Lombardy"},
        {StateID: 1492, CountryID: 110, DisplayName: "Molise"},
        {StateID: 1491, CountryID: 110, DisplayName: "Piedmont"},
        {StateID: 1483, CountryID: 110, DisplayName: "Sardinia"},
        {StateID: 1482, CountryID: 110, DisplayName: "Sicily"},
        {StateID: 1493, CountryID: 110, DisplayName: "The Marches"},
        {StateID: 1488, CountryID: 110, DisplayName: "Trentino-Alto Adige"},
        {StateID: 1489, CountryID: 110, DisplayName: "Tuscany"},
        {StateID: 1487, CountryID: 110, DisplayName: "Umbria"},
        {StateID: 1485, CountryID: 110, DisplayName: "Veneto"},
        {StateID: 1505, CountryID: 111, DisplayName: "Grouville"},
        {StateID: 1502, CountryID: 111, DisplayName: "St Clement"},
        {StateID: 1513, CountryID: 111, DisplayName: "St Helier"},
        {StateID: 1510, CountryID: 111, DisplayName: "St John"},
        {StateID: 1507, CountryID: 111, DisplayName: "St Lawrence"},
        {StateID: 1512, CountryID: 111, DisplayName: "St Martîn"},
        {StateID: 1506, CountryID: 111, DisplayName: "St Mary"},
        {StateID: 1509, CountryID: 111, DisplayName: "St Ouen"},
        {StateID: 1508, CountryID: 111, DisplayName: "St Peter"},
        {StateID: 1503, CountryID: 111, DisplayName: "St Saviour"},
        {StateID: 1504, CountryID: 111, DisplayName: "St. Brelade"},
        {StateID: 1511, CountryID: 111, DisplayName: "Trinity"},
        {StateID: 1527, CountryID: 112, DisplayName: "Clarendon"},
        {StateID: 1526, CountryID: 112, DisplayName: "Hanover"},
        {StateID: 1525, CountryID: 112, DisplayName: "Kingston"},
        {StateID: 1524, CountryID: 112, DisplayName: "Manchester"},
        {StateID: 1523, CountryID: 112, DisplayName: "Portland"},
        {StateID: 1520, CountryID: 112, DisplayName: "Saint Catherine"},
        {StateID: 1521, CountryID: 112, DisplayName: "St Ann"},
        {StateID: 1522, CountryID: 112, DisplayName: "St. Andrew"},
        {StateID: 1519, CountryID: 112, DisplayName: "St. Elizabeth"},
        {StateID: 1518, CountryID: 112, DisplayName: "St. James"},
        {StateID: 1517, CountryID: 112, DisplayName: "St. Mary"},
        {StateID: 1516, CountryID: 112, DisplayName: "St. Thomas"},
        {StateID: 1515, CountryID: 112, DisplayName: "Trelawny"},
        {StateID: 1514, CountryID: 112, DisplayName: "Westmoreland"},
        {StateID: 1536, CountryID: 113, DisplayName: "Ajlun"},
        {StateID: 1532, CountryID: 113, DisplayName: "Amman"},
        {StateID: 1538, CountryID: 113, DisplayName: "Aqaba"},
        {StateID: 1535, CountryID: 113, DisplayName: "Balqa"},
        {StateID: 1529, CountryID: 113, DisplayName: "Irbid"},
        {StateID: 1537, CountryID: 113, DisplayName: "Jerash"},
        {StateID: 1534, CountryID: 113, DisplayName: "Karak"},
        {StateID: 1528, CountryID: 113, DisplayName: "Ma’an"},
        {StateID: 1539, CountryID: 113, DisplayName: "Madaba"},
        {StateID: 1533, CountryID: 113, DisplayName: "Mafraq"},
        {StateID: 1531, CountryID: 113, DisplayName: "Tafielah"},
        {StateID: 1530, CountryID: 113, DisplayName: "Zarqa"},
        {StateID: 1577, CountryID: 114, DisplayName: "Aichi"},
        {StateID: 1584, CountryID: 114, DisplayName: "Akita"},
        {StateID: 1586, CountryID: 114, DisplayName: "Aomori"},
        {StateID: 1583, CountryID: 114, DisplayName: "Chiba"},
        {StateID: 1576, CountryID: 114, DisplayName: "Ehime"},
        {StateID: 1575, CountryID: 114, DisplayName: "Fukui"},
        {StateID: 1574, CountryID: 114, DisplayName: "Fukuoka"},
        {StateID: 1582, CountryID: 114, DisplayName: "Fukushima"},
        {StateID: 1573, CountryID: 114, DisplayName: "Gifu"},
        {StateID: 1572, CountryID: 114, DisplayName: "Gunma"},
        {StateID: 1571, CountryID: 114, DisplayName: "Hiroshima"},
        {StateID: 1585, CountryID: 114, DisplayName: "Hokkaido"},
        {StateID: 1570, CountryID: 114, DisplayName: "Hyogo"},
        {StateID: 1581, CountryID: 114, DisplayName: "Ibaraki"},
        {StateID: 1569, CountryID: 114, DisplayName: "Ishikawa"},
        {StateID: 1580, CountryID: 114, DisplayName: "Iwate"},
        {StateID: 1568, CountryID: 114, DisplayName: "Kagawa"},
        {StateID: 1567, CountryID: 114, DisplayName: "Kagoshima"},
        {StateID: 1566, CountryID: 114, DisplayName: "Kanagawa"},
        {StateID: 1565, CountryID: 114, DisplayName: "Kochi"},
        {StateID: 1564, CountryID: 114, DisplayName: "Kumamoto"},
        {StateID: 1563, CountryID: 114, DisplayName: "Kyoto"},
        {StateID: 1562, CountryID: 114, DisplayName: "Mie"},
        {StateID: 1579, CountryID: 114, DisplayName: "Miyagi"},
        {StateID: 1561, CountryID: 114, DisplayName: "Miyazaki"},
        {StateID: 1560, CountryID: 114, DisplayName: "Nagano"},
        {StateID: 1559, CountryID: 114, DisplayName: "Nagasaki"},
        {StateID: 1558, CountryID: 114, DisplayName: "Nara"},
        {StateID: 1557, CountryID: 114, DisplayName: "Niigata"},
        {StateID: 1556, CountryID: 114, DisplayName: "Oita"},
        {StateID: 1555, CountryID: 114, DisplayName: "Okayama"},
        {StateID: 1554, CountryID: 114, DisplayName: "Okinawa"},
        {StateID: 1553, CountryID: 114, DisplayName: "Osaka"},
        {StateID: 1552, CountryID: 114, DisplayName: "Saga"},
        {StateID: 1551, CountryID: 114, DisplayName: "Saitama"},
        {StateID: 1550, CountryID: 114, DisplayName: "Shiga"},
        {StateID: 1549, CountryID: 114, DisplayName: "Shimane"},
        {StateID: 1548, CountryID: 114, DisplayName: "Shizuoka"},
        {StateID: 1547, CountryID: 114, DisplayName: "Tochigi"},
        {StateID: 1546, CountryID: 114, DisplayName: "Tokushima"},
        {StateID: 1545, CountryID: 114, DisplayName: "Tokyo"},
        {StateID: 1544, CountryID: 114, DisplayName: "Tottori"},
        {StateID: 1543, CountryID: 114, DisplayName: "Toyama"},
        {StateID: 1542, CountryID: 114, DisplayName: "Wakayama"},
        {StateID: 1578, CountryID: 114, DisplayName: "Yamagata"},
        {StateID: 1541, CountryID: 114, DisplayName: "Yamaguchi"},
        {StateID: 1540, CountryID: 114, DisplayName: "Yamanashi"},
        {StateID: 1617, CountryID: 115, DisplayName: "Baringo"},
        {StateID: 1628, CountryID: 115, DisplayName: "Bomet"},
        {StateID: 1616, CountryID: 115, DisplayName: "Bungoma"},
        {StateID: 1615, CountryID: 115, DisplayName: "Busia"},
        {StateID: 1623, CountryID: 115, DisplayName: "Elegeyo-Marakwet"},
        {StateID: 1614, CountryID: 115, DisplayName: "Embu"},
        {StateID: 1613, CountryID: 115, DisplayName: "Garissa"},
        {StateID: 1627, CountryID: 115, DisplayName: "Homa Bay"},
        {StateID: 1612, CountryID: 115, DisplayName: "Isiolo"},
        {StateID: 1625, CountryID: 115, DisplayName: "Kajiado"},
        {StateID: 1611, CountryID: 115, DisplayName: "Kakamega"},
        {StateID: 1610, CountryID: 115, DisplayName: "Kericho"},
        {StateID: 1609, CountryID: 115, DisplayName: "Kiambu"},
        {StateID: 1608, CountryID: 115, DisplayName: "Kilifi"},
        {StateID: 1607, CountryID: 115, DisplayName: "Kirinyaga"},
        {StateID: 1606, CountryID: 115, DisplayName: "Kisii"},
        {StateID: 1605, CountryID: 115, DisplayName: "Kisumu"},
        {StateID: 1604, CountryID: 115, DisplayName: "Kitui"},
        {StateID: 1603, CountryID: 115, DisplayName: "Kwale"},
        {StateID: 1602, CountryID: 115, DisplayName: "Laikipia"},
        {StateID: 1620, CountryID: 115, DisplayName: "Lamu"},
        {StateID: 1621, CountryID: 115, DisplayName: "Machakos"},
        {StateID: 1622, CountryID: 115, DisplayName: "Makueni"},
        {StateID: 1601, CountryID: 115, DisplayName: "Mandera"},
        {StateID: 1600, CountryID: 115, DisplayName: "Marsabit"},
        {StateID: 1599, CountryID: 115, DisplayName: "Meru"},
        {StateID: 1629, CountryID: 115, DisplayName: "Migori"},
        {StateID: 1598, CountryID: 115, DisplayName: "Mombasa"},
        {StateID: 1597, CountryID: 115, DisplayName: "Murang'A"},
        {StateID: 1596, CountryID: 115, DisplayName: "Nairobi Area"},
        {StateID: 1630, CountryID: 115, DisplayName: "Nakuru"},
        {StateID: 1633, CountryID: 115, DisplayName: "Nandi"},
        {StateID: 1631, CountryID: 115, DisplayName: "Narok"},
        {StateID: 1632, CountryID: 115, DisplayName: "Nyamira"},
        {StateID: 1618, CountryID: 115, DisplayName: "Nyandarua"},
        {StateID: 1626, CountryID: 115, DisplayName: "Nyeri"},
        {StateID: 1595, CountryID: 115, DisplayName: "Samburu"},
        {StateID: 1594, CountryID: 115, DisplayName: "Siaya"},
        {StateID: 1624, CountryID: 115, DisplayName: "Taita Taveta"},
        {StateID: 1593, CountryID: 115, DisplayName: "Tana River"},
        {StateID: 1592, CountryID: 115, DisplayName: "Tharaka - Nithi"},
        {StateID: 1591, CountryID: 115, DisplayName: "Trans Nzoia"},
        {StateID: 1590, CountryID: 115, DisplayName: "Turkana"},
        {StateID: 1589, CountryID: 115, DisplayName: "Uasin Gishu"},
        {StateID: 1619, CountryID: 115, DisplayName: "Vihiga"},
        {StateID: 1588, CountryID: 115, DisplayName: "Wajir"},
        {StateID: 1587, CountryID: 115, DisplayName: "West Pokot"},
        {StateID: 1635, CountryID: 116, DisplayName: "Batken"},
        {StateID: 1639, CountryID: 116, DisplayName: "Bishkek"},
        {StateID: 1641, CountryID: 116, DisplayName: "Chüy"},
        {StateID: 1638, CountryID: 116, DisplayName: "Issyk-Kul"},
        {StateID: 1640, CountryID: 116, DisplayName: "Jalal-Abad"},
        {StateID: 1637, CountryID: 116, DisplayName: "Naryn"},
        {StateID: 1634, CountryID: 116, DisplayName: "Osh"},
        {StateID: 1642, CountryID: 116, DisplayName: "Osh City"},
        {StateID: 1636, CountryID: 116, DisplayName: "Talas"},
        {StateID: 1666, CountryID: 117, DisplayName: "Banteay Meanchey"},
        {StateID: 1644, CountryID: 117, DisplayName: "Battambang"},
        {StateID: 1664, CountryID: 117, DisplayName: "Kampong Cham"},
        {StateID: 1663, CountryID: 117, DisplayName: "Kampong Chhnang"},
        {StateID: 1662, CountryID: 117, DisplayName: "Kampong Speu"},
        {StateID: 1661, CountryID: 117, DisplayName: "Kampong Thom"},
        {StateID: 1660, CountryID: 117, DisplayName: "Kampot"},
        {StateID: 1659, CountryID: 117, DisplayName: "Kandal"},
        {StateID: 1657, CountryID: 117, DisplayName: "Kep"},
        {StateID: 1658, CountryID: 117, DisplayName: "Koh Kong"},
        {StateID: 1656, CountryID: 117, DisplayName: "Kratie"},
        {StateID: 1655, CountryID: 117, DisplayName: "Mondolkiri"},
        {StateID: 1648, CountryID: 117, DisplayName: "Otâr Méanchey"},
        {StateID: 1654, CountryID: 117, DisplayName: "Pailin"},
        {StateID: 1653, CountryID: 117, DisplayName: "Phnom Penh"},
        {StateID: 1665, CountryID: 117, DisplayName: "Preah Sihanouk"},
        {StateID: 1652, CountryID: 117, DisplayName: "Preah Vihear"},
        {StateID: 1651, CountryID: 117, DisplayName: "Prey Veng"},
        {StateID: 1643, CountryID: 117, DisplayName: "Pursat"},
        {StateID: 1650, CountryID: 117, DisplayName: "Ratanakiri"},
        {StateID: 1649, CountryID: 117, DisplayName: "Siem Reap"},
        {StateID: 1647, CountryID: 117, DisplayName: "Stung Treng"},
        {StateID: 1646, CountryID: 117, DisplayName: "Svay Rieng"},
        {StateID: 1645, CountryID: 117, DisplayName: "Takeo"},
        {StateID: 1667, CountryID: 117, DisplayName: "Tboung Khmum"},
        {StateID: 1668, CountryID: 118, DisplayName: "Gilbert Islands"},
        {StateID: 1669, CountryID: 118, DisplayName: "Line Islands"},
        {StateID: 1670, CountryID: 118, DisplayName: "Phoenix Islands"},
        {StateID: 1673, CountryID: 119, DisplayName: "Anjouan"},
        {StateID: 1672, CountryID: 119, DisplayName: "Grande Comore"},
        {StateID: 1671, CountryID: 119, DisplayName: "Mohéli"},
        {StateID: 1687, CountryID: 120, DisplayName: "Christ Church Nichola Town"},
        {StateID: 1675, CountryID: 120, DisplayName: "Middle Island"},
        {StateID: 1686, CountryID: 120, DisplayName: "Saint Anne Sandy Point"},
        {StateID: 1685, CountryID: 120, DisplayName: "Saint George Basseterre"},
        {StateID: 1684, CountryID: 120, DisplayName: "Saint George Gingerland"},
        {StateID: 1683, CountryID: 120, DisplayName: "Saint James Windwa"},
        {StateID: 1682, CountryID: 120, DisplayName: "Saint John Capesterre"},
        {StateID: 1681, CountryID: 120, DisplayName: "Saint John Figtree"},
        {StateID: 1680, CountryID: 120, DisplayName: "Saint Mary Cayon"},
        {StateID: 1679, CountryID: 120, DisplayName: "Saint Paul Capesterre"},
        {StateID: 1678, CountryID: 120, DisplayName: "Saint Paul Charlestown"},
        {StateID: 1677, CountryID: 120, DisplayName: "Saint Peter Basseterre"},
        {StateID: 1676, CountryID: 120, DisplayName: "Saint Thomas Lowland"},
        {StateID: 1674, CountryID: 120, DisplayName: "Trinity Palmetto Point"},
        {StateID: 1697, CountryID: 121, DisplayName: "Chagang-do"},
        {StateID: 1696, CountryID: 121, DisplayName: "Hamgyong-bukto"},
        {StateID: 1694, CountryID: 121, DisplayName: "Hamgyong-namdo"},
        {StateID: 1693, CountryID: 121, DisplayName: "Hwanghae-bukto"},
        {StateID: 1692, CountryID: 121, DisplayName: "Hwanghae-namdo"},
        {StateID: 1691, CountryID: 121, DisplayName: "Kangwon-do"},
        {StateID: 1690, CountryID: 121, DisplayName: "P'yongan-bukto"},
        {StateID: 1688, CountryID: 121, DisplayName: "Pyongyang"},
        {StateID: 1698, CountryID: 121, DisplayName: "Rason"},
        {StateID: 1689, CountryID: 121, DisplayName: "South Pyongan"},
        {StateID: 1695, CountryID: 121, DisplayName: "Yanggang-do"},
        {StateID: 1703, CountryID: 122, DisplayName: "Busan"},
        {StateID: 1709, CountryID: 122, DisplayName: "Chungcheongnam-do"},
        {StateID: 1701, CountryID: 122, DisplayName: "Daegu"},
        {StateID: 1700, CountryID: 122, DisplayName: "Daejeon"},
        {StateID: 1707, CountryID: 122, DisplayName: "Gangwon-do"},
        {StateID: 1706, CountryID: 122, DisplayName: "Gwangju"},
        {StateID: 1705, CountryID: 122, DisplayName: "Gyeonggi-do"},
        {StateID: 1704, CountryID: 122, DisplayName: "Gyeongsangbuk-do"},
        {StateID: 1714, CountryID: 122, DisplayName: "Gyeongsangnam-do"},
        {StateID: 1708, CountryID: 122, DisplayName: "Incheon"},
        {StateID: 1713, CountryID: 122, DisplayName: "Jeju-do"},
        {StateID: 1712, CountryID: 122, DisplayName: "Jeollabuk-do"},
        {StateID: 1711, CountryID: 122, DisplayName: "Jeollanam-do"},
        {StateID: 1710, CountryID: 122, DisplayName: "North Chungcheong"},
        {StateID: 1715, CountryID: 122, DisplayName: "Sejong-si"},
        {StateID: 1702, CountryID: 122, DisplayName: "Seoul"},
        {StateID: 1699, CountryID: 122, DisplayName: "Ulsan"},
        {StateID: 1720, CountryID: 124, DisplayName: "Al A?madi"},
        {StateID: 1717, CountryID: 124, DisplayName: "Al Asimah"},
        {StateID: 1719, CountryID: 124, DisplayName: "Al Farwaniyah"},
        {StateID: 1718, CountryID: 124, DisplayName: "Al Jahra'"},
        {StateID: 1716, CountryID: 124, DisplayName: "Hawalli"},
        {StateID: 1721, CountryID: 124, DisplayName: "Mubarak al Kabir"},
        {StateID: 1724, CountryID: 125, DisplayName: "Bodden Town"},
        {StateID: 1726, CountryID: 125, DisplayName: "East End"},
        {StateID: 1722, CountryID: 125, DisplayName: "George Town"},
        {StateID: 1725, CountryID: 125, DisplayName: "North Side"},
        {StateID: 1727, CountryID: 125, DisplayName: "Sister Island"},
        {StateID: 1723, CountryID: 125, DisplayName: "West Bay"},
        {StateID: 1741, CountryID: 126, DisplayName: "Almaty"},
        {StateID: 1742, CountryID: 126, DisplayName: "Almaty Oblysy"},
        {StateID: 1733, CountryID: 126, DisplayName: "Aqmola"},
        {StateID: 1731, CountryID: 126, DisplayName: "Aqtöbe"},
        {StateID: 1730, CountryID: 126, DisplayName: "Atyrau"},
        {StateID: 1744, CountryID: 126, DisplayName: "Baikonur"},
        {StateID: 1728, CountryID: 126, DisplayName: "Batys Qazaqstan"},
        {StateID: 1732, CountryID: 126, DisplayName: "East Kazakhstan"},
        {StateID: 1738, CountryID: 126, DisplayName: "Karaganda"},
        {StateID: 1729, CountryID: 126, DisplayName: "Mangghystau"},
        {StateID: 1734, CountryID: 126, DisplayName: "North Kazakhstan"},
        {StateID: 1745, CountryID: 126, DisplayName: "Nur-Sultan"},
        {StateID: 1735, CountryID: 126, DisplayName: "Pavlodar Region"},
        {StateID: 1737, CountryID: 126, DisplayName: "Qostanay"},
        {StateID: 1736, CountryID: 126, DisplayName: "Qyzylorda"},
        {StateID: 1743, CountryID: 126, DisplayName: "Shymkent"},
        {StateID: 1740, CountryID: 126, DisplayName: "South Kazakhstan"},
        {StateID: 1739, CountryID: 126, DisplayName: "Zhambyl"},
        {StateID: 1758, CountryID: 127, DisplayName: "Attapu"},
        {StateID: 1760, CountryID: 127, DisplayName: "Bokeo"},
        {StateID: 1761, CountryID: 127, DisplayName: "Bolikhamsai"},
        {StateID: 1757, CountryID: 127, DisplayName: "Champasak"},
        {StateID: 1756, CountryID: 127, DisplayName: "Houaphan"},
        {StateID: 1755, CountryID: 127, DisplayName: "Khammouan"},
        {StateID: 1753, CountryID: 127, DisplayName: "Louangphabang"},
        {StateID: 1754, CountryID: 127, DisplayName: "Loungnamtha"},
        {StateID: 1752, CountryID: 127, DisplayName: "Oudômxai"},
        {StateID: 1751, CountryID: 127, DisplayName: "Phôngsali"},
        {StateID: 1750, CountryID: 127, DisplayName: "Salavan"},
        {StateID: 1749, CountryID: 127, DisplayName: "Savannahkhét"},
        {StateID: 1748, CountryID: 127, DisplayName: "Vientiane"},
        {StateID: 1762, CountryID: 127, DisplayName: "Vientiane Prefecture"},
        {StateID: 1763, CountryID: 127, DisplayName: "Xaisomboun"},
        {StateID: 1759, CountryID: 127, DisplayName: "Xékong"},
        {StateID: 1747, CountryID: 127, DisplayName: "Xiagnabouli"},
        {StateID: 1746, CountryID: 127, DisplayName: "Xiangkhoang"},
        {StateID: 1770, CountryID: 128, DisplayName: "Aakkâr"},
        {StateID: 1771, CountryID: 128, DisplayName: "Baalbek-Hermel"},
        {StateID: 1768, CountryID: 128, DisplayName: "Béqaa"},
        {StateID: 1765, CountryID: 128, DisplayName: "Beyrouth"},
        {StateID: 1766, CountryID: 128, DisplayName: "Liban-Nord"},
        {StateID: 1764, CountryID: 128, DisplayName: "Mont-Liban"},
        {StateID: 1769, CountryID: 128, DisplayName: "Nabatîyé"},
        {StateID: 1767, CountryID: 128, DisplayName: "South Governorate"},
        {StateID: 1780, CountryID: 129, DisplayName: "Anse-la-Raye"},
        {StateID: 1781, CountryID: 129, DisplayName: "Canaries"},
        {StateID: 1779, CountryID: 129, DisplayName: "Castries"},
        {StateID: 1778, CountryID: 129, DisplayName: "Choiseul"},
        {StateID: 1777, CountryID: 129, DisplayName: "Dennery"},
        {StateID: 1776, CountryID: 129, DisplayName: "Gros-Islet"},
        {StateID: 1775, CountryID: 129, DisplayName: "Laborie"},
        {StateID: 1774, CountryID: 129, DisplayName: "Micoud"},
        {StateID: 1773, CountryID: 129, DisplayName: "Soufrière"},
        {StateID: 1772, CountryID: 129, DisplayName: "Vieux-Fort"},
        {StateID: 1792, CountryID: 130, DisplayName: "Balzers"},
        {StateID: 1791, CountryID: 130, DisplayName: "Eschen"},
        {StateID: 1790, CountryID: 130, DisplayName: "Gamprin"},
        {StateID: 1789, CountryID: 130, DisplayName: "Mauren"},
        {StateID: 1788, CountryID: 130, DisplayName: "Planken"},
        {StateID: 1787, CountryID: 130, DisplayName: "Ruggell"},
        {StateID: 1786, CountryID: 130, DisplayName: "Schaan"},
        {StateID: 1785, CountryID: 130, DisplayName: "Schellenberg"},
        {StateID: 1784, CountryID: 130, DisplayName: "Triesen"},
        {StateID: 1783, CountryID: 130, DisplayName: "Triesenberg"},
        {StateID: 1782, CountryID: 130, DisplayName: "Vaduz"},
        {StateID: 1799, CountryID: 131, DisplayName: "Central"},
        {StateID: 1801, CountryID: 131, DisplayName: "Eastern Province"},
        {StateID: 1798, CountryID: 131, DisplayName: "North Central"},
        {StateID: 1797, CountryID: 131, DisplayName: "North Western"},
        {StateID: 1800, CountryID: 131, DisplayName: "Northern Province"},
        {StateID: 1796, CountryID: 131, DisplayName: "Sabaragamuwa"},
        {StateID: 1795, CountryID: 131, DisplayName: "Southern"},
        {StateID: 1794, CountryID: 131, DisplayName: "Uva"},
        {StateID: 1793, CountryID: 131, DisplayName: "Western"},
        {StateID: 1811, CountryID: 132, DisplayName: "Bomi"},
        {StateID: 1810, CountryID: 132, DisplayName: "Bong"},
        {StateID: 1815, CountryID: 132, DisplayName: "Gbarpolu"},
        {StateID: 1809, CountryID: 132, DisplayName: "Grand Bassa"},
        {StateID: 1808, CountryID: 132, DisplayName: "Grand Cape Mount"},
        {StateID: 1807, CountryID: 132, DisplayName: "Grand Gedeh"},
        {StateID: 1812, CountryID: 132, DisplayName: "Grand Kru"},
        {StateID: 1806, CountryID: 132, DisplayName: "Lofa"},
        {StateID: 1813, CountryID: 132, DisplayName: "Margibi"},
        {StateID: 1805, CountryID: 132, DisplayName: "Maryland"},
        {StateID: 1804, CountryID: 132, DisplayName: "Montserrado"},
        {StateID: 1803, CountryID: 132, DisplayName: "Nimba"},
        {StateID: 1814, CountryID: 132, DisplayName: "River Cess"},
        {StateID: 1816, CountryID: 132, DisplayName: "River Gee"},
        {StateID: 1802, CountryID: 132, DisplayName: "Sinoe"},
        {StateID: 1826, CountryID: 133, DisplayName: "Berea"},
        {StateID: 1825, CountryID: 133, DisplayName: "Butha-Buthe"},
        {StateID: 1824, CountryID: 133, DisplayName: "Leribe"},
        {StateID: 1823, CountryID: 133, DisplayName: "Mafeteng"},
        {StateID: 1822, CountryID: 133, DisplayName: "Maseru"},
        {StateID: 1821, CountryID: 133, DisplayName: "Mohale's Hoek"},
        {StateID: 1820, CountryID: 133, DisplayName: "Mokhotlong"},
        {StateID: 1819, CountryID: 133, DisplayName: "Qacha's Nek"},
        {StateID: 1818, CountryID: 133, DisplayName: "Quthing"},
        {StateID: 1817, CountryID: 133, DisplayName: "Thaba-Tseka"},
        {StateID: 1827, CountryID: 134, DisplayName: "Alytus"},
        {StateID: 1828, CountryID: 134, DisplayName: "Kaunas"},
        {StateID: 1829, CountryID: 134, DisplayName: "Klaipeda County"},
        {StateID: 1830, CountryID: 134, DisplayName: "Marijampole County"},
        {StateID: 1831, CountryID: 134, DisplayName: "Panevežys"},
        {StateID: 1832, CountryID: 134, DisplayName: "Siauliai"},
        {StateID: 1833, CountryID: 134, DisplayName: "Taurage County"},
        {StateID: 1834, CountryID: 134, DisplayName: "Telsiai"},
        {StateID: 1835, CountryID: 134, DisplayName: "Utena"},
        {StateID: 1836, CountryID: 134, DisplayName: "Vilnius"},
        {StateID: 1848, CountryID: 135, DisplayName: "Capellen"},
        {StateID: 1847, CountryID: 135, DisplayName: "Clervaux"},
        {StateID: 1846, CountryID: 135, DisplayName: "Diekirch"},
        {StateID: 1845, CountryID: 135, DisplayName: "Echternach"},
        {StateID: 1844, CountryID: 135, DisplayName: "Esch-sur-Alzette"},
        {StateID: 1843, CountryID: 135, DisplayName: "Grevenmacher"},
        {StateID: 1842, CountryID: 135, DisplayName: "Luxembourg"},
        {StateID: 1841, CountryID: 135, DisplayName: "Mersch"},
        {StateID: 1840, CountryID: 135, DisplayName: "Redange"},
        {StateID: 1839, CountryID: 135, DisplayName: "Remich"},
        {StateID: 1838, CountryID: 135, DisplayName: "Vianden"},
        {StateID: 1837, CountryID: 135, DisplayName: "Wiltz"},
        {StateID: 1883, CountryID: 136, DisplayName: "Adaži"},
        {StateID: 1878, CountryID: 136, DisplayName: "Aizkraukles novads"},
        {StateID: 1877, CountryID: 136, DisplayName: "Aluksnes Novads"},
        {StateID: 1891, CountryID: 136, DisplayName: "Augšdaugava Municipality"},
        {StateID: 1876, CountryID: 136, DisplayName: "Balvu Novads"},
        {StateID: 1875, CountryID: 136, DisplayName: "Bauskas Rajons"},
        {StateID: 1874, CountryID: 136, DisplayName: "Cesu Rajons"},
        {StateID: 1873, CountryID: 136, DisplayName: "Daugavpils"},
        {StateID: 1872, CountryID: 136, DisplayName: "Dobeles novads"},
        {StateID: 1871, CountryID: 136, DisplayName: "Gulbenes novads"},
        {StateID: 1870, CountryID: 136, DisplayName: "Jekabpils Municipality"},
        {StateID: 1869, CountryID: 136, DisplayName: "Jelgava"},
        {StateID: 1868, CountryID: 136, DisplayName: "Jelgavas novads"},
        {StateID: 1867, CountryID: 136, DisplayName: "Jurmala"},
        {StateID: 1881, CountryID: 136, DisplayName: "Kekava"},
        {StateID: 1866, CountryID: 136, DisplayName: "Kraslavas novads"},
        {StateID: 1865, CountryID: 136, DisplayName: "Kuldigas novads"},
        {StateID: 1864, CountryID: 136, DisplayName: "Liepaja"},
        {StateID: 1863, CountryID: 136, DisplayName: "Limbažu novads"},
        {StateID: 1884, CountryID: 136, DisplayName: "Livani"},
        {StateID: 1862, CountryID: 136, DisplayName: "Ludzas novads"},
        {StateID: 1861, CountryID: 136, DisplayName: "Madona Municipality"},
        {StateID: 1879, CountryID: 136, DisplayName: "Marupe"},
        {StateID: 1860, CountryID: 136, DisplayName: "Ogre"},
        {StateID: 1880, CountryID: 136, DisplayName: "Olaine"},
        {StateID: 1859, CountryID: 136, DisplayName: "Preilu novads"},
        {StateID: 1858, CountryID: 136, DisplayName: "Rezekne"},
        {StateID: 1857, CountryID: 136, DisplayName: "Rezeknes Novads"},
        {StateID: 1856, CountryID: 136, DisplayName: "Riga"},
        {StateID: 1886, CountryID: 136, DisplayName: "Ropažu"},
        {StateID: 1882, CountryID: 136, DisplayName: "Salaspils"},
        {StateID: 1855, CountryID: 136, DisplayName: "Saldus Rajons"},
        {StateID: 1888, CountryID: 136, DisplayName: "Saulkrastu"},
        {StateID: 1887, CountryID: 136, DisplayName: "Sigulda"},
        {StateID: 1889, CountryID: 136, DisplayName: "Smiltene"},
        {StateID: 1890, CountryID: 136, DisplayName: "South Kurzeme Municipality"},
        {StateID: 1854, CountryID: 136, DisplayName: "Talsi Municipality"},
        {StateID: 1853, CountryID: 136, DisplayName: "Tukuma novads"},
        {StateID: 1852, CountryID: 136, DisplayName: "Valka"},
        {StateID: 1851, CountryID: 136, DisplayName: "Valmiera"},
        {StateID: 1885, CountryID: 136, DisplayName: "Varaklani"},
        {StateID: 1850, CountryID: 136, DisplayName: "Ventspils"},
        {StateID: 1849, CountryID: 136, DisplayName: "Ventspils Rajons"},
        {StateID: 1908, CountryID: 137, DisplayName: "Al Butnan"},
        {StateID: 1896, CountryID: 137, DisplayName: "Al Jabal al Akh?ar"},
        {StateID: 1910, CountryID: 137, DisplayName: "Al Jafarah"},
        {StateID: 1906, CountryID: 137, DisplayName: "Al Jufrah"},
        {StateID: 1895, CountryID: 137, DisplayName: "Al Kufrah"},
        {StateID: 1894, CountryID: 137, DisplayName: "Al Marj"},
        {StateID: 1911, CountryID: 137, DisplayName: "Al Marqab"},
        {StateID: 1912, CountryID: 137, DisplayName: "Al Wa?at"},
        {StateID: 1907, CountryID: 137, DisplayName: "An Nuqat al Khams"},
        {StateID: 1905, CountryID: 137, DisplayName: "Ash Shati'"},
        {StateID: 1904, CountryID: 137, DisplayName: "Az Zawiyah"},
        {StateID: 1893, CountryID: 137, DisplayName: "Banghazi"},
        {StateID: 1892, CountryID: 137, DisplayName: "Darnah"},
        {StateID: 1903, CountryID: 137, DisplayName: "Ghat"},
        {StateID: 1909, CountryID: 137, DisplayName: "Jabal al Gharbi"},
        {StateID: 1902, CountryID: 137, DisplayName: "Misratah"},
        {StateID: 1901, CountryID: 137, DisplayName: "Murzuq"},
        {StateID: 1900, CountryID: 137, DisplayName: "Nalut"},
        {StateID: 1899, CountryID: 137, DisplayName: "Sabha"},
        {StateID: 1898, CountryID: 137, DisplayName: "Surt"},
        {StateID: 1897, CountryID: 137, DisplayName: "Tripoli"},
        {StateID: 1913, CountryID: 137, DisplayName: "Wadi al ?ayat"},
        {StateID: 1918, CountryID: 138, DisplayName: "Béni Mellal-Khénifra"},
        {StateID: 1919, CountryID: 138, DisplayName: "Casablanca-Settat"},
        {StateID: 1925, CountryID: 138, DisplayName: "Dakhla-Oued Ed-Dahab"},
        {StateID: 1921, CountryID: 138, DisplayName: "Drâa-Tafilalet"},
        {StateID: 1916, CountryID: 138, DisplayName: "Fès-Meknès"},
        {StateID: 1923, CountryID: 138, DisplayName: "Guelmim-Oued Noun"},
        {StateID: 1924, CountryID: 138, DisplayName: "Laâyoune-Sakia El Hamra"},
        {StateID: 1920, CountryID: 138, DisplayName: "Marrakesh-Safi"},
        {StateID: 1915, CountryID: 138, DisplayName: "Oriental"},
        {StateID: 1917, CountryID: 138, DisplayName: "Rabat-Salé-Kénitra"},
        {StateID: 1922, CountryID: 138, DisplayName: "Souss-Massa"},
        {StateID: 1914, CountryID: 138, DisplayName: "Tanger-Tetouan-Al Hoceima"},
        {StateID: 1926, CountryID: 139, DisplayName: "Municipality of Monaco"},
        {StateID: 1938, CountryID: 140, DisplayName: "Anenii Noi"},
        {StateID: 1963, CountryID: 140, DisplayName: "Balti"},
        {StateID: 1957, CountryID: 140, DisplayName: "Basarabeasca"},
        {StateID: 1962, CountryID: 140, DisplayName: "Bender Municipality"},
        {StateID: 1956, CountryID: 140, DisplayName: "Briceni"},
        {StateID: 1948, CountryID: 140, DisplayName: "Cahul"},
        {StateID: 1947, CountryID: 140, DisplayName: "Calarasi"},
        {StateID: 1946, CountryID: 140, DisplayName: "Cantemir"},
        {StateID: 1945, CountryID: 140, DisplayName: "Causeni"},
        {StateID: 1944, CountryID: 140, DisplayName: "Chi?inau Municipality"},
        {StateID: 1955, CountryID: 140, DisplayName: "Cimislia"},
        {StateID: 1942, CountryID: 140, DisplayName: "Criuleni"},
        {StateID: 1954, CountryID: 140, DisplayName: "Donduseni"},
        {StateID: 1953, CountryID: 140, DisplayName: "Drochia"},
        {StateID: 1952, CountryID: 140, DisplayName: "Dubasari"},
        {StateID: 1951, CountryID: 140, DisplayName: "Fale?ti"},
        {StateID: 1950, CountryID: 140, DisplayName: "Floresti"},
        {StateID: 1961, CountryID: 140, DisplayName: "Gagauzia"},
        {StateID: 1949, CountryID: 140, DisplayName: "Glodeni"},
        {StateID: 1958, CountryID: 140, DisplayName: "Hîncesti"},
        {StateID: 1943, CountryID: 140, DisplayName: "Ialoveni"},
        {StateID: 1940, CountryID: 140, DisplayName: "Leova"},
        {StateID: 1939, CountryID: 140, DisplayName: "Nisporeni"},
        {StateID: 1936, CountryID: 140, DisplayName: "Orhei"},
        {StateID: 1927, CountryID: 140, DisplayName: "Raionul Edinet"},
        {StateID: 1937, CountryID: 140, DisplayName: "Raionul Ocnita"},
        {StateID: 1933, CountryID: 140, DisplayName: "Raionul Soroca"},
        {StateID: 1935, CountryID: 140, DisplayName: "Rezina"},
        {StateID: 1934, CountryID: 140, DisplayName: "Rîscani"},
        {StateID: 1941, CountryID: 140, DisplayName: "Sîngerei"},
        {StateID: 1959, CountryID: 140, DisplayName: "Soldanesti"},
        {StateID: 1931, CountryID: 140, DisplayName: "Stefan-Voda"},
        {StateID: 1932, CountryID: 140, DisplayName: "Stra?eni"},
        {StateID: 1930, CountryID: 140, DisplayName: "Taraclia"},
        {StateID: 1929, CountryID: 140, DisplayName: "Telenesti"},
        {StateID: 1960, CountryID: 140, DisplayName: "Transnistria"},
        {StateID: 1928, CountryID: 140, DisplayName: "Ungheni"},
        {StateID: 1984, CountryID: 141, DisplayName: "Andrijevica"},
        {StateID: 1983, CountryID: 141, DisplayName: "Bar"},
        {StateID: 1977, CountryID: 141, DisplayName: "Berane"},
        {StateID: 1982, CountryID: 141, DisplayName: "Bijelo Polje"},
        {StateID: 1981, CountryID: 141, DisplayName: "Budva"},
        {StateID: 1980, CountryID: 141, DisplayName: "Cetinje"},
        {StateID: 1979, CountryID: 141, DisplayName: "Danilovgrad"},
        {StateID: 1985, CountryID: 141, DisplayName: "Gusinje"},
        {StateID: 1978, CountryID: 141, DisplayName: "Herceg Novi"},
        {StateID: 1975, CountryID: 141, DisplayName: "Kotor"},
        {StateID: 1974, CountryID: 141, DisplayName: "Mojkovac"},
        {StateID: 1969, CountryID: 141, DisplayName: "Opština Šavnik"},
        {StateID: 1965, CountryID: 141, DisplayName: "Opština Žabljak"},
        {StateID: 1976, CountryID: 141, DisplayName: "Opština Kolašin"},
        {StateID: 1973, CountryID: 141, DisplayName: "Opština Nikšic"},
        {StateID: 1972, CountryID: 141, DisplayName: "Opština Plav"},
        {StateID: 1970, CountryID: 141, DisplayName: "Opština Plužine"},
        {StateID: 1964, CountryID: 141, DisplayName: "Opština Rožaje"},
        {StateID: 1986, CountryID: 141, DisplayName: "Petnjica"},
        {StateID: 1971, CountryID: 141, DisplayName: "Pljevlja"},
        {StateID: 1968, CountryID: 141, DisplayName: "Podgorica"},
        {StateID: 1967, CountryID: 141, DisplayName: "Tivat"},
        {StateID: 1987, CountryID: 141, DisplayName: "Tuzi"},
        {StateID: 1966, CountryID: 141, DisplayName: "Ulcinj"},
        {StateID: 1994, CountryID: 143, DisplayName: "Alaotra Mangoro"},
        {StateID: 2002, CountryID: 143, DisplayName: "Amoron'i Mania"},
        {StateID: 1999, CountryID: 143, DisplayName: "Analamanga"},
        {StateID: 1991, CountryID: 143, DisplayName: "Analanjirofo"},
        {StateID: 2008, CountryID: 143, DisplayName: "Androy"},
        {StateID: 2007, CountryID: 143, DisplayName: "Anosy"},
        {StateID: 2009, CountryID: 143, DisplayName: "Atsimo-Andrefana"},
        {StateID: 2006, CountryID: 143, DisplayName: "Atsimo-Atsinanana"},
        {StateID: 2000, CountryID: 143, DisplayName: "Atsinanana"},
        {StateID: 1993, CountryID: 143, DisplayName: "Betsiboka"},
        {StateID: 1992, CountryID: 143, DisplayName: "Boeny"},
        {StateID: 1996, CountryID: 143, DisplayName: "Bongolava"},
        {StateID: 1988, CountryID: 143, DisplayName: "Diana"},
        {StateID: 2005, CountryID: 143, DisplayName: "Ihorombe"},
        {StateID: 1998, CountryID: 143, DisplayName: "Itasy"},
        {StateID: 1995, CountryID: 143, DisplayName: "Melaky"},
        {StateID: 2001, CountryID: 143, DisplayName: "Menabe"},
        {StateID: 1989, CountryID: 143, DisplayName: "Sava"},
        {StateID: 1990, CountryID: 143, DisplayName: "Sofia"},
        {StateID: 2003, CountryID: 143, DisplayName: "Upper Matsiatra"},
        {StateID: 1997, CountryID: 143, DisplayName: "Vakinankaratra"},
        {StateID: 2004, CountryID: 143, DisplayName: "Vatovavy Fitovinany"},
        {StateID: 2010, CountryID: 144, DisplayName: "Ailinginae Atoll"},
        {StateID: 2011, CountryID: 144, DisplayName: "Ailinglaplap Atoll"},
        {StateID: 2012, CountryID: 144, DisplayName: "Ailuk Atoll"},
        {StateID: 2013, CountryID: 144, DisplayName: "Arno Atoll"},
        {StateID: 2014, CountryID: 144, DisplayName: "Aur Atoll"},
        {StateID: 2015, CountryID: 144, DisplayName: "Bikar Atoll"},
        {StateID: 2016, CountryID: 144, DisplayName: "Bikini Atoll"},
        {StateID: 2032, CountryID: 144, DisplayName: "Bokak Atoll"},
        {StateID: 2017, CountryID: 144, DisplayName: "Ebon Atoll"},
        {StateID: 2018, CountryID: 144, DisplayName: "Enewetak Atoll"},
        {StateID: 2019, CountryID: 144, DisplayName: "Erikub Atoll"},
        {StateID: 2038, CountryID: 144, DisplayName: "Jabat Island"},
        {StateID: 2020, CountryID: 144, DisplayName: "Jaluit Atoll"},
        {StateID: 2039, CountryID: 144, DisplayName: "Jemo Island"},
        {StateID: 2040, CountryID: 144, DisplayName: "Kili Island"},
        {StateID: 2021, CountryID: 144, DisplayName: "Kwajalein Atoll"},
        {StateID: 2022, CountryID: 144, DisplayName: "Lae Atoll"},
        {StateID: 2041, CountryID: 144, DisplayName: "Lib Island"},
        {StateID: 2023, CountryID: 144, DisplayName: "Likiep Atoll"},
        {StateID: 2024, CountryID: 144, DisplayName: "Majuro Atoll"},
        {StateID: 2025, CountryID: 144, DisplayName: "Maloelap Atoll"},
        {StateID: 2042, CountryID: 144, DisplayName: "Mejit Island"},
        {StateID: 2026, CountryID: 144, DisplayName: "Mili Atoll"},
        {StateID: 2027, CountryID: 144, DisplayName: "Namdrik Atoll"},
        {StateID: 2028, CountryID: 144, DisplayName: "Namu Atoll"},
        {StateID: 2029, CountryID: 144, DisplayName: "Rongelap Atoll"},
        {StateID: 2030, CountryID: 144, DisplayName: "Rongrik Atoll"},
        {StateID: 2031, CountryID: 144, DisplayName: "Taka Atoll"},
        {StateID: 2033, CountryID: 144, DisplayName: "Ujae Atoll"},
        {StateID: 2034, CountryID: 144, DisplayName: "Ujelang"},
        {StateID: 2035, CountryID: 144, DisplayName: "Utrik Atoll"},
        {StateID: 2036, CountryID: 144, DisplayName: "Wotho Atoll"},
        {StateID: 2037, CountryID: 144, DisplayName: "Wotje Atoll"},
        {StateID: 2065, CountryID: 145, DisplayName: "Arachinovo"},
        {StateID: 2062, CountryID: 145, DisplayName: "Berovo"},
        {StateID: 2063, CountryID: 145, DisplayName: "Bitola"},
        {StateID: 2051, CountryID: 145, DisplayName: "Bogdanci"},
        {StateID: 2066, CountryID: 145, DisplayName: "Bogovinje"},
        {StateID: 2048, CountryID: 145, DisplayName: "Bosilovo"},
        {StateID: 2067, CountryID: 145, DisplayName: "Brvenica"},
        {StateID: 2060, CountryID: 145, DisplayName: "Cešinovo-Obleševo"},
        {StateID: 2069, CountryID: 145, DisplayName: "Centar Zhupa"},
        {StateID: 2068, CountryID: 145, DisplayName: "Chashka"},
        {StateID: 2070, CountryID: 145, DisplayName: "Chucher Sandevo"},
        {StateID: 2071, CountryID: 145, DisplayName: "Debar"},
        {StateID: 2112, CountryID: 145, DisplayName: "Debarca"},
        {StateID: 2072, CountryID: 145, DisplayName: "Delchevo"},
        {StateID: 2073, CountryID: 145, DisplayName: "Demir Hisar"},
        {StateID: 2056, CountryID: 145, DisplayName: "Demir Kapija"},
        {StateID: 2050, CountryID: 145, DisplayName: "Dojran"},
        {StateID: 2074, CountryID: 145, DisplayName: "Dolneni"},
        {StateID: 2075, CountryID: 145, DisplayName: "Gevgelija"},
        {StateID: 2076, CountryID: 145, DisplayName: "Gostivar"},
        {StateID: 2113, CountryID: 145, DisplayName: "Grad Skopje"},
        {StateID: 2058, CountryID: 145, DisplayName: "Gradsko"},
        {StateID: 2077, CountryID: 145, DisplayName: "Ilinden"},
        {StateID: 2078, CountryID: 145, DisplayName: "Jegunovce"},
        {StateID: 2055, CountryID: 145, DisplayName: "Karbinci"},
        {StateID: 2079, CountryID: 145, DisplayName: "Kavadarci"},
        {StateID: 2080, CountryID: 145, DisplayName: "Kichevo"},
        {StateID: 2081, CountryID: 145, DisplayName: "Kochani"},
        {StateID: 2052, CountryID: 145, DisplayName: "Konche"},
        {StateID: 2045, CountryID: 145, DisplayName: "Kratovo"},
        {StateID: 2082, CountryID: 145, DisplayName: "Kriva Palanka"},
        {StateID: 2083, CountryID: 145, DisplayName: "Krivogashtani"},
        {StateID: 2084, CountryID: 145, DisplayName: "Krushevo"},
        {StateID: 2085, CountryID: 145, DisplayName: "Kumanovo"},
        {StateID: 2086, CountryID: 145, DisplayName: "Lipkovo"},
        {StateID: 2059, CountryID: 145, DisplayName: "Lozovo"},
        {StateID: 2053, CountryID: 145, DisplayName: "Makedonska Kamenica"},
        {StateID: 2087, CountryID: 145, DisplayName: "Makedonski Brod"},
        {StateID: 2096, CountryID: 145, DisplayName: "Mavrovo and Rostuša"},
        {StateID: 2064, CountryID: 145, DisplayName: "Mogila"},
        {StateID: 2088, CountryID: 145, DisplayName: "Negotino"},
        {StateID: 2061, CountryID: 145, DisplayName: "Novaci"},
        {StateID: 2047, CountryID: 145, DisplayName: "Novo Selo"},
        {StateID: 2089, CountryID: 145, DisplayName: "Ohrid"},
        {StateID: 2046, CountryID: 145, DisplayName: "Pehchevo"},
        {StateID: 2090, CountryID: 145, DisplayName: "Petrovec"},
        {StateID: 2091, CountryID: 145, DisplayName: "Plasnica"},
        {StateID: 2092, CountryID: 145, DisplayName: "Prilep"},
        {StateID: 2093, CountryID: 145, DisplayName: "Probishtip"},
        {StateID: 2094, CountryID: 145, DisplayName: "Radovish"},
        {StateID: 2095, CountryID: 145, DisplayName: "Rankovce"},
        {StateID: 2044, CountryID: 145, DisplayName: "Resen"},
        {StateID: 2057, CountryID: 145, DisplayName: "Rosoman"},
        {StateID: 2099, CountryID: 145, DisplayName: "Shtip"},
        {StateID: 2097, CountryID: 145, DisplayName: "Sopište"},
        {StateID: 2098, CountryID: 145, DisplayName: "Staro Nagorichane"},
        {StateID: 2100, CountryID: 145, DisplayName: "Struga"},
        {StateID: 2101, CountryID: 145, DisplayName: "Strumica"},
        {StateID: 2102, CountryID: 145, DisplayName: "Studenichani"},
        {StateID: 2103, CountryID: 145, DisplayName: "Sveti Nikole"},
        {StateID: 2104, CountryID: 145, DisplayName: "Tearce"},
        {StateID: 2105, CountryID: 145, DisplayName: "Tetovo"},
        {StateID: 2043, CountryID: 145, DisplayName: "Valandovo"},
        {StateID: 2049, CountryID: 145, DisplayName: "Vasilevo"},
        {StateID: 2106, CountryID: 145, DisplayName: "Veles"},
        {StateID: 2107, CountryID: 145, DisplayName: "Vevchani"},
        {StateID: 2108, CountryID: 145, DisplayName: "Vinica"},
        {StateID: 2109, CountryID: 145, DisplayName: "Vrapchishte"},
        {StateID: 2110, CountryID: 145, DisplayName: "Zelenikovo"},
        {StateID: 2111, CountryID: 145, DisplayName: "Zhelino"},
        {StateID: 2054, CountryID: 145, DisplayName: "Zrnovci"},
        {StateID: 2121, CountryID: 146, DisplayName: "Bamako"},
        {StateID: 2120, CountryID: 146, DisplayName: "Gao"},
        {StateID: 2119, CountryID: 146, DisplayName: "Kayes"},
        {StateID: 2122, CountryID: 146, DisplayName: "Kidal"},
        {StateID: 2118, CountryID: 146, DisplayName: "Koulikoro"},
        {StateID: 2124, CountryID: 146, DisplayName: "Ménaka"},
        {StateID: 2117, CountryID: 146, DisplayName: "Mopti"},
        {StateID: 2116, CountryID: 146, DisplayName: "Ségou"},
        {StateID: 2115, CountryID: 146, DisplayName: "Sikasso"},
        {StateID: 2123, CountryID: 146, DisplayName: "Taoudénit"},
        {StateID: 2114, CountryID: 146, DisplayName: "Tombouctou"},
        {StateID: 2137, CountryID: 147, DisplayName: "Ayeyarwady"},
        {StateID: 2130, CountryID: 147, DisplayName: "Bago"},
        {StateID: 2138, CountryID: 147, DisplayName: "Chin"},
        {StateID: 2136, CountryID: 147, DisplayName: "Kachin"},
        {StateID: 2134, CountryID: 147, DisplayName: "Kayah"},
        {StateID: 2135, CountryID: 147, DisplayName: "Kayin"},
        {StateID: 2133, CountryID: 147, DisplayName: "Magway"},
        {StateID: 2132, CountryID: 147, DisplayName: "Mandalay"},
        {StateID: 2131, CountryID: 147, DisplayName: "Mon"},
        {StateID: 2139, CountryID: 147, DisplayName: "Nay Pyi Taw"},
        {StateID: 2129, CountryID: 147, DisplayName: "Rakhine"},
        {StateID: 2128, CountryID: 147, DisplayName: "Rangoon"},
        {StateID: 2127, CountryID: 147, DisplayName: "Sagain"},
        {StateID: 2126, CountryID: 147, DisplayName: "Shan"},
        {StateID: 2125, CountryID: 147, DisplayName: "Tanintharyi"},
        {StateID: 2158, CountryID: 148, DisplayName: "Arhangay"},
        {StateID: 2144, CountryID: 148, DisplayName: "Bayan-Ölgiy"},
        {StateID: 2145, CountryID: 148, DisplayName: "Bayanhongor"},
        {StateID: 2157, CountryID: 148, DisplayName: "Bulgan"},
        {StateID: 2147, CountryID: 148, DisplayName: "Central Aimak"},
        {StateID: 2159, CountryID: 148, DisplayName: "Darhan Uul"},
        {StateID: 2143, CountryID: 148, DisplayName: "Dzabkhan"},
        {StateID: 2156, CountryID: 148, DisplayName: "East Aimak"},
        {StateID: 2155, CountryID: 148, DisplayName: "East Gobi Aymag"},
        {StateID: 2142, CountryID: 148, DisplayName: "Govi-Altay"},
        {StateID: 2160, CountryID: 148, DisplayName: "Govi-Sumber"},
        {StateID: 2153, CountryID: 148, DisplayName: "Hentiy"},
        {StateID: 2141, CountryID: 148, DisplayName: "Hovd"},
        {StateID: 2152, CountryID: 148, DisplayName: "Hövsgöl"},
        {StateID: 2154, CountryID: 148, DisplayName: "Middle Govi"},
        {StateID: 2151, CountryID: 148, DisplayName: "Ömnögovi"},
        {StateID: 2161, CountryID: 148, DisplayName: "Orhon"},
        {StateID: 2150, CountryID: 148, DisplayName: "Övörhangay"},
        {StateID: 2149, CountryID: 148, DisplayName: "Selenge"},
        {StateID: 2148, CountryID: 148, DisplayName: "Sühbaatar"},
        {StateID: 2146, CountryID: 148, DisplayName: "Ulaanbaatar"},
        {StateID: 2140, CountryID: 148, DisplayName: "Uvs"},
        {StateID: 2168, CountryID: 149, DisplayName: "Cotai"},
        {StateID: 2162, CountryID: 149, DisplayName: "Nossa Senhora de Fátima"},
        {StateID: 2167, CountryID: 149, DisplayName: "Nossa Senhora do Carmo"},
        {StateID: 2163, CountryID: 149, DisplayName: "Santo António"},
        {StateID: 2169, CountryID: 149, DisplayName: "São Francisco Xavier"},
        {StateID: 2164, CountryID: 149, DisplayName: "São Lázaro"},
        {StateID: 2166, CountryID: 149, DisplayName: "São Lourenço"},
        {StateID: 2165, CountryID: 149, DisplayName: "Sé"},
        {StateID: 2173, CountryID: 150, DisplayName: "Northern Islands"},
        {StateID: 2170, CountryID: 150, DisplayName: "Rota"},
        {StateID: 2171, CountryID: 150, DisplayName: "Saipan"},
        {StateID: 2172, CountryID: 150, DisplayName: "Tinian"},
        {StateID: 2174, CountryID: 151, DisplayName: "Martinique"},
        {StateID: 2186, CountryID: 152, DisplayName: "Adrar"},
        {StateID: 2185, CountryID: 152, DisplayName: "Assaba"},
        {StateID: 2184, CountryID: 152, DisplayName: "Brakna"},
        {StateID: 2183, CountryID: 152, DisplayName: "Dakhlet Nouadhibou"},
        {StateID: 2182, CountryID: 152, DisplayName: "Gorgol"},
        {StateID: 2181, CountryID: 152, DisplayName: "Guidimaka"},
        {StateID: 2180, CountryID: 152, DisplayName: "Hodh Ech Chargi"},
        {StateID: 2179, CountryID: 152, DisplayName: "Hodh El Gharbi"},
        {StateID: 2178, CountryID: 152, DisplayName: "Inchiri"},
        {StateID: 2188, CountryID: 152, DisplayName: "Nouakchott Nord"},
        {StateID: 2187, CountryID: 152, DisplayName: "Nouakchott Ouest"},
        {StateID: 2189, CountryID: 152, DisplayName: "Nouakchott Sud"},
        {StateID: 2177, CountryID: 152, DisplayName: "Tagant"},
        {StateID: 2176, CountryID: 152, DisplayName: "Tiris Zemmour"},
        {StateID: 2175, CountryID: 152, DisplayName: "Trarza"},
        {StateID: 2192, CountryID: 153, DisplayName: "Saint Anthony"},
        {StateID: 2191, CountryID: 153, DisplayName: "Saint Georges"},
        {StateID: 2190, CountryID: 153, DisplayName: "Saint Peter"},
        {StateID: 2193, CountryID: 154, DisplayName: "Attard"},
        {StateID: 2194, CountryID: 154, DisplayName: "Balzan"},
        {StateID: 2196, CountryID: 154, DisplayName: "Birkirkara"},
        {StateID: 2197, CountryID: 154, DisplayName: "Birzebbuga"},
        {StateID: 2198, CountryID: 154, DisplayName: "Bormla"},
        {StateID: 2199, CountryID: 154, DisplayName: "Dingli"},
        {StateID: 2201, CountryID: 154, DisplayName: "Floriana"},
        {StateID: 2203, CountryID: 154, DisplayName: "Ghajnsielem"},
        {StateID: 2205, CountryID: 154, DisplayName: "Hal Gharghur"},
        {StateID: 2207, CountryID: 154, DisplayName: "Hal Ghaxaq"},
        {StateID: 2255, CountryID: 154, DisplayName: "Haz-Zabbar"},
        {StateID: 2256, CountryID: 154, DisplayName: "Haz-Zebbug"},
        {StateID: 2195, CountryID: 154, DisplayName: "Il-Birgu"},
        {StateID: 2200, CountryID: 154, DisplayName: "Il-Fgura"},
        {StateID: 2202, CountryID: 154, DisplayName: "Il-Fontana"},
        {StateID: 2208, CountryID: 154, DisplayName: "Il-Gudja"},
        {StateID: 2209, CountryID: 154, DisplayName: "Il-Gzira"},
        {StateID: 2210, CountryID: 154, DisplayName: "Il-Hamrun"},
        {StateID: 2218, CountryID: 154, DisplayName: "Il-Kalkara"},
        {StateID: 2223, CountryID: 154, DisplayName: "Il-Marsa"},
        {StateID: 2226, CountryID: 154, DisplayName: "Il-Mellieha"},
        {StateID: 2227, CountryID: 154, DisplayName: "Il-Mosta"},
        {StateID: 2228, CountryID: 154, DisplayName: "Il-Munxar"},
        {StateID: 2234, CountryID: 154, DisplayName: "Il-Qala"},
        {StateID: 2236, CountryID: 154, DisplayName: "Il-Qrendi"},
        {StateID: 2229, CountryID: 154, DisplayName: "In-Nadur"},
        {StateID: 2230, CountryID: 154, DisplayName: "In-Naxxar"},
        {StateID: 2237, CountryID: 154, DisplayName: "Ir-Rabat"},
        {StateID: 2247, CountryID: 154, DisplayName: "Is-Siggiewi"},
        {StateID: 2249, CountryID: 154, DisplayName: "Is-Swieqi"},
        {StateID: 2252, CountryID: 154, DisplayName: "Ix-Xaghra"},
        {StateID: 2253, CountryID: 154, DisplayName: "Ix-Xewkija"},
        {StateID: 2254, CountryID: 154, DisplayName: "Ix-Xghajra"},
        {StateID: 2257, CountryID: 154, DisplayName: "Iz-Zebbug"},
        {StateID: 2258, CountryID: 154, DisplayName: "Iz-Zejtun"},
        {StateID: 2259, CountryID: 154, DisplayName: "Iz-Zurrieq"},
        {StateID: 2220, CountryID: 154, DisplayName: "Kirkop"},
        {StateID: 2204, CountryID: 154, DisplayName: "L-Gharb"},
        {StateID: 2206, CountryID: 154, DisplayName: "L-Ghasri"},
        {StateID: 2211, CountryID: 154, DisplayName: "L-Iklin"},
        {StateID: 2212, CountryID: 154, DisplayName: "L-Imdina"},
        {StateID: 2213, CountryID: 154, DisplayName: "L-Imgarr"},
        {StateID: 2214, CountryID: 154, DisplayName: "L-Imqabba"},
        {StateID: 2215, CountryID: 154, DisplayName: "L-Imsida"},
        {StateID: 2221, CountryID: 154, DisplayName: "Lija"},
        {StateID: 2222, CountryID: 154, DisplayName: "Luqa"},
        {StateID: 2224, CountryID: 154, DisplayName: "Marsaskala"},
        {StateID: 2225, CountryID: 154, DisplayName: "Marsaxlokk"},
        {StateID: 2216, CountryID: 154, DisplayName: "Mtarfa"},
        {StateID: 2231, CountryID: 154, DisplayName: "Paola"},
        {StateID: 2232, CountryID: 154, DisplayName: "Pembroke"},
        {StateID: 2235, CountryID: 154, DisplayName: "Qormi"},
        {StateID: 2239, CountryID: 154, DisplayName: "Safi"},
        {StateID: 2240, CountryID: 154, DisplayName: "Saint John"},
        {StateID: 2241, CountryID: 154, DisplayName: "Saint Julian"},
        {StateID: 2242, CountryID: 154, DisplayName: "Saint Lawrence"},
        {StateID: 2243, CountryID: 154, DisplayName: "Saint Lucia"},
        {StateID: 2244, CountryID: 154, DisplayName: "Saint Paul’s Bay"},
        {StateID: 2245, CountryID: 154, DisplayName: "Saint Venera"},
        {StateID: 2246, CountryID: 154, DisplayName: "Sannat"},
        {StateID: 2217, CountryID: 154, DisplayName: "Senglea"},
        {StateID: 2219, CountryID: 154, DisplayName: "Ta’ Kercem"},
        {StateID: 2251, CountryID: 154, DisplayName: "Ta’ Xbiex"},
        {StateID: 2233, CountryID: 154, DisplayName: "Tal-Pietà"},
        {StateID: 2250, CountryID: 154, DisplayName: "Tarxien"},
        {StateID: 2248, CountryID: 154, DisplayName: "Tas-Sliema"},
        {StateID: 2260, CountryID: 154, DisplayName: "Valletta"},
        {StateID: 2238, CountryID: 154, DisplayName: "Victoria"},
        {StateID: 2261, CountryID: 155, DisplayName: "Agalega Islands"},
        {StateID: 2270, CountryID: 155, DisplayName: "Black River"},
        {StateID: 2271, CountryID: 155, DisplayName: "Cargados Carajos"},
        {StateID: 2269, CountryID: 155, DisplayName: "Flacq"},
        {StateID: 2268, CountryID: 155, DisplayName: "Grand Port"},
        {StateID: 2267, CountryID: 155, DisplayName: "Moka"},
        {StateID: 2266, CountryID: 155, DisplayName: "Pamplemousses"},
        {StateID: 2265, CountryID: 155, DisplayName: "Plaines Wilhems"},
        {StateID: 2264, CountryID: 155, DisplayName: "Port Louis"},
        {StateID: 2263, CountryID: 155, DisplayName: "Rivière du Rempart"},
        {StateID: 2272, CountryID: 155, DisplayName: "Rodrigues"},
        {StateID: 2262, CountryID: 155, DisplayName: "Savanne"},
        {StateID: 2290, CountryID: 156, DisplayName: "Baa Atholhu"},
        {StateID: 2289, CountryID: 156, DisplayName: "Dhaalu Atholhu"},
        {StateID: 2288, CountryID: 156, DisplayName: "Faafu Atholhu"},
        {StateID: 2287, CountryID: 156, DisplayName: "Gaafu Alif Atoll"},
        {StateID: 2286, CountryID: 156, DisplayName: "Gaafu Dhaalu Atoll"},
        {StateID: 2279, CountryID: 156, DisplayName: "Gnyaviyani Atoll"},
        {StateID: 2285, CountryID: 156, DisplayName: "Haa Alifu Atholhu"},
        {StateID: 2284, CountryID: 156, DisplayName: "Haa Dhaalu Atholhu"},
        {StateID: 2283, CountryID: 156, DisplayName: "Kaafu Atoll"},
        {StateID: 2282, CountryID: 156, DisplayName: "Laamu"},
        {StateID: 2281, CountryID: 156, DisplayName: "Lhaviyani Atholhu"},
        {StateID: 2292, CountryID: 156, DisplayName: "Male"},
        {StateID: 2280, CountryID: 156, DisplayName: "Meemu Atholhu"},
        {StateID: 2278, CountryID: 156, DisplayName: "Noonu Atoll"},
        {StateID: 2291, CountryID: 156, DisplayName: "Northern Ari Atoll"},
        {StateID: 2277, CountryID: 156, DisplayName: "Raa Atoll"},
        {StateID: 2276, CountryID: 156, DisplayName: "Seenu"},
        {StateID: 2275, CountryID: 156, DisplayName: "Shaviyani Atholhu"},
        {StateID: 2293, CountryID: 156, DisplayName: "Southern Ari Atoll"},
        {StateID: 2274, CountryID: 156, DisplayName: "Thaa Atholhu"},
        {StateID: 2273, CountryID: 156, DisplayName: "Vaavu Atholhu"},
        {StateID: 2296, CountryID: 157, DisplayName: "Central Region"},
        {StateID: 2295, CountryID: 157, DisplayName: "Northern Region"},
        {StateID: 2294, CountryID: 157, DisplayName: "Southern Region"},
        {StateID: 2328, CountryID: 158, DisplayName: "Aguascalientes"},
        {StateID: 2327, CountryID: 158, DisplayName: "Baja California"},
        {StateID: 2326, CountryID: 158, DisplayName: "Baja California Sur"},
        {StateID: 2313, CountryID: 158, DisplayName: "Campeche"},
        {StateID: 2312, CountryID: 158, DisplayName: "Chiapas"},
        {StateID: 2325, CountryID: 158, DisplayName: "Chihuahua"},
        {StateID: 2324, CountryID: 158, DisplayName: "Coahuila"},
        {StateID: 2323, CountryID: 158, DisplayName: "Colima"},
        {StateID: 2322, CountryID: 158, DisplayName: "Durango"},
        {StateID: 2321, CountryID: 158, DisplayName: "Guanajuato"},
        {StateID: 2310, CountryID: 158, DisplayName: "Guerrero"},
        {StateID: 2309, CountryID: 158, DisplayName: "Hidalgo"},
        {StateID: 2320, CountryID: 158, DisplayName: "Jalisco"},
        {StateID: 2308, CountryID: 158, DisplayName: "México"},
        {StateID: 2311, CountryID: 158, DisplayName: "Mexico City"},
        {StateID: 2319, CountryID: 158, DisplayName: "Michoacán"},
        {StateID: 2307, CountryID: 158, DisplayName: "Morelos"},
        {StateID: 2318, CountryID: 158, DisplayName: "Nayarit"},
        {StateID: 2306, CountryID: 158, DisplayName: "Nuevo León"},
        {StateID: 2305, CountryID: 158, DisplayName: "Oaxaca"},
        {StateID: 2304, CountryID: 158, DisplayName: "Puebla"},
        {StateID: 2303, CountryID: 158, DisplayName: "Querétaro"},
        {StateID: 2302, CountryID: 158, DisplayName: "Quintana Roo"},
        {StateID: 2317, CountryID: 158, DisplayName: "San Luis Potosí"},
        {StateID: 2316, CountryID: 158, DisplayName: "Sinaloa"},
        {StateID: 2315, CountryID: 158, DisplayName: "Sonora"},
        {StateID: 2301, CountryID: 158, DisplayName: "Tabasco"},
        {StateID: 2300, CountryID: 158, DisplayName: "Tamaulipas"},
        {StateID: 2299, CountryID: 158, DisplayName: "Tlaxcala"},
        {StateID: 2298, CountryID: 158, DisplayName: "Veracruz"},
        {StateID: 2297, CountryID: 158, DisplayName: "Yucatán"},
        {StateID: 2314, CountryID: 158, DisplayName: "Zacatecas"},
        {StateID: 2342, CountryID: 159, DisplayName: "Johor"},
        {StateID: 2341, CountryID: 159, DisplayName: "Kedah"},
        {StateID: 2338, CountryID: 159, DisplayName: "Kelantan"},
        {StateID: 2339, CountryID: 159, DisplayName: "Kuala Lumpur"},
        {StateID: 2343, CountryID: 159, DisplayName: "Labuan"},
        {StateID: 2329, CountryID: 159, DisplayName: "Melaka"},
        {StateID: 2337, CountryID: 159, DisplayName: "Negeri Sembilan"},
        {StateID: 2336, CountryID: 159, DisplayName: "Pahang"},
        {StateID: 2340, CountryID: 159, DisplayName: "Penang"},
        {StateID: 2335, CountryID: 159, DisplayName: "Perak"},
        {StateID: 2334, CountryID: 159, DisplayName: "Perlis"},
        {StateID: 2344, CountryID: 159, DisplayName: "Putrajaya"},
        {StateID: 2333, CountryID: 159, DisplayName: "Sabah"},
        {StateID: 2332, CountryID: 159, DisplayName: "Sarawak"},
        {StateID: 2331, CountryID: 159, DisplayName: "Selangor"},
        {StateID: 2330, CountryID: 159, DisplayName: "Terengganu"},
        {StateID: 2354, CountryID: 160, DisplayName: "Cabo Delgado"},
        {StateID: 2353, CountryID: 160, DisplayName: "Gaza"},
        {StateID: 2352, CountryID: 160, DisplayName: "Inhambane"},
        {StateID: 2351, CountryID: 160, DisplayName: "Manica"},
        {StateID: 2355, CountryID: 160, DisplayName: "Maputo City"},
        {StateID: 2350, CountryID: 160, DisplayName: "Maputo Province"},
        {StateID: 2349, CountryID: 160, DisplayName: "Nampula"},
        {StateID: 2348, CountryID: 160, DisplayName: "Niassa"},
        {StateID: 2347, CountryID: 160, DisplayName: "Sofala"},
        {StateID: 2346, CountryID: 160, DisplayName: "Tete"},
        {StateID: 2345, CountryID: 160, DisplayName: "Zambézia"},
        {StateID: 2358, CountryID: 161, DisplayName: "Erongo"},
        {StateID: 2359, CountryID: 161, DisplayName: "Hardap"},
        {StateID: 2360, CountryID: 161, DisplayName: "Karas"},
        {StateID: 2368, CountryID: 161, DisplayName: "Kavango East"},
        {StateID: 2369, CountryID: 161, DisplayName: "Kavango West"},
        {StateID: 2357, CountryID: 161, DisplayName: "Khomas"},
        {StateID: 2361, CountryID: 161, DisplayName: "Kunene"},
        {StateID: 2362, CountryID: 161, DisplayName: "Ohangwena"},
        {StateID: 2363, CountryID: 161, DisplayName: "Omaheke"},
        {StateID: 2364, CountryID: 161, DisplayName: "Omusati"},
        {StateID: 2365, CountryID: 161, DisplayName: "Oshana"},
        {StateID: 2366, CountryID: 161, DisplayName: "Oshikoto"},
        {StateID: 2367, CountryID: 161, DisplayName: "Otjozondjupa"},
        {StateID: 2356, CountryID: 161, DisplayName: "Zambezi"},
        {StateID: 2372, CountryID: 162, DisplayName: "Loyalty Islands"},
        {StateID: 2371, CountryID: 162, DisplayName: "North Province"},
        {StateID: 2370, CountryID: 162, DisplayName: "South Province"},
        {StateID: 2378, CountryID: 163, DisplayName: "Agadez"},
        {StateID: 2377, CountryID: 163, DisplayName: "Diffa"},
        {StateID: 2376, CountryID: 163, DisplayName: "Dosso"},
        {StateID: 2375, CountryID: 163, DisplayName: "Maradi"},
        {StateID: 2380, CountryID: 163, DisplayName: "Niamey"},
        {StateID: 2374, CountryID: 163, DisplayName: "Tahoua"},
        {StateID: 2379, CountryID: 163, DisplayName: "Tillabéri"},
        {StateID: 2373, CountryID: 163, DisplayName: "Zinder"},
        {StateID: 2401, CountryID: 165, DisplayName: "Abia"},
        {StateID: 2403, CountryID: 165, DisplayName: "Adamawa"},
        {StateID: 2399, CountryID: 165, DisplayName: "Akwa Ibom"},
        {StateID: 2398, CountryID: 165, DisplayName: "Anambra"},
        {StateID: 2397, CountryID: 165, DisplayName: "Bauchi"},
        {StateID: 2407, CountryID: 165, DisplayName: "Bayelsa"},
        {StateID: 2396, CountryID: 165, DisplayName: "Benue"},
        {StateID: 2395, CountryID: 165, DisplayName: "Borno"},
        {StateID: 2394, CountryID: 165, DisplayName: "Cross River"},
        {StateID: 2402, CountryID: 165, DisplayName: "Delta"},
        {StateID: 2408, CountryID: 165, DisplayName: "Ebonyi"},
        {StateID: 2404, CountryID: 165, DisplayName: "Edo"},
        {StateID: 2409, CountryID: 165, DisplayName: "Ekiti"},
        {StateID: 2405, CountryID: 165, DisplayName: "Enugu"},
        {StateID: 2400, CountryID: 165, DisplayName: "FCT"},
        {StateID: 2410, CountryID: 165, DisplayName: "Gombe"},
        {StateID: 2393, CountryID: 165, DisplayName: "Imo"},
        {StateID: 2406, CountryID: 165, DisplayName: "Jigawa"},
        {StateID: 2392, CountryID: 165, DisplayName: "Kaduna"},
        {StateID: 2391, CountryID: 165, DisplayName: "Kano"},
        {StateID: 2390, CountryID: 165, DisplayName: "Katsina"},
        {StateID: 2413, CountryID: 165, DisplayName: "Kebbi"},
        {StateID: 2414, CountryID: 165, DisplayName: "Kogi"},
        {StateID: 2389, CountryID: 165, DisplayName: "Kwara"},
        {StateID: 2388, CountryID: 165, DisplayName: "Lagos"},
        {StateID: 2411, CountryID: 165, DisplayName: "Nassarawa"},
        {StateID: 2387, CountryID: 165, DisplayName: "Niger"},
        {StateID: 2386, CountryID: 165, DisplayName: "Ogun"},
        {StateID: 2385, CountryID: 165, DisplayName: "Ondo"},
        {StateID: 2415, CountryID: 165, DisplayName: "Osun"},
        {StateID: 2384, CountryID: 165, DisplayName: "Oyo"},
        {StateID: 2383, CountryID: 165, DisplayName: "Plateau"},
        {StateID: 2382, CountryID: 165, DisplayName: "Rivers"},
        {StateID: 2381, CountryID: 165, DisplayName: "Sokoto"},
        {StateID: 2416, CountryID: 165, DisplayName: "Taraba"},
        {StateID: 2417, CountryID: 165, DisplayName: "Yobe"},
        {StateID: 2412, CountryID: 165, DisplayName: "Zamfara"},
        {StateID: 2432, CountryID: 166, DisplayName: "Boaco"},
        {StateID: 2431, CountryID: 166, DisplayName: "Carazo"},
        {StateID: 2430, CountryID: 166, DisplayName: "Chinandega"},
        {StateID: 2429, CountryID: 166, DisplayName: "Chontales"},
        {StateID: 2428, CountryID: 166, DisplayName: "Estelí"},
        {StateID: 2427, CountryID: 166, DisplayName: "Granada"},
        {StateID: 2426, CountryID: 166, DisplayName: "Jinotega"},
        {StateID: 2425, CountryID: 166, DisplayName: "León"},
        {StateID: 2424, CountryID: 166, DisplayName: "Madriz"},
        {StateID: 2423, CountryID: 166, DisplayName: "Managua"},
        {StateID: 2422, CountryID: 166, DisplayName: "Masaya"},
        {StateID: 2421, CountryID: 166, DisplayName: "Matagalpa"},
        {StateID: 2433, CountryID: 166, DisplayName: "North Caribbean Coast"},
        {StateID: 2420, CountryID: 166, DisplayName: "Nueva Segovia"},
        {StateID: 2419, CountryID: 166, DisplayName: "Río San Juan"},
        {StateID: 2418, CountryID: 166, DisplayName: "Rivas"},
        {StateID: 2434, CountryID: 166, DisplayName: "South Caribbean Coast"},
        {StateID: 2445, CountryID: 167, DisplayName: "Drenthe"},
        {StateID: 2446, CountryID: 167, DisplayName: "Flevoland"},
        {StateID: 2444, CountryID: 167, DisplayName: "Friesland"},
        {StateID: 2443, CountryID: 167, DisplayName: "Gelderland"},
        {StateID: 2442, CountryID: 167, DisplayName: "Groningen"},
        {StateID: 2441, CountryID: 167, DisplayName: "Limburg"},
        {StateID: 2440, CountryID: 167, DisplayName: "North Brabant"},
        {StateID: 2439, CountryID: 167, DisplayName: "North Holland"},
        {StateID: 2438, CountryID: 167, DisplayName: "Overijssel"},
        {StateID: 2435, CountryID: 167, DisplayName: "South Holland"},
        {StateID: 2437, CountryID: 167, DisplayName: "Utrecht"},
        {StateID: 2436, CountryID: 167, DisplayName: "Zeeland"},
        {StateID: 2453, CountryID: 168, DisplayName: "Agder"},
        {StateID: 2455, CountryID: 168, DisplayName: "Innlandet"},
        {StateID: 2450, CountryID: 168, DisplayName: "Møre og Romsdal"},
        {StateID: 2449, CountryID: 168, DisplayName: "Nordland"},
        {StateID: 2448, CountryID: 168, DisplayName: "Oslo"},
        {StateID: 2447, CountryID: 168, DisplayName: "Rogaland"},
        {StateID: 2454, CountryID: 168, DisplayName: "Troms og Finnmark"},
        {StateID: 2451, CountryID: 168, DisplayName: "Trøndelag"},
        {StateID: 2456, CountryID: 168, DisplayName: "Vestfold og Telemark"},
        {StateID: 2457, CountryID: 168, DisplayName: "Vestland"},
        {StateID: 2452, CountryID: 168, DisplayName: "Viken"},
        {StateID: 2460, CountryID: 169, DisplayName: "Bagmati Province"},
        {StateID: 2463, CountryID: 169, DisplayName: "Karnali Pradesh"},
        {StateID: 2462, CountryID: 169, DisplayName: "Lumbini Province"},
        {StateID: 2458, CountryID: 169, DisplayName: "Province 1"},
        {StateID: 2459, CountryID: 169, DisplayName: "Province 2"},
        {StateID: 2461, CountryID: 169, DisplayName: "Province 4"},
        {StateID: 2464, CountryID: 169, DisplayName: "Sudurpashchim Pradesh"},
        {StateID: 2478, CountryID: 170, DisplayName: "Aiwo"},
        {StateID: 2477, CountryID: 170, DisplayName: "Anabar"},
        {StateID: 2476, CountryID: 170, DisplayName: "Anetan"},
        {StateID: 2475, CountryID: 170, DisplayName: "Anibare"},
        {StateID: 2474, CountryID: 170, DisplayName: "Baiti"},
        {StateID: 2473, CountryID: 170, DisplayName: "Boe"},
        {StateID: 2472, CountryID: 170, DisplayName: "Buada"},
        {StateID: 2471, CountryID: 170, DisplayName: "Denigomodu"},
        {StateID: 2470, CountryID: 170, DisplayName: "Ewa"},
        {StateID: 2469, CountryID: 170, DisplayName: "Ijuw"},
        {StateID: 2468, CountryID: 170, DisplayName: "Meneng"},
        {StateID: 2467, CountryID: 170, DisplayName: "Nibok"},
        {StateID: 2466, CountryID: 170, DisplayName: "Uaboe"},
        {StateID: 2465, CountryID: 170, DisplayName: "Yaren"},
        {StateID: 2491, CountryID: 172, DisplayName: "Auckland"},
        {StateID: 2485, CountryID: 172, DisplayName: "Bay of Plenty"},
        {StateID: 2490, CountryID: 172, DisplayName: "Canterbury"},
        {StateID: 2492, CountryID: 172, DisplayName: "Chatham Islands"},
        {StateID: 2489, CountryID: 172, DisplayName: "Gisborne"},
        {StateID: 2488, CountryID: 172, DisplayName: "Hawke's Bay"},
        {StateID: 2480, CountryID: 172, DisplayName: "Manawatu-Wanganui"},
        {StateID: 2487, CountryID: 172, DisplayName: "Marlborough"},
        {StateID: 2493, CountryID: 172, DisplayName: "Nelson"},
        {StateID: 2486, CountryID: 172, DisplayName: "Northland"},
        {StateID: 2494, CountryID: 172, DisplayName: "Otago"},
        {StateID: 2484, CountryID: 172, DisplayName: "Southland"},
        {StateID: 2483, CountryID: 172, DisplayName: "Taranaki"},
        {StateID: 2482, CountryID: 172, DisplayName: "Tasman"},
        {StateID: 2481, CountryID: 172, DisplayName: "Waikato"},
        {StateID: 2479, CountryID: 172, DisplayName: "Wellington"},
        {StateID: 2495, CountryID: 172, DisplayName: "West Coast"},
        {StateID: 2496, CountryID: 173, DisplayName: "Ad Dakhiliyah"},
        {StateID: 2506, CountryID: 173, DisplayName: "Al Batinah North"},
        {StateID: 2497, CountryID: 173, DisplayName: "Al Batinah South"},
        {StateID: 2504, CountryID: 173, DisplayName: "Al Buraimi"},
        {StateID: 2498, CountryID: 173, DisplayName: "Al Wustá"},
        {StateID: 2500, CountryID: 173, DisplayName: "Az¸ Z¸ahirah"},
        {StateID: 2503, CountryID: 173, DisplayName: "Dhofar"},
        {StateID: 2502, CountryID: 173, DisplayName: "Musandam"},
        {StateID: 2501, CountryID: 173, DisplayName: "Muscat"},
        {StateID: 2505, CountryID: 173, DisplayName: "Northeastern Governorate"},
        {StateID: 2499, CountryID: 173, DisplayName: "Southeastern Governorate"},
        {StateID: 2516, CountryID: 174, DisplayName: "Bocas del Toro"},
        {StateID: 2515, CountryID: 174, DisplayName: "Chiriquí"},
        {StateID: 2514, CountryID: 174, DisplayName: "Coclé"},
        {StateID: 2513, CountryID: 174, DisplayName: "Colón"},
        {StateID: 2512, CountryID: 174, DisplayName: "Darién"},
        {StateID: 2517, CountryID: 174, DisplayName: "Emberá"},
        {StateID: 2508, CountryID: 174, DisplayName: "Guna Yala"},
        {StateID: 2511, CountryID: 174, DisplayName: "Herrera"},
        {StateID: 2510, CountryID: 174, DisplayName: "Los Santos"},
        {StateID: 2518, CountryID: 174, DisplayName: "Ngöbe-Buglé"},
        {StateID: 2509, CountryID: 174, DisplayName: "Panamá"},
        {StateID: 2519, CountryID: 174, DisplayName: "Panamá Oeste"},
        {StateID: 2507, CountryID: 174, DisplayName: "Veraguas"},
        {StateID: 2530, CountryID: 175, DisplayName: "Amazonas"},
        {StateID: 2529, CountryID: 175, DisplayName: "Ancash"},
        {StateID: 2545, CountryID: 175, DisplayName: "Apurímac"},
        {StateID: 2544, CountryID: 175, DisplayName: "Arequipa"},
        {StateID: 2543, CountryID: 175, DisplayName: "Ayacucho"},
        {StateID: 2528, CountryID: 175, DisplayName: "Cajamarca"},
        {StateID: 2542, CountryID: 175, DisplayName: "Callao"},
        {StateID: 2541, CountryID: 175, DisplayName: "Cusco"},
        {StateID: 2540, CountryID: 175, DisplayName: "Huancavelica"},
        {StateID: 2527, CountryID: 175, DisplayName: "Huanuco"},
        {StateID: 2539, CountryID: 175, DisplayName: "Ica"},
        {StateID: 2538, CountryID: 175, DisplayName: "Junin"},
        {StateID: 2526, CountryID: 175, DisplayName: "La Libertad"},
        {StateID: 2525, CountryID: 175, DisplayName: "Lambayeque"},
        {StateID: 2536, CountryID: 175, DisplayName: "Lima"},
        {StateID: 2537, CountryID: 175, DisplayName: "Lima region"},
        {StateID: 2524, CountryID: 175, DisplayName: "Loreto"},
        {StateID: 2535, CountryID: 175, DisplayName: "Madre de Dios"},
        {StateID: 2534, CountryID: 175, DisplayName: "Moquegua"},
        {StateID: 2533, CountryID: 175, DisplayName: "Pasco"},
        {StateID: 2523, CountryID: 175, DisplayName: "Piura"},
        {StateID: 2532, CountryID: 175, DisplayName: "Puno"},
        {StateID: 2522, CountryID: 175, DisplayName: "San Martín"},
        {StateID: 2531, CountryID: 175, DisplayName: "Tacna"},
        {StateID: 2521, CountryID: 175, DisplayName: "Tumbes"},
        {StateID: 2520, CountryID: 175, DisplayName: "Ucayali"},
        {StateID: 2550, CountryID: 176, DisplayName: "Îles Australes"},
        {StateID: 2549, CountryID: 176, DisplayName: "Îles du Vent"},
        {StateID: 2546, CountryID: 176, DisplayName: "Îles Marquises"},
        {StateID: 2547, CountryID: 176, DisplayName: "Îles Tuamotu-Gambier"},
        {StateID: 2548, CountryID: 176, DisplayName: "Leeward Islands"},
        {StateID: 2556, CountryID: 177, DisplayName: "Bougainville"},
        {StateID: 2570, CountryID: 177, DisplayName: "Central Province"},
        {StateID: 2568, CountryID: 177, DisplayName: "Chimbu"},
        {StateID: 2566, CountryID: 177, DisplayName: "East New Britain"},
        {StateID: 2565, CountryID: 177, DisplayName: "East Sepik"},
        {StateID: 2567, CountryID: 177, DisplayName: "Eastern Highlands"},
        {StateID: 2564, CountryID: 177, DisplayName: "Enga"},
        {StateID: 2563, CountryID: 177, DisplayName: "Gulf"},
        {StateID: 2571, CountryID: 177, DisplayName: "Hela"},
        {StateID: 2572, CountryID: 177, DisplayName: "Jiwaka"},
        {StateID: 2562, CountryID: 177, DisplayName: "Madang"},
        {StateID: 2561, CountryID: 177, DisplayName: "Manus"},
        {StateID: 2569, CountryID: 177, DisplayName: "Milne Bay"},
        {StateID: 2560, CountryID: 177, DisplayName: "Morobe"},
        {StateID: 2559, CountryID: 177, DisplayName: "National Capital"},
        {StateID: 2558, CountryID: 177, DisplayName: "New Ireland"},
        {StateID: 2557, CountryID: 177, DisplayName: "Northern Province"},
        {StateID: 2555, CountryID: 177, DisplayName: "Sandaun"},
        {StateID: 2554, CountryID: 177, DisplayName: "Southern Highlands"},
        {StateID: 2551, CountryID: 177, DisplayName: "West New Britain"},
        {StateID: 2553, CountryID: 177, DisplayName: "Western Highlands"},
        {StateID: 2552, CountryID: 177, DisplayName: "Western Province"},
        {StateID: 2573, CountryID: 178, DisplayName: "Autonomous Region in Muslim Mindanao"},
        {StateID: 2588, CountryID: 178, DisplayName: "Bicol"},
        {StateID: 2576, CountryID: 178, DisplayName: "Cagayan Valley"},
        {StateID: 2581, CountryID: 178, DisplayName: "Calabarzon"},
        {StateID: 2578, CountryID: 178, DisplayName: "Caraga"},
        {StateID: 2583, CountryID: 178, DisplayName: "Central Luzon"},
        {StateID: 2584, CountryID: 178, DisplayName: "Central Visayas"},
        {StateID: 2579, CountryID: 178, DisplayName: "Cordillera"},
        {StateID: 2587, CountryID: 178, DisplayName: "Davao"},
        {StateID: 2585, CountryID: 178, DisplayName: "Eastern Visayas"},
        {StateID: 2580, CountryID: 178, DisplayName: "Ilocos"},
        {StateID: 2589, CountryID: 178, DisplayName: "Metro Manila"},
        {StateID: 2575, CountryID: 178, DisplayName: "Mimaropa"},
        {StateID: 2574, CountryID: 178, DisplayName: "Northern Mindanao"},
        {StateID: 2577, CountryID: 178, DisplayName: "Soccsksargen"},
        {StateID: 2582, CountryID: 178, DisplayName: "Western Visayas"},
        {StateID: 2586, CountryID: 178, DisplayName: "Zamboanga Peninsula"},
        {StateID: 2596, CountryID: 179, DisplayName: "Azad Kashmir"},
        {StateID: 2595, CountryID: 179, DisplayName: "Balochistan"},
        {StateID: 2594, CountryID: 179, DisplayName: "Gilgit-Baltistan"},
        {StateID: 2590, CountryID: 179, DisplayName: "Islamabad"},
        {StateID: 2593, CountryID: 179, DisplayName: "Khyber Pakhtunkhwa"},
        {StateID: 2592, CountryID: 179, DisplayName: "Punjab"},
        {StateID: 2591, CountryID: 179, DisplayName: "Sindh"},
        {StateID: 2610, CountryID: 180, DisplayName: "Greater Poland"},
        {StateID: 2612, CountryID: 180, DisplayName: "Kujawsko-Pomorskie"},
        {StateID: 2598, CountryID: 180, DisplayName: "Lesser Poland"},
        {StateID: 2605, CountryID: 180, DisplayName: "Lódz Voivodeship"},
        {StateID: 2604, CountryID: 180, DisplayName: "Lower Silesia"},
        {StateID: 2597, CountryID: 180, DisplayName: "Lublin"},
        {StateID: 2606, CountryID: 180, DisplayName: "Lubusz"},
        {StateID: 2599, CountryID: 180, DisplayName: "Mazovia"},
        {StateID: 2607, CountryID: 180, DisplayName: "Opole Voivodeship"},
        {StateID: 2601, CountryID: 180, DisplayName: "Podlasie"},
        {StateID: 2608, CountryID: 180, DisplayName: "Pomerania"},
        {StateID: 2609, CountryID: 180, DisplayName: "Silesia"},
        {StateID: 2600, CountryID: 180, DisplayName: "Subcarpathia"},
        {StateID: 2602, CountryID: 180, DisplayName: "Swietokrzyskie"},
        {StateID: 2603, CountryID: 180, DisplayName: "Warmia-Masuria"},
        {StateID: 2611, CountryID: 180, DisplayName: "West Pomerania"},
        {StateID: 2614, CountryID: 181, DisplayName: "Miquelon-Langlade"},
        {StateID: 2613, CountryID: 181, DisplayName: "Saint-Pierre"},
        {StateID: 2615, CountryID: 183, DisplayName: "Adjuntas"},
        {StateID: 2616, CountryID: 183, DisplayName: "Aguada"},
        {StateID: 2617, CountryID: 183, DisplayName: "Aguadilla"},
        {StateID: 2618, CountryID: 183, DisplayName: "Aguas Buenas"},
        {StateID: 2619, CountryID: 183, DisplayName: "Aibonito"},
        {StateID: 2620, CountryID: 183, DisplayName: "Añasco"},
        {StateID: 2621, CountryID: 183, DisplayName: "Arecibo"},
        {StateID: 2622, CountryID: 183, DisplayName: "Arroyo"},
        {StateID: 2623, CountryID: 183, DisplayName: "Barceloneta"},
        {StateID: 2624, CountryID: 183, DisplayName: "Barranquitas"},
        {StateID: 2625, CountryID: 183, DisplayName: "Bayamón"},
        {StateID: 2626, CountryID: 183, DisplayName: "Cabo Rojo"},
        {StateID: 2627, CountryID: 183, DisplayName: "Caguas"},
        {StateID: 2628, CountryID: 183, DisplayName: "Camuy"},
        {StateID: 2629, CountryID: 183, DisplayName: "Canóvanas"},
        {StateID: 2630, CountryID: 183, DisplayName: "Carolina"},
        {StateID: 2631, CountryID: 183, DisplayName: "Cataño"},
        {StateID: 2632, CountryID: 183, DisplayName: "Cayey"},
        {StateID: 2633, CountryID: 183, DisplayName: "Ceiba"},
        {StateID: 2634, CountryID: 183, DisplayName: "Ciales"},
        {StateID: 2635, CountryID: 183, DisplayName: "Cidra"},
        {StateID: 2636, CountryID: 183, DisplayName: "Coamo"},
        {StateID: 2637, CountryID: 183, DisplayName: "Comerío"},
        {StateID: 2638, CountryID: 183, DisplayName: "Corozal"},
        {StateID: 2639, CountryID: 183, DisplayName: "Culebra"},
        {StateID: 2640, CountryID: 183, DisplayName: "Dorado"},
        {StateID: 2641, CountryID: 183, DisplayName: "Fajardo"},
        {StateID: 2642, CountryID: 183, DisplayName: "Florida"},
        {StateID: 2643, CountryID: 183, DisplayName: "Guánica"},
        {StateID: 2644, CountryID: 183, DisplayName: "Guayama"},
        {StateID: 2645, CountryID: 183, DisplayName: "Guayanilla"},
        {StateID: 2646, CountryID: 183, DisplayName: "Guaynabo"},
        {StateID: 2647, CountryID: 183, DisplayName: "Gurabo"},
        {StateID: 2648, CountryID: 183, DisplayName: "Hatillo"},
        {StateID: 2649, CountryID: 183, DisplayName: "Hormigueros"},
        {StateID: 2650, CountryID: 183, DisplayName: "Humacao"},
        {StateID: 2651, CountryID: 183, DisplayName: "Isabela"},
        {StateID: 2652, CountryID: 183, DisplayName: "Jayuya"},
        {StateID: 2653, CountryID: 183, DisplayName: "Juana Díaz"},
        {StateID: 2654, CountryID: 183, DisplayName: "Juncos"},
        {StateID: 2655, CountryID: 183, DisplayName: "Lajas"},
        {StateID: 2656, CountryID: 183, DisplayName: "Lares"},
        {StateID: 2657, CountryID: 183, DisplayName: "Las Marías"},
        {StateID: 2658, CountryID: 183, DisplayName: "Las Piedras"},
        {StateID: 2659, CountryID: 183, DisplayName: "Loíza"},
        {StateID: 2660, CountryID: 183, DisplayName: "Luquillo"},
        {StateID: 2661, CountryID: 183, DisplayName: "Manatí"},
        {StateID: 2662, CountryID: 183, DisplayName: "Maricao"},
        {StateID: 2663, CountryID: 183, DisplayName: "Maunabo"},
        {StateID: 2664, CountryID: 183, DisplayName: "Mayagüez"},
        {StateID: 2665, CountryID: 183, DisplayName: "Moca"},
        {StateID: 2666, CountryID: 183, DisplayName: "Morovis"},
        {StateID: 2667, CountryID: 183, DisplayName: "Naguabo"},
        {StateID: 2668, CountryID: 183, DisplayName: "Naranjito"},
        {StateID: 2669, CountryID: 183, DisplayName: "Orocovis"},
        {StateID: 2670, CountryID: 183, DisplayName: "Patillas"},
        {StateID: 2671, CountryID: 183, DisplayName: "Peñuelas"},
        {StateID: 2672, CountryID: 183, DisplayName: "Ponce"},
        {StateID: 2674, CountryID: 183, DisplayName: "Quebradillas"},
        {StateID: 2673, CountryID: 183, DisplayName: "Rincón"},
        {StateID: 2675, CountryID: 183, DisplayName: "Río Grande"},
        {StateID: 2676, CountryID: 183, DisplayName: "Sabana Grande"},
        {StateID: 2677, CountryID: 183, DisplayName: "Salinas"},
        {StateID: 2678, CountryID: 183, DisplayName: "San Germán"},
        {StateID: 2679, CountryID: 183, DisplayName: "San Juan"},
        {StateID: 2680, CountryID: 183, DisplayName: "San Lorenzo"},
        {StateID: 2681, CountryID: 183, DisplayName: "San Sebastián"},
        {StateID: 2682, CountryID: 183, DisplayName: "Santa Isabel"},
        {StateID: 2683, CountryID: 183, DisplayName: "Toa Alta"},
        {StateID: 2684, CountryID: 183, DisplayName: "Toa Baja"},
        {StateID: 2685, CountryID: 183, DisplayName: "Trujillo Alto"},
        {StateID: 2686, CountryID: 183, DisplayName: "Utuado"},
        {StateID: 2687, CountryID: 183, DisplayName: "Vega Alta"},
        {StateID: 2688, CountryID: 183, DisplayName: "Vega Baja"},
        {StateID: 2692, CountryID: 183, DisplayName: "Vieques"},
        {StateID: 2689, CountryID: 183, DisplayName: "Villalba"},
        {StateID: 2690, CountryID: 183, DisplayName: "Yabucoa"},
        {StateID: 2691, CountryID: 183, DisplayName: "Yauco"},
        {StateID: 2693, CountryID: 184, DisplayName: "Gaza Strip"},
        {StateID: 2694, CountryID: 184, DisplayName: "West Bank"},
        {StateID: 2713, CountryID: 185, DisplayName: "Aveiro"},
        {StateID: 2714, CountryID: 185, DisplayName: "Azores"},
        {StateID: 2703, CountryID: 185, DisplayName: "Beja"},
        {StateID: 2712, CountryID: 185, DisplayName: "Braga"},
        {StateID: 2711, CountryID: 185, DisplayName: "Bragança"},
        {StateID: 2702, CountryID: 185, DisplayName: "Castelo Branco"},
        {StateID: 2710, CountryID: 185, DisplayName: "Coimbra"},
        {StateID: 2695, CountryID: 185, DisplayName: "District of Setúbal"},
        {StateID: 2701, CountryID: 185, DisplayName: "Évora"},
        {StateID: 2700, CountryID: 185, DisplayName: "Faro"},
        {StateID: 2709, CountryID: 185, DisplayName: "Guarda"},
        {StateID: 2699, CountryID: 185, DisplayName: "Leiria"},
        {StateID: 2698, CountryID: 185, DisplayName: "Lisbon"},
        {StateID: 2704, CountryID: 185, DisplayName: "Madeira"},
        {StateID: 2697, CountryID: 185, DisplayName: "Portalegre"},
        {StateID: 2708, CountryID: 185, DisplayName: "Porto"},
        {StateID: 2696, CountryID: 185, DisplayName: "Santarém"},
        {StateID: 2707, CountryID: 185, DisplayName: "Viana do Castelo"},
        {StateID: 2706, CountryID: 185, DisplayName: "Vila Real"},
        {StateID: 2705, CountryID: 185, DisplayName: "Viseu"},
        {StateID: 2719, CountryID: 186, DisplayName: "Aimeliik"},
        {StateID: 2720, CountryID: 186, DisplayName: "Airai"},
        {StateID: 2721, CountryID: 186, DisplayName: "Angaur"},
        {StateID: 2718, CountryID: 186, DisplayName: "Hatohobei"},
        {StateID: 2717, CountryID: 186, DisplayName: "Kayangel"},
        {StateID: 2722, CountryID: 186, DisplayName: "Koror"},
        {StateID: 2723, CountryID: 186, DisplayName: "Melekeok"},
        {StateID: 2724, CountryID: 186, DisplayName: "Ngaraard"},
        {StateID: 2726, CountryID: 186, DisplayName: "Ngarchelong"},
        {StateID: 2727, CountryID: 186, DisplayName: "Ngardmau"},
        {StateID: 2728, CountryID: 186, DisplayName: "Ngaremlengui"},
        {StateID: 2715, CountryID: 186, DisplayName: "Ngatpang"},
        {StateID: 2725, CountryID: 186, DisplayName: "Ngchesar"},
        {StateID: 2729, CountryID: 186, DisplayName: "Ngiwal"},
        {StateID: 2730, CountryID: 186, DisplayName: "Peleliu"},
        {StateID: 2716, CountryID: 186, DisplayName: "Sonsorol"},
        {StateID: 2746, CountryID: 187, DisplayName: "Alto Paraguay"},
        {StateID: 2745, CountryID: 187, DisplayName: "Alto Paraná"},
        {StateID: 2744, CountryID: 187, DisplayName: "Amambay"},
        {StateID: 2747, CountryID: 187, DisplayName: "Asunción"},
        {StateID: 2748, CountryID: 187, DisplayName: "Boquerón"},
        {StateID: 2743, CountryID: 187, DisplayName: "Caaguazú"},
        {StateID: 2742, CountryID: 187, DisplayName: "Caazapá"},
        {StateID: 2741, CountryID: 187, DisplayName: "Canindeyú"},
        {StateID: 2740, CountryID: 187, DisplayName: "Central"},
        {StateID: 2739, CountryID: 187, DisplayName: "Concepción"},
        {StateID: 2738, CountryID: 187, DisplayName: "Cordillera"},
        {StateID: 2737, CountryID: 187, DisplayName: "Guairá"},
        {StateID: 2736, CountryID: 187, DisplayName: "Itapúa"},
        {StateID: 2735, CountryID: 187, DisplayName: "Misiones"},
        {StateID: 2734, CountryID: 187, DisplayName: "Ñeembucú"},
        {StateID: 2733, CountryID: 187, DisplayName: "Paraguarí"},
        {StateID: 2732, CountryID: 187, DisplayName: "Presidente Hayes"},
        {StateID: 2731, CountryID: 187, DisplayName: "San Pedro"},
        {StateID: 2750, CountryID: 188, DisplayName: "Al Khor"},
        {StateID: 2754, CountryID: 188, DisplayName: "Al Wakrah"},
        {StateID: 2756, CountryID: 188, DisplayName: "Al-Shahaniya"},
        {StateID: 2753, CountryID: 188, DisplayName: "Baladiyat ad Daw?ah"},
        {StateID: 2752, CountryID: 188, DisplayName: "Baladiyat ar Rayyan"},
        {StateID: 2755, CountryID: 188, DisplayName: "Baladiyat az¸ Z¸a‘ayin"},
        {StateID: 2751, CountryID: 188, DisplayName: "Baladiyat Umm Salal"},
        {StateID: 2749, CountryID: 188, DisplayName: "Madinat ash Shamal"},
        {StateID: 2757, CountryID: 189, DisplayName: "Réunion"},
        {StateID: 2798, CountryID: 190, DisplayName: "Alba"},
        {StateID: 2797, CountryID: 190, DisplayName: "Arad"},
        {StateID: 2796, CountryID: 190, DisplayName: "Arges"},
        {StateID: 2795, CountryID: 190, DisplayName: "Bacau"},
        {StateID: 2794, CountryID: 190, DisplayName: "Bihor"},
        {StateID: 2793, CountryID: 190, DisplayName: "Bistrita-Nasaud"},
        {StateID: 2792, CountryID: 190, DisplayName: "Botosani"},
        {StateID: 2791, CountryID: 190, DisplayName: "Braila"},
        {StateID: 2790, CountryID: 190, DisplayName: "Brasov"},
        {StateID: 2789, CountryID: 190, DisplayName: "Bucuresti"},
        {StateID: 2788, CountryID: 190, DisplayName: "Buzau"},
        {StateID: 2787, CountryID: 190, DisplayName: "Calarasi"},
        {StateID: 2786, CountryID: 190, DisplayName: "Caras-Severin"},
        {StateID: 2785, CountryID: 190, DisplayName: "Cluj"},
        {StateID: 2784, CountryID: 190, DisplayName: "Constan?a"},
        {StateID: 2783, CountryID: 190, DisplayName: "Covasna"},
        {StateID: 2782, CountryID: 190, DisplayName: "Dâmbovita"},
        {StateID: 2781, CountryID: 190, DisplayName: "Dolj"},
        {StateID: 2780, CountryID: 190, DisplayName: "Galati"},
        {StateID: 2779, CountryID: 190, DisplayName: "Giurgiu"},
        {StateID: 2778, CountryID: 190, DisplayName: "Gorj"},
        {StateID: 2777, CountryID: 190, DisplayName: "Harghita"},
        {StateID: 2776, CountryID: 190, DisplayName: "Hunedoara"},
        {StateID: 2775, CountryID: 190, DisplayName: "Ialomita"},
        {StateID: 2774, CountryID: 190, DisplayName: "Iasi"},
        {StateID: 2799, CountryID: 190, DisplayName: "Ilfov"},
        {StateID: 2773, CountryID: 190, DisplayName: "Maramures"},
        {StateID: 2772, CountryID: 190, DisplayName: "Mehedinti"},
        {StateID: 2771, CountryID: 190, DisplayName: "Mures"},
        {StateID: 2770, CountryID: 190, DisplayName: "Neamt"},
        {StateID: 2769, CountryID: 190, DisplayName: "Olt"},
        {StateID: 2768, CountryID: 190, DisplayName: "Prahova"},
        {StateID: 2767, CountryID: 190, DisplayName: "Salaj"},
        {StateID: 2766, CountryID: 190, DisplayName: "Satu Mare"},
        {StateID: 2765, CountryID: 190, DisplayName: "Sibiu"},
        {StateID: 2764, CountryID: 190, DisplayName: "Suceava"},
        {StateID: 2763, CountryID: 190, DisplayName: "Teleorman"},
        {StateID: 2762, CountryID: 190, DisplayName: "Timis"},
        {StateID: 2761, CountryID: 190, DisplayName: "Tulcea"},
        {StateID: 2759, CountryID: 190, DisplayName: "Vâlcea"},
        {StateID: 2760, CountryID: 190, DisplayName: "Vaslui"},
        {StateID: 2758, CountryID: 190, DisplayName: "Vrancea"},
        {StateID: 2801, CountryID: 191, DisplayName: "Central Serbia"},
        {StateID: 2800, CountryID: 191, DisplayName: "Vojvodina"},
        {StateID: 2856, CountryID: 192, DisplayName: "Adygeya Republic"},
        {StateID: 2870, CountryID: 192, DisplayName: "Altai"},
        {StateID: 2872, CountryID: 192, DisplayName: "Altai Krai"},
        {StateID: 2878, CountryID: 192, DisplayName: "Amur Oblast"},
        {StateID: 2855, CountryID: 192, DisplayName: "Arkhangelskaya"},
        {StateID: 2854, CountryID: 192, DisplayName: "Astrakhan"},
        {StateID: 2853, CountryID: 192, DisplayName: "Bashkortostan Republic"},
        {StateID: 2852, CountryID: 192, DisplayName: "Belgorod Oblast"},
        {StateID: 2851, CountryID: 192, DisplayName: "Bryansk Oblast"},
        {StateID: 2879, CountryID: 192, DisplayName: "Buryatiya Republic"},
        {StateID: 2850, CountryID: 192, DisplayName: "Chechnya"},
        {StateID: 2871, CountryID: 192, DisplayName: "Chelyabinsk"},
        {StateID: 2883, CountryID: 192, DisplayName: "Chukotka"},
        {StateID: 2849, CountryID: 192, DisplayName: "Chuvashia"},
        {StateID: 2848, CountryID: 192, DisplayName: "Dagestan"},
        {StateID: 2846, CountryID: 192, DisplayName: "Ingushetiya Republic"},
        {StateID: 2876, CountryID: 192, DisplayName: "Irkutsk Oblast"},
        {StateID: 2845, CountryID: 192, DisplayName: "Ivanovo"},
        {StateID: 2877, CountryID: 192, DisplayName: "Jewish Autonomous Oblast"},
        {StateID: 2844, CountryID: 192, DisplayName: "Kabardino-Balkariya Republic"},
        {StateID: 2843, CountryID: 192, DisplayName: "Kaliningrad"},
        {StateID: 2842, CountryID: 192, DisplayName: "Kalmykiya Republic"},
        {StateID: 2841, CountryID: 192, DisplayName: "Kaluga"},
        {StateID: 2882, CountryID: 192, DisplayName: "Kamchatka"},
        {StateID: 2840, CountryID: 192, DisplayName: "Karachayevo-Cherkesiya Republic"},
        {StateID: 2839, CountryID: 192, DisplayName: "Karelia"},
        {StateID: 2869, CountryID: 192, DisplayName: "Kemerovo Oblast"},
        {StateID: 2875, CountryID: 192, DisplayName: "Khabarovsk"},
        {StateID: 2868, CountryID: 192, DisplayName: "Khakasiya Republic"},
        {StateID: 2867, CountryID: 192, DisplayName: "Khanty-Mansia"},
        {StateID: 2838, CountryID: 192, DisplayName: "Kirov"},
        {StateID: 2837, CountryID: 192, DisplayName: "Komi"},
        {StateID: 2836, CountryID: 192, DisplayName: "Kostroma Oblast"},
        {StateID: 2835, CountryID: 192, DisplayName: "Krasnodarskiy"},
        {StateID: 2866, CountryID: 192, DisplayName: "Krasnoyarskiy"},
        {StateID: 2865, CountryID: 192, DisplayName: "Kurgan Oblast"},
        {StateID: 2834, CountryID: 192, DisplayName: "Kursk"},
        {StateID: 2832, CountryID: 192, DisplayName: "Leningradskaya Oblast'"},
        {StateID: 2831, CountryID: 192, DisplayName: "Lipetsk Oblast"},
        {StateID: 2881, CountryID: 192, DisplayName: "Magadan Oblast"},
        {StateID: 2830, CountryID: 192, DisplayName: "Mariy-El Republic"},
        {StateID: 2829, CountryID: 192, DisplayName: "Mordoviya Republic"},
        {StateID: 2827, CountryID: 192, DisplayName: "Moscow"},
        {StateID: 2828, CountryID: 192, DisplayName: "Moscow Oblast"},
        {StateID: 2826, CountryID: 192, DisplayName: "Murmansk"},
        {StateID: 2825, CountryID: 192, DisplayName: "Nenets"},
        {StateID: 2847, CountryID: 192, DisplayName: "Nizhny Novgorod Oblast"},
        {StateID: 2824, CountryID: 192, DisplayName: "North Ossetia"},
        {StateID: 2823, CountryID: 192, DisplayName: "Novgorod Oblast"},
        {StateID: 2864, CountryID: 192, DisplayName: "Novosibirsk Oblast"},
        {StateID: 2863, CountryID: 192, DisplayName: "Omsk"},
        {StateID: 2821, CountryID: 192, DisplayName: "Orel Oblast"},
        {StateID: 2822, CountryID: 192, DisplayName: "Orenburg Oblast"},
        {StateID: 2820, CountryID: 192, DisplayName: "Penza"},
        {StateID: 2819, CountryID: 192, DisplayName: "Perm"},
        {StateID: 2874, CountryID: 192, DisplayName: "Primorskiy (Maritime) Kray"},
        {StateID: 2818, CountryID: 192, DisplayName: "Pskov Oblast"},
        {StateID: 2860, CountryID: 192, DisplayName: "Republic of Tyva"},
        {StateID: 2817, CountryID: 192, DisplayName: "Rostov"},
        {StateID: 2816, CountryID: 192, DisplayName: "Ryazan Oblast"},
        {StateID: 2873, CountryID: 192, DisplayName: "Sakha"},
        {StateID: 2880, CountryID: 192, DisplayName: "Sakhalin Oblast"},
        {StateID: 2815, CountryID: 192, DisplayName: "Samara Oblast"},
        {StateID: 2814, CountryID: 192, DisplayName: "Saratovskaya Oblast"},
        {StateID: 2813, CountryID: 192, DisplayName: "Smolenskaya Oblast’"},
        {StateID: 2833, CountryID: 192, DisplayName: "St.-Petersburg"},
        {StateID: 2812, CountryID: 192, DisplayName: "Stavropol’ Kray"},
        {StateID: 2862, CountryID: 192, DisplayName: "Sverdlovsk"},
        {StateID: 2811, CountryID: 192, DisplayName: "Tambov"},
        {StateID: 2810, CountryID: 192, DisplayName: "Tatarstan Republic"},
        {StateID: 2861, CountryID: 192, DisplayName: "Tomsk Oblast"},
        {StateID: 2884, CountryID: 192, DisplayName: "Transbaikal Territory"},
        {StateID: 2809, CountryID: 192, DisplayName: "Tula"},
        {StateID: 2808, CountryID: 192, DisplayName: "Tver’ Oblast"},
        {StateID: 2859, CountryID: 192, DisplayName: "Tyumen’ Oblast"},
        {StateID: 2807, CountryID: 192, DisplayName: "Udmurtiya Republic"},
        {StateID: 2806, CountryID: 192, DisplayName: "Ulyanovsk"},
        {StateID: 2857, CountryID: 192, DisplayName: "Vladimir"},
        {StateID: 2805, CountryID: 192, DisplayName: "Volgograd Oblast"},
        {StateID: 2804, CountryID: 192, DisplayName: "Vologda"},
        {StateID: 2803, CountryID: 192, DisplayName: "Voronezj"},
        {StateID: 2858, CountryID: 192, DisplayName: "Yamalo-Nenets"},
        {StateID: 2802, CountryID: 192, DisplayName: "Yaroslavl Oblast"},
        {StateID: 2885, CountryID: 193, DisplayName: "Eastern Province"},
        {StateID: 2886, CountryID: 193, DisplayName: "Kigali"},
        {StateID: 2887, CountryID: 193, DisplayName: "Northern Province"},
        {StateID: 2889, CountryID: 193, DisplayName: "Southern Province"},
        {StateID: 2888, CountryID: 193, DisplayName: "Western Province"},
        {StateID: 2895, CountryID: 194, DisplayName: "'Asir Region"},
        {StateID: 2902, CountryID: 194, DisplayName: "Al Bahah Region"},
        {StateID: 2900, CountryID: 194, DisplayName: "Al Jawf Region"},
        {StateID: 2898, CountryID: 194, DisplayName: "Al-Qassim Region"},
        {StateID: 2896, CountryID: 194, DisplayName: "Eastern Province"},
        {StateID: 2894, CountryID: 194, DisplayName: "Ha'il Region"},
        {StateID: 2893, CountryID: 194, DisplayName: "Jazan Region"},
        {StateID: 2892, CountryID: 194, DisplayName: "Mecca Region"},
        {StateID: 2899, CountryID: 194, DisplayName: "Medina Region"},
        {StateID: 2891, CountryID: 194, DisplayName: "Najran Region"},
        {StateID: 2901, CountryID: 194, DisplayName: "Northern Borders Region"},
        {StateID: 2897, CountryID: 194, DisplayName: "Riyadh Region"},
        {StateID: 2890, CountryID: 194, DisplayName: "Tabuk Region"},
        {StateID: 2907, CountryID: 195, DisplayName: "Central Province"},
        {StateID: 2910, CountryID: 195, DisplayName: "Choiseul"},
        {StateID: 2906, CountryID: 195, DisplayName: "Guadalcanal"},
        {StateID: 2912, CountryID: 195, DisplayName: "Honiara"},
        {StateID: 2905, CountryID: 195, DisplayName: "Isabel"},
        {StateID: 2909, CountryID: 195, DisplayName: "Makira"},
        {StateID: 2904, CountryID: 195, DisplayName: "Malaita"},
        {StateID: 2911, CountryID: 195, DisplayName: "Rennell and Bellona"},
        {StateID: 2908, CountryID: 195, DisplayName: "Temotu"},
        {StateID: 2903, CountryID: 195, DisplayName: "Western Province"},
        {StateID: 2933, CountryID: 196, DisplayName: "Anse Boileau"},
        {StateID: 2932, CountryID: 196, DisplayName: "Anse Etoile"},
        {StateID: 2931, CountryID: 196, DisplayName: "Anse Royale"},
        {StateID: 2934, CountryID: 196, DisplayName: "Anse-aux-Pins"},
        {StateID: 2937, CountryID: 196, DisplayName: "Au Cap"},
        {StateID: 2930, CountryID: 196, DisplayName: "Baie Lazare"},
        {StateID: 2929, CountryID: 196, DisplayName: "Baie Sainte Anne"},
        {StateID: 2928, CountryID: 196, DisplayName: "Beau Vallon"},
        {StateID: 2927, CountryID: 196, DisplayName: "Bel Air"},
        {StateID: 2926, CountryID: 196, DisplayName: "Bel Ombre"},
        {StateID: 2925, CountryID: 196, DisplayName: "Cascade"},
        {StateID: 2920, CountryID: 196, DisplayName: "English River"},
        {StateID: 2924, CountryID: 196, DisplayName: "Glacis"},
        {StateID: 2922, CountryID: 196, DisplayName: "Grand Anse Mahe"},
        {StateID: 2923, CountryID: 196, DisplayName: "Grand Anse Praslin"},
        {StateID: 2939, CountryID: 196, DisplayName: "Ile Perseverance I"},
        {StateID: 2940, CountryID: 196, DisplayName: "Ile Perseverance II"},
        {StateID: 2921, CountryID: 196, DisplayName: "La Digue"},
        {StateID: 2935, CountryID: 196, DisplayName: "Les Mamelles"},
        {StateID: 2919, CountryID: 196, DisplayName: "Mont Buxton"},
        {StateID: 2918, CountryID: 196, DisplayName: "Mont Fleuri"},
        {StateID: 2938, CountryID: 196, DisplayName: "Outer Islands"},
        {StateID: 2917, CountryID: 196, DisplayName: "Plaisance"},
        {StateID: 2916, CountryID: 196, DisplayName: "Pointe Larue"},
        {StateID: 2915, CountryID: 196, DisplayName: "Port Glaud"},
        {StateID: 2936, CountryID: 196, DisplayName: "Roche Caiman"},
        {StateID: 2914, CountryID: 196, DisplayName: "Saint Louis"},
        {StateID: 2913, CountryID: 196, DisplayName: "Takamaka"},
        {StateID: 2944, CountryID: 197, DisplayName: "Al Jazirah"},
        {StateID: 2945, CountryID: 197, DisplayName: "Al Qa?arif"},
        {StateID: 2947, CountryID: 197, DisplayName: "Blue Nile"},
        {StateID: 2958, CountryID: 197, DisplayName: "Central Darfur"},
        {StateID: 2957, CountryID: 197, DisplayName: "Eastern Darfur"},
        {StateID: 2952, CountryID: 197, DisplayName: "Kassala"},
        {StateID: 2942, CountryID: 197, DisplayName: "Khartoum"},
        {StateID: 2955, CountryID: 197, DisplayName: "North Kordofan"},
        {StateID: 2954, CountryID: 197, DisplayName: "Northern Darfur"},
        {StateID: 2941, CountryID: 197, DisplayName: "Northern State"},
        {StateID: 2943, CountryID: 197, DisplayName: "Red Sea"},
        {StateID: 2953, CountryID: 197, DisplayName: "River Nile"},
        {StateID: 2956, CountryID: 197, DisplayName: "Sinnar"},
        {StateID: 2950, CountryID: 197, DisplayName: "Southern Darfur"},
        {StateID: 2951, CountryID: 197, DisplayName: "Southern Kordofan"},
        {StateID: 2949, CountryID: 197, DisplayName: "West Kordofan State"},
        {StateID: 2948, CountryID: 197, DisplayName: "Western Darfur"},
        {StateID: 2946, CountryID: 197, DisplayName: "White Nile"},
        {StateID: 2977, CountryID: 199, DisplayName: "Blekinge"},
        {StateID: 2970, CountryID: 199, DisplayName: "Dalarna"},
        {StateID: 2976, CountryID: 199, DisplayName: "Gävleborg"},
        {StateID: 2975, CountryID: 199, DisplayName: "Gotland"},
        {StateID: 2974, CountryID: 199, DisplayName: "Halland"},
        {StateID: 2973, CountryID: 199, DisplayName: "Jämtland"},
        {StateID: 2972, CountryID: 199, DisplayName: "Jönköping"},
        {StateID: 2971, CountryID: 199, DisplayName: "Kalmar"},
        {StateID: 2969, CountryID: 199, DisplayName: "Kronoberg"},
        {StateID: 2959, CountryID: 199, DisplayName: "Norrbotten"},
        {StateID: 2968, CountryID: 199, DisplayName: "Örebro"},
        {StateID: 2967, CountryID: 199, DisplayName: "Östergötland"},
        {StateID: 2978, CountryID: 199, DisplayName: "Skåne"},
        {StateID: 2966, CountryID: 199, DisplayName: "Södermanland"},
        {StateID: 2965, CountryID: 199, DisplayName: "Stockholm"},
        {StateID: 2964, CountryID: 199, DisplayName: "Uppsala"},
        {StateID: 2963, CountryID: 199, DisplayName: "Värmland"},
        {StateID: 2962, CountryID: 199, DisplayName: "Västerbotten"},
        {StateID: 2961, CountryID: 199, DisplayName: "Västernorrland"},
        {StateID: 2960, CountryID: 199, DisplayName: "Västmanland"},
        {StateID: 2979, CountryID: 199, DisplayName: "Västra Götaland"},
        {StateID: 2980, CountryID: 201, DisplayName: "Ascension"},
        {StateID: 2982, CountryID: 201, DisplayName: "Saint Helena"},
        {StateID: 2981, CountryID: 201, DisplayName: "Tristan da Cunha"},
        {StateID: 2995, CountryID: 202, DisplayName: "Škofja Loka"},
        {StateID: 3043, CountryID: 202, DisplayName: "Škofljica"},
        {StateID: 2991, CountryID: 202, DisplayName: "Šmarje pri Jelšah"},
        {StateID: 2983, CountryID: 202, DisplayName: "Žalec"},
        {StateID: 3035, CountryID: 202, DisplayName: "Ajdovšcina"},
        {StateID: 3046, CountryID: 202, DisplayName: "Borovnica"},
        {StateID: 3034, CountryID: 202, DisplayName: "Brežice"},
        {StateID: 3045, CountryID: 202, DisplayName: "Brezovica"},
        {StateID: 3033, CountryID: 202, DisplayName: "Celje"},
        {StateID: 3032, CountryID: 202, DisplayName: "Cerknica"},
        {StateID: 3031, CountryID: 202, DisplayName: "Crnomelj"},
        {StateID: 3037, CountryID: 202, DisplayName: "Divaca"},
        {StateID: 3030, CountryID: 202, DisplayName: "Domžale"},
        {StateID: 3029, CountryID: 202, DisplayName: "Dravograd"},
        {StateID: 3028, CountryID: 202, DisplayName: "Gornja Radgona"},
        {StateID: 3027, CountryID: 202, DisplayName: "Grosuplje"},
        {StateID: 3026, CountryID: 202, DisplayName: "Hrastnik"},
        {StateID: 3036, CountryID: 202, DisplayName: "Hrpelje-Kozina"},
        {StateID: 3025, CountryID: 202, DisplayName: "Idrija"},
        {StateID: 3044, CountryID: 202, DisplayName: "Ig"},
        {StateID: 3024, CountryID: 202, DisplayName: "Ilirska Bistrica"},
        {StateID: 3023, CountryID: 202, DisplayName: "Izola-Isola"},
        {StateID: 3022, CountryID: 202, DisplayName: "Jesenice"},
        {StateID: 3021, CountryID: 202, DisplayName: "Kamnik"},
        {StateID: 3020, CountryID: 202, DisplayName: "Kocevje"},
        {StateID: 3048, CountryID: 202, DisplayName: "Komen"},
        {StateID: 3019, CountryID: 202, DisplayName: "Koper-Capodistria"},
        {StateID: 3017, CountryID: 202, DisplayName: "Krško"},
        {StateID: 3018, CountryID: 202, DisplayName: "Kranj"},
        {StateID: 3016, CountryID: 202, DisplayName: "Laško"},
        {StateID: 3015, CountryID: 202, DisplayName: "Lenart"},
        {StateID: 3014, CountryID: 202, DisplayName: "Litija"},
        {StateID: 3013, CountryID: 202, DisplayName: "Ljutomer"},
        {StateID: 3039, CountryID: 202, DisplayName: "Loška Dolina"},
        {StateID: 3040, CountryID: 202, DisplayName: "Loški Potok"},
        {StateID: 3012, CountryID: 202, DisplayName: "Logatec"},
        {StateID: 3011, CountryID: 202, DisplayName: "Maribor"},
        {StateID: 3010, CountryID: 202, DisplayName: "Metlika"},
        {StateID: 3009, CountryID: 202, DisplayName: "Mozirje"},
        {StateID: 3008, CountryID: 202, DisplayName: "Murska Sobota"},
        {StateID: 3007, CountryID: 202, DisplayName: "Nova Gorica"},
        {StateID: 3006, CountryID: 202, DisplayName: "Novo Mesto"},
        {StateID: 3005, CountryID: 202, DisplayName: "Ormož"},
        {StateID: 3041, CountryID: 202, DisplayName: "Osilnica"},
        {StateID: 3004, CountryID: 202, DisplayName: "Piran-Pirano"},
        {StateID: 3038, CountryID: 202, DisplayName: "Pivka"},
        {StateID: 3003, CountryID: 202, DisplayName: "Postojna"},
        {StateID: 3002, CountryID: 202, DisplayName: "Ptuj"},
        {StateID: 3001, CountryID: 202, DisplayName: "Radlje ob Dravi"},
        {StateID: 3000, CountryID: 202, DisplayName: "Radovljica"},
        {StateID: 2999, CountryID: 202, DisplayName: "Ribnica"},
        {StateID: 2996, CountryID: 202, DisplayName: "Sežana"},
        {StateID: 2998, CountryID: 202, DisplayName: "Sentjur"},
        {StateID: 2997, CountryID: 202, DisplayName: "Sevnica"},
        {StateID: 2994, CountryID: 202, DisplayName: "Slovenj Gradec"},
        {StateID: 2993, CountryID: 202, DisplayName: "Slovenska Bistrica"},
        {StateID: 2992, CountryID: 202, DisplayName: "Slovenska Konjice"},
        {StateID: 2989, CountryID: 202, DisplayName: "Tolmin"},
        {StateID: 2986, CountryID: 202, DisplayName: "Tržic"},
        {StateID: 2988, CountryID: 202, DisplayName: "Trbovlje"},
        {StateID: 2987, CountryID: 202, DisplayName: "Trebnje"},
        {StateID: 2990, CountryID: 202, DisplayName: "Velenje"},
        {StateID: 3042, CountryID: 202, DisplayName: "Velike Lašce"},
        {StateID: 3047, CountryID: 202, DisplayName: "Vipava"},
        {StateID: 2985, CountryID: 202, DisplayName: "Vrhnika"},
        {StateID: 2984, CountryID: 202, DisplayName: "Zagorje ob Savi"},
        {StateID: 14, CountryID: 234, DisplayName: "Alabama"},
        {StateID: 15, CountryID: 234, DisplayName: "Alaska"},
        {StateID: 16, CountryID: 234, DisplayName: "Arizona"},
        {StateID: 17, CountryID: 234, DisplayName: "Arkansas"},
        {StateID: 18, CountryID: 234, DisplayName: "Armed Forces Americas"},
        {StateID: 19, CountryID: 234, DisplayName: "Armed Forces Europe"},
        {StateID: 20, CountryID: 234, DisplayName: "Armed Forces Pacific"},
        {StateID: 21, CountryID: 234, DisplayName: "California"},
        {StateID: 22, CountryID: 234, DisplayName: "Colorado"},
        {StateID: 23, CountryID: 234, DisplayName: "Connecticut"},
        {StateID: 24, CountryID: 234, DisplayName: "Delaware"},
        {StateID: 25, CountryID: 234, DisplayName: "Florida"},
        {StateID: 26, CountryID: 234, DisplayName: "Georgia"},
        {StateID: 27, CountryID: 234, DisplayName: "Hawaii"},
        {StateID: 28, CountryID: 234, DisplayName: "Idaho"},
        {StateID: 29, CountryID: 234, DisplayName: "Illinois"},
        {StateID: 30, CountryID: 234, DisplayName: "Indiana"},
        {StateID: 31, CountryID: 234, DisplayName: "Iowa"},
        {StateID: 32, CountryID: 234, DisplayName: "Kansas"},
        {StateID: 33, CountryID: 234, DisplayName: "Kentucky"},
        {StateID: 34, CountryID: 234, DisplayName: "Louisiana"},
        {StateID: 35, CountryID: 234, DisplayName: "Maine"},
        {StateID: 36, CountryID: 234, DisplayName: "Maryland"},
        {StateID: 37, CountryID: 234, DisplayName: "Massachusetts"},
        {StateID: 38, CountryID: 234, DisplayName: "Michigan"},
        {StateID: 39, CountryID: 234, DisplayName: "Minnesota"},
        {StateID: 40, CountryID: 234, DisplayName: "Mississippi"},
        {StateID: 41, CountryID: 234, DisplayName: "Missouri"},
        {StateID: 42, CountryID: 234, DisplayName: "Montana"},
        {StateID: 43, CountryID: 234, DisplayName: "Nebraska"},
        {StateID: 44, CountryID: 234, DisplayName: "Nevada"},
        {StateID: 45, CountryID: 234, DisplayName: "New Hampshire"},
        {StateID: 46, CountryID: 234, DisplayName: "New Jersey"},
        {StateID: 47, CountryID: 234, DisplayName: "New Mexico"},
        {StateID: 48, CountryID: 234, DisplayName: "New York"},
        {StateID: 49, CountryID: 234, DisplayName: "North Carolina"},
        {StateID: 50, CountryID: 234, DisplayName: "North Dakota"},
        {StateID: 51, CountryID: 234, DisplayName: "Ohio"},
        {StateID: 52, CountryID: 234, DisplayName: "Oklahoma"},
        {StateID: 53, CountryID: 234, DisplayName: "Oregon"},
        {StateID: 54, CountryID: 234, DisplayName: "Pennsylvania"},
        {StateID: 55, CountryID: 234, DisplayName: "Rhode Island"},
        {StateID: 56, CountryID: 234, DisplayName: "South Carolina"},
        {StateID: 57, CountryID: 234, DisplayName: "South Dakota"},
        {StateID: 58, CountryID: 234, DisplayName: "Tennessee"},
        {StateID: 59, CountryID: 234, DisplayName: "Texas"},
        {StateID: 60, CountryID: 234, DisplayName: "Utah"},
        {StateID: 61, CountryID: 234, DisplayName: "Vermont"},
        {StateID: 62, CountryID: 234, DisplayName: "Virginia"},
        {StateID: 63, CountryID: 234, DisplayName: "Washington"},
        {StateID: 64, CountryID: 234, DisplayName: "Washington, D.C."},
        {StateID: 65, CountryID: 234, DisplayName: "West Virginia"},
        {StateID: 66, CountryID: 234, DisplayName: "Wisconsin"},
        {StateID: 67, CountryID: 234, DisplayName: "Wyoming"}]
