//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[ResearchProjectContactType]

export enum ResearchProjectContactTypeEnum {
  Organization = 1,
  PrincipalInvestigator = 2,
  PersonInChargeOfFieldWork = 3,
  AdditionalParticipant = 4
}
