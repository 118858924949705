import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PermitFormComponent, PermitUpsertDto} from '../shared/permit-form/permit-form.component';

@Component({
    selector: 'castateparksscp-permit-create',
    templateUrl: './permit-create.component.html',
    styleUrl: './permit-create.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createNewPermitForm", {read: PermitFormComponent}) form: PermitFormComponent;

    public permit: PermitUpsertDto = new PermitUpsertDto();
    public permitUpsertDto: PermitUpsertDto;
    private permitResponse: any;

    editMode = true;
    useCDS: boolean = true;
    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        //      this.permitUpsertDto = this.currentPermitService.createPermitDto(this.permit);
        this.permitUpsertDto = {} as PermitUpsertDto;
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    save(result): void {
        if (!!result) {
            this.permitResponse = result;
            this.router.navigateByUrl(`/permits/${result.PermitID}`);
        } else {
            this.cdr.detectChanges();
        }
    }

    cancelEdit() {
        this.router.navigateByUrl("/permits");
    }

    canExit() {
        return true;
        // if (this.permitResponse) {
        //     return true;
        // } else {
        //     return JSON.stringify(this.permitUpsertDto) === JSON.stringify(this.form.permitUpsertDto);
        // }
    }
}
