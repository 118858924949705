import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditViewEventService {
  editButtonClicked = new Subject();
    
  constructor() {}

  listenForEditButtonClicked():Observable<any> {
      return this.editButtonClicked.asObservable();
  }
}
