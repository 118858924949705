import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgGridEventService {
    private eventValue = new BehaviorSubject('');
    currentValue = this.eventValue.asObservable();

    updateValue(value: string) {
        this.eventValue.next(value);
    }
}
