<div *ngIf="!hideButtons" style="display: flex; flex-direction: row-reverse; padding-block-end: .5rem">
    <button class="review" cdsButton="tertiary" size="sm" [disabled]="!buttonRoute" (click)="navigateToReviewedSection()">Make changes
        <svg class="cds--btn__icon" cdsIcon="add" size="16"></svg>
    </button>
</div>
<div class="review-tile-row" *ngFor="let row of reviewRows" style="display: flex; width: 100%">
    <div class="review-tile" style="width: 30%">
        <p>{{row.field}}</p>
    </div>
    <ng-container *ngIf="isShowDiff(row); else noDiff">
        <div class="review-tile" style="width: 70%">
            <div class="width:100%">
                <span style="color:green" [innerHTML]="'(NEW) ' + row.value"></span>
            </div>
            <br>
            <div class="width:100%">
                <span style="color:red" [innerHTML]="'(OLD) ' + row.originalValue"></span>
            </div>
        </div>
    </ng-container>
    <ng-template #noDiff>
        <div class="review-tile" style="width: 70%">
            <span [innerHTML]="row.value"></span>
        </div>
    </ng-template>
<!--    TODO remove template with errors if no longer needed-->
<!--    <div class="review-tile" style="width: 20%">-->
<!--        <p>{{row.field}}<svg *ngIf="row.error?.length" style="fill: #da1e28" class="cds&#45;&#45;btn__icon" cdsIcon="warning" size="16"></svg></p>-->
<!--    </div>-->
<!--    <div class="review-tile" style="width:30%">-->
<!--        <p>{{row.error?.join(" ")}}</p>-->
<!--    </div>-->
<!--    <div class="review-tile" style="width: 50%">-->
<!--        <span [innerHTML]="row.value"></span>-->
<!--    </div>-->
</div>
