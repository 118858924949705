import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';

@Directive({
    selector: '[castateparksscpIntersectionObserver]'
})
export class IntersectionObserverDirective implements AfterViewInit {
    @Output() exists = new EventEmitter();
    @Output() visible = new EventEmitter();
    @Input() checkVisible: boolean = false;

    constructor(private element: ElementRef) {
    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                // will trigger once element exists in DOM
                if (!this.checkVisible) {
                    this.exists.emit();
                    observer.unobserve(this.element.nativeElement); // Stop observing after the first trigger
                    return;
                }
                // will trigger once element is visible in viewport
                if (entry.isIntersecting) {
                    this.visible.emit();
                    observer.unobserve(this.element.nativeElement); // Stop observing after the first trigger
                }
            });
        });

        observer.observe(this.element.nativeElement);
    }
}
