<cds-dropdown
    [type]="type"
    selectionFeedback="fixed"
    [(ngModel)]="model"
    [label]="label"
    [helperText]="helperText"
    size="sm"
    [dropUp]="false"
    [placeholder]="placeholderText"
    [disabled]="disabled"
    [itemValueKey]="'value'">
    <cds-dropdown-list [items]="items"></cds-dropdown-list>
</cds-dropdown>

