import {Component} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'castateparksscp-logo',
    templateUrl: './logo.component.html',
    styleUrl: './logo.component.css'
})
export class LogoComponent {
    constructor(private router: Router) {
    }

    goHome(): void {
        this.router.navigateByUrl("/public").then(x => {
        });
    }
}
