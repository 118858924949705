import { Injectable } from "@angular/core";
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import { Observable } from "rxjs";
import {filter, map} from "rxjs/operators";
import { Configuration } from "src/app/shared/generated/configuration"

@Injectable({
    providedIn: "root",
})
export class FileService {
    constructor(private http: HttpClient, private configuration: Configuration) {}

    downloadFile(fileRoute: string): Observable<any> {
        const route = `${this.getApiUrl()}/${fileRoute}/download`;

        const headers = new HttpHeaders();
        const req = new HttpRequest("GET", route, { headers, responseType: "blob" });
        return this.http.request(req).pipe(
            map((response: HttpResponse<Blob>) => {
                return response.body;
            })
        );
    }

    uploadFiles(fileRoute: string, filesToUpload: any[]): Observable<any> {
        const route = `${this.getApiUrl()}/${fileRoute}`;

        const formData = new FormData();
        filesToUpload.forEach((file) => {
            formData.append(file.name, file);
        });

        const headers = new HttpHeaders();
        const req = new HttpRequest("POST", route, formData, { headers, responseType: "json" });
        return this.http.request(req).pipe(
            filter(event => event.type === HttpEventType.Response),
            map((event: HttpResponse<any>) => event.body)
        );
    }

    private getApiUrl() {
        return this.configuration.basePath;
    }
}
