/**
 * CAStateParksSCP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ResearchProjectSpecialConditionSimpleDto } from '../model/research-project-special-condition-simple-dto';
import { ResearchProjectSpecialConditionUpsertDto } from '../model/research-project-special-condition-upsert-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ResearchProjectSpecialConditionService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectDistrictSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchProjectSpecialConditionSimpleDto>>;
    public researchProjectDistrictSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchProjectSpecialConditionSimpleDto>>>;
    public researchProjectDistrictSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchProjectSpecialConditionSimpleDto>>>;
    public researchProjectDistrictSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectDistrictSpecialConditionsResearchProjectIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchProjectSpecialConditionSimpleDto>>(`${this.basePath}/research-project-district-special-conditions/${encodeURIComponent(String(researchProjectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectSpecialConditionUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectSpecialConditionsMultiplePost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectSpecialConditionsMultiplePost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectSpecialConditionsMultiplePost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectSpecialConditionsMultiplePost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/research-project-special-conditions/multiple`,
            researchProjectSpecialConditionUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectSpecialConditionUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectSpecialConditionsPost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectSpecialConditionSimpleDto>;
    public researchProjectSpecialConditionsPost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectSpecialConditionSimpleDto>>;
    public researchProjectSpecialConditionsPost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectSpecialConditionSimpleDto>>;
    public researchProjectSpecialConditionsPost(researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResearchProjectSpecialConditionSimpleDto>(`${this.basePath}/research-project-special-conditions`,
            researchProjectSpecialConditionUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param conditionNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectSpecialConditionsResearchProjectIDDecrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectSpecialConditionsResearchProjectIDDecrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectSpecialConditionsResearchProjectIDDecrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectSpecialConditionsResearchProjectIDDecrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectSpecialConditionsResearchProjectIDDecrementConditionNumberPut.');
        }

        if (conditionNumber === null || conditionNumber === undefined) {
            throw new Error('Required parameter conditionNumber was null or undefined when calling researchProjectSpecialConditionsResearchProjectIDDecrementConditionNumberPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/research-project-special-conditions/${encodeURIComponent(String(researchProjectID))}/decrement/${encodeURIComponent(String(conditionNumber))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchProjectSpecialConditionSimpleDto>>;
    public researchProjectSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchProjectSpecialConditionSimpleDto>>>;
    public researchProjectSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchProjectSpecialConditionSimpleDto>>>;
    public researchProjectSpecialConditionsResearchProjectIDGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectSpecialConditionsResearchProjectIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchProjectSpecialConditionSimpleDto>>(`${this.basePath}/research-project-special-conditions/${encodeURIComponent(String(researchProjectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param conditionNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectSpecialConditionsResearchProjectIDIncrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectSpecialConditionsResearchProjectIDIncrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectSpecialConditionsResearchProjectIDIncrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectSpecialConditionsResearchProjectIDIncrementConditionNumberPut(researchProjectID: number, conditionNumber: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectSpecialConditionsResearchProjectIDIncrementConditionNumberPut.');
        }

        if (conditionNumber === null || conditionNumber === undefined) {
            throw new Error('Required parameter conditionNumber was null or undefined when calling researchProjectSpecialConditionsResearchProjectIDIncrementConditionNumberPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/research-project-special-conditions/${encodeURIComponent(String(researchProjectID))}/increment/${encodeURIComponent(String(conditionNumber))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectSpecialConditionID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDDelete(researchProjectSpecialConditionID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDDelete(researchProjectSpecialConditionID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDDelete(researchProjectSpecialConditionID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDDelete(researchProjectSpecialConditionID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectSpecialConditionID === null || researchProjectSpecialConditionID === undefined) {
            throw new Error('Required parameter researchProjectSpecialConditionID was null or undefined when calling researchProjectSpecialConditionsResearchProjectSpecialConditionIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/research-project-special-conditions/${encodeURIComponent(String(researchProjectSpecialConditionID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectSpecialConditionID 
     * @param researchProjectSpecialConditionUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDPut(researchProjectSpecialConditionID: number, researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectSpecialConditionSimpleDto>;
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDPut(researchProjectSpecialConditionID: number, researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectSpecialConditionSimpleDto>>;
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDPut(researchProjectSpecialConditionID: number, researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectSpecialConditionSimpleDto>>;
    public researchProjectSpecialConditionsResearchProjectSpecialConditionIDPut(researchProjectSpecialConditionID: number, researchProjectSpecialConditionUpsertDto?: ResearchProjectSpecialConditionUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectSpecialConditionID === null || researchProjectSpecialConditionID === undefined) {
            throw new Error('Required parameter researchProjectSpecialConditionID was null or undefined when calling researchProjectSpecialConditionsResearchProjectSpecialConditionIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectSpecialConditionSimpleDto>(`${this.basePath}/research-project-special-conditions/${encodeURIComponent(String(researchProjectSpecialConditionID))}`,
            researchProjectSpecialConditionUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
