export const BooleanDisplayNameToIdMap: Map<string, boolean> = new Map(
    [
        ['Yes', true],
        ['No', false],
    ])

export const BooleanIdToDisplayNameMap: Map<boolean, string> = new Map(
    [
        [true, 'Yes'],
        [false, 'No'],
    ])

export const getBooleanIdToDisplayName = (value: boolean) => {
    if (!!value) {
        return BooleanIdToDisplayNameMap.get(value);
    }
    return 'No';
}

export const BooleanIdAndDisplayNameArray: Array<{ text: string, value: boolean }> = [
    {text: 'Yes', value: true},
    {text: 'No', value: false},
]
