import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'castateparksscp-research-project-laboratory-work-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectLaboratoryWorkFormComponent extends BasicFormComponent {
    upsertDto: LaboratoryWorkDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'LaboratoryFacility',
                label: 'Laboratory facility',
                type: 'text',
                requiredValidated: true
            },
            {
                class: 'g-col-7',
                name: 'LaboratoryResponsibleOfficialName',
                label: 'Responsible official name',
                type: 'text',
                requiredValidated: true
            },
            {
                class: 'g-col-5'
            },
            {
                class: 'g-col-3',
                name: 'LaboratoryPhone',
                label: 'Phone',
                type: 'phoneNumber',
                requiredValidated: true
            },
            {
                class: 'g-col-3',
                name: 'LaboratoryEmail',
                label: 'Email',
                type: 'text',
                requiredValidated: true
            },
            {
                class: 'g-col-6'
            },
            {
                class: 'g-col-3',
                name: 'LabStudyStartDate',
                label: 'Lab study start date',
                type: 'date',
                requiredValidated: true
            },
            {
                class: 'g-col-3',
                name: 'LabStudyEndDate',
                label: 'Lab study end date',
                type: 'date',
                requiredValidated: true
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                LaboratoryFacility: dto?.LaboratoryFacility,
                LaboratoryResponsibleOfficialName: dto?.LaboratoryResponsibleOfficialName,
                LaboratoryPhone: dto?.LaboratoryPhone,
                LaboratoryEmail: dto?.LaboratoryEmail,
                LabStudyStartDate: dto?.LabStudyStartDate,
                LabStudyEndDate: dto?.LabStudyEndDate,
            } as LaboratoryWorkDto;
        };
    }
}

export interface LaboratoryWorkDto {
    LaboratoryFacility: string;
    LaboratoryResponsibleOfficialName: string;
    LaboratoryPhone: string;
    LaboratoryEmail: string;
    LabStudyStartDate: Date;
    LabStudyEndDate: Date;
}
