export default class CollectionHelpers {
    public static arraysEqual(arr1, arr2, excludeProps = []) {
        if (!arr1 || !arr2) return arr1 == arr2;
        if (arr1.length !== arr2.length) return false;
        let set1 = new Set(arr1.map(item => JSON.stringify(this.removeProps(item, excludeProps))));
        let set2 = new Set(arr2.map(item => JSON.stringify(this.removeProps(item, excludeProps))));
        return [...set1].every(value => set2.has(value));
    }

    private static removeProps(obj, props) {
        const newObj = {...obj};
        props.forEach(prop => delete newObj[prop]);
        return newObj;
    }
}
