<div class="detail-view" *ngIf="user$ | async as user">
    <div class="page-header">
        <h1 class="page-title">
            {{ user?.FullName || ((user?.FirstName || user?.LastName) ? (user?.FirstName + " " + user?.LastName) : null) }}
        </h1>
    </div>

    <div class="actions-bar">
        <esa-material-button *ngIf="canUpdateUser()" type="clear" icon="edit" (click)="enableEditMode()"> </esa-material-button>
    </div>

    <ng-container>
        <castateparksscp-user-form
            #updateUserForm
            [ID]="user.UserID"
            [isCDS]="true"
            [dto]="user"
            [editMode]="editMode"
            [districtOptions]="districtOptions"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"></castateparksscp-user-form>
    </ng-container>
</div>
