import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent, SubscriptionInsufficientComponent, UnauthenticatedComponent} from './shared/pages';
import {UserListComponent} from './pages/user/user-list/user-list.component';
import {HomeIndexComponent} from './pages/home/home-index/home-index.component';
import {UserDetailComponent} from './pages/user/user-detail/user-detail.component';
import {UserEditComponent} from './pages/user-edit/user-edit.component';
import {LoginCallbackComponent} from './pages/login-callback/login-callback.component';
import {HelpComponent} from './pages/help/help.component';
import {CreateUserCallbackComponent} from './pages/create-user-callback/create-user-callback.component';
import {FieldDefinitionListComponent} from './pages/field-definition-list/field-definition-list.component';
import {FieldDefinitionEditComponent} from './pages/field-definition-edit/field-definition-edit.component';
import {CustomPageListComponent} from './pages/custom-page-list/custom-page-list.component';
import {CustomPageDetailComponent} from './pages/custom-page-detail/custom-page-detail.component';
import {
    CustomPageEditPropertiesComponent
} from './pages/custom-page-edit-properties/custom-page-edit-properties.component';
import {MsalGuard} from '@azure/msal-angular';
import {UnsavedChangesGuard} from './shared/guards/unsaved-changes-guard';
import {UserInviteComponent} from './pages/user/user-invite/user-invite.component';
import {ProjectListComponent} from './pages/project/project-list/project-list.component';
import {ProjectCreateComponent} from './pages/project/project-create/project-create.component';
import {ProjectDetailComponent} from './pages/project/project-detail/project-detail.component';
import {
    ProjectDetailSummaryComponent
} from './pages/project/project-detail/project-detail-summary/project-detail-summary.component';
import {ProjectFileListComponent} from "./pages/project/project-detail/project-file-list/project-file-list.component";
import {BrowserUtils} from '@azure/msal-browser';
import {PermitCreateComponent} from './pages/permit/permit-create/permit-create.component';
import {BasicEditListComponent} from './pages/basic/basic-edit-list/basic-edit-list.component';
import {ContactCreateComponent} from './pages/contact/contact-create/contact-create.component';
import {GridTestComponent} from './pages/grid-test/grid-test.component';
import {ContactDetailComponent} from './pages/contact/contact-detail/contact-detail.component';
import {
    ContactDetailSummaryComponent
} from './pages/contact/contact-detail/contact-detail-summary/contact-detail-summary.component';
import {TaxonListComponent} from './pages/taxon/taxon-list/taxon-list.component';
import {DistrictListComponent} from './pages/district/district-list/district-list.component';
import {
    ResearchTeamCreatePublicComponent
} from "./pages/research-team/research-team-create-public/research-team-create-public.component";
import {
    ResearchProjectCreatePublicComponent
} from "./pages/research-project/research-project-create-public/research-project-create-public.component";
import {DistrictDetailComponent} from "./pages/district/district-detail/district-detail.component";
import {
    DistrictDetailSummaryComponent
} from "./pages/district/district-detail/district-detail-summary/district-detail-summary.component";
import {
    ResearchProjectReviewAndSubmitComponent
} from "./pages/research-project-review-and-submit/research-project-review-and-submit.component";
import {
    ResearchProjectDocumentsComponent
} from "./pages/research-project-documents/research-project-documents.component";
import {
    ResearchProjectDataCollectionComponent
} from "./pages/research-project-data-collection/research-project-data-collection.component";
import {
    ResearchProjectSiteLocationComponent
} from "./pages/research-project-site-location/research-project-site-location.component";
import {PublicHomeComponent} from "./pages/public-home/public-home.component";
import {ResearchPermitListComponent} from "./pages/research-permit-list/research-permit-list.component";
import {InternalReviewComponent} from "./pages/internal-review/internal-review.component";
import {ResearchProjectSuccessComponent} from "./pages/research-project-success/research-project-success.component";
import {SpecialConditionListComponent} from "./pages/special-condition-list/special-condition-list.component";
import {
    ResourceCategoryTagListComponent
} from "./pages/resource-category-tag-list/resource-category-tag-list.component";

const routes: Routes = [
    {
        path: "custom-pages/:vanity-url",
        component: CustomPageDetailComponent,
        canDeactivate: [UnsavedChangesGuard],
    },

    {
        path: "manage", component: UserListComponent, canActivate: [MsalGuard], children: [
            {path: "custom-pages", component: CustomPageListComponent, canActivate: [MsalGuard]},
            {path: "taxons", component: TaxonListComponent, canActivate: [MsalGuard]},
            {
                path: "custom-pages/create",
                component: CustomPageEditPropertiesComponent,
                data: {create: true},
                canDeactivate: [UnsavedChangesGuard]
            },
            {
                path: "custom-pages/edit-properties/:vanity-url",
                component: CustomPageEditPropertiesComponent,
                data: {create: false},
                canDeactivate: [UnsavedChangesGuard]
            },
            {
                path: "labels-and-definitions/:id",
                component: FieldDefinitionEditComponent,
                canDeactivate: [UnsavedChangesGuard]
            },
            {path: "labels-and-definitions", component: FieldDefinitionListComponent},
        ]
    },
    // {
    //     path: "manage/users/invite", component: UserInviteComponent,
    //     canActivate: [MsalGuard],
    // },
    {
        path: "manage/users/:id", component: UserDetailComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "manage/users/:id/edit", component: UserEditComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
    },
    {path: "projects", component: ProjectListComponent, canActivate: [MsalGuard]},
    {
        path: "projects/create", component: ProjectCreateComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
    },
    {
        path: "projects/:id",
        component: ProjectDetailComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: "",
                component: ProjectDetailSummaryComponent,
                canActivate: [MsalGuard],
                canDeactivate: [UnsavedChangesGuard]
            },
            {
                path: "file-list",
                component: ProjectFileListComponent,
                canActivate: [MsalGuard],
                canDeactivate: [UnsavedChangesGuard]
            }
        ]
    },
    {
        path: "public/research-teams/:researchProjectID",
        component: ResearchTeamCreatePublicComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "public/research-projects/:researchProjectID",
        component: ResearchProjectCreatePublicComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "public/research-project-study-areas/:researchProjectID",
        component: ResearchProjectSiteLocationComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "public/research-project-data-collection/:researchProjectID",
        component: ResearchProjectDataCollectionComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "public/research-project-documents/:researchProjectID",
        component: ResearchProjectDocumentsComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "public/research-project-review-and-submit/:researchProjectID",
        component: ResearchProjectReviewAndSubmitComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "public/research-project-submission-success/:researchProjectID",
        component: ResearchProjectSuccessComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "internal-review/:researchProjectID",
        component: InternalReviewComponent,
        canActivate: [MsalGuard],
    },
    {path: "taxons", component: TaxonListComponent, canActivate: [MsalGuard]},
    {path: "edit-list", component: BasicEditListComponent, canActivate: [MsalGuard]},
    {path: "permits", component: ResearchPermitListComponent, canActivate: [MsalGuard]},
    {
        path: "permits/create", component: PermitCreateComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
    },
    {
        path: "contacts/create", component: ContactCreateComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
    },
    {
        path: "contacts/:id",
        component: ContactDetailComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: "",
                component: ContactDetailSummaryComponent,
                canActivate: [MsalGuard],
                canDeactivate: [UnsavedChangesGuard]
            },
        ]
    },
    {path: "special-conditions", component: SpecialConditionListComponent, canActivate: [MsalGuard]},
    {path: "tags", component: ResourceCategoryTagListComponent, canActivate: [MsalGuard]},
    {path: "districts", component: DistrictListComponent, canActivate: [MsalGuard]},
    {
        path: "districts/:id",
        component: DistrictDetailComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: "",
                component: DistrictDetailSummaryComponent,
                canActivate: [MsalGuard],
                canDeactivate: [UnsavedChangesGuard]
            },
        ]
    },
    {path: "public", component: PublicHomeComponent},
    {path: "help", component: HelpComponent},
    {path: "create-user-callback", component: CreateUserCallbackComponent},
    {path: "not-found", component: NotFoundComponent},
    {path: 'subscription-insufficient', component: SubscriptionInsufficientComponent},
    {path: 'unauthenticated', component: UnauthenticatedComponent},
    {path: "signin-oidc", component: LoginCallbackComponent},
    {path: 'grid-test', component: GridTestComponent},
    {path: "", component: PublicHomeComponent},
    {path: "internal", component: HomeIndexComponent},
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // Don't perform initial navigation in iframes
            initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? "enabledNonBlocking" : "disabled", // Set to enabledBlocking to use Angular Universal
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
