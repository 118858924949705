import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {BaseModal, ModalButtonType, ModalService} from "carbon-components-angular";
import {FormElement, FormPage} from "../shared/basic-form/basic-form.component";
import {BasicModalFormComponent} from "./basic-modal-form/basic-modal-form.component";

@Component({
    selector: 'castateparksscp-basic-modal',
    templateUrl: './basic-modal.component.html',
})
export class BasicModalComponent extends BaseModal implements OnChanges {
    @Input() open = false;
    @Input() config: BasicModalConfiguration;
    @Input() formConfig: FormPage;
    @Input() elements: FormElement[];
    @ViewChild("formComponent") public form: BasicModalFormComponent;
    formPage;

    isLoading = false;
    constructor(
        protected modalService: ModalService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formConfig'] || changes['elements']) {
            this.formPage = Object.assign(this.formConfig, {elements: this.elements});
        }
    }

    showSecondaryModal() {
        this.modalService.show({
            label: this.config.secondaryHeaderLabel,
            title: this.config.secondaryTitle,
            content: this.config.secondaryContent,
            size: this.config.size,
            buttons: [{
                text: "Cancel",
                type: ModalButtonType.secondary
            }, {
                text: "OK",
                type: ModalButtonType.primary,
                click: () => alert("OK button clicked")
            }]
        });
    }

    isPrimaryButtonDisabled(){
        if (this.config.primaryButtonDisable) return true;
        if (this.config?.formPage?.elements?.length) {
            return this.form?.form?.invalid;
        }
        return !!this.isLoading;
    }

    handlePrimaryButtonClick() {
        if (this.config.primaryButtonClick) {
            let upsertDto = this.form?.upsertDto;
            this.config.primaryButtonClick(upsertDto);
            return;
        }
        if (this.config.formPage.post) {
            this.isLoading = true;
            this.form.save();
            return;
        }
        this.closeModal();
    }

    handleFormSubmitted(savedValue: any) {
        this.isLoading = false;
        this.form.setForm();
        this.config.formPage.elements.forEach(e => {
            if (!!e.listItemValue) {
                e.listItemValue = null;
            }
        })
        this.form.clearFilterSelectFormElements();
        this.close.emit();
    }
}

export interface BasicModalConfiguration {
    size: 'sm'|'md'|'lg';
    modalHeaderTitle: string;
    contentTitle?: string;
    content: string;
    secondaryHeaderLabel?: string,
    secondaryTitle?: string,
    secondaryContent?: string,
    secondaryButtons?: Array<ModalButton>
    downloadLinks?: Array<ModalLink>,
    primaryButtonClick?: (params: any) => void;
    primaryButtonText?: string;
    primaryButtonDisable?: boolean;
    secondaryButtonClick?: () => void;
    secondaryButtonText?: string;
    formPage?: FormPage;
    formPageDto?: any;
}

export interface ModalButton {
    text: string,
    type: ModalButtonType | string,
    click?: Function
}

export interface ModalLink {
    text: string,
    route: string,
}
