import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchProjectFileTypeEnum} from "../../shared/generated/enum/research-project-file-type-enum";
import {ResearchProjectLaboratoryWorkFlagFormComponent} from "./research-project-laboratory-work-flag-form.component";
import {
    ResearchProjectDataCollectionUpsertDto
} from "../../shared/generated/model/research-project-data-collection-upsert-dto";
import {ResearchProjectStudyProceduresFormComponent} from "./research-project-study-procedures-form.component";
import {ResearchProjectLaboratoryWorkFormComponent} from "./research-project-laboratory-work-form.component";
import {
    ResearchProjectSpecimenCollectionFlagFormComponent
} from "./research-project-specimen-collection-flag-form.component";
import {ResearchProjectSpecimenCollectionFormComponent} from "./research-project-specimen-collection-form.component";
import {ResearchProjectCollectionRationaleFormComponent} from "./research-project-collection-rationale-form.component";
import {ResearchProjectDataProductsFormComponent} from "./research-project-data-products-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {Alert} from "../../shared/models/alert";
import {AlertService} from "../../shared/services/alert.service";
import {finalize, forkJoin} from "rxjs";
import {
    ResearchProjectBaseComponentComponent
} from "../research-project/shared/research-project-base-component/research-project-base-component.component";
import {AuthenticationService} from "../../services/authentication.service";
import {ResearchProjectDto} from "../../shared/generated/model/research-project-dto";
import {Title} from "@angular/platform-browser";
import {
    ReturnedToResearcherNotificationComponent
} from "../research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'castateparksscp-research-project-data-collection',
    templateUrl: './research-project-data-collection.component.html',
    styleUrls: ['./research-project-data-collection.component.scss']
})
export class ResearchProjectDataCollectionComponent extends ResearchProjectBaseComponentComponent implements OnInit {
    pageSpecificTitle = 'Data Collection';
    stepIndex: number = 3;
    @ViewChild('researchProjectStudyProceduresForm') studyProceduresForm: ResearchProjectStudyProceduresFormComponent;
    @ViewChild('researchProjectLaboratoryWorkFlagForm') laboratoryWorkFlagForm: ResearchProjectLaboratoryWorkFlagFormComponent;
    @ViewChild('researchProjectLaboratoryWorkForm') laboratoryWorkForm: ResearchProjectLaboratoryWorkFormComponent;
    @ViewChild('researchProjectSpecimenCollectionFlagForm') specimenCollectionFlagForm: ResearchProjectSpecimenCollectionFlagFormComponent;
    @ViewChild('researchProjectCollectionRationaleForm') collectionRationaleForm: ResearchProjectCollectionRationaleFormComponent;
    @ViewChild('researchProjectSpecimenCollectionForm') specimenCollectionForm: ResearchProjectSpecimenCollectionFormComponent;
    @ViewChild('researchProjectDataProductsForm') dataProductsForm: ResearchProjectDataProductsFormComponent;
    @ViewChild("notificationComponent", {static: true})
    public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;

    researchProjectID: number;
    researchProjectDto: ResearchProjectDataCollectionUpsertDto = {};
    projectDtoRaw: ResearchProjectDto;
    isSaveInProgress: boolean = false;

    errors = {};
    constructor(private researchProjectService: ResearchProjectService,
                private alertService: AlertService,
                private router: Router,
                public route: ActivatedRoute,
                public dialog: MatDialog,
                public cdr: ChangeDetectorRef,
                public authenticationService: AuthenticationService,
                public titleService: Title
    ) {
        super(titleService);
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        forkJoin({
            r: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
            u: this.authenticationService.getCurrentUser()
        })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(({r, u}) => {
                this.researchProjectDto = r;
                this.setTitle(r);
                this.projectDtoRaw = r;
                this.user = u;
                this.setCanEditPage(u, r, this.router);
            });
    }

    saveAndContinue(isStepSelected = false): void {
        this.isSaveInProgress = true;
        this.studyProceduresForm.mapUpsert(this.researchProjectDto);
        this.laboratoryWorkFlagForm.mapUpsert(this.researchProjectDto);
        this.laboratoryWorkForm?.mapUpsert(this.researchProjectDto);
        this.specimenCollectionFlagForm.mapUpsert(this.researchProjectDto);
        this.collectionRationaleForm?.mapUpsert(this.researchProjectDto);
        this.specimenCollectionForm?.mapUpsert(this.researchProjectDto);
        this.dataProductsForm.mapUpsert(this.researchProjectDto);

        this.researchProjectService.researchProjectsResearchProjectIDUpdateDataCollectionPut(this.researchProjectID, this.researchProjectDto)
            .pipe(finalize(() => this.isSaveInProgress = false))
            .subscribe({
                next: (researchProject) => {
                    let url = this.incompleteSectionsExcludingReviewAndSubmitExist(researchProject)
                        ? `/public/research-project-documents/${this.researchProjectID}`
                        : `/public/research-project-review-and-submit/${this.researchProjectID}`;
                    if (isStepSelected) url = this.stepSelectedRoute;
                    this.router.navigateByUrl(url)
                        .then(() => this.alertService.pushAlert(
                            new Alert('The research project was successfully updated.'), 5000));
                },
                error: (err) => this.errors = err?.error
            });
    }

    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;

    handleInvolvesLaboratoryWork(value: any) {
        this.researchProjectDto.LaboratoryWork = value;
        if (!value) {
            this.laboratoryWorkForm.setBlankForm();
        }
    }

    handleInvolvesSpecimenCollection(value: any) {
        this.researchProjectDto.SpecimenCollection = value;
        if (!value) {
            this.specimenCollectionForm.setBlankForm();
            this.collectionRationaleForm.setBlankForm();
        }
    }

    handleStepSelect(route: string): void {
        this.stepSelectedRoute = route;
        if (this.isLoading) {
            this.router.navigateByUrl(route).then(x => {});
        } else {
            this.saveAndContinue(true);
        }
    }
    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => this.researchProjectDto = res);
        this.returnedToResearcherNotification.refreshInternalFields();
    }

    handleWithdraw() {
        this.withdraw(this.dialog, this.researchProjectID, this.researchProjectService, this.router, this.alertService);
    }

    override canMoveToWithdrawnStatus() {
        return this.canWithdrawStatuses.includes(this.projectDtoRaw?.ResearchProjectStatusTypeID);
    }
}
