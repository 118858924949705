import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
  selector: 'castateparksscp-research-project-data-products-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectDataProductsFormComponent extends BasicFormComponent{
    upsertDto: DataProductsDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'DataProductDetails',
                label: 'Location of data and data products collected under this permit',
                type: 'textArea',
                helperText: ' Describe anticipated products and deliverables resulting from this study, such as reports, publications, brochures, GIS layers, web tools, videos, etc., and how study results will be disseminated.',
                checkbox: true,
                checkboxName: 'ProposalIncludesDataProducts',
                requiredValidated: true
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                DataProductDetails: dto.DataProductDetails,
                ProposalIncludesDataProducts: dto.ProposalIncludesDataProducts} as DataProductsDto
        };
    }
}

export interface DataProductsDto {
    DataProductDetails: string;
    ProposalIncludesDataProducts: boolean;
}
