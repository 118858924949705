export const StateIdAndDisplayNameArrayUSandCA: Array<{ text: string, value: number }> = [
    {text: 'Alberta', value: 1},
    {text: 'British Columbia', value: 2},
    {text: 'Manitoba', value: 3},
    {text: 'New Brunswick', value: 4},
    {text: 'Newfoundland and Labrador', value: 5},
    {text: 'Northwest Territories', value: 6},
    {text: 'Nova Scotia', value: 7},
    {text: 'Nunavut', value: 8},
    {text: 'Ontario', value: 9},
    {text: 'Prince Edward Island', value: 10},
    {text: 'Quebec', value: 11},
    {text: 'Saskatchewan', value: 12},
    {text: 'Yukon', value: 13},
    {text: 'Alabama', value: 14},
    {text: 'Alaska', value: 15},
    {text: 'Arizona', value: 16},
    {text: 'Arkansas', value: 17},
    {text: 'Armed Forces Americas', value: 18},
    {text: 'Armed Forces Europe', value: 19},
    {text: 'Armed Forces Pacific', value: 20},
    {text: 'California', value: 21},
    {text: 'Colorado', value: 22},
    {text: 'Connecticut', value: 23},
    {text: 'Delaware', value: 24},
    {text: 'Florida', value: 25},
    {text: 'Georgia', value: 26},
    {text: 'Hawaii', value: 27},
    {text: 'Idaho', value: 28},
    {text: 'Illinois', value: 29},
    {text: 'Indiana', value: 30},
    {text: 'Iowa', value: 31},
    {text: 'Kansas', value: 32},
    {text: 'Kentucky', value: 33},
    {text: 'Louisiana', value: 34},
    {text: 'Maine', value: 35},
    {text: 'Maryland', value: 36},
    {text: 'Massachusetts', value: 37},
    {text: 'Michigan', value: 38},
    {text: 'Minnesota', value: 39},
    {text: 'Mississippi', value: 40},
    {text: 'Missouri', value: 41},
    {text: 'Montana', value: 42},
    {text: 'Nebraska', value: 43},
    {text: 'Nevada', value: 44},
    {text: 'New Hampshire', value: 45},
    {text: 'New Jersey', value: 46},
    {text: 'New Mexico', value: 47},
    {text: 'New York', value: 48},
    {text: 'North Carolina', value: 49},
    {text: 'North Dakota', value: 50},
    {text: 'Ohio', value: 51},
    {text: 'Oklahoma', value: 52},
    {text: 'Oregon', value: 53},
    {text: 'Pennsylvania', value: 54},
    {text: 'Rhode Island', value: 55},
    {text: 'South Carolina', value: 56},
    {text: 'South Dakota', value: 57},
    {text: 'Tennessee', value: 58},
    {text: 'Texas', value: 59},
    {text: 'Utah', value: 60},
    {text: 'Vermont', value: 61},
    {text: 'Virginia', value: 62},
    {text: 'Washington', value: 63},
    {text: 'Washington, D.C.', value: 64},
    {text: 'West Virginia', value: 65},
    {text: 'Wisconsin', value: 66},
    {text: 'Wyoming', value: 67},

]

export const StateIdAndDisplayNameArrayUS: Array<{ text: string, value: number }> = [
    {text: 'Alabama', value: 14},
    {text: 'Alaska', value: 15},
    {text: 'Arizona', value: 16},
    {text: 'Arkansas', value: 17},
    {text: 'Armed Forces Americas', value: 18},
    {text: 'Armed Forces Europe', value: 19},
    {text: 'Armed Forces Pacific', value: 20},
    {text: 'California', value: 21},
    {text: 'Colorado', value: 22},
    {text: 'Connecticut', value: 23},
    {text: 'Delaware', value: 24},
    {text: 'Florida', value: 25},
    {text: 'Georgia', value: 26},
    {text: 'Hawaii', value: 27},
    {text: 'Idaho', value: 28},
    {text: 'Illinois', value: 29},
    {text: 'Indiana', value: 30},
    {text: 'Iowa', value: 31},
    {text: 'Kansas', value: 32},
    {text: 'Kentucky', value: 33},
    {text: 'Louisiana', value: 34},
    {text: 'Maine', value: 35},
    {text: 'Maryland', value: 36},
    {text: 'Massachusetts', value: 37},
    {text: 'Michigan', value: 38},
    {text: 'Minnesota', value: 39},
    {text: 'Mississippi', value: 40},
    {text: 'Missouri', value: 41},
    {text: 'Montana', value: 42},
    {text: 'Nebraska', value: 43},
    {text: 'Nevada', value: 44},
    {text: 'New Hampshire', value: 45},
    {text: 'New Jersey', value: 46},
    {text: 'New Mexico', value: 47},
    {text: 'New York', value: 48},
    {text: 'North Carolina', value: 49},
    {text: 'North Dakota', value: 50},
    {text: 'Ohio', value: 51},
    {text: 'Oklahoma', value: 52},
    {text: 'Oregon', value: 53},
    {text: 'Pennsylvania', value: 54},
    {text: 'Rhode Island', value: 55},
    {text: 'South Carolina', value: 56},
    {text: 'South Dakota', value: 57},
    {text: 'Tennessee', value: 58},
    {text: 'Texas', value: 59},
    {text: 'Utah', value: 60},
    {text: 'Vermont', value: 61},
    {text: 'Virginia', value: 62},
    {text: 'Washington', value: 63},
    {text: 'Washington, D.C.', value: 64},
    {text: 'West Virginia', value: 65},
    {text: 'Wisconsin', value: 66},
    {text: 'Wyoming', value: 67},

]

export const StateIdToDisplayNameMapUSandCA: Map<number, string> = new Map(
    [
        [1, 'Alberta'],
        [2, 'British Columbia'],
        [3, 'Manitoba'],
        [4, 'New Brunswick'],
        [5, 'Newfoundland and Labrador'],
        [6, 'Northwest Territories'],
        [7, 'Nova Scotia'],
        [8, 'Nunavut'],
        [9, 'Ontario'],
        [10, 'Prince Edward Island'],
        [11, 'Quebec'],
        [12, 'Saskatchewan'],
        [13, 'Yukon'],
        [14, 'Alabama'],
        [15, 'Alaska'],
        [16, 'Arizona'],
        [17, 'Arkansas'],
        [18, 'Armed Forces Americas'],
        [19, 'Armed Forces Europe'],
        [20, 'Armed Forces Pacific'],
        [21, 'California'],
        [22, 'Colorado'],
        [23, 'Connecticut'],
        [24, 'Delaware'],
        [25, 'Florida'],
        [26, 'Georgia'],
        [27, 'Hawaii'],
        [28, 'Idaho'],
        [29, 'Illinois'],
        [30, 'Indiana'],
        [31, 'Iowa'],
        [32, 'Kansas'],
        [33, 'Kentucky'],
        [34, 'Louisiana'],
        [35, 'Maine'],
        [36, 'Maryland'],
        [37, 'Massachusetts'],
        [38, 'Michigan'],
        [39, 'Minnesota'],
        [40, 'Mississippi'],
        [41, 'Missouri'],
        [42, 'Montana'],
        [43, 'Nebraska'],
        [44, 'Nevada'],
        [45, 'New Hampshire'],
        [46, 'New Jersey'],
        [47, 'New Mexico'],
        [48, 'New York'],
        [49, 'North Carolina'],
        [50, 'North Dakota'],
        [51, 'Ohio'],
        [52, 'Oklahoma'],
        [53, 'Oregon'],
        [54, 'Pennsylvania'],
        [55, 'Rhode Island'],
        [56, 'South Carolina'],
        [57, 'South Dakota'],
        [58, 'Tennessee'],
        [59, 'Texas'],
        [60, 'Utah'],
        [61, 'Vermont'],
        [62, 'Virginia'],
        [63, 'Washington'],
        [64, 'Washington, D.C.'],
        [65, 'West Virginia'],
        [66, 'Wisconsin'],
        [67, 'Wyoming'],

    ]
)


export const StateIdToDisplayNameMap: Map<number, string> = new Map(
    [[1, "Alberta"],
        [2, "British Columbia"],
        [3, "Manitoba"],
        [4, "New Brunswick"],
        [5, "Newfoundland and Labrador"],
        [6, "Northwest Territories"],
        [7, "Nova Scotia"],
        [8, "Nunavut"],
        [9, "Ontario"],
        [10, "Prince Edward Island"],
        [11, "Quebec"],
        [12, "Saskatchewan"],
        [13, "Yukon"],
        [14, "Alabama"],
        [15, "Alaska"],
        [16, "Arizona"],
        [17, "Arkansas"],
        [18, "Armed Forces Americas"],
        [19, "Armed Forces Europe"],
        [20, "Armed Forces Pacific"],
        [21, "California"],
        [22, "Colorado"],
        [23, "Connecticut"],
        [24, "Delaware"],
        [25, "Florida"],
        [26, "Georgia"],
        [27, "Hawaii"],
        [28, "Idaho"],
        [29, "Illinois"],
        [30, "Indiana"],
        [31, "Iowa"],
        [32, "Kansas"],
        [33, "Kentucky"],
        [34, "Louisiana"],
        [35, "Maine"],
        [36, "Maryland"],
        [37, "Massachusetts"],
        [38, "Michigan"],
        [39, "Minnesota"],
        [40, "Mississippi"],
        [41, "Missouri"],
        [42, "Montana"],
        [43, "Nebraska"],
        [44, "Nevada"],
        [45, "New Hampshire"],
        [46, "New Jersey"],
        [47, "New Mexico"],
        [48, "New York"],
        [49, "North Carolina"],
        [50, "North Dakota"],
        [51, "Ohio"],
        [52, "Oklahoma"],
        [53, "Oregon"],
        [54, "Pennsylvania"],
        [55, "Rhode Island"],
        [56, "South Carolina"],
        [57, "South Dakota"],
        [58, "Tennessee"],
        [59, "Texas"],
        [60, "Utah"],
        [61, "Vermont"],
        [62, "Virginia"],
        [63, "Washington"],
        [64, "Washington, D.C."],
        [65, "West Virginia"],
        [66, "Wisconsin"],
        [67, "Wyoming"],
        [68, "Sant Julià de Loria"],
        [69, "Ordino"],
        [70, "La Massana"],
        [71, "Encamp"],
        [72, "Canillo"],
        [73, "Andorra la Vella"],
        [74, "Escaldes-Engordany"],
        [75, "Imarat Umm al Qaywayn"],
        [76, "Ra's al Khaymah"],
        [77, "Dubai"],
        [78, "Sharjah"],
        [79, "Fujairah"],
        [80, "Ajman"],
        [81, "Abu Dhabi"],
        [82, "Zabul"],
        [83, "Vardak"],
        [84, "Takhar"],
        [85, "Sar-e Pol"],
        [86, "Samangan"],
        [87, "Parwan"],
        [88, "Paktika"],
        [89, "Paktia"],
        [90, "Oruzgan"],
        [91, "Nimroz"],
        [92, "Nangarhar"],
        [93, "Logar"],
        [94, "Laghman"],
        [95, "Kunduz"],
        [96, "Kunar"],
        [97, "Kapisa"],
        [98, "Kandahar"],
        [99, "Kabul"],
        [100, "Jowzjan"],
        [101, "Herat"],
        [102, "Helmand"],
        [103, "Ghowr"],
        [104, "Ghazni"],
        [105, "Faryab"],
        [106, "Farah"],
        [107, "Bamyan"],
        [108, "Balkh"],
        [109, "Baghlan"],
        [110, "Badghis"],
        [111, "Badakhshan"],
        [112, "Khowst"],
        [113, "Nuristan"],
        [114, "Daykundi"],
        [115, "Panjshir"],
        [116, "Saint Philip"],
        [117, "Saint Peter"],
        [118, "Saint Paul"],
        [119, "Saint Mary"],
        [120, "Saint John"],
        [121, "Saint George"],
        [122, "Redonda"],
        [123, "Barbuda"],
        [124, "Blowing Point"],
        [125, "Sandy Ground"],
        [126, "Sandy Hill"],
        [127, "The Valley"],
        [128, "East End"],
        [129, "North Hill"],
        [130, "West End"],
        [131, "South Hill"],
        [132, "The Quarter"],
        [133, "North Side"],
        [134, "Island Harbour"],
        [135, "George Hill"],
        [136, "Stoney Ground"],
        [137, "The Farrington"],
        [138, "Berat"],
        [139, "Dibër"],
        [140, "Elbasan"],
        [141, "Gjirokastër"],
        [142, "Korçë"],
        [143, "Kukës"],
        [144, "Durrës"],
        [145, "Fier"],
        [146, "Lezhë"],
        [147, "Shkodër"],
        [148, "Tirana"],
        [149, "Vlorë"],
        [150, "Ararat"],
        [151, "Syunik"],
        [152, "Vayots Dzor"],
        [153, "Yerevan"],
        [154, "Aragatsotn"],
        [155, "Armavir"],
        [156, "Gegharkunik"],
        [157, "Kotayk"],
        [158, "Lori"],
        [159, "Shirak"],
        [160, "Tavush"],
        [161, "Lunda Sul"],
        [162, "Luanda Norte"],
        [163, "Moxico"],
        [164, "Cuando Cobango"],
        [165, "Zaire"],
        [166, "Uíge"],
        [167, "Malanje"],
        [168, "Luanda"],
        [169, "Cuanza Norte"],
        [170, "Cabinda"],
        [171, "Bengo"],
        [172, "Namibe"],
        [173, "Huíla"],
        [174, "Huambo"],
        [175, "Cunene"],
        [176, "Kwanza Sul"],
        [177, "Bíe"],
        [178, "Benguela"],
        [179, "Misiones"],
        [180, "Formosa"],
        [181, "Buenos Aires F.D."],
        [182, "Entre Rios"],
        [183, "Corrientes"],
        [184, "Buenos Aires"],
        [185, "Tucuman"],
        [186, "Tierra del Fuego"],
        [187, "Santiago del Estero"],
        [188, "Santa Fe"],
        [189, "Santa Cruz"],
        [190, "San Luis"],
        [191, "San Juan"],
        [192, "Salta"],
        [193, "Rio Negro"],
        [194, "Neuquen"],
        [195, "Mendoza"],
        [196, "La Rioja"],
        [197, "La Pampa"],
        [198, "Jujuy"],
        [199, "Cordoba"],
        [200, "Chubut"],
        [201, "Chaco"],
        [202, "Catamarca"],
        [203, "Western District"],
        [204, "Swains Island"],
        [205, "Eastern District"],
        [206, "Manu'a"],
        [207, "Rose Island"],
        [208, "Vienna"],
        [209, "Vorarlberg"],
        [210, "Tyrol"],
        [211, "Styria"],
        [212, "Salzburg"],
        [213, "Upper Austria"],
        [214, "Lower Austria"],
        [215, "Carinthia"],
        [216, "Burgenland"],
        [217, "Western Australia"],
        [218, "South Australia"],
        [219, "Northern Territory"],
        [220, "Victoria"],
        [221, "Tasmania"],
        [222, "Queensland"],
        [223, "New South Wales"],
        [224, "Australian Capital Territory"],
        [225, "Mariehamns stad"],
        [226, "Ålands landsbygd"],
        [227, "Ålands skärgård"],
        [228, "Beyl?qan"],
        [229, "Z?ngilan"],
        [230, "Yardimli"],
        [231, "Shusha"],
        [232, "Salyan"],
        [233, "Sabirabad"],
        [234, "Saatli"],
        [235, "Bil?suvar"],
        [236, "Neftçala"],
        [237, "Nakhichevan"],
        [238, "Masally"],
        [239, "Lerik"],
        [240, "L?nk?ran"],
        [241, "Laçin"],
        [242, "Qubadli"],
        [243, "Imisli"],
        [244, "Füzuli"],
        [245, "Jabrayil"],
        [246, "Jalilabad"],
        [247, "Astara"],
        [248, "Xocali"],
        [249, "Agcab?di"],
        [250, "Agdam"],
        [251, "Shirvan"],
        [252, "Lankaran Sahari"],
        [253, "Shusha City"],
        [254, "T?rt?r"],
        [255, "Xank?ndi"],
        [256, "Khojavend"],
        [257, "Z?rdab"],
        [258, "Zaqatala"],
        [259, "Yevlax"],
        [260, "Oguz"],
        [261, "Ucar"],
        [262, "Tovuz"],
        [263, "Samaxi"],
        [264, "Shaki"],
        [265, "S?mkir"],
        [266, "Kürd?mir"],
        [267, "Q?b?l?"],
        [268, "Qusar"],
        [269, "Quba"],
        [270, "Goygol Rayon"],
        [271, "Xaçmaz"],
        [272, "Kalbajar"],
        [273, "Qazax"],
        [274, "Goranboy"],
        [275, "Qax"],
        [276, "Ismayilli"],
        [277, "Göyçay"],
        [278, "Shabran"],
        [279, "Dask?s?n"],
        [280, "Balak?n"],
        [281, "Barda"],
        [282, "Baki"],
        [283, "Abseron"],
        [284, "Agsu"],
        [285, "Agdas"],
        [286, "G?d?b?y"],
        [287, "Agstafa"],
        [288, "G?nc?"],
        [289, "Ming?cevir"],
        [290, "Naftalan"],
        [291, "Qobustan"],
        [292, "Samux"],
        [293, "Shaki City"],
        [294, "Siy?z?n"],
        [295, "Sumqayit"],
        [296, "Xizi"],
        [297, "Yevlax City"],
        [298, "Haciqabul"],
        [299, "Federation of B&H"],
        [300, "Srpska"],
        [301, "Brcko"],
        [302, "Saint Thomas"],
        [303, "Saint Philip"],
        [304, "Saint Peter"],
        [305, "Saint Michael"],
        [306, "Saint Lucy"],
        [307, "Saint Joseph"],
        [308, "Saint John"],
        [309, "Saint James"],
        [310, "Saint George"],
        [311, "Saint Andrew"],
        [312, "Christ Church"],
        [313, "Rajshahi Division"],
        [314, "Dhaka"],
        [315, "Chittagong"],
        [316, "Khulna"],
        [317, "Barisal"],
        [318, "Sylhet"],
        [319, "Rangpur Division"],
        [320, "Mymensingh Division"],
        [321, "Brussels Capital"],
        [322, "Wallonia"],
        [323, "Flanders"],
        [324, "Boucle du Mouhoun"],
        [325, "Cascades"],
        [326, "Centre"],
        [327, "Centre-Est"],
        [328, "Centre-Nord"],
        [329, "Centre-Ouest"],
        [330, "Centre-Sud"],
        [331, "Est"],
        [332, "Hauts-Bassins"],
        [333, "Nord"],
        [334, "Plateau-Central"],
        [335, "Sahel"],
        [336, "Sud-Ouest"],
        [337, "Razgrad"],
        [338, "Montana"],
        [339, "Vratsa"],
        [340, "Varna"],
        [341, "Dobrich"],
        [342, "Sofia"],
        [343, "Ruse"],
        [344, "Plovdiv"],
        [345, "Pleven"],
        [346, "Pernik"],
        [347, "Pazardzhik"],
        [348, "Lovech"],
        [349, "Haskovo"],
        [350, "Sofia-Capital"],
        [351, "Burgas"],
        [352, "Blagoevgrad"],
        [353, "Gabrovo"],
        [354, "Kardzhali"],
        [355, "Kyustendil"],
        [356, "Shumen"],
        [357, "Silistra"],
        [358, "Sliven"],
        [359, "Smolyan"],
        [360, "Stara Zagora"],
        [361, "Targovishte"],
        [362, "Veliko Tarnovo"],
        [363, "Vidin"],
        [364, "Yambol"],
        [365, "Muharraq"],
        [366, "Manama"],
        [367, "Southern Governorate"],
        [368, "Northern"],
        [369, "Makamba"],
        [370, "Bururi"],
        [371, "Muramvya"],
        [372, "Gitega"],
        [373, "Ruyigi"],
        [374, "Cankuzo"],
        [375, "Karuzi"],
        [376, "Bubanza"],
        [377, "Cibitoke"],
        [378, "Ngozi"],
        [379, "Kayanza"],
        [380, "Muyinga"],
        [381, "Kirundo"],
        [382, "Rutana"],
        [383, "Mwaro"],
        [384, "Bujumbura Mairie"],
        [385, "Bujumbura Rural"],
        [386, "Rumonge"],
        [387, "Zou"],
        [388, "Ouémé"],
        [389, "Mono"],
        [390, "Borgou"],
        [391, "Atlantique"],
        [392, "Atakora"],
        [393, "Alibori"],
        [394, "Collines"],
        [395, "Kouffo"],
        [396, "Donga"],
        [397, "Littoral"],
        [398, "Plateau"],
        [399, "Warwick"],
        [400, "Southampton"],
        [401, "Smith's"],
        [402, "Sandys"],
        [403, "Saint George's"],
        [404, "Saint George"],
        [405, "Pembroke"],
        [406, "Paget"],
        [407, "Hamilton"],
        [408, "Hamilton city"],
        [409, "Devonshire"],
        [410, "Tutong"],
        [411, "Temburong"],
        [412, "Brunei-Muara District"],
        [413, "Belait"],
        [414, "Tarija"],
        [415, "Santa Cruz"],
        [416, "Potosí"],
        [417, "Pando"],
        [418, "Oruro"],
        [419, "La Paz"],
        [420, "Cochabamba"],
        [421, "Chuquisaca"],
        [422, "El Beni"],
        [423, "Bonaire"],
        [424, "Saba"],
        [425, "Sint Eustatius"],
        [426, "Rio Grande do Norte"],
        [427, "Piauí"],
        [428, "Pernambuco"],
        [429, "Paraíba"],
        [430, "Pará"],
        [431, "Maranhão"],
        [432, "Ceará"],
        [433, "Amapá"],
        [434, "Alagoas"],
        [435, "Sergipe"],
        [436, "São Paulo"],
        [437, "Santa Catarina"],
        [438, "Rio Grande do Sul"],
        [439, "Rio de Janeiro"],
        [440, "Paraná"],
        [441, "Minas Gerais"],
        [442, "Mato Grosso do Sul"],
        [443, "Mato Grosso"],
        [444, "Goiás"],
        [445, "Federal District"],
        [446, "Espírito Santo"],
        [447, "Bahia"],
        [448, "Tocantins"],
        [449, "Roraima"],
        [450, "Amazonas"],
        [451, "Acre"],
        [452, "Rondônia"],
        [453, "San Salvador"],
        [454, "Ragged Island"],
        [455, "Berry Islands"],
        [456, "New Providence"],
        [457, "Mayaguana"],
        [458, "Long Island"],
        [459, "Inagua"],
        [460, "Harbour Island"],
        [461, "Freeport"],
        [462, "Exuma"],
        [463, "Cat Island"],
        [464, "Bimini"],
        [465, "Acklins"],
        [466, "Black Point"],
        [467, "Central Abaco"],
        [468, "Central Andros"],
        [469, "Central Eleuthera"],
        [470, "Crooked Island and Long Cay"],
        [471, "East Grand Bahama"],
        [472, "Grand Cay"],
        [473, "Hope Town"],
        [474, "Mangrove Cay"],
        [475, "Moore’s Island"],
        [476, "North Abaco"],
        [477, "North Andros"],
        [478, "North Eleuthera"],
        [479, "Rum Cay"],
        [480, "South Abaco"],
        [481, "South Andros"],
        [482, "South Eleuthera"],
        [483, "Spanish Wells"],
        [484, "West Grand Bahama"],
        [485, "Bumthang"],
        [486, "Chukha"],
        [487, "Dagana"],
        [488, "Chirang"],
        [489, "Geylegphug"],
        [490, "Haa"],
        [491, "Lhuntse"],
        [492, "Mongar"],
        [493, "Paro"],
        [494, "Pemagatshel"],
        [495, "Punakha"],
        [496, "Samchi"],
        [497, "Samdrup Jongkhar"],
        [498, "Shemgang"],
        [499, "Tashigang"],
        [500, "Thimphu"],
        [501, "Tongsa"],
        [502, "Wangdi Phodrang"],
        [503, "Gasa"],
        [504, "Trashi Yangste"],
        [505, "Ngwaketsi"],
        [506, "South-East"],
        [507, "North-East"],
        [508, "North-West"],
        [509, "Kweneng"],
        [510, "Kgatleng"],
        [511, "Kgalagadi"],
        [512, "Ghanzi"],
        [513, "Chobe"],
        [514, "Central"],
        [515, "City of Francistown"],
        [516, "Gaborone"],
        [517, "Jwaneng"],
        [518, "Lobatse"],
        [519, "Selibe Phikwe"],
        [520, "Sowa Town"],
        [521, "Vitebsk"],
        [522, "Mogilev"],
        [523, "Minsk"],
        [524, "Minsk City"],
        [525, "Grodnenskaya"],
        [526, "Gomel Oblast"],
        [527, "Brest"],
        [528, "Toledo"],
        [529, "Southern District"],
        [530, "Orange Walk"],
        [531, "Corozal"],
        [532, "Cayo"],
        [533, "Belize"],
        [534, "Tshuapa"],
        [535, "Tshopo"],
        [536, "Tanganyika"],
        [537, "South Kivu"],
        [538, "Sankuru"],
        [539, "Nord Kivu"],
        [540, "Mongala"],
        [541, "Maniema"],
        [542, "Kasai-Central"],
        [543, "Kasaï-Oriental"],
        [544, "Kasai"],
        [545, "Ituri"],
        [546, "Haut-Uele"],
        [547, "Haut-Lomami"],
        [548, "Équateur"],
        [549, "Bas-Uele"],
        [550, "Lualaba"],
        [551, "Mai-Ndombe"],
        [552, "Kwilu"],
        [553, "Kwango"],
        [554, "Kinshasa"],
        [555, "Bas-Congo"],
        [556, "Haut-Katanga"],
        [557, "Lomami"],
        [558, "Nord-Ubangi"],
        [559, "Sud-Ubangi"],
        [560, "Vakaga"],
        [561, "Ouaka"],
        [562, "Mbomou"],
        [563, "Haut-Mbomou"],
        [564, "Haute-Kotto"],
        [565, "Basse-Kotto"],
        [566, "Bamingui-Bangoran"],
        [567, "Sangha-Mbaéré"],
        [568, "Ouham-Pendé"],
        [569, "Ouham"],
        [570, "Ombella-M'Poko"],
        [571, "Nana-Mambéré"],
        [572, "Lobaye"],
        [573, "Kémo"],
        [574, "Mambéré-Kadéï"],
        [575, "Nana-Grébizi"],
        [576, "Bangui"],
        [577, "Sangha"],
        [578, "Pool"],
        [579, "Plateaux"],
        [580, "Niari"],
        [581, "Likouala"],
        [582, "Lékoumou"],
        [583, "Kouilou"],
        [584, "Cuvette"],
        [585, "Bouenza"],
        [586, "Brazzaville"],
        [587, "Cuvette-Ouest"],
        [588, "Pointe-Noire"],
        [589, "Zurich"],
        [590, "Zug"],
        [591, "Vaud"],
        [592, "Valais"],
        [593, "Uri"],
        [594, "Ticino"],
        [595, "Thurgau"],
        [596, "Solothurn"],
        [597, "Schwyz"],
        [598, "Schaffhausen"],
        [599, "Saint Gallen"],
        [600, "Obwalden"],
        [601, "Nidwalden"],
        [602, "Neuchâtel"],
        [603, "Lucerne"],
        [604, "Jura"],
        [605, "Grisons"],
        [606, "Glarus"],
        [607, "Geneva"],
        [608, "Fribourg"],
        [609, "Bern"],
        [610, "Basel-City"],
        [611, "Basel-Landschaft"],
        [612, "Appenzell Ausserrhoden"],
        [613, "Appenzell Innerrhoden"],
        [614, "Aargau"],
        [615, "Yamoussoukro"],
        [616, "Bas-Sassandra"],
        [617, "Comoé"],
        [618, "Denguélé"],
        [619, "Gôh-Djiboua"],
        [620, "Lacs"],
        [621, "Lagunes"],
        [622, "Montagnes"],
        [623, "Sassandra-Marahoué"],
        [624, "Savanes"],
        [625, "Vallée du Bandama"],
        [626, "Woroba"],
        [627, "Zanzan"],
        [628, "Abidjan"],
        [629, "Aitutaki"],
        [630, "Atiu"],
        [631, "Mangaia"],
        [632, "Manihiki"],
        [633, "Ma'uke"],
        [634, "Mitiaro"],
        [635, "Palmerston"],
        [636, "Penrhyn"],
        [637, "Pukapuka"],
        [638, "Rakahanga"],
        [639, "Rarotonga"],
        [640, "Valparaíso"],
        [641, "Tarapacá"],
        [642, "Santiago Metropolitan"],
        [643, "Maule Region"],
        [644, "Los Lagos Region"],
        [645, "O'Higgins Region"],
        [646, "Coquimbo Region"],
        [647, "Biobío"],
        [648, "Atacama"],
        [649, "Araucanía"],
        [650, "Antofagasta"],
        [651, "Aysén"],
        [652, "Region of Magallanes"],
        [653, "Arica y Parinacota"],
        [654, "Los Ríos Region"],
        [655, "Ñuble"],
        [656, "South-West"],
        [657, "South"],
        [658, "West"],
        [659, "North-West"],
        [660, "North"],
        [661, "Littoral"],
        [662, "Far North"],
        [663, "East"],
        [664, "Centre"],
        [665, "Adamaoua"],
        [666, "Tibet"],
        [667, "Qinghai"],
        [668, "Xinjiang"],
        [669, "Zhejiang"],
        [670, "Yunnan"],
        [671, "Tianjin"],
        [672, "Sichuan"],
        [673, "Shanxi"],
        [674, "Shanghai"],
        [675, "Shandong"],
        [676, "Shaanxi"],
        [677, "Ningxia Hui Autonomous Region"],
        [678, "Jiangxi"],
        [679, "Jiangsu"],
        [680, "Hunan"],
        [681, "Hubei"],
        [682, "Henan"],
        [683, "Hebei"],
        [684, "Hainan"],
        [685, "Guizhou"],
        [686, "Guangxi"],
        [687, "Guangdong"],
        [688, "Gansu"],
        [689, "Fujian"],
        [690, "Chongqing"],
        [691, "Anhui"],
        [692, "Inner Mongolia"],
        [693, "Liaoning"],
        [694, "Jilin"],
        [695, "Heilongjiang"],
        [696, "Beijing"],
        [697, "Vichada"],
        [698, "Vaupés"],
        [699, "Valle del Cauca"],
        [700, "Tolima"],
        [701, "Sucre"],
        [702, "Santander"],
        [703, "San Andres y Providencia"],
        [704, "Risaralda"],
        [705, "Quindío"],
        [706, "Putumayo"],
        [707, "Norte de Santander"],
        [708, "Nariño"],
        [709, "Meta"],
        [710, "Magdalena"],
        [711, "La Guajira"],
        [712, "Huila"],
        [713, "Guaviare"],
        [714, "Guainía"],
        [715, "Cundinamarca"],
        [716, "Córdoba"],
        [717, "Chocó"],
        [718, "Cesar"],
        [719, "Cauca"],
        [720, "Casanare"],
        [721, "Caquetá"],
        [722, "Caldas"],
        [723, "Boyacá"],
        [724, "Bolívar"],
        [725, "Bogota D.C."],
        [726, "Atlántico"],
        [727, "Departamento de Arauca"],
        [728, "Antioquia"],
        [729, "Amazonas"],
        [730, "San José"],
        [731, "Puntarenas"],
        [732, "Limón"],
        [733, "Heredia"],
        [734, "Guanacaste"],
        [735, "Cartago"],
        [736, "Alajuela"],
        [737, "Villa Clara"],
        [738, "Santiago de Cuba"],
        [739, "Sancti Spíritus"],
        [740, "Pinar del Río"],
        [741, "Matanzas"],
        [742, "Las Tunas"],
        [743, "Isla de la Juventud"],
        [744, "Holguín"],
        [745, "Guantánamo"],
        [746, "Granma"],
        [747, "Havana"],
        [748, "Cienfuegos"],
        [749, "Ciego de Ávila"],
        [750, "Camagüey"],
        [751, "Artemisa"],
        [752, "Mayabeque"],
        [753, "Tarrafal"],
        [754, "São Vicente"],
        [755, "Santa Catarina"],
        [756, "Sal"],
        [757, "Ribeira Grande"],
        [758, "Praia"],
        [759, "Paul"],
        [760, "Maio"],
        [761, "Brava"],
        [762, "Boa Vista"],
        [763, "Mosteiros"],
        [764, "Santa Cruz"],
        [765, "São Domingos"],
        [766, "São Filipe"],
        [767, "São Miguel"],
        [768, "Porto Novo"],
        [769, "Ribeira Brava"],
        [770, "Santa Catarina do Fogo"],
        [771, "São Salvador do Mundo"],
        [772, "Tarrafal de São Nicolau"],
        [773, "São Lourenço dos Órgãos"],
        [774, "Ribeira Grande de Santiago"],
        [775, "Pafos"],
        [776, "Nicosia"],
        [777, "Limassol"],
        [778, "Larnaka"],
        [779, "Keryneia"],
        [780, "Ammochostos"],
        [781, "Hlavní mesto Praha"],
        [782, "South Moravian"],
        [783, "Jihoceský kraj"],
        [784, "Vysocina"],
        [785, "Karlovarský kraj"],
        [786, "Královéhradecký kraj"],
        [787, "Liberecký kraj"],
        [788, "Olomoucký"],
        [789, "Moravskoslezský"],
        [790, "Pardubický"],
        [791, "Plzenský kraj"],
        [792, "Central Bohemia"],
        [793, "Ústecký kraj"],
        [794, "Zlín"],
        [795, "Thuringia"],
        [796, "Schleswig-Holstein"],
        [797, "Saxony-Anhalt"],
        [798, "Saxony"],
        [799, "Saarland"],
        [800, "Rheinland-Pfalz"],
        [801, "North Rhine-Westphalia"],
        [802, "Lower Saxony"],
        [803, "Mecklenburg-Vorpommern"],
        [804, "Hesse"],
        [805, "Hamburg"],
        [806, "Bremen"],
        [807, "Brandenburg"],
        [808, "Berlin"],
        [809, "Bavaria"],
        [810, "Baden-Württemberg"],
        [811, "Tadjourah"],
        [812, "Obock"],
        [813, "Djibouti"],
        [814, "Dikhil"],
        [815, "Ali Sabieh"],
        [816, "Arta"],
        [817, "Capital Region"],
        [818, "Central Jutland"],
        [819, "North Denmark"],
        [820, "Zealand"],
        [821, "South Denmark"],
        [822, "Saint Peter"],
        [823, "Saint Paul"],
        [824, "Saint Patrick"],
        [825, "Saint Mark"],
        [826, "Saint Luke"],
        [827, "Saint Joseph"],
        [828, "Saint John"],
        [829, "Saint George"],
        [830, "Saint David"],
        [831, "Saint Andrew"],
        [832, "Valverde"],
        [833, "Santiago Rodríguez"],
        [834, "Santiago"],
        [835, "San Pedro de Macorís"],
        [836, "San Juan"],
        [837, "San Cristóbal"],
        [838, "Sánchez Ramírez"],
        [839, "Samaná"],
        [840, "Hermanas Mirabal"],
        [841, "Puerto Plata"],
        [842, "Peravia"],
        [843, "Pedernales"],
        [844, "Nacional"],
        [845, "Monte Plata"],
        [846, "Monte Cristi"],
        [847, "Monseñor Nouel"],
        [848, "María Trinidad Sánchez"],
        [849, "La Vega"],
        [850, "La Romana"],
        [851, "La Altagracia"],
        [852, "Independencia"],
        [853, "Hato Mayor"],
        [854, "Espaillat"],
        [855, "El Seíbo"],
        [856, "Elías Piña"],
        [857, "Duarte"],
        [858, "Dajabón"],
        [859, "Barahona"],
        [860, "Baoruco"],
        [861, "Azua"],
        [862, "San José de Ocoa"],
        [863, "Santo Domingo"],
        [864, "Tlemcen"],
        [865, "Tizi Ouzou"],
        [866, "Tissemsilt"],
        [867, "Tipaza"],
        [868, "Tindouf"],
        [869, "Tiaret"],
        [870, "Tébessa"],
        [871, "Tamanrasset"],
        [872, "Souk Ahras"],
        [873, "Skikda"],
        [874, "Sidi Bel Abbès"],
        [875, "Sétif"],
        [876, "Saida"],
        [877, "Relizane"],
        [878, "Oum el Bouaghi"],
        [879, "Ouargla"],
        [880, "Oran"],
        [881, "Naama"],
        [882, "M'Sila"],
        [883, "Mostaganem"],
        [884, "Mila"],
        [885, "Medea"],
        [886, "Mascara"],
        [887, "Laghouat"],
        [888, "Khenchela"],
        [889, "Jijel"],
        [890, "Illizi"],
        [891, "Guelma"],
        [892, "Ghardaia"],
        [893, "El Tarf"],
        [894, "El Oued"],
        [895, "El Bayadh"],
        [896, "Djelfa"],
        [897, "Constantine"],
        [898, "Chlef"],
        [899, "Boumerdes"],
        [900, "Bouira"],
        [901, "Bordj Bou Arréridj"],
        [902, "Blida"],
        [903, "Biskra"],
        [904, "Béjaïa"],
        [905, "Béchar"],
        [906, "Batna"],
        [907, "Annaba"],
        [908, "Algiers"],
        [909, "Aïn Témouchent"],
        [910, "Aïn Defla"],
        [911, "Adrar"],
        [912, "Bordj Badji Mokhtar"],
        [913, "Timimoun"],
        [914, "Beni Abbes"],
        [915, "In Guezzam"],
        [916, "In Salah"],
        [917, "Djanet"],
        [918, "El Menia"],
        [919, "Touggourt"],
        [920, "El Mghair"],
        [921, "Ouled Djellal"],
        [922, "Zamora-Chinchipe"],
        [923, "Tungurahua"],
        [924, "Pichincha"],
        [925, "Pastaza"],
        [926, "Napo"],
        [927, "Morona-Santiago"],
        [928, "Manabí"],
        [929, "Los Ríos"],
        [930, "Loja"],
        [931, "Imbabura"],
        [932, "Guayas"],
        [933, "Galápagos"],
        [934, "Esmeraldas"],
        [935, "El Oro"],
        [936, "Cotopaxi"],
        [937, "Chimborazo"],
        [938, "Carchi"],
        [939, "Cañar"],
        [940, "Bolívar"],
        [941, "Azuay"],
        [942, "Sucumbios"],
        [943, "Orellana"],
        [944, "Santo Domingo de los Tsáchilas"],
        [945, "Santa Elena"],
        [946, "Võrumaa"],
        [947, "Viljandimaa"],
        [948, "Valgamaa"],
        [949, "Tartu"],
        [950, "Saare"],
        [951, "Raplamaa"],
        [952, "Põlvamaa"],
        [953, "Pärnumaa"],
        [954, "Lääne-Virumaa"],
        [955, "Lääne"],
        [956, "Jõgevamaa"],
        [957, "Järvamaa"],
        [958, "Ida-Virumaa"],
        [959, "Hiiumaa"],
        [960, "Harjumaa"],
        [961, "Sohag"],
        [962, "North Sinai"],
        [963, "Qena"],
        [964, "Matruh"],
        [965, "Kafr el-Sheikh"],
        [966, "South Sinai"],
        [967, "Damietta"],
        [968, "Port Said"],
        [969, "Beni Suweif"],
        [970, "Asyut"],
        [971, "Aswan"],
        [972, "Suez"],
        [973, "Sharqia"],
        [974, "New Valley"],
        [975, "Qalyubia"],
        [976, "Cairo"],
        [977, "Minya"],
        [978, "Monufia"],
        [979, "Giza"],
        [980, "Ismailia"],
        [981, "Alexandria"],
        [982, "Gharbia"],
        [983, "Faiyum"],
        [984, "Beheira"],
        [985, "Red Sea"],
        [986, "Dakahlia"],
        [987, "Luxor"],
        [988, "Anseba"],
        [989, "Debub"],
        [990, "Southern Red Sea"],
        [991, "Gash-Barka"],
        [992, "Maekel"],
        [993, "Northern Red Sea"],
        [994, "Murcia"],
        [995, "Ceuta"],
        [996, "Balearic Islands"],
        [997, "Andalusia"],
        [998, "Canary Islands"],
        [999, "Castille-La Mancha"],
        [1000, "Extremadura"],
        [1001, "Valencia"],
        [1002, "Asturias"],
        [1003, "Navarre"],
        [1004, "Madrid"],
        [1005, "La Rioja"],
        [1006, "Cantabria"],
        [1007, "Aragon"],
        [1008, "Castille and León"],
        [1009, "Catalonia"],
        [1010, "Galicia"],
        [1011, "Basque Country"],
        [1012, "Melilla"],
        [1013, "Addis Ababa"],
        [1014, "Afar"],
        [1015, "Amhara"],
        [1016, "Binshangul Gumuz"],
        [1017, "Dire Dawa"],
        [1018, "Gambela"],
        [1019, "Harari"],
        [1020, "Oromiya"],
        [1021, "Somali"],
        [1022, "Tigray"],
        [1023, "SNNPR"],
        [1024, "Lapland"],
        [1025, "Kainuu"],
        [1026, "North Ostrobothnia"],
        [1027, "Central Ostrobothnia"],
        [1028, "Ostrobothnia"],
        [1029, "South Ostrobothnia"],
        [1030, "Central Finland"],
        [1031, "North Karelia"],
        [1032, "North Savo"],
        [1033, "South Savo"],
        [1034, "South Karelia"],
        [1035, "Kymenlaakso"],
        [1036, "Pirkanmaa"],
        [1037, "Kanta-Häme"],
        [1038, "Southwest Finland"],
        [1039, "Uusimaa"],
        [1040, "Paijat-Hame"],
        [1041, "Satakunta"],
        [1042, "Western"],
        [1043, "Northern"],
        [1044, "Central"],
        [1045, "Eastern"],
        [1046, "Rotuma"],
        [1047, "Yap"],
        [1048, "Pohnpei"],
        [1049, "Kosrae"],
        [1050, "Chuuk"],
        [1051, "Vágar"],
        [1052, "Suðuroy"],
        [1053, "Streymoy"],
        [1054, "Sandoy"],
        [1055, "Norðoyar"],
        [1056, "Eysturoy"],
        [1057, "Provence-Alpes-Côte d'Azur"],
        [1058, "Pays de la Loire"],
        [1059, "Île-de-France"],
        [1060, "Corsica"],
        [1061, "Centre"],
        [1062, "Brittany"],
        [1063, "Bourgogne-Franche-Comté"],
        [1064, "Nouvelle-Aquitaine"],
        [1065, "Normandy"],
        [1066, "Grand Est"],
        [1067, "Occitanie"],
        [1068, "Hauts-de-France"],
        [1069, "Auvergne-Rhône-Alpes"],
        [1070, "Woleu-Ntem"],
        [1071, "Ogooué-Maritime"],
        [1072, "Ogooué-Lolo"],
        [1073, "Ogooué-Ivindo"],
        [1074, "Nyanga"],
        [1075, "Ngouni"],
        [1076, "Moyen-Ogooué"],
        [1077, "Haut-Ogooué"],
        [1078, "Estuaire"],
        [1079, "Wales"],
        [1080, "Scotland"],
        [1081, "Northern Ireland"],
        [1082, "England"],
        [1083, "Saint Patrick"],
        [1084, "Saint Mark"],
        [1085, "Saint John"],
        [1086, "Saint George"],
        [1087, "Saint David"],
        [1088, "Saint Andrew"],
        [1089, "Carriacou and Petite Martinique"],
        [1090, "T'bilisi"],
        [1091, "Achara"],
        [1092, "Kvemo Kartli"],
        [1093, "Kakheti"],
        [1094, "Guria"],
        [1095, "Imereti"],
        [1096, "Shida Kartli"],
        [1097, "Mtskheta-Mtianeti"],
        [1098, "Racha-Lechkhumi and Kvemo Svaneti"],
        [1099, "Samegrelo and Zemo Svaneti"],
        [1100, "Samtskhe-Javakheti"],
        [1101, "Abkhazia"],
        [1102, "Guyane"],
        [1103, "St Pierre du Bois"],
        [1104, "Torteval"],
        [1105, "Saint Saviour"],
        [1106, "Forest"],
        [1107, "St Martin"],
        [1108, "Saint Andrew"],
        [1109, "St Peter Port"],
        [1110, "Castel"],
        [1111, "Vale"],
        [1112, "St Sampson"],
        [1113, "Alderney"],
        [1114, "Western"],
        [1115, "Volta"],
        [1116, "Upper West"],
        [1117, "Upper East"],
        [1118, "Northern"],
        [1119, "Greater Accra"],
        [1120, "Eastern"],
        [1121, "Central"],
        [1122, "Ashanti"],
        [1123, "Bono"],
        [1124, "Oti"],
        [1125, "North East"],
        [1126, "Ahafo"],
        [1127, "Bono East"],
        [1128, "Savannah"],
        [1129, "Western North"],
        [1130, "Kujalleq"],
        [1131, "Qeqqata"],
        [1132, "Sermersooq"],
        [1133, "Qeqertalik"],
        [1134, "Avannaata"],
        [1135, "Western"],
        [1136, "Upper River"],
        [1137, "North Bank"],
        [1138, "Central River"],
        [1139, "Lower River"],
        [1140, "Banjul"],
        [1141, "Conakry"],
        [1142, "Boke"],
        [1143, "Faranah"],
        [1144, "Kankan"],
        [1145, "Kindia"],
        [1146, "Labe"],
        [1147, "Mamou"],
        [1148, "Nzerekore"],
        [1149, "Guadeloupe"],
        [1150, "Annobon"],
        [1151, "Bioko Norte"],
        [1152, "Bioko Sur"],
        [1153, "Centro Sur"],
        [1154, "Kié-Ntem"],
        [1155, "Litoral"],
        [1156, "Wele-Nzas"],
        [1157, "Djibloho"],
        [1158, "Mount Athos"],
        [1159, "Attica"],
        [1160, "Central Greece"],
        [1161, "Central Macedonia"],
        [1162, "Crete"],
        [1163, "East Macedonia and Thrace"],
        [1164, "Epirus"],
        [1165, "Ionian Islands"],
        [1166, "North Aegean"],
        [1167, "Peloponnese"],
        [1168, "South Aegean"],
        [1169, "Thessaly"],
        [1170, "West Greece"],
        [1171, "West Macedonia"],
        [1172, "Zacapa"],
        [1173, "Totonicapán"],
        [1174, "Suchitepeque"],
        [1175, "Sololá"],
        [1176, "Santa Rosa"],
        [1177, "San Marcos"],
        [1178, "Sacatepéquez"],
        [1179, "Retalhuleu"],
        [1180, "Quiché"],
        [1181, "Quetzaltenango"],
        [1182, "Petén"],
        [1183, "Jutiapa"],
        [1184, "Jalapa"],
        [1185, "Izabal"],
        [1186, "Huehuetenango"],
        [1187, "Guatemala"],
        [1188, "Escuintla"],
        [1189, "El Progreso"],
        [1190, "Chiquimula"],
        [1191, "Chimaltenango"],
        [1192, "Baja Verapaz"],
        [1193, "Alta Verapaz"],
        [1194, "Piti"],
        [1195, "Santa Rita"],
        [1196, "Sinajana"],
        [1197, "Talofofo"],
        [1198, "Tamuning"],
        [1199, "Umatac"],
        [1200, "Yigo"],
        [1201, "Yona"],
        [1202, "Merizo"],
        [1203, "Mangilao"],
        [1204, "Agana Heights"],
        [1205, "Chalan Pago-Ordot"],
        [1206, "Asan"],
        [1207, "Agat"],
        [1208, "Dededo"],
        [1209, "Barrigada"],
        [1210, "Hagatna"],
        [1211, "Inarajan"],
        [1212, "Mongmong-Toto-Maite"],
        [1213, "Tombali"],
        [1214, "Quinara"],
        [1215, "Oio"],
        [1216, "Gabú"],
        [1217, "Cacheu"],
        [1218, "Bolama"],
        [1219, "Bissau"],
        [1220, "Biombo"],
        [1221, "Bafatá"],
        [1222, "Upper Takutu-Upper Essequibo"],
        [1223, "Upper Demerara-Berbice"],
        [1224, "Potaro-Siparuni"],
        [1225, "Pomeroon-Supenaam"],
        [1226, "Mahaica-Berbice"],
        [1227, "Essequibo Islands-West Demerara"],
        [1228, "East Berbice-Corentyne"],
        [1229, "Demerara-Mahaica"],
        [1230, "Cuyuni-Mazaruni"],
        [1231, "Barima-Waini"],
        [1232, "Yuen Long"],
        [1233, "Tsuen Wan"],
        [1234, "Tai Po"],
        [1235, "Sai Kung"],
        [1236, "Islands"],
        [1237, "Central and Western"],
        [1238, "Wan Chai"],
        [1239, "Eastern"],
        [1240, "Southern"],
        [1241, "Yau Tsim Mong"],
        [1242, "Sham Shui Po"],
        [1243, "Kowloon City"],
        [1244, "Wong Tai Sin"],
        [1245, "Kwun Tong"],
        [1246, "Kwai Tsing"],
        [1247, "Tuen Mun"],
        [1248, "North"],
        [1249, "Sha Tin"],
        [1250, "Yoro"],
        [1251, "Valle"],
        [1252, "Santa Bárbara"],
        [1253, "Olancho"],
        [1254, "Ocotepeque"],
        [1255, "Lempira"],
        [1256, "La Paz"],
        [1257, "Bay Islands"],
        [1258, "Intibucá"],
        [1259, "Gracias a Dios"],
        [1260, "Francisco Morazán"],
        [1261, "El Paraíso"],
        [1262, "Cortés"],
        [1263, "Copán"],
        [1264, "Comayagua"],
        [1265, "Colón"],
        [1266, "Choluteca"],
        [1267, "Atlántida"],
        [1268, "Bjelovarsko-Bilogorska"],
        [1269, "Slavonski Brod-Posavina"],
        [1270, "Dubrovacko-Neretvanska"],
        [1271, "Istria"],
        [1272, "Karlovacka"],
        [1273, "Koprivnicko-Križevacka"],
        [1274, "Krapinsko-Zagorska"],
        [1275, "Licko-Senjska"],
        [1276, "Medimurska"],
        [1277, "Osjecko-Baranjska"],
        [1278, "Požeško-Slavonska"],
        [1279, "Primorsko-Goranska"],
        [1280, "Šibensko-Kniniska"],
        [1281, "Sisacko-Moslavacka"],
        [1282, "Split-Dalmatia"],
        [1283, "Varaždinska"],
        [1284, "Vukovar-Sirmium"],
        [1285, "Zadarska"],
        [1286, "Zagrebacka"],
        [1287, "City of Zagreb"],
        [1288, "Virovitick-Podravska"],
        [1289, "Sud-Est"],
        [1290, "Sud"],
        [1291, "Ouest"],
        [1292, "Nord-Ouest"],
        [1293, "Nord-Est"],
        [1294, "Nord"],
        [1295, "Grand'Anse"],
        [1296, "Centre"],
        [1297, "Artibonite"],
        [1298, "Nippes"],
        [1299, "Szabolcs-Szatmár-Bereg"],
        [1300, "Jász-Nagykun-Szolnok"],
        [1301, "Heves"],
        [1302, "Hajdú-Bihar"],
        [1303, "Csongrád"],
        [1304, "Borsod-Abaúj-Zemplén"],
        [1305, "Bekes County"],
        [1306, "Zala"],
        [1307, "Veszprém"],
        [1308, "Vas"],
        [1309, "Tolna"],
        [1310, "Somogy"],
        [1311, "Pest"],
        [1312, "Nógrád"],
        [1313, "Komárom-Esztergom"],
        [1314, "Gyor-Moson-Sopron"],
        [1315, "Fejér"],
        [1316, "Budapest"],
        [1317, "Baranya"],
        [1318, "Bács-Kiskun"],
        [1319, "North Sumatra"],
        [1320, "Aceh"],
        [1321, "Yogyakarta"],
        [1322, "South Sumatra"],
        [1323, "West Sumatra"],
        [1324, "North Sulawesi"],
        [1325, "Southeast Sulawesi"],
        [1326, "Central Sulawesi"],
        [1327, "South Sulawesi"],
        [1328, "Riau"],
        [1329, "East Nusa Tenggara"],
        [1330, "West Nusa Tenggara"],
        [1331, "Maluku"],
        [1332, "Lampung"],
        [1333, "East Kalimantan"],
        [1334, "Central Kalimantan"],
        [1335, "South Kalimantan"],
        [1336, "West Kalimantan"],
        [1337, "East Java"],
        [1338, "Central Java"],
        [1339, "West Java"],
        [1340, "Jambi"],
        [1341, "Jakarta"],
        [1342, "Papua"],
        [1343, "Bengkulu"],
        [1344, "Bali"],
        [1345, "Banten"],
        [1346, "Gorontalo"],
        [1347, "Bangka–Belitung Islands"],
        [1348, "North Maluku"],
        [1349, "West Papua"],
        [1350, "West Sulawesi"],
        [1351, "Riau Islands"],
        [1352, "North Kalimantan"],
        [1353, "Connaught"],
        [1354, "Leinster"],
        [1355, "Munster"],
        [1356, "Ulster"],
        [1357, "Jerusalem"],
        [1358, "Tel Aviv"],
        [1359, "Haifa"],
        [1360, "Northern District"],
        [1361, "Central District"],
        [1362, "Southern District"],
        [1363, "Judea and Samaria Area"],
        [1364, "Andreas"],
        [1365, "Arbory"],
        [1366, "Ballaugh"],
        [1367, "Braddan"],
        [1368, "Bride"],
        [1369, "Castletown"],
        [1370, "Douglas"],
        [1371, "German"],
        [1372, "Jurby"],
        [1373, "Laxey"],
        [1374, "Lezayre"],
        [1375, "Lonan"],
        [1376, "Malew"],
        [1377, "Marown"],
        [1378, "Maughold"],
        [1379, "Michael"],
        [1380, "Onchan"],
        [1381, "Patrick"],
        [1382, "Peel"],
        [1383, "Port Erin"],
        [1384, "Port St Mary"],
        [1385, "Ramsey"],
        [1386, "Rushen"],
        [1387, "Santon"],
        [1388, "West Bengal"],
        [1389, "Uttar Pradesh"],
        [1390, "Tripura"],
        [1391, "Telangana"],
        [1392, "Tamil Nadu"],
        [1393, "Sikkim"],
        [1394, "Rajasthan"],
        [1395, "Punjab"],
        [1396, "Puducherry"],
        [1397, "Odisha"],
        [1398, "Nagaland"],
        [1399, "Mizoram"],
        [1400, "Meghalaya"],
        [1401, "Manipur"],
        [1402, "Maharashtra"],
        [1403, "Madhya Pradesh"],
        [1404, "Laccadives"],
        [1405, "Kerala"],
        [1406, "Karnataka"],
        [1407, "Jammu and Kashmir"],
        [1408, "Himachal Pradesh"],
        [1409, "Haryana"],
        [1410, "Gujarat"],
        [1411, "Goa"],
        [1412, "Delhi"],
        [1413, "Chandigarh"],
        [1414, "Bihar"],
        [1415, "Assam"],
        [1416, "Arunachal Pradesh"],
        [1417, "Andhra Pradesh"],
        [1418, "Andaman and Nicobar"],
        [1419, "Chhattisgarh"],
        [1420, "Jharkhand"],
        [1421, "Uttarakhand"],
        [1422, "Ladakh"],
        [1423, "Dadra and Nagar Haveli and Daman and Diu"],
        [1424, "Basra"],
        [1425, "Wasit"],
        [1426, "Salah ad Din"],
        [1427, "Nineveh"],
        [1428, "Maysan"],
        [1429, "Karbala'"],
        [1430, "Arbil"],
        [1431, "Diyalá"],
        [1432, "Dhi Qar"],
        [1433, "Duhok"],
        [1434, "Baghdad"],
        [1435, "Babil"],
        [1436, "Kirkuk"],
        [1437, "Sulaymaniyah"],
        [1438, "An Najaf"],
        [1439, "Al Qadisiyah"],
        [1440, "Al Muthanná"],
        [1441, "Al Anbar"],
        [1442, "Halabja Governorate"],
        [1443, "Tehran"],
        [1444, "Zanjan"],
        [1445, "Yazd"],
        [1446, "Semnan"],
        [1447, "Mazandaran"],
        [1448, "Markazi"],
        [1449, "Lorestan Province"],
        [1450, "Kordestan"],
        [1451, "Kohgiluyeh and Boyer-Ahmad"],
        [1452, "Khuzestan"],
        [1453, "Kermanshah"],
        [1454, "Kerman"],
        [1455, "Ilam Province"],
        [1456, "Hormozgan"],
        [1457, "Hamadan"],
        [1458, "Gilan"],
        [1459, "Fars"],
        [1460, "Chaharmahal and Bakhtiari"],
        [1461, "Bushehr"],
        [1462, "East Azerbaijan"],
        [1463, "West Azerbaijan"],
        [1464, "Ardabil"],
        [1465, "Isfahan"],
        [1466, "Golestan"],
        [1467, "Qazvin"],
        [1468, "Qom"],
        [1469, "Sistan and Baluchestan"],
        [1470, "South Khorasan Province"],
        [1471, "Razavi Khorasan"],
        [1472, "North Khorasan"],
        [1473, "Alborz Province"],
        [1474, "Northwest"],
        [1475, "Northeast"],
        [1476, "East"],
        [1477, "South"],
        [1478, "Capital Region"],
        [1479, "Southern Peninsula"],
        [1480, "West"],
        [1481, "Westfjords"],
        [1482, "Sicily"],
        [1483, "Sardinia"],
        [1484, "Calabria"],
        [1485, "Veneto"],
        [1486, "Aosta Valley"],
        [1487, "Umbria"],
        [1488, "Trentino-Alto Adige"],
        [1489, "Tuscany"],
        [1490, "Apulia"],
        [1491, "Piedmont"],
        [1492, "Molise"],
        [1493, "The Marches"],
        [1494, "Lombardy"],
        [1495, "Liguria"],
        [1496, "Latium"],
        [1497, "Friuli Venezia Giulia"],
        [1498, "Emilia-Romagna"],
        [1499, "Campania"],
        [1500, "Basilicate"],
        [1501, "Abruzzo"],
        [1502, "St Clement"],
        [1503, "St Saviour"],
        [1504, "St. Brelade"],
        [1505, "Grouville"],
        [1506, "St Mary"],
        [1507, "St Lawrence"],
        [1508, "St Peter"],
        [1509, "St Ouen"],
        [1510, "St John"],
        [1511, "Trinity"],
        [1512, "St Martîn"],
        [1513, "St Helier"],
        [1514, "Westmoreland"],
        [1515, "Trelawny"],
        [1516, "St. Thomas"],
        [1517, "St. Mary"],
        [1518, "St. James"],
        [1519, "St. Elizabeth"],
        [1520, "Saint Catherine"],
        [1521, "St Ann"],
        [1522, "St. Andrew"],
        [1523, "Portland"],
        [1524, "Manchester"],
        [1525, "Kingston"],
        [1526, "Hanover"],
        [1527, "Clarendon"],
        [1528, "Ma’an"],
        [1529, "Irbid"],
        [1530, "Zarqa"],
        [1531, "Tafielah"],
        [1532, "Amman"],
        [1533, "Mafraq"],
        [1534, "Karak"],
        [1535, "Balqa"],
        [1536, "Ajlun"],
        [1537, "Jerash"],
        [1538, "Aqaba"],
        [1539, "Madaba"],
        [1540, "Yamanashi"],
        [1541, "Yamaguchi"],
        [1542, "Wakayama"],
        [1543, "Toyama"],
        [1544, "Tottori"],
        [1545, "Tokyo"],
        [1546, "Tokushima"],
        [1547, "Tochigi"],
        [1548, "Shizuoka"],
        [1549, "Shimane"],
        [1550, "Shiga"],
        [1551, "Saitama"],
        [1552, "Saga"],
        [1553, "Osaka"],
        [1554, "Okinawa"],
        [1555, "Okayama"],
        [1556, "Oita"],
        [1557, "Niigata"],
        [1558, "Nara"],
        [1559, "Nagasaki"],
        [1560, "Nagano"],
        [1561, "Miyazaki"],
        [1562, "Mie"],
        [1563, "Kyoto"],
        [1564, "Kumamoto"],
        [1565, "Kochi"],
        [1566, "Kanagawa"],
        [1567, "Kagoshima"],
        [1568, "Kagawa"],
        [1569, "Ishikawa"],
        [1570, "Hyogo"],
        [1571, "Hiroshima"],
        [1572, "Gunma"],
        [1573, "Gifu"],
        [1574, "Fukuoka"],
        [1575, "Fukui"],
        [1576, "Ehime"],
        [1577, "Aichi"],
        [1578, "Yamagata"],
        [1579, "Miyagi"],
        [1580, "Iwate"],
        [1581, "Ibaraki"],
        [1582, "Fukushima"],
        [1583, "Chiba"],
        [1584, "Akita"],
        [1585, "Hokkaido"],
        [1586, "Aomori"],
        [1587, "West Pokot"],
        [1588, "Wajir"],
        [1589, "Uasin Gishu"],
        [1590, "Turkana"],
        [1591, "Trans Nzoia"],
        [1592, "Tharaka - Nithi"],
        [1593, "Tana River"],
        [1594, "Siaya"],
        [1595, "Samburu"],
        [1596, "Nairobi Area"],
        [1597, "Murang'A"],
        [1598, "Mombasa"],
        [1599, "Meru"],
        [1600, "Marsabit"],
        [1601, "Mandera"],
        [1602, "Laikipia"],
        [1603, "Kwale"],
        [1604, "Kitui"],
        [1605, "Kisumu"],
        [1606, "Kisii"],
        [1607, "Kirinyaga"],
        [1608, "Kilifi"],
        [1609, "Kiambu"],
        [1610, "Kericho"],
        [1611, "Kakamega"],
        [1612, "Isiolo"],
        [1613, "Garissa"],
        [1614, "Embu"],
        [1615, "Busia"],
        [1616, "Bungoma"],
        [1617, "Baringo"],
        [1618, "Nyandarua"],
        [1619, "Vihiga"],
        [1620, "Lamu"],
        [1621, "Machakos"],
        [1622, "Makueni"],
        [1623, "Elegeyo-Marakwet"],
        [1624, "Taita Taveta"],
        [1625, "Kajiado"],
        [1626, "Nyeri"],
        [1627, "Homa Bay"],
        [1628, "Bomet"],
        [1629, "Migori"],
        [1630, "Nakuru"],
        [1631, "Narok"],
        [1632, "Nyamira"],
        [1633, "Nandi"],
        [1634, "Osh"],
        [1635, "Batken"],
        [1636, "Talas"],
        [1637, "Naryn"],
        [1638, "Issyk-Kul"],
        [1639, "Bishkek"],
        [1640, "Jalal-Abad"],
        [1641, "Chüy"],
        [1642, "Osh City"],
        [1643, "Pursat"],
        [1644, "Battambang"],
        [1645, "Takeo"],
        [1646, "Svay Rieng"],
        [1647, "Stung Treng"],
        [1648, "Otâr Méanchey"],
        [1649, "Siem Reap"],
        [1650, "Ratanakiri"],
        [1651, "Prey Veng"],
        [1652, "Preah Vihear"],
        [1653, "Phnom Penh"],
        [1654, "Pailin"],
        [1655, "Mondolkiri"],
        [1656, "Kratie"],
        [1657, "Kep"],
        [1658, "Koh Kong"],
        [1659, "Kandal"],
        [1660, "Kampot"],
        [1661, "Kampong Thom"],
        [1662, "Kampong Speu"],
        [1663, "Kampong Chhnang"],
        [1664, "Kampong Cham"],
        [1665, "Preah Sihanouk"],
        [1666, "Banteay Meanchey"],
        [1667, "Tboung Khmum"],
        [1668, "Gilbert Islands"],
        [1669, "Line Islands"],
        [1670, "Phoenix Islands"],
        [1671, "Mohéli"],
        [1672, "Grande Comore"],
        [1673, "Anjouan"],
        [1674, "Trinity Palmetto Point"],
        [1675, "Middle Island"],
        [1676, "Saint Thomas Lowland"],
        [1677, "Saint Peter Basseterre"],
        [1678, "Saint Paul Charlestown"],
        [1679, "Saint Paul Capesterre"],
        [1680, "Saint Mary Cayon"],
        [1681, "Saint John Figtree"],
        [1682, "Saint John Capesterre"],
        [1683, "Saint James Windwa"],
        [1684, "Saint George Gingerland"],
        [1685, "Saint George Basseterre"],
        [1686, "Saint Anne Sandy Point"],
        [1687, "Christ Church Nichola Town"],
        [1688, "Pyongyang"],
        [1689, "South Pyongan"],
        [1690, "P'yongan-bukto"],
        [1691, "Kangwon-do"],
        [1692, "Hwanghae-namdo"],
        [1693, "Hwanghae-bukto"],
        [1694, "Hamgyong-namdo"],
        [1695, "Yanggang-do"],
        [1696, "Hamgyong-bukto"],
        [1697, "Chagang-do"],
        [1698, "Rason"],
        [1699, "Ulsan"],
        [1700, "Daejeon"],
        [1701, "Daegu"],
        [1702, "Seoul"],
        [1703, "Busan"],
        [1704, "Gyeongsangbuk-do"],
        [1705, "Gyeonggi-do"],
        [1706, "Gwangju"],
        [1707, "Gangwon-do"],
        [1708, "Incheon"],
        [1709, "Chungcheongnam-do"],
        [1710, "North Chungcheong"],
        [1711, "Jeollanam-do"],
        [1712, "Jeollabuk-do"],
        [1713, "Jeju-do"],
        [1714, "Gyeongsangnam-do"],
        [1715, "Sejong-si"],
        [1716, "Hawalli"],
        [1717, "Al Asimah"],
        [1718, "Al Jahra'"],
        [1719, "Al Farwaniyah"],
        [1720, "Al A?madi"],
        [1721, "Mubarak al Kabir"],
        [1722, "George Town"],
        [1723, "West Bay"],
        [1724, "Bodden Town"],
        [1725, "North Side"],
        [1726, "East End"],
        [1727, "Sister Island"],
        [1728, "Batys Qazaqstan"],
        [1729, "Mangghystau"],
        [1730, "Atyrau"],
        [1731, "Aqtöbe"],
        [1732, "East Kazakhstan"],
        [1733, "Aqmola"],
        [1734, "North Kazakhstan"],
        [1735, "Pavlodar Region"],
        [1736, "Qyzylorda"],
        [1737, "Qostanay"],
        [1738, "Karaganda"],
        [1739, "Zhambyl"],
        [1740, "South Kazakhstan"],
        [1741, "Almaty"],
        [1742, "Almaty Oblysy"],
        [1743, "Shymkent"],
        [1744, "Baikonur"],
        [1745, "Nur-Sultan"],
        [1746, "Xiangkhoang"],
        [1747, "Xiagnabouli"],
        [1748, "Vientiane"],
        [1749, "Savannahkhét"],
        [1750, "Salavan"],
        [1751, "Phôngsali"],
        [1752, "Oudômxai"],
        [1753, "Louangphabang"],
        [1754, "Loungnamtha"],
        [1755, "Khammouan"],
        [1756, "Houaphan"],
        [1757, "Champasak"],
        [1758, "Attapu"],
        [1759, "Xékong"],
        [1760, "Bokeo"],
        [1761, "Bolikhamsai"],
        [1762, "Vientiane Prefecture"],
        [1763, "Xaisomboun"],
        [1764, "Mont-Liban"],
        [1765, "Beyrouth"],
        [1766, "Liban-Nord"],
        [1767, "South Governorate"],
        [1768, "Béqaa"],
        [1769, "Nabatîyé"],
        [1770, "Aakkâr"],
        [1771, "Baalbek-Hermel"],
        [1772, "Vieux-Fort"],
        [1773, "Soufrière"],
        [1774, "Micoud"],
        [1775, "Laborie"],
        [1776, "Gros-Islet"],
        [1777, "Dennery"],
        [1778, "Choiseul"],
        [1779, "Castries"],
        [1780, "Anse-la-Raye"],
        [1781, "Canaries"],
        [1782, "Vaduz"],
        [1783, "Triesenberg"],
        [1784, "Triesen"],
        [1785, "Schellenberg"],
        [1786, "Schaan"],
        [1787, "Ruggell"],
        [1788, "Planken"],
        [1789, "Mauren"],
        [1790, "Gamprin"],
        [1791, "Eschen"],
        [1792, "Balzers"],
        [1793, "Western"],
        [1794, "Uva"],
        [1795, "Southern"],
        [1796, "Sabaragamuwa"],
        [1797, "North Western"],
        [1798, "North Central"],
        [1799, "Central"],
        [1800, "Northern Province"],
        [1801, "Eastern Province"],
        [1802, "Sinoe"],
        [1803, "Nimba"],
        [1804, "Montserrado"],
        [1805, "Maryland"],
        [1806, "Lofa"],
        [1807, "Grand Gedeh"],
        [1808, "Grand Cape Mount"],
        [1809, "Grand Bassa"],
        [1810, "Bong"],
        [1811, "Bomi"],
        [1812, "Grand Kru"],
        [1813, "Margibi"],
        [1814, "River Cess"],
        [1815, "Gbarpolu"],
        [1816, "River Gee"],
        [1817, "Thaba-Tseka"],
        [1818, "Quthing"],
        [1819, "Qacha's Nek"],
        [1820, "Mokhotlong"],
        [1821, "Mohale's Hoek"],
        [1822, "Maseru"],
        [1823, "Mafeteng"],
        [1824, "Leribe"],
        [1825, "Butha-Buthe"],
        [1826, "Berea"],
        [1827, "Alytus"],
        [1828, "Kaunas"],
        [1829, "Klaipeda County"],
        [1830, "Marijampole County"],
        [1831, "Panevežys"],
        [1832, "Siauliai"],
        [1833, "Taurage County"],
        [1834, "Telsiai"],
        [1835, "Utena"],
        [1836, "Vilnius"],
        [1837, "Wiltz"],
        [1838, "Vianden"],
        [1839, "Remich"],
        [1840, "Redange"],
        [1841, "Mersch"],
        [1842, "Luxembourg"],
        [1843, "Grevenmacher"],
        [1844, "Esch-sur-Alzette"],
        [1845, "Echternach"],
        [1846, "Diekirch"],
        [1847, "Clervaux"],
        [1848, "Capellen"],
        [1849, "Ventspils Rajons"],
        [1850, "Ventspils"],
        [1851, "Valmiera"],
        [1852, "Valka"],
        [1853, "Tukuma novads"],
        [1854, "Talsi Municipality"],
        [1855, "Saldus Rajons"],
        [1856, "Riga"],
        [1857, "Rezeknes Novads"],
        [1858, "Rezekne"],
        [1859, "Preilu novads"],
        [1860, "Ogre"],
        [1861, "Madona Municipality"],
        [1862, "Ludzas novads"],
        [1863, "Limbažu novads"],
        [1864, "Liepaja"],
        [1865, "Kuldigas novads"],
        [1866, "Kraslavas novads"],
        [1867, "Jurmala"],
        [1868, "Jelgavas novads"],
        [1869, "Jelgava"],
        [1870, "Jekabpils Municipality"],
        [1871, "Gulbenes novads"],
        [1872, "Dobeles novads"],
        [1873, "Daugavpils"],
        [1874, "Cesu Rajons"],
        [1875, "Bauskas Rajons"],
        [1876, "Balvu Novads"],
        [1877, "Aluksnes Novads"],
        [1878, "Aizkraukles novads"],
        [1879, "Marupe"],
        [1880, "Olaine"],
        [1881, "Kekava"],
        [1882, "Salaspils"],
        [1883, "Adaži"],
        [1884, "Livani"],
        [1885, "Varaklani"],
        [1886, "Ropažu"],
        [1887, "Sigulda"],
        [1888, "Saulkrastu"],
        [1889, "Smiltene"],
        [1890, "South Kurzeme Municipality"],
        [1891, "Augšdaugava Municipality"],
        [1892, "Darnah"],
        [1893, "Banghazi"],
        [1894, "Al Marj"],
        [1895, "Al Kufrah"],
        [1896, "Al Jabal al Akh?ar"],
        [1897, "Tripoli"],
        [1898, "Surt"],
        [1899, "Sabha"],
        [1900, "Nalut"],
        [1901, "Murzuq"],
        [1902, "Misratah"],
        [1903, "Ghat"],
        [1904, "Az Zawiyah"],
        [1905, "Ash Shati'"],
        [1906, "Al Jufrah"],
        [1907, "An Nuqat al Khams"],
        [1908, "Al Butnan"],
        [1909, "Jabal al Gharbi"],
        [1910, "Al Jafarah"],
        [1911, "Al Marqab"],
        [1912, "Al Wa?at"],
        [1913, "Wadi al ?ayat"],
        [1914, "Tanger-Tetouan-Al Hoceima"],
        [1915, "Oriental"],
        [1916, "Fès-Meknès"],
        [1917, "Rabat-Salé-Kénitra"],
        [1918, "Béni Mellal-Khénifra"],
        [1919, "Casablanca-Settat"],
        [1920, "Marrakesh-Safi"],
        [1921, "Drâa-Tafilalet"],
        [1922, "Souss-Massa"],
        [1923, "Guelmim-Oued Noun"],
        [1924, "Laâyoune-Sakia El Hamra"],
        [1925, "Dakhla-Oued Ed-Dahab"],
        [1926, "Municipality of Monaco"],
        [1927, "Raionul Edinet"],
        [1928, "Ungheni"],
        [1929, "Telenesti"],
        [1930, "Taraclia"],
        [1931, "Stefan-Voda"],
        [1932, "Stra?eni"],
        [1933, "Raionul Soroca"],
        [1934, "Rîscani"],
        [1935, "Rezina"],
        [1936, "Orhei"],
        [1937, "Raionul Ocnita"],
        [1938, "Anenii Noi"],
        [1939, "Nisporeni"],
        [1940, "Leova"],
        [1941, "Sîngerei"],
        [1942, "Criuleni"],
        [1943, "Ialoveni"],
        [1944, "Chi?inau Municipality"],
        [1945, "Causeni"],
        [1946, "Cantemir"],
        [1947, "Calarasi"],
        [1948, "Cahul"],
        [1949, "Glodeni"],
        [1950, "Floresti"],
        [1951, "Fale?ti"],
        [1952, "Dubasari"],
        [1953, "Drochia"],
        [1954, "Donduseni"],
        [1955, "Cimislia"],
        [1956, "Briceni"],
        [1957, "Basarabeasca"],
        [1958, "Hîncesti"],
        [1959, "Soldanesti"],
        [1960, "Transnistria"],
        [1961, "Gagauzia"],
        [1962, "Bender Municipality"],
        [1963, "Balti"],
        [1964, "Opština Rožaje"],
        [1965, "Opština Žabljak"],
        [1966, "Ulcinj"],
        [1967, "Tivat"],
        [1968, "Podgorica"],
        [1969, "Opština Šavnik"],
        [1970, "Opština Plužine"],
        [1971, "Pljevlja"],
        [1972, "Opština Plav"],
        [1973, "Opština Nikšic"],
        [1974, "Mojkovac"],
        [1975, "Kotor"],
        [1976, "Opština Kolašin"],
        [1977, "Berane"],
        [1978, "Herceg Novi"],
        [1979, "Danilovgrad"],
        [1980, "Cetinje"],
        [1981, "Budva"],
        [1982, "Bijelo Polje"],
        [1983, "Bar"],
        [1984, "Andrijevica"],
        [1985, "Gusinje"],
        [1986, "Petnjica"],
        [1987, "Tuzi"],
        [1988, "Diana"],
        [1989, "Sava"],
        [1990, "Sofia"],
        [1991, "Analanjirofo"],
        [1992, "Boeny"],
        [1993, "Betsiboka"],
        [1994, "Alaotra Mangoro"],
        [1995, "Melaky"],
        [1996, "Bongolava"],
        [1997, "Vakinankaratra"],
        [1998, "Itasy"],
        [1999, "Analamanga"],
        [2000, "Atsinanana"],
        [2001, "Menabe"],
        [2002, "Amoron'i Mania"],
        [2003, "Upper Matsiatra"],
        [2004, "Vatovavy Fitovinany"],
        [2005, "Ihorombe"],
        [2006, "Atsimo-Atsinanana"],
        [2007, "Anosy"],
        [2008, "Androy"],
        [2009, "Atsimo-Andrefana"],
        [2010, "Ailinginae Atoll"],
        [2011, "Ailinglaplap Atoll"],
        [2012, "Ailuk Atoll"],
        [2013, "Arno Atoll"],
        [2014, "Aur Atoll"],
        [2015, "Bikar Atoll"],
        [2016, "Bikini Atoll"],
        [2017, "Ebon Atoll"],
        [2018, "Enewetak Atoll"],
        [2019, "Erikub Atoll"],
        [2020, "Jaluit Atoll"],
        [2021, "Kwajalein Atoll"],
        [2022, "Lae Atoll"],
        [2023, "Likiep Atoll"],
        [2024, "Majuro Atoll"],
        [2025, "Maloelap Atoll"],
        [2026, "Mili Atoll"],
        [2027, "Namdrik Atoll"],
        [2028, "Namu Atoll"],
        [2029, "Rongelap Atoll"],
        [2030, "Rongrik Atoll"],
        [2031, "Taka Atoll"],
        [2032, "Bokak Atoll"],
        [2033, "Ujae Atoll"],
        [2034, "Ujelang"],
        [2035, "Utrik Atoll"],
        [2036, "Wotho Atoll"],
        [2037, "Wotje Atoll"],
        [2038, "Jabat Island"],
        [2039, "Jemo Island"],
        [2040, "Kili Island"],
        [2041, "Lib Island"],
        [2042, "Mejit Island"],
        [2043, "Valandovo"],
        [2044, "Resen"],
        [2045, "Kratovo"],
        [2046, "Pehchevo"],
        [2047, "Novo Selo"],
        [2048, "Bosilovo"],
        [2049, "Vasilevo"],
        [2050, "Dojran"],
        [2051, "Bogdanci"],
        [2052, "Konche"],
        [2053, "Makedonska Kamenica"],
        [2054, "Zrnovci"],
        [2055, "Karbinci"],
        [2056, "Demir Kapija"],
        [2057, "Rosoman"],
        [2058, "Gradsko"],
        [2059, "Lozovo"],
        [2060, "Cešinovo-Obleševo"],
        [2061, "Novaci"],
        [2062, "Berovo"],
        [2063, "Bitola"],
        [2064, "Mogila"],
        [2065, "Arachinovo"],
        [2066, "Bogovinje"],
        [2067, "Brvenica"],
        [2068, "Chashka"],
        [2069, "Centar Zhupa"],
        [2070, "Chucher Sandevo"],
        [2071, "Debar"],
        [2072, "Delchevo"],
        [2073, "Demir Hisar"],
        [2074, "Dolneni"],
        [2075, "Gevgelija"],
        [2076, "Gostivar"],
        [2077, "Ilinden"],
        [2078, "Jegunovce"],
        [2079, "Kavadarci"],
        [2080, "Kichevo"],
        [2081, "Kochani"],
        [2082, "Kriva Palanka"],
        [2083, "Krivogashtani"],
        [2084, "Krushevo"],
        [2085, "Kumanovo"],
        [2086, "Lipkovo"],
        [2087, "Makedonski Brod"],
        [2088, "Negotino"],
        [2089, "Ohrid"],
        [2090, "Petrovec"],
        [2091, "Plasnica"],
        [2092, "Prilep"],
        [2093, "Probishtip"],
        [2094, "Radovish"],
        [2095, "Rankovce"],
        [2096, "Mavrovo and Rostuša"],
        [2097, "Sopište"],
        [2098, "Staro Nagorichane"],
        [2099, "Shtip"],
        [2100, "Struga"],
        [2101, "Strumica"],
        [2102, "Studenichani"],
        [2103, "Sveti Nikole"],
        [2104, "Tearce"],
        [2105, "Tetovo"],
        [2106, "Veles"],
        [2107, "Vevchani"],
        [2108, "Vinica"],
        [2109, "Vrapchishte"],
        [2110, "Zelenikovo"],
        [2111, "Zhelino"],
        [2112, "Debarca"],
        [2113, "Grad Skopje"],
        [2114, "Tombouctou"],
        [2115, "Sikasso"],
        [2116, "Ségou"],
        [2117, "Mopti"],
        [2118, "Koulikoro"],
        [2119, "Kayes"],
        [2120, "Gao"],
        [2121, "Bamako"],
        [2122, "Kidal"],
        [2123, "Taoudénit"],
        [2124, "Ménaka"],
        [2125, "Tanintharyi"],
        [2126, "Shan"],
        [2127, "Sagain"],
        [2128, "Rangoon"],
        [2129, "Rakhine"],
        [2130, "Bago"],
        [2131, "Mon"],
        [2132, "Mandalay"],
        [2133, "Magway"],
        [2134, "Kayah"],
        [2135, "Kayin"],
        [2136, "Kachin"],
        [2137, "Ayeyarwady"],
        [2138, "Chin"],
        [2139, "Nay Pyi Taw"],
        [2140, "Uvs"],
        [2141, "Hovd"],
        [2142, "Govi-Altay"],
        [2143, "Dzabkhan"],
        [2144, "Bayan-Ölgiy"],
        [2145, "Bayanhongor"],
        [2146, "Ulaanbaatar"],
        [2147, "Central Aimak"],
        [2148, "Sühbaatar"],
        [2149, "Selenge"],
        [2150, "Övörhangay"],
        [2151, "Ömnögovi"],
        [2152, "Hövsgöl"],
        [2153, "Hentiy"],
        [2154, "Middle Govi"],
        [2155, "East Gobi Aymag"],
        [2156, "East Aimak"],
        [2157, "Bulgan"],
        [2158, "Arhangay"],
        [2159, "Darhan Uul"],
        [2160, "Govi-Sumber"],
        [2161, "Orhon"],
        [2162, "Nossa Senhora de Fátima"],
        [2163, "Santo António"],
        [2164, "São Lázaro"],
        [2165, "Sé"],
        [2166, "São Lourenço"],
        [2167, "Nossa Senhora do Carmo"],
        [2168, "Cotai"],
        [2169, "São Francisco Xavier"],
        [2170, "Rota"],
        [2171, "Saipan"],
        [2172, "Tinian"],
        [2173, "Northern Islands"],
        [2174, "Martinique"],
        [2175, "Trarza"],
        [2176, "Tiris Zemmour"],
        [2177, "Tagant"],
        [2178, "Inchiri"],
        [2179, "Hodh El Gharbi"],
        [2180, "Hodh Ech Chargi"],
        [2181, "Guidimaka"],
        [2182, "Gorgol"],
        [2183, "Dakhlet Nouadhibou"],
        [2184, "Brakna"],
        [2185, "Assaba"],
        [2186, "Adrar"],
        [2187, "Nouakchott Ouest"],
        [2188, "Nouakchott Nord"],
        [2189, "Nouakchott Sud"],
        [2190, "Saint Peter"],
        [2191, "Saint Georges"],
        [2192, "Saint Anthony"],
        [2193, "Attard"],
        [2194, "Balzan"],
        [2195, "Il-Birgu"],
        [2196, "Birkirkara"],
        [2197, "Birzebbuga"],
        [2198, "Bormla"],
        [2199, "Dingli"],
        [2200, "Il-Fgura"],
        [2201, "Floriana"],
        [2202, "Il-Fontana"],
        [2203, "Ghajnsielem"],
        [2204, "L-Gharb"],
        [2205, "Hal Gharghur"],
        [2206, "L-Ghasri"],
        [2207, "Hal Ghaxaq"],
        [2208, "Il-Gudja"],
        [2209, "Il-Gzira"],
        [2210, "Il-Hamrun"],
        [2211, "L-Iklin"],
        [2212, "L-Imdina"],
        [2213, "L-Imgarr"],
        [2214, "L-Imqabba"],
        [2215, "L-Imsida"],
        [2216, "Mtarfa"],
        [2217, "Senglea"],
        [2218, "Il-Kalkara"],
        [2219, "Ta’ Kercem"],
        [2220, "Kirkop"],
        [2221, "Lija"],
        [2222, "Luqa"],
        [2223, "Il-Marsa"],
        [2224, "Marsaskala"],
        [2225, "Marsaxlokk"],
        [2226, "Il-Mellieha"],
        [2227, "Il-Mosta"],
        [2228, "Il-Munxar"],
        [2229, "In-Nadur"],
        [2230, "In-Naxxar"],
        [2231, "Paola"],
        [2232, "Pembroke"],
        [2233, "Tal-Pietà"],
        [2234, "Il-Qala"],
        [2235, "Qormi"],
        [2236, "Il-Qrendi"],
        [2237, "Ir-Rabat"],
        [2238, "Victoria"],
        [2239, "Safi"],
        [2240, "Saint John"],
        [2241, "Saint Julian"],
        [2242, "Saint Lawrence"],
        [2243, "Saint Lucia"],
        [2244, "Saint Paul’s Bay"],
        [2245, "Saint Venera"],
        [2246, "Sannat"],
        [2247, "Is-Siggiewi"],
        [2248, "Tas-Sliema"],
        [2249, "Is-Swieqi"],
        [2250, "Tarxien"],
        [2251, "Ta’ Xbiex"],
        [2252, "Ix-Xaghra"],
        [2253, "Ix-Xewkija"],
        [2254, "Ix-Xghajra"],
        [2255, "Haz-Zabbar"],
        [2256, "Haz-Zebbug"],
        [2257, "Iz-Zebbug"],
        [2258, "Iz-Zejtun"],
        [2259, "Iz-Zurrieq"],
        [2260, "Valletta"],
        [2261, "Agalega Islands"],
        [2262, "Savanne"],
        [2263, "Rivière du Rempart"],
        [2264, "Port Louis"],
        [2265, "Plaines Wilhems"],
        [2266, "Pamplemousses"],
        [2267, "Moka"],
        [2268, "Grand Port"],
        [2269, "Flacq"],
        [2270, "Black River"],
        [2271, "Cargados Carajos"],
        [2272, "Rodrigues"],
        [2273, "Vaavu Atholhu"],
        [2274, "Thaa Atholhu"],
        [2275, "Shaviyani Atholhu"],
        [2276, "Seenu"],
        [2277, "Raa Atoll"],
        [2278, "Noonu Atoll"],
        [2279, "Gnyaviyani Atoll"],
        [2280, "Meemu Atholhu"],
        [2281, "Lhaviyani Atholhu"],
        [2282, "Laamu"],
        [2283, "Kaafu Atoll"],
        [2284, "Haa Dhaalu Atholhu"],
        [2285, "Haa Alifu Atholhu"],
        [2286, "Gaafu Dhaalu Atoll"],
        [2287, "Gaafu Alif Atoll"],
        [2288, "Faafu Atholhu"],
        [2289, "Dhaalu Atholhu"],
        [2290, "Baa Atholhu"],
        [2291, "Northern Ari Atoll"],
        [2292, "Male"],
        [2293, "Southern Ari Atoll"],
        [2294, "Southern Region"],
        [2295, "Northern Region"],
        [2296, "Central Region"],
        [2297, "Yucatán"],
        [2298, "Veracruz"],
        [2299, "Tlaxcala"],
        [2300, "Tamaulipas"],
        [2301, "Tabasco"],
        [2302, "Quintana Roo"],
        [2303, "Querétaro"],
        [2304, "Puebla"],
        [2305, "Oaxaca"],
        [2306, "Nuevo León"],
        [2307, "Morelos"],
        [2308, "México"],
        [2309, "Hidalgo"],
        [2310, "Guerrero"],
        [2311, "Mexico City"],
        [2312, "Chiapas"],
        [2313, "Campeche"],
        [2314, "Zacatecas"],
        [2315, "Sonora"],
        [2316, "Sinaloa"],
        [2317, "San Luis Potosí"],
        [2318, "Nayarit"],
        [2319, "Michoacán"],
        [2320, "Jalisco"],
        [2321, "Guanajuato"],
        [2322, "Durango"],
        [2323, "Colima"],
        [2324, "Coahuila"],
        [2325, "Chihuahua"],
        [2326, "Baja California Sur"],
        [2327, "Baja California"],
        [2328, "Aguascalientes"],
        [2329, "Melaka"],
        [2330, "Terengganu"],
        [2331, "Selangor"],
        [2332, "Sarawak"],
        [2333, "Sabah"],
        [2334, "Perlis"],
        [2335, "Perak"],
        [2336, "Pahang"],
        [2337, "Negeri Sembilan"],
        [2338, "Kelantan"],
        [2339, "Kuala Lumpur"],
        [2340, "Penang"],
        [2341, "Kedah"],
        [2342, "Johor"],
        [2343, "Labuan"],
        [2344, "Putrajaya"],
        [2345, "Zambézia"],
        [2346, "Tete"],
        [2347, "Sofala"],
        [2348, "Niassa"],
        [2349, "Nampula"],
        [2350, "Maputo Province"],
        [2351, "Manica"],
        [2352, "Inhambane"],
        [2353, "Gaza"],
        [2354, "Cabo Delgado"],
        [2355, "Maputo City"],
        [2356, "Zambezi"],
        [2357, "Khomas"],
        [2358, "Erongo"],
        [2359, "Hardap"],
        [2360, "Karas"],
        [2361, "Kunene"],
        [2362, "Ohangwena"],
        [2363, "Omaheke"],
        [2364, "Omusati"],
        [2365, "Oshana"],
        [2366, "Oshikoto"],
        [2367, "Otjozondjupa"],
        [2368, "Kavango East"],
        [2369, "Kavango West"],
        [2370, "South Province"],
        [2371, "North Province"],
        [2372, "Loyalty Islands"],
        [2373, "Zinder"],
        [2374, "Tahoua"],
        [2375, "Maradi"],
        [2376, "Dosso"],
        [2377, "Diffa"],
        [2378, "Agadez"],
        [2379, "Tillabéri"],
        [2380, "Niamey"],
        [2381, "Sokoto"],
        [2382, "Rivers"],
        [2383, "Plateau"],
        [2384, "Oyo"],
        [2385, "Ondo"],
        [2386, "Ogun"],
        [2387, "Niger"],
        [2388, "Lagos"],
        [2389, "Kwara"],
        [2390, "Katsina"],
        [2391, "Kano"],
        [2392, "Kaduna"],
        [2393, "Imo"],
        [2394, "Cross River"],
        [2395, "Borno"],
        [2396, "Benue"],
        [2397, "Bauchi"],
        [2398, "Anambra"],
        [2399, "Akwa Ibom"],
        [2400, "FCT"],
        [2401, "Abia"],
        [2402, "Delta"],
        [2403, "Adamawa"],
        [2404, "Edo"],
        [2405, "Enugu"],
        [2406, "Jigawa"],
        [2407, "Bayelsa"],
        [2408, "Ebonyi"],
        [2409, "Ekiti"],
        [2410, "Gombe"],
        [2411, "Nassarawa"],
        [2412, "Zamfara"],
        [2413, "Kebbi"],
        [2414, "Kogi"],
        [2415, "Osun"],
        [2416, "Taraba"],
        [2417, "Yobe"],
        [2418, "Rivas"],
        [2419, "Río San Juan"],
        [2420, "Nueva Segovia"],
        [2421, "Matagalpa"],
        [2422, "Masaya"],
        [2423, "Managua"],
        [2424, "Madriz"],
        [2425, "León"],
        [2426, "Jinotega"],
        [2427, "Granada"],
        [2428, "Estelí"],
        [2429, "Chontales"],
        [2430, "Chinandega"],
        [2431, "Carazo"],
        [2432, "Boaco"],
        [2433, "North Caribbean Coast"],
        [2434, "South Caribbean Coast"],
        [2435, "South Holland"],
        [2436, "Zeeland"],
        [2437, "Utrecht"],
        [2438, "Overijssel"],
        [2439, "North Holland"],
        [2440, "North Brabant"],
        [2441, "Limburg"],
        [2442, "Groningen"],
        [2443, "Gelderland"],
        [2444, "Friesland"],
        [2445, "Drenthe"],
        [2446, "Flevoland"],
        [2447, "Rogaland"],
        [2448, "Oslo"],
        [2449, "Nordland"],
        [2450, "Møre og Romsdal"],
        [2451, "Trøndelag"],
        [2452, "Viken"],
        [2453, "Agder"],
        [2454, "Troms og Finnmark"],
        [2455, "Innlandet"],
        [2456, "Vestfold og Telemark"],
        [2457, "Vestland"],
        [2458, "Province 1"],
        [2459, "Province 2"],
        [2460, "Bagmati Province"],
        [2461, "Province 4"],
        [2462, "Lumbini Province"],
        [2463, "Karnali Pradesh"],
        [2464, "Sudurpashchim Pradesh"],
        [2465, "Yaren"],
        [2466, "Uaboe"],
        [2467, "Nibok"],
        [2468, "Meneng"],
        [2469, "Ijuw"],
        [2470, "Ewa"],
        [2471, "Denigomodu"],
        [2472, "Buada"],
        [2473, "Boe"],
        [2474, "Baiti"],
        [2475, "Anibare"],
        [2476, "Anetan"],
        [2477, "Anabar"],
        [2478, "Aiwo"],
        [2479, "Wellington"],
        [2480, "Manawatu-Wanganui"],
        [2481, "Waikato"],
        [2482, "Tasman"],
        [2483, "Taranaki"],
        [2484, "Southland"],
        [2485, "Bay of Plenty"],
        [2486, "Northland"],
        [2487, "Marlborough"],
        [2488, "Hawke's Bay"],
        [2489, "Gisborne"],
        [2490, "Canterbury"],
        [2491, "Auckland"],
        [2492, "Chatham Islands"],
        [2493, "Nelson"],
        [2494, "Otago"],
        [2495, "West Coast"],
        [2496, "Ad Dakhiliyah"],
        [2497, "Al Batinah South"],
        [2498, "Al Wustá"],
        [2499, "Southeastern Governorate"],
        [2500, "Az¸ Z¸ahirah"],
        [2501, "Muscat"],
        [2502, "Musandam"],
        [2503, "Dhofar"],
        [2504, "Al Buraimi"],
        [2505, "Northeastern Governorate"],
        [2506, "Al Batinah North"],
        [2507, "Veraguas"],
        [2508, "Guna Yala"],
        [2509, "Panamá"],
        [2510, "Los Santos"],
        [2511, "Herrera"],
        [2512, "Darién"],
        [2513, "Colón"],
        [2514, "Coclé"],
        [2515, "Chiriquí"],
        [2516, "Bocas del Toro"],
        [2517, "Emberá"],
        [2518, "Ngöbe-Buglé"],
        [2519, "Panamá Oeste"],
        [2520, "Ucayali"],
        [2521, "Tumbes"],
        [2522, "San Martín"],
        [2523, "Piura"],
        [2524, "Loreto"],
        [2525, "Lambayeque"],
        [2526, "La Libertad"],
        [2527, "Huanuco"],
        [2528, "Cajamarca"],
        [2529, "Ancash"],
        [2530, "Amazonas"],
        [2531, "Tacna"],
        [2532, "Puno"],
        [2533, "Pasco"],
        [2534, "Moquegua"],
        [2535, "Madre de Dios"],
        [2536, "Lima"],
        [2537, "Lima region"],
        [2538, "Junin"],
        [2539, "Ica"],
        [2540, "Huancavelica"],
        [2541, "Cusco"],
        [2542, "Callao"],
        [2543, "Ayacucho"],
        [2544, "Arequipa"],
        [2545, "Apurímac"],
        [2546, "Îles Marquises"],
        [2547, "Îles Tuamotu-Gambier"],
        [2548, "Leeward Islands"],
        [2549, "Îles du Vent"],
        [2550, "Îles Australes"],
        [2551, "West New Britain"],
        [2552, "Western Province"],
        [2553, "Western Highlands"],
        [2554, "Southern Highlands"],
        [2555, "Sandaun"],
        [2556, "Bougainville"],
        [2557, "Northern Province"],
        [2558, "New Ireland"],
        [2559, "National Capital"],
        [2560, "Morobe"],
        [2561, "Manus"],
        [2562, "Madang"],
        [2563, "Gulf"],
        [2564, "Enga"],
        [2565, "East Sepik"],
        [2566, "East New Britain"],
        [2567, "Eastern Highlands"],
        [2568, "Chimbu"],
        [2569, "Milne Bay"],
        [2570, "Central Province"],
        [2571, "Hela"],
        [2572, "Jiwaka"],
        [2573, "Autonomous Region in Muslim Mindanao"],
        [2574, "Northern Mindanao"],
        [2575, "Mimaropa"],
        [2576, "Cagayan Valley"],
        [2577, "Soccsksargen"],
        [2578, "Caraga"],
        [2579, "Cordillera"],
        [2580, "Ilocos"],
        [2581, "Calabarzon"],
        [2582, "Western Visayas"],
        [2583, "Central Luzon"],
        [2584, "Central Visayas"],
        [2585, "Eastern Visayas"],
        [2586, "Zamboanga Peninsula"],
        [2587, "Davao"],
        [2588, "Bicol"],
        [2589, "Metro Manila"],
        [2590, "Islamabad"],
        [2591, "Sindh"],
        [2592, "Punjab"],
        [2593, "Khyber Pakhtunkhwa"],
        [2594, "Gilgit-Baltistan"],
        [2595, "Balochistan"],
        [2596, "Azad Kashmir"],
        [2597, "Lublin"],
        [2598, "Lesser Poland"],
        [2599, "Mazovia"],
        [2600, "Subcarpathia"],
        [2601, "Podlasie"],
        [2602, "Swietokrzyskie"],
        [2603, "Warmia-Masuria"],
        [2604, "Lower Silesia"],
        [2605, "Lódz Voivodeship"],
        [2606, "Lubusz"],
        [2607, "Opole Voivodeship"],
        [2608, "Pomerania"],
        [2609, "Silesia"],
        [2610, "Greater Poland"],
        [2611, "West Pomerania"],
        [2612, "Kujawsko-Pomorskie"],
        [2613, "Saint-Pierre"],
        [2614, "Miquelon-Langlade"],
        [2615, "Adjuntas"],
        [2616, "Aguada"],
        [2617, "Aguadilla"],
        [2618, "Aguas Buenas"],
        [2619, "Aibonito"],
        [2620, "Añasco"],
        [2621, "Arecibo"],
        [2622, "Arroyo"],
        [2623, "Barceloneta"],
        [2624, "Barranquitas"],
        [2625, "Bayamón"],
        [2626, "Cabo Rojo"],
        [2627, "Caguas"],
        [2628, "Camuy"],
        [2629, "Canóvanas"],
        [2630, "Carolina"],
        [2631, "Cataño"],
        [2632, "Cayey"],
        [2633, "Ceiba"],
        [2634, "Ciales"],
        [2635, "Cidra"],
        [2636, "Coamo"],
        [2637, "Comerío"],
        [2638, "Corozal"],
        [2639, "Culebra"],
        [2640, "Dorado"],
        [2641, "Fajardo"],
        [2642, "Florida"],
        [2643, "Guánica"],
        [2644, "Guayama"],
        [2645, "Guayanilla"],
        [2646, "Guaynabo"],
        [2647, "Gurabo"],
        [2648, "Hatillo"],
        [2649, "Hormigueros"],
        [2650, "Humacao"],
        [2651, "Isabela"],
        [2652, "Jayuya"],
        [2653, "Juana Díaz"],
        [2654, "Juncos"],
        [2655, "Lajas"],
        [2656, "Lares"],
        [2657, "Las Marías"],
        [2658, "Las Piedras"],
        [2659, "Loíza"],
        [2660, "Luquillo"],
        [2661, "Manatí"],
        [2662, "Maricao"],
        [2663, "Maunabo"],
        [2664, "Mayagüez"],
        [2665, "Moca"],
        [2666, "Morovis"],
        [2667, "Naguabo"],
        [2668, "Naranjito"],
        [2669, "Orocovis"],
        [2670, "Patillas"],
        [2671, "Peñuelas"],
        [2672, "Ponce"],
        [2673, "Rincón"],
        [2674, "Quebradillas"],
        [2675, "Río Grande"],
        [2676, "Sabana Grande"],
        [2677, "Salinas"],
        [2678, "San Germán"],
        [2679, "San Juan"],
        [2680, "San Lorenzo"],
        [2681, "San Sebastián"],
        [2682, "Santa Isabel"],
        [2683, "Toa Alta"],
        [2684, "Toa Baja"],
        [2685, "Trujillo Alto"],
        [2686, "Utuado"],
        [2687, "Vega Alta"],
        [2688, "Vega Baja"],
        [2689, "Villalba"],
        [2690, "Yabucoa"],
        [2691, "Yauco"],
        [2692, "Vieques"],
        [2693, "Gaza Strip"],
        [2694, "West Bank"],
        [2695, "District of Setúbal"],
        [2696, "Santarém"],
        [2697, "Portalegre"],
        [2698, "Lisbon"],
        [2699, "Leiria"],
        [2700, "Faro"],
        [2701, "Évora"],
        [2702, "Castelo Branco"],
        [2703, "Beja"],
        [2704, "Madeira"],
        [2705, "Viseu"],
        [2706, "Vila Real"],
        [2707, "Viana do Castelo"],
        [2708, "Porto"],
        [2709, "Guarda"],
        [2710, "Coimbra"],
        [2711, "Bragança"],
        [2712, "Braga"],
        [2713, "Aveiro"],
        [2714, "Azores"],
        [2715, "Ngatpang"],
        [2716, "Sonsorol"],
        [2717, "Kayangel"],
        [2718, "Hatohobei"],
        [2719, "Aimeliik"],
        [2720, "Airai"],
        [2721, "Angaur"],
        [2722, "Koror"],
        [2723, "Melekeok"],
        [2724, "Ngaraard"],
        [2725, "Ngchesar"],
        [2726, "Ngarchelong"],
        [2727, "Ngardmau"],
        [2728, "Ngaremlengui"],
        [2729, "Ngiwal"],
        [2730, "Peleliu"],
        [2731, "San Pedro"],
        [2732, "Presidente Hayes"],
        [2733, "Paraguarí"],
        [2734, "Ñeembucú"],
        [2735, "Misiones"],
        [2736, "Itapúa"],
        [2737, "Guairá"],
        [2738, "Cordillera"],
        [2739, "Concepción"],
        [2740, "Central"],
        [2741, "Canindeyú"],
        [2742, "Caazapá"],
        [2743, "Caaguazú"],
        [2744, "Amambay"],
        [2745, "Alto Paraná"],
        [2746, "Alto Paraguay"],
        [2747, "Asunción"],
        [2748, "Boquerón"],
        [2749, "Madinat ash Shamal"],
        [2750, "Al Khor"],
        [2751, "Baladiyat Umm Salal"],
        [2752, "Baladiyat ar Rayyan"],
        [2753, "Baladiyat ad Daw?ah"],
        [2754, "Al Wakrah"],
        [2755, "Baladiyat az¸ Z¸a‘ayin"],
        [2756, "Al-Shahaniya"],
        [2757, "Réunion"],
        [2758, "Vrancea"],
        [2759, "Vâlcea"],
        [2760, "Vaslui"],
        [2761, "Tulcea"],
        [2762, "Timis"],
        [2763, "Teleorman"],
        [2764, "Suceava"],
        [2765, "Sibiu"],
        [2766, "Satu Mare"],
        [2767, "Salaj"],
        [2768, "Prahova"],
        [2769, "Olt"],
        [2770, "Neamt"],
        [2771, "Mures"],
        [2772, "Mehedinti"],
        [2773, "Maramures"],
        [2774, "Iasi"],
        [2775, "Ialomita"],
        [2776, "Hunedoara"],
        [2777, "Harghita"],
        [2778, "Gorj"],
        [2779, "Giurgiu"],
        [2780, "Galati"],
        [2781, "Dolj"],
        [2782, "Dâmbovita"],
        [2783, "Covasna"],
        [2784, "Constan?a"],
        [2785, "Cluj"],
        [2786, "Caras-Severin"],
        [2787, "Calarasi"],
        [2788, "Buzau"],
        [2789, "Bucuresti"],
        [2790, "Brasov"],
        [2791, "Braila"],
        [2792, "Botosani"],
        [2793, "Bistrita-Nasaud"],
        [2794, "Bihor"],
        [2795, "Bacau"],
        [2796, "Arges"],
        [2797, "Arad"],
        [2798, "Alba"],
        [2799, "Ilfov"],
        [2800, "Vojvodina"],
        [2801, "Central Serbia"],
        [2802, "Yaroslavl Oblast"],
        [2803, "Voronezj"],
        [2804, "Vologda"],
        [2805, "Volgograd Oblast"],
        [2806, "Ulyanovsk"],
        [2807, "Udmurtiya Republic"],
        [2808, "Tver’ Oblast"],
        [2809, "Tula"],
        [2810, "Tatarstan Republic"],
        [2811, "Tambov"],
        [2812, "Stavropol’ Kray"],
        [2813, "Smolenskaya Oblast’"],
        [2814, "Saratovskaya Oblast"],
        [2815, "Samara Oblast"],
        [2816, "Ryazan Oblast"],
        [2817, "Rostov"],
        [2818, "Pskov Oblast"],
        [2819, "Perm"],
        [2820, "Penza"],
        [2821, "Orel Oblast"],
        [2822, "Orenburg Oblast"],
        [2823, "Novgorod Oblast"],
        [2824, "North Ossetia"],
        [2825, "Nenets"],
        [2826, "Murmansk"],
        [2827, "Moscow"],
        [2828, "Moscow Oblast"],
        [2829, "Mordoviya Republic"],
        [2830, "Mariy-El Republic"],
        [2831, "Lipetsk Oblast"],
        [2832, "Leningradskaya Oblast'"],
        [2833, "St.-Petersburg"],
        [2834, "Kursk"],
        [2835, "Krasnodarskiy"],
        [2836, "Kostroma Oblast"],
        [2837, "Komi"],
        [2838, "Kirov"],
        [2839, "Karelia"],
        [2840, "Karachayevo-Cherkesiya Republic"],
        [2841, "Kaluga"],
        [2842, "Kalmykiya Republic"],
        [2843, "Kaliningrad"],
        [2844, "Kabardino-Balkariya Republic"],
        [2845, "Ivanovo"],
        [2846, "Ingushetiya Republic"],
        [2847, "Nizhny Novgorod Oblast"],
        [2848, "Dagestan"],
        [2849, "Chuvashia"],
        [2850, "Chechnya"],
        [2851, "Bryansk Oblast"],
        [2852, "Belgorod Oblast"],
        [2853, "Bashkortostan Republic"],
        [2854, "Astrakhan"],
        [2855, "Arkhangelskaya"],
        [2856, "Adygeya Republic"],
        [2857, "Vladimir"],
        [2858, "Yamalo-Nenets"],
        [2859, "Tyumen’ Oblast"],
        [2860, "Republic of Tyva"],
        [2861, "Tomsk Oblast"],
        [2862, "Sverdlovsk"],
        [2863, "Omsk"],
        [2864, "Novosibirsk Oblast"],
        [2865, "Kurgan Oblast"],
        [2866, "Krasnoyarskiy"],
        [2867, "Khanty-Mansia"],
        [2868, "Khakasiya Republic"],
        [2869, "Kemerovo Oblast"],
        [2870, "Altai"],
        [2871, "Chelyabinsk"],
        [2872, "Altai Krai"],
        [2873, "Sakha"],
        [2874, "Primorskiy (Maritime) Kray"],
        [2875, "Khabarovsk"],
        [2876, "Irkutsk Oblast"],
        [2877, "Jewish Autonomous Oblast"],
        [2878, "Amur Oblast"],
        [2879, "Buryatiya Republic"],
        [2880, "Sakhalin Oblast"],
        [2881, "Magadan Oblast"],
        [2882, "Kamchatka"],
        [2883, "Chukotka"],
        [2884, "Transbaikal Territory"],
        [2885, "Eastern Province"],
        [2886, "Kigali"],
        [2887, "Northern Province"],
        [2888, "Western Province"],
        [2889, "Southern Province"],
        [2890, "Tabuk Region"],
        [2891, "Najran Region"],
        [2892, "Mecca Region"],
        [2893, "Jazan Region"],
        [2894, "Ha'il Region"],
        [2895, "'Asir Region"],
        [2896, "Eastern Province"],
        [2897, "Riyadh Region"],
        [2898, "Al-Qassim Region"],
        [2899, "Medina Region"],
        [2900, "Al Jawf Region"],
        [2901, "Northern Borders Region"],
        [2902, "Al Bahah Region"],
        [2903, "Western Province"],
        [2904, "Malaita"],
        [2905, "Isabel"],
        [2906, "Guadalcanal"],
        [2907, "Central Province"],
        [2908, "Temotu"],
        [2909, "Makira"],
        [2910, "Choiseul"],
        [2911, "Rennell and Bellona"],
        [2912, "Honiara"],
        [2913, "Takamaka"],
        [2914, "Saint Louis"],
        [2915, "Port Glaud"],
        [2916, "Pointe Larue"],
        [2917, "Plaisance"],
        [2918, "Mont Fleuri"],
        [2919, "Mont Buxton"],
        [2920, "English River"],
        [2921, "La Digue"],
        [2922, "Grand Anse Mahe"],
        [2923, "Grand Anse Praslin"],
        [2924, "Glacis"],
        [2925, "Cascade"],
        [2926, "Bel Ombre"],
        [2927, "Bel Air"],
        [2928, "Beau Vallon"],
        [2929, "Baie Sainte Anne"],
        [2930, "Baie Lazare"],
        [2931, "Anse Royale"],
        [2932, "Anse Etoile"],
        [2933, "Anse Boileau"],
        [2934, "Anse-aux-Pins"],
        [2935, "Les Mamelles"],
        [2936, "Roche Caiman"],
        [2937, "Au Cap"],
        [2938, "Outer Islands"],
        [2939, "Ile Perseverance I"],
        [2940, "Ile Perseverance II"],
        [2941, "Northern State"],
        [2942, "Khartoum"],
        [2943, "Red Sea"],
        [2944, "Al Jazirah"],
        [2945, "Al Qa?arif"],
        [2946, "White Nile"],
        [2947, "Blue Nile"],
        [2948, "Western Darfur"],
        [2949, "West Kordofan State"],
        [2950, "Southern Darfur"],
        [2951, "Southern Kordofan"],
        [2952, "Kassala"],
        [2953, "River Nile"],
        [2954, "Northern Darfur"],
        [2955, "North Kordofan"],
        [2956, "Sinnar"],
        [2957, "Eastern Darfur"],
        [2958, "Central Darfur"],
        [2959, "Norrbotten"],
        [2960, "Västmanland"],
        [2961, "Västernorrland"],
        [2962, "Västerbotten"],
        [2963, "Värmland"],
        [2964, "Uppsala"],
        [2965, "Stockholm"],
        [2966, "Södermanland"],
        [2967, "Östergötland"],
        [2968, "Örebro"],
        [2969, "Kronoberg"],
        [2970, "Dalarna"],
        [2971, "Kalmar"],
        [2972, "Jönköping"],
        [2973, "Jämtland"],
        [2974, "Halland"],
        [2975, "Gotland"],
        [2976, "Gävleborg"],
        [2977, "Blekinge"],
        [2978, "Skåne"],
        [2979, "Västra Götaland"],
        [2980, "Ascension"],
        [2981, "Tristan da Cunha"],
        [2982, "Saint Helena"],
        [2983, "Žalec"],
        [2984, "Zagorje ob Savi"],
        [2985, "Vrhnika"],
        [2986, "Tržic"],
        [2987, "Trebnje"],
        [2988, "Trbovlje"],
        [2989, "Tolmin"],
        [2990, "Velenje"],
        [2991, "Šmarje pri Jelšah"],
        [2992, "Slovenska Konjice"],
        [2993, "Slovenska Bistrica"],
        [2994, "Slovenj Gradec"],
        [2995, "Škofja Loka"],
        [2996, "Sežana"],
        [2997, "Sevnica"],
        [2998, "Sentjur"],
        [2999, "Ribnica"],
        [3000, "Radovljica"],
        [3001, "Radlje ob Dravi"],
        [3002, "Ptuj"],
        [3003, "Postojna"],
        [3004, "Piran-Pirano"],
        [3005, "Ormož"],
        [3006, "Novo Mesto"],
        [3007, "Nova Gorica"],
        [3008, "Murska Sobota"],
        [3009, "Mozirje"],
        [3010, "Metlika"],
        [3011, "Maribor"],
        [3012, "Logatec"],
        [3013, "Ljutomer"],
        [3014, "Litija"],
        [3015, "Lenart"],
        [3016, "Laško"],
        [3017, "Krško"],
        [3018, "Kranj"],
        [3019, "Koper-Capodistria"],
        [3020, "Kocevje"],
        [3021, "Kamnik"],
        [3022, "Jesenice"],
        [3023, "Izola-Isola"],
        [3024, "Ilirska Bistrica"],
        [3025, "Idrija"],
        [3026, "Hrastnik"],
        [3027, "Grosuplje"],
        [3028, "Gornja Radgona"],
        [3029, "Dravograd"],
        [3030, "Domžale"],
        [3031, "Crnomelj"],
        [3032, "Cerknica"],
        [3033, "Celje"],
        [3034, "Brežice"],
        [3035, "Ajdovšcina"],
        [3036, "Hrpelje-Kozina"],
        [3037, "Divaca"],
        [3038, "Pivka"],
        [3039, "Loška Dolina"],
        [3040, "Loški Potok"],
        [3041, "Osilnica"],
        [3042, "Velike Lašce"],
        [3043, "Škofljica"],
        [3044, "Ig"],
        [3045, "Brezovica"],
        [3046, "Borovnica"],
        [3047, "Vipava"],
        [3048, "Komen"],
    ])


export const StateIdAndDisplayNameArray: Array<{ text: string, value: number }> = [
    {text: "Alberta", value: 1},
    {text: "British Columbia", value: 2},
    {text: "Manitoba", value: 3},
    {text: "New Brunswick", value: 4},
    {text: "Newfoundland and Labrador", value: 5},
    {text: "Northwest Territories", value: 6},
    {text: "Nova Scotia", value: 7},
    {text: "Nunavut", value: 8},
    {text: "Ontario", value: 9},
    {text: "Prince Edward Island", value: 10},
    {text: "Quebec", value: 11},
    {text: "Saskatchewan", value: 12},
    {text: "Yukon", value: 13},
    {text: "Alabama", value: 14},
    {text: "Alaska", value: 15},
    {text: "Arizona", value: 16},
    {text: "Arkansas", value: 17},
    {text: "Armed Forces Americas", value: 18},
    {text: "Armed Forces Europe", value: 19},
    {text: "Armed Forces Pacific", value: 20},
    {text: "California", value: 21},
    {text: "Colorado", value: 22},
    {text: "Connecticut", value: 23},
    {text: "Delaware", value: 24},
    {text: "Florida", value: 25},
    {text: "Georgia", value: 26},
    {text: "Hawaii", value: 27},
    {text: "Idaho", value: 28},
    {text: "Illinois", value: 29},
    {text: "Indiana", value: 30},
    {text: "Iowa", value: 31},
    {text: "Kansas", value: 32},
    {text: "Kentucky", value: 33},
    {text: "Louisiana", value: 34},
    {text: "Maine", value: 35},
    {text: "Maryland", value: 36},
    {text: "Massachusetts", value: 37},
    {text: "Michigan", value: 38},
    {text: "Minnesota", value: 39},
    {text: "Mississippi", value: 40},
    {text: "Missouri", value: 41},
    {text: "Montana", value: 42},
    {text: "Nebraska", value: 43},
    {text: "Nevada", value: 44},
    {text: "New Hampshire", value: 45},
    {text: "New Jersey", value: 46},
    {text: "New Mexico", value: 47},
    {text: "New York", value: 48},
    {text: "North Carolina", value: 49},
    {text: "North Dakota", value: 50},
    {text: "Ohio", value: 51},
    {text: "Oklahoma", value: 52},
    {text: "Oregon", value: 53},
    {text: "Pennsylvania", value: 54},
    {text: "Rhode Island", value: 55},
    {text: "South Carolina", value: 56},
    {text: "South Dakota", value: 57},
    {text: "Tennessee", value: 58},
    {text: "Texas", value: 59},
    {text: "Utah", value: 60},
    {text: "Vermont", value: 61},
    {text: "Virginia", value: 62},
    {text: "Washington", value: 63},
    {text: "Washington, D.C.", value: 64},
    {text: "West Virginia", value: 65},
    {text: "Wisconsin", value: 66},
    {text: "Wyoming", value: 67},
    {text: "Sant Julià de Loria", value: 68},
    {text: "Ordino", value: 69},
    {text: "La Massana", value: 70},
    {text: "Encamp", value: 71},
    {text: "Canillo", value: 72},
    {text: "Andorra la Vella", value: 73},
    {text: "Escaldes-Engordany", value: 74},
    {text: "Imarat Umm al Qaywayn", value: 75},
    {text: "Ra's al Khaymah", value: 76},
    {text: "Dubai", value: 77},
    {text: "Sharjah", value: 78},
    {text: "Fujairah", value: 79},
    {text: "Ajman", value: 80},
    {text: "Abu Dhabi", value: 81},
    {text: "Zabul", value: 82},
    {text: "Vardak", value: 83},
    {text: "Takhar", value: 84},
    {text: "Sar-e Pol", value: 85},
    {text: "Samangan", value: 86},
    {text: "Parwan", value: 87},
    {text: "Paktika", value: 88},
    {text: "Paktia", value: 89},
    {text: "Oruzgan", value: 90},
    {text: "Nimroz", value: 91},
    {text: "Nangarhar", value: 92},
    {text: "Logar", value: 93},
    {text: "Laghman", value: 94},
    {text: "Kunduz", value: 95},
    {text: "Kunar", value: 96},
    {text: "Kapisa", value: 97},
    {text: "Kandahar", value: 98},
    {text: "Kabul", value: 99},
    {text: "Jowzjan", value: 100},
    {text: "Herat", value: 101},
    {text: "Helmand", value: 102},
    {text: "Ghowr", value: 103},
    {text: "Ghazni", value: 104},
    {text: "Faryab", value: 105},
    {text: "Farah", value: 106},
    {text: "Bamyan", value: 107},
    {text: "Balkh", value: 108},
    {text: "Baghlan", value: 109},
    {text: "Badghis", value: 110},
    {text: "Badakhshan", value: 111},
    {text: "Khowst", value: 112},
    {text: "Nuristan", value: 113},
    {text: "Daykundi", value: 114},
    {text: "Panjshir", value: 115},
    {text: "Saint Philip", value: 116},
    {text: "Saint Peter", value: 117},
    {text: "Saint Paul", value: 118},
    {text: "Saint Mary", value: 119},
    {text: "Saint John", value: 120},
    {text: "Saint George", value: 121},
    {text: "Redonda", value: 122},
    {text: "Barbuda", value: 123},
    {text: "Blowing Point", value: 124},
    {text: "Sandy Ground", value: 125},
    {text: "Sandy Hill", value: 126},
    {text: "The Valley", value: 127},
    {text: "East End", value: 128},
    {text: "North Hill", value: 129},
    {text: "West End", value: 130},
    {text: "South Hill", value: 131},
    {text: "The Quarter", value: 132},
    {text: "North Side", value: 133},
    {text: "Island Harbour", value: 134},
    {text: "George Hill", value: 135},
    {text: "Stoney Ground", value: 136},
    {text: "The Farrington", value: 137},
    {text: "Berat", value: 138},
    {text: "Dibër", value: 139},
    {text: "Elbasan", value: 140},
    {text: "Gjirokastër", value: 141},
    {text: "Korçë", value: 142},
    {text: "Kukës", value: 143},
    {text: "Durrës", value: 144},
    {text: "Fier", value: 145},
    {text: "Lezhë", value: 146},
    {text: "Shkodër", value: 147},
    {text: "Tirana", value: 148},
    {text: "Vlorë", value: 149},
    {text: "Ararat", value: 150},
    {text: "Syunik", value: 151},
    {text: "Vayots Dzor", value: 152},
    {text: "Yerevan", value: 153},
    {text: "Aragatsotn", value: 154},
    {text: "Armavir", value: 155},
    {text: "Gegharkunik", value: 156},
    {text: "Kotayk", value: 157},
    {text: "Lori", value: 158},
    {text: "Shirak", value: 159},
    {text: "Tavush", value: 160},
    {text: "Lunda Sul", value: 161},
    {text: "Luanda Norte", value: 162},
    {text: "Moxico", value: 163},
    {text: "Cuando Cobango", value: 164},
    {text: "Zaire", value: 165},
    {text: "Uíge", value: 166},
    {text: "Malanje", value: 167},
    {text: "Luanda", value: 168},
    {text: "Cuanza Norte", value: 169},
    {text: "Cabinda", value: 170},
    {text: "Bengo", value: 171},
    {text: "Namibe", value: 172},
    {text: "Huíla", value: 173},
    {text: "Huambo", value: 174},
    {text: "Cunene", value: 175},
    {text: "Kwanza Sul", value: 176},
    {text: "Bíe", value: 177},
    {text: "Benguela", value: 178},
    {text: "Misiones", value: 179},
    {text: "Formosa", value: 180},
    {text: "Buenos Aires F.D.", value: 181},
    {text: "Entre Rios", value: 182},
    {text: "Corrientes", value: 183},
    {text: "Buenos Aires", value: 184},
    {text: "Tucuman", value: 185},
    {text: "Tierra del Fuego", value: 186},
    {text: "Santiago del Estero", value: 187},
    {text: "Santa Fe", value: 188},
    {text: "Santa Cruz", value: 189},
    {text: "San Luis", value: 190},
    {text: "San Juan", value: 191},
    {text: "Salta", value: 192},
    {text: "Rio Negro", value: 193},
    {text: "Neuquen", value: 194},
    {text: "Mendoza", value: 195},
    {text: "La Rioja", value: 196},
    {text: "La Pampa", value: 197},
    {text: "Jujuy", value: 198},
    {text: "Cordoba", value: 199},
    {text: "Chubut", value: 200},
    {text: "Chaco", value: 201},
    {text: "Catamarca", value: 202},
    {text: "Western District", value: 203},
    {text: "Swains Island", value: 204},
    {text: "Eastern District", value: 205},
    {text: "Manu'a", value: 206},
    {text: "Rose Island", value: 207},
    {text: "Vienna", value: 208},
    {text: "Vorarlberg", value: 209},
    {text: "Tyrol", value: 210},
    {text: "Styria", value: 211},
    {text: "Salzburg", value: 212},
    {text: "Upper Austria", value: 213},
    {text: "Lower Austria", value: 214},
    {text: "Carinthia", value: 215},
    {text: "Burgenland", value: 216},
    {text: "Western Australia", value: 217},
    {text: "South Australia", value: 218},
    {text: "Northern Territory", value: 219},
    {text: "Victoria", value: 220},
    {text: "Tasmania", value: 221},
    {text: "Queensland", value: 222},
    {text: "New South Wales", value: 223},
    {text: "Australian Capital Territory", value: 224},
    {text: "Mariehamns stad", value: 225},
    {text: "Ålands landsbygd", value: 226},
    {text: "Ålands skärgård", value: 227},
    {text: "Beyl?qan", value: 228},
    {text: "Z?ngilan", value: 229},
    {text: "Yardimli", value: 230},
    {text: "Shusha", value: 231},
    {text: "Salyan", value: 232},
    {text: "Sabirabad", value: 233},
    {text: "Saatli", value: 234},
    {text: "Bil?suvar", value: 235},
    {text: "Neftçala", value: 236},
    {text: "Nakhichevan", value: 237},
    {text: "Masally", value: 238},
    {text: "Lerik", value: 239},
    {text: "L?nk?ran", value: 240},
    {text: "Laçin", value: 241},
    {text: "Qubadli", value: 242},
    {text: "Imisli", value: 243},
    {text: "Füzuli", value: 244},
    {text: "Jabrayil", value: 245},
    {text: "Jalilabad", value: 246},
    {text: "Astara", value: 247},
    {text: "Xocali", value: 248},
    {text: "Agcab?di", value: 249},
    {text: "Agdam", value: 250},
    {text: "Shirvan", value: 251},
    {text: "Lankaran Sahari", value: 252},
    {text: "Shusha City", value: 253},
    {text: "T?rt?r", value: 254},
    {text: "Xank?ndi", value: 255},
    {text: "Khojavend", value: 256},
    {text: "Z?rdab", value: 257},
    {text: "Zaqatala", value: 258},
    {text: "Yevlax", value: 259},
    {text: "Oguz", value: 260},
    {text: "Ucar", value: 261},
    {text: "Tovuz", value: 262},
    {text: "Samaxi", value: 263},
    {text: "Shaki", value: 264},
    {text: "S?mkir", value: 265},
    {text: "Kürd?mir", value: 266},
    {text: "Q?b?l?", value: 267},
    {text: "Qusar", value: 268},
    {text: "Quba", value: 269},
    {text: "Goygol Rayon", value: 270},
    {text: "Xaçmaz", value: 271},
    {text: "Kalbajar", value: 272},
    {text: "Qazax", value: 273},
    {text: "Goranboy", value: 274},
    {text: "Qax", value: 275},
    {text: "Ismayilli", value: 276},
    {text: "Göyçay", value: 277},
    {text: "Shabran", value: 278},
    {text: "Dask?s?n", value: 279},
    {text: "Balak?n", value: 280},
    {text: "Barda", value: 281},
    {text: "Baki", value: 282},
    {text: "Abseron", value: 283},
    {text: "Agsu", value: 284},
    {text: "Agdas", value: 285},
    {text: "G?d?b?y", value: 286},
    {text: "Agstafa", value: 287},
    {text: "G?nc?", value: 288},
    {text: "Ming?cevir", value: 289},
    {text: "Naftalan", value: 290},
    {text: "Qobustan", value: 291},
    {text: "Samux", value: 292},
    {text: "Shaki City", value: 293},
    {text: "Siy?z?n", value: 294},
    {text: "Sumqayit", value: 295},
    {text: "Xizi", value: 296},
    {text: "Yevlax City", value: 297},
    {text: "Haciqabul", value: 298},
    {text: "Federation of B&H", value: 299},
    {text: "Srpska", value: 300},
    {text: "Brcko", value: 301},
    {text: "Saint Thomas", value: 302},
    {text: "Saint Philip", value: 303},
    {text: "Saint Peter", value: 304},
    {text: "Saint Michael", value: 305},
    {text: "Saint Lucy", value: 306},
    {text: "Saint Joseph", value: 307},
    {text: "Saint John", value: 308},
    {text: "Saint James", value: 309},
    {text: "Saint George", value: 310},
    {text: "Saint Andrew", value: 311},
    {text: "Christ Church", value: 312},
    {text: "Rajshahi Division", value: 313},
    {text: "Dhaka", value: 314},
    {text: "Chittagong", value: 315},
    {text: "Khulna", value: 316},
    {text: "Barisal", value: 317},
    {text: "Sylhet", value: 318},
    {text: "Rangpur Division", value: 319},
    {text: "Mymensingh Division", value: 320},
    {text: "Brussels Capital", value: 321},
    {text: "Wallonia", value: 322},
    {text: "Flanders", value: 323},
    {text: "Boucle du Mouhoun", value: 324},
    {text: "Cascades", value: 325},
    {text: "Centre", value: 326},
    {text: "Centre-Est", value: 327},
    {text: "Centre-Nord", value: 328},
    {text: "Centre-Ouest", value: 329},
    {text: "Centre-Sud", value: 330},
    {text: "Est", value: 331},
    {text: "Hauts-Bassins", value: 332},
    {text: "Nord", value: 333},
    {text: "Plateau-Central", value: 334},
    {text: "Sahel", value: 335},
    {text: "Sud-Ouest", value: 336},
    {text: "Razgrad", value: 337},
    {text: "Montana", value: 338},
    {text: "Vratsa", value: 339},
    {text: "Varna", value: 340},
    {text: "Dobrich", value: 341},
    {text: "Sofia", value: 342},
    {text: "Ruse", value: 343},
    {text: "Plovdiv", value: 344},
    {text: "Pleven", value: 345},
    {text: "Pernik", value: 346},
    {text: "Pazardzhik", value: 347},
    {text: "Lovech", value: 348},
    {text: "Haskovo", value: 349},
    {text: "Sofia-Capital", value: 350},
    {text: "Burgas", value: 351},
    {text: "Blagoevgrad", value: 352},
    {text: "Gabrovo", value: 353},
    {text: "Kardzhali", value: 354},
    {text: "Kyustendil", value: 355},
    {text: "Shumen", value: 356},
    {text: "Silistra", value: 357},
    {text: "Sliven", value: 358},
    {text: "Smolyan", value: 359},
    {text: "Stara Zagora", value: 360},
    {text: "Targovishte", value: 361},
    {text: "Veliko Tarnovo", value: 362},
    {text: "Vidin", value: 363},
    {text: "Yambol", value: 364},
    {text: "Muharraq", value: 365},
    {text: "Manama", value: 366},
    {text: "Southern Governorate", value: 367},
    {text: "Northern", value: 368},
    {text: "Makamba", value: 369},
    {text: "Bururi", value: 370},
    {text: "Muramvya", value: 371},
    {text: "Gitega", value: 372},
    {text: "Ruyigi", value: 373},
    {text: "Cankuzo", value: 374},
    {text: "Karuzi", value: 375},
    {text: "Bubanza", value: 376},
    {text: "Cibitoke", value: 377},
    {text: "Ngozi", value: 378},
    {text: "Kayanza", value: 379},
    {text: "Muyinga", value: 380},
    {text: "Kirundo", value: 381},
    {text: "Rutana", value: 382},
    {text: "Mwaro", value: 383},
    {text: "Bujumbura Mairie", value: 384},
    {text: "Bujumbura Rural", value: 385},
    {text: "Rumonge", value: 386},
    {text: "Zou", value: 387},
    {text: "Ouémé", value: 388},
    {text: "Mono", value: 389},
    {text: "Borgou", value: 390},
    {text: "Atlantique", value: 391},
    {text: "Atakora", value: 392},
    {text: "Alibori", value: 393},
    {text: "Collines", value: 394},
    {text: "Kouffo", value: 395},
    {text: "Donga", value: 396},
    {text: "Littoral", value: 397},
    {text: "Plateau", value: 398},
    {text: "Warwick", value: 399},
    {text: "Southampton", value: 400},
    {text: "Smith's", value: 401},
    {text: "Sandys", value: 402},
    {text: "Saint George's", value: 403},
    {text: "Saint George", value: 404},
    {text: "Pembroke", value: 405},
    {text: "Paget", value: 406},
    {text: "Hamilton", value: 407},
    {text: "Hamilton city", value: 408},
    {text: "Devonshire", value: 409},
    {text: "Tutong", value: 410},
    {text: "Temburong", value: 411},
    {text: "Brunei-Muara District", value: 412},
    {text: "Belait", value: 413},
    {text: "Tarija", value: 414},
    {text: "Santa Cruz", value: 415},
    {text: "Potosí", value: 416},
    {text: "Pando", value: 417},
    {text: "Oruro", value: 418},
    {text: "La Paz", value: 419},
    {text: "Cochabamba", value: 420},
    {text: "Chuquisaca", value: 421},
    {text: "El Beni", value: 422},
    {text: "Bonaire", value: 423},
    {text: "Saba", value: 424},
    {text: "Sint Eustatius", value: 425},
    {text: "Rio Grande do Norte", value: 426},
    {text: "Piauí", value: 427},
    {text: "Pernambuco", value: 428},
    {text: "Paraíba", value: 429},
    {text: "Pará", value: 430},
    {text: "Maranhão", value: 431},
    {text: "Ceará", value: 432},
    {text: "Amapá", value: 433},
    {text: "Alagoas", value: 434},
    {text: "Sergipe", value: 435},
    {text: "São Paulo", value: 436},
    {text: "Santa Catarina", value: 437},
    {text: "Rio Grande do Sul", value: 438},
    {text: "Rio de Janeiro", value: 439},
    {text: "Paraná", value: 440},
    {text: "Minas Gerais", value: 441},
    {text: "Mato Grosso do Sul", value: 442},
    {text: "Mato Grosso", value: 443},
    {text: "Goiás", value: 444},
    {text: "Federal District", value: 445},
    {text: "Espírito Santo", value: 446},
    {text: "Bahia", value: 447},
    {text: "Tocantins", value: 448},
    {text: "Roraima", value: 449},
    {text: "Amazonas", value: 450},
    {text: "Acre", value: 451},
    {text: "Rondônia", value: 452},
    {text: "San Salvador", value: 453},
    {text: "Ragged Island", value: 454},
    {text: "Berry Islands", value: 455},
    {text: "New Providence", value: 456},
    {text: "Mayaguana", value: 457},
    {text: "Long Island", value: 458},
    {text: "Inagua", value: 459},
    {text: "Harbour Island", value: 460},
    {text: "Freeport", value: 461},
    {text: "Exuma", value: 462},
    {text: "Cat Island", value: 463},
    {text: "Bimini", value: 464},
    {text: "Acklins", value: 465},
    {text: "Black Point", value: 466},
    {text: "Central Abaco", value: 467},
    {text: "Central Andros", value: 468},
    {text: "Central Eleuthera", value: 469},
    {text: "Crooked Island and Long Cay", value: 470},
    {text: "East Grand Bahama", value: 471},
    {text: "Grand Cay", value: 472},
    {text: "Hope Town", value: 473},
    {text: "Mangrove Cay", value: 474},
    {text: "Moore’s Island", value: 475},
    {text: "North Abaco", value: 476},
    {text: "North Andros", value: 477},
    {text: "North Eleuthera", value: 478},
    {text: "Rum Cay", value: 479},
    {text: "South Abaco", value: 480},
    {text: "South Andros", value: 481},
    {text: "South Eleuthera", value: 482},
    {text: "Spanish Wells", value: 483},
    {text: "West Grand Bahama", value: 484},
    {text: "Bumthang", value: 485},
    {text: "Chukha", value: 486},
    {text: "Dagana", value: 487},
    {text: "Chirang", value: 488},
    {text: "Geylegphug", value: 489},
    {text: "Haa", value: 490},
    {text: "Lhuntse", value: 491},
    {text: "Mongar", value: 492},
    {text: "Paro", value: 493},
    {text: "Pemagatshel", value: 494},
    {text: "Punakha", value: 495},
    {text: "Samchi", value: 496},
    {text: "Samdrup Jongkhar", value: 497},
    {text: "Shemgang", value: 498},
    {text: "Tashigang", value: 499},
    {text: "Thimphu", value: 500},
    {text: "Tongsa", value: 501},
    {text: "Wangdi Phodrang", value: 502},
    {text: "Gasa", value: 503},
    {text: "Trashi Yangste", value: 504},
    {text: "Ngwaketsi", value: 505},
    {text: "South-East", value: 506},
    {text: "North-East", value: 507},
    {text: "North-West", value: 508},
    {text: "Kweneng", value: 509},
    {text: "Kgatleng", value: 510},
    {text: "Kgalagadi", value: 511},
    {text: "Ghanzi", value: 512},
    {text: "Chobe", value: 513},
    {text: "Central", value: 514},
    {text: "City of Francistown", value: 515},
    {text: "Gaborone", value: 516},
    {text: "Jwaneng", value: 517},
    {text: "Lobatse", value: 518},
    {text: "Selibe Phikwe", value: 519},
    {text: "Sowa Town", value: 520},
    {text: "Vitebsk", value: 521},
    {text: "Mogilev", value: 522},
    {text: "Minsk", value: 523},
    {text: "Minsk City", value: 524},
    {text: "Grodnenskaya", value: 525},
    {text: "Gomel Oblast", value: 526},
    {text: "Brest", value: 527},
    {text: "Toledo", value: 528},
    {text: "Southern District", value: 529},
    {text: "Orange Walk", value: 530},
    {text: "Corozal", value: 531},
    {text: "Cayo", value: 532},
    {text: "Belize", value: 533},
    {text: "Tshuapa", value: 534},
    {text: "Tshopo", value: 535},
    {text: "Tanganyika", value: 536},
    {text: "South Kivu", value: 537},
    {text: "Sankuru", value: 538},
    {text: "Nord Kivu", value: 539},
    {text: "Mongala", value: 540},
    {text: "Maniema", value: 541},
    {text: "Kasai-Central", value: 542},
    {text: "Kasaï-Oriental", value: 543},
    {text: "Kasai", value: 544},
    {text: "Ituri", value: 545},
    {text: "Haut-Uele", value: 546},
    {text: "Haut-Lomami", value: 547},
    {text: "Équateur", value: 548},
    {text: "Bas-Uele", value: 549},
    {text: "Lualaba", value: 550},
    {text: "Mai-Ndombe", value: 551},
    {text: "Kwilu", value: 552},
    {text: "Kwango", value: 553},
    {text: "Kinshasa", value: 554},
    {text: "Bas-Congo", value: 555},
    {text: "Haut-Katanga", value: 556},
    {text: "Lomami", value: 557},
    {text: "Nord-Ubangi", value: 558},
    {text: "Sud-Ubangi", value: 559},
    {text: "Vakaga", value: 560},
    {text: "Ouaka", value: 561},
    {text: "Mbomou", value: 562},
    {text: "Haut-Mbomou", value: 563},
    {text: "Haute-Kotto", value: 564},
    {text: "Basse-Kotto", value: 565},
    {text: "Bamingui-Bangoran", value: 566},
    {text: "Sangha-Mbaéré", value: 567},
    {text: "Ouham-Pendé", value: 568},
    {text: "Ouham", value: 569},
    {text: "Ombella-M'Poko", value: 570},
    {text: "Nana-Mambéré", value: 571},
    {text: "Lobaye", value: 572},
    {text: "Kémo", value: 573},
    {text: "Mambéré-Kadéï", value: 574},
    {text: "Nana-Grébizi", value: 575},
    {text: "Bangui", value: 576},
    {text: "Sangha", value: 577},
    {text: "Pool", value: 578},
    {text: "Plateaux", value: 579},
    {text: "Niari", value: 580},
    {text: "Likouala", value: 581},
    {text: "Lékoumou", value: 582},
    {text: "Kouilou", value: 583},
    {text: "Cuvette", value: 584},
    {text: "Bouenza", value: 585},
    {text: "Brazzaville", value: 586},
    {text: "Cuvette-Ouest", value: 587},
    {text: "Pointe-Noire", value: 588},
    {text: "Zurich", value: 589},
    {text: "Zug", value: 590},
    {text: "Vaud", value: 591},
    {text: "Valais", value: 592},
    {text: "Uri", value: 593},
    {text: "Ticino", value: 594},
    {text: "Thurgau", value: 595},
    {text: "Solothurn", value: 596},
    {text: "Schwyz", value: 597},
    {text: "Schaffhausen", value: 598},
    {text: "Saint Gallen", value: 599},
    {text: "Obwalden", value: 600},
    {text: "Nidwalden", value: 601},
    {text: "Neuchâtel", value: 602},
    {text: "Lucerne", value: 603},
    {text: "Jura", value: 604},
    {text: "Grisons", value: 605},
    {text: "Glarus", value: 606},
    {text: "Geneva", value: 607},
    {text: "Fribourg", value: 608},
    {text: "Bern", value: 609},
    {text: "Basel-City", value: 610},
    {text: "Basel-Landschaft", value: 611},
    {text: "Appenzell Ausserrhoden", value: 612},
    {text: "Appenzell Innerrhoden", value: 613},
    {text: "Aargau", value: 614},
    {text: "Yamoussoukro", value: 615},
    {text: "Bas-Sassandra", value: 616},
    {text: "Comoé", value: 617},
    {text: "Denguélé", value: 618},
    {text: "Gôh-Djiboua", value: 619},
    {text: "Lacs", value: 620},
    {text: "Lagunes", value: 621},
    {text: "Montagnes", value: 622},
    {text: "Sassandra-Marahoué", value: 623},
    {text: "Savanes", value: 624},
    {text: "Vallée du Bandama", value: 625},
    {text: "Woroba", value: 626},
    {text: "Zanzan", value: 627},
    {text: "Abidjan", value: 628},
    {text: "Aitutaki", value: 629},
    {text: "Atiu", value: 630},
    {text: "Mangaia", value: 631},
    {text: "Manihiki", value: 632},
    {text: "Ma'uke", value: 633},
    {text: "Mitiaro", value: 634},
    {text: "Palmerston", value: 635},
    {text: "Penrhyn", value: 636},
    {text: "Pukapuka", value: 637},
    {text: "Rakahanga", value: 638},
    {text: "Rarotonga", value: 639},
    {text: "Valparaíso", value: 640},
    {text: "Tarapacá", value: 641},
    {text: "Santiago Metropolitan", value: 642},
    {text: "Maule Region", value: 643},
    {text: "Los Lagos Region", value: 644},
    {text: "O'Higgins Region", value: 645},
    {text: "Coquimbo Region", value: 646},
    {text: "Biobío", value: 647},
    {text: "Atacama", value: 648},
    {text: "Araucanía", value: 649},
    {text: "Antofagasta", value: 650},
    {text: "Aysén", value: 651},
    {text: "Region of Magallanes", value: 652},
    {text: "Arica y Parinacota", value: 653},
    {text: "Los Ríos Region", value: 654},
    {text: "Ñuble", value: 655},
    {text: "South-West", value: 656},
    {text: "South", value: 657},
    {text: "West", value: 658},
    {text: "North-West", value: 659},
    {text: "North", value: 660},
    {text: "Littoral", value: 661},
    {text: "Far North", value: 662},
    {text: "East", value: 663},
    {text: "Centre", value: 664},
    {text: "Adamaoua", value: 665},
    {text: "Tibet", value: 666},
    {text: "Qinghai", value: 667},
    {text: "Xinjiang", value: 668},
    {text: "Zhejiang", value: 669},
    {text: "Yunnan", value: 670},
    {text: "Tianjin", value: 671},
    {text: "Sichuan", value: 672},
    {text: "Shanxi", value: 673},
    {text: "Shanghai", value: 674},
    {text: "Shandong", value: 675},
    {text: "Shaanxi", value: 676},
    {text: "Ningxia Hui Autonomous Region", value: 677},
    {text: "Jiangxi", value: 678},
    {text: "Jiangsu", value: 679},
    {text: "Hunan", value: 680},
    {text: "Hubei", value: 681},
    {text: "Henan", value: 682},
    {text: "Hebei", value: 683},
    {text: "Hainan", value: 684},
    {text: "Guizhou", value: 685},
    {text: "Guangxi", value: 686},
    {text: "Guangdong", value: 687},
    {text: "Gansu", value: 688},
    {text: "Fujian", value: 689},
    {text: "Chongqing", value: 690},
    {text: "Anhui", value: 691},
    {text: "Inner Mongolia", value: 692},
    {text: "Liaoning", value: 693},
    {text: "Jilin", value: 694},
    {text: "Heilongjiang", value: 695},
    {text: "Beijing", value: 696},
    {text: "Vichada", value: 697},
    {text: "Vaupés", value: 698},
    {text: "Valle del Cauca", value: 699},
    {text: "Tolima", value: 700},
    {text: "Sucre", value: 701},
    {text: "Santander", value: 702},
    {text: "San Andres y Providencia", value: 703},
    {text: "Risaralda", value: 704},
    {text: "Quindío", value: 705},
    {text: "Putumayo", value: 706},
    {text: "Norte de Santander", value: 707},
    {text: "Nariño", value: 708},
    {text: "Meta", value: 709},
    {text: "Magdalena", value: 710},
    {text: "La Guajira", value: 711},
    {text: "Huila", value: 712},
    {text: "Guaviare", value: 713},
    {text: "Guainía", value: 714},
    {text: "Cundinamarca", value: 715},
    {text: "Córdoba", value: 716},
    {text: "Chocó", value: 717},
    {text: "Cesar", value: 718},
    {text: "Cauca", value: 719},
    {text: "Casanare", value: 720},
    {text: "Caquetá", value: 721},
    {text: "Caldas", value: 722},
    {text: "Boyacá", value: 723},
    {text: "Bolívar", value: 724},
    {text: "Bogota D.C.", value: 725},
    {text: "Atlántico", value: 726},
    {text: "Departamento de Arauca", value: 727},
    {text: "Antioquia", value: 728},
    {text: "Amazonas", value: 729},
    {text: "San José", value: 730},
    {text: "Puntarenas", value: 731},
    {text: "Limón", value: 732},
    {text: "Heredia", value: 733},
    {text: "Guanacaste", value: 734},
    {text: "Cartago", value: 735},
    {text: "Alajuela", value: 736},
    {text: "Villa Clara", value: 737},
    {text: "Santiago de Cuba", value: 738},
    {text: "Sancti Spíritus", value: 739},
    {text: "Pinar del Río", value: 740},
    {text: "Matanzas", value: 741},
    {text: "Las Tunas", value: 742},
    {text: "Isla de la Juventud", value: 743},
    {text: "Holguín", value: 744},
    {text: "Guantánamo", value: 745},
    {text: "Granma", value: 746},
    {text: "Havana", value: 747},
    {text: "Cienfuegos", value: 748},
    {text: "Ciego de Ávila", value: 749},
    {text: "Camagüey", value: 750},
    {text: "Artemisa", value: 751},
    {text: "Mayabeque", value: 752},
    {text: "Tarrafal", value: 753},
    {text: "São Vicente", value: 754},
    {text: "Santa Catarina", value: 755},
    {text: "Sal", value: 756},
    {text: "Ribeira Grande", value: 757},
    {text: "Praia", value: 758},
    {text: "Paul", value: 759},
    {text: "Maio", value: 760},
    {text: "Brava", value: 761},
    {text: "Boa Vista", value: 762},
    {text: "Mosteiros", value: 763},
    {text: "Santa Cruz", value: 764},
    {text: "São Domingos", value: 765},
    {text: "São Filipe", value: 766},
    {text: "São Miguel", value: 767},
    {text: "Porto Novo", value: 768},
    {text: "Ribeira Brava", value: 769},
    {text: "Santa Catarina do Fogo", value: 770},
    {text: "São Salvador do Mundo", value: 771},
    {text: "Tarrafal de São Nicolau", value: 772},
    {text: "São Lourenço dos Órgãos", value: 773},
    {text: "Ribeira Grande de Santiago", value: 774},
    {text: "Pafos", value: 775},
    {text: "Nicosia", value: 776},
    {text: "Limassol", value: 777},
    {text: "Larnaka", value: 778},
    {text: "Keryneia", value: 779},
    {text: "Ammochostos", value: 780},
    {text: "Hlavní mesto Praha", value: 781},
    {text: "South Moravian", value: 782},
    {text: "Jihoceský kraj", value: 783},
    {text: "Vysocina", value: 784},
    {text: "Karlovarský kraj", value: 785},
    {text: "Královéhradecký kraj", value: 786},
    {text: "Liberecký kraj", value: 787},
    {text: "Olomoucký", value: 788},
    {text: "Moravskoslezský", value: 789},
    {text: "Pardubický", value: 790},
    {text: "Plzenský kraj", value: 791},
    {text: "Central Bohemia", value: 792},
    {text: "Ústecký kraj", value: 793},
    {text: "Zlín", value: 794},
    {text: "Thuringia", value: 795},
    {text: "Schleswig-Holstein", value: 796},
    {text: "Saxony-Anhalt", value: 797},
    {text: "Saxony", value: 798},
    {text: "Saarland", value: 799},
    {text: "Rheinland-Pfalz", value: 800},
    {text: "North Rhine-Westphalia", value: 801},
    {text: "Lower Saxony", value: 802},
    {text: "Mecklenburg-Vorpommern", value: 803},
    {text: "Hesse", value: 804},
    {text: "Hamburg", value: 805},
    {text: "Bremen", value: 806},
    {text: "Brandenburg", value: 807},
    {text: "Berlin", value: 808},
    {text: "Bavaria", value: 809},
    {text: "Baden-Württemberg", value: 810},
    {text: "Tadjourah", value: 811},
    {text: "Obock", value: 812},
    {text: "Djibouti", value: 813},
    {text: "Dikhil", value: 814},
    {text: "Ali Sabieh", value: 815},
    {text: "Arta", value: 816},
    {text: "Capital Region", value: 817},
    {text: "Central Jutland", value: 818},
    {text: "North Denmark", value: 819},
    {text: "Zealand", value: 820},
    {text: "South Denmark", value: 821},
    {text: "Saint Peter", value: 822},
    {text: "Saint Paul", value: 823},
    {text: "Saint Patrick", value: 824},
    {text: "Saint Mark", value: 825},
    {text: "Saint Luke", value: 826},
    {text: "Saint Joseph", value: 827},
    {text: "Saint John", value: 828},
    {text: "Saint George", value: 829},
    {text: "Saint David", value: 830},
    {text: "Saint Andrew", value: 831},
    {text: "Valverde", value: 832},
    {text: "Santiago Rodríguez", value: 833},
    {text: "Santiago", value: 834},
    {text: "San Pedro de Macorís", value: 835},
    {text: "San Juan", value: 836},
    {text: "San Cristóbal", value: 837},
    {text: "Sánchez Ramírez", value: 838},
    {text: "Samaná", value: 839},
    {text: "Hermanas Mirabal", value: 840},
    {text: "Puerto Plata", value: 841},
    {text: "Peravia", value: 842},
    {text: "Pedernales", value: 843},
    {text: "Nacional", value: 844},
    {text: "Monte Plata", value: 845},
    {text: "Monte Cristi", value: 846},
    {text: "Monseñor Nouel", value: 847},
    {text: "María Trinidad Sánchez", value: 848},
    {text: "La Vega", value: 849},
    {text: "La Romana", value: 850},
    {text: "La Altagracia", value: 851},
    {text: "Independencia", value: 852},
    {text: "Hato Mayor", value: 853},
    {text: "Espaillat", value: 854},
    {text: "El Seíbo", value: 855},
    {text: "Elías Piña", value: 856},
    {text: "Duarte", value: 857},
    {text: "Dajabón", value: 858},
    {text: "Barahona", value: 859},
    {text: "Baoruco", value: 860},
    {text: "Azua", value: 861},
    {text: "San José de Ocoa", value: 862},
    {text: "Santo Domingo", value: 863},
    {text: "Tlemcen", value: 864},
    {text: "Tizi Ouzou", value: 865},
    {text: "Tissemsilt", value: 866},
    {text: "Tipaza", value: 867},
    {text: "Tindouf", value: 868},
    {text: "Tiaret", value: 869},
    {text: "Tébessa", value: 870},
    {text: "Tamanrasset", value: 871},
    {text: "Souk Ahras", value: 872},
    {text: "Skikda", value: 873},
    {text: "Sidi Bel Abbès", value: 874},
    {text: "Sétif", value: 875},
    {text: "Saida", value: 876},
    {text: "Relizane", value: 877},
    {text: "Oum el Bouaghi", value: 878},
    {text: "Ouargla", value: 879},
    {text: "Oran", value: 880},
    {text: "Naama", value: 881},
    {text: "M'Sila", value: 882},
    {text: "Mostaganem", value: 883},
    {text: "Mila", value: 884},
    {text: "Medea", value: 885},
    {text: "Mascara", value: 886},
    {text: "Laghouat", value: 887},
    {text: "Khenchela", value: 888},
    {text: "Jijel", value: 889},
    {text: "Illizi", value: 890},
    {text: "Guelma", value: 891},
    {text: "Ghardaia", value: 892},
    {text: "El Tarf", value: 893},
    {text: "El Oued", value: 894},
    {text: "El Bayadh", value: 895},
    {text: "Djelfa", value: 896},
    {text: "Constantine", value: 897},
    {text: "Chlef", value: 898},
    {text: "Boumerdes", value: 899},
    {text: "Bouira", value: 900},
    {text: "Bordj Bou Arréridj", value: 901},
    {text: "Blida", value: 902},
    {text: "Biskra", value: 903},
    {text: "Béjaïa", value: 904},
    {text: "Béchar", value: 905},
    {text: "Batna", value: 906},
    {text: "Annaba", value: 907},
    {text: "Algiers", value: 908},
    {text: "Aïn Témouchent", value: 909},
    {text: "Aïn Defla", value: 910},
    {text: "Adrar", value: 911},
    {text: "Bordj Badji Mokhtar", value: 912},
    {text: "Timimoun", value: 913},
    {text: "Beni Abbes", value: 914},
    {text: "In Guezzam", value: 915},
    {text: "In Salah", value: 916},
    {text: "Djanet", value: 917},
    {text: "El Menia", value: 918},
    {text: "Touggourt", value: 919},
    {text: "El Mghair", value: 920},
    {text: "Ouled Djellal", value: 921},
    {text: "Zamora-Chinchipe", value: 922},
    {text: "Tungurahua", value: 923},
    {text: "Pichincha", value: 924},
    {text: "Pastaza", value: 925},
    {text: "Napo", value: 926},
    {text: "Morona-Santiago", value: 927},
    {text: "Manabí", value: 928},
    {text: "Los Ríos", value: 929},
    {text: "Loja", value: 930},
    {text: "Imbabura", value: 931},
    {text: "Guayas", value: 932},
    {text: "Galápagos", value: 933},
    {text: "Esmeraldas", value: 934},
    {text: "El Oro", value: 935},
    {text: "Cotopaxi", value: 936},
    {text: "Chimborazo", value: 937},
    {text: "Carchi", value: 938},
    {text: "Cañar", value: 939},
    {text: "Bolívar", value: 940},
    {text: "Azuay", value: 941},
    {text: "Sucumbios", value: 942},
    {text: "Orellana", value: 943},
    {text: "Santo Domingo de los Tsáchilas", value: 944},
    {text: "Santa Elena", value: 945},
    {text: "Võrumaa", value: 946},
    {text: "Viljandimaa", value: 947},
    {text: "Valgamaa", value: 948},
    {text: "Tartu", value: 949},
    {text: "Saare", value: 950},
    {text: "Raplamaa", value: 951},
    {text: "Põlvamaa", value: 952},
    {text: "Pärnumaa", value: 953},
    {text: "Lääne-Virumaa", value: 954},
    {text: "Lääne", value: 955},
    {text: "Jõgevamaa", value: 956},
    {text: "Järvamaa", value: 957},
    {text: "Ida-Virumaa", value: 958},
    {text: "Hiiumaa", value: 959},
    {text: "Harjumaa", value: 960},
    {text: "Sohag", value: 961},
    {text: "North Sinai", value: 962},
    {text: "Qena", value: 963},
    {text: "Matruh", value: 964},
    {text: "Kafr el-Sheikh", value: 965},
    {text: "South Sinai", value: 966},
    {text: "Damietta", value: 967},
    {text: "Port Said", value: 968},
    {text: "Beni Suweif", value: 969},
    {text: "Asyut", value: 970},
    {text: "Aswan", value: 971},
    {text: "Suez", value: 972},
    {text: "Sharqia", value: 973},
    {text: "New Valley", value: 974},
    {text: "Qalyubia", value: 975},
    {text: "Cairo", value: 976},
    {text: "Minya", value: 977},
    {text: "Monufia", value: 978},
    {text: "Giza", value: 979},
    {text: "Ismailia", value: 980},
    {text: "Alexandria", value: 981},
    {text: "Gharbia", value: 982},
    {text: "Faiyum", value: 983},
    {text: "Beheira", value: 984},
    {text: "Red Sea", value: 985},
    {text: "Dakahlia", value: 986},
    {text: "Luxor", value: 987},
    {text: "Anseba", value: 988},
    {text: "Debub", value: 989},
    {text: "Southern Red Sea", value: 990},
    {text: "Gash-Barka", value: 991},
    {text: "Maekel", value: 992},
    {text: "Northern Red Sea", value: 993},
    {text: "Murcia", value: 994},
    {text: "Ceuta", value: 995},
    {text: "Balearic Islands", value: 996},
    {text: "Andalusia", value: 997},
    {text: "Canary Islands", value: 998},
    {text: "Castille-La Mancha", value: 999},
    {text: "Extremadura", value: 1000},
    {text: "Valencia", value: 1001},
    {text: "Asturias", value: 1002},
    {text: "Navarre", value: 1003},
    {text: "Madrid", value: 1004},
    {text: "La Rioja", value: 1005},
    {text: "Cantabria", value: 1006},
    {text: "Aragon", value: 1007},
    {text: "Castille and León", value: 1008},
    {text: "Catalonia", value: 1009},
    {text: "Galicia", value: 1010},
    {text: "Basque Country", value: 1011},
    {text: "Melilla", value: 1012},
    {text: "Addis Ababa", value: 1013},
    {text: "Afar", value: 1014},
    {text: "Amhara", value: 1015},
    {text: "Binshangul Gumuz", value: 1016},
    {text: "Dire Dawa", value: 1017},
    {text: "Gambela", value: 1018},
    {text: "Harari", value: 1019},
    {text: "Oromiya", value: 1020},
    {text: "Somali", value: 1021},
    {text: "Tigray", value: 1022},
    {text: "SNNPR", value: 1023},
    {text: "Lapland", value: 1024},
    {text: "Kainuu", value: 1025},
    {text: "North Ostrobothnia", value: 1026},
    {text: "Central Ostrobothnia", value: 1027},
    {text: "Ostrobothnia", value: 1028},
    {text: "South Ostrobothnia", value: 1029},
    {text: "Central Finland", value: 1030},
    {text: "North Karelia", value: 1031},
    {text: "North Savo", value: 1032},
    {text: "South Savo", value: 1033},
    {text: "South Karelia", value: 1034},
    {text: "Kymenlaakso", value: 1035},
    {text: "Pirkanmaa", value: 1036},
    {text: "Kanta-Häme", value: 1037},
    {text: "Southwest Finland", value: 1038},
    {text: "Uusimaa", value: 1039},
    {text: "Paijat-Hame", value: 1040},
    {text: "Satakunta", value: 1041},
    {text: "Western", value: 1042},
    {text: "Northern", value: 1043},
    {text: "Central", value: 1044},
    {text: "Eastern", value: 1045},
    {text: "Rotuma", value: 1046},
    {text: "Yap", value: 1047},
    {text: "Pohnpei", value: 1048},
    {text: "Kosrae", value: 1049},
    {text: "Chuuk", value: 1050},
    {text: "Vágar", value: 1051},
    {text: "Suðuroy", value: 1052},
    {text: "Streymoy", value: 1053},
    {text: "Sandoy", value: 1054},
    {text: "Norðoyar", value: 1055},
    {text: "Eysturoy", value: 1056},
    {text: "Provence-Alpes-Côte d'Azur", value: 1057},
    {text: "Pays de la Loire", value: 1058},
    {text: "Île-de-France", value: 1059},
    {text: "Corsica", value: 1060},
    {text: "Centre", value: 1061},
    {text: "Brittany", value: 1062},
    {text: "Bourgogne-Franche-Comté", value: 1063},
    {text: "Nouvelle-Aquitaine", value: 1064},
    {text: "Normandy", value: 1065},
    {text: "Grand Est", value: 1066},
    {text: "Occitanie", value: 1067},
    {text: "Hauts-de-France", value: 1068},
    {text: "Auvergne-Rhône-Alpes", value: 1069},
    {text: "Woleu-Ntem", value: 1070},
    {text: "Ogooué-Maritime", value: 1071},
    {text: "Ogooué-Lolo", value: 1072},
    {text: "Ogooué-Ivindo", value: 1073},
    {text: "Nyanga", value: 1074},
    {text: "Ngouni", value: 1075},
    {text: "Moyen-Ogooué", value: 1076},
    {text: "Haut-Ogooué", value: 1077},
    {text: "Estuaire", value: 1078},
    {text: "Wales", value: 1079},
    {text: "Scotland", value: 1080},
    {text: "Northern Ireland", value: 1081},
    {text: "England", value: 1082},
    {text: "Saint Patrick", value: 1083},
    {text: "Saint Mark", value: 1084},
    {text: "Saint John", value: 1085},
    {text: "Saint George", value: 1086},
    {text: "Saint David", value: 1087},
    {text: "Saint Andrew", value: 1088},
    {text: "Carriacou and Petite Martinique", value: 1089},
    {text: "T'bilisi", value: 1090},
    {text: "Achara", value: 1091},
    {text: "Kvemo Kartli", value: 1092},
    {text: "Kakheti", value: 1093},
    {text: "Guria", value: 1094},
    {text: "Imereti", value: 1095},
    {text: "Shida Kartli", value: 1096},
    {text: "Mtskheta-Mtianeti", value: 1097},
    {text: "Racha-Lechkhumi and Kvemo Svaneti", value: 1098},
    {text: "Samegrelo and Zemo Svaneti", value: 1099},
    {text: "Samtskhe-Javakheti", value: 1100},
    {text: "Abkhazia", value: 1101},
    {text: "Guyane", value: 1102},
    {text: "St Pierre du Bois", value: 1103},
    {text: "Torteval", value: 1104},
    {text: "Saint Saviour", value: 1105},
    {text: "Forest", value: 1106},
    {text: "St Martin", value: 1107},
    {text: "Saint Andrew", value: 1108},
    {text: "St Peter Port", value: 1109},
    {text: "Castel", value: 1110},
    {text: "Vale", value: 1111},
    {text: "St Sampson", value: 1112},
    {text: "Alderney", value: 1113},
    {text: "Western", value: 1114},
    {text: "Volta", value: 1115},
    {text: "Upper West", value: 1116},
    {text: "Upper East", value: 1117},
    {text: "Northern", value: 1118},
    {text: "Greater Accra", value: 1119},
    {text: "Eastern", value: 1120},
    {text: "Central", value: 1121},
    {text: "Ashanti", value: 1122},
    {text: "Bono", value: 1123},
    {text: "Oti", value: 1124},
    {text: "North East", value: 1125},
    {text: "Ahafo", value: 1126},
    {text: "Bono East", value: 1127},
    {text: "Savannah", value: 1128},
    {text: "Western North", value: 1129},
    {text: "Kujalleq", value: 1130},
    {text: "Qeqqata", value: 1131},
    {text: "Sermersooq", value: 1132},
    {text: "Qeqertalik", value: 1133},
    {text: "Avannaata", value: 1134},
    {text: "Western", value: 1135},
    {text: "Upper River", value: 1136},
    {text: "North Bank", value: 1137},
    {text: "Central River", value: 1138},
    {text: "Lower River", value: 1139},
    {text: "Banjul", value: 1140},
    {text: "Conakry", value: 1141},
    {text: "Boke", value: 1142},
    {text: "Faranah", value: 1143},
    {text: "Kankan", value: 1144},
    {text: "Kindia", value: 1145},
    {text: "Labe", value: 1146},
    {text: "Mamou", value: 1147},
    {text: "Nzerekore", value: 1148},
    {text: "Guadeloupe", value: 1149},
    {text: "Annobon", value: 1150},
    {text: "Bioko Norte", value: 1151},
    {text: "Bioko Sur", value: 1152},
    {text: "Centro Sur", value: 1153},
    {text: "Kié-Ntem", value: 1154},
    {text: "Litoral", value: 1155},
    {text: "Wele-Nzas", value: 1156},
    {text: "Djibloho", value: 1157},
    {text: "Mount Athos", value: 1158},
    {text: "Attica", value: 1159},
    {text: "Central Greece", value: 1160},
    {text: "Central Macedonia", value: 1161},
    {text: "Crete", value: 1162},
    {text: "East Macedonia and Thrace", value: 1163},
    {text: "Epirus", value: 1164},
    {text: "Ionian Islands", value: 1165},
    {text: "North Aegean", value: 1166},
    {text: "Peloponnese", value: 1167},
    {text: "South Aegean", value: 1168},
    {text: "Thessaly", value: 1169},
    {text: "West Greece", value: 1170},
    {text: "West Macedonia", value: 1171},
    {text: "Zacapa", value: 1172},
    {text: "Totonicapán", value: 1173},
    {text: "Suchitepeque", value: 1174},
    {text: "Sololá", value: 1175},
    {text: "Santa Rosa", value: 1176},
    {text: "San Marcos", value: 1177},
    {text: "Sacatepéquez", value: 1178},
    {text: "Retalhuleu", value: 1179},
    {text: "Quiché", value: 1180},
    {text: "Quetzaltenango", value: 1181},
    {text: "Petén", value: 1182},
    {text: "Jutiapa", value: 1183},
    {text: "Jalapa", value: 1184},
    {text: "Izabal", value: 1185},
    {text: "Huehuetenango", value: 1186},
    {text: "Guatemala", value: 1187},
    {text: "Escuintla", value: 1188},
    {text: "El Progreso", value: 1189},
    {text: "Chiquimula", value: 1190},
    {text: "Chimaltenango", value: 1191},
    {text: "Baja Verapaz", value: 1192},
    {text: "Alta Verapaz", value: 1193},
    {text: "Piti", value: 1194},
    {text: "Santa Rita", value: 1195},
    {text: "Sinajana", value: 1196},
    {text: "Talofofo", value: 1197},
    {text: "Tamuning", value: 1198},
    {text: "Umatac", value: 1199},
    {text: "Yigo", value: 1200},
    {text: "Yona", value: 1201},
    {text: "Merizo", value: 1202},
    {text: "Mangilao", value: 1203},
    {text: "Agana Heights", value: 1204},
    {text: "Chalan Pago-Ordot", value: 1205},
    {text: "Asan", value: 1206},
    {text: "Agat", value: 1207},
    {text: "Dededo", value: 1208},
    {text: "Barrigada", value: 1209},
    {text: "Hagatna", value: 1210},
    {text: "Inarajan", value: 1211},
    {text: "Mongmong-Toto-Maite", value: 1212},
    {text: "Tombali", value: 1213},
    {text: "Quinara", value: 1214},
    {text: "Oio", value: 1215},
    {text: "Gabú", value: 1216},
    {text: "Cacheu", value: 1217},
    {text: "Bolama", value: 1218},
    {text: "Bissau", value: 1219},
    {text: "Biombo", value: 1220},
    {text: "Bafatá", value: 1221},
    {text: "Upper Takutu-Upper Essequibo", value: 1222},
    {text: "Upper Demerara-Berbice", value: 1223},
    {text: "Potaro-Siparuni", value: 1224},
    {text: "Pomeroon-Supenaam", value: 1225},
    {text: "Mahaica-Berbice", value: 1226},
    {text: "Essequibo Islands-West Demerara", value: 1227},
    {text: "East Berbice-Corentyne", value: 1228},
    {text: "Demerara-Mahaica", value: 1229},
    {text: "Cuyuni-Mazaruni", value: 1230},
    {text: "Barima-Waini", value: 1231},
    {text: "Yuen Long", value: 1232},
    {text: "Tsuen Wan", value: 1233},
    {text: "Tai Po", value: 1234},
    {text: "Sai Kung", value: 1235},
    {text: "Islands", value: 1236},
    {text: "Central and Western", value: 1237},
    {text: "Wan Chai", value: 1238},
    {text: "Eastern", value: 1239},
    {text: "Southern", value: 1240},
    {text: "Yau Tsim Mong", value: 1241},
    {text: "Sham Shui Po", value: 1242},
    {text: "Kowloon City", value: 1243},
    {text: "Wong Tai Sin", value: 1244},
    {text: "Kwun Tong", value: 1245},
    {text: "Kwai Tsing", value: 1246},
    {text: "Tuen Mun", value: 1247},
    {text: "North", value: 1248},
    {text: "Sha Tin", value: 1249},
    {text: "Yoro", value: 1250},
    {text: "Valle", value: 1251},
    {text: "Santa Bárbara", value: 1252},
    {text: "Olancho", value: 1253},
    {text: "Ocotepeque", value: 1254},
    {text: "Lempira", value: 1255},
    {text: "La Paz", value: 1256},
    {text: "Bay Islands", value: 1257},
    {text: "Intibucá", value: 1258},
    {text: "Gracias a Dios", value: 1259},
    {text: "Francisco Morazán", value: 1260},
    {text: "El Paraíso", value: 1261},
    {text: "Cortés", value: 1262},
    {text: "Copán", value: 1263},
    {text: "Comayagua", value: 1264},
    {text: "Colón", value: 1265},
    {text: "Choluteca", value: 1266},
    {text: "Atlántida", value: 1267},
    {text: "Bjelovarsko-Bilogorska", value: 1268},
    {text: "Slavonski Brod-Posavina", value: 1269},
    {text: "Dubrovacko-Neretvanska", value: 1270},
    {text: "Istria", value: 1271},
    {text: "Karlovacka", value: 1272},
    {text: "Koprivnicko-Križevacka", value: 1273},
    {text: "Krapinsko-Zagorska", value: 1274},
    {text: "Licko-Senjska", value: 1275},
    {text: "Medimurska", value: 1276},
    {text: "Osjecko-Baranjska", value: 1277},
    {text: "Požeško-Slavonska", value: 1278},
    {text: "Primorsko-Goranska", value: 1279},
    {text: "Šibensko-Kniniska", value: 1280},
    {text: "Sisacko-Moslavacka", value: 1281},
    {text: "Split-Dalmatia", value: 1282},
    {text: "Varaždinska", value: 1283},
    {text: "Vukovar-Sirmium", value: 1284},
    {text: "Zadarska", value: 1285},
    {text: "Zagrebacka", value: 1286},
    {text: "City of Zagreb", value: 1287},
    {text: "Virovitick-Podravska", value: 1288},
    {text: "Sud-Est", value: 1289},
    {text: "Sud", value: 1290},
    {text: "Ouest", value: 1291},
    {text: "Nord-Ouest", value: 1292},
    {text: "Nord-Est", value: 1293},
    {text: "Nord", value: 1294},
    {text: "Grand'Anse", value: 1295},
    {text: "Centre", value: 1296},
    {text: "Artibonite", value: 1297},
    {text: "Nippes", value: 1298},
    {text: "Szabolcs-Szatmár-Bereg", value: 1299},
    {text: "Jász-Nagykun-Szolnok", value: 1300},
    {text: "Heves", value: 1301},
    {text: "Hajdú-Bihar", value: 1302},
    {text: "Csongrád", value: 1303},
    {text: "Borsod-Abaúj-Zemplén", value: 1304},
    {text: "Bekes County", value: 1305},
    {text: "Zala", value: 1306},
    {text: "Veszprém", value: 1307},
    {text: "Vas", value: 1308},
    {text: "Tolna", value: 1309},
    {text: "Somogy", value: 1310},
    {text: "Pest", value: 1311},
    {text: "Nógrád", value: 1312},
    {text: "Komárom-Esztergom", value: 1313},
    {text: "Gyor-Moson-Sopron", value: 1314},
    {text: "Fejér", value: 1315},
    {text: "Budapest", value: 1316},
    {text: "Baranya", value: 1317},
    {text: "Bács-Kiskun", value: 1318},
    {text: "North Sumatra", value: 1319},
    {text: "Aceh", value: 1320},
    {text: "Yogyakarta", value: 1321},
    {text: "South Sumatra", value: 1322},
    {text: "West Sumatra", value: 1323},
    {text: "North Sulawesi", value: 1324},
    {text: "Southeast Sulawesi", value: 1325},
    {text: "Central Sulawesi", value: 1326},
    {text: "South Sulawesi", value: 1327},
    {text: "Riau", value: 1328},
    {text: "East Nusa Tenggara", value: 1329},
    {text: "West Nusa Tenggara", value: 1330},
    {text: "Maluku", value: 1331},
    {text: "Lampung", value: 1332},
    {text: "East Kalimantan", value: 1333},
    {text: "Central Kalimantan", value: 1334},
    {text: "South Kalimantan", value: 1335},
    {text: "West Kalimantan", value: 1336},
    {text: "East Java", value: 1337},
    {text: "Central Java", value: 1338},
    {text: "West Java", value: 1339},
    {text: "Jambi", value: 1340},
    {text: "Jakarta", value: 1341},
    {text: "Papua", value: 1342},
    {text: "Bengkulu", value: 1343},
    {text: "Bali", value: 1344},
    {text: "Banten", value: 1345},
    {text: "Gorontalo", value: 1346},
    {text: "Bangka–Belitung Islands", value: 1347},
    {text: "North Maluku", value: 1348},
    {text: "West Papua", value: 1349},
    {text: "West Sulawesi", value: 1350},
    {text: "Riau Islands", value: 1351},
    {text: "North Kalimantan", value: 1352},
    {text: "Connaught", value: 1353},
    {text: "Leinster", value: 1354},
    {text: "Munster", value: 1355},
    {text: "Ulster", value: 1356},
    {text: "Jerusalem", value: 1357},
    {text: "Tel Aviv", value: 1358},
    {text: "Haifa", value: 1359},
    {text: "Northern District", value: 1360},
    {text: "Central District", value: 1361},
    {text: "Southern District", value: 1362},
    {text: "Judea and Samaria Area", value: 1363},
    {text: "Andreas", value: 1364},
    {text: "Arbory", value: 1365},
    {text: "Ballaugh", value: 1366},
    {text: "Braddan", value: 1367},
    {text: "Bride", value: 1368},
    {text: "Castletown", value: 1369},
    {text: "Douglas", value: 1370},
    {text: "German", value: 1371},
    {text: "Jurby", value: 1372},
    {text: "Laxey", value: 1373},
    {text: "Lezayre", value: 1374},
    {text: "Lonan", value: 1375},
    {text: "Malew", value: 1376},
    {text: "Marown", value: 1377},
    {text: "Maughold", value: 1378},
    {text: "Michael", value: 1379},
    {text: "Onchan", value: 1380},
    {text: "Patrick", value: 1381},
    {text: "Peel", value: 1382},
    {text: "Port Erin", value: 1383},
    {text: "Port St Mary", value: 1384},
    {text: "Ramsey", value: 1385},
    {text: "Rushen", value: 1386},
    {text: "Santon", value: 1387},
    {text: "West Bengal", value: 1388},
    {text: "Uttar Pradesh", value: 1389},
    {text: "Tripura", value: 1390},
    {text: "Telangana", value: 1391},
    {text: "Tamil Nadu", value: 1392},
    {text: "Sikkim", value: 1393},
    {text: "Rajasthan", value: 1394},
    {text: "Punjab", value: 1395},
    {text: "Puducherry", value: 1396},
    {text: "Odisha", value: 1397},
    {text: "Nagaland", value: 1398},
    {text: "Mizoram", value: 1399},
    {text: "Meghalaya", value: 1400},
    {text: "Manipur", value: 1401},
    {text: "Maharashtra", value: 1402},
    {text: "Madhya Pradesh", value: 1403},
    {text: "Laccadives", value: 1404},
    {text: "Kerala", value: 1405},
    {text: "Karnataka", value: 1406},
    {text: "Jammu and Kashmir", value: 1407},
    {text: "Himachal Pradesh", value: 1408},
    {text: "Haryana", value: 1409},
    {text: "Gujarat", value: 1410},
    {text: "Goa", value: 1411},
    {text: "Delhi", value: 1412},
    {text: "Chandigarh", value: 1413},
    {text: "Bihar", value: 1414},
    {text: "Assam", value: 1415},
    {text: "Arunachal Pradesh", value: 1416},
    {text: "Andhra Pradesh", value: 1417},
    {text: "Andaman and Nicobar", value: 1418},
    {text: "Chhattisgarh", value: 1419},
    {text: "Jharkhand", value: 1420},
    {text: "Uttarakhand", value: 1421},
    {text: "Ladakh", value: 1422},
    {text: "Dadra and Nagar Haveli and Daman and Diu", value: 1423},
    {text: "Basra", value: 1424},
    {text: "Wasit", value: 1425},
    {text: "Salah ad Din", value: 1426},
    {text: "Nineveh", value: 1427},
    {text: "Maysan", value: 1428},
    {text: "Karbala'", value: 1429},
    {text: "Arbil", value: 1430},
    {text: "Diyalá", value: 1431},
    {text: "Dhi Qar", value: 1432},
    {text: "Duhok", value: 1433},
    {text: "Baghdad", value: 1434},
    {text: "Babil", value: 1435},
    {text: "Kirkuk", value: 1436},
    {text: "Sulaymaniyah", value: 1437},
    {text: "An Najaf", value: 1438},
    {text: "Al Qadisiyah", value: 1439},
    {text: "Al Muthanná", value: 1440},
    {text: "Al Anbar", value: 1441},
    {text: "Halabja Governorate", value: 1442},
    {text: "Tehran", value: 1443},
    {text: "Zanjan", value: 1444},
    {text: "Yazd", value: 1445},
    {text: "Semnan", value: 1446},
    {text: "Mazandaran", value: 1447},
    {text: "Markazi", value: 1448},
    {text: "Lorestan Province", value: 1449},
    {text: "Kordestan", value: 1450},
    {text: "Kohgiluyeh and Boyer-Ahmad", value: 1451},
    {text: "Khuzestan", value: 1452},
    {text: "Kermanshah", value: 1453},
    {text: "Kerman", value: 1454},
    {text: "Ilam Province", value: 1455},
    {text: "Hormozgan", value: 1456},
    {text: "Hamadan", value: 1457},
    {text: "Gilan", value: 1458},
    {text: "Fars", value: 1459},
    {text: "Chaharmahal and Bakhtiari", value: 1460},
    {text: "Bushehr", value: 1461},
    {text: "East Azerbaijan", value: 1462},
    {text: "West Azerbaijan", value: 1463},
    {text: "Ardabil", value: 1464},
    {text: "Isfahan", value: 1465},
    {text: "Golestan", value: 1466},
    {text: "Qazvin", value: 1467},
    {text: "Qom", value: 1468},
    {text: "Sistan and Baluchestan", value: 1469},
    {text: "South Khorasan Province", value: 1470},
    {text: "Razavi Khorasan", value: 1471},
    {text: "North Khorasan", value: 1472},
    {text: "Alborz Province", value: 1473},
    {text: "Northwest", value: 1474},
    {text: "Northeast", value: 1475},
    {text: "East", value: 1476},
    {text: "South", value: 1477},
    {text: "Capital Region", value: 1478},
    {text: "Southern Peninsula", value: 1479},
    {text: "West", value: 1480},
    {text: "Westfjords", value: 1481},
    {text: "Sicily", value: 1482},
    {text: "Sardinia", value: 1483},
    {text: "Calabria", value: 1484},
    {text: "Veneto", value: 1485},
    {text: "Aosta Valley", value: 1486},
    {text: "Umbria", value: 1487},
    {text: "Trentino-Alto Adige", value: 1488},
    {text: "Tuscany", value: 1489},
    {text: "Apulia", value: 1490},
    {text: "Piedmont", value: 1491},
    {text: "Molise", value: 1492},
    {text: "The Marches", value: 1493},
    {text: "Lombardy", value: 1494},
    {text: "Liguria", value: 1495},
    {text: "Latium", value: 1496},
    {text: "Friuli Venezia Giulia", value: 1497},
    {text: "Emilia-Romagna", value: 1498},
    {text: "Campania", value: 1499},
    {text: "Basilicate", value: 1500},
    {text: "Abruzzo", value: 1501},
    {text: "St Clement", value: 1502},
    {text: "St Saviour", value: 1503},
    {text: "St. Brelade", value: 1504},
    {text: "Grouville", value: 1505},
    {text: "St Mary", value: 1506},
    {text: "St Lawrence", value: 1507},
    {text: "St Peter", value: 1508},
    {text: "St Ouen", value: 1509},
    {text: "St John", value: 1510},
    {text: "Trinity", value: 1511},
    {text: "St Martîn", value: 1512},
    {text: "St Helier", value: 1513},
    {text: "Westmoreland", value: 1514},
    {text: "Trelawny", value: 1515},
    {text: "St. Thomas", value: 1516},
    {text: "St. Mary", value: 1517},
    {text: "St. James", value: 1518},
    {text: "St. Elizabeth", value: 1519},
    {text: "Saint Catherine", value: 1520},
    {text: "St Ann", value: 1521},
    {text: "St. Andrew", value: 1522},
    {text: "Portland", value: 1523},
    {text: "Manchester", value: 1524},
    {text: "Kingston", value: 1525},
    {text: "Hanover", value: 1526},
    {text: "Clarendon", value: 1527},
    {text: "Ma’an", value: 1528},
    {text: "Irbid", value: 1529},
    {text: "Zarqa", value: 1530},
    {text: "Tafielah", value: 1531},
    {text: "Amman", value: 1532},
    {text: "Mafraq", value: 1533},
    {text: "Karak", value: 1534},
    {text: "Balqa", value: 1535},
    {text: "Ajlun", value: 1536},
    {text: "Jerash", value: 1537},
    {text: "Aqaba", value: 1538},
    {text: "Madaba", value: 1539},
    {text: "Yamanashi", value: 1540},
    {text: "Yamaguchi", value: 1541},
    {text: "Wakayama", value: 1542},
    {text: "Toyama", value: 1543},
    {text: "Tottori", value: 1544},
    {text: "Tokyo", value: 1545},
    {text: "Tokushima", value: 1546},
    {text: "Tochigi", value: 1547},
    {text: "Shizuoka", value: 1548},
    {text: "Shimane", value: 1549},
    {text: "Shiga", value: 1550},
    {text: "Saitama", value: 1551},
    {text: "Saga", value: 1552},
    {text: "Osaka", value: 1553},
    {text: "Okinawa", value: 1554},
    {text: "Okayama", value: 1555},
    {text: "Oita", value: 1556},
    {text: "Niigata", value: 1557},
    {text: "Nara", value: 1558},
    {text: "Nagasaki", value: 1559},
    {text: "Nagano", value: 1560},
    {text: "Miyazaki", value: 1561},
    {text: "Mie", value: 1562},
    {text: "Kyoto", value: 1563},
    {text: "Kumamoto", value: 1564},
    {text: "Kochi", value: 1565},
    {text: "Kanagawa", value: 1566},
    {text: "Kagoshima", value: 1567},
    {text: "Kagawa", value: 1568},
    {text: "Ishikawa", value: 1569},
    {text: "Hyogo", value: 1570},
    {text: "Hiroshima", value: 1571},
    {text: "Gunma", value: 1572},
    {text: "Gifu", value: 1573},
    {text: "Fukuoka", value: 1574},
    {text: "Fukui", value: 1575},
    {text: "Ehime", value: 1576},
    {text: "Aichi", value: 1577},
    {text: "Yamagata", value: 1578},
    {text: "Miyagi", value: 1579},
    {text: "Iwate", value: 1580},
    {text: "Ibaraki", value: 1581},
    {text: "Fukushima", value: 1582},
    {text: "Chiba", value: 1583},
    {text: "Akita", value: 1584},
    {text: "Hokkaido", value: 1585},
    {text: "Aomori", value: 1586},
    {text: "West Pokot", value: 1587},
    {text: "Wajir", value: 1588},
    {text: "Uasin Gishu", value: 1589},
    {text: "Turkana", value: 1590},
    {text: "Trans Nzoia", value: 1591},
    {text: "Tharaka - Nithi", value: 1592},
    {text: "Tana River", value: 1593},
    {text: "Siaya", value: 1594},
    {text: "Samburu", value: 1595},
    {text: "Nairobi Area", value: 1596},
    {text: "Murang'A", value: 1597},
    {text: "Mombasa", value: 1598},
    {text: "Meru", value: 1599},
    {text: "Marsabit", value: 1600},
    {text: "Mandera", value: 1601},
    {text: "Laikipia", value: 1602},
    {text: "Kwale", value: 1603},
    {text: "Kitui", value: 1604},
    {text: "Kisumu", value: 1605},
    {text: "Kisii", value: 1606},
    {text: "Kirinyaga", value: 1607},
    {text: "Kilifi", value: 1608},
    {text: "Kiambu", value: 1609},
    {text: "Kericho", value: 1610},
    {text: "Kakamega", value: 1611},
    {text: "Isiolo", value: 1612},
    {text: "Garissa", value: 1613},
    {text: "Embu", value: 1614},
    {text: "Busia", value: 1615},
    {text: "Bungoma", value: 1616},
    {text: "Baringo", value: 1617},
    {text: "Nyandarua", value: 1618},
    {text: "Vihiga", value: 1619},
    {text: "Lamu", value: 1620},
    {text: "Machakos", value: 1621},
    {text: "Makueni", value: 1622},
    {text: "Elegeyo-Marakwet", value: 1623},
    {text: "Taita Taveta", value: 1624},
    {text: "Kajiado", value: 1625},
    {text: "Nyeri", value: 1626},
    {text: "Homa Bay", value: 1627},
    {text: "Bomet", value: 1628},
    {text: "Migori", value: 1629},
    {text: "Nakuru", value: 1630},
    {text: "Narok", value: 1631},
    {text: "Nyamira", value: 1632},
    {text: "Nandi", value: 1633},
    {text: "Osh", value: 1634},
    {text: "Batken", value: 1635},
    {text: "Talas", value: 1636},
    {text: "Naryn", value: 1637},
    {text: "Issyk-Kul", value: 1638},
    {text: "Bishkek", value: 1639},
    {text: "Jalal-Abad", value: 1640},
    {text: "Chüy", value: 1641},
    {text: "Osh City", value: 1642},
    {text: "Pursat", value: 1643},
    {text: "Battambang", value: 1644},
    {text: "Takeo", value: 1645},
    {text: "Svay Rieng", value: 1646},
    {text: "Stung Treng", value: 1647},
    {text: "Otâr Méanchey", value: 1648},
    {text: "Siem Reap", value: 1649},
    {text: "Ratanakiri", value: 1650},
    {text: "Prey Veng", value: 1651},
    {text: "Preah Vihear", value: 1652},
    {text: "Phnom Penh", value: 1653},
    {text: "Pailin", value: 1654},
    {text: "Mondolkiri", value: 1655},
    {text: "Kratie", value: 1656},
    {text: "Kep", value: 1657},
    {text: "Koh Kong", value: 1658},
    {text: "Kandal", value: 1659},
    {text: "Kampot", value: 1660},
    {text: "Kampong Thom", value: 1661},
    {text: "Kampong Speu", value: 1662},
    {text: "Kampong Chhnang", value: 1663},
    {text: "Kampong Cham", value: 1664},
    {text: "Preah Sihanouk", value: 1665},
    {text: "Banteay Meanchey", value: 1666},
    {text: "Tboung Khmum", value: 1667},
    {text: "Gilbert Islands", value: 1668},
    {text: "Line Islands", value: 1669},
    {text: "Phoenix Islands", value: 1670},
    {text: "Mohéli", value: 1671},
    {text: "Grande Comore", value: 1672},
    {text: "Anjouan", value: 1673},
    {text: "Trinity Palmetto Point", value: 1674},
    {text: "Middle Island", value: 1675},
    {text: "Saint Thomas Lowland", value: 1676},
    {text: "Saint Peter Basseterre", value: 1677},
    {text: "Saint Paul Charlestown", value: 1678},
    {text: "Saint Paul Capesterre", value: 1679},
    {text: "Saint Mary Cayon", value: 1680},
    {text: "Saint John Figtree", value: 1681},
    {text: "Saint John Capesterre", value: 1682},
    {text: "Saint James Windwa", value: 1683},
    {text: "Saint George Gingerland", value: 1684},
    {text: "Saint George Basseterre", value: 1685},
    {text: "Saint Anne Sandy Point", value: 1686},
    {text: "Christ Church Nichola Town", value: 1687},
    {text: "Pyongyang", value: 1688},
    {text: "South Pyongan", value: 1689},
    {text: "P'yongan-bukto", value: 1690},
    {text: "Kangwon-do", value: 1691},
    {text: "Hwanghae-namdo", value: 1692},
    {text: "Hwanghae-bukto", value: 1693},
    {text: "Hamgyong-namdo", value: 1694},
    {text: "Yanggang-do", value: 1695},
    {text: "Hamgyong-bukto", value: 1696},
    {text: "Chagang-do", value: 1697},
    {text: "Rason", value: 1698},
    {text: "Ulsan", value: 1699},
    {text: "Daejeon", value: 1700},
    {text: "Daegu", value: 1701},
    {text: "Seoul", value: 1702},
    {text: "Busan", value: 1703},
    {text: "Gyeongsangbuk-do", value: 1704},
    {text: "Gyeonggi-do", value: 1705},
    {text: "Gwangju", value: 1706},
    {text: "Gangwon-do", value: 1707},
    {text: "Incheon", value: 1708},
    {text: "Chungcheongnam-do", value: 1709},
    {text: "North Chungcheong", value: 1710},
    {text: "Jeollanam-do", value: 1711},
    {text: "Jeollabuk-do", value: 1712},
    {text: "Jeju-do", value: 1713},
    {text: "Gyeongsangnam-do", value: 1714},
    {text: "Sejong-si", value: 1715},
    {text: "Hawalli", value: 1716},
    {text: "Al Asimah", value: 1717},
    {text: "Al Jahra'", value: 1718},
    {text: "Al Farwaniyah", value: 1719},
    {text: "Al A?madi", value: 1720},
    {text: "Mubarak al Kabir", value: 1721},
    {text: "George Town", value: 1722},
    {text: "West Bay", value: 1723},
    {text: "Bodden Town", value: 1724},
    {text: "North Side", value: 1725},
    {text: "East End", value: 1726},
    {text: "Sister Island", value: 1727},
    {text: "Batys Qazaqstan", value: 1728},
    {text: "Mangghystau", value: 1729},
    {text: "Atyrau", value: 1730},
    {text: "Aqtöbe", value: 1731},
    {text: "East Kazakhstan", value: 1732},
    {text: "Aqmola", value: 1733},
    {text: "North Kazakhstan", value: 1734},
    {text: "Pavlodar Region", value: 1735},
    {text: "Qyzylorda", value: 1736},
    {text: "Qostanay", value: 1737},
    {text: "Karaganda", value: 1738},
    {text: "Zhambyl", value: 1739},
    {text: "South Kazakhstan", value: 1740},
    {text: "Almaty", value: 1741},
    {text: "Almaty Oblysy", value: 1742},
    {text: "Shymkent", value: 1743},
    {text: "Baikonur", value: 1744},
    {text: "Nur-Sultan", value: 1745},
    {text: "Xiangkhoang", value: 1746},
    {text: "Xiagnabouli", value: 1747},
    {text: "Vientiane", value: 1748},
    {text: "Savannahkhét", value: 1749},
    {text: "Salavan", value: 1750},
    {text: "Phôngsali", value: 1751},
    {text: "Oudômxai", value: 1752},
    {text: "Louangphabang", value: 1753},
    {text: "Loungnamtha", value: 1754},
    {text: "Khammouan", value: 1755},
    {text: "Houaphan", value: 1756},
    {text: "Champasak", value: 1757},
    {text: "Attapu", value: 1758},
    {text: "Xékong", value: 1759},
    {text: "Bokeo", value: 1760},
    {text: "Bolikhamsai", value: 1761},
    {text: "Vientiane Prefecture", value: 1762},
    {text: "Xaisomboun", value: 1763},
    {text: "Mont-Liban", value: 1764},
    {text: "Beyrouth", value: 1765},
    {text: "Liban-Nord", value: 1766},
    {text: "South Governorate", value: 1767},
    {text: "Béqaa", value: 1768},
    {text: "Nabatîyé", value: 1769},
    {text: "Aakkâr", value: 1770},
    {text: "Baalbek-Hermel", value: 1771},
    {text: "Vieux-Fort", value: 1772},
    {text: "Soufrière", value: 1773},
    {text: "Micoud", value: 1774},
    {text: "Laborie", value: 1775},
    {text: "Gros-Islet", value: 1776},
    {text: "Dennery", value: 1777},
    {text: "Choiseul", value: 1778},
    {text: "Castries", value: 1779},
    {text: "Anse-la-Raye", value: 1780},
    {text: "Canaries", value: 1781},
    {text: "Vaduz", value: 1782},
    {text: "Triesenberg", value: 1783},
    {text: "Triesen", value: 1784},
    {text: "Schellenberg", value: 1785},
    {text: "Schaan", value: 1786},
    {text: "Ruggell", value: 1787},
    {text: "Planken", value: 1788},
    {text: "Mauren", value: 1789},
    {text: "Gamprin", value: 1790},
    {text: "Eschen", value: 1791},
    {text: "Balzers", value: 1792},
    {text: "Western", value: 1793},
    {text: "Uva", value: 1794},
    {text: "Southern", value: 1795},
    {text: "Sabaragamuwa", value: 1796},
    {text: "North Western", value: 1797},
    {text: "North Central", value: 1798},
    {text: "Central", value: 1799},
    {text: "Northern Province", value: 1800},
    {text: "Eastern Province", value: 1801},
    {text: "Sinoe", value: 1802},
    {text: "Nimba", value: 1803},
    {text: "Montserrado", value: 1804},
    {text: "Maryland", value: 1805},
    {text: "Lofa", value: 1806},
    {text: "Grand Gedeh", value: 1807},
    {text: "Grand Cape Mount", value: 1808},
    {text: "Grand Bassa", value: 1809},
    {text: "Bong", value: 1810},
    {text: "Bomi", value: 1811},
    {text: "Grand Kru", value: 1812},
    {text: "Margibi", value: 1813},
    {text: "River Cess", value: 1814},
    {text: "Gbarpolu", value: 1815},
    {text: "River Gee", value: 1816},
    {text: "Thaba-Tseka", value: 1817},
    {text: "Quthing", value: 1818},
    {text: "Qacha's Nek", value: 1819},
    {text: "Mokhotlong", value: 1820},
    {text: "Mohale's Hoek", value: 1821},
    {text: "Maseru", value: 1822},
    {text: "Mafeteng", value: 1823},
    {text: "Leribe", value: 1824},
    {text: "Butha-Buthe", value: 1825},
    {text: "Berea", value: 1826},
    {text: "Alytus", value: 1827},
    {text: "Kaunas", value: 1828},
    {text: "Klaipeda County", value: 1829},
    {text: "Marijampole County", value: 1830},
    {text: "Panevežys", value: 1831},
    {text: "Siauliai", value: 1832},
    {text: "Taurage County", value: 1833},
    {text: "Telsiai", value: 1834},
    {text: "Utena", value: 1835},
    {text: "Vilnius", value: 1836},
    {text: "Wiltz", value: 1837},
    {text: "Vianden", value: 1838},
    {text: "Remich", value: 1839},
    {text: "Redange", value: 1840},
    {text: "Mersch", value: 1841},
    {text: "Luxembourg", value: 1842},
    {text: "Grevenmacher", value: 1843},
    {text: "Esch-sur-Alzette", value: 1844},
    {text: "Echternach", value: 1845},
    {text: "Diekirch", value: 1846},
    {text: "Clervaux", value: 1847},
    {text: "Capellen", value: 1848},
    {text: "Ventspils Rajons", value: 1849},
    {text: "Ventspils", value: 1850},
    {text: "Valmiera", value: 1851},
    {text: "Valka", value: 1852},
    {text: "Tukuma novads", value: 1853},
    {text: "Talsi Municipality", value: 1854},
    {text: "Saldus Rajons", value: 1855},
    {text: "Riga", value: 1856},
    {text: "Rezeknes Novads", value: 1857},
    {text: "Rezekne", value: 1858},
    {text: "Preilu novads", value: 1859},
    {text: "Ogre", value: 1860},
    {text: "Madona Municipality", value: 1861},
    {text: "Ludzas novads", value: 1862},
    {text: "Limbažu novads", value: 1863},
    {text: "Liepaja", value: 1864},
    {text: "Kuldigas novads", value: 1865},
    {text: "Kraslavas novads", value: 1866},
    {text: "Jurmala", value: 1867},
    {text: "Jelgavas novads", value: 1868},
    {text: "Jelgava", value: 1869},
    {text: "Jekabpils Municipality", value: 1870},
    {text: "Gulbenes novads", value: 1871},
    {text: "Dobeles novads", value: 1872},
    {text: "Daugavpils", value: 1873},
    {text: "Cesu Rajons", value: 1874},
    {text: "Bauskas Rajons", value: 1875},
    {text: "Balvu Novads", value: 1876},
    {text: "Aluksnes Novads", value: 1877},
    {text: "Aizkraukles novads", value: 1878},
    {text: "Marupe", value: 1879},
    {text: "Olaine", value: 1880},
    {text: "Kekava", value: 1881},
    {text: "Salaspils", value: 1882},
    {text: "Adaži", value: 1883},
    {text: "Livani", value: 1884},
    {text: "Varaklani", value: 1885},
    {text: "Ropažu", value: 1886},
    {text: "Sigulda", value: 1887},
    {text: "Saulkrastu", value: 1888},
    {text: "Smiltene", value: 1889},
    {text: "South Kurzeme Municipality", value: 1890},
    {text: "Augšdaugava Municipality", value: 1891},
    {text: "Darnah", value: 1892},
    {text: "Banghazi", value: 1893},
    {text: "Al Marj", value: 1894},
    {text: "Al Kufrah", value: 1895},
    {text: "Al Jabal al Akh?ar", value: 1896},
    {text: "Tripoli", value: 1897},
    {text: "Surt", value: 1898},
    {text: "Sabha", value: 1899},
    {text: "Nalut", value: 1900},
    {text: "Murzuq", value: 1901},
    {text: "Misratah", value: 1902},
    {text: "Ghat", value: 1903},
    {text: "Az Zawiyah", value: 1904},
    {text: "Ash Shati'", value: 1905},
    {text: "Al Jufrah", value: 1906},
    {text: "An Nuqat al Khams", value: 1907},
    {text: "Al Butnan", value: 1908},
    {text: "Jabal al Gharbi", value: 1909},
    {text: "Al Jafarah", value: 1910},
    {text: "Al Marqab", value: 1911},
    {text: "Al Wa?at", value: 1912},
    {text: "Wadi al ?ayat", value: 1913},
    {text: "Tanger-Tetouan-Al Hoceima", value: 1914},
    {text: "Oriental", value: 1915},
    {text: "Fès-Meknès", value: 1916},
    {text: "Rabat-Salé-Kénitra", value: 1917},
    {text: "Béni Mellal-Khénifra", value: 1918},
    {text: "Casablanca-Settat", value: 1919},
    {text: "Marrakesh-Safi", value: 1920},
    {text: "Drâa-Tafilalet", value: 1921},
    {text: "Souss-Massa", value: 1922},
    {text: "Guelmim-Oued Noun", value: 1923},
    {text: "Laâyoune-Sakia El Hamra", value: 1924},
    {text: "Dakhla-Oued Ed-Dahab", value: 1925},
    {text: "Municipality of Monaco", value: 1926},
    {text: "Raionul Edinet", value: 1927},
    {text: "Ungheni", value: 1928},
    {text: "Telenesti", value: 1929},
    {text: "Taraclia", value: 1930},
    {text: "Stefan-Voda", value: 1931},
    {text: "Stra?eni", value: 1932},
    {text: "Raionul Soroca", value: 1933},
    {text: "Rîscani", value: 1934},
    {text: "Rezina", value: 1935},
    {text: "Orhei", value: 1936},
    {text: "Raionul Ocnita", value: 1937},
    {text: "Anenii Noi", value: 1938},
    {text: "Nisporeni", value: 1939},
    {text: "Leova", value: 1940},
    {text: "Sîngerei", value: 1941},
    {text: "Criuleni", value: 1942},
    {text: "Ialoveni", value: 1943},
    {text: "Chi?inau Municipality", value: 1944},
    {text: "Causeni", value: 1945},
    {text: "Cantemir", value: 1946},
    {text: "Calarasi", value: 1947},
    {text: "Cahul", value: 1948},
    {text: "Glodeni", value: 1949},
    {text: "Floresti", value: 1950},
    {text: "Fale?ti", value: 1951},
    {text: "Dubasari", value: 1952},
    {text: "Drochia", value: 1953},
    {text: "Donduseni", value: 1954},
    {text: "Cimislia", value: 1955},
    {text: "Briceni", value: 1956},
    {text: "Basarabeasca", value: 1957},
    {text: "Hîncesti", value: 1958},
    {text: "Soldanesti", value: 1959},
    {text: "Transnistria", value: 1960},
    {text: "Gagauzia", value: 1961},
    {text: "Bender Municipality", value: 1962},
    {text: "Balti", value: 1963},
    {text: "Opština Rožaje", value: 1964},
    {text: "Opština Žabljak", value: 1965},
    {text: "Ulcinj", value: 1966},
    {text: "Tivat", value: 1967},
    {text: "Podgorica", value: 1968},
    {text: "Opština Šavnik", value: 1969},
    {text: "Opština Plužine", value: 1970},
    {text: "Pljevlja", value: 1971},
    {text: "Opština Plav", value: 1972},
    {text: "Opština Nikšic", value: 1973},
    {text: "Mojkovac", value: 1974},
    {text: "Kotor", value: 1975},
    {text: "Opština Kolašin", value: 1976},
    {text: "Berane", value: 1977},
    {text: "Herceg Novi", value: 1978},
    {text: "Danilovgrad", value: 1979},
    {text: "Cetinje", value: 1980},
    {text: "Budva", value: 1981},
    {text: "Bijelo Polje", value: 1982},
    {text: "Bar", value: 1983},
    {text: "Andrijevica", value: 1984},
    {text: "Gusinje", value: 1985},
    {text: "Petnjica", value: 1986},
    {text: "Tuzi", value: 1987},
    {text: "Diana", value: 1988},
    {text: "Sava", value: 1989},
    {text: "Sofia", value: 1990},
    {text: "Analanjirofo", value: 1991},
    {text: "Boeny", value: 1992},
    {text: "Betsiboka", value: 1993},
    {text: "Alaotra Mangoro", value: 1994},
    {text: "Melaky", value: 1995},
    {text: "Bongolava", value: 1996},
    {text: "Vakinankaratra", value: 1997},
    {text: "Itasy", value: 1998},
    {text: "Analamanga", value: 1999},
    {text: "Atsinanana", value: 2000},
    {text: "Menabe", value: 2001},
    {text: "Amoron'i Mania", value: 2002},
    {text: "Upper Matsiatra", value: 2003},
    {text: "Vatovavy Fitovinany", value: 2004},
    {text: "Ihorombe", value: 2005},
    {text: "Atsimo-Atsinanana", value: 2006},
    {text: "Anosy", value: 2007},
    {text: "Androy", value: 2008},
    {text: "Atsimo-Andrefana", value: 2009},
    {text: "Ailinginae Atoll", value: 2010},
    {text: "Ailinglaplap Atoll", value: 2011},
    {text: "Ailuk Atoll", value: 2012},
    {text: "Arno Atoll", value: 2013},
    {text: "Aur Atoll", value: 2014},
    {text: "Bikar Atoll", value: 2015},
    {text: "Bikini Atoll", value: 2016},
    {text: "Ebon Atoll", value: 2017},
    {text: "Enewetak Atoll", value: 2018},
    {text: "Erikub Atoll", value: 2019},
    {text: "Jaluit Atoll", value: 2020},
    {text: "Kwajalein Atoll", value: 2021},
    {text: "Lae Atoll", value: 2022},
    {text: "Likiep Atoll", value: 2023},
    {text: "Majuro Atoll", value: 2024},
    {text: "Maloelap Atoll", value: 2025},
    {text: "Mili Atoll", value: 2026},
    {text: "Namdrik Atoll", value: 2027},
    {text: "Namu Atoll", value: 2028},
    {text: "Rongelap Atoll", value: 2029},
    {text: "Rongrik Atoll", value: 2030},
    {text: "Taka Atoll", value: 2031},
    {text: "Bokak Atoll", value: 2032},
    {text: "Ujae Atoll", value: 2033},
    {text: "Ujelang", value: 2034},
    {text: "Utrik Atoll", value: 2035},
    {text: "Wotho Atoll", value: 2036},
    {text: "Wotje Atoll", value: 2037},
    {text: "Jabat Island", value: 2038},
    {text: "Jemo Island", value: 2039},
    {text: "Kili Island", value: 2040},
    {text: "Lib Island", value: 2041},
    {text: "Mejit Island", value: 2042},
    {text: "Valandovo", value: 2043},
    {text: "Resen", value: 2044},
    {text: "Kratovo", value: 2045},
    {text: "Pehchevo", value: 2046},
    {text: "Novo Selo", value: 2047},
    {text: "Bosilovo", value: 2048},
    {text: "Vasilevo", value: 2049},
    {text: "Dojran", value: 2050},
    {text: "Bogdanci", value: 2051},
    {text: "Konche", value: 2052},
    {text: "Makedonska Kamenica", value: 2053},
    {text: "Zrnovci", value: 2054},
    {text: "Karbinci", value: 2055},
    {text: "Demir Kapija", value: 2056},
    {text: "Rosoman", value: 2057},
    {text: "Gradsko", value: 2058},
    {text: "Lozovo", value: 2059},
    {text: "Cešinovo-Obleševo", value: 2060},
    {text: "Novaci", value: 2061},
    {text: "Berovo", value: 2062},
    {text: "Bitola", value: 2063},
    {text: "Mogila", value: 2064},
    {text: "Arachinovo", value: 2065},
    {text: "Bogovinje", value: 2066},
    {text: "Brvenica", value: 2067},
    {text: "Chashka", value: 2068},
    {text: "Centar Zhupa", value: 2069},
    {text: "Chucher Sandevo", value: 2070},
    {text: "Debar", value: 2071},
    {text: "Delchevo", value: 2072},
    {text: "Demir Hisar", value: 2073},
    {text: "Dolneni", value: 2074},
    {text: "Gevgelija", value: 2075},
    {text: "Gostivar", value: 2076},
    {text: "Ilinden", value: 2077},
    {text: "Jegunovce", value: 2078},
    {text: "Kavadarci", value: 2079},
    {text: "Kichevo", value: 2080},
    {text: "Kochani", value: 2081},
    {text: "Kriva Palanka", value: 2082},
    {text: "Krivogashtani", value: 2083},
    {text: "Krushevo", value: 2084},
    {text: "Kumanovo", value: 2085},
    {text: "Lipkovo", value: 2086},
    {text: "Makedonski Brod", value: 2087},
    {text: "Negotino", value: 2088},
    {text: "Ohrid", value: 2089},
    {text: "Petrovec", value: 2090},
    {text: "Plasnica", value: 2091},
    {text: "Prilep", value: 2092},
    {text: "Probishtip", value: 2093},
    {text: "Radovish", value: 2094},
    {text: "Rankovce", value: 2095},
    {text: "Mavrovo and Rostuša", value: 2096},
    {text: "Sopište", value: 2097},
    {text: "Staro Nagorichane", value: 2098},
    {text: "Shtip", value: 2099},
    {text: "Struga", value: 2100},
    {text: "Strumica", value: 2101},
    {text: "Studenichani", value: 2102},
    {text: "Sveti Nikole", value: 2103},
    {text: "Tearce", value: 2104},
    {text: "Tetovo", value: 2105},
    {text: "Veles", value: 2106},
    {text: "Vevchani", value: 2107},
    {text: "Vinica", value: 2108},
    {text: "Vrapchishte", value: 2109},
    {text: "Zelenikovo", value: 2110},
    {text: "Zhelino", value: 2111},
    {text: "Debarca", value: 2112},
    {text: "Grad Skopje", value: 2113},
    {text: "Tombouctou", value: 2114},
    {text: "Sikasso", value: 2115},
    {text: "Ségou", value: 2116},
    {text: "Mopti", value: 2117},
    {text: "Koulikoro", value: 2118},
    {text: "Kayes", value: 2119},
    {text: "Gao", value: 2120},
    {text: "Bamako", value: 2121},
    {text: "Kidal", value: 2122},
    {text: "Taoudénit", value: 2123},
    {text: "Ménaka", value: 2124},
    {text: "Tanintharyi", value: 2125},
    {text: "Shan", value: 2126},
    {text: "Sagain", value: 2127},
    {text: "Rangoon", value: 2128},
    {text: "Rakhine", value: 2129},
    {text: "Bago", value: 2130},
    {text: "Mon", value: 2131},
    {text: "Mandalay", value: 2132},
    {text: "Magway", value: 2133},
    {text: "Kayah", value: 2134},
    {text: "Kayin", value: 2135},
    {text: "Kachin", value: 2136},
    {text: "Ayeyarwady", value: 2137},
    {text: "Chin", value: 2138},
    {text: "Nay Pyi Taw", value: 2139},
    {text: "Uvs", value: 2140},
    {text: "Hovd", value: 2141},
    {text: "Govi-Altay", value: 2142},
    {text: "Dzabkhan", value: 2143},
    {text: "Bayan-Ölgiy", value: 2144},
    {text: "Bayanhongor", value: 2145},
    {text: "Ulaanbaatar", value: 2146},
    {text: "Central Aimak", value: 2147},
    {text: "Sühbaatar", value: 2148},
    {text: "Selenge", value: 2149},
    {text: "Övörhangay", value: 2150},
    {text: "Ömnögovi", value: 2151},
    {text: "Hövsgöl", value: 2152},
    {text: "Hentiy", value: 2153},
    {text: "Middle Govi", value: 2154},
    {text: "East Gobi Aymag", value: 2155},
    {text: "East Aimak", value: 2156},
    {text: "Bulgan", value: 2157},
    {text: "Arhangay", value: 2158},
    {text: "Darhan Uul", value: 2159},
    {text: "Govi-Sumber", value: 2160},
    {text: "Orhon", value: 2161},
    {text: "Nossa Senhora de Fátima", value: 2162},
    {text: "Santo António", value: 2163},
    {text: "São Lázaro", value: 2164},
    {text: "Sé", value: 2165},
    {text: "São Lourenço", value: 2166},
    {text: "Nossa Senhora do Carmo", value: 2167},
    {text: "Cotai", value: 2168},
    {text: "São Francisco Xavier", value: 2169},
    {text: "Rota", value: 2170},
    {text: "Saipan", value: 2171},
    {text: "Tinian", value: 2172},
    {text: "Northern Islands", value: 2173},
    {text: "Martinique", value: 2174},
    {text: "Trarza", value: 2175},
    {text: "Tiris Zemmour", value: 2176},
    {text: "Tagant", value: 2177},
    {text: "Inchiri", value: 2178},
    {text: "Hodh El Gharbi", value: 2179},
    {text: "Hodh Ech Chargi", value: 2180},
    {text: "Guidimaka", value: 2181},
    {text: "Gorgol", value: 2182},
    {text: "Dakhlet Nouadhibou", value: 2183},
    {text: "Brakna", value: 2184},
    {text: "Assaba", value: 2185},
    {text: "Adrar", value: 2186},
    {text: "Nouakchott Ouest", value: 2187},
    {text: "Nouakchott Nord", value: 2188},
    {text: "Nouakchott Sud", value: 2189},
    {text: "Saint Peter", value: 2190},
    {text: "Saint Georges", value: 2191},
    {text: "Saint Anthony", value: 2192},
    {text: "Attard", value: 2193},
    {text: "Balzan", value: 2194},
    {text: "Il-Birgu", value: 2195},
    {text: "Birkirkara", value: 2196},
    {text: "Birzebbuga", value: 2197},
    {text: "Bormla", value: 2198},
    {text: "Dingli", value: 2199},
    {text: "Il-Fgura", value: 2200},
    {text: "Floriana", value: 2201},
    {text: "Il-Fontana", value: 2202},
    {text: "Ghajnsielem", value: 2203},
    {text: "L-Gharb", value: 2204},
    {text: "Hal Gharghur", value: 2205},
    {text: "L-Ghasri", value: 2206},
    {text: "Hal Ghaxaq", value: 2207},
    {text: "Il-Gudja", value: 2208},
    {text: "Il-Gzira", value: 2209},
    {text: "Il-Hamrun", value: 2210},
    {text: "L-Iklin", value: 2211},
    {text: "L-Imdina", value: 2212},
    {text: "L-Imgarr", value: 2213},
    {text: "L-Imqabba", value: 2214},
    {text: "L-Imsida", value: 2215},
    {text: "Mtarfa", value: 2216},
    {text: "Senglea", value: 2217},
    {text: "Il-Kalkara", value: 2218},
    {text: "Ta’ Kercem", value: 2219},
    {text: "Kirkop", value: 2220},
    {text: "Lija", value: 2221},
    {text: "Luqa", value: 2222},
    {text: "Il-Marsa", value: 2223},
    {text: "Marsaskala", value: 2224},
    {text: "Marsaxlokk", value: 2225},
    {text: "Il-Mellieha", value: 2226},
    {text: "Il-Mosta", value: 2227},
    {text: "Il-Munxar", value: 2228},
    {text: "In-Nadur", value: 2229},
    {text: "In-Naxxar", value: 2230},
    {text: "Paola", value: 2231},
    {text: "Pembroke", value: 2232},
    {text: "Tal-Pietà", value: 2233},
    {text: "Il-Qala", value: 2234},
    {text: "Qormi", value: 2235},
    {text: "Il-Qrendi", value: 2236},
    {text: "Ir-Rabat", value: 2237},
    {text: "Victoria", value: 2238},
    {text: "Safi", value: 2239},
    {text: "Saint John", value: 2240},
    {text: "Saint Julian", value: 2241},
    {text: "Saint Lawrence", value: 2242},
    {text: "Saint Lucia", value: 2243},
    {text: "Saint Paul’s Bay", value: 2244},
    {text: "Saint Venera", value: 2245},
    {text: "Sannat", value: 2246},
    {text: "Is-Siggiewi", value: 2247},
    {text: "Tas-Sliema", value: 2248},
    {text: "Is-Swieqi", value: 2249},
    {text: "Tarxien", value: 2250},
    {text: "Ta’ Xbiex", value: 2251},
    {text: "Ix-Xaghra", value: 2252},
    {text: "Ix-Xewkija", value: 2253},
    {text: "Ix-Xghajra", value: 2254},
    {text: "Haz-Zabbar", value: 2255},
    {text: "Haz-Zebbug", value: 2256},
    {text: "Iz-Zebbug", value: 2257},
    {text: "Iz-Zejtun", value: 2258},
    {text: "Iz-Zurrieq", value: 2259},
    {text: "Valletta", value: 2260},
    {text: "Agalega Islands", value: 2261},
    {text: "Savanne", value: 2262},
    {text: "Rivière du Rempart", value: 2263},
    {text: "Port Louis", value: 2264},
    {text: "Plaines Wilhems", value: 2265},
    {text: "Pamplemousses", value: 2266},
    {text: "Moka", value: 2267},
    {text: "Grand Port", value: 2268},
    {text: "Flacq", value: 2269},
    {text: "Black River", value: 2270},
    {text: "Cargados Carajos", value: 2271},
    {text: "Rodrigues", value: 2272},
    {text: "Vaavu Atholhu", value: 2273},
    {text: "Thaa Atholhu", value: 2274},
    {text: "Shaviyani Atholhu", value: 2275},
    {text: "Seenu", value: 2276},
    {text: "Raa Atoll", value: 2277},
    {text: "Noonu Atoll", value: 2278},
    {text: "Gnyaviyani Atoll", value: 2279},
    {text: "Meemu Atholhu", value: 2280},
    {text: "Lhaviyani Atholhu", value: 2281},
    {text: "Laamu", value: 2282},
    {text: "Kaafu Atoll", value: 2283},
    {text: "Haa Dhaalu Atholhu", value: 2284},
    {text: "Haa Alifu Atholhu", value: 2285},
    {text: "Gaafu Dhaalu Atoll", value: 2286},
    {text: "Gaafu Alif Atoll", value: 2287},
    {text: "Faafu Atholhu", value: 2288},
    {text: "Dhaalu Atholhu", value: 2289},
    {text: "Baa Atholhu", value: 2290},
    {text: "Northern Ari Atoll", value: 2291},
    {text: "Male", value: 2292},
    {text: "Southern Ari Atoll", value: 2293},
    {text: "Southern Region", value: 2294},
    {text: "Northern Region", value: 2295},
    {text: "Central Region", value: 2296},
    {text: "Yucatán", value: 2297},
    {text: "Veracruz", value: 2298},
    {text: "Tlaxcala", value: 2299},
    {text: "Tamaulipas", value: 2300},
    {text: "Tabasco", value: 2301},
    {text: "Quintana Roo", value: 2302},
    {text: "Querétaro", value: 2303},
    {text: "Puebla", value: 2304},
    {text: "Oaxaca", value: 2305},
    {text: "Nuevo León", value: 2306},
    {text: "Morelos", value: 2307},
    {text: "México", value: 2308},
    {text: "Hidalgo", value: 2309},
    {text: "Guerrero", value: 2310},
    {text: "Mexico City", value: 2311},
    {text: "Chiapas", value: 2312},
    {text: "Campeche", value: 2313},
    {text: "Zacatecas", value: 2314},
    {text: "Sonora", value: 2315},
    {text: "Sinaloa", value: 2316},
    {text: "San Luis Potosí", value: 2317},
    {text: "Nayarit", value: 2318},
    {text: "Michoacán", value: 2319},
    {text: "Jalisco", value: 2320},
    {text: "Guanajuato", value: 2321},
    {text: "Durango", value: 2322},
    {text: "Colima", value: 2323},
    {text: "Coahuila", value: 2324},
    {text: "Chihuahua", value: 2325},
    {text: "Baja California Sur", value: 2326},
    {text: "Baja California", value: 2327},
    {text: "Aguascalientes", value: 2328},
    {text: "Melaka", value: 2329},
    {text: "Terengganu", value: 2330},
    {text: "Selangor", value: 2331},
    {text: "Sarawak", value: 2332},
    {text: "Sabah", value: 2333},
    {text: "Perlis", value: 2334},
    {text: "Perak", value: 2335},
    {text: "Pahang", value: 2336},
    {text: "Negeri Sembilan", value: 2337},
    {text: "Kelantan", value: 2338},
    {text: "Kuala Lumpur", value: 2339},
    {text: "Penang", value: 2340},
    {text: "Kedah", value: 2341},
    {text: "Johor", value: 2342},
    {text: "Labuan", value: 2343},
    {text: "Putrajaya", value: 2344},
    {text: "Zambézia", value: 2345},
    {text: "Tete", value: 2346},
    {text: "Sofala", value: 2347},
    {text: "Niassa", value: 2348},
    {text: "Nampula", value: 2349},
    {text: "Maputo Province", value: 2350},
    {text: "Manica", value: 2351},
    {text: "Inhambane", value: 2352},
    {text: "Gaza", value: 2353},
    {text: "Cabo Delgado", value: 2354},
    {text: "Maputo City", value: 2355},
    {text: "Zambezi", value: 2356},
    {text: "Khomas", value: 2357},
    {text: "Erongo", value: 2358},
    {text: "Hardap", value: 2359},
    {text: "Karas", value: 2360},
    {text: "Kunene", value: 2361},
    {text: "Ohangwena", value: 2362},
    {text: "Omaheke", value: 2363},
    {text: "Omusati", value: 2364},
    {text: "Oshana", value: 2365},
    {text: "Oshikoto", value: 2366},
    {text: "Otjozondjupa", value: 2367},
    {text: "Kavango East", value: 2368},
    {text: "Kavango West", value: 2369},
    {text: "South Province", value: 2370},
    {text: "North Province", value: 2371},
    {text: "Loyalty Islands", value: 2372},
    {text: "Zinder", value: 2373},
    {text: "Tahoua", value: 2374},
    {text: "Maradi", value: 2375},
    {text: "Dosso", value: 2376},
    {text: "Diffa", value: 2377},
    {text: "Agadez", value: 2378},
    {text: "Tillabéri", value: 2379},
    {text: "Niamey", value: 2380},
    {text: "Sokoto", value: 2381},
    {text: "Rivers", value: 2382},
    {text: "Plateau", value: 2383},
    {text: "Oyo", value: 2384},
    {text: "Ondo", value: 2385},
    {text: "Ogun", value: 2386},
    {text: "Niger", value: 2387},
    {text: "Lagos", value: 2388},
    {text: "Kwara", value: 2389},
    {text: "Katsina", value: 2390},
    {text: "Kano", value: 2391},
    {text: "Kaduna", value: 2392},
    {text: "Imo", value: 2393},
    {text: "Cross River", value: 2394},
    {text: "Borno", value: 2395},
    {text: "Benue", value: 2396},
    {text: "Bauchi", value: 2397},
    {text: "Anambra", value: 2398},
    {text: "Akwa Ibom", value: 2399},
    {text: "FCT", value: 2400},
    {text: "Abia", value: 2401},
    {text: "Delta", value: 2402},
    {text: "Adamawa", value: 2403},
    {text: "Edo", value: 2404},
    {text: "Enugu", value: 2405},
    {text: "Jigawa", value: 2406},
    {text: "Bayelsa", value: 2407},
    {text: "Ebonyi", value: 2408},
    {text: "Ekiti", value: 2409},
    {text: "Gombe", value: 2410},
    {text: "Nassarawa", value: 2411},
    {text: "Zamfara", value: 2412},
    {text: "Kebbi", value: 2413},
    {text: "Kogi", value: 2414},
    {text: "Osun", value: 2415},
    {text: "Taraba", value: 2416},
    {text: "Yobe", value: 2417},
    {text: "Rivas", value: 2418},
    {text: "Río San Juan", value: 2419},
    {text: "Nueva Segovia", value: 2420},
    {text: "Matagalpa", value: 2421},
    {text: "Masaya", value: 2422},
    {text: "Managua", value: 2423},
    {text: "Madriz", value: 2424},
    {text: "León", value: 2425},
    {text: "Jinotega", value: 2426},
    {text: "Granada", value: 2427},
    {text: "Estelí", value: 2428},
    {text: "Chontales", value: 2429},
    {text: "Chinandega", value: 2430},
    {text: "Carazo", value: 2431},
    {text: "Boaco", value: 2432},
    {text: "North Caribbean Coast", value: 2433},
    {text: "South Caribbean Coast", value: 2434},
    {text: "South Holland", value: 2435},
    {text: "Zeeland", value: 2436},
    {text: "Utrecht", value: 2437},
    {text: "Overijssel", value: 2438},
    {text: "North Holland", value: 2439},
    {text: "North Brabant", value: 2440},
    {text: "Limburg", value: 2441},
    {text: "Groningen", value: 2442},
    {text: "Gelderland", value: 2443},
    {text: "Friesland", value: 2444},
    {text: "Drenthe", value: 2445},
    {text: "Flevoland", value: 2446},
    {text: "Rogaland", value: 2447},
    {text: "Oslo", value: 2448},
    {text: "Nordland", value: 2449},
    {text: "Møre og Romsdal", value: 2450},
    {text: "Trøndelag", value: 2451},
    {text: "Viken", value: 2452},
    {text: "Agder", value: 2453},
    {text: "Troms og Finnmark", value: 2454},
    {text: "Innlandet", value: 2455},
    {text: "Vestfold og Telemark", value: 2456},
    {text: "Vestland", value: 2457},
    {text: "Province 1", value: 2458},
    {text: "Province 2", value: 2459},
    {text: "Bagmati Province", value: 2460},
    {text: "Province 4", value: 2461},
    {text: "Lumbini Province", value: 2462},
    {text: "Karnali Pradesh", value: 2463},
    {text: "Sudurpashchim Pradesh", value: 2464},
    {text: "Yaren", value: 2465},
    {text: "Uaboe", value: 2466},
    {text: "Nibok", value: 2467},
    {text: "Meneng", value: 2468},
    {text: "Ijuw", value: 2469},
    {text: "Ewa", value: 2470},
    {text: "Denigomodu", value: 2471},
    {text: "Buada", value: 2472},
    {text: "Boe", value: 2473},
    {text: "Baiti", value: 2474},
    {text: "Anibare", value: 2475},
    {text: "Anetan", value: 2476},
    {text: "Anabar", value: 2477},
    {text: "Aiwo", value: 2478},
    {text: "Wellington", value: 2479},
    {text: "Manawatu-Wanganui", value: 2480},
    {text: "Waikato", value: 2481},
    {text: "Tasman", value: 2482},
    {text: "Taranaki", value: 2483},
    {text: "Southland", value: 2484},
    {text: "Bay of Plenty", value: 2485},
    {text: "Northland", value: 2486},
    {text: "Marlborough", value: 2487},
    {text: "Hawke's Bay", value: 2488},
    {text: "Gisborne", value: 2489},
    {text: "Canterbury", value: 2490},
    {text: "Auckland", value: 2491},
    {text: "Chatham Islands", value: 2492},
    {text: "Nelson", value: 2493},
    {text: "Otago", value: 2494},
    {text: "West Coast", value: 2495},
    {text: "Ad Dakhiliyah", value: 2496},
    {text: "Al Batinah South", value: 2497},
    {text: "Al Wustá", value: 2498},
    {text: "Southeastern Governorate", value: 2499},
    {text: "Az¸ Z¸ahirah", value: 2500},
    {text: "Muscat", value: 2501},
    {text: "Musandam", value: 2502},
    {text: "Dhofar", value: 2503},
    {text: "Al Buraimi", value: 2504},
    {text: "Northeastern Governorate", value: 2505},
    {text: "Al Batinah North", value: 2506},
    {text: "Veraguas", value: 2507},
    {text: "Guna Yala", value: 2508},
    {text: "Panamá", value: 2509},
    {text: "Los Santos", value: 2510},
    {text: "Herrera", value: 2511},
    {text: "Darién", value: 2512},
    {text: "Colón", value: 2513},
    {text: "Coclé", value: 2514},
    {text: "Chiriquí", value: 2515},
    {text: "Bocas del Toro", value: 2516},
    {text: "Emberá", value: 2517},
    {text: "Ngöbe-Buglé", value: 2518},
    {text: "Panamá Oeste", value: 2519},
    {text: "Ucayali", value: 2520},
    {text: "Tumbes", value: 2521},
    {text: "San Martín", value: 2522},
    {text: "Piura", value: 2523},
    {text: "Loreto", value: 2524},
    {text: "Lambayeque", value: 2525},
    {text: "La Libertad", value: 2526},
    {text: "Huanuco", value: 2527},
    {text: "Cajamarca", value: 2528},
    {text: "Ancash", value: 2529},
    {text: "Amazonas", value: 2530},
    {text: "Tacna", value: 2531},
    {text: "Puno", value: 2532},
    {text: "Pasco", value: 2533},
    {text: "Moquegua", value: 2534},
    {text: "Madre de Dios", value: 2535},
    {text: "Lima", value: 2536},
    {text: "Lima region", value: 2537},
    {text: "Junin", value: 2538},
    {text: "Ica", value: 2539},
    {text: "Huancavelica", value: 2540},
    {text: "Cusco", value: 2541},
    {text: "Callao", value: 2542},
    {text: "Ayacucho", value: 2543},
    {text: "Arequipa", value: 2544},
    {text: "Apurímac", value: 2545},
    {text: "Îles Marquises", value: 2546},
    {text: "Îles Tuamotu-Gambier", value: 2547},
    {text: "Leeward Islands", value: 2548},
    {text: "Îles du Vent", value: 2549},
    {text: "Îles Australes", value: 2550},
    {text: "West New Britain", value: 2551},
    {text: "Western Province", value: 2552},
    {text: "Western Highlands", value: 2553},
    {text: "Southern Highlands", value: 2554},
    {text: "Sandaun", value: 2555},
    {text: "Bougainville", value: 2556},
    {text: "Northern Province", value: 2557},
    {text: "New Ireland", value: 2558},
    {text: "National Capital", value: 2559},
    {text: "Morobe", value: 2560},
    {text: "Manus", value: 2561},
    {text: "Madang", value: 2562},
    {text: "Gulf", value: 2563},
    {text: "Enga", value: 2564},
    {text: "East Sepik", value: 2565},
    {text: "East New Britain", value: 2566},
    {text: "Eastern Highlands", value: 2567},
    {text: "Chimbu", value: 2568},
    {text: "Milne Bay", value: 2569},
    {text: "Central Province", value: 2570},
    {text: "Hela", value: 2571},
    {text: "Jiwaka", value: 2572},
    {text: "Autonomous Region in Muslim Mindanao", value: 2573},
    {text: "Northern Mindanao", value: 2574},
    {text: "Mimaropa", value: 2575},
    {text: "Cagayan Valley", value: 2576},
    {text: "Soccsksargen", value: 2577},
    {text: "Caraga", value: 2578},
    {text: "Cordillera", value: 2579},
    {text: "Ilocos", value: 2580},
    {text: "Calabarzon", value: 2581},
    {text: "Western Visayas", value: 2582},
    {text: "Central Luzon", value: 2583},
    {text: "Central Visayas", value: 2584},
    {text: "Eastern Visayas", value: 2585},
    {text: "Zamboanga Peninsula", value: 2586},
    {text: "Davao", value: 2587},
    {text: "Bicol", value: 2588},
    {text: "Metro Manila", value: 2589},
    {text: "Islamabad", value: 2590},
    {text: "Sindh", value: 2591},
    {text: "Punjab", value: 2592},
    {text: "Khyber Pakhtunkhwa", value: 2593},
    {text: "Gilgit-Baltistan", value: 2594},
    {text: "Balochistan", value: 2595},
    {text: "Azad Kashmir", value: 2596},
    {text: "Lublin", value: 2597},
    {text: "Lesser Poland", value: 2598},
    {text: "Mazovia", value: 2599},
    {text: "Subcarpathia", value: 2600},
    {text: "Podlasie", value: 2601},
    {text: "Swietokrzyskie", value: 2602},
    {text: "Warmia-Masuria", value: 2603},
    {text: "Lower Silesia", value: 2604},
    {text: "Lódz Voivodeship", value: 2605},
    {text: "Lubusz", value: 2606},
    {text: "Opole Voivodeship", value: 2607},
    {text: "Pomerania", value: 2608},
    {text: "Silesia", value: 2609},
    {text: "Greater Poland", value: 2610},
    {text: "West Pomerania", value: 2611},
    {text: "Kujawsko-Pomorskie", value: 2612},
    {text: "Saint-Pierre", value: 2613},
    {text: "Miquelon-Langlade", value: 2614},
    {text: "Adjuntas", value: 2615},
    {text: "Aguada", value: 2616},
    {text: "Aguadilla", value: 2617},
    {text: "Aguas Buenas", value: 2618},
    {text: "Aibonito", value: 2619},
    {text: "Añasco", value: 2620},
    {text: "Arecibo", value: 2621},
    {text: "Arroyo", value: 2622},
    {text: "Barceloneta", value: 2623},
    {text: "Barranquitas", value: 2624},
    {text: "Bayamón", value: 2625},
    {text: "Cabo Rojo", value: 2626},
    {text: "Caguas", value: 2627},
    {text: "Camuy", value: 2628},
    {text: "Canóvanas", value: 2629},
    {text: "Carolina", value: 2630},
    {text: "Cataño", value: 2631},
    {text: "Cayey", value: 2632},
    {text: "Ceiba", value: 2633},
    {text: "Ciales", value: 2634},
    {text: "Cidra", value: 2635},
    {text: "Coamo", value: 2636},
    {text: "Comerío", value: 2637},
    {text: "Corozal", value: 2638},
    {text: "Culebra", value: 2639},
    {text: "Dorado", value: 2640},
    {text: "Fajardo", value: 2641},
    {text: "Florida", value: 2642},
    {text: "Guánica", value: 2643},
    {text: "Guayama", value: 2644},
    {text: "Guayanilla", value: 2645},
    {text: "Guaynabo", value: 2646},
    {text: "Gurabo", value: 2647},
    {text: "Hatillo", value: 2648},
    {text: "Hormigueros", value: 2649},
    {text: "Humacao", value: 2650},
    {text: "Isabela", value: 2651},
    {text: "Jayuya", value: 2652},
    {text: "Juana Díaz", value: 2653},
    {text: "Juncos", value: 2654},
    {text: "Lajas", value: 2655},
    {text: "Lares", value: 2656},
    {text: "Las Marías", value: 2657},
    {text: "Las Piedras", value: 2658},
    {text: "Loíza", value: 2659},
    {text: "Luquillo", value: 2660},
    {text: "Manatí", value: 2661},
    {text: "Maricao", value: 2662},
    {text: "Maunabo", value: 2663},
    {text: "Mayagüez", value: 2664},
    {text: "Moca", value: 2665},
    {text: "Morovis", value: 2666},
    {text: "Naguabo", value: 2667},
    {text: "Naranjito", value: 2668},
    {text: "Orocovis", value: 2669},
    {text: "Patillas", value: 2670},
    {text: "Peñuelas", value: 2671},
    {text: "Ponce", value: 2672},
    {text: "Rincón", value: 2673},
    {text: "Quebradillas", value: 2674},
    {text: "Río Grande", value: 2675},
    {text: "Sabana Grande", value: 2676},
    {text: "Salinas", value: 2677},
    {text: "San Germán", value: 2678},
    {text: "San Juan", value: 2679},
    {text: "San Lorenzo", value: 2680},
    {text: "San Sebastián", value: 2681},
    {text: "Santa Isabel", value: 2682},
    {text: "Toa Alta", value: 2683},
    {text: "Toa Baja", value: 2684},
    {text: "Trujillo Alto", value: 2685},
    {text: "Utuado", value: 2686},
    {text: "Vega Alta", value: 2687},
    {text: "Vega Baja", value: 2688},
    {text: "Villalba", value: 2689},
    {text: "Yabucoa", value: 2690},
    {text: "Yauco", value: 2691},
    {text: "Vieques", value: 2692},
    {text: "Gaza Strip", value: 2693},
    {text: "West Bank", value: 2694},
    {text: "District of Setúbal", value: 2695},
    {text: "Santarém", value: 2696},
    {text: "Portalegre", value: 2697},
    {text: "Lisbon", value: 2698},
    {text: "Leiria", value: 2699},
    {text: "Faro", value: 2700},
    {text: "Évora", value: 2701},
    {text: "Castelo Branco", value: 2702},
    {text: "Beja", value: 2703},
    {text: "Madeira", value: 2704},
    {text: "Viseu", value: 2705},
    {text: "Vila Real", value: 2706},
    {text: "Viana do Castelo", value: 2707},
    {text: "Porto", value: 2708},
    {text: "Guarda", value: 2709},
    {text: "Coimbra", value: 2710},
    {text: "Bragança", value: 2711},
    {text: "Braga", value: 2712},
    {text: "Aveiro", value: 2713},
    {text: "Azores", value: 2714},
    {text: "Ngatpang", value: 2715},
    {text: "Sonsorol", value: 2716},
    {text: "Kayangel", value: 2717},
    {text: "Hatohobei", value: 2718},
    {text: "Aimeliik", value: 2719},
    {text: "Airai", value: 2720},
    {text: "Angaur", value: 2721},
    {text: "Koror", value: 2722},
    {text: "Melekeok", value: 2723},
    {text: "Ngaraard", value: 2724},
    {text: "Ngchesar", value: 2725},
    {text: "Ngarchelong", value: 2726},
    {text: "Ngardmau", value: 2727},
    {text: "Ngaremlengui", value: 2728},
    {text: "Ngiwal", value: 2729},
    {text: "Peleliu", value: 2730},
    {text: "San Pedro", value: 2731},
    {text: "Presidente Hayes", value: 2732},
    {text: "Paraguarí", value: 2733},
    {text: "Ñeembucú", value: 2734},
    {text: "Misiones", value: 2735},
    {text: "Itapúa", value: 2736},
    {text: "Guairá", value: 2737},
    {text: "Cordillera", value: 2738},
    {text: "Concepción", value: 2739},
    {text: "Central", value: 2740},
    {text: "Canindeyú", value: 2741},
    {text: "Caazapá", value: 2742},
    {text: "Caaguazú", value: 2743},
    {text: "Amambay", value: 2744},
    {text: "Alto Paraná", value: 2745},
    {text: "Alto Paraguay", value: 2746},
    {text: "Asunción", value: 2747},
    {text: "Boquerón", value: 2748},
    {text: "Madinat ash Shamal", value: 2749},
    {text: "Al Khor", value: 2750},
    {text: "Baladiyat Umm Salal", value: 2751},
    {text: "Baladiyat ar Rayyan", value: 2752},
    {text: "Baladiyat ad Daw?ah", value: 2753},
    {text: "Al Wakrah", value: 2754},
    {text: "Baladiyat az¸ Z¸a‘ayin", value: 2755},
    {text: "Al-Shahaniya", value: 2756},
    {text: "Réunion", value: 2757},
    {text: "Vrancea", value: 2758},
    {text: "Vâlcea", value: 2759},
    {text: "Vaslui", value: 2760},
    {text: "Tulcea", value: 2761},
    {text: "Timis", value: 2762},
    {text: "Teleorman", value: 2763},
    {text: "Suceava", value: 2764},
    {text: "Sibiu", value: 2765},
    {text: "Satu Mare", value: 2766},
    {text: "Salaj", value: 2767},
    {text: "Prahova", value: 2768},
    {text: "Olt", value: 2769},
    {text: "Neamt", value: 2770},
    {text: "Mures", value: 2771},
    {text: "Mehedinti", value: 2772},
    {text: "Maramures", value: 2773},
    {text: "Iasi", value: 2774},
    {text: "Ialomita", value: 2775},
    {text: "Hunedoara", value: 2776},
    {text: "Harghita", value: 2777},
    {text: "Gorj", value: 2778},
    {text: "Giurgiu", value: 2779},
    {text: "Galati", value: 2780},
    {text: "Dolj", value: 2781},
    {text: "Dâmbovita", value: 2782},
    {text: "Covasna", value: 2783},
    {text: "Constan?a", value: 2784},
    {text: "Cluj", value: 2785},
    {text: "Caras-Severin", value: 2786},
    {text: "Calarasi", value: 2787},
    {text: "Buzau", value: 2788},
    {text: "Bucuresti", value: 2789},
    {text: "Brasov", value: 2790},
    {text: "Braila", value: 2791},
    {text: "Botosani", value: 2792},
    {text: "Bistrita-Nasaud", value: 2793},
    {text: "Bihor", value: 2794},
    {text: "Bacau", value: 2795},
    {text: "Arges", value: 2796},
    {text: "Arad", value: 2797},
    {text: "Alba", value: 2798},
    {text: "Ilfov", value: 2799},
    {text: "Vojvodina", value: 2800},
    {text: "Central Serbia", value: 2801},
    {text: "Yaroslavl Oblast", value: 2802},
    {text: "Voronezj", value: 2803},
    {text: "Vologda", value: 2804},
    {text: "Volgograd Oblast", value: 2805},
    {text: "Ulyanovsk", value: 2806},
    {text: "Udmurtiya Republic", value: 2807},
    {text: "Tver’ Oblast", value: 2808},
    {text: "Tula", value: 2809},
    {text: "Tatarstan Republic", value: 2810},
    {text: "Tambov", value: 2811},
    {text: "Stavropol’ Kray", value: 2812},
    {text: "Smolenskaya Oblast’", value: 2813},
    {text: "Saratovskaya Oblast", value: 2814},
    {text: "Samara Oblast", value: 2815},
    {text: "Ryazan Oblast", value: 2816},
    {text: "Rostov", value: 2817},
    {text: "Pskov Oblast", value: 2818},
    {text: "Perm", value: 2819},
    {text: "Penza", value: 2820},
    {text: "Orel Oblast", value: 2821},
    {text: "Orenburg Oblast", value: 2822},
    {text: "Novgorod Oblast", value: 2823},
    {text: "North Ossetia", value: 2824},
    {text: "Nenets", value: 2825},
    {text: "Murmansk", value: 2826},
    {text: "Moscow", value: 2827},
    {text: "Moscow Oblast", value: 2828},
    {text: "Mordoviya Republic", value: 2829},
    {text: "Mariy-El Republic", value: 2830},
    {text: "Lipetsk Oblast", value: 2831},
    {text: "Leningradskaya Oblast'", value: 2832},
    {text: "St.-Petersburg", value: 2833},
    {text: "Kursk", value: 2834},
    {text: "Krasnodarskiy", value: 2835},
    {text: "Kostroma Oblast", value: 2836},
    {text: "Komi", value: 2837},
    {text: "Kirov", value: 2838},
    {text: "Karelia", value: 2839},
    {text: "Karachayevo-Cherkesiya Republic", value: 2840},
    {text: "Kaluga", value: 2841},
    {text: "Kalmykiya Republic", value: 2842},
    {text: "Kaliningrad", value: 2843},
    {text: "Kabardino-Balkariya Republic", value: 2844},
    {text: "Ivanovo", value: 2845},
    {text: "Ingushetiya Republic", value: 2846},
    {text: "Nizhny Novgorod Oblast", value: 2847},
    {text: "Dagestan", value: 2848},
    {text: "Chuvashia", value: 2849},
    {text: "Chechnya", value: 2850},
    {text: "Bryansk Oblast", value: 2851},
    {text: "Belgorod Oblast", value: 2852},
    {text: "Bashkortostan Republic", value: 2853},
    {text: "Astrakhan", value: 2854},
    {text: "Arkhangelskaya", value: 2855},
    {text: "Adygeya Republic", value: 2856},
    {text: "Vladimir", value: 2857},
    {text: "Yamalo-Nenets", value: 2858},
    {text: "Tyumen’ Oblast", value: 2859},
    {text: "Republic of Tyva", value: 2860},
    {text: "Tomsk Oblast", value: 2861},
    {text: "Sverdlovsk", value: 2862},
    {text: "Omsk", value: 2863},
    {text: "Novosibirsk Oblast", value: 2864},
    {text: "Kurgan Oblast", value: 2865},
    {text: "Krasnoyarskiy", value: 2866},
    {text: "Khanty-Mansia", value: 2867},
    {text: "Khakasiya Republic", value: 2868},
    {text: "Kemerovo Oblast", value: 2869},
    {text: "Altai", value: 2870},
    {text: "Chelyabinsk", value: 2871},
    {text: "Altai Krai", value: 2872},
    {text: "Sakha", value: 2873},
    {text: "Primorskiy (Maritime) Kray", value: 2874},
    {text: "Khabarovsk", value: 2875},
    {text: "Irkutsk Oblast", value: 2876},
    {text: "Jewish Autonomous Oblast", value: 2877},
    {text: "Amur Oblast", value: 2878},
    {text: "Buryatiya Republic", value: 2879},
    {text: "Sakhalin Oblast", value: 2880},
    {text: "Magadan Oblast", value: 2881},
    {text: "Kamchatka", value: 2882},
    {text: "Chukotka", value: 2883},
    {text: "Transbaikal Territory", value: 2884},
    {text: "Eastern Province", value: 2885},
    {text: "Kigali", value: 2886},
    {text: "Northern Province", value: 2887},
    {text: "Western Province", value: 2888},
    {text: "Southern Province", value: 2889},
    {text: "Tabuk Region", value: 2890},
    {text: "Najran Region", value: 2891},
    {text: "Mecca Region", value: 2892},
    {text: "Jazan Region", value: 2893},
    {text: "Ha'il Region", value: 2894},
    {text: "'Asir Region", value: 2895},
    {text: "Eastern Province", value: 2896},
    {text: "Riyadh Region", value: 2897},
    {text: "Al-Qassim Region", value: 2898},
    {text: "Medina Region", value: 2899},
    {text: "Al Jawf Region", value: 2900},
    {text: "Northern Borders Region", value: 2901},
    {text: "Al Bahah Region", value: 2902},
    {text: "Western Province", value: 2903},
    {text: "Malaita", value: 2904},
    {text: "Isabel", value: 2905},
    {text: "Guadalcanal", value: 2906},
    {text: "Central Province", value: 2907},
    {text: "Temotu", value: 2908},
    {text: "Makira", value: 2909},
    {text: "Choiseul", value: 2910},
    {text: "Rennell and Bellona", value: 2911},
    {text: "Honiara", value: 2912},
    {text: "Takamaka", value: 2913},
    {text: "Saint Louis", value: 2914},
    {text: "Port Glaud", value: 2915},
    {text: "Pointe Larue", value: 2916},
    {text: "Plaisance", value: 2917},
    {text: "Mont Fleuri", value: 2918},
    {text: "Mont Buxton", value: 2919},
    {text: "English River", value: 2920},
    {text: "La Digue", value: 2921},
    {text: "Grand Anse Mahe", value: 2922},
    {text: "Grand Anse Praslin", value: 2923},
    {text: "Glacis", value: 2924},
    {text: "Cascade", value: 2925},
    {text: "Bel Ombre", value: 2926},
    {text: "Bel Air", value: 2927},
    {text: "Beau Vallon", value: 2928},
    {text: "Baie Sainte Anne", value: 2929},
    {text: "Baie Lazare", value: 2930},
    {text: "Anse Royale", value: 2931},
    {text: "Anse Etoile", value: 2932},
    {text: "Anse Boileau", value: 2933},
    {text: "Anse-aux-Pins", value: 2934},
    {text: "Les Mamelles", value: 2935},
    {text: "Roche Caiman", value: 2936},
    {text: "Au Cap", value: 2937},
    {text: "Outer Islands", value: 2938},
    {text: "Ile Perseverance I", value: 2939},
    {text: "Ile Perseverance II", value: 2940},
    {text: "Northern State", value: 2941},
    {text: "Khartoum", value: 2942},
    {text: "Red Sea", value: 2943},
    {text: "Al Jazirah", value: 2944},
    {text: "Al Qa?arif", value: 2945},
    {text: "White Nile", value: 2946},
    {text: "Blue Nile", value: 2947},
    {text: "Western Darfur", value: 2948},
    {text: "West Kordofan State", value: 2949},
    {text: "Southern Darfur", value: 2950},
    {text: "Southern Kordofan", value: 2951},
    {text: "Kassala", value: 2952},
    {text: "River Nile", value: 2953},
    {text: "Northern Darfur", value: 2954},
    {text: "North Kordofan", value: 2955},
    {text: "Sinnar", value: 2956},
    {text: "Eastern Darfur", value: 2957},
    {text: "Central Darfur", value: 2958},
    {text: "Norrbotten", value: 2959},
    {text: "Västmanland", value: 2960},
    {text: "Västernorrland", value: 2961},
    {text: "Västerbotten", value: 2962},
    {text: "Värmland", value: 2963},
    {text: "Uppsala", value: 2964},
    {text: "Stockholm", value: 2965},
    {text: "Södermanland", value: 2966},
    {text: "Östergötland", value: 2967},
    {text: "Örebro", value: 2968},
    {text: "Kronoberg", value: 2969},
    {text: "Dalarna", value: 2970},
    {text: "Kalmar", value: 2971},
    {text: "Jönköping", value: 2972},
    {text: "Jämtland", value: 2973},
    {text: "Halland", value: 2974},
    {text: "Gotland", value: 2975},
    {text: "Gävleborg", value: 2976},
    {text: "Blekinge", value: 2977},
    {text: "Skåne", value: 2978},
    {text: "Västra Götaland", value: 2979},
    {text: "Ascension", value: 2980},
    {text: "Tristan da Cunha", value: 2981},
    {text: "Saint Helena", value: 2982},
    {text: "Žalec", value: 2983},
    {text: "Zagorje ob Savi", value: 2984},
    {text: "Vrhnika", value: 2985},
    {text: "Tržic", value: 2986},
    {text: "Trebnje", value: 2987},
    {text: "Trbovlje", value: 2988},
    {text: "Tolmin", value: 2989},
    {text: "Velenje", value: 2990},
    {text: "Šmarje pri Jelšah", value: 2991},
    {text: "Slovenska Konjice", value: 2992},
    {text: "Slovenska Bistrica", value: 2993},
    {text: "Slovenj Gradec", value: 2994},
    {text: "Škofja Loka", value: 2995},
    {text: "Sežana", value: 2996},
    {text: "Sevnica", value: 2997},
    {text: "Sentjur", value: 2998},
    {text: "Ribnica", value: 2999},
    {text: "Radovljica", value: 3000},
    {text: "Radlje ob Dravi", value: 3001},
    {text: "Ptuj", value: 3002},
    {text: "Postojna", value: 3003},
    {text: "Piran-Pirano", value: 3004},
    {text: "Ormož", value: 3005},
    {text: "Novo Mesto", value: 3006},
    {text: "Nova Gorica", value: 3007},
    {text: "Murska Sobota", value: 3008},
    {text: "Mozirje", value: 3009},
    {text: "Metlika", value: 3010},
    {text: "Maribor", value: 3011},
    {text: "Logatec", value: 3012},
    {text: "Ljutomer", value: 3013},
    {text: "Litija", value: 3014},
    {text: "Lenart", value: 3015},
    {text: "Laško", value: 3016},
    {text: "Krško", value: 3017},
    {text: "Kranj", value: 3018},
    {text: "Koper-Capodistria", value: 3019},
    {text: "Kocevje", value: 3020},
    {text: "Kamnik", value: 3021},
    {text: "Jesenice", value: 3022},
    {text: "Izola-Isola", value: 3023},
    {text: "Ilirska Bistrica", value: 3024},
    {text: "Idrija", value: 3025},
    {text: "Hrastnik", value: 3026},
    {text: "Grosuplje", value: 3027},
    {text: "Gornja Radgona", value: 3028},
    {text: "Dravograd", value: 3029},
    {text: "Domžale", value: 3030},
    {text: "Crnomelj", value: 3031},
    {text: "Cerknica", value: 3032},
    {text: "Celje", value: 3033},
    {text: "Brežice", value: 3034},
    {text: "Ajdovšcina", value: 3035},
    {text: "Hrpelje-Kozina", value: 3036},
    {text: "Divaca", value: 3037},
    {text: "Pivka", value: 3038},
    {text: "Loška Dolina", value: 3039},
    {text: "Loški Potok", value: 3040},
    {text: "Osilnica", value: 3041},
    {text: "Velike Lašce", value: 3042},
    {text: "Škofljica", value: 3043},
    {text: "Ig", value: 3044},
    {text: "Brezovica", value: 3045},
    {text: "Borovnica", value: 3046},
    {text: "Vipava", value: 3047},
    {text: "Komen", value: 3048},
]



