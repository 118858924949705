import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'castateparksscp-research-project-additional-permit-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectAdditionalPermitFormComponent extends BasicFormComponent {
    upsertDto: AdditionalPermitsDto;
    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'AdditionalPermitsDetails',
                label: 'If you replied yes but do not have all the necessary permits prior to submission of this application, provide rationale or additional information',
                type: 'textArea',
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService);
        this.formPage.createUpsertDto = (dto) => {
            return {
                AdditionalPermitsDetails: dto?.AdditionalPermitsDetails,
                ProposalIncludesAdditionalPermits: dto?.ProposalIncludesAdditionalPermits
            } as AdditionalPermitsDto
        };
    }
}

export interface AdditionalPermitsDto {
    AdditionalPermitsDetails: string;
}
