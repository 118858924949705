//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Permission]

export enum PermissionEnum {
  CustomPageRights = 1,
  CustomRichTextRights = 2,
  FieldDefinitionRights = 3,
  UserRights = 4,
  ProjectRights = 5,
  PermitRights = 6,
  ContactRights = 7,
  TaxonRights = 8,
  StandardConditionRights = 9,
  DistrictRights = 10,
  SectorRights = 11,
  ParkRights = 12,
  ResearchProjectRights = 13,
  SpecialConditionRights = 14,
  InternalReviewRights = 15,
  ResourceCategoryTagRights = 16
}
