<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="project"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()"
            (navigateToApprovePermitEvent)="scrollToPermitApproval()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <div style="margin-top:30px; margin-bottom:25px; display: flex; justify-content: space-between">
            <h1 style="margin: 0">{{title}}</h1>
        </div>
        <castateparksscp-annual-report-notification [parentRenewedFromPermit]="renewedFromResearchProject"></castateparksscp-annual-report-notification>
        <castateparksscp-returned-to-researcher-notification #notificationComponent
                                                             [researchProjectID]="researchProjectID" [alwaysDisplay]="false"></castateparksscp-returned-to-researcher-notification>

        <ng-container *ngIf="canReadAssignee()">
            <castateparksscp-internal-review-assignee-form
                #internalReviewAssigneeForm
                [isLoading]="isLoading"
                [isCDS]="true"
                [dto]="internalFieldsDto"
                [ID]="researchProjectID"
                [editMode]="canEditAssignee()"
                [isHideActionButtons]="true"
                (formSubmitted)="internalFieldsSaved($event)"
                (formChanged)="saveInternalFields()"
                [canEditAssignee]="canEditAssignee()"
            ></castateparksscp-internal-review-assignee-form>
        </ng-container>

        <div class="grid-12">
            <div class="g-col-7">
                <cds-table *ngIf="!!cdsTable.model"
                           [model]="cdsTable.model"
                           [size]="cdsTable.size"
                           [enableSingleSelect]="false"
                           [showSelectionColumn]="false"
                           [striped]="false"
                           [sortable]="false"
                           [isDataGrid]="true"
                           [skeleton]="false"
                           [style]="'margin-top: 10px'"
                ></cds-table>
            </div>
        </div>

        <br/>

        <ng-container *ngIf="canReadTags()">
            <castateparksscp-resource-category-tag-form
                #tagForm
                [isLoading]="isLoading"
                [isCDS]="true"
                [dto]="internalFieldsDto"
                [researchProjectID]="researchProjectID"
                [editMode]="canEditTags()"
                [isHideActionButtons]="true"
                [displayDefaultSuccessAlert]="false"
                (formSubmitted)="handleTagSave($event)"
                (formChanged)="handleTagFormChanged($event)"
                [canEditTechnicalReview]="true"
            ></castateparksscp-resource-category-tag-form>
            <cds-tag-filter *ngFor="let tag of tagForm.getResearchProjectTags()"
                [type]="'cool-gray'"
                [size]="'md'"
                title="Filter"
                closeButtonLabel="Clear"
                (close)="handleTagRemove(tag.ResearchProjectTagID)">
                {{tag.Content}}
            </cds-tag-filter>
        </ng-container>

        <ng-container *ngIf="canReadHQSpecialConditions() || canReadDistrictSpecialConditions()">
            <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
            <div class="grid-12">
                <ng-container *ngIf="canReadHQSpecialConditions()">
                    <div class="g-col-12">
                        <castateparksscp-research-project-special-conditions-grid
                            (editEvent)="handleSpecialConditionsEditEvent()"
                            [disabled]="!canEditHQSpecialConditions()"
                            [researchProjectID]="researchProjectID"
                        >
                            <h2>HQ Special Conditions</h2>
                        </castateparksscp-research-project-special-conditions-grid>
                    </div>
                </ng-container>
                <ng-container *ngIf="canReadDistrictSpecialConditions()">
                    <div class="g-col-12">
                        <castateparksscp-research-project-district-special-conditions-grid
                            (editEvent)="handleSpecialConditionsEditEvent()"
                            [disabled]="!canEditDistrictSpecialConditions()"
                            [researchProjectID]="researchProjectID">
                            <h2>District Special Conditions</h2>
                        </castateparksscp-research-project-district-special-conditions-grid>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div id="permit-approval"></div>
        <ng-container *ngIf="canReadPermitDatesAndCeqa()">
            <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
            <h2 castateparksscpIntersectionObserver (exists)="handlePermitApprovalSectionExists()" style="margin-bottom: 10px">{{getApprovalHeader()}}</h2>
            <ng-container *ngIf="!!project && !project?.AmendedFromPermitNumber && canReadUploadSigned()">
                <h3 style="margin-top: 0">1: Set permit dates, CEQA, and print permit</h3>
            </ng-container>
            <div class="grid-12">
                    <div class="g-col-6">
                        <castateparksscp-unsigned-permit-form
                            #unsignedPermitForm
                            [isLoading]="isLoading"
                            [isCDS]="true"
                            [dto]="project"
                            [ID]="researchProjectID"
                            [editMode]="canEditPermitDatesAndCeqa()"
                            [isHideActionButtons]="true"
                            (formSubmitted)="generateUnsignedPermit($event)">
                            ></castateparksscp-unsigned-permit-form>
                    </div>
                <ng-container *ngIf="canReadGenerateButton()">
                    <div class="g-col-6">
                        <div *ngIf="displayGenerateButton()" style="margin-top: 1.5rem; width: 100%; display: flex;">
                            <button (click)="generate()" [disabled]="isDisabledGenerateButton()" cdsButton="secondary" size="md"
                                    style="margin-right: 10px">{{getGenerateButtonLabel()}}
                            </button>
                            <cds-inline-loading [state]="errorState"
                                                [loadingText]="'Generating…'"
                                                [errorText]="'Generation failed'">
                            </cds-inline-loading>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>


        <ng-container *ngIf="canReadUploadSigned()">
            <ng-container *ngIf="!!project && !project?.AmendedFromPermitNumber">
                <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
                <h3>2: Upload signed permit and mark as approved</h3>
            </ng-container>
            <div class="grid-12">
                <div class="g-col-6">
                    <ng-container *ngIf="!!project && !project?.AmendedFromPermitNumber">
                        <castateparksscp-research-project-file-list-manager
                            #signedPermitFileManager
                            [uploadFileButtonText]="'Upload Signed Active Permit*'"
                            [limitOneFile]="true"
                            [canDeleteFile]="canEditUploadSigned()"
                            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.SignedActivePermit"
                            [researchProjectID]="researchProjectID"
                            (uploadedFileChangeEmitted)="handleDocumentChange()"
                            [disabled]="!canEditUploadSigned()"
                        ></castateparksscp-research-project-file-list-manager>
                    </ng-container>
                    <ng-container *ngIf="!!project?.AmendedFromPermitNumber">
                        <castateparksscp-research-project-file-list-manager
                            #amendmentFinalLetterFileManager
                            [uploadFileButtonText]="'Upload Signed Amendment Final Letter*'"
                            [limitOneFile]="true"
                            [canDeleteFile]="canEditUploadSigned()"
                            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.SignedAmendmentFinalLetter"
                            [researchProjectID]="researchProjectID"
                            (uploadedFileChangeEmitted)="handleDocumentChange()"
                            [required]="true"
                            [disabled]="!canEditUploadSigned()"
                        ></castateparksscp-research-project-file-list-manager>
                        <castateparksscp-research-project-file-list-manager
                            #amendmentSupportingDocFileManager
                            [uploadFileButtonText]="'Upload Amendment Supporting Documentation'"
                            [buttonType]="'secondary'"
                            [canDeleteFile]="canEditUploadSigned()"
                            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.AmendmentSupportingDocumentation"
                            [researchProjectID]="researchProjectID"
                            (uploadedFileChangeEmitted)="handleDocumentChange()"
                            [disabled]="!canEditUploadSigned()"
                        ></castateparksscp-research-project-file-list-manager>
                    </ng-container>
                </div>
                <div class="g-col-6" style="margin-top: 1rem">
                    <div *ngIf="!!project?.RenewedFromPermitNumber" style="margin-bottom: 1rem; display: flex; width: 100%">
                        <ng-container *ngIf="canEditUploadSigned()">
                            <button cdsButton [disabled]="isMarkAwaitingAnnualReportButtonDisabled()" (click)="markAwaitingAnnualReport()">Mark Awaiting Annual Report</button>
                        </ng-container>
                    </div>
                    <div style="display: flex; width: 100%">
                        <ng-container *ngIf="canViewApproveButton()">
                            <button size="xl" style="margin-right: 10px" cdsButton [disabled]="isApproveButtonDisabled()" (click)="approve()">Approve permit</button>
                            <cds-inline-loading *ngIf="isApprovalInProgress"
                                                [state]="loadingState"
                                                [loadingText]="'Approval in progress…'">
                            </cds-inline-loading>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>


        <h3>Form Documents</h3>

        <castateparksscp-research-project-file-list-manager
            [description]="'Upload any additional supporting documentation'"
            [buttonType]="'secondary'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.SupportingDocumentation"
            [researchProjectID]="researchProjectID"
            [isShowUploadedFiles]="false"
            (uploadedFileChangeEmitted)="handleDocumentChange()"
            [disabled]="isUploadSupportingDocumentationDisabled()">
        </castateparksscp-research-project-file-list-manager>
        <castateparksscp-research-project-file-list
            #researchProjectFileList
            [researchProjectID]="researchProjectID"
            [researchProjectFileTypeID]="null"
            [fileRoute]="fileRoute"
            [viewAllFiles]="true"
            [useDefaultViewAllFilesHeight]="false"
            [disabled]="true"
            [isShowAddButton]="true"
        ></castateparksscp-research-project-file-list>

        <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
        <div class="grid-12">
            <ng-container *ngIf="canReadInternalNotes()">
                <div class="g-col-4"><h2 style="margin: 0">Internal Notes</h2></div>
                <div class="g-col-4" style="display:flex; flex-direction: row-reverse">
                    <button *ngIf="showInternalNotesEditButton()" [disabled]="!canEditInternalNotes()" cdsButton="secondary"
                            type="button" title="Edit" (click)="enterInternalNotesEditMode()">Edit
                    </button>
                </div>
                <div class="g-col-8">
                    <castateparksscp-internal-notes-form
                        #internalNotesForm
                        [isLoading]="isLoading"
                        [isCDS]="true"
                        [dto]="internalNotes"
                        [ID]="researchProjectID"
                        [isHideActionButtons]="false"
                        (cancelEditModeChange)="handleInternalNotesEditCancel()"
                        (formSubmitted)="refreshInternalNotes($event)">
                    </castateparksscp-internal-notes-form>
                </div>
            </ng-container>
        </div>

        <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
        <castateparksscp-permit-workflow-change-list #workflowChangesList
                                                     [researchProjectID]="researchProjectID">
            <h2>History</h2>
        </castateparksscp-permit-workflow-change-list>

    </div>
</div>

