import {ContactDto} from "src/app/shared/generated/model/contact-dto";
import { StateIdToDisplayNameMap } from "src/app/shared/models/enums/state.enum.util";

export class ContactDtoUtil {
    public static additionalProcess(dto: ContactDto): ContactDto {
        dto['Name'] = this.getPersonName(dto);
        return dto;
    }

    public static getPersonName(contactDto: ContactDto): string {
        let LastName = !!contactDto?.LastName ? contactDto.LastName : "";
        let FirstName = !!contactDto?.FirstName ? contactDto.FirstName : "";
        let OrganizationName = !!contactDto?.OrganizationName ? contactDto.OrganizationName : "";
        return `${LastName}, ${FirstName}`;
    }
}
