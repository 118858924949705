<ng-container *ngIf="useCDS; else material">
    <div style="width: 100%; display: flex; justify-content: center">
        <button cdsButton="ghost" size="sm" (click)="onClick($event)" title="{{buttonTitle}}" [disabled]="disabled">
            <svg class="cds--btn__icon no-margin" cdsIcon="{{icon}}" size="16"></svg>
        </button>
    </div>
</ng-container>
<ng-template #material>
    <div class="grid-button-renderer">
        <esa-material-button [disabled]="disabled" [label]="label" [icon]="icon" [type]="type" (click)="onClick($event)"></esa-material-button>
    </div>
</ng-template>
