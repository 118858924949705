import {Component, OnInit, SimpleChanges} from '@angular/core';
import {BasicFormComponent, FormElement, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {DistrictService} from "../../shared/generated/api/district.service";
import {AlertService} from "../../shared/services/alert.service";
import {ParkService} from "../../shared/generated/api/park.service";
import {forkJoin} from "rxjs";
import {ResearchProjectAreaParkService} from "../../shared/generated/api/research-project-area-park.service";
import {ResearchProjectAreaParkUpsertDto} from "../../shared/generated/model/research-project-area-park-upsert-dto";

@Component({
  selector: 'castateparksscp-research-project-study-area-form',
    templateUrl: '../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../basic/shared/basic-form/basic-form.component.scss'],
})
export class ResearchProjectStudyAreaFormComponent extends BasicFormComponent implements OnInit {
    upsertDto: ResearchProjectAreaParkUpsertDto;
    parkOptions = [];
    parkOptionsMap: Map<number, string> = new Map<number, string>();
    formPage: FormPage = {
        dtoName: 'research project',
    } as FormPage;

    constructor(public parkService: ParkService,
                public researchProjectAreaParkService: ResearchProjectAreaParkService,
                public districtService: DistrictService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => dto;
        this.formPage.put = (id, upsertDto) => this.researchProjectAreaParkService.researchProjectAreaParksResearchProjectsResearchProjectIDPut(id, upsertDto);
    }

    override ngOnInit() {
        forkJoin({
            parks: this.parkService.parksGet(),
            districts: this.districtService.districtsGet()
        })
        .subscribe(({parks, districts}) => {
            const districtMap = new Map(districts.map(d => {
                    return [d.DistrictID, d.Name]
            }));
            this.parkOptions = parks.map(p => {
                const label = `${p.Name} - ${districtMap.get(p.DistrictID)}`;
                this.parkOptionsMap.set(p.ParkID, label);
                return {content: label, value: p.ParkID};
            });
            this.refreshFormElements();
        });
        super.ngOnInit();
    }

    buildFormElements(isAccessRestricted): FormElement[] {
        let mappedDtoValue = this.mapDtoValueToListItem('ParkIDs');
        return [{
            class: 'g-col-7',
            name: 'ParkIDs',
            label: 'Parks',
            selectDisplayFieldName: 'ParkDisplayName',
            type: 'filterSelect',
            listItems: this.parkOptions,
            listItemValue: mappedDtoValue,
            displayFilterSelectedItems: true,
            requiredValidated: true
        }]  as FormElement[];
    }

    override mapDtoValueToListItem(property: string, displayField: string = null): null | any {
        if (!this.upsertDto?.[property]) return null;
        return this.upsertDto[property].map(x => Object.assign({}, {'content': this.parkOptionsMap.get(x), 'selected': true, value: x}));
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        this.refreshFormElements();
    }
}
