import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CurrentContactService} from 'src/app/services/current-contact.service';
import {EditViewEventService} from 'src/app/services/edit-view-event.service';
import {ContactDto} from 'src/app/shared/generated/model/contact-dto';
import {ContactUpsertDto} from 'src/app/shared/generated/model/contact-upsert-dto';
import {UserDto} from 'src/app/shared/generated/model/user-dto';
import {ContactFormComponent} from '../../shared/contact-form/contact-form.component';
import {ContactDtoUtil} from '../../shared/contact-dto.util';
import {StateIdToDisplayNameMap} from "../../../../shared/models/enums/state.enum.util";
import {CountryIdToDisplayNameMap} from 'src/app/shared/models/enums/country.enum.util';
import {RoleEnum} from "../../../../shared/generated/enum/role-enum";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'castateparksscp-contact-detail-summary',
    templateUrl: './contact-detail-summary.component.html',
    styleUrl: './contact-detail-summary.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactDetailSummaryComponent implements OnInit, OnDestroy {
    @ViewChild("updateContactForm", {read: ContactFormComponent}) form: ContactFormComponent;

    public contact$: Observable<any>;

    public contact: ContactDto;
    public currentUser: UserDto;
    public editMode = false;
    public contactUpsertDto: ContactUpsertDto;

    user: Subscription;
    routerParams: Subscription;

    constructor(
        private currentContactService: CurrentContactService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private cdr: ChangeDetectorRef,
        public route: ActivatedRoute,
    ) {
        this.routerParams = this.route.params.subscribe(() => {
            this.editMode = false;
        });
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.contact$ = this.currentContactService.getCurrentContact().pipe(
            map((data: ContactDto) => {
                if (data && data.StateID) data['StateDisplayName'] = StateIdToDisplayNameMap.get(data.StateID);
                if (data && data.CountryID) data['CountryDisplayName'] = CountryIdToDisplayNameMap.get(data.CountryID);
                this.contact = data;
                this.contactUpsertDto = this.currentContactService.createContactDto(this.contact);
                this.cdr.markForCheck();
                return data;
            })
        );
    }

    isPublicUser(): boolean {
        const x = RoleEnum.Public == this.currentUser?.Role?.RoleID;
        this.cdr.markForCheck();
        return x;
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.routerParams.unsubscribe();
    }

    refreshContact(result: any) {
        result = ContactDtoUtil.additionalProcess(result);
        this.editMode = false;
        this.currentContactService.setCurrentContact(result);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save(result) {
        if (!result) {
            this.cdr.markForCheck();
            return;
        }
        this.refreshContact(result);
    }

    canEdit(): boolean {
        if (this.currentUser?.ContactID == this.contact?.ContactID) return true;
        return this.currentContactService.canEditCurrentContact(this?.currentUser, this.contact) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        // reverts to upsert changes
        this.contactUpsertDto = this.currentContactService.createContactDto(this.contact);
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.contactUpsertDto) === JSON.stringify(this.form.upsertDto);
        } else {
            return true;
        }
    }
}
