<div>
    <div id="returnedToResearcher" *ngIf="show" class="m-y">
        <cds-inline-notification [notificationObj]="{
            type: 'info',
            lowContrast: true,
            showClose: false,
            template: notificationContent,
            title: 'Readonly'
        }"></cds-inline-notification>
        <ng-template #notificationContent>
            <p cdsToastTitle>Read-only Application</p>
            <p cdsToastCaption>The current user is not able to make edits to this application at this time.</p>
        </ng-template>
    </div>
</div>

