import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'castateparksscp-review-section',
  templateUrl: './review-section.component.html',
  styleUrl: './review-section.component.css'
})
export class ReviewSectionComponent {
    @Input() reviewRows: Array<{field: string, value: string, originalValue?: string}> = [];
    @Input() buttonRoute: string;
    @Input() hideButtons: boolean = false;
    @Input() showDiff: boolean = false;

    navigateToReviewedSection() {
        this.router.navigateByUrl(this.buttonRoute).then();
    }

    constructor(private router: Router) {}

    isShowDiff(row) {
        return this.showDiff && row.value != row.originalValue;
    }
}

