import {Component, OnInit} from '@angular/core';
import {BasicFormComponent, FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectInternalNotesDto} from "../../../shared/generated/model/research-project-internal-notes-dto";

@Component({
    selector: 'castateparksscp-internal-notes-form',
    templateUrl: './../../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './internal-notes-form.component.css'
})
export class InternalNotesFormComponent extends BasicFormComponent implements OnInit {
    upsertDto: ResearchProjectInternalNotesDto;

    formPage: FormPage = {
        dtoName: 'notes',
        elements: [
            {
                class: 'g-col-12',
                name: 'InternalNotes',
                label: '',
                type: 'editor',
                editorConfig: {toolbar: true, menubar: true, plugins: 'lists link table help wordcount'}
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            return {'InternalNotes': dto?.InternalNotes,};
        };
        this.formPage.put = (id, upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDInternalNotesPut(id, upsertDto);
    }
}
