import {Component, Input} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'castateparksscp-in-line-edit-buttons-renderer',
    templateUrl: './in-line-edit-buttons-renderer.component.html',
    styleUrl: './in-line-edit-buttons-renderer.component.css'
})
export class InLineEditButtonsRendererComponent implements ICellRendererAngularComp {
    params: any;
    modalEdit;

    agInit(params: ICellRendererParams<any, any, any>): void {
        this.params = params;
        this.modalEdit = !!params['modalEdit'];

    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        return true;
    }

    edit($event) {
        if (this.params.edit instanceof Function) {
            const params = {
                event: $event,
                node: this.params.node,
                editControl: this
            };
            this.params.edit(params);
        }
    }

    delete($event) {
        if (this.params.delete instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data,
                editControl: this
            };
            this.params.delete(params);
        }
    }

    save($event) {
        if (this.params.save instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data,
                editControl: this
            };
            this.params.save(params);
        }
    }

    cancel($event) {
        if (this.params.cancel instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data,
                editControl: this
            };
            this.params.cancel(params);
        }
    }

    isEditMode(): boolean {
        return this.params.editRowIndex() == this.params.node.rowIndex;
    }

    canEdit(): boolean {
        if (this.params.editableCallback instanceof Function) {
            return this.params.editableCallback();
        }
        return this.params.canEdit == undefined ? true : this.params.canEdit;
    }

    canDelete(): boolean {
        if (this.params.editableCallback instanceof Function) {
            return this.params.editableCallback();
        }
        return this.params.canDelete == undefined ? true : this.params.canDelete;
    }

}
