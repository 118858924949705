import {Component, EventEmitter, Output} from '@angular/core';
import {BasicFormComponent, FormPage} from "../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'castateparksscp-research-project-soil-disturbance-flag-form',
    templateUrl: './../basic/shared/basic-form/basic-form.component.html',
    styleUrl: './../basic/shared/basic-form/basic-form.component.scss'
})
export class ResearchProjectSoilDisturbanceFlagFormComponent extends BasicFormComponent {
    upsertDto: SoilDisturbanceFlagDto;

    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'SoilDisturbance',
                label: 'Will your project involve soil disturbance (placement of stakes, collection of soil cores, etc.)?',
                required: false,
                type: 'radio',
                radioOptions: this.flagOptions,
                onChange: this.handleFlagChange.bind(this)
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            return {
                SoilDisturbance: dto?.SoilDisturbance || false
            } as SoilDisturbanceFlagDto
        };
    }
}

export interface SoilDisturbanceFlagDto {
    SoilDisturbance: boolean;
}
