import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Alert} from "../../../shared/models/alert";
import {AlertContext} from "../../../shared/models/enums/alert-context.enum";
import {UserDto} from "../../../shared/generated/model/user-dto";
import {PermissionEnum} from "../../../shared/generated/enum/permission-enum";
import {RightsEnum} from "../../../shared/models/enums/rights.enum";
import {AuthenticationService} from "../../../services/authentication.service";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectFileListComponent} from "../research-project-file-list/research-project-file-list.component";
import {ResearchProjectFileService} from "../../../shared/generated/api/research-project-file.service";
import {Observable, Subscription} from 'rxjs';
import {FileUploaderComponent} from "../../../shared/components/file-uploader/file-uploader.component";

@Component({
    selector: 'castateparksscp-research-project-file-list-manager',
    templateUrl: './research-project-file-list-manager.component.html',
    styleUrl: './research-project-file-list-manager.component.css'
})
export class ResearchProjectFileListManagerComponent implements OnInit, OnDestroy {
    @Input() researchProjectID: number;
    @Input() researchProjectFileTypeID: number;
    @Input() title: string = '';
    @Input() description: string = '';
    @Input() useCDS: boolean = true;
    @Input() uploadFileButtonText: string;
    @Input() disabled: boolean = false;
    @Input() titleLinkRoute: string = '';
    @Input() titleLinkLabel: string = 'Download unsigned form';
    @Input() required: boolean = false;
    @Input() limitOneFile: boolean = false;
    @Input() canDeleteFile: boolean = true;
    @Input() supportedFileTypes: string[] = [];
    @Input() buttonType = "primary";
    @Input() isShowUploadedFiles = true;
    @Output() uploadedFileChangeEmitted: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("researchProjectFileList") researchProjectFileList: ResearchProjectFileListComponent;
    @ViewChild("fileUploader") fileUploader: FileUploaderComponent;

    public isLoading: Boolean = false;
    public hasUnuploadedFiles: Boolean = false;
    public fileRoute: string;
    public currentUser: UserDto;
    currentUserSubscription: Subscription;
    filesGetter: () => Observable<Array<any>>;
    fileDelete: (researchFileTypeID: number, blobName: string) => Observable<void>

    constructor(
        private authenticationService: AuthenticationService,
        public researchProjectFileService: ResearchProjectFileService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
    ) {

    }

    ngOnInit(): void {
        this.fileRoute = `researchProjects/${this.researchProjectID}/researchProjectFileTypes/${this.researchProjectFileTypeID}/files`;
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
        });
        this.filesGetter = () => this.researchProjectFileService.researchProjectsResearchProjectIDFilesGet(this.researchProjectID);
        this.fileDelete = (researchFileTypeID: number, blobName: string) => this.researchProjectFileService.researchProjectsResearchProjectIDResearchProjectFileTypesResearchProjectFileTypeIDFilesBlobNameDelete(this.researchProjectID, researchFileTypeID, blobName);
    }

    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
    }

    hasFilesToUpload(event) {
        this.hasUnuploadedFiles = event;
    }

    fileUploadStarted(event) {
        this.isLoading = true;
        this.cdr.markForCheck();
    }

    fileUploadSuccess(fileUploadResult) {
        if (!this.useCDS) {
            this.refreshData();
        }
        let alert = !!fileUploadResult?.isSuccess ? new Alert("The files were successfully uploaded.", AlertContext.Success)
            : new Alert( fileUploadResult?.error || "The files failed to upload.",  AlertContext.Danger);
        this.alertService.pushAlert(alert);
        if (fileUploadResult) this.uploadedFileChangeEmitted.emit();
    }

    canUploadProjectFile(currentUser: UserDto) {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.ResearchProjectRights, RightsEnum.Update);
    }

    refreshData() {
        this.researchProjectFileList.loadData();
    }

    fileDeleteSuccess(isSuccess) {
        let alert = isSuccess ? new Alert("The file was successfully deleted.", AlertContext.Success)
                : new Alert( "File delete failed.",  AlertContext.Danger);
        this.alertService.pushAlert(alert);
        if (isSuccess) this.uploadedFileChangeEmitted.emit();
    }

    uploadedFilesExist() {
        return !!this.fileUploader?.getUploadedFilesCount();
    }
}
