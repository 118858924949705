export const RoleDisplayNameToIdMap: Map<string, number> = new Map(
    [
        ['System Administrator', 1],
        ['Public', 2],
        ['Technical Reviewer', 5],
        ['District Staff', 6],
        ['Inactive', 7]
    ])


export const RoleIdToDisplayNameMap: Map<number, string> = new Map(
    [
        [1, 'System Administrator'],
        [2, 'Public'],
        [5, 'Technical Reviewer'],
        [6, 'District Staff'],
        [7, 'Inactive'],
    ])


export const RoleIdAndDisplayNameArray: Array<{ text: string, value: number }> = [

    {text: 'System Administrator', value: 1},
    {text: 'Public', value: 2},
    {text: 'Technical Reviewer', value: 5},
    {text: 'District Staff', value: 6},
    {text: 'Inactive', value: 7},
]
