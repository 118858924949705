import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "src/app/services/authentication.service";
import {LinkRendererComponent} from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import {ProjectService} from "src/app/shared/generated/api/project.service";
import {PermissionEnum} from "src/app/shared/generated/enum/permission-enum";
import {DateColumnCreatorService} from "src/app/shared/services/date-column-creator/date-column-creator.service";
import {ButtonModule, Step} from 'carbon-components-angular';
import {BasicListComponent, ListPage} from "../../basic/basic-list/basic-list.component";
import { InLineEditButtonsRendererComponent } from "src/app/shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component";
import { ProjectDto } from "src/app/shared/generated/model/models";
import {GetRowIdFunc, GetRowIdParams } from "ag-grid-community";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "castateparksscp-project-list",
    templateUrl: "../../basic/basic-list/basic-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListComponent extends BasicListComponent implements OnInit {
    listPage: ListPage = {
        permission: PermissionEnum.ProjectRights,
        createRoute: '/projects/create',
        pageTitle: 'Projects',
        createButtonLabel: 'Project',
        downloadFileName: 'projects',
        isShowAddButton: true,
    } as ListPage;

    constructor(
        public projectService: ProjectService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.projectService.projectsGet();
        this.listPage.columnDefs = [
            {
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                        },
                    };
                },
                width: 100
            },
            {
                field: "Name",
                headerName: "Name",
                headerTooltip: "Name",
                editable: true,
                cellEditor: "agTextCellEditor",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ProjectID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return {
                        component: LinkRendererComponent,
                        params: {inRouterLink: "/projects/"},
                    };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (
                    linkA,
                    linkB,
                    nodeA,
                    nodeB,
                    isDescending
                ) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 500,
                sort: "asc",
            },
            this.dateColumnCreator.createDateColumnDef(
                "Date",
                "ProjectDate",
                "M/dd/YYYY"
            ),
            {
                field: "Description",
                headerName: "Description",
                headerTooltip: "Description",
                editable: true,
            }
        ];
    }

    override onGridReady(gridEvent) {
        this.grid.api.showLoadingOverlay();

        this.getRequest = this.listPage
            .dtosGetter()
            .subscribe((results) => {
                this.rowData = results;
                this.grid.api.hideOverlay();
                this.dtos = results;
                this.cdr.markForCheck();
            });
    }

    public idField: string = 'ProjectID';
    public startEditingColKey: string = 'Name';

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ProjectDto> | any) => params.data.ProjectID;
    public putAction = (id: number, dto: any) => {
        return this.projectService.projectsProjectIDPut(id, dto);
    }
}
